import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import Button from '../../components/Button';
import { opacityInAnimation, OpacityHoverAnimation, zoomOutAnimation } from '../../sharedStyled';
import { LandingText } from '../../Auth/Signup/styled';
import { PlanBenefit } from '../Landing/styled';

interface IPayButtonProps {
  error?: string;
}

interface IPriceFieldWrapper {
  applyBorder?: boolean;
}

interface IAddressFields {
  applyBorderRadius?: boolean;
  applyTopBorder?: boolean;
}

interface ISelectField {
  isMenuShown: boolean;
}
interface IFieldLabel {
  isError?: boolean;
}

interface IPaymentMethodInfoContainer {
  isError?: boolean;
}
const PaymentFormContainer = styled('div')`
  border-radius: 16px;
  margin-top: 5%;
  margin-bottom: 5%;
  background: ${colors.lightestGrey};
  width: max(33vw, 480px);
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 90%;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    box-sizing: border-box;
    //width: 90%;
  }
  ${props => props.theme.breakpoints.down('$xs')} {
    overflow: auto;
  }
  ${props => props.theme.breakpoints.down('$xxs')} {
    width: 95%;
  }

  ${zoomOutAnimation(0.3, 'ease-in')}
`;

const PaymentFormTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 43px;
  text-align: center;
`;

const FieldLabel = styled('div', { shouldForwardProp: prop => shouldForwardProps(prop, ['error']) })<IFieldLabel>(
  ({ isError }) => `
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
  transition: color 0.5s ease-in-out;

  ${isError ? `color:${colors.red};` : ''}

`,
);

const commonFieldsStyles = `
  width: 100%;
  border: 1px solid ${colors.black};
  padding: 9px 12px;
  box-sizing: border-box;
`;

const TextField = styled('input')`
  margin-bottom: 32px;
  border-radius: 8px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
  ${commonFieldsStyles}
  :focus {
    outline: none;
  }
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
`;

interface ICardDetailsContainer {
  isError: boolean;
}
const CardDetailsContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isError']),
})<ICardDetailsContainer>(
  ({ isError }) => `
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  box-shadow: ${isError ? 'none' : '0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04)'};
  div {
    ${commonFieldsStyles}
  }
  div:nth-child(1) {
    border-radius: 8px 8px 0 0;
  }
  div:nth-child(2) {
    border-radius: 0 0 0 8px;
  }
  div:nth-child(3) {
    border-radius: 0 0 8px 0;
    border-left: none;
  }
  div:nth-child(2),
  div:nth-child(3) {
    border-top: none;
    width: 50%;
  }
`,
);

const PayButton = styled(Button, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['error']),
})<IPayButtonProps>(
  ({ theme, error }) => `
  min-width:40%;

  // ${theme.breakpoints.down('sm')} {
  //   font-size: 16px;
  //   }
`,
);

const CancelPaymentButton = styled(Button)`
  width: 100%;
  margin-bottom: 50px;
`;

const FooterText = styled('div')`
  font-size: 0.75rem;
  color: ${colors.black10};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing(5)};
  padding-bottom: ${props => props.theme.spacing(5)};
  color: ${colors.darkGrey};
  gap: 9px;
  font-weight: 500;
  margin-top:auto;
  left: 0;
  width: 100%;
  span {
    display: flex;
    align-items: center;
  }
  svg:nth-child(2) {
    margin-left: 12px;
    margin-right: 12px;
  }

  }
`;

const SelectField = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isMenuShown']),
})<ISelectField>(
  ({ isMenuShown, theme }) => `
  ${commonFieldsStyles}
  border-radius: ${isMenuShown ? 0 : '0px 0px 8px 8px'};
  // border-bottom: none;

  :focus {
    outline: none;
  }
  background:${colors.white};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  font-size: 0.8375rem;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
    button {
      font-size:1rem;
    }
  }
  
`,
);

const AddressFieldsContainer = styled('div')`
  // input {
  //   border-radius: 8px 8px 0px 0px;
  // }
  margin-bottom: 32px;
  position: relative;
`;

const CountriesMenu = styled('div')`
  background-color: ${colors.white};
  position: absolute;
  // bottom: calc(-100% + 87px);

  left: 0;
  right: 0;
  border: 1px solid ${colors.black};
  border-top: 0;
  box-sizing: border-box;
  padding: 9px 0;
  border-radius: 0 0 8px 8px;
  z-index: 10;
`;

const CountryOption = styled('div')`
  padding: 12px;
  :hover {
    background-color: ${colors.lightGrey};
    cursor: pointer;
  }
`;

const ErrorMessage = styled('div')`
  color: ${colors.red};
  font-size: 0.75rem;
  margin-bottom: 16px;
  text-align: center;
  border: none !important;
  ${opacityInAnimation()};
`;

const ErrorMessageNoMargin = styled(ErrorMessage)`
  margin: 0;
`;
interface IPriceFieldWrapper {
  isVisible?: boolean;
}

const FieldErrorMessage = styled(ErrorMessage, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isVisible']),
})<IPriceFieldWrapper>(
  ({ isVisible }) => `
  margin-top: 10px;
  margin-bottom: 32px;
  text-align: left;

  visibility:${isVisible ? 'visible' : 'hidden'};
`,
);

const PriceFieldsWrapper = styled('div')`
  //border-top: 1px solid ${colors.grey500};
  border-top: 1px solid hsla(0, 0%, 10%, 0.2);
  padding-top: 10px;
  margin-bottom: 32px;
  transition: all 0.08s ease-in;
`;
const InnerPriceFieldsWrapper = styled('div')`
  width: 70%;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 85%;
  }
`;
const PriceFieldWrapper = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['applyBorder']),
})<IPriceFieldWrapper>(
  ({ applyBorder }) => `
 
  border-top: ${applyBorder ? `1px solid hsla(0, 0%, 10%, 0.2)` : 0};
  padding-top:16px;
  padding-bottom:16px;
`,
);

const PriceField = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  min-height: 25px;
  // ${props => props.theme.breakpoints.down('sm')} {
  //   font-size: 16px;
  // }
`;

const Price = styled('span')`
  color: ${colors.black10};
  font-weight: 700;
`;

const TaxPrice = styled('span')`
  color: ${colors.grey500};
`;

const DiscountPrice = styled('span')`
  color: ${colors.green20};
  margin-bottom: 16px;
`;

const InnerTaxFieldWrapper = styled(InnerPriceFieldsWrapper)`
  width: 100%:
`;

const AddressField = styled(TextField, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['applyBorderRadius', 'applyTopBorder']),
})<IAddressFields>(
  ({ applyBorderRadius, applyTopBorder }) => `
  border-radius: ${applyBorderRadius ? '8px 8px 0px 0px' : 0};
  margin: 0;
  ${!applyTopBorder ? 'border-top:0;' : ''}

`,
);

const PromotionCodeButton = styled('button')`
  all: unset;
  color: ${colors.purple20};
  cursor: pointer;
  font-size: 0.9rem;
  margin-bottom: 16px;
  // ${props => props.theme.breakpoints.down('sm')} {
  //   font-size: 16px;
  // }
  ${OpacityHoverAnimation(colors.purple20)};
`;

const PromotionCodeWrapper = styled('div')`
  margin-bottom: 16px;
  position: relative;
  min-height: 45px;
`;

const PromotionCodeInput = styled(TextField)`
  margin: 0;
  border: 0;
  margin-bottom:4px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px ${colors.grey500}, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
 
  :focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(96, 75, 223, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.3), 0 0 0 4px rgba(96, 75, 223, 0.3);
    z-index: 2;
  }
  animation: widthIn 0.3s ease-out;
  @keyframes widthIn {
    0% {
      width:40%;
    }
   
    100% {
      width: 100%;
    };
`;

const ApplyButton = styled('button')`
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 100%;
  z-index: 3;
  right: 10px;
  top: 8px;
  font-size: 0.9rem;
  font-weight: 700;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
  ${opacityInAnimation(0.8)};
  ${OpacityHoverAnimation(colors.purple40)};
`;

const PricingFieldLabel = styled(FieldLabel)`
  font-size: 0.9rem;
`;

const PriceSelectionContainer = styled('div')`
  background: ${colors.purple1};
  box-sizing: border-box;
  border-radius: 16px 16px 0px 0px;
  padding: 59px 56px 35px 59px;
  width: 50%;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.down('xmd')} {
    padding-left: 25px;
    padding-right: 25px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 10%;
    padding-right: 10%;
    background: ${colors.white};
  }
`;

const Footer = styled('div')`
  margin-top: auto;
  margin-bottom: ${props => props.theme.spacing(5)};
  background: ${colors.lightestGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  gap: ${props => props.theme.spacing(5)};
  flex-wrap: wrap;
  // ${props => props.theme.breakpoints.up('lg')} {
  //   button {
  //     min-width: 160px;
  //   }
  // }
`;

const FormContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled('form')`
  background: ${colors.lightestGrey};

  border-radius: 16px;
  padding: 59px 40px 20px 40px;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    min-height: unset;
  }
  ${props => props.theme.breakpoints.down('xmd')} {
    padding-left: 25px;
    padding-right: 25px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: 5%;
    padding-right: 5%;
  }
  input,
  .StripeElement {
    background-color: ${colors.white};
  }

  .message-container {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const Title = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  ${props => props.theme.breakpoints.up('lg')} {
    font-size: 1.25rem;
    line-height: 32px;
  }
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const PricesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
`;
const PriceContainer = styled('div')`
  background: ${colors.white};
  padding: 24px 40px;
  box-sizing: border-box;
  border: 1px solid #ece9fb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${props => props.theme.spacing(3)};
  ${props => props.theme.breakpoints.down('$xs')} {
    padding: 12px 20px;
  }
  ${props => props.theme.breakpoints.down('$sm')} {
    min-height: 95px;
  }
`;

const PriceDescription = styled(PlanBenefit)`
  margin-bottom: 0;
`;

const FormTitle = styled('div')`
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const FormTitleBottomMargin = styled(FormTitle)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const MembershipInfoContainer = styled('div')`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.spacing(3)};
  border: 1px solid ${colors.grey600};
  border-radius: 8px;
  background: ${colors.white};
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacing(7)};
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    min-width: 400px;
  }

  box-sizing: border-box;
`;

const MembershipInfoTitle = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  front-size: 1rem;
  flex-direction: column;
  span {
    font-weight: 700;
  }
  .addon-title {
    color: ${colors.primary};
  }
`;

const MembershipBillingCycle = styled('span')`
  font-size: 0.875rem;
  color: ${colors.grey400};
`;

const PaymentMethodInfoContainer = styled(MembershipInfoContainer, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isError']),
})<IPaymentMethodInfoContainer>(
  ({ isError }) => `
  flex-direction: row;
  align-items: center;
  button {
    font-size: 0.875rem;
    color: ${colors.black10};
  }
  position: relative;
  ${isError ? `background: ${colors.pink};` : ''}
  ${isError ? `border: 1px solid ${colors.red300};` : ''}
`,
);

const PaymentMethodInfoTitleContainer = styled(`div`)`
  svg {
    width: 48px;
    height: 48px;
  }
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
`;

const PaymentMethodInfoTitle = styled('div')`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled('div')`
  font-weight: 700;
  font-size: 1rem;
`;

const CardExpiry = styled('div')`
  font-weight: 600;
  font-size: 0.875rem;
`;

const ErrorIconWrapper = styled('div')`
  position: absolute;
  right: -30px;
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const NoPaymentMethod = styled('div')`
  text-align: center;
`;

export {
  //PaymentFormContainer,
  FormContainer,
  PaymentFormContainer,
  PaymentFormTitle,
  FieldLabel,
  TextField,
  CardDetailsContainer,
  PayButton,
  CancelPaymentButton,
  FooterText,
  SelectField,
  AddressFieldsContainer,
  CountriesMenu,
  CountryOption,
  ErrorMessage,
  FieldErrorMessage,
  PriceFieldsWrapper,
  PriceFieldWrapper,
  InnerPriceFieldsWrapper,
  Price,
  AddressField,
  TaxPrice,
  InnerTaxFieldWrapper,
  PriceField,
  PromotionCodeButton,
  PromotionCodeWrapper,
  PromotionCodeInput,
  ApplyButton,
  PricingFieldLabel,
  DiscountPrice,
  PriceSelectionContainer,
  Footer,
  FormContent,
  Title,
  PriceContainer,
  PriceDescription,
  PricesContainer,
  FormTitle,
  FormTitleBottomMargin,
  MembershipInfoContainer,
  MembershipInfoTitle,
  MembershipBillingCycle,
  PaymentMethodInfoContainer,
  PaymentMethodInfoTitleContainer,
  PaymentMethodInfoTitle,
  CardTitle,
  CardExpiry,
  ErrorMessageNoMargin,
  ErrorIconWrapper,
  NoPaymentMethod,
};
