import { en } from '../../../i18n';
import {
  PlanTitle,
  PlanBenefits,
  PlanBenefit,
  PlanButton,
  PlanPromotion,
  PlanContainer,
  ButtonsContainer,
  PlanContent,
  PlanHeadLine,
  PlanPrice,
} from './styled';
import parse from 'html-react-parser';

import { ReactComponent as CheckMarkIcon } from '../../../images/check.svg';
import useMembershipPlan from '../hooks/useMembershipPlan';
import SmartNText from '../../components/SmartnText';
import { defaultPlan as plan } from '../plans';

const { planLandingButtonText, upgradeTitle, upgradeSubTitle, upgradeSubtitleRight } = en.billing;

const Plan = () => {
  const { state, control } = useMembershipPlan();
  const { loading } = state;
  const { handleUpgrade } = control;

  const renderBenefits = () => {
    return plan.benefits.map(benefit => (
      <PlanBenefit key={benefit}>
        <div>
          <CheckMarkIcon />
        </div>
        <div>{parse(benefit)}</div>
      </PlanBenefit>
    ));
  };

  return (
    <PlanContainer>
      <PlanContent>
        <PlanTitle>
          {upgradeTitle}
          {/* <SmartNText />
          &nbsp;
          {parse(plan.outerTitle || '')} */}
        </PlanTitle>
        {plan.headLine && (
          <PlanHeadLine>
            {upgradeSubTitle}&nbsp;
            <SmartNText>{plan.outerTitle}</SmartNText>
            &nbsp;{upgradeSubtitleRight}
          </PlanHeadLine>
        )}
        <PlanPrice>
          ${plan.price}&nbsp;
          {plan.priceDescription}
        </PlanPrice>
        {plan.promotion && <PlanPromotion>{parse(plan.promotion)}</PlanPromotion>}
        <PlanBenefits>
          {renderBenefits()}
          <ButtonsContainer>
            <PlanButton variant="primary" isCurrent={false} onClick={handleUpgrade} loading={loading}>
              {planLandingButtonText}
            </PlanButton>
          </ButtonsContainer>
        </PlanBenefits>
      </PlanContent>
    </PlanContainer>
  );
};

export default Plan;
