import { useState, createRef, useRef, useEffect } from 'react';
import { ICodeInputField } from '../types';
import { Cells, Container, ErrorContainer } from './styled';
import Cell from './Cell';
import { ILooseObject } from '../../../../types';
import { ReactComponent as CloseIcon } from '../../../../../images/icons/signup/close.svg';

const CodeInputField = ({
  inputLength = 4,
  submitAutomatically,
  onChange,
  outputFormat,
  error,
  errorText,
  onSubmit,
  loading,
  formState,
}: ICodeInputField) => {
  const [currentInput, setCurrentInput] = useState(new Array(inputLength));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [forceRemount, setForceRemount] = useState(0);

  useEffect(() => {
    if (error) {
      setForceRemount(forceRemount + 1);
      setCurrentInput(new Array(inputLength));
      setCurrentIndex(0);
      onChange('');
    }
  }, [error]);
  const refs = useRef(new Array(inputLength).fill(0).map(() => createRef<ILooseObject>()));

  const checkSubmit = (code: string[]) => {
    const formInput = code.join('');
    const typedValue = outputFormat === 'number' ? +formInput : formInput;
    if (formInput.length === inputLength && submitAutomatically) {
      onSubmit?.({ code: typedValue, ...formState });
    }
  };

  const pasteCode = (code: string | number) => {
    if (!code) return;
    const stringCode = `${code}`;
    const arrayInput = stringCode.split('');
    if (arrayInput.length !== inputLength) return;
    setCurrentInput(arrayInput);
    setCurrentIndex(inputLength - 1);
    checkSubmit(arrayInput);
  };

  const switchFocus = (nextIndex: number) => {
    setCurrentIndex(nextIndex);
    refs.current[nextIndex]?.current?.focus();
  };
  const onChangeCode = (index: number) => (value: string) => {
    if (value && value.length > 1) {
      return pasteCode(value);
    }
    const nextState = [...currentInput];
    nextState[index] = value;
    setCurrentInput(nextState);
    const nextIndex = currentIndex + 1;
    if (currentIndex < inputLength - 1 && value) switchFocus(nextIndex);
    checkSubmit(nextState);
  };

  const goBackPreviousIndex = () => {
    if (currentIndex >= 1) switchFocus(currentIndex - 1);
  };

  const onFocus = (index: number) => () => setCurrentIndex(index);

  const renderCells = () => {
    const cells = new Array(inputLength)
      .fill(0)
      .map((_, index) => (
        <Cell
          ref={refs.current[index]}
          key={index}
          onChange={onChangeCode(index)}
          onFocus={onFocus(index)}
          error={error}
          loading={loading}
          goBackPreviousIndex={goBackPreviousIndex}
          pasteCode={pasteCode}
          externalValue={currentInput[index]}
        />
      ));
    return cells;
  };
  return (
    <Container key={forceRemount}>
      <Cells>{renderCells()}</Cells>{' '}
      {error && (
        <ErrorContainer>
          <CloseIcon />
          <span>{errorText}</span>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default CodeInputField;
