import React from 'react';
import { Auth } from 'aws-amplify';

import { getOrbitsConfig } from './utils';

import { useAuthorization, useSubscriptions } from './v2/hooks';
import { IAppContext } from './v2/types';

const config = getOrbitsConfig();

Auth.configure(config?.awsCognito);

const initialState: IAppContext = {
  isLoggedIn: false,
  isOnboarded: false,
  isSubscribed: false,
};
const AppContext = React.createContext(initialState);

const AppContextProvider = ({ children }: { children: React.ReactChild }) => {
  const {
    state: { isOnboarded, user, isLoggedIn },
  } = useAuthorization({ useFetchSubscriptions: true });

  const { isSubscribed } = useSubscriptions({ useFetchSubscriptions: false });

  return (
    <AppContext.Provider
      value={{
        isOnboarded: !!isOnboarded,
        user,
        isLoggedIn,
        isSubscribed,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
