import { useSelector } from 'react-redux';
import { usePersonalInfo, useDispatch } from '../../hooks';
import { authSelectors, actions } from '../../Auth/redux/slice';
import { useEffect, useState } from 'react';
import { EUserRole } from '../../types';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { InfoContainer, InfoEditable, InfoLabel } from '../../Home/SideBar/styled';
import { en } from '../../../i18n';
import Button from '../Button';
import Spinner from '../Spinner';
import { DropdownContainer } from './styled';
import UserRolesTransformer from '../../classes/DataTransformers/UserRolesTransformer';

const { dashboard } = en;
const { role, edit, save, cancel, selectARole } = dashboard;

const EditRole = () => {
  const { getRole, isRoleLoading, currentOnBoardingTest, rolePlaceholderAttempt } = usePersonalInfo();
  const { loadingEditRole } = useSelector(authSelectors.allState);
  const { dispatch: dispatchAuth } = useDispatch();
  const [isEditingRole, setIsEditingRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState<EUserRole>();
  const userRole = getRole();

  const hiddenRoles = [EUserRole.USER, EUserRole.INSTRUCTOR, EUserRole.JOB_SEEKER, EUserRole.TUTOR];
  useEffect(() => {
    if (typeof userRole === 'string') setSelectedRole(userRole as EUserRole);
  }, [userRole]);

  const onEditClick = () => {
    if (isEditingRole && selectedRole !== getRole() && currentOnBoardingTest && selectedRole) {
      const roleQuestionId = new UserRolesTransformer().getRoleQuestionID(currentOnBoardingTest);
      if (currentOnBoardingTest.onBoardingVersion === 'v0' || currentOnBoardingTest.onBoardingVersion === 'v1') {
        if (rolePlaceholderAttempt) {
          dispatchAuth(
            actions.editRoleRequest({
              role: selectedRole,
              attemptId: rolePlaceholderAttempt.attemptId,
              questionIdOverride: roleQuestionId,
            }),
          );
        } else {
          dispatchAuth(
            actions.editRoleRequestV0({
              role: selectedRole,
              questionIdOverride: roleQuestionId,
            }),
          );
        }
        setIsEditingRole(!isEditingRole);
        return;
      }

      dispatchAuth(
        actions.editRoleRequest({
          role: selectedRole,
          attemptId: currentOnBoardingTest.attemptId,
          questionIdOverride: roleQuestionId,
        }),
      );
    }
    setIsEditingRole(!isEditingRole);
  };

  const onSelectRole = (e: SelectChangeEvent<EUserRole>) => {
    setSelectedRole(e.target.value as EUserRole);
  };

  const dropDownMenu = () => {
    const renderOptions = () =>
      Object.values(EUserRole)
        .filter(role => !hiddenRoles.includes(role))
        .map(role => (
          <MenuItem value={role} key={role}>
            {role}
          </MenuItem>
        ));

    return (
      <DropdownContainer>
        <Select
          className=".edit-role-button"
          disabled={loadingEditRole}
          onChange={onSelectRole}
          value={selectedRole}
          size="small"
          MenuProps={{ disableScrollLock: true }}
        >
          {renderOptions()}
        </Select>
      </DropdownContainer>
    );
  };

  const renderEditButton = () => {
    //  return <Spinner inline loading size="1.2rem" />;
    if (loadingEditRole) return <Spinner inline loading size="1.2rem" />;
    if (isRoleLoading) return null;
    const renderButtonText = () => {
      if (!isEditingRole) return edit;
      if (selectedRole === getRole()) return cancel;
      return save;
    };
    return (
      <Button variant="underlined" onClick={onEditClick}>
        {renderButtonText()}
      </Button>
    );
  };
  //const hiddenRoles = [EUserRole.USER, EUserRole.INSTRUCTOR, EUserRole.JOB_SEEKER, EUserRole.TUTOR];
  const renderUserRole = () => {
    if (
      userRole === EUserRole.USER ||
      userRole === EUserRole.INSTRUCTOR ||
      userRole === EUserRole.JOB_SEEKER ||
      userRole === EUserRole.TUTOR
    )
      return selectARole;

    return userRole;
  };

  return (
    <InfoContainer className="info-container-editRole">
      <InfoLabel>{role}</InfoLabel>
      <InfoEditable>
        {isEditingRole || loadingEditRole ? dropDownMenu() : renderUserRole()}
        {renderEditButton()}
      </InfoEditable>
    </InfoContainer>
  );
};

export default EditRole;
