import Auth from '../Auth';
import Form from '../../components/Form';
import BackButton from '../../components/BackButton';
import { fieldConfigs } from '../constants';
import Button from '../../components/Button';

import { SubTitleFormWrapper, BackButtonContainer } from '../styled';
import { en } from '../../../i18n';
import { ILooseObject } from '../../types';
import { useAuthorization, useDispatch, useSeoTags } from '../../hooks';
import { useHistory } from 'react-router';
import { actions } from '../redux/slice';
import useAuthForm from '../useAuthForm';

const { seoTags } = en;
const { title, submitText, subTitle } = en.registerConfirmation;
const { inputCode, userName } = fieldConfigs;

const RegisterConfirmation = () => {
  const { dispatch } = useDispatch();
  const history = useHistory();
  const { state } = useAuthorization();
  const { user } = state;
  const { loading, errorFeedback, successFeedback } = useAuthForm({
    errorFeedbackPosition: 'hidden',
    disableResetState: true,
    useDefaultValues: false,
  });
  const seo = useSeoTags({ pageName: 'registerConfirmation' });
  const fieldsWithUserInput = { inputCode, userName };
  const fieldsWithoutUserInput = { inputCode };

  const resendCodeRequest = (formState: ILooseObject) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const userName = user?.username || formState.userName;
    // TODO: display error if userName is empty
    if (!userName) return;
    dispatch(actions.resendConfirmationCodeRequest({ userName }));
  };

  const resendCode = ({ formState }: ILooseObject) => (
    <Button
      onClick={resendCodeRequest(formState)}
      loading={loading}
      className="form-resend-button"
      variant="underlined"
    >
      {submitText}
    </Button>
  );

  const onSubmit = (formState: ILooseObject) => {
    const { code, userName } = formState;
    dispatch(actions.confirmAccountRequest({ code, history, userName: user?.username || userName }));
  };

  const renderForm = () => {
    const props = {
      loading: loading,
      formError: errorFeedback,
      formSuccess: successFeedback,
      subTitle: subTitle,
      title: title,
      submitText: submitText,
      CustomButtonComponent: resendCode,
      onSubmit: onSubmit,
      useGlobalError: true,
    };
    if (!user?.username) {
      return <Form fields={fieldsWithUserInput} {...props} />;
    }
    return <Form fields={fieldsWithoutUserInput} {...props} />;
  };

  return (
    <Auth mobileWidth={90} centerFormVertical={false}>
      {seo}
      <SubTitleFormWrapper>
        <BackButtonContainer>
          <BackButton text={en.forgotPassword.back} />
        </BackButtonContainer>
        {renderForm()}
      </SubTitleFormWrapper>
    </Auth>
  );
};

export default RegisterConfirmation;
