import React from 'react';
import { useHistory } from 'react-router';
import { styled } from '@mui/material/styles';
import { dashboardPath } from '../Router/paths';

import orbitsPurpleLogoSVG from '../../images/newLogo.svg';
import { en, ORBOT_AVATAR_ALT } from '../../i18n';

const ImageContainer = styled('div')`
  img {
    width: 138px;
  }
  ${props => props.theme.breakpoints.down('$sm')} {
    img {
      margin-top: 5px;
      width: 100px;
    }
  }
`;

function OrbotAvatar() {
  const history = useHistory();

  const goToDashboard = () => {
    const { location } = history;
    if (location.pathname.includes('on-boarding') || location.pathname.includes('placement')) return;
    history.push(dashboardPath);
  };

  return (
    <ImageContainer onClick={goToDashboard}>
      <img height="auto" alt={en[ORBOT_AVATAR_ALT]} src={orbitsPurpleLogoSVG} />
    </ImageContainer>
  );
}

export default OrbotAvatar;
