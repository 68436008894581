import { IAuthError } from '../../types';
import BaseTransformer from './BaseTransformer';

class AuthErrorTransformer extends BaseTransformer {
  public transformOut(data: any): { errors: IAuthError } {
    const { code: responseCode, message, name: responseCodeName } = data;
    return {
      errors: {
        message,
        responseCode,
        responseCodeName,
      },
    };
  }
}

export default AuthErrorTransformer;
