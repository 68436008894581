import BaseMembership from './BaseMembership';
import Feedback, { IFeedback } from '../../SmartQuiz/results/Feedback';
import UpgradeInfo from '../../SmartQuiz/results/UpgradeInfo';
import {
  // UpgradeMoreContainer,
  UnsubscribedResults,
  FeedbackContainer,
  FeedbackTextContainer,
} from '../../SmartQuiz/results/unSubscribedStyled';
import { studySpacePath } from '../../Router/paths';
import { en } from '../../../i18n';

const { placementTest } = en;

const { infoTrialling, upgradeInfo } = placementTest;

class BaseLineTestReviewResults extends BaseMembership {
  public free() {
    // const location = super.getLocation();
    // if (location && studySpacePath.includes(location)) return <Feedback {...(super.getContext() as IFeedback)} />;
    const upgradeInfoOverrides = {
      isTrialing: false,
      header: upgradeInfo.freeMember.header,
      subTitleA: upgradeInfo.freeMember.subTitleA,
      subTitleB: upgradeInfo.freeMember.subTitleB,
      info: infoTrialling,
    };

    return (
      <UnsubscribedResults>
        <Feedback
          {...(super.getContext() as IFeedback)}
          overrides={{
            ResultsContainer: FeedbackContainer,
            ResultsTextContainer: FeedbackTextContainer,
          }}
        />
        <UpgradeInfo {...upgradeInfoOverrides} />
      </UnsubscribedResults>
    );
  }
  public essential() {
    return <Feedback {...(super.getContext() as IFeedback)} />;
  }
  public allAccess() {
    return this.essential();
  }

  public trialling() {
    const upgradeInfoOverrides = {
      isTrialing: true,
      header: upgradeInfo.trialling.header,
      subTitleA: upgradeInfo.trialling.subTitleA,
      subTitleB: upgradeInfo.trialling.subTitleB,
      info: infoTrialling,
    };

    //return this.free();
    return (
      <UnsubscribedResults>
        <Feedback
          {...(super.getContext() as IFeedback)}
          overrides={{
            ResultsContainer: FeedbackContainer,
            ResultsTextContainer: FeedbackTextContainer,
          }}
        />
        <UpgradeInfo {...upgradeInfoOverrides} />
      </UnsubscribedResults>
    );
  }
}
export default BaseLineTestReviewResults;
