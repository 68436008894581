import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { opacityInAnimation, scrollbar, Tag } from '../sharedStyled';
import { shouldForwardProps } from '../utils';
//import { Tag } from '../sharedStyled';

interface IProps {
  leftPadding?: string;
}

interface ICaseStudyContainerProps {
  isPastQuiz?: boolean;
}

interface IAnalysisTagProps {
  backgroundColor: string;
  textColor: string;
}

interface ITitleCell {
  width?: number;
  isCentered?: boolean;
}

interface IMasteryLevelBar {
  isFilled?: boolean;
}

interface IInfoContainer {
  fullWidth?: boolean;
}

const Banner = styled('div', { shouldForwardProp: prop => shouldForwardProps(prop, ['leftPadding']) })<IProps>(
  ({ theme, leftPadding }) => `
  width: 100%;
  height: fit-content;
  background: ${colors.mediumLightGray};
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  padding: 12px ${leftPadding || '23.5%'};
  position: fixed;
  top: 0;
  & div:first-child {
    margin-bottom: ${theme.spacing(0.8)};
  }
  h1 {
    margin-bottom: ${theme.spacing(1)};
  }
  button {
    margin-right: ${theme.spacing(2.5)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: 12px ${leftPadding || '5%'};
    width:95%;
  }

  ${theme.breakpoints.between('sm', 'md')} {
    padding: 12px 10%;
  }

  ${theme.breakpoints.between('sm', 'md')} {
    padding: 12px 10%;
  }
`,
);

const ChoicesContainer = styled('div')`
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  ${props => props.theme.breakpoints.down('sm')} {
    > span {
      &:first-child {
        display: none;
      }
    }
  }

  ${props => props.theme.breakpoints.down('$xs')} {
    gap: 2px;
  }

  ${props => props.theme.breakpoints.up('$xs')} {
    gap: ${props => props.theme.spacing(2)};
  }
`;

const Choice = styled('div')`
  display: flex;
  align-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    span {
      font-size: 0.9rem;
    }
    .MuiRadio-root {
      padding-left: 0px;
      padding-right: 2px;
    }
  }
`;

const QuestionsContainer = styled('div')`
  height: 100%;
  margin-top: calc(160px + 5vh);
  margin-left: 10px;
  margin-right: 10px;
  max-height: calc(100vh - (160px + 5vh));
  overflow-y: auto;
  ${scrollbar}
  ${props => props.theme.breakpoints.down(361)} {
    margin-top: calc(160px + 15vh);
    max-height: calc(100vh - (160px + 15vh));
  }
  ${props => props.theme.breakpoints.down('sm')} {
    max-height: calc(100vh - (170px + 13vh));
  }
  p {
    margin-top: ${props => props.theme.spacing(0.5)};
  }
  > div:nth-child(1) {
    > div {
      padding-top: 0;
    }
  }

  }

`;

const CaseStudyContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isPastQuiz']),
})<ICaseStudyContainerProps>(
  ({ theme, isPastQuiz }) => `
  background-color: ${colors.lightestGrey};
  height: 100%;
  margin-top: 166px;
  margin-right: 8px;
  padding-right: 32px;
  padding-left: 40px;
  max-height: calc(100vh - (120px + 5vh));
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar}
  h2 {
    margin-top: 20px;
  }
  ${
    isPastQuiz
      ? `
    &&& {
      margin-top: 0;
      margin-bottom: 0;
      width: calc(100% - 135px);
      ${theme.breakpoints.down('sm')} {
        margin: 0;
        width: 100%;
      }
    }
  `
      : ''
  }
`,
);

const MessageContainer = styled('div')`
  text-align: center;
`;

const QuestionsWrapper = styled('div')`
  background-color: ${colors.lightestGrey};
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: -1;
  width: 100%;
`;

const SubquestionContainer = styled('div')`
  box-shadow: 0px 0px 40px ${colors.purple10};
  border-radius: 16px;
  margin-bottom: 80px;
  background-color: ${colors.lightestGrey};
  > div:nth-child(1) {
    height: 625px;
    ${props => props.theme.breakpoints.down('xmd')} {
      height: unset;
    }
    > div:nth-child(1) {
      padding-top: 64px;
    }
    > div:nth-child(2) {
      padding-top: 64px;
      ${props => props.theme.breakpoints.down('xmd')} {
        padding-top: 0;
      }
    }
  }
  > div:nth-child(2) {
    border-radius: 0 0 16px 16px;
    padding: 64px 40px 64px 40px !important;
    ${props => props.theme.breakpoints.down('sm')} {
      padding: 12px !important;
      padding-bottom: 40px !important;
    }
  }
  [data-rbd-draggable-id] {
    font-size: 0.65rem;
  }
`;

const CaseStudySelection = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 67px;
  margin-bottom: 24px;
  > div:nth-child(1) {
    font-size: 1.125rem;
    font-weight: 700;
    width: 181px;
  }
  > div:nth-child(2) {
    width: 32%;
    ${props => props.theme.breakpoints.down('sm')} {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
`;

const ResultsContainer = styled('div')`
  position: fixed;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: ${colors.lightestGrey};
  z-index: -2;
`;

const TagsContainer = styled('div')`
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  gap: 10px;
`;

const AnalysisContainer = styled('div')`
  width: 100%;
  ${opacityInAnimation()};
`;

const FreeAnalysisContainer = styled(AnalysisContainer)`
  ${props => props.theme.breakpoints.down('lg')} {
    margin-top: 46px;
  }
  ${props => props.theme.breakpoints.down('$md')} {
    margin-top: 46px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 59px;
  }
`;

const AnalysisBanner = styled('div')``;

const TitleContainer = styled('div')`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 12px;
    justify-content: center;
  }
`;

const AnalysisPageContainer = styled('div')`
  padding-top: ${props => props.theme.spacing(4)};
  padding-bottom: 21px;
  padding-left: ${props => props.theme.spacing(5)};
  padding-right: ${props => props.theme.spacing(5)};
  min-height: 100vh;

  ${props => props.theme.breakpoints.down('lg')} {
    padding-left: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(4)};
  }
  ${props => props.theme.breakpoints.down('md')} {
    padding-left: ${props => props.theme.spacing(3)};
    padding-right: ${props => props.theme.spacing(3)};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;

const QuizTitle = styled('h1')`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const PageWrapper = styled('div')`
  background-color: ${colors.lightestGrey};
  padding-bottom: 60px;
`;

const DatesContainer = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(5)};
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

const DateContainer = styled('span')`
  font-size: 1rem;
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  font-weight: 600;
  align-items: center;
`;

const AnalysisTagsContainer = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing(5)};

  ${props => props.theme.breakpoints.down('lg')} {
    gap: 20px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 20px;
  }
`;

const AnalysisTagContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['leftPadding', 'backgroundColor', 'textColor']),
})<IAnalysisTagProps>(
  ({ theme, backgroundColor, textColor }) => `
    background:${backgroundColor};
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    color:${textColor};
    h3, h4{
     color:${textColor};
    }
    h4 {
      display: flex;
      align-items: flex-start;
    }
    padding-top:12px;
    padding-bottom:21px;
    padding-left:16px;
    padding-right:16px;
    display:flex;
    flex-direction:column;
    gap:20px;
    `,
);

const AnalysisBodyTagText = styled('h3')`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 4px;
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const AnaylsisTitleTagText = styled('h4')`
  font-size: 0.875rem;
  font-weight: 600;
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const QuizProgressContainer = styled('div')`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 4px;
  }
`;

const ProgressFooter = styled('div')`
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
  span {
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

const SectionContainer = styled('div')`
  margin-top: 56px;
`;

const TopicsAnalysisTitle = styled('h2')`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const RecommendationdationCardContainer = styled('div')`
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
`;

const RecommendationTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h2 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
`;

const RecommendationCardsContainer = styled('div')`
  width: 100%;
  display: flex;
  gap: ${props => props.theme.spacing(4)};
  margin-top: ${props => props.theme.spacing(3)};
  justify-content: space-between;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const ChartsContainer = styled(RecommendationCardsContainer)`
  ${props => props.theme.breakpoints.down('xl')} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const IconContainer = styled('div')`
  flex-shrink: 0;
`;

const TopicsTableRow = styled('div')`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: ${props => props.theme.spacing(2)};
  align-items: center;
`;

const TopicsTableContentRow = styled(TopicsTableRow)`
  background-color: ${colors.white};
  border-radius: 8px;
  margin-bottom: ${props => props.theme.spacing(0.5)};
`;

const TitleCell = styled('span', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['width', 'isCentered']),
})<ITitleCell>(
  ({ width, isCentered, theme }) => `
  width:${width ? `${width}%` : 'auto'};
  font-size:0.875rem;
  font-weight:700;
  text-transform:uppercase;
  color:${colors.grey400};
  text-align:${isCentered ? 'center' : 'left'};
  ${theme.breakpoints.down('md')}{
    width:${width ? (width < 5 ? '5%' : `${width}%`) : 'auto'};
  }
  
  `,
);

const ContentCell = styled(TitleCell)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.black10};
  text-transform: none;
  overflow-wrap: break-word;
  &::first-letter {
    text-transform: capitalize;
  }
  h1 {
    font-size: 0.875rem;
  }
`;

const MasteryLevelContainer = styled('div')`
  display: flex;
  gap: 6px;
  justify-content: center;
`;

const MasteryLevelBar = styled('span', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isFilled']),
})<IMasteryLevelBar>(
  ({ isFilled }) => `
width: 6px;
height: 31px;
background-color:${isFilled ? colors.primary : colors.grey600};
border-radius:8px;
`,
);

const MobileTopicCardsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MobileTopicCard = styled('div')`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.white};
`;

const InfoContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['fullWidth']),
})<IInfoContainer>(
  ({ fullWidth }) => `
  display: flex;
  gap: 4px;
  flex-direction:column;
  width:${fullWidth ? '100%' : 'auto'};
  h5 {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  h1 {
    font-size: 0.875rem;
  }
`,
);

const MobileTopicCardRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const QuestionTypesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
`;

const QuestionTag = styled(Tag)`
  font-size: 0.8rem;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('sm')} {
    width: auto;
  }
`;

const QuestionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const MarksHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export {
  Banner,
  ChoicesContainer,
  Choice,
  QuestionsContainer,
  MessageContainer,
  QuestionsWrapper,
  CaseStudyContainer,
  SubquestionContainer,
  CaseStudySelection,
  ResultsContainer,
  TagsContainer,
  AnalysisContainer,
  FreeAnalysisContainer,
  AnalysisBanner,
  AnalysisPageContainer,
  QuizTitle,
  PageWrapper,
  TitleContainer,
  DatesContainer,
  DateContainer,
  AnalysisTagContainer,
  AnalysisBodyTagText,
  AnaylsisTitleTagText,
  AnalysisTagsContainer,
  QuizProgressContainer,
  ProgressFooter,
  SectionContainer,
  TopicsAnalysisTitle,
  RecommendationCardsContainer,
  RecommendationdationCardContainer,
  RecommendationTextContainer,
  IconContainer,
  TopicsTableRow,
  TopicsTableContentRow,
  TitleCell,
  ContentCell,
  MasteryLevelBar,
  MasteryLevelContainer,
  ChartsContainer,
  MobileTopicCardsContainer,
  MobileTopicCardRow,
  MobileTopicCard,
  InfoContainer,
  QuestionTypesContainer,
  QuestionTag,
  QuestionHeader,
  MarksHeader,
};
