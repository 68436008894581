import Axios from 'axios';
import { CurrentProfile } from '../classes';
import store from '../redux/store';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { actions as authActions } from '../Auth/redux/slice';
import { actions as studySpaceActions } from '../StudySpace/redux/slice';

type TRefreshResult = {
  idToken: {
    jwtToken?: string;
  };
};

class RequestMapCounter {
  private static requestMap: { [key: string]: number } = {};
  private static maxTries = 2;

  static addRequest(url: string) {
    if (RequestMapCounter.requestMap[url]) {
      RequestMapCounter.requestMap[url] += 1;
    } else {
      RequestMapCounter.requestMap[url] = 1;
    }
  }

  static isMaxReached(url: string) {
    const value = RequestMapCounter.requestMap[url];
    if (!value) return false;
    return value % RequestMapCounter.maxTries === 0;
  }

  static removeURL(url: string) {
    delete RequestMapCounter.requestMap[url];
  }
}

const run = () => {
  Axios.interceptors.response.use(
    response => response,
    async error => {
      const isResponsePresent = !!error.response;
      const isUnauthorized = !isResponsePresent || [401, 403].includes(error.response.status);
      const isConfigPresent = !!error.config;
      if (!isUnauthorized || !isConfigPresent) return Promise.reject(error);
      const config = error.config;
      try {
        if (RequestMapCounter.isMaxReached(config.url)) {
          // Max retries reached go to catch
          RequestMapCounter.removeURL(config.url);
          return Promise.reject(error);
        }
        const cognitioUser: CognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession: CognitoUserSession = await Auth.currentSession();
        const result = await new Promise((resolve, reject) => {
          cognitioUser.refreshSession(currentSession.getRefreshToken(), (err: any, result: TRefreshResult) => {
            if (err) {
              RequestMapCounter.removeURL(config.url);
              store.dispatch(authActions.logOut());
              store.dispatch(studySpaceActions.resetState());
              CurrentProfile.logout();
              reject();
            }

            const idToken = result?.idToken.jwtToken || '';
            store.dispatch(authActions.refreshIdToken({ idToken }));
            CurrentProfile.updateToken(idToken);
            resolve(idToken);
          });
        });

        if (!result) {
          RequestMapCounter.removeURL(config.url);
          return Promise.reject(error);
        }

        config.headers = {
          ...config.headers,
          Authorization: result,
        };

        RequestMapCounter.addRequest(config.url);

        return Axios(config);
      } catch (e: any) {
        if (e === 'The user is not authenticated') {
          store.dispatch(authActions.logOut());
          store.dispatch(studySpaceActions.resetState());
          CurrentProfile.logout();
        }
        RequestMapCounter.removeURL(config.url);
        return Promise.reject(error);
      }
    },
  );
};

export default { run };
