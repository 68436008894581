import { EStripeDeclineCodes } from '../../types';

// Move those to enums
interface IPaymentError {
  code: string;
  message: string;
  type: string;
  declineCode?: string;
}
class PaymentError extends Error {
  public code: string;
  public message: string;
  public type: string;

  constructor({ code, message, type, declineCode }: IPaymentError) {
    super(message);
    this.message = message;
    if (declineCode === EStripeDeclineCodes.TEST_MODE) this.message = 'Your card number is invalid.';
    this.code = code;
    this.type = type;
  }
}

export default PaymentError;
