import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Address from './Address';
import { FormTitleBottomMargin, FieldLabel, CardDetailsContainer, TextField, ErrorMessageNoMargin } from './styled';
import { en } from '../../../i18n';
import { IAddress } from '../../types';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

const {
  paymentForm: { title, titlePayment, card, name, poweredBy },
} = en.billing;

interface ICardSection {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCard: (e: StripeCardNumberElementChangeEvent) => void;
  onChangeExpiry: (e: StripeCardExpiryElementChangeEvent) => void;
  onChangeCVC: (e: StripeCardCvcElementChangeEvent) => void;
}

const CardSection = ({
  handleChange,
  formState,
  errors,
  handleAddressFieldChange,
  handleCountryChange,
  onChangeCard,
  onChangeExpiry,
  onChangeCVC,
}: ICardSection & IAddress) => {
  return (
    <>
      <FormTitleBottomMargin>{titlePayment}</FormTitleBottomMargin>
      <FieldLabel isError={!!errors.card}>{card} * </FieldLabel>
      <CardDetailsContainer isError={!!errors.card}>
        <CardNumberElement onChange={onChangeCard} options={{ showIcon: true }} />
        <CardExpiryElement onChange={onChangeExpiry} />
        <CardCvcElement onChange={onChangeCVC} />
        {errors.card && <ErrorMessageNoMargin>{errors.card}</ErrorMessageNoMargin>}
      </CardDetailsContainer>

      <FieldLabel>{name} *</FieldLabel>
      <TextField name="name" value={formState.name} required onChange={handleChange} />
      <Address
        formState={formState}
        errors={errors}
        handleAddressFieldChange={handleAddressFieldChange}
        handleCountryChange={handleCountryChange}
      />
    </>
  );
};

export default CardSection;
