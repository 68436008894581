import BaseContent from './BaseContent';
import { en } from '../../../../../i18n';
const { readinessAssesement } = en.dashboard;

const { active } = readinessAssesement;

class Active extends BaseContent {
  public body(): string {
    return active.body;
  }
  public buttonText(): string {
    return active.startButton;
  }
  public logicFunction(): (e?: any) => void {
    return () => this.functions.navigateToReadiness(true);
  }
}

export default Active;
