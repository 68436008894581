import { useState, ChangeEvent } from 'react';
import { StyledTextField, InputFieldIcon, ForgetPassword, FieldHelperText } from './styled';
import { IPasswordField } from './types';
import InputAdornment from '@mui/material/InputAdornment';
import { forgetPasswordPath } from '../../../Router/paths';

import { ReactComponent as ShowPassword } from '../../../../images/icons/signup/showPassword.svg';
import { ReactComponent as HidePassword } from '../../../../images/icons/signup/hidePassword.svg';
import { en } from '../../../../i18n';
import { Link } from 'react-router-dom';

const { form } = en;

const PasswordField = ({
  label,
  required,
  helperText,
  onFocus,
  onBlur,
  isFocused,
  fieldKey,
  isEmpty,
  onChange,
  value,
  showForgetPassword,
  error,
  renderErrors,
  onTouchStart,
  fullWidth,
}: IPasswordField) => {
  const [togglePasswordView, setTogglePasswordView] = useState(false);

  const endAdornment = (
    <InputAdornment position="end">
      {togglePasswordView ? (
        <InputFieldIcon>
          <HidePassword onClick={() => setTogglePasswordView(false)} />
        </InputFieldIcon>
      ) : (
        <InputFieldIcon>
          <ShowPassword onClick={() => setTogglePasswordView(true)} />{' '}
        </InputFieldIcon>
      )}
    </InputAdornment>
  );
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const renderHelperText = () => {
    return (
      <>
        {renderErrors?.()}
        <FieldHelperText>{helperText}</FieldHelperText>
      </>
    );
  };

  const passwordField = (
    <StyledTextField
      className={`form-field form-field-${fieldKey}`}
      label={label}
      InputLabelProps={{ className: 'form-field-label' }}
      type={togglePasswordView ? 'text' : 'password'}
      required={required}
      helperText={renderHelperText()}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocused={isFocused}
      InputProps={{ autoComplete: 'new-password', endAdornment }}
      autoComplete="new-password"
      isEmpty={isEmpty}
      onChange={onChangeField}
      value={value}
      error={error}
      fullWidth={fullWidth}
      {...(onTouchStart && { onTouchStart })}
    />
  );

  const passwordFieldWithForgetPassword = (
    <>
      {passwordField}
      {
        <ForgetPassword>
          <Link to={forgetPasswordPath}>{form.forgotPassword}</Link>
        </ForgetPassword>
      }
    </>
  );

  if (showForgetPassword) return passwordFieldWithForgetPassword;
  return passwordField;
};

export default PasswordField;
