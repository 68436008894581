import { EEnviornment } from '../types';

class Domains {
  private static PRODUCTION_HOST = 'smartn.ca';
  private static LOCAL_HOST = 'localhost';
  private static TEST_INSTANCE = '.blue';
  private static testInstanceRegExp = /(?<testInstanceName>.+)-app\.(.*)/;

  static isProductionDomain() {
    return window.location.host.includes(Domains.PRODUCTION_HOST);
  }

  static getEnviornment() {
    const { host } = window.location;
    switch (true) {
      case host.includes(Domains.PRODUCTION_HOST):
        return EEnviornment.PRODUCTION;
      case host.includes(Domains.TEST_INSTANCE):
        return EEnviornment.TEST_INSTANCE;
      default:
        return EEnviornment.DEVELOPMENT;
    }
  }

  static getTestInstanceName() {
    if (this.getEnviornment() !== EEnviornment.TEST_INSTANCE) return '';
    const match = window.location.host.match(Domains.testInstanceRegExp);
    return match?.groups?.testInstanceName || '';
  }
}

export default Domains;
