import { ILooseObject } from '../types';

interface IRegistry {
  [key: string]: string;
}

class ImageRegistry {
  public static instance: ImageRegistry;
  public static registry: IRegistry = {};

  constructor() {
    if (ImageRegistry.instance) return ImageRegistry.instance;
    ImageRegistry.instance = this;
    return this;
  }

  public setImage(key: string, file: string) {
    ImageRegistry.registry[key] = file;
  }

  public getImage(key: string) {
    return ImageRegistry.registry[key];
  }

  public deleteImage(key: string) {
    if (ImageRegistry.registry[key]) delete ImageRegistry.registry[key];
  }
}

export default ImageRegistry;
