import { colors } from '../../../themes';
import { styled } from '@mui/material/styles';

const UnstartedQuizContainer = styled('div')`
  width: calc(50% - 24px);
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 24px;
  button {
    padding: 8px 16px 8px 16px;
    width: fit-content;
    font-size: 0.875rem;
    span {
      font-weight: 700;
    }
  }
  h1 {;
    margin-bottom: 16
    margin-top: 24pxpx;
    font-weight: 700;
    font-size: 1rem;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 24px;
  }
  box-shadow: 0px 0px 4px 0px #0000001a;
  border-radius: 8px;
  ${props => props.theme.breakpoints.down('sm')} {
      width: 100%;
      align-items:center;
      gap: 12px;
      flex-direction: column;
      button {
        width:100%;
         height: fit-content;
      }
    }
`;

const UnstartedQuizContainerFlat = styled(UnstartedQuizContainer)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  span {
  }
  h1 {
    margin-top: 0;
  }
  button {
    height: fit-content;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 12px;
  }
`;

const IconTextContainer = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 50%;
  svg {
    width: 240px;
    height: auto;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }

  ${props => props.theme.breakpoints.down('xsm')} {
    flex-direction: column;
    svg {
      width: 120px;
    }
  }
`;

const ButtonContinerFlat = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  alrign-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    button {
      width: 100%;
    }
  }
`;

const UnstartedQuizzesContainer = styled('div')`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export {
  UnstartedQuizContainer,
  UnstartedQuizzesContainer,
  UnstartedQuizContainerFlat,
  IconTextContainer,
  ButtonContinerFlat,
};
