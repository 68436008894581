import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { opacityInAnimation } from '../../sharedStyled';
import { shouldForwardProps } from '../../utils';
import { ContentTitle } from '../Components/styled';

interface IQuizCard {
  hideCursor?: boolean;
  applyFlex?: boolean;
}
const ContinueQuizLeftContent = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
  ${props => props.theme.breakpoints.down('xsm')} {
    flex-direction: column;
  }
`;

const ContinueQuizContent = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  align-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 16px;
  }
`;

const QuizCard = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['hideCursor', 'applyFlex']),
})<IQuizCard>(
  ({ hideCursor, applyFlex, theme }) => `
  background-color: ${colors.lightestGrey};
  border: 1px solid rgba(226, 226, 231, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: ${hideCursor ? 'default' : 'pointer'};
  min-height: 130px;
  box-sizing: border-box;
  ${applyFlex ? 'display:flex;' : ''}
  position: relative;
  transition: all 0.2s ease-in-out;


  padding: 24px;
  ${theme.breakpoints.down('xsm')} {
      padding:12px;
  }
  h3 {
    font-size: 0.875rem;
    color: ${colors.charcoal};
    margin-bottom: 4px;
    font-weight: 600;
    margin-top: 0;
  }

  h2 {
    font-size: 1rem;
    color: ${colors.black10};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 4px;
  }

  span {
    color: ${colors.grey500};
    font-size: 0.875rem;
    font-weight: 600;

  } 
`,
);

const ButtonContainer = styled('div')`
  span {
    color: ${colors.primary} !important;
    font-weight: 700;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    button {
      width: 100%;
      padding: 6.5px 16px;
    }
  }
`;

const PastQuizzesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopicsTitle = styled('h3')`
  margin: 0;
  font-size: 1rem;
  color: ${colors.black};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    cursor: pointer;
  }

  span {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  ${props => props.theme.breakpoints.down('$xs')} {
    flex-direction: column;
  }
`;

const TitleContainerLargeMargin = styled(TitleContainer)`
  margin-bottom: 40px;
`;

const TitleContainerSmallMargin = styled(TitleContainer)`
  margin-bottom: 12px;
`;

const StartQuizContentContainer = styled('div')`
  .message-container {
    margin-bottom: 40px;
  }
`;

const SelectAllButtonContainer = styled('div')`
  text-transform: uppercase;
  span {
    color: ${colors.blue400};
    font-size: 0.75rem !important;
  }
  font-size: 0.75rem !important;
`;

const StartButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  span {
    margin: unset;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    button,
    span {
      width: 100%;
    }
  }
`;

const TopicsNumberInfo = styled('div')`
  color: ${colors.black10};
  font-size: 1rem;
  font-weight: 600;
`;

const ClearAllButtonContainer = styled(SelectAllButtonContainer)`
  span,
  p {
    color: ${colors.errorRed};
    font-size: 0.75rem !important;
  }
  font-size: 0.75rem !important;
`;

const ContinueTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 12px;
  }
  button {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ContinueContentTitle = styled(ContentTitle)`
  margin: 0;
`;

export {
  ContinueQuizContent,
  ContinueQuizLeftContent,
  QuizCard,
  ButtonContainer,
  PastQuizzesContainer,
  TopicsTitle,
  StartQuizContentContainer,
  TitleContainer,
  SelectAllButtonContainer,
  TitleContainerLargeMargin,
  TitleContainerSmallMargin,
  StartButtonContainer,
  TopicsNumberInfo,
  ClearAllButtonContainer,
  ContinueTitleContainer,
  ContinueContentTitle,
};
