import Button from '../components/Button';
import { BannerOverride, ButtonsContainer, BottomBannerContainer, FooterNote } from './styled';
import { en } from '../../i18n';
import { useNavigations, useMembershipProxy } from '../hooks';
import { EAddons } from '../types';

const { unstartedQuiz } = en.readinessAssesment;

interface IBottomBanner {
  startQuiz: () => void;
}

const BottomBanner = ({ startQuiz }: IBottomBanner) => {
  const { navigateToDashboard } = useNavigations();
  const { quizYourself } = useMembershipProxy({ requiredAddons: [EAddons.smartCLEX] });
  const { buttonDisabled, buttonDisableText } = quizYourself.run();

  return (
    <BottomBannerContainer>
      {/* <FooterNote>{unstartedQuiz.footerNote}</FooterNote> */}
      <BannerOverride bottom>
        <ButtonsContainer>
          <Button onClick={navigateToDashboard} variant="primary">
            {' '}
            {unstartedQuiz.goBack}
          </Button>
          <Button disabled={buttonDisabled} onClick={startQuiz} variant="primary">
            {buttonDisabled ? buttonDisableText : unstartedQuiz.startButton}
          </Button>
        </ButtonsContainer>
      </BannerOverride>
    </BottomBannerContainer>
  );
};

export default BottomBanner;
