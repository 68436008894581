import { useSelector } from 'react-redux';
import { quizSelectors } from '../StudySpace/redux/slice';
import { LiveAttemptTransformer } from '../classes';
import { useRef } from 'react';
import { IAttempt } from '../types';
import { cloneDeep } from 'lodash';

const useTransformAttemptFromStore = (condition: boolean = false) => {
  const { attempts, loadingAttempts, currentAttempt: stored, questionAnswers } = useSelector(quizSelectors.allState);

  const attemptsTransformer = new LiveAttemptTransformer(questionAnswers);
  const transformedAttempt = useRef<IAttempt | undefined>();

  const getCurrentAttemptIndex = () => {
    if (!attempts) return;
    const currentAttemptIndex = [...attempts].reverse().findIndex(attempt => attempt.attemptId === stored?.attemptId);
    if (currentAttemptIndex == -1) {
      return attempts.length;
    }
    return currentAttemptIndex;
  };

  const { current: currentAttempt } = transformedAttempt;
  if (!loadingAttempts && !currentAttempt && stored && !condition && attempts) {
    const newAttempt = cloneDeep(stored);
    newAttempt.progress = {
      answeredQuestions: 0,
      totalQuestions: 0,
      percentage: 0,
      totalMark: 0,
      totalMaximumMark: 0,
      grade: 0,
    };

    attemptsTransformer.mapQuestionAnswersToAttempt(newAttempt);
    transformedAttempt.current = attemptsTransformer.transform([newAttempt], getCurrentAttemptIndex())[0];
  }

  return transformedAttempt.current;
};

export default useTransformAttemptFromStore;
