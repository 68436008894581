import { NavHeaderTopContainer } from './styled';
import { en } from '../../../i18n';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { dashboardPath } from '../../Router/paths';
import { useSelector } from 'react-redux';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import { quizSelectors } from '../../StudySpace/redux/slice';
import { subscriptionsSelectors } from '../../Membership/redux/slice';
import { useMediaQuery, useTheme } from '@mui/material';
import { SideBarCardTitle } from '../../Home/SideBar/styled';
import { ESelectedSection } from './types';
import useMainNavHeader from './useMainNavHeader';
import useSecondaryNavHeader from './useSecondaryNavHeader';

const { dashboard } = en;
const { title } = dashboard.quickLinks;
interface IUseMainNavHeader {
  variant?: 'main' | 'secondary';
}

const NavHeader = ({ variant = 'main' }: IUseMainNavHeader) => {
  const { state, pathname } = useLocation<{ sectionEnum: ESelectedSection }>();
  const { loading } = useSelector(topicsSelectors.globalSelector);
  const { loadingAttempts, loadingOnBoardingAttempts, loadingPlacementAttempts } = useSelector(quizSelectors.allState);
  const { loading: loadingTopics } = useSelector(topicsSelectors.globalSelector);
  const { loading: loadingSubscriptions } = useSelector(subscriptionsSelectors.allState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xmd'));
  // Main Nav bar header Refs
  const nurseGPTRef = useRef<HTMLDivElement>(null);
  const navHeaderRef = useRef<HTMLDivElement>(null);
  const studyRef = useRef<HTMLDivElement>(null);
  const applyRef = useRef<HTMLDivElement>(null);

  //Scondary Nav bar header regs
  const practiceRef = useRef<HTMLDivElement>(null);
  const assessRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const analyzeRef = useRef<HTMLDivElement>(null);

  const main = useMainNavHeader({ pathname, nurseGPTRef, studyRef, applyRef, isMobile });
  const secondary = useSecondaryNavHeader({
    pathname,
    practiceRef,
    assessRef,
    reviewRef,
    analyzeRef,
    isMobile,
    applyRef,
  });

  const isSecondary = variant === 'secondary';

  const { offsetTop, getRef, renderSections } = isSecondary ? secondary : main;

  const isLoading =
    loading ||
    loadingAttempts ||
    loadingSubscriptions ||
    loadingTopics ||
    loadingOnBoardingAttempts ||
    loadingPlacementAttempts;

  useEffect(() => {
    if (
      !state ||
      !state.sectionEnum ||
      isLoading ||
      pathname !== dashboardPath ||
      (state.sectionEnum !== secondary.selectedTab && secondary)
    )
      return;

    const section = document.getElementById(`${state.sectionEnum}`);
    const sectionTop = section?.offsetTop || 0;

    window.scrollTo({
      behavior: 'smooth',
      top: sectionTop - offsetTop.current(),
    });
  }, [isLoading]);

  const currentRef = getRef();

  return (
    <NavHeaderTopContainer
      ref={navHeaderRef}
      width={currentRef?.current?.offsetWidth || 0}
      offset={currentRef?.current?.offsetLeft || 0}
    >
      <>
        {isMobile && <SideBarCardTitle>{title}</SideBarCardTitle>}
        {renderSections()}
      </>
    </NavHeaderTopContainer>
  );
};

export default NavHeader;
