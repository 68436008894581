import { TabContent as TabContentStyled, ContentTitle, ContentSubTitle, SmallTitleMargin } from './styled';

interface ITabContent {
  children: React.ReactElement<any> | JSX.Element[] | JSX.Element;
  title?: string;
  subTitle?: string;
  smallTitleMargin?: boolean;
}
const TabContent = ({ children, title, subTitle, smallTitleMargin }: ITabContent) => {
  const TitleComponent = smallTitleMargin ? SmallTitleMargin : ContentTitle;

  return (
    <TabContentStyled>
      {title && <TitleComponent>{title}</TitleComponent>}
      {subTitle && <ContentSubTitle>{subTitle}</ContentSubTitle>}
      {children}
    </TabContentStyled>
  );
};

export default TabContent;
