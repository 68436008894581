import { Drawer, DrawerContainer } from './styled';
import { actions, mobileHeaderSelector } from '../../redux/globalSlice';
import { useDispatch, useMembershipProxy, useScrollPosition, useSubscriptions } from '../../hooks';
import { useSelector } from 'react-redux';
import NurseGPT from './NurseGPT';
import Topics from './Topics';
import PersonalInfo from './PersonalInfo';
import { IAttempt } from '../../types';
import SmartCLEX from './SmartCLEX';
import { subscriptionsSelectors } from '../../Membership/redux/slice';
import Spinner from '../../components/Spinner';
import { useMediaQuery, useTheme } from '@mui/material';
import NavHeader from '../../Header/NavHeader';

interface ISidebarInterface {
  toggleNurseGPTModal: () => void;
  attempts: IAttempt[];
}

const SideBar = ({ toggleNurseGPTModal, attempts }: ISidebarInterface) => {
  const { dispatch } = useDispatch();
  const isSideBarOpen = useSelector(mobileHeaderSelector);
  const scrollPosition = useScrollPosition();
  const { sideBarStyled } = useMembershipProxy();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xmd'));
  const { loadingFetchAddons, addons } = useSelector(subscriptionsSelectors.allState);
  const { Container = Drawer } = sideBarStyled.run();

  const toggleSideBar = () => {
    dispatch(actions.toggleSideBar({}));
  };

  const DrawerContent = (
    <DrawerContainer>
      {/* <ReadinessAssesment /> */}
      {/* <PersonalizedQuiz /> */}
      <PersonalInfo attempts={attempts} />
      {isMobile && <NavHeader variant="secondary" />}
      <NurseGPT toggleNurseGPTModal={toggleNurseGPTModal} />
      {loadingFetchAddons ? <Spinner loading inline /> : <SmartCLEX isActive={!!addons.smartCLEX} />}

      {/* <Topics /> */}
    </DrawerContainer>
  );

  const applyDrawerTopMargin = scrollPosition < 10;
  return (
    <>
      <Container
        //container={container}
        applyMargin={applyDrawerTopMargin}
        //applyMargin
        variant="temporary"
        open={isSideBarOpen}
        onClose={toggleSideBar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}
      >
        {DrawerContent}
      </Container>
      <Container
        //applyMargin
        applyMargin={applyDrawerTopMargin}
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
        open
      >
        {DrawerContent}
      </Container>
    </>
  );
};

export default SideBar;
