import { en } from '../../i18n';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { quizSelectors, actions } from '../StudySpace/redux/slice';
import { useEffect } from 'react';
import { useDispatch, useNavigations, useTransformAttemptFromStore } from '../hooks';

import { topicsSelectors } from '../StudySpace/redux/topics/slice';
import { actions as globalActions } from '../redux/globalSlice';
import { subject } from '../StudySpace/staticConstants';

const useInitResults = () => {
  const { attempts = [], loadingAttempts, currentAttempt } = useSelector(quizSelectors.allState);
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const { loading: loadingTopics } = useSelector(topicsSelectors.globalSelector);
  const { dispatch } = useDispatch();
  const location = useLocation();
  const { navigateToDashboard } = useNavigations();
  const query = new URLSearchParams(location.search);
  const attemptId = query.get('attemptId');

  const loading = loadingAttempts || attemptId !== currentAttempt?.attemptId || loadingTopics || !allTopics;

  useEffect(() => {
    const isEmpty = allTopics && Object.keys(allTopics).length === 0;
    if (!isEmpty) return;
    dispatch(globalActions.topicsIndexRequest({ subjectID: subject.id }));
  }, []);

  useEffect(() => {
    if (attemptId && attempts.length === 0) dispatch(actions.fetchAttempt({ attemptId: attemptId }));
  }, []);

  useEffect(() => {
    if (!location.search || attempts.length === 0) return;
    const attempt = attempts.find(attempt => attempt.attemptId === attemptId);
    if (!attempt && !loading) {
      navigateToDashboard();
      return;
    }
    if (attempt && (currentAttempt?.attemptId !== attempt?.attemptId || !currentAttempt?.questionsTransformed)) {
      dispatch(actions.setCurrentAttempt({ attempt }));
    }
  }, [attempts]);

  return { currentAttempt, query, loading };
};

export default useInitResults;
