import { TimerText } from './styled';
import { useTimer } from 'react-timer-hook';

interface ITimer {
  date: Date;
}

const Timer = ({ date }: ITimer) => {
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp: date,
    onExpire: () => console.warn('onExpire called'),
  });

  const renderer = () => {
    const pad = (value?: number) => {
      if (!value) return `00`;
      if (value >= 10) return value;
      return `0${value}`;
    };

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };
  return <TimerText>⏱️ {renderer()}</TimerText>;
  //return <Countdown />;
};

export default Timer;
