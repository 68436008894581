import { ICaseStudyContent, ICaseStudyOption, ILooseObject } from '../../../types';
import { MultipleHighlightContainer, ChoicesList, ChoiceListItem } from './styled';
import ContentRenderer from './ContentRenderer';

const MultipleChoiceHighlight = ({
  header,
  choices,
  answerSubquestion,
  questionId,
  answer,
  isDone,
  correctAnswer,
  isReviewing,
}: {
  header?: ICaseStudyContent;
  choices: ICaseStudyOption[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  answer?: ILooseObject;
  isDone?: boolean;
  correctAnswer?: string[];
  isReviewing: boolean;
}) => {
  const onChange = (id: string) => {
    if (isDone) return;
    const newValue = [...(answer?.answerIds || [])];
    if (!newValue.includes(id)) newValue.push(id);
    else newValue.splice(newValue.indexOf(id), 1);
    answerSubquestion({ id: questionId, answerIds: newValue }, { id: questionId, answerIds: correctAnswer });
  };

  return (
    <MultipleHighlightContainer>
      {header && <ContentRenderer content={header} />}
      <ChoicesList>
        {choices.map((choice, index) => (
          <ChoiceListItem
            key={index}
            onClick={() => onChange(choice.id)}
            highlighted={isReviewing ? correctAnswer?.includes(choice.id) : answer?.answerIds?.includes(choice.id)}
            isReviewing={isReviewing}
          >
            <ContentRenderer content={choice.detail.content} />
          </ChoiceListItem>
        ))}
      </ChoicesList>
    </MultipleHighlightContainer>
  );
};

export default MultipleChoiceHighlight;
