import { en } from '../../../i18n';
import { PersonalizedQuizContainer, PersonalizeQuizTitle, SideBarCardTitle, BetaTag, SideBarCardBody } from './styled';
import Button from '../../components/Button';
import { GoogleTags } from '../../classes';
import { EGoogleTags } from '../../types';
import { isInternalUser } from '../../utils';
import { useAuthorization, useNavigations } from '../../hooks';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { useSelector } from 'react-redux';
import { quizSelectors } from '../../StudySpace/redux/slice';
import { ReactComponent as Icon } from '../../../images/icons/dashboard/nurseGPT.svg';

interface INurseGPTInterface {
  toggleNurseGPTModal: () => void;
}

const { nurseGPTTitle, beta, askNursingQuestion, nurseGPTBody } = en.dashboard.nurseGPT;
const googleTagManager = new GoogleTags(EGoogleTags.NURSE_GPT_CLICK);

const NurseGPT = ({ toggleNurseGPTModal }: INurseGPTInterface) => {
  const {
    state: { user },
  } = useAuthorization();
  const { navigateToNurseGPT } = useNavigations();

  const { loadingAttempts } = useSelector(quizSelectors.allState);
  const handleClick = () => {
    if (isInternalUser(user?.attributes?.email)) navigateToNurseGPT();
    else {
      googleTagManager.fireEvent();
      toggleNurseGPTModal();
    }
  };

  return (
    <PersonalizedQuizContainer smallMargin>
      <NurseGPTIcon />
      <SideBarCardTitle>
        {nurseGPTTitle}
        {/* <BetaTag>{beta}</BetaTag> */}
      </SideBarCardTitle>
      <SideBarCardBody>{nurseGPTBody}</SideBarCardBody>
      <Button variant="secondary" onClick={handleClick} disabled={loadingAttempts} fullWidth size="small">
        {askNursingQuestion}
      </Button>
    </PersonalizedQuizContainer>
  );
};

export default NurseGPT;
