import Button from '../../components/Button';
import CircularProgressBar from '../../components/CircularProgressBar';
import { useMembershipProxy, useNavigations } from '../../hooks';
import { IAttempt } from '../../types';
import { ButtonContainer, ContinueQuizContent, ContinueQuizLeftContent, QuizCard } from '../PracticeQuiz/styled';
import { en } from '../../../i18n';

const { nclex } = en.dashboard.practiceQuiz;

const { quizCardSubTitle, reviewAnswers, continueButton, start, defaultTitle } = nclex;

interface IReadinessQuizCard {
  attempt?: IAttempt;
}

const ReadinessQuizCard = ({ attempt }: IReadinessQuizCard) => {
  const { continueQuizButtons } = useMembershipProxy();
  const { buttonDisabled, buttonDisabledText } = continueQuizButtons.run();
  const {
    navigateToContinueQuiz,
    navigateToResultsAnswers,
    navigateToMembershipUnconditional,
    navigateToReadiness,
  } = useNavigations();

  const renderCompleteButton = () => {
    if (!attempt) {
      return (
        <Button size="small" variant="secondary" onClick={() => navigateToReadiness(true)}>
          {start}
        </Button>
      );
    }
    if (attempt.isDone)
      return (
        <Button size="small" variant="secondary" onClick={() => navigateToResultsAnswers(attempt)}>
          {reviewAnswers}
        </Button>
      );
    if (buttonDisabled)
      return (
        <Button size="small" variant="secondary" onClick={navigateToMembershipUnconditional}>
          {buttonDisabledText}
        </Button>
      );
    return (
      <Button size="small" variant="secondary" onClick={() => navigateToContinueQuiz(attempt)}>
        {continueButton}
      </Button>
    );
  };

  return (
    <QuizCard applyFlex hideCursor key={attempt?.attemptId || 'new-readiness'}>
      <ContinueQuizContent>
        <ContinueQuizLeftContent>
          {attempt && <CircularProgressBar progress={attempt.isDone ? 100 : attempt.progress.percentage} />}
          <div>
            <h2>{attempt?.title || defaultTitle}</h2>
            <span>{quizCardSubTitle}</span>
          </div>
        </ContinueQuizLeftContent>
        <ButtonContainer>{renderCompleteButton()}</ButtonContainer>
      </ContinueQuizContent>
    </QuizCard>
  );
};

export default ReadinessQuizCard;
