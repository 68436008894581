import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { Event } from '../../types';

const SLICE_NAME = 'events';

const initialState = {
  loading: false,
  errors: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    sendEventRequest: (state, action: PayloadAction<{ event: Event }>) => {
      state.loading = true;
      state.errors = {};
    },
    sendEventSuccess: state => {
      state.loading = false;
      state.errors = {};
    },
    sendEventFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loading = false;
      state.errors = errors;
    },
  },
});

const eventsSelectors = {
  allState: (state: RootState): typeof initialState => state.events,
};

const actions = { ...slice.actions };

export { eventsSelectors, SLICE_NAME, actions };
export default slice.reducer;
