import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { ITopic, ISelectedTopic } from '../../../types';
import { RootState } from '../../../redux/store';
import { globalSelector } from '../../../redux/globalSlice';
import { categoriesToTopics } from '../../staticConstants';

const SLICE_NAME = 'categories';

const categoriesAdapter = createEntityAdapter<ITopic>();

const initialState = categoriesAdapter.getInitialState({
  selectedCategories: {} as ISelectedTopic,
  childrenTopics: categoriesToTopics,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    indexSuccess: (state, action: PayloadAction<{ entities: ITopic[] }>) => {
      const { entities } = action.payload;
      categoriesAdapter.addMany(state, entities);
    },

    setSelectedCategoryIds: (state, action: PayloadAction<{ selectedCategoryIds: string[] }>) => {
      const { selectedCategoryIds } = action.payload;
      state.selectedCategories = {};
      selectedCategoryIds.forEach(id => (state.selectedCategories[id] = true));
    },

    setSelectedCategories: (state, action: PayloadAction<{ selectedCategories: ISelectedTopic }>) => {
      const { selectedCategories } = action.payload;
      state.selectedCategories = selectedCategories;
    },
  },
});

const categoriesSelectors = {
  allState: (state: RootState): typeof initialState => state.categories,
  globalSelector: globalSelector(SLICE_NAME),
};

const actions = { ...slice.actions };

export { categoriesSelectors, SLICE_NAME, actions };
export default slice.reducer;
