import BaseTransformer from './BaseTransformer';
import { IAttemptQuestion, IAttempt, EQuestionTypes, ILooseObject, EQuizTypes, EAttemptTypes } from '../../types';
import QuestionResponseTransformer from './QuestionResponseTransformer';
import { getCaseStudyLength, isAttemptDone } from '../../utils';
import {
  onBoardingQuestionsV0,
  onBoardingQuestionsV1,
  placementTestQuestions,
  onBoardingQuestionsV2,
  readinessQuestionsV0,
  readinessQuestionsSorted,
  readinessQuestionsV1,
  onBoardingQuestionsV3,
  applyQuestionsV0,
} from '../../StudySpace/staticConstants';
import { en } from '../../../i18n';
import PopulateAnswers from '../PopulateAnswers';
import { NURSE_GPT_QUESTION_ID } from '../../constants';
import { roleQuestionId } from '../../OnBoarding/constants';

const { baseline, readiness } = en.reviewPastQuizzes;
const { smartQuiz } = en.smartQuiz;
interface IQuestionChecks {
  questionId: string;
  groupIds?: string[];
}
class AllAttemptsTransformer extends BaseTransformer {
  private questionResponseTransformer: QuestionResponseTransformer;

  public constructor() {
    super();
    this.questionResponseTransformer = new QuestionResponseTransformer();
  }

  public transform(attempts: IAttempt[], fixedIndex?: number): IAttempt[] {
    attempts.reverse().forEach((attempt, index) => {
      attempt.topicIDs = [];
      attempt.progress = {
        answeredQuestions: 0,
        totalQuestions: 0,
        percentage: 0,
        totalMark: 0,
        totalMaximumMark: 0,
        grade: 0,
      };
      attempt.isDone = isAttemptDone(attempt, true);
      attempt.isOnBoarding = this.checkOnboarding(attempt);
      attempt.onBoardingVersion = this.checkOnboardingVersion(attempt);
      attempt.isPlacement = this.checkPlacementTest(attempt);
      attempt.isReadiness = this.checkIsReadiness(attempt);
      attempt.isNurseGPT = this.checkNurseGPT(attempt);
      attempt.isApply = this.checkIsApply(attempt);
      attempt.isRolePlaceHolder = this.checkRolePlaceHolder(attempt);
      attempt.quizType = this.getAttemptType(attempt);

      attempt.questions.forEach(question => {
        this.addQuestionTopics(question, attempt);
        this.markQuestionAndTrackProgress(question, attempt);
      });
      attempt.topicIDs = [...new Set(attempt.topicIDs)];
      this.setAttemptPercentage(attempt);

      if (attempt.isReadiness) {
        this.sortReadinessQuestions(attempt);
      }
      attempt.title = this.getAttemptTitle(attempt, fixedIndex || index);
      attempt.progress.grade = attempt.progress.totalMark / attempt.progress.totalMaximumMark;
      this.setAttemptCurrentQuestionInfo(attempt);
      this.setAttemptTimers(attempt);
    });
    return attempts.reverse();
  }

  private getAttemptType(attempt: IAttempt) {
    switch (true) {
      case attempt.isOnBoarding:
        return EAttemptTypes.ONBOARDING;
      case attempt.isPlacement:
        return EAttemptTypes.PLACEMENT;

      case attempt.isReadiness:
        return EAttemptTypes.READINESS;
      case attempt.isNurseGPT:
        return EAttemptTypes.NURSE_GPT;
      case attempt.isApply:
        return EAttemptTypes.APPLY;
      case attempt.isRolePlaceHolder:
        return EAttemptTypes.ROLE_PLACEHOLDER;

      default:
        return EAttemptTypes.SMART_QUIZ;
    }
  }

  private sortReadinessQuestions(attempt: IAttempt) {
    const newSortedQuestions = readinessQuestionsSorted.map(sortedQuestion => {
      const { questionId } = sortedQuestion;
      const attemptQuestion = attempt.questions.find(question => questionId === question.questionId);
      if (!attemptQuestion) return attempt.questions[0];
      return attemptQuestion;
    });

    attempt.questions = newSortedQuestions;
  }

  private getAttemptTitle(attempt: IAttempt, index: number) {
    if (attempt.isReadiness) return `${readiness} Attempt`;
    if (attempt.isPlacement) return baseline;
    return smartQuiz;
  }

  private setAttemptPercentage(attempt: IAttempt) {
    const total = attempt.progress.answeredQuestions / attempt.progress.totalQuestions || 0;
    attempt.progress.percentage = Math.round(total * 100);
  }

  private addQuestionTopics(question: IAttemptQuestion, attempt: IAttempt) {
    const { groupIds } = question;
    attempt.topicIDs = [...attempt.topicIDs, ...groupIds];
  }

  public markQuestionAndTrackProgress(question: IAttemptQuestion, attempt: IAttempt) {
    const { response, mark } = question;
    const transformedResponse = this.questionResponseTransformer.transform(response || '');
    if (attempt.quizType === EAttemptTypes.APPLY) {
      attempt.progress.totalQuestions += 1;
      if (transformedResponse) attempt.progress.answeredQuestions += 1;
      return;
    }

    if (!mark) {
      attempt.progress.totalQuestions += 1;
      return;
    }

    const transformedMark = JSON.parse(mark);
    if (!Array.isArray(transformedMark)) {
      question.parsedMark = transformedMark;
      attempt.progress.totalQuestions += 1;
      attempt.progress.answeredQuestions += 1;
      attempt.progress.totalMark += question.parsedMark?.mark;
      attempt.progress.totalMaximumMark += question.parsedMark?.maximumMark;
    } else {
      const subAnswers = transformedMark.reduce((acc: ILooseObject, subMark: ILooseObject, index: number) => {
        const isDone = Boolean(transformedResponse[index]);
        if (isDone) attempt.progress.answeredQuestions += 1;
        acc[subMark.id] = {
          value: transformedResponse[index] || '',
          isDone,
          parsedMark: subMark,
        };
        attempt.progress.totalMark += subMark.mark;
        attempt.progress.totalMaximumMark += subMark.maximumMark;
        return acc;
      }, {});

      attempt.progress.totalQuestions += getCaseStudyLength(question.questionId);
      question.subAnswers = subAnswers;
    }
  }

  private checkOnboarding(attempt: IAttempt) {
    const isOnboarding =
      this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV0) ||
      this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV1) ||
      this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV2) ||
      this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV3);

    return isOnboarding;
  }

  private checkOnboardingVersion(attempt: IAttempt) {
    if (!attempt.isOnBoarding) return undefined;
    switch (true) {
      case this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV0):
        return 'v0';
      case this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV1):
        return 'v1';
      case this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV2):
        return 'v2';

      case this.checkAttemptQuestionsInclude(attempt.questions, onBoardingQuestionsV3):
        return 'v3';
    }
    return undefined;
  }

  private checkRolePlaceHolder(attempt: IAttempt) {
    return attempt.questions.length === 1 && attempt.questions[0].questionId === roleQuestionId;
  }

  private checkPlacementTest(attempt: IAttempt) {
    return (
      (this.checkAttemptQuestionsInclude(attempt.questions, placementTestQuestions) &&
        attempt.questions.length >= 13 &&
        attempt.questions.length < 20) ||
      attempt.questions.length === 14
    );
  }

  private checkIsReadiness(attempt: IAttempt) {
    const isReadiness =
      (this.checkAttemptQuestionsInclude(attempt.questions, readinessQuestionsV0) ||
        this.checkAttemptQuestionsInclude(attempt.questions, readinessQuestionsV1)) &&
      attempt.questions.length > 50;

    return isReadiness;
  }

  private checkIsApply(attempt: IAttempt) {
    const isApply = this.checkAttemptQuestionsInclude(attempt.questions, applyQuestionsV0);
    return isApply;
  }

  private checkNurseGPT(attempt: IAttempt) {
    return attempt.questions.length === 1 && attempt.questions[0].questionId === NURSE_GPT_QUESTION_ID;
  }

  private checkAttemptQuestionsInclude(questions: IAttemptQuestion[], questionsCheck: IQuestionChecks[]) {
    return questions.every(question =>
      questionsCheck.some(questionsCheck => question.questionId === questionsCheck.questionId),
    );
  }

  public getQuestionResponseTransformer() {
    return this.questionResponseTransformer;
  }

  public setAttemptCurrentQuestionInfo(attempt: IAttempt) {
    const populateAnswers = new PopulateAnswers({}, attempt);
    attempt.currentQuestionInfo = populateAnswers.getCurrentQuestion();
  }

  private setAttemptTimers(attempt: IAttempt) {
    const { questionIndex } = attempt.currentQuestionInfo;
    const currentQuestion = attempt.questions[questionIndex];
    const { ctime } = currentQuestion;
    attempt.totalTime = new Date(ctime).getTime() - new Date(attempt.ctime).getTime();
    const timeDivisor = attempt.totalDisplayQuestions || attempt.questions.length;
    attempt.avgQuestionTime = attempt.totalTime / timeDivisor;
    if (attempt.isDone) {
      attempt.endtime = new Date(currentQuestion.ctime).getTime();
    }
  }
}

export default AllAttemptsTransformer;
