import { IStepComponent } from '../types';

import { ButtonContainer, FAQContainer, ListColumnItemOverride, ListContainerOverride, Title } from './styled';
import { en } from '../../../i18n';
import parse from 'html-react-parser';
import Button from '../../components/Button';
import { useDispatch, useNavigations } from '../../hooks';

import { ReactComponent as Practice } from '../../../images/icons/dashboard/parctice.svg';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { ListDetail, ListSubTitle, ListTitle } from '../../OnBoarding/Ready/styled';
import { actions } from '../redux/slice';
import { useMediaQuery, useTheme } from '@mui/material';

interface IExit extends IStepComponent {
  supported: boolean;
}

const { apply } = en;
const { exit } = apply;
const { notSupported, supported: supportedText } = exit;

const iconsSource = [NurseGPTIcon, Practice];

const Exit = ({ question, supported }: IExit) => {
  const { navigateToDashboard, navigateToNurseGPT } = useNavigations();
  const { dispatch } = useDispatch();
  const theme = useTheme();

  const isMobileDetails = useMediaQuery(theme.breakpoints.down('lg'));

  const reset = () => {
    dispatch(actions.setCurrentQuestionIndex({ index: 0 }));
  };

  const renderList = () => {
    const navigations = [navigateToNurseGPT, navigateToDashboard];
    const { infoList: dataSource } = exit;
    return dataSource.map((listItem, index) => {
      const Icon = iconsSource[index];
      return (
        <ListColumnItemOverride onClick={navigations[index]} key={index}>
          <ListTitle>
            <Icon />
            <div>{listItem.title}</div>
          </ListTitle>
          <ListSubTitle>{listItem.subtitle}</ListSubTitle>
          <ListDetail>{parse(isMobileDetails ? listItem.detailsMobile : listItem.details)}</ListDetail>
          {/* <ListDetail>{parse(listItem.detailsMobile)}</ListDetail> */}
        </ListColumnItemOverride>
      );
    });
  };

  const renderTitles = () => {
    if (!supported) {
      return (
        <>
          <Title>{parse(notSupported.title)}</Title>
          <Title fontSize="1.25rem" normalFontWeight>
            {notSupported.subTitleA}
          </Title>
          <Title fontSize="1.25rem" normalFontWeight>
            {notSupported.subTitleB}
          </Title>
          <Title fontSize="1.25rem" normalFontWeight>
            {notSupported.subTitleC}
          </Title>
        </>
      );
    }

    return (
      <>
        <Title normalFontWeight fontSize="1.25rem">
          {parse(supportedText.title)}
        </Title>
        <Title>{supportedText.subTitleA}</Title>
        <Title fontSize="1.25rem" normalFontWeight>
          {supportedText.subTitleB}
        </Title>
      </>
    );
  };
  return (
    <>
      {renderTitles()}
      <ListContainerOverride>{renderList()}</ListContainerOverride>
      <ButtonContainer>
        <Button onClick={reset} variant="primary">
          {exit.resetText}
        </Button>
        {/* <Button onClick={navigateToDashboard} variant="primary">
          {exit.buttonText}
        </Button> */}

        <FAQContainer>{parse(exit.faq)}</FAQContainer>
      </ButtonContainer>
    </>
  );
};

export default Exit;
