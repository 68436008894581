// To be deleted
enum ECategories {
  BASIC_CARE_COMFORT = 'basicCareAndComfort',
  HEALTH_PROMOTION_MAINTENACE = 'healthPromotionAndMaintenance',
  MANAGEMENT_OF_CARE = 'managementOfCare',
  PHYSIOLOGICAL_ADAPTATION = 'physiologicalAdaptation',
  PSYCHOSOCIAL_INTEGRITY = 'psychosocialIntegrity',
  PHARAMACOLOGICAL_PARENTAL_THERAPIES = 'pharmacologicalAndParentalTherapies',
  SAFETY_INFECTION_CONTROL = 'safetyAndInfectionControl',
  REDUCTION_RISK_POTENTIAL = 'reductionOfRiskPotential',
}

enum ETopics {
  CARDIAC = 'cardiac',
  ABG = 'Fluids & Electrolytes/Acid-Base Balance',
  MEDICATION_CALCULATION = 'medicationCalculation',
  ENDOCRINE_METABOLIC = 'endocrine_metabolic',
  ETHICAL_LEGAL = 'ethicalLegal',
  INTEGUMENTARY = 'integumentary',
  LABS = 'Vital Signs and Laboratory Values',
  IMMUNLOGY_INFECTIOUS = 'immunologyInfectious',
  GASTROINTESTINAL = 'gastrointestinal',
  RENAL_GENITOURINARY = 'renalGenitourinary',
  RESPIRATORY = 'respiratory',
  PEDIATRIC_HEALTH = 'pediatricHealth',
  MATERNAL_NEW_BORN = 'maternalNewBorn',
  PRIOTIZATION_DELEGATION = 'priotizationDelegation',
  MENTAL_HEALTH = 'mentalHealth',
  EYE_EAR_NOSE_THROAT = 'eyeEarNoseThroat',
  PHARMACOLOGY = 'pharmacology',
  HEMATOLOGIC_ONCOLOGY = 'hematologicImmunology',
  MUSCULOSKELETAL = 'musculoskeletal',
  NEURO = 'neurological',
  COMPLEX_CARE_CONCEPTS = 'complexCareConcepts',
  FUNDAMENTALS = 'fundamentals',
  oncology = 'oncology',
  tests = 'tests',
}

// To be deleted
enum ESubjects {
  NCLEX = 'NCLEX',
}

// To be deleted
enum ESources {
  NURSING = 'nursing.com',
  KLIMEK_REVIEWS = 'prep.klimekreviews.com',
  UNION_TEST_PREP = 'UnionTestPrep',
  UWORLD = 'uworld.com',
  SAUNDERS = 'saunders',
  YOURIKA = 'yourika',
}

// To be deleted

// To be deleted
enum EQuestionTypes {
  MULTIPLE_CHOICE = 'multipleChoice',
  SHORT_ANSWER = 'shortAnswer',
  NGN_CASE_STUDY = 'ngnCaseStudy',
  ARITHMETIC = 'arithmetic',
  NCLEX_STYLE_QUESTIONS = 'nclexStyle',
}

enum EDifficulty {
  easy = 'easy',
  medium = 'medium',
  hard = 'hard',
}

enum EVideoEvents {
  PAUSE = 'pause',
  PLAYING = 'playing',
  SEEKED = 'seeked',
}

enum EVideoEventsDetails {
  PAUSE = 'ORBITS Video Paused',
  PLAYING = 'ORBITS Video Played',
  SEEKED = 'ORBITS Video Seeked',
}

// TODO: Implement all auth states here
enum EAuthStates {
  LOGGED_IN = 1,
  ONBOARDED = 2,
  PLACEMENT_TEST_DONE = 3,
}

enum EProficiencyLevels {
  JUST_STARTED = 'Just Getting Started',
  SOME_UNDERSTANDING = 'Some Understanding',
  GOOD_UNDERSTANDING = 'Good Understanding',
  SOME_PROFICIENCY = 'Some Proficiency',
  PROFICIENT = 'Proficient',
  EXPERT = 'Expert',
}

enum EReadinessPassingChances {
  UNAVAILABLE = 'Unavailable',
  LOW = 'Low',
  MEDIUM = 'Somewhat Likely',
  HIGH = 'High',
}

enum EMembershipType {
  FREE,
  ESSENTIALS,
  ALL_ACCESS,
  TRIALLING,
  DEPRECATED_SUBSCRIPTION,
}
enum EGoogleTags {
  SIGN_UP = 'AppSignup',
  VERIFIED_SIGN_UP = 'VerifiedSignup',
  PURCHASE_COMPLETE = 'PurchaseComplete',
  QUIZ_COMPLETE = 'QuizComplete',
  KA = 'KAComplete',
  ONBOARDING_COMPLETE = 'OnboardingComplete',
  READINESS_ASSESMENT_CLICK = 'ReadinessAssessmentClick',
  READINESS_ASSESMENT_COMPLETE = 'ReadinessAssessmentComplete',
  NURSE_GPT_CLICK = 'NurseGPTClick',
}

// TODO: Refactor this to properly reflect state
enum EPaymentSteps {
  INPUT_FIELDS,
  TAX_CALCULATED,
  EDIT_CARD,
  VIEW_SUBSCRIPTION,
  BUY_SUBSCRIPTION,
  BUY_ADDON,
  BUY_ADDON_NO_CARD,
}

enum ESubscriptionsStatus {
  ACTIVE = 'active',
  INCOMPLETE = 'incomplete',
  TRIALING = 'trialing',
  PAST_DUE = 'past_due',
}
enum EQuizTypes {
  MULTIPLE_CHOICE = 'multipleChoice',
  NGN_CASE_STUDY = 'ngnCaseStudy',
  BOTH = 'both',
  ARITHMETIC = 'arithmetic',
  SMARTN_STYLE_QUESTIONS = 'smartNStlyeQuestions',
  ALL = 'all',
}

enum ECardBrands {
  VISA = 'visa',
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTER_CARD = 'mastercard',
}

enum EPlanTypes {
  SUBSCRIPTION,
  ADDON,
}

enum EAddons {
  smartCLEX = 'smartCLEX',
}

enum ECaseStudyClinicalJudgement {
  PRIOTIZIE_HYPOTHESES = 'Prioritize Hypotheses',
  GENERATE_SOLUTIONS = 'Generate Solutions',
  TAKE_ACTIONS = 'Take Actions',
  EVALUATE_OUTCOMES = 'Evaluate Outcomes',
  ANALYZE_CUES = 'Analyzing Cues',
  RECOGNIZE_CUES = 'Recognize Cues',
}

enum EEnviornment {
  DEVELOPMENT = 'development',
  TEST_INSTANCE = 'Test Instance',
  PRODUCTION = 'production',
}

enum EUserRole {
  UNDERGRAD = 'Undergraduate Student',
  GRAD = 'Graduate Student',
  NURSE = 'Practicing Nurse',
  INSTRUCTOR = 'Instructor',
  TUTOR = 'NCLEX Tutor',
  USER = 'User',
  JOB_SEEKER = 'Job Seeker',
  NURSING_AID = 'Nurse Aide/Assistant',
}

enum ENurseGPTRole {
  NURSING_STUDENT = 'Nursing Student',
  NCLEX_TAKER = 'NCLEX Taker',
  PRACTICING_NURSE = 'Practicing Nurse',
}

export {
  ECategories,
  ESources,
  ESubjects,
  EQuestionTypes,
  ETopics,
  EDifficulty,
  EVideoEvents,
  EVideoEventsDetails,
  EAuthStates,
  EProficiencyLevels,
  EMembershipType,
  EGoogleTags,
  EPaymentSteps,
  ESubscriptionsStatus,
  EQuizTypes,
  EReadinessPassingChances,
  ECardBrands,
  EPlanTypes,
  EAddons,
  ECaseStudyClinicalJudgement,
  EEnviornment,
  EUserRole,
  ENurseGPTRole,
};
