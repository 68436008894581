const document = `<p>
  This user agreement (&quot;agreement&quot;) is a contract between you and Soach Inc. (DBA Smart&rsquo;n) and applies
  to your subscription to and use of products and services available through the Smart&rsquo;n Platform (hereafter known
  as the &quot;Platform&quot;) and (collectively the &quot;Services&quot;). Purchase of services indicates an implicit
  agreement with the Terms of Use. If you do not agree or do not intend to abide by these stated Terms of Use, please
  refrain from purchasing or use of Smart&rsquo;n services.
</p>
<p><br /></p>
<p>User Agreement</p>
<p>
  You must read, agree with, and accept all of the terms and conditions and our privacy policy before you become a
  subscriber to and user of Smart&rsquo;n services.
</p>
<p><br /></p>
<p>
  We may amend this agreement at any time by posting the amended terms on our websites, including but not limited to
  www.smartn.ca. Except as stated below, all amended terms shall be effective 30 days after they are initially posted on
  our site. Upcoming changes will also be posted on this page.
</p>
<p><br /></p>
<p>
  In this agreement, &quot;you&quot; or &quot;your&quot; means any person or entity subscribing to and/or using the
  service (&quot;users&quot;). Unless otherwise stated, &quot;Smart&rsquo;n,&quot; &quot;we,&quot; or &quot;our&quot;
  will collectively refer to Smart&apos;n.
</p>
<p><br /></p>
<p>
  Please read the following terms, conditions, and disclaimers in this agreement carefully before subscribing to our
  material. Agreeing to the terms of use includes the following:
</p>
<p><br /></p>
<p>Age Restriction</p>
<p>In order to use Smart&apos;n services, you need to:</p>
<p><br /></p>
<ul>
  <li>
    <p>Be 13 or older</p>
  </li>
  <li>
    <p>have the power to enter a binding agreement(s) with us without being restricted by any applicable laws</p>
  </li>
</ul>
<p><br /></p>
<p>License</p>
<p>
  In consideration of and subject to your payment of the appropriate membership fee for the services you subscribe to,
  and your agreement to and compliance with the terms and conditions outlined in this agreement, Smart&rsquo;n grants to
  you a non-exclusive, non-sub licensable, non-transferable license, and right to use and access the Smart&rsquo;n
  content (the &quot;licensed materials&quot;) from multiple devices/locations with non-concurrant login access
  (&quot;different times&quot;). You are not allowed to access the Smart&rsquo;n content from multiple devices/locations
  concurrently (at the &quot;same time&quot;). Access of Smart&rsquo;n services from multiple devices/locations
  concurrently will be defined as account sharing, and appropriate action will be taken as defined in the
  &quot;Restriction Against Transfer&quot; section of this agreement.
</p>
<p><br /></p>
<p>System Requirements</p>
<p>
  You must use a Smart&rsquo;n recommended version of an operating system, Internet browser, and any other required
  software to access the licensed materials as specified in our system requirements. System Requirements are updated to
  account for advancements in technology. Smart&rsquo;n reserves the right to discontinue support for any system at any
  time. Compensation will not be provided for the inability to use or access the services on discontinued or unsupported
  systems/devices.
</p>
<p><br /></p>
<p>Software Use Terms</p>
<p>
  The Smart&apos;n software used for the question banks (Qbank), Self-Assessment (SimExam), and Computer-based Case
  Simulation (CCS) (hereafter collectively referred to as &quot;software&quot;) is designed to work with Java Runtime
  Engine (JRE) 8.0 or higher on Windows and Macintosh. Any attempt to use the Smart&apos;n software with lower versions
  of JRE constitutes a violation of the terms and conditions set forth in this agreement, and might result in unexpected
  behavior, for which Smart&apos;n cannot be held responsible or liable for any damages. The Smart&apos;n software is
  designed to access your computer system&apos;s clipboard during use. While a test is in progress, the Smart&apos;n
  software shall disable all clipboard functions of your computer system (including, but not limited to,
  copy-paste-print and save-to-disk functions). Furthermore, the Smart&apos;n software shall monitor all processes on
  your computer to determine if there exists any applications that could be used (intentionally or unintentionally) to
  copy contents. Simultaneous use of such applications (hereafter referred to as &quot;dubious applications&quot;) with
  the Smart&apos;n software constitutes a violation of this agreement. Smart&apos;n reserves the right to determine
  which applications are classified as &quot;dubious applications&quot;. When such a process or application is found to
  be active on a user&apos;s machine, the administrator will be notified with the process name. The user will also be
  notified with instructions to disable it on every attempt. In addition, Smart&apos;n reserves the right to disable the
  launch of any test(s), if any such &quot;dubious application&quot; is found to be active on the user&rsquo;s computer
  system. The user will not be able to switch between applications when Smart&apos;n software is running. The user also
  understands and acknowledges this disabled function as part of the subscription terms.
</p>
<p><br /></p>
<p>
  The content presented in the self-assessment examination serves as an educational tool only for medical students and
  graduates. The Assessment Score is calculated by Smart&apos;n based on its own proprietary algorithm. This is not
  intended to predict your performance on the actual USMLE&trade; but is designed to help you assess relative strengths
  and weaknesses in different subjects and systems of medicine.
</p>
<p><br /></p>
<p>
  Smart&apos;n will always keep your account and self-assessment results confidential and will not provide such
  information to third parties without your consent.&nbsp;
</p>
<p><br /></p>
<p>
  The Smart&apos;n software has been tested with a wide range of computer configurations; however, due to the rapidly
  evolving computer industry and unpredictability of various factors affecting computer systems, Smart&apos;n does not
  guarantee the functionality of its software on every configuration, nor does Smart&apos;n guarantee predictable
  behavior of the software in general. You recognize all of the above terms and accept that use of the Smart&apos;n
  software on your system is done solely at your own risk.
</p>
<p><br /></p>
<p>
  In order to install and use any of our software or app(s), you must have a compatible device that meets all system
  requirements.
</p>
<p><br /></p>
<p>
  You agree that Smart&apos;n will not be held liable for any damages to your devices, operating systems, and/or any
  data loss resulting from the direct or indirect installation or use of the provided software.
</p>
<p><br /></p>
<p>
  No refunds or cancellations will be honored due to your inability to install and/or use the provided software on any
  mobile device (regardless of whether or not the device meets the minimum system requirements).
</p>
<p><br /></p>
<p>
  An internet connection is required while the software is in use, and a Wi-Fi internet connection with an unlimited
  data plan is highly recommended (both unlimited upload and unlimited download). Use of a fixed data plan, limited data
  plan, and/or any other pay-per-use data plan for internet connection is not recommended. If you use a fixed, limited,
  or pay-per-use data plan, you will be solely responsible for any data charges incurred. You agree that Smart&apos;n
  will not be held liable for any data charges, overage fees, or any other miscellaneous fees or costs that result,
  directly or indirectly, from use of the software on your devices, including in the event of software malfunction.
</p>
<p><br /></p>
<p>Export Restrictions on International Sales</p>
<p>
  In accordance with current U.S. export restrictions, Smart&apos;n software may be downloaded by individuals throughout
  the world except those in the following embargoed countries: Cuba, China, Iran, Syria, North Korea, Russina and Sudan.
  If you are residing in one of these countries, you may not purchase a subscription or download Smart&apos;n software.
</p>
<p><br /></p>
<p>Reasonable Use</p>
<p><br /></p>
<p>
  Usage of Smart&apos;n subscriptions which is determined not to fall under reasonable use may result in action taken
  against the account at Smart&apos;n&apos;s sole discretion. Subscription utilization that is indicative of, or arising
  from, impermissible business, multiple user, community, or fraudulent use may result in suspension or termination of
  the subscription in question. Smart&apos;n may contact any user at any time to request clarification of a usage
  pattern which does not fall under reasonable use.
</p>
<p><br /></p>
<p>Order</p>
<p>
  Most of the information collected in the registration process will be used to process orders. During the order
  process, you will have to provide financial information such as your credit/debit card number, expiration date, CVV2
  code, payee name, and billing address. This information is used for billing purposes and to fulfill your order. To
  properly process your credit/debit card information, we must share your personal and financial information with the
  merchant bank for authorization and approval. This process is protected by an enhanced security system. We do not
  share your personal and financial information with any third parties, except those specified in our Privacy Policy.
</p>
<p><br /></p>
<p>
  When you attempt a transaction on our website, the card processor verifies available funds by placing a hold, or
  pending charge, on the amount of the intended transaction against your card. The card processor then confirms the
  accuracy of your remaining information (e.g., your address, CVV2 Code, expiration date, etc.) before processing the
  transaction. If you enter an incorrect address or other associated detail, then the transaction is declined. This is
  NOT a situation unique with this website, but it is how virtually all online transactions are handled. Typically it
  takes the card issuing bank 2-5 days to clear associated pending charges resulting from failed/declined attempts.
</p>
<p><br /></p>
<p>
  We charge for each successful transaction attempt and provide a confirmation of such on our website and send a
  confirmation email to the registered email address upon each successful transaction on our website. You can also
  log-in to your account on our website and click on &quot;Payments&quot; section to retrieve a copy of your payment
  receipt(s).
</p>
<p><br /></p>
<p>Refunds</p>
<p>
  Except as otherwise provided in this agreement or at the sole discretion of Smart&apos;n, no refunds, cancellations,
  or changes to subscriptions will be allowed. Smart&apos;n reserves the right to refuse a refund if the user is found
  to be in violation of Terms and Conditions or acting against Smart&apos;n&apos;s interest.
</p>
<p><br /></p>
<p>Member Account/Password and Security</p>
<p>
  You must complete Smart&apos;n&apos;s registration process in order to subscribe to and use the services by providing
  us with current, complete, and accurate information as prompted by the applicable registration form. You shall be
  responsible for the accuracy of the data provided and may update your profile data in order to reflect the most
  accurate current information. You will also choose a password. You are entirely responsible for maintaining the
  confidentiality of your password and account and for any and all activities that occur under your account. You agree
  to notify Smart&apos;n immediately of any unauthorized use of your account or any other breach of security.
  Smart&apos;n will not be liable for any loss that you may incur as a result of someone else using your password or
  account, either with or without your knowledge. However, you could be held liable for losses incurred by Smart&apos;n
  or another party due to someone else using your account or password.
</p>
<p><br /></p>
<p>Ownership</p>
<p>
  The licensed materials, including all intellectual property rights within the licensed materials (such as copyright)
  are the sole and exclusive property of Smart&apos;n. By indicating that you accept these terms, you do not become the
  owner of the licensed materials, but are entitled to use them according to the terms of this agreement.
</p>
<p><br /></p>
<p>Subscription Term</p>
<p>
  You have the right to use and access the licensed materials for the course(s) you have subscribed to for the duration
  of your membership period unless an interval of maintenance downtime has been scheduled. Content will be provided as
  described in the respective product description pages and may change during the course of a subscription period to
  remain relevant.&nbsp;
</p>
<p><br /></p>
<p>Accessibility</p>
<p>
  Smart&apos;n, LLC is committed to ensuring that we provide access to our electronic and information technologies,
  learning platform, and content made available through the use of our online applications for users with disabilities.
  We make reasonable efforts to follow the guidelines put forth by the World Wide Web Consortium&apos;s (W3C) Web
  Content Accessibility Guidelines 2.0 (Level AA) in accordance with section 508 of the Americans with Disabilities Act
  in order to provide accessible products and user experiences for all users.
</p>
<p><br /></p>
<p>
  To allow for the use of browser-based accessibility accommodations within a Smart&apos;n- supported browser, we
  utilize scripting languages, including JavaScript, in our online applications and device-independent event handlers.
</p>
<p><br /></p>
<p>
  We have taken steps to make our product reasonably accessible, including adjustable font sizes and colors, background
  brightness and contrast settings, and ongoing improvements to offer voiceovers and closed captions for any video
  content. We test with popular browsers and but cannot cover all or custom platforms. We hope to provide a consistent
  user experience (subject to certain limitations) across devices, regardless of input tools.
</p>
<p><br /></p>
<p>
  If you have any questions, comments, or suggestions on web accessibility issues relating to our website or mobile
  apps, please contact us at adarequest@Smart&apos;n.com.
</p>
<p><br /></p>
<p>Accommodation Request</p>
<p><br /></p>
<p>Only available for individuals enrolled in Smart&apos;n&rsquo;s products</p>
<p><br /></p>
<p>Prohibitions</p>
<p>
  You are expressly prohibited from copying, reverse engineering, or modifying any or all of the licensed materials. No
  part of the licensed materials may be copied for resale or other commercial use or offered for sale or reproduced on
  any bulletin boards, web sites, discussion forums, Internet domains, or online chatrooms. You many not use any device,
  software, or routine to interfere or attempt to interfere with the proper working of the site or any activity being
  conducted at the site. You may not disclose or share your password with any third parties or use your password for any
  unauthorized purpose.
</p>
<p><br /></p>
<p>Restriction Against Transfer</p>
<p>
  You are not allowed to sublicense, assign, share, sell, rent, lease, or otherwise transfer your right to use the
  licensed materials. All accounts at Smart&apos;n are monitored for multiple logins. In the event that Smart&apos;n
  believes at its sole discretion that an account is being used by multiple users at the same time, Smart&apos;n
  reserves the right to terminate that account without any notice or refund. We also retain the right to sue on grounds
  of breach of contract. Therefore, please do not disclose your account details to anyone else.
</p>
<p><br /></p>
<p>Violations</p>
<p>
  In the event that you breach any term of this agreement, Smart&apos;n may, at its sole discretion, terminate this
  agreement, your access to the site, and its provision of services to you without refund. Smart&apos;n reserves the
  right to seek all remedies available by law and in equity for such breaches.
</p>
<p><br /></p>
<p>No Warranties</p>
<p>
  Smart&apos;n provides its services &quot;As Is&quot; and without any warranty or condition, express, implied, or
  statutory. We specifically disclaim any implied warranties of title, merchantability, fitness for a particular
  purpose, and non-infringement.
</p>
<p><br /></p>
<p>
  Smart&apos;n does not endorse and is not responsible for, the accuracy or reliability of any opinion, advice, or
  statement made through the site by any party other than Smart&apos;n. Other than as required under consumer protection
  law, under no circumstance will Smart&apos;n be liable for any loss or damage caused by your reliance on information
  obtained through the site. It is your responsibility to evaluate the accuracy, completeness, or usefulness of any
  information, opinion, advice, or other content available through the site. Please seek the advice of professionals as
  appropriate regarding the evaluation of any specific information, opinion, advice, or other content.
</p>
<p>
  The information provided in Smart&apos;n is no substitute for individual patient assessment based upon the healthcare
  provider&apos;s examination of each patient and consideration of laboratory data and other factors unique to the
  patient
</p>
<p>
  Smart&apos;n reserves the right to withdraw subscriptions and the complete site if necessary. If this ever becomes
  necessary, you will only pay for the time of your subscription that has elapsed (on a pro-rata basis)
</p>
<p>
  Smart&apos;n will use reasonable efforts to rectify any error that occurs on its site but does not guarantee access to
  the site in case of software errors/viruses/other natural or technical catastrophes that cannot be repaired despite a
  reasonable effort
</p>
<p>
  Smart&apos;n will use reasonable efforts to keep its site online at all times. You understand that scheduled
  maintenance and problems out of the control of Smart&apos;n can cause the site to be temporarily unavailable.
</p>
<p><br /></p>
<p>Limitation of Liability</p>
<p>
  Smart&apos;n specifically disclaims any liability (whether based in contract, tort, strict liability, or otherwise)
  for any indirect, incidental, consequential, or special damages arising out of or in any way connected with access to
  or use of the site, this agreement, and our services, even if Smart&apos;n has been advised of the possibility of such
  damages, including liability associated with any medical malpractice or viruses, which may infect a user&apos;s
  computer equipment. Smart&apos;n&apos;s maximum liability arising out of or in connection with your use of its
  services and site, regardless of the cause of action (whether in contract, tort, breach of warranty or otherwise),
  will not exceed your subscription fee.
</p>
<p><br /></p>
<p>Confidentiality of User Communications</p>
<p>
  Except as required by law, Smart&apos;n will maintain the confidentiality of all user communications, which contain
  personal user information and which are transmitted directly to Smart&apos;n. Your postings on any public arena, such
  as a message board or in any chat room, will not be protected as confidential, and Smart&apos;n may use and disclose
  the information contained in any such postings (including any ideas, concepts, know-how, or other intellectual
  property) for any purpose deemed appropriate by Smart&apos;n.
</p>
<p><br /></p>
<p>Search engines and web crawlers</p>
<p>
  The information that is posted on Smart&apos;n Forums is routinely indexed by search engines and web crawlers (like
  Google, MSN, Yahoo, etc.). Therefore, when an online search is conducted, the information posted might show up in
  search results. If you do not wish for posting to be made publicly available or indexed by third-party search engines,
  then do not make a post on our forums.
</p>
<p><br /></p>
<p>Linked Internet Sites</p>
<p>
  Smart&apos;n is not responsible for the content available on any other Internet sites linked to the site. Access other
  Internet sites linked to the site is at your own risk.
</p>
<p><br /></p>
<p>You may link to the home page of our website as long as the link does not cast us in a false or misleading light.</p>
<p><br /></p>
<p>Postings</p>
<p>
  Smart&apos;n is under no obligation to review any messages, information, or content (&quot;Postings&quot;) posted on
  the site by users, and assumes no responsibility or liability relating to any such postings. Notwithstanding the
  above, Smart&apos;n may from time to time monitor the postings on the site and may decline to accept and/or remove any
  postings that contain:
</p>
<p><br /></p>
<p>
  Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racial, ethnic,
  or otherwise objectionable material of any kind, including, but not limited to any material which encourages conduct
  that would constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local,
  state, provincial, national, or international law
</p>
<p><br /></p>
<p><br /></p>
<p><br /></p>
<p>
  If you are the copyright owner or an agent acting on behalf of the copyright owner, please provide us the following
  information:
</p>
<p><br /></p>
<p>The description of the probable copyright infringement material(s)</p>
<p>
  Any information that can assist Smart&apos;n agents to properly pinpoint the information, including, infringer&apos;s
  user ID, URL of the page, date of posting, etc.
</p>
<p>Detailed contact information, including your full name, address, e-mail, and telephone number</p>
<p>
  Include the following statement: &quot;I have a good faith belief that use of the copyrighted materials described
  above as allegedly infringing is not authorized by the copyright owner, its agent, or the law.&quot;
</p>
<p>
  Include the following statement: &quot;I swear, under penalty of perjury, that the information in the notification is
  accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that
  is allegedly infringed.&quot;
</p>
<p><br /></p>
<p>Send written communication to:</p>
<p>Smart&apos;n Legal</p>
<p><br /></p>
<p>4211 Yonge Street, Suite 402</p>
<p>Toronto, ON</p>
<p>M2P 2A5</p>
<p><br /></p>
<p>Governing Law</p>
<p>
  This agreement constitutes the entire understanding between the parties with respect to the subject matter hereof, and
  all prior agreements, representations, statements, and undertakings, oral or written, are hereby expressly superseded
  and cancelled. This agreement is governed by the internal substantive laws of the province of Ontario in Canada,
  without respect to the conflict of laws principles. Jurisdiction for any claims arising under this agreement shall lie
  exclusively with the provincial or federal courts within Ontario in Canada. If any provision of this agreement is
  found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the
  validity of the remaining provisions of this agreement, which shall remain in full force and effect. No waiver of any
  term of this agreement shall be deemed a further or continuing waiver of such term or any other term.
</p>
<p><br /></p>
<p>Should you have any questions regarding this agreement, you may contact us at any time.</p>
`;

export default document;
