import { en } from '../../../i18n';
import Button from '../../components/Button';
import { useDispatch } from '../../hooks';
import { actions } from '../redux/slice';
import { PaymentIssueMessageContent, PaymentIssueText } from './styled';

const { billing } = en;
const { paymentIssueTitleMessage, paymentIssueSubTitle, paymentIssueAction } = billing;

const PaymentIssueMessage = () => {
  const { dispatch } = useDispatch();

  const openEditPaymentMethod = () => {
    dispatch(actions.setShowPaymentForm(true));
  };
  return (
    <PaymentIssueMessageContent>
      <PaymentIssueText>
        <div>{paymentIssueTitleMessage}</div>
        <div>{paymentIssueSubTitle}</div>
      </PaymentIssueText>

      <Button onClick={openEditPaymentMethod} size="small" variant="errorRed300">
        {paymentIssueAction}
      </Button>
    </PaymentIssueMessageContent>
  );
};

export default PaymentIssueMessage;
