import { MouseEvent } from 'react';
import {
  FiltersModalOverlay,
  FiltersModalContainer,
  FiltersModalTitle,
  FiltersModalBody,
  FiltersModalButtons,
} from './styled';
import { en } from '../../i18n';
import Button from '../components/Button';
import { useKeyDownPress } from '../hooks';

const { cancel } = en.smartQuiz;

interface ISmarQuizModal {
  toggleModal: () => void;
  onApply: () => void;
  title: string;
  body: string;
  confirmButtonText: string;
  buttonError?: boolean;
}

const SmartQuizModal = ({ toggleModal, onApply, title, body, confirmButtonText, buttonError }: ISmarQuizModal) => {
  const onConfirm = () => {
    onApply();
    toggleModal();
  };
  const onOverlayClick = () => {
    toggleModal();
  };
  const onContainerClick = (event: MouseEvent) => {
    event?.stopPropagation();
  };

  useKeyDownPress('Escape', toggleModal);

  return (
    <FiltersModalOverlay onClick={onOverlayClick}>
      <FiltersModalContainer onClick={onContainerClick}>
        <FiltersModalTitle>{title}</FiltersModalTitle>
        <FiltersModalBody>{body}</FiltersModalBody>
        <FiltersModalButtons>
          <Button variant="secondary" onClick={toggleModal}>
            {cancel}
          </Button>
          <Button variant={buttonError ? 'error' : 'primary'} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </FiltersModalButtons>
      </FiltersModalContainer>
    </FiltersModalOverlay>
  );
};

export default SmartQuizModal;
