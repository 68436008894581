import { useState } from 'react';
import useAttempts from './useAttempts';
import { IAttempt } from '../types';
import { useTheme } from '@mui/material/styles';
import useNavigations from './useNavigations';

const usePastQuizzes = () => {
  const { filteredAttempts: attempts = [], allAttempts = [] } = useAttempts();
  const { navigateToResults } = useNavigations();

  const [menusOpen, setMenusOpen] = useState((attempts || []).map(() => false));

  const handleMenuButtonClick = (index: number) => {
    const newMenusOpen = [...menusOpen];
    newMenusOpen[index] = !newMenusOpen[index];
    setMenusOpen(newMenusOpen);
  };

  const showAnswersModal = (attempt: IAttempt, index: number) => {
    handleMenuButtonClick(index);
    openAnswerModal(attempt);
  };

  const openAnswerModal = (attempt: IAttempt) => {
    navigateToResults(attempt);
  };

  return {
    attempts,
    allAttempts,
    openAnswerModal,
    menusOpen,
    handleMenuButtonClick,
    showAnswersModal,
  };
};

export default usePastQuizzes;
