import { styled } from '@mui/material/styles';

const TextContainer = styled('span')`
text-align-center;
.bold {
    font-weight:700;
}
.underline {
     text-decoration: underline;
}

`;

export { TextContainer };
