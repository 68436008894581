import { MouseEvent, KeyboardEvent, MutableRefObject, useEffect, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as BinIcon } from '../../../images/bin.svg';
import { ReactComponent as PencilIcon } from '../../../images/pencil.svg';
import { SessionItem, SessionButton, SessionGradient, SessionMenu, SessionMenuItem, SessionTitle } from '../styled';
import { en } from '../../../i18n';
import { ISession } from '../../types';

interface ISessionInterface {
  session: ISession;
  clickedSessionMenu: string;
  sessionToRename: ISession | undefined;
  sessionsTitlesRef: MutableRefObject<Record<string, HTMLDivElement | null>>;
  setCurrentSession: (session: ISession) => void;
  handleSessionMenuClick: (e: MouseEvent<HTMLElement>, sessionId: string) => void;
  handleSessionMenuClickaway: () => void;
  handleDeleteClick: (e: MouseEvent<HTMLElement>, session: ISession) => void;
  handleRenameClick: (e: MouseEvent<HTMLElement>, session: ISession) => void;
  handleRenameBlur: () => void;
  onRenameKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
  displaySessionMenuUp: (sessionId: string) => boolean;
}

const { deleteSession, renameSession } = en.nurseGPT;

const Session = ({
  session,
  clickedSessionMenu,
  sessionToRename,
  sessionsTitlesRef,
  setCurrentSession,
  handleSessionMenuClick,
  handleSessionMenuClickaway,
  handleDeleteClick,
  handleRenameClick,
  handleRenameBlur,
  onRenameKeyDown,
  displaySessionMenuUp,
}: ISessionInterface) => {
  const [displayTitle, setDisplayTitle] = useState(session.justCreated ? session.title.slice(0, 1) : session.title);
  const [isTyping, setIsTyping] = useState(Boolean(session.justCreated));

  useEffect(() => {
    if (session.justCreated) {
      let i = 2;
      const intervalId = setInterval(() => {
        setDisplayTitle(session.title.slice(0, i));
        i++;
        if (i > session.title.length) {
          setIsTyping(false);
          clearInterval(intervalId);
        }
      }, 20);
    } else setDisplayTitle(session.title);
  }, []);

  useEffect(() => {
    if (!isTyping) setDisplayTitle(session.title);
  }, [session.title]);

  return (
    <SessionItem
      onClick={() => setCurrentSession(session)}
      isMenuClicked={clickedSessionMenu === session.sessionId}
      isRenaming={session.sessionId === sessionToRename?.sessionId}
      removed={session.removed}
    >
      <SessionTitle
        isRenaming={session.sessionId === sessionToRename?.sessionId}
        ref={element => (sessionsTitlesRef.current[session.sessionId] = element)}
        onBlur={handleRenameBlur}
        onKeyDown={onRenameKeyDown}
      >
        {displayTitle}
      </SessionTitle>
      {session.sessionId !== sessionToRename?.sessionId && !isTyping && (
        <>
          <SessionButton
            onClick={e => handleSessionMenuClick(e, session.sessionId)}
            isClicked={clickedSessionMenu === session.sessionId}
          >
            <MoreHorizIcon />
          </SessionButton>
          <SessionGradient />
        </>
      )}
      {clickedSessionMenu === session.sessionId && (
        <ClickAwayListener onClickAway={handleSessionMenuClickaway} touchEvent={false}>
          <SessionMenu displayUp={displaySessionMenuUp(session.sessionId)}>
            <SessionMenuItem onClick={e => handleRenameClick(e, session)}>
              <PencilIcon />
              {renameSession}
            </SessionMenuItem>
            <SessionMenuItem warning onClick={e => handleDeleteClick(e, session)}>
              <BinIcon />
              {deleteSession}
            </SessionMenuItem>
          </SessionMenu>
        </ClickAwayListener>
      )}
    </SessionItem>
  );
};

export default Session;
