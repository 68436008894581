import { RefObject } from 'react';
import { UnderLinedButton, PrimaryButton, UnderLinedButtonSpan, SecondaryButton, PrimaryButtonSpan } from './styled';
import { TVariant, Tsizes } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import StyleBuilder from './StyleBuilder';
import { useHistory } from 'react-router-dom';

interface IButtonProps {
  variant: TVariant;
  children: any;
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLElement>) => void);
  size?: Tsizes;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'reset';
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
  link?: string;
  form?: string;
}

const variantToComponentMapping = {
  underlined: UnderLinedButton,
  primary: PrimaryButton,
  underlinedSpan: UnderLinedButtonSpan,
  secondary: SecondaryButton,
  error: SecondaryButton,
  errorFilled: PrimaryButton,
  errorRed300: SecondaryButton,
  secondayGreyDisable: SecondaryButton,
  primarySpan: PrimaryButtonSpan,
};

const Button = ({
  variant = 'underlined',
  children,
  onClick,
  size = 'medium',
  disabled,
  loading = false,
  fullWidth = false,
  className,
  type,
  link,
  form,
}: IButtonProps) => {
  const Component = variantToComponentMapping[variant];
  const history = useHistory();

  const styleBuilder = new StyleBuilder({ size, disabled, loading, variant, fullWidth });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (disabled || loading) return;
    if (link && !onClick) return history.push(link);
    onClick?.(e);
  };

  return (
    <Component className={className} type={type} customStyle={styleBuilder.build()} onClick={handleClick} form={form}>
      <span>{children}</span>
      {loading && <CircularProgress />}
    </Component>
  );
};

export type { IButtonProps };
export default Button;
