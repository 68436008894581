import { IStepComponent } from '../types';
import {
  DesktopLicenseContainer,
  LicenseColumnContainer,
  LicenseInputContainer,
  ColumnTitle,
  LicenseDisclaimer,
} from './styled';
import { en } from '../../../i18n';
import useLocationUI from '../../components/Location/useLocationUI';
import { useMediaQuery, useTheme } from '@mui/material';
import useStep from './useStep';
import { ILocationState } from '../../components/Location/useLocation';
import { ILooseObject } from '../../types';

const { apply } = en;

const License = ({ question }: IStepComponent) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { title, currentResponse, onChangeAnswer } = useStep({ question });

  const onChangeLocation = (locationType: 'locationA' | 'locationB') => (locationState: ILocationState) => {
    const nextLocation = currentResponse ? { ...(currentResponse as ILooseObject) } : {};
    nextLocation[locationType] = locationState;
    onChangeAnswer(nextLocation);
  };

  const { locationA: locationAResponse, locationB: locationBResponse } = (currentResponse as ILooseObject) || {};

  const locationA = useLocationUI({
    onChangeExternal: onChangeLocation('locationA'),
    externalState: locationAResponse,
    index: 0,
  });
  const locationB = useLocationUI({
    onChangeExternal: onChangeLocation('locationB'),
    externalState: locationBResponse,
    index: 1,
  });

  const renderDesktop = () => {
    return (
      <DesktopLicenseContainer>
        <LicenseColumnContainer>
          <ColumnTitle>{en.location.country}</ColumnTitle>
          {locationA.dropDowns.countryDropdown}
          {locationB.dropDowns.countryDropdown}
        </LicenseColumnContainer>
        <LicenseColumnContainer>
          <ColumnTitle>{en.location.province}</ColumnTitle>
          {locationA.dropDowns.stateDropDown}
          {locationB.dropDowns.stateDropDown}
        </LicenseColumnContainer>
      </DesktopLicenseContainer>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <LicenseColumnContainer>
          <ColumnTitle>{apply.license.titleA}</ColumnTitle>
          {locationA.dropDowns.countryDropdown}
          {locationA.dropDowns.stateDropDown}
        </LicenseColumnContainer>

        <LicenseColumnContainer>
          <ColumnTitle>{apply.license.titleB}</ColumnTitle>
          {locationB.dropDowns.countryDropdown}
          {locationB.dropDowns.stateDropDown}
        </LicenseColumnContainer>
      </>
    );
  };

  return (
    <>
      {title}
      <LicenseInputContainer>
        {isMobile ? renderMobile() : renderDesktop()}

        <LicenseDisclaimer>{apply.license.disclaimer}</LicenseDisclaimer>
      </LicenseInputContainer>
    </>
  );
};

export default License;
