import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';

const TimerText = styled('div')`
  width: fit-content;
  box-sizing: border-box;
  padding: 5px 16px;
  background: ${colors.lightGrey};
  font-weight: 700;
  font-size: 1rem;
  border-radius: 4px;
`;

export { TimerText };
