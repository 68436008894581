import {
  UnstartedCaseStudyWrapper,
  UnstartedCaseStudyContainer,
  UnstartedCaseStudyTitle,
  UnstartedCaseStudyText,
} from './styled';
import { Tag } from '../../../sharedStyled';
import { en } from '../../../../i18n';
import SmartNLogo from '../../../../images/icons/signup/smartN.png';

const {
  unstartedTitle,
  unstartedFirstBody,
  unstartedSecondBody,
  unstartedThirdBody,
  unstartedFirstBodyPlacement,
  unstartedSecondBodyPlacement,
  unstartedTitlePlacement,
} = en.caseStudy;

interface IUnstartedCaseStudy {
  topic?: string;
  totalQuestions?: number;
  isPlacement?: boolean;
}

const UnstartedCaseStudy = ({ topic, totalQuestions, isPlacement }: IUnstartedCaseStudy) => {
  const renderTitle = () => {
    if (isPlacement)
      return (
        <>
          <img src={SmartNLogo}></img>
          <span>{unstartedTitlePlacement}</span>
        </>
      );

    return unstartedTitle;
  };

  const renderFirstBody = () => {
    if (isPlacement) return unstartedFirstBodyPlacement;
    return unstartedFirstBody(totalQuestions || 6);
  };

  const renderSecondBody = () => {
    if (isPlacement) return unstartedSecondBodyPlacement;
    return unstartedSecondBody;
  };
  return (
    <UnstartedCaseStudyWrapper>
      <UnstartedCaseStudyContainer>
        <UnstartedCaseStudyTitle>{renderTitle()}</UnstartedCaseStudyTitle>
        <Tag variant="outlined">{topic}</Tag>
        <UnstartedCaseStudyText>{renderFirstBody()}</UnstartedCaseStudyText>
        <UnstartedCaseStudyText>{renderSecondBody()}</UnstartedCaseStudyText>
        {!isPlacement && <UnstartedCaseStudyText>{unstartedThirdBody}</UnstartedCaseStudyText>}
      </UnstartedCaseStudyContainer>
    </UnstartedCaseStudyWrapper>
  );
};

export default UnstartedCaseStudy;
