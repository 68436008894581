import { styled } from '@mui/material/styles';
import { opacityInAnimation } from '../../sharedStyled';
import { shouldForwardProps } from '../../utils';
import { colors } from '../../../themes';

interface ITipProps {
  open: boolean;
}

const QuestionTip = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<ITipProps>(
  ({ open }) => `
  width: 100%;
  height: 36px;
  background-color: ${colors.purple5};
  padding: 9px 11px 9px 14px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;
  position: relative;
  border: 1px solid ${colors.purple5};
  border-bottom: none;
  z-index:3;
  ${
    open
      ? `
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #D1CBF6;
    border-bottom: none;
    border-radius: 5px 5px 0 0;`
      : ''
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 7.5px;
    font-size: 0.875rem;
    font-weight: 700;
    color: ${colors.purple30};
  }
`,
);

const QuestionTipText = styled('div')`
  position: absolute;
  left: -1px;
  right: -1px;
  top: 35px;
  padding: 16px;
  font-size: 0.875rem;
  font-weight: normal;
  border-radius: 0 0 5px 5px;
  border: 1px solid #d1cbf6;
  border-top: none;
  background-color: ${colors.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  p,
  div {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: normal;
  }
  ${opacityInAnimation(0.3)};
`;

export { QuestionTip, QuestionTipText };
