import { ILooseObject } from '../types';
const HARD_CODED_QUIZ_ID = '57c57982-5497-11ef-b864-0242ac120002';

export { HARD_CODED_QUIZ_ID };
// const steps = [
//   {
//     questionId: '04b8214a-5497-11ef-b864-0242ac120002',
//     Component: Experience,
//   },
//   {
//     questionId: '04b82794-5497-11ef-b864-0242ac120002',
//     Component: EducationLevel,
//   },
//   {
//     questionId: '04b828ca-5497-11ef-b864-0242ac120002',
//     Component: Certifications,
//   },
//   {
//     questionId: '04b82a1e-5497-11ef-b864-0242ac120002',
//     Component: EmploymentTypes,
//   },
//   {
//     questionId: '04b82f1e-5497-11ef-b864-0242ac120002',
//     Component: HealthCareRating,
//   },
//   {
//     questionId: '04b83036-5497-11ef-b864-0242ac120002',
//     Component: ShiftWork,
//   },

//   {
//     questionId: locationId,
//     Component: Location,
//   },
//   {
//     questionId: '04b832ca-5497-11ef-b864-0242ac120002',
//     Component: Commute,
//   },
//   {
//     questionId: '04b836c6-5497-11ef-b864-0242ac120002',
//     Component: Relocate,
//   },
//   {
//     questionId: '04b837b6-5497-11ef-b864-0242ac120002',
//     Component: License,
//   },

//   {
//     questionId: '04b838a6-5497-11ef-b864-0242ac120002',
//     Component: UploadResume,
//   },

//   {
//     questionId: '04b838a6-5497-11ef-b864-0242ac120002',
//     Component: SubmitScreen,
//   },

//   {
//     questionId: '04b838a6-5497-11ef-b864-0242ac120002',
//     Component: ConfirmMissingCV,
//   },
//   {
//     questionId: '030dabb2-5497-11ef-b864-0242ac120002',
//     Component: Notifications,
//   },
// ];
