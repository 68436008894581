import { MasteryLevelBar, MasteryLevelContainer } from '../styled';
import CustomTooltip from '../../components/Tooltip';
import { en } from '../../../i18n';

const { masteryLevelDescription } = en.reviewResults.topicAnalysis;

interface IMasteryLevel {
  level: number;
}

const MasteryLevel = ({ level }: IMasteryLevel) => {
  return (
    <CustomTooltip
      enterTouchDelay={0}
      disableFocusListener
      width="100%"
      arrow={false}
      title={masteryLevelDescription(level)}
      placement="top"
      centerText
    >
      <MasteryLevelContainer>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <MasteryLevelBar key={index} isFilled={index + 1 <= level}></MasteryLevelBar>
          ))}
      </MasteryLevelContainer>
    </CustomTooltip>
  );
};

export default MasteryLevel;
