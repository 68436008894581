import { AnalysisContainer, AnalysisPageContainer, PageWrapper, SectionContainer } from './styled';
import { useMembershipProxy, useSeoTags, useTopicsRecommendations } from '../hooks';
import Spinner from '../components/Spinner';
import TopBanner from './analysis/TopBanner';
import TopicAnalysis from './analysis/TopicAnalysis';
import TopicsTable from './analysis/TopicsTable';
import useInitResults from './useInitResults';
import CategoryBreakDown from './analysis/CateogryBreakdown';
import { useSelector } from 'react-redux';
import { quizSelectors } from '../StudySpace/redux/slice';

const ReviewResults = () => {
  const { reviewResultsAnalysisTitle } = useMembershipProxy({ useStrict: true });
  const { Container = AnalysisContainer } = reviewResultsAnalysisTitle.run();
  const seo = useSeoTags({ pageName: 'reviewResults' });
  const { loading, currentAttempt } = useInitResults();
  const { questionAnswers, questions } = useSelector(quizSelectors.allState);

  const topicsRecommendationsInput = currentAttempt ? [currentAttempt] : [];
  const { topicsResults } = useTopicsRecommendations(topicsRecommendationsInput, {
    thresholdTopics: 0,
    useOnbaordingWeakTopics: false,
    questionAnswers,
    transformedQuestions: questions,
  });

  const renderContent = () => {
    if (loading || !currentAttempt) return <Spinner loading />;

    return (
      <Container>
        <TopBanner topicsResults={topicsResults} attempt={currentAttempt} />
        {currentAttempt.isReadiness && (
          <SectionContainer>
            <CategoryBreakDown />
          </SectionContainer>
        )}
        <TopicAnalysis attempt={currentAttempt} />
        <TopicsTable topicsResults={topicsResults} attempt={currentAttempt} />
      </Container>
    );
  };

  return (
    <PageWrapper>
      {seo}
      <AnalysisPageContainer>{renderContent()}</AnalysisPageContainer>
    </PageWrapper>
  );
};

export default ReviewResults;
