import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { upperFirst, uniqBy } from 'lodash';
import {
  INurseGPTState,
  IAttempt,
  ILooseObject,
  IQuestion,
  IFetchSessionsResponse,
  IFetchSessionResponse,
} from '../../types';
import { RootState } from '../../redux/store';
import { SendJsonMessage } from 'react-use-websocket/src/lib/types';

const SLICE_NAME = 'nurseGPT';

const initialState: INurseGPTState = {
  loading: false,
  loadingAnswer: false,
  errors: {},
  submitted: false,
  loadingResponse: false,
  currentSessionId: '',
  lastFetchedSession: '',
  sessions: [],
  loadingSessions: false,
  allSessionsFetched: false,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // Interest Form Reducers
    attemptQuiz: state => {
      state.loading = true;
    },
    attemptQuizSuccess: (
      state,
      action: PayloadAction<{ attempt: IAttempt; questions: { [key: string]: IQuestion }; submitted: boolean }>,
    ) => {
      state.loading = false;
      state.attempt = action.payload.attempt;
      state.questionsTransformed = action.payload.questions;
      state.submitted = action.payload.submitted;
    },
    attemptQuizFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },

    submitAnswer: (
      state,
      action: PayloadAction<{
        questionId: string;
        attemptId: string;
        response: string;
      }>,
    ) => {
      state.loadingAnswer = true;
    },

    submitAnswerSuccess: state => {
      state.loadingAnswer = false;
      state.submitted = true;
    },
    submitAnswerFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loadingAnswer = false;
    },

    // NurseGPT Page Reducers
    submitPrompt: (
      state,
      action: PayloadAction<{ prompt: string; currentSessionId: string; sendJsonMessage: SendJsonMessage }>,
    ) => {
      const { prompt, currentSessionId } = action.payload;
      state.loadingResponse = true;
      if (!currentSessionId) {
        state.sessions = [
          {
            sessionId: 'TEMP_ID',
            createdAt: Date.now(),
            title: upperFirst(prompt),
            messages: [
              {
                source: 'user',
                content: prompt,
              },
            ],
            justCreated: true,
            receivingResponse: true,
          },
          ...state.sessions,
        ];
        state.currentSessionId = 'TEMP_ID';
      } else {
        const targetSession = state.sessions.find(session => session.sessionId === currentSessionId);
        if (targetSession) {
          targetSession.receivingResponse = true;
          targetSession.messages = [
            ...targetSession.messages,
            {
              source: 'user',
              content: prompt,
            },
          ];
        }
      }
    },
    submitPromptSuccess: (state, action: PayloadAction<{ response: string; sessionId: string }>) => {
      const { response, sessionId } = action.payload;
      state.loadingResponse = false;
      let targetSession = state.sessions.find(session => session.sessionId === sessionId);
      if (targetSession) {
        targetSession.messages = [
          ...targetSession.messages,
          {
            source: 'assistant',
            content: response,
          },
        ];
        targetSession.receivingResponse = false;
      } else {
        targetSession = state.sessions.find(session => session.sessionId === 'TEMP_ID') || state.sessions[0];
        targetSession.sessionId = sessionId;
        targetSession.messages = [
          ...targetSession.messages,
          {
            source: 'assistant',
            content: response,
          },
        ];
        targetSession.justCreated = false;
        targetSession.receivingResponse = false;
      }
      if (state.currentSessionId === 'TEMP_ID') state.currentSessionId = sessionId;
    },
    submitPromptFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loadingResponse = false;
      state.errors = errors;
    },

    startNewChat: state => {
      state.currentSessionId = '';
    },

    fetchSessions: (state, action: PayloadAction<{ lastSession?: ILooseObject }>) => {
      state.loadingSessions = true;
    },
    fetchSessionsSuccess: (state, action: PayloadAction<{ sessions: IFetchSessionsResponse[] }>) => {
      const { sessions } = action.payload;
      state.loadingSessions = false;
      const oldSessionsLength = state.sessions.length;
      state.sessions = uniqBy(
        state.sessions.concat(
          sessions.map(session => {
            return {
              sessionId: session.session_id,
              createdAt: session.session_created_at * 1000,
              title: upperFirst(session.session_title) || 'NurseGPT Session',
              loadingSession: false,
              allMessagesFetched: false,
              numberOfFetches: 0,
            };
          }),
        ),
        'sessionId',
      );
      if (state.sessions.length === oldSessionsLength) state.allSessionsFetched = true;
    },
    fetchSessionsFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loadingSessions = false;
      state.errors = errors;
    },

    fetchSession: (state, action: PayloadAction<{ session: ILooseObject }>) => {
      const { session } = action.payload;
      const targetSessionIndex = state.sessions.findIndex(sessionItem => sessionItem.sessionId === session.sessionId);
      state.sessions[targetSessionIndex].loadingSession = true;
    },
    fetchSessionSuccess: (state, action: PayloadAction<{ session: IFetchSessionResponse }>) => {
      const { session } = action.payload;
      const targetSessionIndex = state.sessions.findIndex(sessionItem => sessionItem.sessionId === session.session_id);
      state.sessions[targetSessionIndex] = {
        ...state.sessions[targetSessionIndex],
        loadingSession: false,
        messages: session.messages
          .reverse()
          .map(message => ({
            source: message.role,
            content: message.content[0],
            messageId: message.pk,
          }))
          .concat(state.sessions[targetSessionIndex].messages || []),
      };
      if (session.messages.length === 0) state.sessions[targetSessionIndex].allMessagesFetched = true;
      state.sessions[targetSessionIndex].numberOfFetches++;
      state.lastFetchedSession = session.session_id;
    },
    fetchSessionFailure: (state, action: PayloadAction<{ errors: any; session: ILooseObject }>) => {
      const { errors, session } = action.payload;
      const targetSessionIndex = state.sessions.findIndex(sessionItem => sessionItem.sessionId === session.sessionId);
      state.sessions[targetSessionIndex].loadingSession = false;
      state.errors = errors;
    },

    setCurrentSession: (state, action: PayloadAction<{ session: ILooseObject }>) => {
      const { session } = action.payload;
      state.currentSessionId = session.sessionId;
    },

    resetState: state => initialState,

    deleteSession: (state, action: PayloadAction<{ sessionId: string }>) => {},
    deleteSessionVisualSuccess: (state, action: PayloadAction<{ sessionId: string }>) => {
      const { sessionId } = action.payload;
      if (sessionId === state.currentSessionId) {
        state.currentSessionId = '';
      }
      const sessionIndex = state.sessions.findIndex(session => session.sessionId === sessionId);
      state.sessions[sessionIndex] = {
        ...state.sessions[sessionIndex],
        removed: true,
      };
    },
    deleteSessionSuccess: (state, action: PayloadAction<{ sessionId: string }>) => {
      const { sessionId } = action.payload;
      if (sessionId === state.currentSessionId) {
        state.currentSessionId = '';
      }
      state.sessions = state.sessions.filter(session => session.sessionId !== sessionId);
    },
    deleteSessionFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.errors = errors;
    },

    renameSession: (state, action: PayloadAction<{ sessionId: string; title: string; originalTitle: string }>) => {
      const { sessionId, title } = action.payload;
      const sessionIndex = state.sessions.findIndex(session => session.sessionId === sessionId);
      state.sessions[sessionIndex] = {
        ...state.sessions[sessionIndex],
        title,
      };
    },
    renameSessionSuccess: state => {},
    renameSessionFailure: (state, action: PayloadAction<{ errors: any; sessionId: string; originalTitle: string }>) => {
      const { errors, sessionId, originalTitle } = action.payload;
      state.errors = errors;
      const sessionIndex = state.sessions.findIndex(session => session.sessionId === sessionId);
      state.sessions[sessionIndex] = {
        ...state.sessions[sessionIndex],
        title: originalTitle,
      };
    },
  },
});

const nurseGPTSelectors = {
  allState: (state: RootState): typeof initialState => state.nurseGPT,
};

const actions = { ...slice.actions };

export { nurseGPTSelectors, SLICE_NAME, actions };

export default slice.reducer;
