import React, { useReducer, ChangeEvent, useEffect, useRef } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { EPlanTypes, EPaymentSteps, ESubscriptionsStatus, ILooseObject, IPaymentFormState } from '../../types';
import { en } from '../../../i18n';
import { actions, subscriptionsSelectors } from '../redux/slice';
import { useSelector } from 'react-redux';
import { useDispatch, useSubscriptions } from '../../hooks';
import { cloneDeep } from 'lodash';
import useStripeElementValidation from './useStripeElementsValidation';

const {
  paymentForm: { pay, countries },
} = en.billing;

const initialState = {
  name: '',
  email: '',
  promoCode: '',
  address: {
    postalCode: '',
    country: countries[0],
    line1: '',
    city: '',
  },
};
const reducer = (state: IPaymentFormState, action: ILooseObject) => {
  switch (action.type) {
    case 'CHANGE':
      return { ...state, [action.name]: action.value };
    case 'RESET':
      return cloneDeep(initialState);
    default:
      return state;
  }
};

let debounceTimeOut: any;
const DEBOUNCE_TIME = 2000;

const usePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    currentSubscription,
    paymentStep,
    promotionCodeID,
    stripePlan,
    errors,
    showPaymentForm,
    isPaymentMethodChanged,
    loadingChangePaymentMethod,
    loadingCreateSubscription,
    loadingPurchaseAddon,
  } = useSelector(subscriptionsSelectors.allState);
  const { isActive, isPaymentIssue } = useSubscriptions({ useFetchSubscriptions: false });
  const { dispatch: dispatchRedux } = useDispatch();
  const { state: cardsSectionState, control } = useStripeElementValidation();
  const isAddressChanged = useRef(false);

  const [formState, dispatch] = useReducer(reducer, initialState);

  const { paymentMethod } = currentSubscription || {};

  //TODO: This should be removed when payment state is refactored
  const getCurrentState = () => {
    switch (true) {
      case !isActive && !isPaymentIssue:
        return EPaymentSteps.BUY_SUBSCRIPTION;
      case paymentStep === EPaymentSteps.EDIT_CARD:
        return EPaymentSteps.EDIT_CARD;
      case stripePlan.type === EPlanTypes.ADDON && !paymentMethod:
        return EPaymentSteps.BUY_ADDON_NO_CARD;
      case stripePlan.type === EPlanTypes.ADDON:
        return EPaymentSteps.BUY_ADDON;

      case isPaymentIssue:
        return EPaymentSteps.VIEW_SUBSCRIPTION;
      default:
        return EPaymentSteps.VIEW_SUBSCRIPTION;
    }
  };

  const currentState = getCurrentState();

  const isAddressPresent = () => {
    return Boolean(formState.address.line1 && formState.address.postalCode);
  };

  const createAddress = () => {
    dispatchRedux(actions.setLoadingDebounce(false));

    if (isAddressPresent()) {
      dispatchRedux(actions.createAddressRequest({ address: formState.address }));
    }
  };

  useEffect(() => {
    if (!isAddressChanged.current || currentState !== EPaymentSteps.BUY_SUBSCRIPTION) return;
    if (debounceTimeOut) clearTimeout(debounceTimeOut);
    dispatchRedux(actions.resetAddressError());
    if (isAddressPresent()) {
      dispatchRedux(actions.setLoadingDebounce(true));
      dispatchRedux(actions.setPriceIdIsChanged(false));
    }
    debounceTimeOut = setTimeout(() => {
      createAddress();
    }, DEBOUNCE_TIME);
  }, [formState.address]);

  useEffect(() => {
    if (isActive) return;
    if (stripePlan.priceId && !errors.address && showPaymentForm) {
      dispatchRedux(actions.createSubscriptionRequest());
    }
  }, [stripePlan.priceId]);

  useEffect(() => {
    if (!currentSubscription || currentSubscription.status !== ESubscriptionsStatus.INCOMPLETE) return;
    const { address } = currentSubscription;
    const { country, ...rest } = address;
    const selectedCountry = countries.find(current => current.value === country);
    dispatch({ type: 'CHANGE', name: 'address', value: { country: selectedCountry || countries[1], ...rest } });
    dispatchRedux(actions.setPaymentStep(EPaymentSteps.TAX_CALCULATED));
    dispatchRedux(actions.setIsAdressCreated({ isAddressCreated: true }));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({ type: 'CHANGE', name, value });
  };

  const handleCountryChange = (country: ILooseObject) => {
    isAddressChanged.current = true;
    if (currentState !== EPaymentSteps.EDIT_CARD) dispatchRedux(actions.setPaymentStep(EPaymentSteps.INPUT_FIELDS));
    dispatch({ type: 'CHANGE', name: 'address', value: { ...formState.address, country } });
  };

  const handleAddressFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    isAddressChanged.current = true;
    const { name, value } = event.target;
    if (currentState !== EPaymentSteps.EDIT_CARD) dispatchRedux(actions.setPaymentStep(EPaymentSteps.INPUT_FIELDS));
    dispatch({ type: 'CHANGE', name: 'address', value: { ...formState.address, [name]: value } });
  };

  const handleDirectChange = (name: string, value: string | boolean) => {
    if (currentState !== EPaymentSteps.EDIT_CARD) dispatchRedux(actions.setPaymentStep(EPaymentSteps.INPUT_FIELDS));
    dispatch({ type: 'CHANGE', name, value });
  };

  const onOpenPromoCode = () => {
    dispatch({ type: 'CHANGE', name: 'isPromoCodeApplied', value: false });
  };

  const onApplyPromoCode = () => {
    dispatch({ type: 'CHANGE', name: 'isPromoCodeApplied', value: true });
    if (formState.promoCode)
      dispatchRedux(actions.fetchPromoCodeRequest({ code: formState.promoCode, productId: stripePlan.productId }));
    else resetPromoCode();
  };

  const resetPromoCode = () => {
    const wasPromoCodeIDPresent = promotionCodeID;
    dispatchRedux(actions.resetPromoCode());
    if (wasPromoCodeIDPresent) dispatchRedux(actions.createSubscriptionRequest());
  };

  const getButtonDisabled = () => {
    const currentState = getCurrentState();
    if (currentState === EPaymentSteps.BUY_SUBSCRIPTION)
      return !cardsSectionState.isComplete || paymentStep === EPaymentSteps.INPUT_FIELDS || loadingCreateSubscription;
    if (currentState === EPaymentSteps.EDIT_CARD)
      return !cardsSectionState.isComplete || !isAddressPresent() || !formState.name || loadingChangePaymentMethod;

    if (currentState === EPaymentSteps.BUY_ADDON) return isPaymentIssue;

    return false;
  };

  const buttonDisabled = getButtonDisabled();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (buttonDisabled) return;
    if (paymentStep === EPaymentSteps.TAX_CALCULATED)
      dispatchRedux(
        actions.confirmPaymentRequest({
          stripe,
          elements,
          clientSecret: currentSubscription?.latestInvoice?.paymentIntent.clientSecret || '',
          formState,
        }),
      );
  };

  const closeModal = () => {
    dispatchRedux(actions.setShowPaymentForm(false));
    dispatchRedux(actions.resetPaymentFormState());
    dispatch({ type: 'RESET' });
  };

  const onSumbitChangePaymentMethod = (e: React.FormEvent) => {
    e.preventDefault();
    if (paymentStep !== EPaymentSteps.EDIT_CARD || buttonDisabled) return;

    dispatchRedux(
      actions.changePaymentMethodRequest({
        stripe,
        elements,
        formState,
        subId: currentSubscription?.id || '',
      }),
    );
  };

  const onToggleEdit = () => {
    if (paymentStep !== EPaymentSteps.EDIT_CARD) {
      dispatchRedux(actions.setPaymentStep(EPaymentSteps.EDIT_CARD));
      dispatchRedux(actions.hideChangePaymentMethod());
      return;
    }
    dispatchRedux(actions.setPaymentStep(EPaymentSteps.INPUT_FIELDS));
  };

  //TODO: Refactor this
  const onModalClick = (e: React.SyntheticEvent) => {
    //@ts-ignore
    const parentElement = e.target?.parentElement;
    if (parentElement?.className?.includes('UserAgreementsModal')) return;
    e.stopPropagation();
  };

  const openCancelModal = () => {
    closeModal();
    dispatchRedux(actions.setModalVariant('cancel'));
    dispatchRedux(actions.setShowModal(true));
  };

  const purchaseAddon = () => {
    dispatchRedux(
      actions.purchaseAddonStoredCardRequest({
        priceId: stripePlan.priceId,
        paymentMethodId: currentSubscription?.paymentMethod?.id || '',
        stripe,
      }),
    );
  };

  const purchaseAddonNoCard = () => {
    dispatchRedux(
      actions.purchaseAddonRequest({
        priceId: stripePlan.priceId,
        stripe,
        elements,
        formState,
      }),
    );
  };

  return {
    control: {
      handleChange,
      handleCountryChange,
      handleAddressFieldChange,
      handleDirectChange,
      onSubmit,
      onOpenPromoCode,
      onApplyPromoCode,
      resetPromoCode,
      closeModal,
      onSumbitChangePaymentMethod,
      onToggleEdit,
      getCurrentState,
      onModalClick,
      openCancelModal,
      purchaseAddon,
      purchaseAddonNoCard,
      ...control,
    },

    state: {
      formState,
      buttonDisabled,
      buttonText: pay,
      errors,
      isActive,
      currentSubscription,
      paymentStep,
      isPaymentMethodChanged,
      isPaymentIssue,
      stripePlan,
      loadingPurchaseAddon,
    },
  };
};

export default usePaymentForm;
