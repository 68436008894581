import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch } from '../../hooks';
import { actions } from '../redux/slice';

type TFieldNames = 'card' | 'expiry' | 'cvc';
type TState = {
  [key in TFieldNames]: {
    error?: string;
    isComplete: boolean;
  };
};

const initialState: TState = {
  card: {
    error: '',
    isComplete: false,
  },
  expiry: { error: '', isComplete: false },
  cvc: { error: '', isComplete: false },
};

const useStripeElementValidation = () => {
  const { dispatch } = useDispatch();
  const [state, setState] = useState<TState>(initialState);

  useEffect(() => {
    const fieldPriorities: TFieldNames[] = ['card', 'expiry', 'cvc'];
    for (const field of fieldPriorities) {
      if (state[field].error) {
        dispatch(actions.setErrors({ errors: { card: state[field].error } }));
        return;
      }
    }

    dispatch(actions.setErrors({ errors: { card: '' } }));
  }, [state]);

  const handleErrors = (
    name: TFieldNames,
    stripeEvent:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => {
    setState(prevState => ({
      ...prevState,
      [name]: {
        error: stripeEvent.error?.message,
        isComplete: stripeEvent.complete,
      },
    }));
  };
  const onChangeCard = (e: StripeCardNumberElementChangeEvent) => {
    handleErrors('card', e);
  };

  const onChangeExpiry = (e: StripeCardExpiryElementChangeEvent) => {
    handleErrors('expiry', e);
  };

  const onChangeCVC = (e: StripeCardCvcElementChangeEvent) => {
    handleErrors('cvc', e);
  };

  const isComplete = Object.values(state).every(value => value.isComplete);

  return {
    control: {
      onChangeCard,
      onChangeExpiry,
      onChangeCVC,
    },
    state: {
      isComplete,
    },
  };
};

export default useStripeElementValidation;
