import React, { useState } from 'react';
import { ILooseObject } from '../../types';
import { TextField } from '@mui/material';
import { FieldContainer, Container } from './styled';
import Button from '../Button';
import { en } from '../../../i18n';

const { dynamicField } = en;

interface IDynamicField {
  placeHolder?: string;
  maxFields?: number;
  initialFieldState?: ILooseObject;
  onChangeExternal?: (state: ILooseObject) => void;
}

interface IStateField {
  value?: string;
}

const DynamicField = ({ placeHolder, maxFields = 10, initialFieldState, onChangeExternal }: IDynamicField) => {
  const [lastKey, setLastKey] = useState(() => {
    if (!initialFieldState) return 0;
    return Object.values(initialFieldState).length - 1;
  });
  const [fields, setCurrentFields] = useState<ILooseObject>(initialFieldState || { 0: { value: '' } });

  const onAdd = () => {
    const fieldsDuplicate = { ...fields };
    fieldsDuplicate[lastKey + 1] = {
      value: '',
    };
    setCurrentFields(fieldsDuplicate);
    onChangeExternal?.(fieldsDuplicate);
    setLastKey(lastKey + 1);
  };

  const onRemove = (key: string) => () => {
    const fieldsDuplicate = { ...fields };
    if (fieldsDuplicate[key]) delete fieldsDuplicate[key];
    setCurrentFields(fieldsDuplicate);
    onChangeExternal?.(fieldsDuplicate);
  };

  const onChange = (key: string) => (e: React.BaseSyntheticEvent) => {
    const fieldsDuplicate = { ...fields };
    if (fieldsDuplicate[key]) {
      fieldsDuplicate[key].value = e.target.value;
    }

    setCurrentFields(fieldsDuplicate);
    onChangeExternal?.(fieldsDuplicate);
  };

  const renderFields = () => {
    const sortedKeys = Object.keys(fields).sort((a, b) => +a - +b);
    return sortedKeys.map((fieldKey, index) => {
      const { value } = fields[fieldKey];
      const isFirst = +fieldKey === 0;
      const isLast = +fieldKey === +sortedKeys[sortedKeys.length - 1];
      const isMaxReached = isLast && sortedKeys.length === maxFields;

      const getButton = () => {
        if (isFirst && isLast)
          return {
            onClick: onAdd,
            text: dynamicField.add,
          };

        if (isMaxReached)
          return {
            onClick: onRemove(fieldKey),
            text: dynamicField.remove,
          };

        if (isLast)
          return {
            onClick: onAdd,
            text: dynamicField.add,
          };

        return {
          onClick: onRemove(fieldKey),
          text: dynamicField.remove,
        };
      };

      const { onClick, text } = getButton();

      return (
        <FieldContainer key={fieldKey}>
          <TextField
            InputLabelProps={{ shrink: false }}
            onChange={onChange(fieldKey)}
            value={value}
            placeholder={placeHolder}
          />
          <Button variant="primary" size="small" onClick={onClick}>
            {text}
          </Button>
        </FieldContainer>
      );
    });
  };

  return <Container>{renderFields()}</Container>;
};

export default DynamicField;
