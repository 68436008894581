import Matrix from '../../components/Matrix';
import { IStepComponent } from '../types';

import { Title, QuestionHeaderCentered, MatrixInputContainer } from './styled';

import { en } from '../../../i18n';
import useStep from './useStep';
import { ILooseObject } from '../../types';
const { apply } = en;

const EmploymentTypes = ({ question }: IStepComponent) => {
  const { renderSanitizedText, onChangeAnswer, currentResponse } = useStep({ question });

  const header = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 0, value: 'Not interested' },
  ];

  const rows =
    question.content.choices?.map(choice => ({ id: choice.id, value: choice.content.choice?.content.text || '' })) ||
    [];

  return (
    <>
      <Title>{renderSanitizedText(question.content.question.content.text)}</Title>
      <QuestionHeaderCentered>{apply.employmentType.header}</QuestionHeaderCentered>
      <MatrixInputContainer>
        <Matrix
          header={header}
          rows={rows}
          onChange={onChangeAnswer}
          rowUniquenessConstraint
          response={currentResponse as ILooseObject}
          horizontalOptionsMobile
        />
      </MatrixInputContainer>
    </>
  );
};

export default EmploymentTypes;
