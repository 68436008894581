import { Bar, BarButtonContair, BarContainer, TitleContainer } from './styled';
//import Button from '../../components/Button';
import Tooltip from '../Tooltip';
import { UnderLinedButtonSpan } from '../Button/styled';
import StyleBuilder from '../Button/StyleBuilder';
import { en } from '../../../i18n';

const { studySpace, smartQuiz } = en;

interface IProps {
  percentage: number;
  color?: string;
  title?: string;
  useBarOnly?: boolean;
  barHeight?: string;
  useBarStripes?: boolean;
  backgroundColor?: string;
  width?: number;
  useMountAnimation?: boolean;
  upperBound?: number;
  lowerBound?: number;
}
const ProgressBar = ({
  percentage,
  color,
  title,
  useBarOnly,
  barHeight,
  useBarStripes = true,
  backgroundColor,
  useMountAnimation,
  lowerBound,
  upperBound,
}: IProps) => {
  const progressBar = (
    <Bar
      backgroundColor={backgroundColor}
      useBarStripes={useBarStripes}
      barHeight={barHeight}
      width={percentage}
      color={color}
      useBarOnly={useBarOnly}
      useMountAnimation={useMountAnimation}
    >
      <span />
    </Bar>
  );

  if (useBarOnly) return progressBar;

  const styleBuilder = new StyleBuilder({
    size: 'medium',
    disabled: false,
    loading: false,
    variant: 'underlinedSpan',
  });

  const toolTipTitle =
    percentage === 0 || lowerBound === 0
      ? studySpace.noQuiz
      : `${lowerBound} ${smartQuiz.of} ${upperBound} ${studySpace.completion}`;

  return (
    <BarContainer>
      <TitleContainer>
        <span>{title}</span>
        <UnderLinedButtonSpan customStyle={styleBuilder.build()}>
          {percentage}%&nbsp;{studySpace.completion}
        </UnderLinedButtonSpan>
      </TitleContainer>
      <BarButtonContair>
        {progressBar}
        <Tooltip rightPopper="-25px" marginTopPopper="12px" placement="bottom-end" title={toolTipTitle}>
          <UnderLinedButtonSpan customStyle={styleBuilder.build()}>
            {percentage}%&nbsp;{studySpace.completion}
          </UnderLinedButtonSpan>
        </Tooltip>
      </BarButtonContair>
    </BarContainer>
  );
};

export type { IProps };
export default ProgressBar;
