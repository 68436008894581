import Auth from '../Auth';
import Form from '../../components/Form';
import BackButton from '../../components/BackButton';
import { fieldConfigs } from '../constants';
import Button from '../../components/Button';
import useAuthForm from '../useAuthForm';
import { useDispatch, useSeoTags } from '../../hooks';
import { useHistory } from 'react-router';

import { SubTitleFormWrapper, BackButtonContainer } from '../styled';
import { en } from '../../../i18n';
import { useEffect } from 'react';
import { ILooseObject } from '../../types';
import { changePasswordPath, forgetPasswordPath } from '../../Router/paths';
import { actions } from '../redux/slice';

const { title, submitText, subTitle } = en.resetPassword;
const { inputCode } = fieldConfigs;

const fields = { inputCode };

const CodeInput = () => {
  const { dispatch } = useDispatch();
  const history = useHistory();
  const { loading, errorFeedback, successFeedback } = useAuthForm({
    errorFeedbackPosition: 'hidden',
    disableResetState: true,
    useDefaultValues: false,
  });

  const seo = useSeoTags({ pageName: 'resetPassword' });

  const { state } = history.location;
  const { email } = (state as ILooseObject) || {};

  useEffect(() => {
    if (!email) {
      history.push(forgetPasswordPath);
    }
  }, []);

  const resendCodeRequest = (formState: ILooseObject) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    // TODO: display error if userName is empty
    dispatch(actions.resendConfirmationCodeRequest({ userName: email }));
  };
  const resendCode = ({ formState }: ILooseObject) => (
    <Button
      loading={loading}
      onClick={resendCodeRequest(formState)}
      className="form-resend-button"
      variant="underlined"
    >
      {submitText}
    </Button>
  );

  const onSubmit = (formState: ILooseObject) => {
    const { code } = formState;
    history.push({ pathname: changePasswordPath, state: { email, code } });
  };

  return (
    <Auth mobileWidth={90} centerFormVertical={false}>
      {seo}
      <SubTitleFormWrapper>
        <BackButtonContainer>
          <BackButton text={en.forgotPassword.back} />
        </BackButtonContainer>
        <Form
          loading={loading}
          fields={fields}
          subTitle={subTitle}
          title={title}
          submitText={submitText}
          CustomButtonComponent={resendCode}
          formSuccess={successFeedback}
          formError={errorFeedback}
          useGlobalError={true}
          onSubmit={onSubmit}
        ></Form>
      </SubTitleFormWrapper>
    </Auth>
  );
};

export default CodeInput;
