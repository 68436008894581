import { Feature, FeaturesContainer, IconContainer, UnstartedQuizContainer } from './styled';
import { Title } from '../OnBoarding/styled';
import { en } from '../../i18n';
const { unstartedQuiz } = en.readinessAssesment;
import { ReactComponent as Watch } from '../../images/icons/readinessAssesment/watch.svg';
import { ReactComponent as PenPaper } from '../../images/icons/readinessAssesment/penPaper.svg';
import { ReactComponent as Graph } from '../../images/icons/readinessAssesment/graph.svg';

const icons = { Watch, PenPaper, Graph };

const UnstartedQuiz = () => {
  // const { quizYourself } = useMembershipProxy();
  // const { buttonDisabled: isButtonDisabled } = quizYourself.run();

  // const getStartButon = () => {
  //   const startButton = (
  //     <ButtonToolTip
  //       buttonProps={{
  //         disabled: isButtonDisabled,
  //         loading: false
  //         onClick: onStart,
  //         size: 'large',
  //         variant: 'primary',
  //         children: buttonText,
  //       }}
  //       extraButtonProps={{ isPlacement }}
  //       buttonOverride={StartQuizButton}
  //       toolTipProps={{
  //         title: buttonDisableText,
  //         leftPopper: '20px',
  //       }}
  //       showToolTip={isButtonDisabled}
  //       ToolTipContainer={StartQuizButtonContainer}
  //     ></ButtonToolTip>
  //   );
  //   return startButton;
  // };
  const renderFeatures = () => {
    return unstartedQuiz.features.map((feature, index) => {
      const Icon = icons[feature.icon as keyof typeof icons];
      return (
        <Feature key={index}>
          <IconContainer>
            <Icon />
          </IconContainer>
          <span>{feature.text}</span>
        </Feature>
      );
    });
  };

  return (
    <UnstartedQuizContainer>
      <Title>{unstartedQuiz.title}</Title>
      <FeaturesContainer>{renderFeatures()}</FeaturesContainer>
    </UnstartedQuizContainer>
  );
};

export default UnstartedQuiz;
