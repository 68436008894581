import { IStepComponent } from '../types';
import { AttachmentButtonContainer, Title } from './styled';
import AttachmentButton from '../../components/AttachmentButton';
import { en } from '../../../i18n';
import useStep from './useStep';

const { apply } = en;

const SubmitScreen = ({ question }: IStepComponent) => {
  const { renderSanitizedText, onUpload, currentResponse } = useStep({ question });

  return (
    <>
      <Title centerMobile>{en.apply.submit.titleA}</Title>
      <Title centerMobile>{renderSanitizedText(en.apply.submit.titleB, true)}</Title>
      <AttachmentButtonContainer>
        <AttachmentButton
          text={apply.uploadResume.buttonText}
          onUpload={onUpload}
          externalFile={currentResponse as File}
        />
      </AttachmentButtonContainer>
    </>
  );
};

export default SubmitScreen;
