import { styled } from '@mui/material/styles';

const ModalContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
`;

export { ModalContainer };
