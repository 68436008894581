import { IAttempt, ILooseObject, IQuestion, TApplyResponse } from '../types';
import EducationLevel from './steps/EducationLevel';
import Experience from './steps/Experience';
import Certifications from './steps/Certifications';
import EmploymentTypes from './steps/EmploymentTypes';
import HealthCareRating from './steps/HealthCareRating';
import ShiftWork from './steps/ShiftWork';
import Location from './steps/Location';
import Commute from './steps/Commute';
import Relocate from './steps/Relocate';
import License from './steps/License';
import UploadResume from './steps/UploadResume';
import SubmitScreen from './steps/SubmitScreen';
import ConfirmMissingCV from './steps/ConfirmMissingCV';
import Notifications from './steps/Notifications';
import { useSelector } from 'react-redux';
import { applySelector } from './redux/slice';
import Exit from './steps/Exit';
import { supportedCountries, supportedStates } from '../classes/LocationInterface';

import { en } from '../../i18n';
const { apply } = en;

interface IUseSteps {
  index: number;
  attempt?: IAttempt;
}

type TCheckDisabled = TApplyResponse | TApplyResponse[] | ILooseObject;
interface IStep {
  id: string;
  Component: React.ElementType<any>;
  checkDisabled?: (currentResponse: TCheckDisabled, question?: IQuestion | null) => boolean;

  isExit?: boolean;
  buttonText?: string;
}

const checkDisabledMatrix = (response?: TCheckDisabled, currentQuestion?: IQuestion | null) => {
  const currentResponse = response as ILooseObject;
  if (!currentQuestion || !currentResponse) return true;
  const choices = currentQuestion.content.choices?.map(choice => choice.id);
  if (!choices) return true;

  return !choices.every(choiceId => currentResponse[choiceId] !== null && currentResponse[choiceId] !== undefined);
};

const questionData = {
  expierence: {
    id: '04b8214a-5497-11ef-b864-0242ac120002',
    Component: Experience,
  },

  educationLevel: {
    id: '04b82794-5497-11ef-b864-0242ac120002',
    Component: EducationLevel,
  },

  certification: {
    id: '04b828ca-5497-11ef-b864-0242ac120002',
    Component: Certifications,
    checkDisabled: (currentResponse?: any) => {
      return false;
      // if (!currentResponse) return true;
      // const valueExists = currentResponse.filter(item => item).length > 0;
      // return !valueExists;
    },
  },

  employmentTypes: {
    id: '04b82a1e-5497-11ef-b864-0242ac120002',
    Component: EmploymentTypes,
    checkDisabled: checkDisabledMatrix,
  },

  healthcareRating: {
    id: '04b82f1e-5497-11ef-b864-0242ac120002',
    Component: HealthCareRating,
    checkDisabled: checkDisabledMatrix,
  },

  shiftWork: {
    id: '04b83036-5497-11ef-b864-0242ac120002',
    Component: ShiftWork,
  },

  location: {
    id: '04b831da-5497-11ef-b864-0242ac120002',
    Component: Location,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      return !(currentResponse?.country && currentResponse?.state);
    },
  },

  commute: {
    id: '04b832ca-5497-11ef-b864-0242ac120002',
    Component: Commute,
  },

  relocate: {
    id: '04b836c6-5497-11ef-b864-0242ac120002',
    Component: Relocate,
    checkDisabled: checkDisabledMatrix,
  },

  license: {
    id: '04b837b6-5497-11ef-b864-0242ac120002',
    Component: License,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      if (!currentResponse) return true;
      if (!currentResponse.locationA && !currentResponse.locationB) return true;
      return (
        !(currentResponse.locationA?.country && currentResponse.locationA?.state) &&
        !(currentResponse.locationB?.country && currentResponse.locationB?.state)
      );
    },
  },

  uploadResume: {
    id: '04b838a6-5497-11ef-b864-0242ac120002',
    Component: UploadResume,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
  },
  submit: {
    id: '04b838a6-5497-11ef-b864-0242ac120002',
    Component: SubmitScreen,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
    buttonText: apply.submitButton,
  },
  confirmMissing: {
    id: '04b838a6-5497-11ef-b864-0242ac120002',
    Component: ConfirmMissingCV,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
    buttonText: apply.submitButton,
  },
  notifications: {
    id: '030dabb2-5497-11ef-b864-0242ac120002',
    Component: Notifications,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      if (!currentResponse) return true;
      switch (true) {
        case currentResponse?.email?.checked && !currentResponse.text?.checked:
          return false;

        case currentResponse?.text?.checked:
          if (currentResponse?.text?.number) return false;
      }

      return true;
    },
    buttonText: apply.submitButton,
  },
  noService: {
    id: '030dabb2-5497-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported={false} {...props} />,
    isExit: true,
  },

  suportedService: {
    id: '030dabb2-5497-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported {...props} />,
    isExit: true,
  },
};

const baseSteps: IStep[] = [
  questionData.expierence,
  questionData.educationLevel,
  questionData.certification,
  questionData.employmentTypes,
  questionData.healthcareRating,
  questionData.shiftWork,
  questionData.location,
  questionData.commute,
  questionData.relocate,
  questionData.license,
  questionData.uploadResume,
  //questionData.noService,
  //questionData.suportedService,
];

const allRequiredSteps: IStep[] = [...baseSteps, questionData.notifications];

const useSteps = ({ index, attempt }: IUseSteps) => {
  const { questionAnswers } = useSelector(applySelector.allState);

  const getSteps = () => {
    let stepsFlow: IStep[] = baseSteps;
    const isCVUploaded = questionAnswers[questionData.uploadResume.id];
    if (isCVUploaded?.response && (isCVUploaded?.response as ILooseObject).name)
      stepsFlow = [...stepsFlow, questionData.submit as IStep];
    else stepsFlow = [...stepsFlow, questionData.confirmMissing as IStep];

    const location = questionAnswers[questionData.location.id];
    const willingToRelocate = questionAnswers[questionData.relocate.id] as ILooseObject;
    if (!location?.response) {
      stepsFlow = [...stepsFlow, questionData.noService];
      return stepsFlow;
    }

    const { country, state } = location.response as ILooseObject;

    if (!supportedCountries.includes(country)) {
      stepsFlow = [...stepsFlow, questionData.noService];
      return stepsFlow;
    }

    const allowedStates = supportedStates[country];
    if (!allowedStates.includes(state) && willingToRelocate?.response?.anotherProvince !== 'yes') {
      stepsFlow = [...stepsFlow, questionData.noService];
      return stepsFlow;
    }

    stepsFlow = [...stepsFlow, questionData.notifications as IStep, questionData.suportedService];
    return stepsFlow;
  };

  const getStep = () => {
    const steps = getSteps();
    if (index === -1) {
      return steps[steps.length - 1];
    }

    return steps[index];
  };

  const step = getStep();

  const renderStep = () => {
    const question = getCurrentQuestion();
    if (!question) return null;
    //const step = [index];
    const { Component } = step;
    return <Component question={question} />;
  };

  const getCurrentQuestion = (overrideId?: string) => {
    if (!attempt || !attempt.questionsTransformed) return null;
    const { id: questionId } = step;
    const question = attempt.questionsTransformed[overrideId || questionId];
    return question;
  };
  return {
    renderStep,
    currentStep: step,
    getCurrentQuestion,
    getSteps,
  };
};

export type { IStep };
export { questionData, allRequiredSteps };
export default useSteps;
