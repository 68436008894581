import { useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { getOrbitsConfig } from '../../utils';
import { loadStripe } from '@stripe/stripe-js';
import { MembershipContainer, RadialBackground } from './styled';
import Landing from './Landing';
import LandingSubscribed from './LandingSubscribed';
import useMembershipPlan from './hooks/useMembershipPlan';
import Spinner from '../components/Spinner';
import PaymentForm from './PaymentForm';
import SubscriptionModal from './SubscriptionModal';
import { useSeoTags } from '../hooks';
import { useLocation } from 'react-router-dom';
import { IPlan } from './plans';
import { BugSnag } from '../classes';
import { colors } from '../../themes';

const config = getOrbitsConfig();

interface IRouteState {
  plan?: IPlan;
}

let stripePromise: any = null;
try {
  stripePromise = loadStripe(config?.stripePublishableKey || '');
} catch (e) {
  new BugSnag().notify(new Error('Failed to load Stripe.js'));
}

const Membership = () => {
  const { state, control } = useMembershipPlan();
  const isAlreadyAutoOpened = useRef<boolean>(false);
  const seo = useSeoTags({ pageName: 'membership' });
  const location = useLocation();
  const {
    currentSubscription,
    incompleteSubscriptionPriceId,
    isPageLoading,
    loading,
    modalVariant,
    showModal,
    isActive,
    stripePlan,
    isPaymentIssue,
  } = state;
  const { setPrice, showPaymentFormPopup, handleCancelSubscription } = control;
  const routeState = location.state as IRouteState;

  // useEffect(() => {
  //   if (
  //     isAlreadyAutoOpened.current ||
  //     !currentSubscription ||
  //     !(currentSubscription.status === ESubscriptionsStatus.INCOMPLETE)
  //   )
  //     return;
  //   showPaymentFormPopup(defaultPlan);
  //   isAlreadyAutoOpened.current = true;
  // }, [currentSubscription]);

  useEffect(() => {
    if (!routeState?.plan) return;
    setPrice(routeState.plan.priceId);
    showPaymentFormPopup(routeState.plan);
    isAlreadyAutoOpened.current = true;
  }, []);

  useEffect(() => {
    if (incompleteSubscriptionPriceId) setPrice(incompleteSubscriptionPriceId);
  }, [incompleteSubscriptionPriceId]);

  const renderContent = () => {
    if (isPageLoading) return <Spinner size="48px" appplyBackground loading spinnerColor={colors.primary} />;
    if (isActive || isPaymentIssue) return <LandingSubscribed />;
    return (
      <>
        <Landing />
      </>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      {seo}
      <MembershipContainer>
        <RadialBackground>{renderContent()}</RadialBackground>
        <PaymentForm />
        {showModal && (
          <SubscriptionModal
            plan={{
              ...currentSubscription,
            }}
            loading={loading}
            handleCancelSubscription={handleCancelSubscription}
            variant={modalVariant}
            stripePlan={stripePlan}
          />
        )}
      </MembershipContainer>
    </Elements>
  );
};

export default Membership;
