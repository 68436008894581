import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useDispatch } from '../../hooks';
import { SLICE_NAME as studySpaceSlice, actions, quizSelectors } from '../../StudySpace/redux/slice';
import {
  ResultsContainer as ResultsContainerStyled,
  ResultsSubtitle,
  ResultsTitle,
  ResultsTextContainer as ResultsTextContainerStyled,
  ConceptsList,
  Concept,
  PurpleText,
} from './styled';
import { ReactComponent as BadFace } from '../../../images/badFace.svg';
import { ReactComponent as GoodFace } from '../../../images/goodFace.svg';
import { en } from '../../../i18n';
import { IQuestionAnswersState, ITopic } from '../../types';
import { Dictionary } from '@reduxjs/toolkit';
import usePropsOverride from '../../hooks/usePropsOverride';
import { getCaseStudyLength } from '../../utils';

const {
  youGot,
  marksScored,
  correctConcepts,

  of,
  incorrectConcepts,
} = en.smartQuiz;

interface IFeedback {
  topics: Dictionary<ITopic>;
  total: number;
  totalMark: number;
  totalMaximumMark: number;
  correctTopics: string[];
  incorrectTopics: string[];
  ResultsContainer?: React.ElementType<any>;
  ResultsTextContainer?: React.ElementType<any>;
  overrides?: Partial<IFeedback>;
}

const Feedback = ({ overrides, ...props }: IFeedback) => {
  const {
    topics,
    total,
    totalMark,
    totalMaximumMark,
    correctTopics,
    incorrectTopics,
    ResultsContainer = ResultsContainerStyled,
    ResultsTextContainer = ResultsTextContainerStyled,
  } = usePropsOverride<IFeedback>(props, overrides);

  const location = useLocation();
  const { dispatch } = useDispatch(studySpaceSlice);
  const { attempts, currentAttempt } = useSelector(quizSelectors.allState);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const attemptId = query.get('attemptId');
    const attempt = attempts?.find(attempt => attempt.attemptId === attemptId);
    if (attempt && attemptId !== currentAttempt?.attemptId) dispatch(actions.setCurrentAttempt({ attempt }));
  }, []);

  const renderTopics = (inputTopicsIds: string[]) => {
    return (
      <ConceptsList>
        {inputTopicsIds.map(topicId => {
          const topic = topics[topicId];
          return (
            <Concept key={topicId}>
              <span>{topic?.name}</span>
            </Concept>
          );
        })}
      </ConceptsList>
    );
  };

  return (
    <ResultsContainer>
      <ResultsTextContainer>
        <ResultsTitle>
          {youGot} <PurpleText>{totalMark}</PurpleText> {of} <PurpleText>{totalMaximumMark}</PurpleText> {marksScored}
        </ResultsTitle>
        {correctTopics.length > 0 && (
          <div>
            <ResultsSubtitle>
              <GoodFace /> {correctConcepts}{' '}
            </ResultsSubtitle>
            {renderTopics(correctTopics)}
          </div>
        )}

        {incorrectTopics.length > 0 && (
          <div>
            <ResultsSubtitle>
              <BadFace /> {incorrectConcepts}
            </ResultsSubtitle>
            {renderTopics(incorrectTopics)}
          </div>
        )}
      </ResultsTextContainer>
    </ResultsContainer>
  );
};

export type { IFeedback };
export default Feedback;
