abstract class BaseTransformer {
  // TODO: rename this to transormIn
  public transform(data: any): any {
    return data;
  }

  public transformOut(data: any) {
    return data;
  }
}

export default BaseTransformer;
