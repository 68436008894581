import { useMemo, useState } from 'react';
import { IAttempt, ILooseObject } from '../../../types';
import { SectionContainer } from '../../styled';
import { Menu, MenuItem, MenuButton } from '../../../Home/styled';
import { useKeyDownPress, useNavigations } from '../../../hooks';
import { useSelector } from 'react-redux';
import { topicsSelectors } from '../../../StudySpace/redux/topics/slice';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as Arrow } from '../../../../images/polygon.svg';
import MasteryLevel from '../MasteryLevel';
import DesktopTable from './DesktopTable';
import { useMediaQuery, useTheme } from '@mui/material';
import MobileCards from './MobileCards';
import OutsideClickHandler from 'react-outside-click-handler';
import { en } from '../../../../i18n';

import QuestionProgress from '../QuestionProgress';
import QuestionTypes from './QuestionTypes';

const { resultsTopicFilterButton } = en.reviewResults;
interface ITopicAnalysis {
  attempt: IAttempt;
  topicsResults: ILooseObject;
}

const TopicsTable = ({ attempt, topicsResults }: ITopicAnalysis) => {
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const { navigateToResultsAnswers } = useNavigations();
  const [menusOpen, setMenusOpen] = useState<{ [key: string]: boolean }>({});

  useKeyDownPress('Escape', () => setMenusOpen({}));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tableData = useMemo(() => {
    const mapPercentageToMasteryLevel = (percentage: number) => {
      switch (true) {
        case percentage >= 0.9:
          return 4;
        case percentage >= 0.7:
          return 3;
        case percentage >= 0.6:
          return 2;
        case percentage >= 0.5:
          return 1;
        default:
          return 0;
      }
    };

    const tableData = Object.keys(topicsResults)
      .filter(topicId => allTopics[topicId])
      .map(topicId => {
        const { totalMark, totalMaximumMark, percentage } = topicsResults[topicId];
        const mastery = mapPercentageToMasteryLevel(percentage);

        const viewResults = () => navigateToResultsAnswers(attempt, [topicId]);
        const questionProgress = (
          <QuestionProgress
            totalMark={totalMark}
            totalMaximumMark={totalMaximumMark}
            percentage={percentage}
            progressBarOverrides={{ useMountAnimation: false }}
          />
        );
        const menuButton = (
          <MenuButton
            variant="secondary"
            onClick={() =>
              setMenusOpen(current => {
                if (current[topicId]) return { [topicId]: false };
                return { [topicId]: true };
              })
            }
          >
            <MoreHorizIcon />
            <Menu open={menusOpen[topicId]}>
              <Arrow />
              <MenuItem disabled={false} onClick={viewResults}>
                {resultsTopicFilterButton}
              </MenuItem>
            </Menu>
          </MenuButton>
        );

        return {
          id: topicId,
          topicName: allTopics[topicId]?.name,
          mastery: <MasteryLevel level={mastery} />,
          questionProgress,
          viewResults,
          questionTypes: <QuestionTypes topicId={topicId} attempt={attempt} />,
          menuButton,
        };
      });
    return tableData;
  }, [topicsResults, allTopics, menusOpen]);

  return (
    <SectionContainer>
      {isMobile ? (
        <MobileCards data={tableData} />
      ) : (
        <OutsideClickHandler onOutsideClick={() => setMenusOpen({})}>
          <DesktopTable data={tableData} />
        </OutsideClickHandler>
      )}
    </SectionContainer>
  );
};

export default TopicsTable;
