import { en } from '../../../i18n';
import { ReactComponent as Icon } from '../../../images/icons/dashboard/assess.svg';
import { IAttempt } from '../../types';
import { TabContainer } from '../Components/styled';
import TabTitle from '../Components/TabTitle';
import InitialQuizzes from './InitialQuizzes';
import { IHomePageSection } from '../Components/types';
import Readiness from '../Readiness';

const { title, baselineQuiz, readiness } = en.dashboard.assess;

interface IAssess extends IHomePageSection {
  currentReadinessAttempt?: IAttempt;
  readinessAttempts?: IAttempt[];
}

const Assess = ({ attempts, id, currentReadinessAttempt, readinessAttempts }: IAssess) => {
  const renderReadiness = () => {
    if (!currentReadinessAttempt || !readinessAttempts) return null;
    return <Readiness currentReadinessAttempt={currentReadinessAttempt} readinessAttempts={readinessAttempts} />;
  };

  return (
    <TabContainer id={`${id}`}>
      <TabTitle Icon={Icon} text={title} />
      <InitialQuizzes />
      {renderReadiness()}
    </TabContainer>
  );
};

export default Assess;
