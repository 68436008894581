import React from 'react';
import Button, { IButtonProps } from '../Button';
import CustomTooltip, { ITooltipProps } from '../Tooltip';
import { ILooseObject } from '../../types';

interface IButtonTooolTipPros {
  buttonProps: IButtonProps;
  toolTipProps: Partial<ITooltipProps>;
  buttonOverride?: React.ElementType<any>;
  showToolTip: boolean;
  ToolTipContainer?: React.ElementType<any>;
  extraButtonProps?: ILooseObject;
  children?: React.ReactNode;
}

const ButtonToolTip = ({
  buttonProps,
  toolTipProps,
  ToolTipContainer,
  buttonOverride,
  showToolTip,
  extraButtonProps,
}: IButtonTooolTipPros) => {
  const ButtonComponent = buttonOverride || Button;

  const button = <ButtonComponent {...buttonProps} {...extraButtonProps} />;

  if (!showToolTip) return button;

  return (
    <CustomTooltip
      fontSize="0.875rem"
      arrow={false}
      centerText
      placement="top"
      enterTouchDelay={0}
      disableFocusListener
      title="asfasf"
      {...toolTipProps}
    >
      {ToolTipContainer ? <ToolTipContainer>{button}</ToolTipContainer> : button}
    </CustomTooltip>
  );
};

export default ButtonToolTip;
