import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import rootSaga from './rootSaga';
import global from './globalSlice';
import { useDispatch } from 'react-redux';
import topics from '../StudySpace/redux/topics/slice';
import categories from '../StudySpace/redux/categories/slice';
import quiz from '../StudySpace/redux/slice';
import auth from '../Auth/redux/slice';
import events from '../events/redux/slice';
import onboarding from '../OnBoarding/redux/slice';
import subscriptions from '../Membership/redux/slice';
import nurseGPT from '../NurseGPT/redux/slice';
import apply from '../Apply/redux/slice';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const connectedRouterMiddleware = routerMiddleware(history);

const store = configureStore({
  reducer: {
    routing: routerReducer,
    global,
    topics,
    categories,
    quiz,
    auth,
    events,
    onboarding,
    subscriptions,
    nurseGPT,
    apply,
  },
  middleware: [sagaMiddleware, connectedRouterMiddleware],
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
export { history };
