import { colors } from '../../../themes';
import ProgressBar, { IProps as IProgressBarProps } from '../../components/ProgressBar';
import { IAttempt } from '../../types';
import { ProgressFooter, QuizProgressContainer } from '../styled';
import { en } from '../../../i18n';

// const { quizProgress } = en.reviewResults;

interface IQuestionProgress {
  percentage: number;
  totalMaximumMark: number;
  totalMark: number;
  progressBarOverrides?: Partial<IProgressBarProps>;
}

const QuestionProgress = ({ percentage, totalMaximumMark, totalMark, progressBarOverrides }: IQuestionProgress) => {
  // const incorrectQuestions = () => {
  //   const answered = answeredQuestions || upperBound;
  //   return answered - totalCorrectQuestions;
  // };

  return (
    <QuizProgressContainer>
      <h1>
        {totalMark} of {totalMaximumMark}
      </h1>
      <ProgressBar
        useBarOnly
        barHeight="8px"
        color={colors.highlightGreen}
        backgroundColor={colors.errorRed}
        percentage={percentage * 100}
        upperBound={totalMaximumMark}
        lowerBound={0}
        useBarStripes={false}
        useMountAnimation
        {...progressBarOverrides}
      />
      {/* <ProgressFooter>
        <span>
          {totalCorrectQuestions} {quizProgress.leftLabel}
        </span>
        <span>
          {incorrectQuestions()} {quizProgress.rightLabel}
        </span>
      </ProgressFooter> */}
    </QuizProgressContainer>
  );
};

export default QuestionProgress;
