import { TabContainer } from '../Components/styled';
import TabTitle from '../Components/TabTitle';
import { ReactComponent as Icon } from '../../../images/icons/dashboard/analyze.svg';
import { EQuizTypes, IAttempt, ILooseObject } from '../../types';
import { en } from '../../../i18n';
import { NoQuizzes, NoQuizzesTextDisclaimer, TitleContainer } from '../ReviewInsigts/styled';
import Button from '../../components/Button';
import {
  TabContentAnalyze,
  TopicBox,
  TopicBoxesContainer,
  TopicsFilter,
  TopicsFilterContainer,
  TopicsTitle,
  Menu,
  MenuButton,
  MenuItem,
} from './styled';
import { useState } from 'react';
import useSubject from '../Subject/useSubject';
import { ReactComponent as TopicsIcon } from '../../../images/icons/dashboard/topicsTitle.svg';
import { useSelector } from 'react-redux';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as Arrow } from '../../../images/polygon.svg';
import { useMembershipProxy, useStartQuiz } from '../../hooks';
import { topicsWithCaseStudies } from '../../StudySpace/staticConstants';
import { IHomePageSection } from '../Components/types';
import CustomTooltip from '../../components/Tooltip';

const { dashboard } = en;
const {
  title,
  button,
  strongTopics: strongTopicsTitle,
  weakTopics: weakTopicsTitle,
  innerTitle,
  quizOnTopic,
  caseStudyOnTopic,
  noTopicsTitle,
  noTopicsSubTitle,
  startQuizbutton,
} = dashboard.analyze;

const { toolTip } = dashboard.reviewPastQuizzes;

enum ESelectedTopics {
  WEAK,
  STRONG,
}
const Analyze = ({ attempts, id }: IHomePageSection) => {
  const [selectedTopics, setSelectedTopics] = useState<ESelectedTopics>(ESelectedTopics.STRONG);
  const { getStrongTopics, getWeakTopics } = useSubject({ attempts });
  const weakTopics = getWeakTopics();
  const strongTopics = getStrongTopics();
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const [menusOpen, setMenusOpen] = useState<ILooseObject>({});
  const { quizYourself } = useMembershipProxy({ useStrict: true });
  const { buttonDisabled } = quizYourself.run();
  const [isAscending, setIsAscending] = useState(true);

  const { startQuiz } = useStartQuiz();

  const handleTopicMenuClick = (id: string) => () => {
    setMenusOpen({ ...menusOpen, [id]: !menusOpen[id] });
  };

  const onSelectTopic = (topicType: ESelectedTopics) => () => {
    setIsAscending(true);
    setSelectedTopics(topicType);
  };

  const handleStartQuiz = (topicId: string, quizType: EQuizTypes) => {
    if (quizType === EQuizTypes.NGN_CASE_STUDY && !topicsWithCaseStudies.includes(topicId)) return;
    startQuiz([topicId], [quizType]);
  };
  const renderTopics = () => {
    const dataSource = selectedTopics === ESelectedTopics.WEAK ? weakTopics : strongTopics;
    if (!dataSource || dataSource.length === 0) {
      return (
        <NoQuizzes>
          <NoQuizzesTextDisclaimer>
            <h1>{noTopicsTitle(selectedTopics === ESelectedTopics.STRONG)}</h1>
            <h2>{noTopicsSubTitle}</h2>
          </NoQuizzesTextDisclaimer>
          <Button
            variant="secondary"
            onClick={() => {
              window.scrollTo({ behavior: 'smooth', top: 0 });
            }}
          >
            {startQuizbutton}
          </Button>
        </NoQuizzes>
      );
    }
    const dataSourceFilter = dataSource.filter(topicId => allTopics[topicId]);
    return dataSourceFilter
      .sort((a, b) => {
        const topicAName = allTopics[a]?.name as string;
        const topicBName = allTopics[b]?.name as string;
        if (isAscending) return topicAName.localeCompare(topicBName);
        return topicBName.localeCompare(topicAName);
      })
      .map(topicId => (
        <TopicBox key={topicId}>
          <div>{allTopics[topicId]?.name}</div>

          <MenuButton variant="secondary" onClick={handleTopicMenuClick(topicId)}>
            <MoreHorizIcon />
            <Menu open={menusOpen[topicId]}>
              <Arrow />
              <MenuItem disabled={buttonDisabled} onClick={() => handleStartQuiz(topicId, EQuizTypes.MULTIPLE_CHOICE)}>
                {quizOnTopic}
              </MenuItem>
              <MenuItem
                disabled={!topicsWithCaseStudies.includes(topicId) || buttonDisabled}
                onClick={() => handleStartQuiz(topicId, EQuizTypes.NGN_CASE_STUDY)}
              >
                {caseStudyOnTopic}
              </MenuItem>
            </Menu>
          </MenuButton>
        </TopicBox>
      ));
  };

  const isEmptyTopics = () => {
    const dataSource = selectedTopics === ESelectedTopics.WEAK ? weakTopics : strongTopics;
    const dataSourceFilter = dataSource?.filter(topicId => allTopics[topicId]);
    return !dataSource || dataSourceFilter.length === 0;
  };

  const insightsButton = (
    <Button disabled={attempts?.length === 0} onClick={() => {}} variant="secondayGreyDisable">
      {button}
    </Button>
  );

  const buttonWithToolTip = (
    <CustomTooltip width="100%" arrow={false} title={toolTip}>
      <span>{insightsButton}</span>
    </CustomTooltip>
  );

  const toggleSort = () => {
    setIsAscending(!isAscending);
  };

  return (
    <TabContainer id={`${id}`}>
      <TitleContainer>
        <TabTitle Icon={Icon} text={title} />
        {/* {attempts?.length === 0 ? buttonWithToolTip : insightsButton} */}
      </TitleContainer>
      <TabContentAnalyze>
        <TopicsFilterContainer>
          <TopicsFilter
            onClick={onSelectTopic(ESelectedTopics.STRONG)}
            isSelected={selectedTopics === ESelectedTopics.STRONG}
          >
            {strongTopicsTitle}
          </TopicsFilter>
          <TopicsFilter
            onClick={onSelectTopic(ESelectedTopics.WEAK)}
            isSelected={selectedTopics === ESelectedTopics.WEAK}
          >
            {weakTopicsTitle}
          </TopicsFilter>
        </TopicsFilterContainer>
        {!isEmptyTopics() && (
          <TopicsTitle onClick={toggleSort}>
            <span>{innerTitle}</span> <TopicsIcon />
          </TopicsTitle>
        )}
        <TopicBoxesContainer>{renderTopics()}</TopicBoxesContainer>
      </TabContentAnalyze>
    </TabContainer>
  );
};

export default Analyze;
