import useLocation, { IUseLocation, notAvailable } from './useLocation';
import { en } from '../../../i18n';
import { DropDownTitleContainer, MobileAutoComplete, Title } from './styled';
import DropDown from '../Form/Fields/DropDown';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import { StyledTextField } from '../Form/Fields/styled';
import { ILooseObject } from '../../types';
import { useMemo } from 'react';

const { location } = en;

interface IUseLocationUI extends IUseLocation {
  hideOnDisabled?: boolean;
}

const useLocationUI = ({ onChangeExternal, externalState, hideOnDisabled, index = 0 }: IUseLocationUI) => {
  const locationData = useLocation({ onChangeExternal, externalState });
  const { state: locationState, control, data } = locationData;
  const { country, state, city } = locationState;
  const {
    onChangeCountries,
    onChangeState,
    onChangeCity,
    renderCountryOptions,
    onChangeStateMobile,
    onChangeCityMobile,
    onChangeCountryMobile,
    getCountryOptions,
    renderCountryOption,
  } = control;
  const { states, cities } = data;
  const theme = useTheme();

  const classesOverride = {
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  };

  const countryOptions = useMemo(() => getCountryOptions(), []);

  const countryDropDownValue = countryOptions?.find(countryOption => countryOption.id === country);
  const stateDropDownValue = states?.find(stateOption => stateOption.id === state);
  const cityDropDownValue = cities?.find((cityOption: ILooseObject) => cityOption.id === city);

  // const countryDropdown = (
  //   <DropDown
  //     classesOverride={classesOverride}
  //     fieldKey="dropDown"
  //     onChange={onChangeCountries}
  //     value={country}
  //     size="small"
  //     options={[]}
  //     renderOptionsOverride={renderCountryOptions}
  //     notched={false}
  //     key="country"
  //   />
  // );

  const countryDropdown = (
    <Autocomplete
      onChange={onChangeCountryMobile}
      value={countryDropDownValue}
      options={countryOptions}
      getOptionLabel={option => option.label}
      renderOption={renderCountryOption}
      isOptionEqualToValue={(option, value) => {
        return value && option && option.id === value.id;
      }}
      renderInput={params => <MobileAutoComplete {...params} size="small" />}
    />
  );
  const countryDropdownTitle = (
    <DropDownTitleContainer>
      <Title>{location.country}</Title>
      {countryDropdown}
    </DropDownTitleContainer>
  );

  const statesDisabled = states.length === 1 && states[0].id === notAvailable;

  // const stateDropDown = (
  //   <DropDown
  //     classesOverride={classesOverride}
  //     fieldKey="dropDownState"
  //     onChange={onChangeState}
  //     options={states}
  //     disabled={statesDisabled}
  //     value={state}
  //     size="small"
  //     placeholder={!country ? location.statePlaceHolder : undefined}
  //     notched={false}
  //   />
  // );'

  // const stateDropDown = (
  //   <Autocomplete options={states} renderInput={params => <TextField {...params} size="small" />} />
  // );

  const stateDropDown = (
    <Autocomplete
      key={`${index}-${country}`}
      placeholder={!country ? location.statePlaceHolder : undefined}
      onChange={onChangeStateMobile}
      disabled={statesDisabled}
      value={stateDropDownValue}
      options={states}
      renderInput={params => <MobileAutoComplete {...params} size="small" />}
    />
  );

  const stateDropDownTitle = (
    <DropDownTitleContainer>
      <Title>{location.province}</Title>
      {stateDropDown}
    </DropDownTitleContainer>
  );

  // const cityDropDown = (
  //   <DropDown
  //     classesOverride={classesOverride}
  //     fieldKey="dropDownCity"
  //     onChange={onChangeCity}
  //     options={cities}
  //     value={city}
  //     size="small"
  //     placeholder={!state ? location.cityPlaceHolder : undefined}
  //     disabled={statesDisabled}
  //     notched={false}
  //   />
  // );

  const cityDropDown = (
    <Autocomplete
      key={`${index}-${country}-${state}`}
      onChange={onChangeCityMobile}
      disabled={statesDisabled}
      value={cityDropDownValue}
      options={cities}
      placeholder={!state ? location.cityPlaceHolder : undefined}
      renderInput={params => <MobileAutoComplete {...params} size="small" />}
    />
  );

  const cityDropDownTitle = (
    <DropDownTitleContainer>
      <Title>{location.city}</Title>
      {cityDropDown}
    </DropDownTitleContainer>
  );

  const hidden = statesDisabled && hideOnDisabled;

  return {
    dropDowns: {
      countryDropdown,
      stateDropDown: hidden ? null : stateDropDown,
      cityDropDown: hidden ? null : cityDropDown,
    },
    titleDropDowns: {
      countryDropdownTitle,
      stateDropDownTitle: hidden ? null : stateDropDownTitle,
      cityDropDownTitle: hidden ? null : cityDropDownTitle,
    },
  };
};

export type { IUseLocationUI };
export default useLocationUI;
