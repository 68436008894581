import { useEffect } from 'react';
import { useAuthorization, useDispatch } from '../hooks';
import { SLICE_NAME as subscriptionsSlice, actions, subscriptionsSelectors } from '../Membership/redux/slice';
import { useSelector } from 'react-redux';
import { ESubscriptionsStatus } from '../types';
import LocalStorageWithTTL from '../classes/LocalStorageWithTTL';
import { TRIAL_ENDED_MODAL_SHOWN } from '../constants';
import { IDeprecatedSubscription, deprecatedSubscriptions } from '../Membership/plans';
import { CurrentProfile } from '../classes';

interface IUseSubscriptions {
  useFetchSubscriptions?: boolean;
}

const useSubscriptions = (props?: IUseSubscriptions) => {
  const { useFetchSubscriptions = true } = props || {};
  const { dispatch } = useDispatch(subscriptionsSlice);
  const {
    currentSubscription,
    triallingSubscription,
    loading,
    subscriptionsFound,
    isTrialEndedModalShown,
    isTrialEnded,
    addons,
    isSponsored,
  } = useSelector(subscriptionsSelectors.allState);

  const isCancelled = currentSubscription?.cancelAtPeriodEnd;
  const isActive = currentSubscription?.status === ESubscriptionsStatus.ACTIVE;

  const isPaymentIssue = currentSubscription?.status === ESubscriptionsStatus.PAST_DUE;

  const getIsTrialling = () => {
    if (isActive || isTrialEnded) return false;
    if (currentSubscription?.status === ESubscriptionsStatus.TRIALING || triallingSubscription) return true;

    return false;
  };
  const isTrialling = getIsTrialling();
  const isSubscribed = !currentSubscription || isActive || isTrialling;
  const isSubscribedStrict = isActive || isTrialling;
  const isFreeUserStrict = subscriptionsFound !== undefined && !isSubscribedStrict;

  const isIncomplete = currentSubscription?.status === ESubscriptionsStatus.INCOMPLETE;
  // Trialling check to handle new users who will be trialling with old subscriptions on the release day
  const isDeprecatedSubscription = !isTrialling && currentSubscription?.isDeprecated;

  const getDeprecatedSubscription = (): IDeprecatedSubscription | undefined => {
    if (!isDeprecatedSubscription) return;
    const sub = Object.values(deprecatedSubscriptions).find(
      deprecatedSub => deprecatedSub.priceId === currentSubscription?.items?.data?.[0].price.id,
    );
    return sub;
  };
  const deprecatedSubscription = getDeprecatedSubscription();
  useEffect(() => {
    if (!currentSubscription && useFetchSubscriptions) {
      dispatch(actions.fetchSubscriptionRequest());
      dispatch(actions.fetchAddonsRequest());
    }
  }, []);

  useEffect(() => {
    if (isPaymentIssue && useFetchSubscriptions) {
      dispatch(actions.setPaymentIssueModal(true));
    }
  }, [isPaymentIssue]);

  useEffect(() => {
    if (isDeprecatedSubscription && useFetchSubscriptions) {
      dispatch(actions.setDeprecatedSubscriptionModal(true));
    }
  }, [isDeprecatedSubscription]);

  useEffect(() => {
    if (isSubscribedStrict || subscriptionsFound || subscriptionsFound === undefined) return;
    const localstorage = new LocalStorageWithTTL(TRIAL_ENDED_MODAL_SHOWN);
    const data = localstorage.get(false, true);

    if (data) return;

    if (!isTrialling) dispatch(actions.setisTrialEndedModalShown(false));
    //localstorage.set(true);
    //dispatch(actions.createTrialRequest());
  }, [subscriptionsFound, isTrialling]);

  useEffect(() => {
    if (!isTrialEndedModalShown) {
      const localstorage = new LocalStorageWithTTL(TRIAL_ENDED_MODAL_SHOWN);
      localstorage.set(true);
    }
  }, [isTrialEndedModalShown]);

  const refreshSubscriptions = () => {
    dispatch(actions.refreshSubscriptions());
  };

  const fetchSubscriptionPolling = () => {
    if (isActive || isTrialling) return;
    dispatch(actions.fetchSubscriptionRequestPolling());
  };

  const setTrialEnded = () => {
    dispatch(actions.setisTrialEndedModalShown(false));
  };

  return {
    currentSubscription,
    isCancelled,
    isSubscribed,
    isIncomplete,
    isSubscribedStrict,
    isActive,
    isTrialling,
    refreshSubscriptions,
    triallingSubscription,
    fetchSubscriptionPolling,
    loading,
    setTrialEnded,
    isFreeUserStrict: !!isFreeUserStrict,
    isPaymentIssue,
    addons,
    isDeprecatedSubscription,
    deprecatedSubscription,
    isSponsored,
  };
};

export default useSubscriptions;
