import { styled } from '@mui/material/styles';

const DropdownContainer = styled('div')`
  width: 100%;

  .MuiInputBase-root {
    width: 90%;
    position: relative;
  }

  svg {
    position: static;
    transform: none;
  }
`;

export { DropdownContainer };
