import { shouldForwardProps } from '../utils';
import { styled } from '@mui/material/styles';
import { colors } from '../../themes';

import { zoomOutAnimation } from '../sharedStyled';

interface IFormFooter {
  applyBorderRadius?: boolean;
  applyFormFooterOnMobile?: boolean;
}

interface IAuthFormInsideContainer {
  centerFormVertical?: boolean;
  mobileWidth?: number;
  tallForm?: boolean;
}

interface IAuthFormPopup {
  largeForm?: boolean;
  tallForm?: boolean;
}

const PageContainer = styled('div')`
  width: 100vw;
  min-height: calc(100vh - 64px);
  height: auto;
  background-color: ${colors.purple5};
  @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape) {
    height: auto;
  }
`;

const PageContainerOverride = styled(PageContainer)`
  height: auto;
  min-height: calc(100vh - 64px);
`;

const RadialBackground = styled('div')`
  width: 100%;
  background: radial-gradient(circle at top left, rgba(250, 171, 98, 0.4) 0%, rgba(217, 217, 217, 0) 30%),
    radial-gradient(ellipse at center right, rgba(198, 172, 253, 0.8) 0%, rgba(217, 217, 217, 0) 50%),
    radial-gradient(circle at bottom right, rgba(250, 171, 98, 0.4) 0%, rgba(217, 217, 217, 0) 50%),
    radial-gradient(ellipse at bottom left, rgba(198, 172, 253, 0.2) 0%, rgba(217, 217, 217, 0) 80%);
  height: 100%;
  overflow: hidden;

  @media only screen and (min-device-width: 375px) ) and (max-device-width: 950px) and (orientation: landscape) {
    height: auto;
    overflow: visible;
  }
`;

const RadialBackgroundOverride = styled(RadialBackground)`
  height: auto;
`;

const AuthContainer = styled('div')`
  width: 100%;
  min-height: calc(100vh - 64px);
  max-width: max(1440px, 50vw);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .form-container {
    width: 100%;

    @media only screen and (min-device-width: 375px) and (max-device-width: 1200px) and (orientation: landscape) {
      height: auto;
    }
  }
  .form-button {
    margin-top: ${props => props.theme.spacing(6)} !important;
    margin-bottom: ${props => props.theme.spacing(6)};
  }

  @media only screen and (max-height: 900px) and (orientation: landscape) {
    padding: 30px 0px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 30px 0px;
  }
`;

const AuthContainerOverride = styled(AuthContainer)`
  height: unset;
  min-height: calc(100vh - 64px);

  @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape) {
    height: auto;
  }
`;

const AuthFormPopup = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['largeForm', 'tallForm']),
})<IAuthFormPopup>(({ largeForm, theme, tallForm }) => {
  const getMinHeight = () => {
    if (tallForm) {
      return largeForm ? 'min(calc(85vh + 92px) , 952px)' : 'min(calc(80vh + 156px), 796px)';
    }

    return largeForm ? 'min(85vh,860px)' : 'min(calc(80vh + 64px), 704px)';
  };

  return `
  filter: drop-shadow(0px 0px 40px rgb(209, 203, 246));
  background: ${colors.white};
  width:${largeForm ? 'min(1240px, 86vw)' : 'max(640px, 30vw)'};
  min-height: ${getMinHeight()};
  height:auto;

  ${theme.breakpoints.down('sm')} {
    width: 90%;
    //min-height: 70vh;

  }

  ${theme.breakpoints.down('$xxs')} {
    padding-bottom: 10%;
    padding-top: 10%;
  }

  border-radius: 16px;
  margin: auto;
  display: flex;
  justify-content: center;
 
  position: relative;
  ${zoomOutAnimation()};
  
`;
});

const AuthFormInsideContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['centerFormVertical', 'mobileWidth', 'tallForm']),
})<IAuthFormInsideContainer>(
  ({ centerFormVertical, theme, mobileWidth, tallForm }) => `
  min-height: calc(100% - 64px);
  width: 62.5%;
  min-height:${tallForm ? '700px' : '500px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${centerFormVertical ? 'justify-content: center' : 'padding-top:11.5%'};
  ${theme.breakpoints.down('sm')} {
    width:${mobileWidth || '80'}%;
  }
  ${theme.breakpoints.down('$xs')} {
    width:${mobileWidth || '90'}%;
  }
`,
);

const FormFooter = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['applyBorderRadius', 'applyFormFooterOnMobile']),
})<IFormFooter>(
  ({ applyBorderRadius, theme, applyFormFooterOnMobile }) => `
  position: absolute;
  bottom: 0;
  left:0;
  height: 64px;
  color: ${colors.black};
  width: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;


  background-color: ${colors.lightestGrey};
  a {
    color: ${colors.highlightBlue};
  }
  ${applyBorderRadius ? 'border-radius:0px 0px 16px 16px;' : ''}

  ${applyFormFooterOnMobile && theme.breakpoints.down('md')} {
    background-color: transparent;
    position: relative;
  }
 
`,
);

const FormFooterContainer = styled('div')``;

const SubTitleFormWrapper = styled('div')`
  .form-title {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
  .form-subtitle {
    margin-bottom: ${props => props.theme.spacing(5)};
  }

  .form-resend-button {
    width: 100%;
    color: ${colors.highlightBlue};
  }
  .form-button,
  .form-resend-button {
    margin-top: ${props => props.theme.spacing(4)} !important;
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const AuthLinkText = styled('div')`
  width: 100%;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
`;

const BackButtonContainer = styled('div')`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export {
  PageContainer,
  RadialBackground,
  AuthFormPopup,
  AuthContainer,
  FormFooter,
  AuthFormInsideContainer,
  SubTitleFormWrapper,
  AuthLinkText,
  BackButtonContainer,
  RadialBackgroundOverride,
  AuthContainerOverride,
  PageContainerOverride,
  FormFooterContainer,
};
