import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

interface IPassingChanceTag {
  textColor: string;
  backgroundColor: string;
}

const ExamContainer = styled('div')`
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
`;

const InsightsSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${colors.lightGrey};

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px 10px;
    flex-direction: column;
    gap: 12px;
    button {
      width: 100%;
    }
  }
`;

const ExamTitle = styled('h1')`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 700;
`;

const ExamSubTitle = styled('h2')`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 600;
`;

const ExamInfoContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ExamIconContainer = styled('div')`
  display: flex;
  gap: 16px;
  width: 70%;
  svg {
    min-width: 120px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
    width: 100%;
    svg {
      width: 100%;
    }
  }
`;

const ExamHistoryContainer = styled('div')`
  padding-left: 20px;
  padding-right: 20px;
`;

const InfoTagsContainer = styled('div')`
  display: flex;
  gap: 64px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  svg {
    cursor: pointer;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 16px;
  }
`;

const InfoTag = styled('div')`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const PassingChanceIconContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const PassingChanceContainer = styled('div')`
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .skeleton {
    margin-bottom: auto;
    margin-top: -3px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
    flex-direction: column-reverse;
  }
`;

const PassingChanceTag = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['textColor', 'backgroundColor']),
})<IPassingChanceTag>(
  ({ backgroundColor, textColor }) => `
  box-sizing: border-box;
  color: ${textColor};
  text-align: center;
  margin-bottom: auto;
  font-weight:700;
  font-size:1.75rem;
`,
);

const InfoSpan = styled('span')`
  font-size: 0.875rem;
  color: ${colors.black};
  font-weight: 600;
`;

const InfoHeader = styled('div')`
  box-sizing: border-box;
  color: ${colors.black};
  text-align: center;
  margin-bottom: auto;
  font-weight: 700;
  font-size: 1.75rem;
`;

const CurrentReadinessAttemptContainer = styled('div')`
  margin-bottom: 48px;
`;

const StartAnotherTitle = styled('h1')`
  margin: 0;
  font-weight: 700;
  font-size: 1rem;
  color: ${colors.black};
  margin-bottom: 16px;
`;

const NewReadinessAttemptContainer = styled('div')`
  margin-bottom: 24px;
`;

export {
  ExamContainer,
  InsightsSection,
  ExamTitle,
  ExamSubTitle,
  ExamIconContainer,
  ExamInfoContainer,
  ExamHistoryContainer,
  InfoTagsContainer,
  InfoTag,
  PassingChanceIconContainer,
  PassingChanceTag,
  PassingChanceContainer,
  InfoSpan,
  InfoHeader,
  CurrentReadinessAttemptContainer,
  StartAnotherTitle,
  NewReadinessAttemptContainer,
};
