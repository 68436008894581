import Modal from '../Modal';
import parse from 'html-react-parser';
import privacyPolicyDocument from './privacyPolicyDocument';
import termsAndConditionsDocument from './termsAndConditionsDocument';
import { ModalBody, ModalContainer } from './styled';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface IUserAgreement {
  type: 'privacyPolicy' | 'termsAndAgreements';
  id: string;
  isModalOpen: boolean;
  onClose: () => void;
}

const UserAgreement = ({ type, id, isModalOpen, onClose }: IUserAgreement) => {
  const getDocument = () => {
    switch (type) {
      case 'privacyPolicy':
        return privacyPolicyDocument;
      case 'termsAndAgreements':
        return termsAndConditionsDocument;
    }
  };
  return (
    <Modal onClose={onClose} id={id} isModalOpen={isModalOpen} onPrimaryButtonClick={() => {}}>
      <ClickAwayListener onClickAway={onClose}>
        <ModalContainer>
          <ModalBody>{parse(getDocument())}</ModalBody>
        </ModalContainer>
      </ClickAwayListener>
    </Modal>
  );
};

export default UserAgreement;
