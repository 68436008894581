import { IAttempt, ILooseObject } from '../../types';
import BaseTransformer from './BaseTransformer';

class ApplyAnswersTransformer extends BaseTransformer {
  public transform(data: IAttempt): ILooseObject {
    const { questionAnswers } = data;
    const answers: ILooseObject = {};
    Object.keys(questionAnswers).forEach(questionId => {
      answers[questionId] = {
        response: questionAnswers[questionId].value,
        isDone: !!questionAnswers[questionId].value,
      };
    });
    return answers;
  }
}

export default ApplyAnswersTransformer;
