import { useDispatch, useSubscriptions } from '../../hooks';
import { SLICE_NAME as subscriptionsSlice, actions, subscriptionsSelectors } from '../redux/slice';
import { useSelector } from 'react-redux';

import { IAddon, IPlan, defaultPlan, priceIdsToStaticPlans } from '../plans';

const useMembershipPlan = () => {
  const { dispatch } = useDispatch(subscriptionsSlice);
  const {
    loading,
    showPaymentForm,
    showModal,
    modalVariant,
    stripePlan,
    loadingChangePaymentMethod,
    loadingFetchSubscriptions,
    loadingFetchAddons,
    addons,
  } = useSelector(subscriptionsSelectors.allState);

  const {
    currentSubscription,
    isCancelled,
    isSubscribed,
    isActive,
    isIncomplete,
    isTrialling,
    isPaymentIssue,
    isDeprecatedSubscription,
    deprecatedSubscription,
    isSponsored,
  } = useSubscriptions({
    useFetchSubscriptions: false,
  });
  const currentSubscriptionPriceId = (isSubscribed && currentSubscription?.items?.data[0].price.id) || '';
  const currentStaticSubscription = priceIdsToStaticPlans[currentSubscriptionPriceId] || defaultPlan;

  const incompleteSubscriptionPriceId = (isIncomplete && currentSubscription?.items?.data[0].price.id) || '';
  const incompleteStaticSubscritpion = priceIdsToStaticPlans[incompleteSubscriptionPriceId];

  const isPageLoading =
    ((loading || loadingFetchAddons) && !showPaymentForm && !showModal) || loadingFetchSubscriptions;

  const setPrice = (priceId: string) => {
    const plan = priceIdsToStaticPlans[priceId];
    if (plan) dispatch(actions.setStripePlan({ stripePlan: plan }));
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const cancelCurrentSubscription = () => {
    if (currentSubscription?.id)
      dispatch(actions.cancelSubscriptionRequest({ subId: currentSubscription?.id, cancelAtPeriodEnd: !isCancelled }));
  };

  const handleCancelSubscription = () => {
    scrollToTop();
    cancelCurrentSubscription();
  };

  const handleUpgrade = () => {
    if (isActive) return;
    dispatch(actions.setShowPaymentForm(true));
    scrollToTop();
  };

  const showPaymentFormPopup = (product: IAddon | IPlan) => {
    setPrice(product.priceId);
    dispatch(actions.setShowPaymentForm(true));
  };

  const noCurrentPlan = !isSubscribed || isTrialling;

  return {
    state: {
      isActive,
      isSubscribed,
      currentStaticSubscription,
      isTrialling,
      noCurrentPlan,
      isCancelled,
      loading,
      isPageLoading,
      showPaymentForm,
      currentSubscription,
      incompleteSubscriptionPriceId,
      incompleteStaticSubscritpion,
      stripePlan,
      modalVariant,
      showModal,
      loadingChangePaymentMethod,
      isPaymentIssue,
      loadingFetchAddons,
      addons,
      isDeprecatedSubscription,
      deprecatedSubscription,
      isSponsored,
    },
    control: {
      setPrice,
      cancelCurrentSubscription,
      handleCancelSubscription,
      handleUpgrade,
      showPaymentFormPopup,
    },
  };
};

export { priceIdsToStaticPlans };
export default useMembershipPlan;
