import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { opacityInAnimation } from '../sharedStyled';
import Button from '../components/Button';
import { shouldForwardProps } from '../utils';

interface IMenuItemProps {
  disabled?: boolean;
}

interface IMenuProps {
  open?: boolean;
}

const SpinnerContainer = styled('div')`
  div {
    top: 64px;
    ${props => props.theme.breakpoints.up('lg')} {
      width: calc(100vw - min(23vw, 460px));
      margin-left: min(23vw, 460px);
    }
  }
`;

const DashboardContentContainer = styled('div')`
  ${opacityInAnimation()};
  display: flex;
  flex-direction: column;
  gap: 108px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.breakpoints.up('$xxxxl')} {
    max-width: 50vw;
  }
`;

const DashboardContainer = styled('div')`
  background-color: ${colors.lightGrey};
  padding: 64px 0;
  min-height: 100vh;

  ${props => props.theme.breakpoints.up('lg')} {
    width: calc(100vw - min(23vw, 460px));
    margin-left: min(23vw, 460px);
  }
`;

const WhiteButton = styled(Button)`
  color: ${colors.white};
  padding: 0;
  font-weight: 600;
  font-size: 0.875rem;
`;

const SideBarQuizButton = styled(WhiteButton)`
  color: ${colors.white};
  font-weight: 700;
  display: inline;
`;

const SideBarQuizPrimaryButton = styled(SideBarQuizButton)`
  color: ${colors.primary};
`;

const LargeWhiteButton = styled(WhiteButton)`
  font-size: 1rem;
`;

const Menu = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<IMenuProps>(
  ({ open }) => `
  padding: 8px 0px;
  border-radius: 4px;
  position: absolute;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  background-color: ${colors.white};
  display: ${open ? 'block' : 'none'};
  right: -8px;
  top: 50px;
  z-index: 1;
  svg {
    right: 12px;
    position: absolute;
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 20%));
    transform: translateY(-100%);
    top: 0;
    filter: drop-shadow(0px -1px 1px rgb(0 0 0 / 7%));
  }
`,
);

const MenuItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<IMenuItemProps>(
  ({ disabled }) => `
  font-size: 1rem;
  color: ${disabled ? colors.grey500 : colors.black};
  font-weight: 600;
  white-space: nowrap;
  :hover {
    background-color: ${disabled ? 'none' : colors.lightestGrey};
  }
  padding: 16px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  text-align: left;
  font-weight:600;
`,
);

const FreeUserBannerContainer = styled('div')`
  background: #353535;
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.white};
  padding: 16px 24px;
  margin: auto;
  border-radius: 8px;
  margin-top: 50px;
  width: fit-content;
  ${props => props.theme.breakpoints.down('md')} {
    padding: 12px 16px;
    width: 80%;
    margin-top: 25px;
  }
`;

const FreeUserBannerWrapper = styled('div')`
  width: 100%;
`;

const MenuButton = styled(Button)`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  :hover {
    background-color: ${colors.purple5};
  }
  :focus {
    background-color: ${colors.purple5};
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NavHeaderContainer = styled('div')`
  background: ${colors.purple15};
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    width: calc(100vw - min(23vw, 460px));
    margin-left: min(23vw, 460px);
  }
`;

export {
  SpinnerContainer,
  DashboardContentContainer,
  DashboardContainer,
  WhiteButton,
  SideBarQuizButton,
  SideBarQuizPrimaryButton,
  LargeWhiteButton,
  Menu,
  MenuItem,
  FreeUserBannerContainer,
  FreeUserBannerWrapper,
  MenuButton,
  NavHeaderContainer,
};
