import Button from '../Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachmentButtonContent, ButtonContainer, DeleteIconContainer } from './styled';
import React, { useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface IAttachmentButton {
  onUpload?: (file: File | null) => void;
  text: string;
  externalFile?: File;
}

const AttachmentButton = ({ onUpload, text, externalFile }: IAttachmentButton) => {
  const [currentFile, setCurrentFile] = useState<File | undefined>(externalFile);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const truncateFileName = (name: string, maxLength: number): string => {
    if (name.length <= maxLength) {
      return name;
    }
    return `${name.substring(0, maxLength)}...`;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setCurrentFile(file);
      onUpload?.(file);
    }
  };

  const onDelete = () => {
    setCurrentFile(undefined);
    onUpload?.(null);
  };

  return (
    <ButtonContainer>
      {/* <Button onClick={test} variant="primary"> */}
      <label htmlFor="file-upload">
        <Button variant="primarySpan">
          <AttachmentButtonContent>
            {' '}
            <AttachFileIcon />
            {currentFile?.name ? truncateFileName(currentFile.name, isMobile ? 20 : 100) : text}
          </AttachmentButtonContent>
        </Button>
      </label>
      <DeleteIconContainer>{currentFile?.name && <DeleteIcon onClick={onDelete} />}</DeleteIconContainer>
      {/* </Button> */}
      <input onChange={onChange} accept=".pdf" id="file-upload" type="file" />
    </ButtonContainer>
  );
};

export default AttachmentButton;
