import BaseMembership from './BaseMembership';
import { FreeAnalysisContainer } from '../../ReviewResults/styled';

class ReviewResultsAnalysis extends BaseMembership {
  public free() {
    return { Container: FreeAnalysisContainer };
  }
  public essential() {
    return {};
  }
  public allAccess() {
    return this.essential();
  }

  public useStrictSubscriptions() {
    return false;
  }

  public trialling() {
    return this.free();
  }

  public useStrictFreeMembers() {
    return true;
  }
}

export default ReviewResultsAnalysis;
