import { styled } from '@mui/material/styles';
//import { FormPopup } from '../OnBoarding/styled';
import { scrollbar, zoomOutAnimation } from '../sharedStyled';
import { CaseStudyContainer } from '../SmartQuiz/question/CaseStudy/styled';
import { shouldForwardProps } from '../utils';
import { colors } from '../../themes';

interface IQuizFormPopupProps {
  isViewingResults: boolean;
}
interface IFormPopup {
  isChoosingAdventure?: boolean;
}

const FormPopup = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isChoosingAdventure']),
})<IFormPopup>(
  ({ theme, isChoosingAdventure }) => `
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgb(209, 203, 246));
  background: ${colors.white};
  width: min(1240px, 86vw);
  height: min(90vh, 860px);
  ${theme.breakpoints.down('sm')} {
    min-height: 102vh;
    padding-top: 20px;
    width: 90%;
  }
  ${theme.breakpoints.down('xsm')} {
    min-height: 120vh;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape), {
    min-height: min(85vh, 860px);
  }
  @media only screen and (max-height: 600px) and (orientation: landscape) {
    min-height: min(85vh, 860px);
  }
  overflow-y: hidden;
  border-radius: 16px;
  position: relative;
  ${zoomOutAnimation()}
  ${
    isChoosingAdventure
      ? `
  ${theme.breakpoints.down('xl')} {
    height: auto;
    margin: 40px 0;
  }
  ${theme.breakpoints.down('sm')} {
    height: auto;
  }
  ${theme.breakpoints.down('xsm')} {
    height: auto;
  }
  `
      : ''
  }
`,
);

const QuizFormPopup = styled(FormPopup, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isViewingResults']),
})<IQuizFormPopupProps>(
  ({ isViewingResults, theme }) => `
  padding: 0;
  overflow-x: hidden;
  padding: 0 !important;
  width: 90%;
  //height: 90%;
  ${theme.breakpoints.down('sm')} {
    width: 90%;
    min-height: 70vh;
  }
  
  > div {
    margin-top: 0;
    min-height: unset;
    height: 100%;
    ${
      !isViewingResults
        ? `
    > div:nth-child(3) {
      height: 60%;
      margin: 0 10px;
      overflow-y: auto;
      ${scrollbar}
    }
    `
        : `
        
        // > div:nth-child(2) {
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -56%);
        //   width: 100%;
        //   margin-top: 0;
        //   margin-bottom: 0;
        //   ${theme.breakpoints.down('sm')} {
        //     transform: translate(-50%, -70%);
        //   }
        // }
        // > div:nth-child(3) {
        //   padding: 12px 23.5%;
        //   justify-content: center;
        // }
        `
    }

    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape) {
      height: max(85vh, 560px);
      margin-top:10%;
      margin-bottom:10%;
  }
`,
);

const QuizFormPopupOverride = styled(FormPopup, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isViewingResults']),
})<IQuizFormPopupProps>(
  ({ theme }) => `
  padding: 0;
  height: unset;
  overflow-x: hidden;
  padding: 0 !important;
  width: 90%;
  //height: 90%;
  margin-top:78px;
  margin-bottom:142px;
  filter: none;
  ${theme.breakpoints.down('sm')} {
    width: 90%;
    min-height: 70vh;
  }
  ${theme.breakpoints.down('md')} {
    margin-top:39px;
    margin-bottom:71px;
  }
  > div:nth-child(1) {
    margin-top: 0;
  
  }
  `,
);

const PlacementCaseStudy = styled(CaseStudyContainer)`
  height: auto;
`;

export { QuizFormPopup, QuizFormPopupOverride, PlacementCaseStudy };
