import { roleQuestionId, roleQuestionIdV2 } from '../../OnBoarding/constants';
import { EUserRole, IAttempt } from '../../types';
import BaseTransformer from './BaseTransformer';

class UserRolesTransformer extends BaseTransformer {
  public transform(data: string): EUserRole {
    switch (data) {
      case 'underGradStudent':
        return EUserRole.UNDERGRAD;
      case 'gradStudent':
        return EUserRole.GRAD;
      case 'practicingNurse':
        return EUserRole.NURSE;
      case 'instructor':
        return EUserRole.INSTRUCTOR;
      case 'tutor':
        return EUserRole.TUTOR;
      case 'jobSeeker':
        return EUserRole.JOB_SEEKER;
      case 'nurseAide':
        return EUserRole.NURSING_AID;
      default:
        return EUserRole.USER;
    }
  }

  public transformOut(data: EUserRole) {
    switch (data) {
      case EUserRole.UNDERGRAD:
        return 'underGradStudent';
      case EUserRole.GRAD:
        return 'gradStudent';
      case EUserRole.NURSE:
        return 'practicingNurse';
      case EUserRole.INSTRUCTOR:
        return 'instructor';

      case EUserRole.TUTOR:
        return 'tutor';
      case EUserRole.JOB_SEEKER:
        return 'jobSeeker';
      case EUserRole.NURSING_AID:
        return 'nurseAide';
      default:
        return 'user';
    }
  }

  public getRoleQuestionID(attempt: IAttempt) {
    if (attempt.onBoardingVersion === 'v2') {
      return roleQuestionIdV2;
    }

    if (attempt.onBoardingVersion === 'v3') {
      return roleQuestionId;
    }

    return roleQuestionId;
  }
}

export default UserRolesTransformer;
