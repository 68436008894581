import Active from './Active';
import { en } from '../../../../i18n';
import parse from 'html-react-parser';
import SmartNText from '../../../components/SmartnText';
import { EPaymentSteps } from '../../../types';
import { defaultPlan } from '../../plans';

const { billing } = en;

class DeprecatedSubscription extends Active {
  public membershipInfoPrice(): string {
    const additionalInfo = super.getAdditionalInfo();
    const { deprecatedSubscription } = additionalInfo;
    if (this.getCurrentState() === EPaymentSteps.BUY_SUBSCRIPTION) {
      return `$${defaultPlan.price} ${defaultPlan.priceDescription}`;
    }
    if (!deprecatedSubscription) return super.membershipInfoPrice();
    return `$${deprecatedSubscription.price} ${deprecatedSubscription.periodDescription}`;
  }

  public landingMembershipInfoTitle(): string {
    const additionalInfo = super.getAdditionalInfo();
    const { deprecatedSubscription } = additionalInfo;
    return deprecatedSubscription?.title || super.landingMembershipInfoTitle();
  }
  public membershipInfoTitle() {
    if (this.getCurrentState() === EPaymentSteps.BUY_SUBSCRIPTION) {
      return super.membershipInfoTitle();
    }
    return this.landingMembershipInfoTitle();
  }

  public membershipMeta() {
    const additionalInfo = super.getAdditionalInfo();
    const { isCancelled, isPaymentIssue } = additionalInfo;
    if (isPaymentIssue) return this.paymentIssueMeta();
    if (isCancelled) return this.canceledMembershipMeta();

    return (
      <>
        {parse(billing.landingSubscribed.deprecatedSubscriptionSubTitle)}
        <SmartNText>Aspire</SmartNText>&nbsp;
        {billing.landingSubscribed.deprecatedSubscriptionSubTitleRight}.
      </>
    );
  }

  public canceledMembershipMeta() {
    const additionalInfo = super.getAdditionalInfo();
    const { currentSubscription } = additionalInfo;
    return (
      <>
        {billing.landingSubscribed.cancelledMetaDeprecated}{' '}
        {currentSubscription?.formattedEndDate || this.landingSubscribedEndDate('MMMM dd, yyyy')}.{' '}
        {billing.landingSubscribed.cancelledMetaDeprecatedRight}
      </>
    );
  }
}

export default DeprecatedSubscription;
