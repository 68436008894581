import { Helmet } from 'react-helmet';
import { en } from '../../i18n';
const { seoTags } = en;

interface IUseSeoTags {
  pageName: keyof typeof seoTags.titles;
}

const useSeoTags = ({ pageName }: IUseSeoTags) => {
  return (
    <Helmet>
      <title>{seoTags.titles[pageName]}</title>
    </Helmet>
  );
};

export default useSeoTags;
