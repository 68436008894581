import { useHistory, useLocation } from 'react-router';
import {
  AuthContainer as OriginalAuthContainer,
  PageContainer as OriginalPageContainer,
  RadialBackground as OriginalRadialBackground,
} from '../Auth/styled';
import { QuizFormPopup as OriginalQuizFormPopup } from '../PlacementTest/styled';
import SmartQuiz from '../SmartQuiz';
import { SLICE_NAME as studySpaceSlice, actions, quizSelectors } from '../StudySpace/redux/slice';
import { useSelector } from 'react-redux';
import { actions as globalActions } from '../redux/globalSlice';
import { useEffect, useState } from 'react';
import UnstartedQuiz from './UnstartedQuiz';
import BottomBanner from './BottomBanner';
import SmartQuizTypeBuilder from '../classes/SmartQuizTypeBuilder';
import { en } from '../../i18n';
import {
  useDispatch,
  useMembershipProxy,
  useNavigations,
  usePropsOverride,
  useSeoTags,
  useVariableKeyDownPress,
} from '../hooks';
import useContinueQuiz from '../hooks/useContinueQuiz';
import useAttempts from '../hooks/useAttempts';

const HARD_CODED_QUIZ_ID = 'e0c719e2-12e0-11ee-be56-0242ac120002';

const { readinessAssesment } = en;

interface IOverrides {
  QuizFormPopup?: React.ElementType<any>;
  PageContainer?: React.ElementType<any>;
  AuthContainer?: React.ElementType<any>;
  RadialBackground?: React.ElementType<any>;
  overrides?: Partial<IOverrides>;
}

const smartQuizTypeBuilder = new SmartQuizTypeBuilder({ mode: 'exam' });

const ReadinessExam = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const attemptIdQuery = query.get('attemptId');
  const { dispatch: studySpaceDispatch } = useDispatch(studySpaceSlice);
  const { loadingAttempts, loadingAnswer, isViewingResults, currentAttempt } = useSelector(quizSelectors.allState);
  const { currentReadinessAttempt } = useAttempts();
  const { navigateToContinueQuiz, navigateToResults } = useNavigations();
  const { baseLineTestLayoutComponents } = useMembershipProxy({ useStrict: true });
  const seo = useSeoTags({ pageName: 'readinessAssesment' });
  const history = useHistory();
  const {
    AuthContainer = OriginalAuthContainer,
    RadialBackground = OriginalRadialBackground,
    QuizFormPopup = OriginalQuizFormPopup,
    PageContainer = OriginalPageContainer,
  } = usePropsOverride<IOverrides>({}, baseLineTestLayoutComponents.run());

  const { isStarted: isStartedContinueQuiz } = useContinueQuiz();
  const { newStart } = (history?.location?.state || {}) as { newStart: boolean };

  const { dispatch } = useDispatch();

  useEffect(() => {
    dispatch(globalActions.topicsIndexRequest({ subjectID: '7b9a7934-6060-11ed-91bc-3933fa62cf29' }));
  }, []);

  useEffect(() => {
    if (attemptIdQuery && (!currentReadinessAttempt || currentReadinessAttempt.attemptId !== attemptIdQuery))
      studySpaceDispatch(actions.fetchAttempt({ attemptId: attemptIdQuery }));
  }, [attemptIdQuery]);

  useEffect(() => {
    if (!currentReadinessAttempt || currentReadinessAttempt.isDone || newStart) return;
    if (currentReadinessAttempt.attemptId !== currentAttempt?.attemptId) {
      navigateToContinueQuiz(currentReadinessAttempt);
    } else {
      setIsStarted(true);
    }
  }, [currentReadinessAttempt]);

  const [isStarted, setIsStarted] = useState(false);

  const stateOverrides = {
    loading: loadingAttempts || loadingAnswer,
    isStarted: isStarted || isStartedContinueQuiz,
  };

  const startQuiz = () => {
    if (isStarted || isStartedContinueQuiz) return;
    setIsStarted(true);
    dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID }));
  };

  const unstartedQuizOverrides = {
    component: <UnstartedQuiz />,
    bannerOverride: <BottomBanner startQuiz={startQuiz} />,
  };

  const bannerOverrides = {
    useAttemptTitle: true,
    disableChangeTopics: true,
  };

  const textOverrides = {
    exitButtonText: readinessAssesment.buttons.exit,
    exitModalTitle: readinessAssesment.buttons.exitmodalTitle,
    exitModalConfirmButtonText: readinessAssesment.buttons.exitModalConfirmation,
  };

  const controlOverrides = {
    onViewResults: () => {
      if (currentAttempt) navigateToResults(currentAttempt);
      dispatch(actions.fetchAttempt({ attemptId: currentReadinessAttempt?.attemptId || '' }));
    },
  };

  //useVariableKeyDownPress('Enter', startQuiz);

  return (
    <PageContainer>
      {seo}
      <RadialBackground>
        <AuthContainer>
          <QuizFormPopup isViewingResults={isViewingResults}>
            <SmartQuiz
              bannerOverrides={bannerOverrides}
              stateOverrides={stateOverrides}
              unstartedQuizOverrides={unstartedQuizOverrides}
              textOverrides={textOverrides}
              flowOverrides={smartQuizTypeBuilder.getFlowOverrides()}
              controlOverrides={controlOverrides}
            />
          </QuizFormPopup>
        </AuthContainer>
      </RadialBackground>
    </PageContainer>
  );
};

export default ReadinessExam;
