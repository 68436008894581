import { useState } from 'react';
import { en } from '../../../i18n';
import { ExpandableTag, OptionsContainer, Option } from '../../SmartQuiz/question/CaseStudy/MatrixChoice/styled';
import { ITableInput } from './types';
const { caseStudy } = en;
const { mobile } = caseStudy;
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Radio } from '@mui/material';

interface IExpandable {
  options: ITableInput[];
  response?: string | number;
  onChange: (optionId: string | number) => void;
  horizontalMobile?: boolean;
}

const ExpandableOption = ({ options, response, onChange, horizontalMobile }: IExpandable) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const renderExpandIcon = () => {
    if (isExpanded) return <ExpandLessIcon onClick={toggleIsExpanded} />;
    return <ExpandMoreIcon onClick={toggleIsExpanded} />;
  };

  const renderOptions = () => {
    const height = isExpanded ? '20em' : '0';
    return (
      <OptionsContainer horizontal={horizontalMobile} height={height}>
        {options.map((option, indexOption) => (
          <Option key={indexOption}>
            <Radio
              key={indexOption}
              checked={response === option.id}
              value={option.id}
              onChange={() => onChange(option.id)}
              //disabled={isDone}
            />
            <span>{option.value}</span>
          </Option>
        ))}
      </OptionsContainer>
    );
  };

  return (
    <>
      <ExpandableTag>
        <span>{mobile.expandablesOptionsLabel} </span>
        {renderExpandIcon()}
      </ExpandableTag>
      {renderOptions()}
    </>
  );
};

export default ExpandableOption;
