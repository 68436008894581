import { ReactNode } from 'react';
import BaseContent, { IConfirmButton } from './BaseContent';
import { EPaymentSteps } from '../../../types';
import { en } from '../../../../i18n';
import SmartNText from '../../../components/SmartnText';
import { format } from 'date-fns';
import { defaultPlan } from '../../plans';
import { RenewalInfo } from '../../LandingSubscribed/styled';

const {
  paymentForm: {
    titleTop,
    changePaymentMethod,
    planRightTitle,
    title,
    planSubTitleTrial,
    notSubscribed,
    planSubTitleMember,
    landingSubscibedTrial,
    landingSubscribedActive,
  },
  planButtonText,
  cancel: cancelMembership,
  landingSubscribed: { cancelledMeta, paymentIssueMeta },
  addonConfirmationButton,
} = en.billing;

class Active extends BaseContent {
  public confirmButton(): IConfirmButton {
    const currentState = super.getCurrentState();
    const additionalInfo = super.getAdditionalInfo();

    switch (true) {
      case currentState === EPaymentSteps.VIEW_SUBSCRIPTION:
        return {
          loading: false,
          form: '',
          text: cancelMembership,
          variant: 'error',
          onClick: 'openCancelModal',
        };
      case currentState === EPaymentSteps.EDIT_CARD:
        return {
          loading: !!additionalInfo.loadingChangePaymentMethod,
          form: 'payment-method-form',
          text: changePaymentMethod,
          variant: 'primary',
        };

      case currentState === EPaymentSteps.BUY_ADDON_NO_CARD:
        return {
          form: 'payment-method-form',
          text: addonConfirmationButton,
          variant: 'primary',
          loading: !!additionalInfo.loadingPurchaseAddon,
          onClick: 'purchaseAddon',
        };

      default:
        return {
          loading: !!additionalInfo.loading,
          form: 'payment-form',
          text: planButtonText,
          variant: 'primary',
        };
    }
  }
  public formTitle(): string {
    return title;
  }
  public membershipInfoTitle(): ReactNode {
    return (
      <span>
        <SmartNText>Aspire</SmartNText>
      </span>
    );
  }

  public landingMembershipInfoTitle(): string {
    return "Smart'n Aspire";
  }

  public membershipInfoSubTitle(): string {
    const additionalInfo = super.getAdditionalInfo();
    const { currentSubscription, isActive, isTrialling } = additionalInfo;

    let endDate;
    if (currentSubscription) endDate = currentSubscription.formattedEndDate;
    if (isTrialling) return `${planSubTitleTrial} ${endDate}`;
    if (isActive) return `${planSubTitleMember} ${endDate}`;
    return notSubscribed;
  }

  public membershipInfoTopTitle(): string {
    return titleTop;
  }

  public membershipMeta(): ReactNode {
    const additionalInfo = super.getAdditionalInfo();
    const { isCancelled, isTrialling, isPaymentIssue } = additionalInfo;
    if (isPaymentIssue) return this.paymentIssueMeta();
    if (isCancelled) return this.canceledMembershipMeta();

    const preText = isTrialling ? landingSubscibedTrial : landingSubscribedActive;

    return (
      <>
        {defaultPlan.headLine}&nbsp;
        <RenewalInfo>
          [{preText} {this.landingSubscribedEndDate()}]
        </RenewalInfo>
      </>
    );
  }

  public paymentIssueMeta() {
    return paymentIssueMeta;
  }

  public canceledMembershipMeta() {
    const additionalInfo = super.getAdditionalInfo();
    const { currentSubscription } = additionalInfo;

    return (
      <>
        {cancelledMeta} {currentSubscription?.formattedEndDate || this.landingSubscribedEndDate('MMMM dd, yyyy')}.
      </>
    );
  }

  public landingSubscribedEndDate(dateFormat = 'MMMM yyyy'): string {
    const additionalInfo = super.getAdditionalInfo();
    const { currentSubscription } = additionalInfo;
    let endDate;
    if (currentSubscription?.currentPeriodEnd) {
      endDate = format(new Date(currentSubscription.currentPeriodEnd), dateFormat);
    }

    return endDate || '';
  }
}

export default Active;
