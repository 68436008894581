import { useSelector } from 'react-redux';
import { authSelectors } from '../Auth/redux/slice';
import { EUserRole, IAttempt } from '../types';
import { useMemo } from 'react';
import { calculateProficiency, countQuestionsAnswered, humanize, uppercaseWords } from '../utils';
import useAttempts from './useAttempts';
import useSubscriptions from './useSubscriptions';
import { quizSelectors } from '../StudySpace/redux/slice';
import Spinner from '../components/Spinner';
import SmartNText from '../components/SmartnText';
import { en } from '../../i18n';
import { studyGoalOnboardingQuestionId } from '../OnBoarding/constants';
import useAuthorization from './useAuthorization';
const { freeMember, defaultGoal } = en.dashboard;
const { sponsored } = en.billing;

const usePersonalInfo = () => {
  const {
    filteredAttempts: attempts,
    onBoardingAttempts,
    isRoleQuestionAnswered: isRoleQuestionAttemptAnswered,
    currentOnBoardingTest,
    rolePlaceholderAttempt,
  } = useAttempts();
  const { user } = useSelector(authSelectors.allState);

  const getUserName = () => {
    const getName = (): string => {
      const namesList = user?.attributes?.name.split(' ') || [];

      switch (true) {
        case namesList?.length === 0:
          return '';

        case namesList?.length === 1:
          return namesList[0];

        case namesList?.length > 1:
          return [namesList[0], namesList[namesList.length - 1]].join(' ');
      }
      return '';
    };
    return uppercaseWords(getName());
  };
  const name = getUserName();
  const proficiencyObject = useMemo(() => calculateProficiency(attempts || []), [attempts]);
  const { isActive, isTrialling, loading, isSponsored } = useSubscriptions({ useFetchSubscriptions: false });
  const { loadingOnBoardingAttempts } = useSelector(quizSelectors.allState);
  const { state } = useAuthorization();

  const numberOfQuizzes = attempts?.filter(attempt => attempt.isDone)?.length || 0;
  const numberOfQuestions = countQuestionsAnswered(attempts || []);
  const allTopicsQuizzed = attempts?.reduce((acc: string[], curr: IAttempt) => {
    if (!curr.isDone) return acc;
    const topicIds = curr.questions.map(question => question.groupIds).flat();
    return [...acc, ...topicIds];
  }, []);
  const numberOfTopics = [...new Set(allTopicsQuizzed)].length;

  const getMembership = () => {
    switch (true) {
      case loading:
        return <Spinner inline size="1.2rem" loading />;
      case isSponsored:
        return (
          <span>
            <SmartNText>Aspire</SmartNText> - {sponsored}
          </span>
        );
      case isTrialling:
        return <SmartNText>Aspire Trial</SmartNText>;
      case isActive:
        return <SmartNText>Aspire</SmartNText>;
      default:
        return freeMember;
    }
  };

  const getGoal = () => {
    if (loadingOnBoardingAttempts) return <Spinner inline size="1.2rem" loading />;
    const onboardingAttempt = onBoardingAttempts?.[0];
    if (!onboardingAttempt) return defaultGoal;
    const goalQuestion = onboardingAttempt.questions.find(
      question => question.questionId === studyGoalOnboardingQuestionId,
    );
    if (!goalQuestion?.response) return defaultGoal;

    return humanize(`${goalQuestion.response}`.replace(/"/g, ''));
  };

  const getRole = () => {
    if (loadingOnBoardingAttempts) return <Spinner inline size="1.2rem" loading />;
    return state.storedRole;
  };

  const isStudentRole = getRole() === EUserRole.GRAD || getRole() === EUserRole.UNDERGRAD;

  const isRoleQuestionAnswered = getRole() !== EUserRole.USER && isRoleQuestionAttemptAnswered;

  return {
    name,
    proficiencyObject,
    getMembership,
    getGoal,
    numberOfQuestions,
    numberOfQuizzes,
    numberOfTopics,
    role: state.storedRole,
    getRole,
    isStudentRole,
    isRoleLoading: loadingOnBoardingAttempts,
    isRoleQuestionAnswered,
    currentOnBoardingTest,
    rolePlaceholderAttempt,
  };
};

export default usePersonalInfo;
