import BaseContent, { IAdditionalInfo } from './BaseContent';
import { EMembershipType, EPaymentSteps, EPlanTypes } from '../../../types';
import { IPlan, IAddon } from '../../plans';
import BuyAddon from './BuyAddon';
import Active from './Active';
import DeprecatedSubscription from './DeprecatedSubscription';
import Sponsored from './Sponsored';

class ContentManager {
  public content: BaseContent;

  public constructor(
    stripePlan: IPlan | IAddon,
    currentPaymentState: EPaymentSteps,
    additionalInfo: Partial<IAdditionalInfo> = {},
  ) {
    if (additionalInfo.deprecatedSubscription) {
      this.content = new DeprecatedSubscription(stripePlan, currentPaymentState, additionalInfo);
      return;
    }

    if (
      currentPaymentState === EPaymentSteps.BUY_ADDON ||
      currentPaymentState === EPaymentSteps.BUY_ADDON_NO_CARD ||
      (stripePlan.type === EPlanTypes.ADDON && currentPaymentState === EPaymentSteps.EDIT_CARD)
    ) {
      this.content = new BuyAddon(stripePlan, currentPaymentState, additionalInfo);
      return;
    }
    if (additionalInfo.isSponsored) {
      this.content = new Sponsored(stripePlan, currentPaymentState, additionalInfo);
      return;
    }
    this.content = new Active(stripePlan, currentPaymentState, additionalInfo);
  }
}

export default ContentManager;
