import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { ICaseStudyContent, ICaseStudyOption, ICaseStudySubquestion, ILooseObject } from '../../../../types';
import Matrix from './Matrix';
import Expandables from './Expandable';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MatrixChoice = ({
  header,
  rows,
  isMultiple,
  answerSubquestion,
  questionId,
  answer,
  isDone,
  isReviewing,
  isDisabled,
}: {
  header?: ICaseStudyContent;
  rows: ICaseStudySubquestion[];
  isMultiple: boolean;
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  answer?: ILooseObject;
  isDone?: boolean;
  isReviewing?: boolean;
  isDisabled?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onChange = (id: string, index: number) => {
    const newValue = rows.map((row, index) => ({ id: row.id, answerId: answer?.questions?.[index]?.answerId || '' }));
    newValue[index].answerId = id;
    answerSubquestion(
      {
        id: questionId,
        questions: newValue,
      },
      {
        id: questionId,
        questions: rows.map(row => ({
          id: row.id,
          answerId: row.detail.answerId,
        })),
      },
    );
  };

  const onMultipleChange = (id: string, index: number, isChecked: boolean) => {
    const newValue = rows.map((row, index) => ({ id: row.id, answerIds: answer?.questions?.[index]?.answerIds || [] }));
    if (isChecked) newValue[index].answerIds = [...newValue[index].answerIds, id];
    else newValue[index].answerIds = newValue[index].answerIds.filter((answerId: string) => answerId !== id);
    answerSubquestion(
      {
        id: questionId,
        questions: newValue,
      },
      {
        id: questionId,
        questions: rows.map(row => ({
          id: row.id,
          answerIds: row.detail.answerIds,
        })),
      },
    );
  };

  const renderRowAnswer = (row: ICaseStudySubquestion, option: ICaseStudyOption, index: number) => {
    if (isMultiple) {
      const isChecked = isReviewing
        ? row.detail.answerIds?.includes(option.id)
        : !!answer?.questions?.[index].answerIds.includes(option.id);
      return (
        <Checkbox
          key={index}
          checked={isChecked}
          value={option.id}
          onChange={() => onMultipleChange(option.id, index, !answer?.questions?.[index].answerIds.includes(option.id))}
          disabled={isDone}
        ></Checkbox>
      );
    }
    const isChecked = isReviewing
      ? row.detail.answerId === option.id
      : answer?.questions?.[index].answerId === option.id;

    return (
      <Radio
        key={index}
        checked={isChecked}
        value={option.id}
        onChange={() => onChange(option.id, index)}
        disabled={isDone}
      />
    );
  };
  if (isMobile)
    return <Expandables header={header} renderRowAnswer={renderRowAnswer} rows={rows} isDisabled={isDisabled} />;
  return <Matrix header={header} renderRowAnswer={renderRowAnswer} rows={rows} />;
};

export default MatrixChoice;
