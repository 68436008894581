import { styled } from '@mui/material/styles';
import { opacityInAnimation } from '../../sharedStyled';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  ${props => props.theme.breakpoints.down('md')} {
    gap: 25px;
  }
`;

const FieldContainer = styled('div')`
  display: flex;
  gap: 25px;
  button {
    width: 20%;
  }
  ${opacityInAnimation()};
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
    gap: 5px;
    button {
      width: 50%;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    input {
      width: 100%;
    }
  }
`;

export { Container, FieldContainer };
