import { useState } from 'react';
import { ReactComponent as TipIcon } from '../../../images/tip.svg';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../../images/minus.svg';
import { QuestionTip as QuestionTipStyled, QuestionTipText } from './styled';
import CaseStudyContentRenderer from '../../SmartQuiz/question/CaseStudy/ContentRenderer';
import TemplateRenderer from '../../SmartQuiz/question/templates';
import { ICaseStudyContent, IContent, ILooseObject } from '../../types';
import { en } from '../../../i18n';

const { smartnTip } = en.caseStudy;

interface IQuestionTip {
  tip: IContent | ICaseStudyContent;
}

const QuestionTip = ({ tip }: IQuestionTip) => {
  const [tipOpen, setTipOpen] = useState(false);

  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  const isCaseStudyContent = (inputTip: ILooseObject): inputTip is ICaseStudyContent => {
    return !!inputTip.detail;
  };

  const renderComponent = () => {
    if (isCaseStudyContent(tip)) return <CaseStudyContentRenderer content={tip} />;
    return <TemplateRenderer content={tip} />;
  };

  return (
    <QuestionTipStyled onClick={toggleTip} open={tipOpen}>
      <div>
        <TipIcon />
        {smartnTip}
      </div>
      {tipOpen ? <MinusIcon /> : <PlusIcon />}
      {tipOpen && <QuestionTipText>{renderComponent()}</QuestionTipText>}
    </QuestionTipStyled>
  );
};

export default QuestionTip;
