import { styled } from '@mui/material/styles';
import { Tag } from '../../sharedStyled';

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${props => props.theme.breakpoints.down('$md')} {
    align-items: center;
  }

  span {
    ${props => props.theme.breakpoints.down('$md')} {
      text-align: center;
    }
    font-size: 0.875rem;
    font-weight: 600;
  }
  .bold {
    font-weight: 700;
  }
`;

const TagsContainer = styled('div')`
  display: flex;
  ${props => props.theme.breakpoints.down('sm')} {
    overflow-y: auto;
  }
`;

const TimerContainer = styled('div')`
  width: calc(23.5% - 22px);
  ${props => props.theme.breakpoints.down('$md')} {
    width: auto;
  }
`;

const RightButtonContainer = styled('div')`
  margin-left: auto;
  ${props => props.theme.breakpoints.down('md')} {
    margin-left: unset;
  }
`;

const QuizBannerTag = styled(Tag)`
  ${props => props.theme.breakpoints.down('$md')} {
    margin-right: 0;
  }
`;

const QuizTitle = styled('div')`
  font-weight: 700;
  font-size: 1.125rem;
`;

export { Header, TagsContainer, TimerContainer, RightButtonContainer, QuizBannerTag, QuizTitle };
