import { useState, useRef, ChangeEvent, KeyboardEvent, MutableRefObject, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { ISession, IMessage, ENurseGPTRole } from '../types';
import useAuthorization from './useAuthorization';
import useNavigations from './useNavigations';
import usePersonalInfo from './usePersonalInfo';
import { isInternalUser, getDateInterval, mapOnboardingRoleToNurseGPTRole, isJSONObject } from '../utils';
import { useDispatch, useSocket } from '../hooks';
import { actions, nurseGPTSelectors, SLICE_NAME as nurseGPTSlice } from '../NurseGPT/redux/slice';
import { endpoints } from '../constants';
import NurseGPTLoader from '../../images/nurseGPTLoader.svg';
import useRemoveChatWidget from './useRemoveChatWidget';
import { BugSnag } from '../classes';

const useNurseGPT = () => {
  useRemoveChatWidget();
  const { sendJsonMessage, lastMessage, readyState } = useSocket({ url: endpoints.nurseGPTWebSocket });
  const { getRole, isRoleLoading } = usePersonalInfo();
  const storedRole = getRole();
  const [prompt, setPrompt] = useState('');
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState<ISession>();
  const [sessionToRename, setSessionToRename] = useState<ISession>();
  const [typedResponse, setTypedResponse] = useState('');
  const [clickedSessionMenu, setClickedSessionMenu] = useState('');
  const [roleValue, setRoleValue] = useState(mapOnboardingRoleToNurseGPTRole(storedRole));
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [sessionChangeToggle, setSessionChangeToggle] = useState(false);
  const [svgLoader, setSvgLoader] = useState('');
  const typedResponseRef = useRef() as MutableRefObject<HTMLDivElement>;
  const inputRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const messagesWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const sessionsTitlesRef = useRef<Record<string, HTMLDivElement | null>>({});
  const sessionsListRef = useRef() as MutableRefObject<HTMLDivElement>;
  const previousMessagesWrapperScrolllHeight = useRef(0);
  const previousMessagesWrapperScrollTop = useRef(0);
  const {
    state: { user },
  } = useAuthorization();
  const { navigateToDashboard, refresh } = useNavigations();
  const {
    loadingResponse,
    currentSessionId,
    sessions,
    loadingSessions,
    errors,
    allSessionsFetched,
    lastFetchedSession,
  } = useSelector(nurseGPTSelectors.allState);
  const { dispatch } = useDispatch();
  const currentSession = sessions.find(session => session.sessionId === currentSessionId);
  const chat = currentSession?.messages || [];
  const userFirstLetter = user?.username.charAt(0);
  const userEmail = user?.attributes?.email;
  const isUnstarted = chat.length === 0 && !currentSessionId;
  const noSessions = sessions.length === 0;
  const groupedSessions = sessions.reduce((acc, session) => {
    const section = getDateInterval(session.createdAt);
    if (acc[section]) acc[section].push(session);
    else acc[section] = [session];
    return acc;
  }, {});
  const typedResponseHeight = typedResponseRef.current?.offsetHeight / 24;

  const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const handlePromptSubmit = () => {
    dispatch(actions.submitPrompt({ prompt, currentSessionId, sendJsonMessage }));
    setPrompt('');
  };

  const handleStartNewChat = () => {
    dispatch(actions.startNewChat());
  };

  const toggleAboutModal = () => {
    setMobileDrawerOpen(false);
    setShowAboutModal(!showAboutModal);
  };

  const toggleDeleteModal = () => {
    if (showDeleteModal) setSessionToDelete(undefined);
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleExampleClick = (prompt: string) => {
    setPrompt(prompt);
    inputRef.current?.focus();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (loadingResponse || !prompt) setPrompt(prompt + '\n');
      else handlePromptSubmit();
    }
  };

  const setCurrentSession = (session: ISession) => {
    if (sessionToRename?.sessionId === session.sessionId) return;
    setMobileDrawerOpen(false);
    if (session.sessionId !== currentSessionId) {
      if (session.messages?.length > 0) {
        setSessionChangeToggle(!sessionChangeToggle);
      } else {
        dispatch(actions.fetchSession({ session }));
      }
      dispatch(actions.setCurrentSession({ session }));
    }
  };

  const handleSessionMenuClick = (e: MouseEvent<HTMLElement>, sessionId: string) => {
    e.stopPropagation();
    if (clickedSessionMenu === sessionId) setClickedSessionMenu('');
    else setClickedSessionMenu(sessionId);
  };

  const handleSessionMenuClickaway = () => {
    setClickedSessionMenu('');
  };

  const handleSessionDelete = () => {
    dispatch(actions.deleteSession({ sessionId: sessionToDelete?.sessionId || '' }));
    toggleDeleteModal();
  };

  const handleDeleteClick = (e: MouseEvent<HTMLElement>, session: ISession) => {
    e.stopPropagation();
    setSessionToDelete(session);
    toggleDeleteModal();
  };

  const handleRenameClick = (e: MouseEvent<HTMLElement>, session: ISession) => {
    e.stopPropagation();
    setSessionToRename(session);
    setClickedSessionMenu('');
  };

  const handleRenameBlur = () => {
    const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId || ''];
    if (sessionToRenameRef) {
      sessionToRenameRef.scrollLeft = 0;
      sessionToRenameRef.contentEditable = 'false';
      dispatch(
        actions.renameSession({
          sessionId: sessionToRename?.sessionId || '',
          title: sessionToRenameRef.innerText,
          originalTitle: sessionToRename?.title || '',
        }),
      );
    }
    setSessionToRename(undefined);
  };

  const onRenameKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId || ''];
      if (sessionToRenameRef) sessionToRenameRef.blur();
    }
  };

  const getPlaceholder = (
    initialPlaceholder: string,
    ongoingPlaceholder: string,
    initialMobilePlaceholder: string,
    ongoingMobilePlaceholder: string,
  ) => {
    return window.innerWidth > 900
      ? chat.length === 0
        ? initialPlaceholder
        : ongoingPlaceholder
      : chat.length === 0
      ? initialMobilePlaceholder
      : ongoingMobilePlaceholder;
  };

  const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleValue((e.target as HTMLInputElement).value);
  };

  const handleMobileRoleChange = (role: ENurseGPTRole) => {
    setRoleValue(role);
    toggleRoleDropdown();
  };

  const toggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const displaySessionMenuUp = (sessionId: string) => {
    const sessionsListBottom = sessionsListRef.current?.getBoundingClientRect().bottom;
    const sessionItemBottom = (sessionsTitlesRef.current[sessionId]?.getBoundingClientRect().bottom || 0) + 13;
    const availableSpace = sessionsListBottom - sessionItemBottom;
    return availableSpace < 89;
  };

  const handleMessagesScroll = (e: any) => {
    previousMessagesWrapperScrollTop.current = e.target.scrollTop;
    previousMessagesWrapperScrolllHeight.current = e.target.scrollHeight;
    if (e.target.scrollTop === 0 && chat[0] && chat[0].messageId && !currentSession?.allMessagesFetched) {
      dispatch(actions.fetchSession({ session: currentSession || {} }));
    }
  };

  useEffect(() => {
    dispatch(actions.fetchSessions({}));
    return () => {
      dispatch(actions.resetState());
    };
  }, []);

  useEffect(() => {
    if (!isInternalUser(userEmail)) navigateToDashboard();
  }, [userEmail]);

  useEffect(() => {
    if (chat.length > 0 && chat[chat.length - 1].source === 'user')
      messagesWrapperRef.current?.scrollBy({
        behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [chat.length]);

  useEffect(() => {
    if (!messagesWrapperRef.current) return;
    const bottomReached =
      Math.abs(
        messagesWrapperRef.current.scrollHeight -
          messagesWrapperRef.current.scrollTop -
          messagesWrapperRef.current.clientHeight,
      ) < 50;
    if (typedResponseHeight > 2 && bottomReached)
      messagesWrapperRef.current?.scrollBy({
        behavior: 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [typedResponseHeight]);

  useEffect(() => {
    if (!lastMessage) return;
    let messageContent = lastMessage.data;
    if (isJSONObject(messageContent)) {
      if (JSON.parse(messageContent).message === 'Endpoint request timed out') return;
      else
        dispatch(
          actions.submitPromptFailure({
            errors: { webSocketError: { message: messageContent } },
          }),
        );
    } else if (messageContent.includes('<sys>')) {
      messageContent = messageContent.replace('<sys>', '');
      messageContent = messageContent.replace('</sys>', '');
      const parsedMessageContent = JSON.parse(messageContent);
      if (parsedMessageContent.statusCode !== 200) {
        dispatch(
          actions.submitPromptFailure({
            errors: { webSocketError: { message: parsedMessageContent } },
          }),
        );
      } else {
        const serverResponse = JSON.parse(parsedMessageContent.body);
        dispatch(
          actions.submitPromptSuccess({
            response: typedResponse,
            sessionId: serverResponse.session_id,
          }),
        );
        setTypedResponse('');
      }
    } else {
      setTypedResponse(typedResponse + messageContent);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!sessionToRename) return;
    const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId];
    if (sessionToRenameRef) {
      sessionToRenameRef.contentEditable = 'true';
      sessionToRenameRef.focus();
      const selection = window.getSelection();
      if (selection) selection.selectAllChildren(sessionToRenameRef);
      if (selection) selection.collapseToEnd();
      sessionToRenameRef.scrollLeft = sessionToRenameRef.scrollWidth;
    }
  }, [sessionToRename]);

  useEffect(() => {
    setRoleValue(mapOnboardingRoleToNurseGPTRole(storedRole));
  }, [storedRole]);

  useEffect(() => {
    messagesWrapperRef.current?.scrollBy({
      behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
      left: 0,
      top: messagesWrapperRef.current.scrollHeight,
    });
  }, [sessionChangeToggle]);

  useEffect(() => {
    if (lastFetchedSession === currentSessionId && currentSession?.numberOfFetches === 1)
      messagesWrapperRef.current?.scrollBy({
        behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [lastFetchedSession]);

  useEffect(() => {
    if (lastFetchedSession === currentSessionId && currentSession?.numberOfFetches > 1 && messagesWrapperRef.current) {
      const newScrollHeight = messagesWrapperRef.current.scrollHeight;
      const heightDifference = newScrollHeight - previousMessagesWrapperScrolllHeight.current;
      messagesWrapperRef.current.scrollTop = previousMessagesWrapperScrollTop.current + heightDifference;
    }
  }, [currentSession?.numberOfFetches]);

  useEffect(() => {
    fetch(NurseGPTLoader)
      .then(response => response.text())
      .then(text => setSvgLoader(text));
  }, []);

  useEffect(() => {
    if (errors?.webSocketError) {
      new BugSnag().notify(new Error(`${errors?.webSocketError}`));
      setShowErrorModal(true);
    }
  }, [errors]);

  useEffect(() => {
    if (readyState !== 1 && loadingResponse) {
      new BugSnag().notify(new Error(`Can't send or receive messages. Connection State: ${readyState}`));
      setShowErrorModal(true);
    }
  }, [readyState, loadingResponse]);

  // TODO: Refactor to use onScroll react event, to be consistent with messages pagination
  useEffect(() => {
    if (sessionsListRef.current) {
      sessionsListRef.current.onscroll = () => {
        if (
          sessionsListRef.current.scrollHeight -
            sessionsListRef.current.offsetHeight -
            sessionsListRef.current.scrollTop <=
            1 &&
          !loadingSessions &&
          !allSessionsFetched
        )
          dispatch(actions.fetchSessions({ lastSession: sessions[sessions.length - 1] }));
      };
    }
  }, [sessions, loadingSessions]);

  return {
    state: {
      prompt,
      chat,
      messagesWrapperRef,
      typedResponseRef,
      userFirstLetter,
      loadingResponse,
      isUnstarted,
      sessions,
      noSessions,
      showAboutModal,
      groupedSessions,
      mobileDrawerOpen,
      loadingSessions,
      inputRef,
      typedResponse,
      clickedSessionMenu,
      showDeleteModal,
      sessionToDelete,
      sessionToRename,
      sessionsTitlesRef,
      sessionsListRef,
      roleValue,
      roleDropdownOpen,
      currentSession,
      svgLoader,
      showErrorModal,
      storedRole,
      isRoleLoading,
    },
    control: {
      setPrompt,
      handlePromptChange,
      handlePromptSubmit,
      handleStartNewChat,
      onKeyDown,
      toggleAboutModal,
      setCurrentSession,
      toggleMobileDrawer,
      handleExampleClick,
      getPlaceholder,
      handleSessionMenuClick,
      handleSessionMenuClickaway,
      handleSessionDelete,
      toggleDeleteModal,
      handleDeleteClick,
      handleRenameClick,
      handleRenameBlur,
      onRenameKeyDown,
      handleRoleChange,
      handleMobileRoleChange,
      toggleRoleDropdown,
      setRoleDropdownOpen,
      displaySessionMenuUp,
      refresh,
      handleMessagesScroll,
    },
  };
};

export default useNurseGPT;
