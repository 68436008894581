import { Ref, useMemo } from 'react';
import { TopBanner } from '../styled';
import { Header, TagsContainer, TimerContainer, RightButtonContainer, QuizBannerTag, QuizTitle } from './styled';
import { IAttempt, IQuestion, ITopic } from '../../types';
import Button from '../../components/Button';
import { en } from '../../../i18n';
import { Dictionary } from '@reduxjs/toolkit';
import Timer from '../../components/Timer';
import { IBannerOverrides } from '../types';

const { question, of, changeTopics, skipBaseline } = en.smartQuiz;

const { ngnCaseStudy } = en.caseStudy;
const QUIZ_PERIOD = 3 * 60 * 60000; // 3 hours;
const oldDate = new Date();
const defaultPeriod = new Date(oldDate.getTime() + QUIZ_PERIOD);

interface IQuizBanner {
  bannerRef: Ref<HTMLDivElement>;
  currentQuestionIndex: number;
  totalNumberOfQuestions: number;
  isCaseStudy: boolean;
  caseStudyIndex: number;
  caseStudyTotalQuestions: number;
  isPlacement?: boolean;
  skipPlacement?: () => void;
  toggleFiltersModal: () => void;
  currentQuestion?: IQuestion;
  allTopics: Dictionary<ITopic>;
  ctime?: string;
  currentAttemptTitle: string;
  overrides?: Partial<IBannerOverrides>;
}

const QuizBanner = ({
  bannerRef,
  currentQuestionIndex,
  totalNumberOfQuestions,
  isCaseStudy,
  caseStudyIndex,
  caseStudyTotalQuestions,
  isPlacement,
  skipPlacement,
  toggleFiltersModal,
  currentQuestion,
  allTopics,
  ctime,
  overrides,
  currentAttemptTitle,
}: IQuizBanner) => {
  const renderTags = () => {
    if (overrides?.useAttemptTitle) return <QuizTitle>{currentAttemptTitle}</QuizTitle>;
    const { topicsIds } = currentQuestion || {};
    const topicsNames = topicsIds?.map(id => allTopics?.[id]?.name);
    const topicNameSingle = [topicsNames?.[topicsNames?.length - 1]];
    return (
      <TagsContainer>
        {topicNameSingle.map(name =>
          name ? (
            <QuizBannerTag key={name} variant="outlined">
              {name}
            </QuizBannerTag>
          ) : null,
        )}
        {/* <QuizBannerTag key="ID" variant="outlined">
          ID:{currentQuestion?.id}
        </QuizBannerTag> */}
      </TagsContainer>
    );
  };
  const getExpiry = () => {
    if (!ctime) return null;
    const cDate = new Date(ctime);
    const expiry = new Date(cDate.getTime() + QUIZ_PERIOD);
    return expiry;
  };

  const renderRightControlButton = () => {
    if (isPlacement)
      return (
        <Button variant="primary" onClick={skipPlacement}>
          {skipBaseline}
        </Button>
      );
    return (
      <Button variant="primary" onClick={toggleFiltersModal}>
        {changeTopics}
      </Button>
    );
  };

  const timer = <Timer date={getExpiry() || defaultPeriod} />;
  return (
    <TopBanner hasChangeTopics ref={bannerRef}>
      <TimerContainer>{!overrides?.disableChangeTopics && timer}</TimerContainer>

      <Header>
        <span>
          {question} <span className="bold">{currentQuestionIndex + 1}</span> {of}{' '}
          <span className="bold">{totalNumberOfQuestions}</span>
          {isCaseStudy && ` - ${ngnCaseStudy(caseStudyIndex + 1, caseStudyTotalQuestions)}`}
        </span>
        {renderTags()}
      </Header>

      <RightButtonContainer>{overrides?.disableChangeTopics ? timer : renderRightControlButton()}</RightButtonContainer>
    </TopBanner>
  );
};

export default QuizBanner;
