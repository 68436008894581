import { useEffect } from 'react';
import { Banner, SmartQuizContainer } from '../styled';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../components/Button';
import { en } from '../../../i18n';
import { quizSelectors, actions } from '../../StudySpace/redux/slice';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import { IResultsProps } from '../types';
import {
  usePropsOverride,
  useMembershipProxy,
  useDispatch,
  useNavigations,
  useKeyDownPress,
  useTransformAttemptFromStore,
  useTopicsRecommendations,
} from '../../hooks';
import { colors } from '../../../themes';
import ProgressBar from '../../components/ProgressBar';
import { placementTestPath } from '../../Router/paths';

const { reviewAnswers, returnToDashboard } = en.smartQuiz;

interface ITopicsAnalysis {
  [key: string]: {
    totalMark: number;
    totalMaximumMark: number;
    totalQuestions: number;
    percentage: number;
  };
}
interface IAnalysis {
  totalMark: number;
  totalMaximumMark: number;
  topics: ITopicsAnalysis;
}

const Results = ({ overrides, ...props }: IResultsProps) => {
  const history = useHistory();
  const { redirectToViewResults } = usePropsOverride<IResultsProps>(props, overrides);
  const { currentAttempt, questionAnswers, questions: transformedQuestions } = useSelector(quizSelectors.allState);
  const { entities: topics } = useSelector(topicsSelectors.allState);
  const { dispatch } = useDispatch();
  const { navigateToDashboard } = useNavigations();

  useKeyDownPress('Enter', redirectToViewResults);
  useKeyDownPress('Backspace', navigateToDashboard);

  if (!currentAttempt) return null;

  const transformedAttempt = useTransformAttemptFromStore();
  const topicsRecommendationsInput = transformedAttempt ? [transformedAttempt] : [];

  useEffect(() => {
    const { location } = history;
    const { pathname } = location;
    if (pathname?.includes(placementTestPath)) dispatch(actions.markAsDonePlacement({ attempt: currentAttempt }));
  }, []);

  const { getStrongTopics, getWeakTopics, total, totalMark, totalMaximumMark } = useTopicsRecommendations(
    topicsRecommendationsInput,
    {
      thresholdTopics: 0,
      useOnbaordingWeakTopics: false,
      questionAnswers,
      transformedQuestions,
    },
  );

  const membershipContext = {
    topics,
    total,
    totalMark,
    totalMaximumMark,
    correctTopics: getStrongTopics().slice(0, 3),
    incorrectTopics: getWeakTopics().slice(0, 3),
  };

  const { reviewResults } = useMembershipProxy({
    contextMap: {
      BaseLineTestReviewResults: membershipContext,
    },
    useStrict: true,
  });

  return (
    <SmartQuizContainer progress={100}>
      <ProgressBar
        backgroundColor={colors.mediumGray}
        barHeight="12px"
        color={colors.purple20}
        useBarOnly
        useBarStripes={false}
        percentage={100}
      />
      {reviewResults.run()}

      <Banner bottom leftPadding="30%">
        <Button onClick={navigateToDashboard} variant="primary">
          {returnToDashboard}
        </Button>
        <Button onClick={redirectToViewResults} variant="secondary">
          {reviewAnswers}
        </Button>
      </Banner>
    </SmartQuizContainer>
  );
};

export default Results;
