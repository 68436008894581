import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  SmartQuizContainer,
  PastQuizzesContainer,
  PastQuizzesTitle,
  PastQuizCard,
  PastQuizCardTitle,
  PastQuizCardResult,
  PastQuizCardTagsContainer,
  PastQuizzesHeader,
  PastQuizzesWrapper,
  PastQuizzesSubtitle,
  PastQuizzesTag,
  PastQuizzesShowMoreButton,
  PastQuizCardDate,
} from '../styled';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Button from '../../components/Button';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import { en } from '../../../i18n';
import { IAttempt, ILooseObject } from '../../types';
import { useNavigations, usePastQuizzes, useDispatch } from '../../hooks';
import { calculateTotalMark, calculateTotalMaximumMark } from '../../utils';
import Message from '../../components/Message';
import MenuButton from './MenuButton';
import { SLICE_NAME as studySpaceSlice, actions, quizSelectors } from '../../StudySpace/redux/slice';
import Spinner from '../../components/Spinner';
import CustomTooltip from '../../components/Tooltip';

const {
  pastQuizzes,
  youGot,
  of,
  marks,
  backToDashboard,
  incomplete,
  pastQuizzesSubtitle,
  showMore,
  noMoreQuizzes,
} = en.reviewPastQuizzes;

const { dashboard } = en;

const PastQuizzes = () => {
  const { attempts, allAttempts, menusOpen, handleMenuButtonClick, showAnswersModal } = usePastQuizzes();
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const { loadingAttemptsPage } = useSelector(quizSelectors.allState);
  const { navigateToDashboard } = useNavigations();
  const { dispatch } = useDispatch(studySpaceSlice);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const {
    location: { state: locationState, search },
  } = history;

  const { onlyIncomplete } = (locationState as ILooseObject) || {};

  useEffect(() => {
    dispatch(actions.fetchAttempts({ page }));
  }, [page]);

  const showFullLoader = () => {
    return loadingAttemptsPage && attempts.length === 0;
  };

  const handleShowMore = () => {
    setPage(page + 1);
  };

  const renderShowMoreButton = () => {
    const hasMoreQuizzes = allAttempts.length / 10 === page;
    const button = (
      <PastQuizzesShowMoreButton
        variant="primary"
        loading={loadingAttemptsPage}
        onClick={handleShowMore}
        disabled={!hasMoreQuizzes}
      >
        {showMore}
      </PastQuizzesShowMoreButton>
    );
    if (hasMoreQuizzes || loadingAttemptsPage) return button;
    return (
      <CustomTooltip
        centerText
        width="100%"
        arrow={false}
        title={noMoreQuizzes}
        placement="top"
        enterTouchDelay={0}
        disableFocusListener
      >
        <span>{button}</span>
      </CustomTooltip>
    );
  };

  const renderTags = (attempt: IAttempt) => {
    const topicsNames = attempt.questions
      .map(question => question.groupIds)
      .flat()
      .map(id => allTopics?.[id]?.name)
      .filter(topicName => topicName);
    return (
      <PastQuizCardTagsContainer key={attempt.attemptId}>
        {[...new Set(topicsNames)].slice(0, 3).map(name => (
          <PastQuizzesTag variant="outlined">{name}</PastQuizzesTag>
        ))}
      </PastQuizCardTagsContainer>
    );
  };

  const renderAttempts = () => {
    if (attempts?.length == 0)
      return (
        <Message>
          <span>{dashboard.pastQuizzes.notFoundMessage}</span>
        </Message>
      );

    const dataSource = onlyIncomplete ? attempts?.filter(attempt => !attempt.isDone) : attempts;
    return (
      <>
        {dataSource?.map((attempt, index) => (
          <PastQuizCard key={index}>
            <MenuButton
              index={index}
              isMenuOpen={menusOpen[index]}
              attempt={attempt}
              setIsClicked={handleMenuButtonClick}
              showAnswersModal={showAnswersModal}
            />
            <PastQuizCardTitle>
              {attempt.title}
              {!attempt.isDone && <span>{incomplete}</span>}
            </PastQuizCardTitle>
            <PastQuizCardDate>{format(new Date(attempt.ctime), "MMM d, yyyy 'at' H:mm a")}</PastQuizCardDate>
            <PastQuizCardResult>
              {youGot} <span>{calculateTotalMark(attempt)}</span> {of} <span>{calculateTotalMaximumMark(attempt)}</span>{' '}
              {marks}
            </PastQuizCardResult>
            {renderTags(attempt)}
          </PastQuizCard>
        ))}
        {renderShowMoreButton()}
      </>
    );
  };
  // TODO: Move this component to another file and use memo
  return (
    <SmartQuizContainer>
      <PastQuizzesWrapper>
        <PastQuizzesContainer>
          <PastQuizzesHeader>
            <div>
              <PastQuizzesTitle>{pastQuizzes}</PastQuizzesTitle>
              <PastQuizzesSubtitle>{pastQuizzesSubtitle}</PastQuizzesSubtitle>
            </div>
            <Button variant="secondary" onClick={navigateToDashboard}>
              {backToDashboard}
            </Button>
          </PastQuizzesHeader>
          {showFullLoader() ? <Spinner loading /> : renderAttempts()}
        </PastQuizzesContainer>
      </PastQuizzesWrapper>
    </SmartQuizContainer>
  );
};

export default PastQuizzes;
