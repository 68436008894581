import {
  PastQuizMenuButton as PastQuizMenuButtonDefault,
  PastQuizCardMenu as PastQuizCardMenuDefault,
  PastQuizCardMenuItem,
} from '../styled';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as Arrow } from '../../../images/polygon.svg';
import { IAttempt } from '../../types';
import { en } from '../../../i18n';
import { useMembershipProxy, useNavigations } from '../../hooks';

const { reviewAnswers, continueQuiz } = en.reviewPastQuizzes;

interface IMenuBtton {
  index: number;
  isMenuOpen: boolean;
  attempt: IAttempt;
  setIsClicked: (index: number) => void;
  showAnswersModal?: (attempt: IAttempt, index: number) => void;
  PastQuizMenuButton?: React.ElementType<any>;
  PastQuizCardMenu?: React.ElementType<any>;
}

const MenuButton = ({ index, isMenuOpen, setIsClicked, showAnswersModal, attempt, ...overrides }: IMenuBtton) => {
  const { PastQuizMenuButton = PastQuizMenuButtonDefault, PastQuizCardMenu = PastQuizCardMenuDefault } = overrides;
  const { navigateToContinueQuiz, navigateToMembershipUnconditional } = useNavigations();
  const { continueQuizButtons } = useMembershipProxy();
  const { buttonDisabled, buttonDisabledText } = continueQuizButtons.run();

  const renderCompleteButton = () => {
    if (attempt.isDone) return null;
    if (buttonDisabled)
      return (
        <PastQuizCardMenuItem onClick={navigateToMembershipUnconditional}>{buttonDisabledText}</PastQuizCardMenuItem>
      );
    return <PastQuizCardMenuItem onClick={() => navigateToContinueQuiz(attempt)}>{continueQuiz}</PastQuizCardMenuItem>;
  };

  return (
    <>
      <PastQuizMenuButton
        variant="secondary"
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          setIsClicked(index);
        }}
      >
        <MoreHorizIcon />
      </PastQuizMenuButton>
      <PastQuizCardMenu open={isMenuOpen}>
        <Arrow />
        {attempt.isDone && (
          <PastQuizCardMenuItem onClick={() => showAnswersModal?.(attempt, index)}>
            {reviewAnswers}
          </PastQuizCardMenuItem>
        )}
        {renderCompleteButton()}
      </PastQuizCardMenu>
    </>
  );
};

export default MenuButton;
