import TimerText from '../components/TrialTimer/TimerText';
import { HeaderTrial } from './styled';

const HeaderTrialBanner = () => {
  return (
    <HeaderTrial>
      <TimerText useRefreshSubscriptions />
    </HeaderTrial>
  );
};

export default HeaderTrialBanner;
