import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { quizSelectors } from '../StudySpace/redux/slice';
import { firstNameQuestionId, roleQuestionId, roleQuestionIdV2, readyQuestionID } from '../OnBoarding/constants';
import { IAttempt } from '../types';
import { onBoardingQuestionsV2, onBoardingQuestionsV3 } from '../StudySpace/staticConstants';
import { applySelector } from '../Apply/redux/slice';

const isFirstOnboardingPartSolved = (attempt: IAttempt) => {
  const firstNameQuestion = attempt.questions.find(question => question.questionId === firstNameQuestionId);
  const roleQuestion = attempt.questions.find(question => question.questionId === roleQuestionId);
  const readyQuestion = attempt.questions.find(question => question.questionId === readyQuestionID);
  return firstNameQuestion?.response && roleQuestion?.response && readyQuestion?.response;
};

const isRoleQuestionAnsweredV2 = (attempt: IAttempt) => {
  const roleQuestion = attempt.questions.find(question => question.questionId === roleQuestionIdV2);
  return roleQuestion?.response;
};

const isNewestOnboardingVersion = (attempt?: IAttempt) => {
  if (!attempt) return true;
  return attempt.questions.every(question =>
    onBoardingQuestionsV3.some(onBoardingQuestion => question.questionId === onBoardingQuestion.questionId),
  );
};

const useAttempts = () => {
  const { attempts, onBoardingAttempts, placementAttempts } = useSelector(quizSelectors.allState);
  const { attempts: applyAttempts } = useSelector(applySelector.allState);

  const attemptsCachedInfo = useMemo(() => {
    if (!attempts && !onBoardingAttempts && !placementAttempts && !applyAttempts) return {};

    const filteredAttempts = attempts?.filter(
      attempt => !attempt.isOnBoarding && !attempt.isNurseGPT && !attempt.isRolePlaceHolder && !attempt.isApply,
    );
    const isPlacementTestDone = placementAttempts?.some(attempt => attempt.isDone);
    // Either there is a done placement test attempt or return the first placement test attempt is found
    const currentPlacementTest = placementAttempts?.find(attempt => attempt.isDone) || placementAttempts?.[0];
    const currentOnBoardingTest =
      onBoardingAttempts?.find(attempt => attempt.isDone) ||
      onBoardingAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      onBoardingAttempts?.[0];
    const isFullyOnboarded =
      onBoardingAttempts?.some(attempt => attempt.isDone) || !isNewestOnboardingVersion(currentOnBoardingTest);

    const isOnboarded = isFullyOnboarded || onBoardingAttempts?.some(attempt => isFirstOnboardingPartSolved(attempt));
    //const isOnboarded = false;
    const readinessAttempts = attempts?.filter(attempt => attempt.isReadiness);
    const currentReadinessAttempt = readinessAttempts?.[0];

    const isRoleQuestionAnswered =
      !!onBoardingAttempts?.find(isFirstOnboardingPartSolved) || !!onBoardingAttempts?.find(isRoleQuestionAnsweredV2);

    const rolePlaceholderAttempt = attempts?.find(attempt => attempt.isRolePlaceHolder);

    const currentApplyAttempt =
      applyAttempts?.find(attempt => attempt.isDone) ||
      //applyAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      applyAttempts?.[0];
    //console.log(isOnboarded, isFullyOnboarded, currentOnBoardingTest);
    return {
      onBoardingAttempts,
      placementTestAttempts: placementAttempts,
      isOnboarded,
      isFullyOnboarded,
      isPlacementTestDone,
      filteredAttempts,
      allAttempts: attempts,
      currentPlacementTest,
      currentOnBoardingTest,
      readinessAttempts,
      currentReadinessAttempt,
      isRoleQuestionAnswered,
      rolePlaceholderAttempt,
      applyAttempts,
      currentApplyAttempt,
    };
  }, [attempts, attempts?.length, onBoardingAttempts, onBoardingAttempts?.length, applyAttempts?.length]);

  return attemptsCachedInfo;
};

export default useAttempts;
