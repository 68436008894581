import { TabTitle } from './styled';
interface ITabTitle {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

const Title = ({ Icon, text }: ITabTitle) => {
  return (
    <TabTitle className="tab-title">
      <Icon />
      <span>{text}</span>
    </TabTitle>
  );
};

export default Title;
