import { getOrbitsConfig } from '../../utils';
import { EAddons, EPlanTypes } from '../types';

const config = getOrbitsConfig();
const {
  essentials,
  smartn30Days,
  smartn90Days,
  basicAccess,
  smartCLEXAddon,
  allAccess,
  sponsoredBasicAccess,
} = config.stripePlans;

interface IBaseStripePlan {
  price: number;
  productId: string;
  priceId: string;
  benefits: string[];
  type: EPlanTypes;
  title: string;
  priceDescription: string;
}
interface IPlan extends IBaseStripePlan {
  outerTitle: string;
  headLine: string;
  period: string;
  priceDescription: string;
  promotion?: string;
}

interface IAddon extends IBaseStripePlan {
  title: string;
  subTitle: string;
  isOneTime: boolean;
  billingCycle: string;
  key: EAddons;
}

interface IPlans {
  basicAccess: IPlan;
  sponsoredBasicAccess: IPlan;
}

interface IAddons {
  smartCLEX: IAddon;
}

const plans: IPlans = {
  basicAccess: {
    outerTitle: 'Aspire',
    title: 'Aspire',
    price: 9,
    priceId: basicAccess.priceId,
    productId: basicAccess.productId,
    headLine: "You are currently on the Smart'n Aspire Membership Plan",
    period: '30 Days',
    priceDescription: '/month USD',
    benefits: [
      'Advance your clinical judgment with hours of realistic case study practice across all body systems',
      'Practice and study with access to NCLEX-style questions & rationales created by AI, perfected by Registered Nurses',
      'Save time with tailored and adaptive quizzes that focus on what you need to know ',
      'Understand your strengths and identify knowledge gaps, in real-time',
      'Access monthly scholarship grants',
      'Access to surprise rewards and nurse perks',
      'Early access to upcoming features like Nurse Job Matching',
    ],
    type: EPlanTypes.SUBSCRIPTION,
  },

  sponsoredBasicAccess: {
    outerTitle: 'Aspire',
    title: 'Aspire',
    price: 0,
    priceId: sponsoredBasicAccess.priceId,
    productId: sponsoredBasicAccess.productId,
    headLine: "You are currently on a sponsored Smart'n Aspire Membership",
    period: '30 Days',
    priceDescription: '(sponsorship value $9 / month USD)',
    benefits: [
      'Advance your clinical judgment with hours of realistic case study practice across all body systems',
      'Practice and study with access to NCLEX-style questions & rationales created by AI, perfected by Registered Nurses',
      'Save time with tailored and adaptive quizzes that focus on what you need to know ',
      'Understand your strengths and identify knowledge gaps, in real-time',
      'Access monthly scholarship grants',
      'Access to surprise rewards and nurse perks',
      'Early access to upcoming features like Nurse Job Matching',
    ],
    type: EPlanTypes.SUBSCRIPTION,
  },
};
const addons: IAddons = {
  [EAddons.smartCLEX]: {
    title: 'SmartCLEX™ Exam',
    key: EAddons.smartCLEX,
    subTitle: 'Take the guesswork out of passing your NCLEX-RN by adding SmartCLEX™',
    isOneTime: true,
    billingCycle: '',
    price: 29,
    productId: smartCLEXAddon.productId,
    priceId: smartCLEXAddon.priceId,
    benefits: [
      'The SmartCLEX™ exam models the Next Gen NCLEX-RN',
      'The SmartCLEX™ assessment uses AI to accurately predict your likelihood of passing',
      'The SmartCLEX™ exam identifies topical strengths and opportunities for improvement',
      'Add-on Includes bonus NCLEX style practice questions, verified and handpicked by nurses who passed the NCLEX-RN',
      'Add-on includes bonus NCLEX-style case studies, verified and handpicked by nurses who passed the NCLEX-RN',
    ],
    type: EPlanTypes.ADDON,
    priceDescription: 'USD one-time addition',
  },
};

const oldPriceIds = [essentials.priceId, smartn30Days.priceId, smartn90Days.priceId, allAccess.priceId];

interface IDeprecatedSubscription {
  priceId: string;
  price: string;
  periodDescription: string;
  title: string;
}

interface IDeprecatedSubscriptions {
  essentials: IDeprecatedSubscription;
  smartn30Days: IDeprecatedSubscription;
  smartn90Days: IDeprecatedSubscription;
  allAccess: IDeprecatedSubscription;
}

const deprecatedSubscriptions: IDeprecatedSubscriptions = {
  essentials: {
    priceId: essentials.priceId,
    price: '119',
    periodDescription: '/year USD',
    title: 'Basic Access',
  },
  smartn30Days: {
    priceId: smartn30Days.priceId,
    price: '39',
    periodDescription: '/month USD',
    title: 'Basic Access',
  },

  smartn90Days: {
    priceId: smartn90Days.priceId,
    price: '79',
    periodDescription: '/3 months USD',
    title: 'Basic Access',
  },

  allAccess: {
    priceId: allAccess.priceId,
    price: '119',
    periodDescription: '/year USD',
    title: 'Basic Access',
  },
};

const priceIdsToStaticPlans = {
  [basicAccess.priceId]: plans.basicAccess,
  [smartCLEXAddon.priceId]: addons.smartCLEX,
};

const defaultPlan = plans.basicAccess;
const defaultSponsoredPlan = plans.sponsoredBasicAccess;

export type { IPlan, IPlans, IAddon, IDeprecatedSubscription, IDeprecatedSubscriptions };
export {
  plans,
  oldPriceIds,
  priceIdsToStaticPlans,
  defaultPlan,
  addons,
  deprecatedSubscriptions,
  defaultSponsoredPlan,
};
