import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, SLICE_NAME } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import { actions as globalActions } from '../../redux/globalSlice';
import { actions as topicActions } from './topics/slice';
import { endpoints } from '../../constants';
import { onBoardingQuizIds, placementQuizIds } from '../staticConstants';
import { calculateProficiency, getMonthAgo, pollRequest, requestShowAll } from '../../utils';
import {
  IAttempt,
  ICreateQuizResponse,
  IStartQuizResponse,
  IFetchQuestionResponse,
  IQuestion,
  IAnswerQuestionResponse,
  IFetchAttemptResponse,
  EQuizTypes,
  ILooseObject,
} from '../../types';
import {
  FetchQuizQuestions,
  PopulateAnswers,
  ZipQuestionReader,
  QuestionTransformer,
  QuestionResponseTransformer,
  FullAttemptsTransformer,
  AllAttemptsTransformer,
} from '../../classes';
import { PayloadAction } from '@reduxjs/toolkit';

function* createQuiz(action: { payload: { groupIds: string[]; questionTypes: EQuizTypes[] } }) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    const { payload } = action;
    // Create quiz
    const { data }: ICreateQuizResponse = yield call(axiosRequest, {
      url: endpoints.createQuiz,
      requestData: { body: payload },
      method: ERequestMethods.POST,
    });
    let attemptId: string = '';
    // Start quiz
    yield put(actions.createQuizSuccess({ quizId: data }));
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId: data } },
      method: ERequestMethods.POST,
    });
    attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');

    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt?.data?.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );

    if (!attempt) throw new Error('Attempt not found');
    const fullAttemptsTransformer = new FullAttemptsTransformer();
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const transformedAttempt = allAttemptsTransformer.transform([attempt]);
    const questionTransformer = new QuestionTransformer();
    const fetchQuizQuestions = new FetchQuizQuestions(attempt);
    const questions: IQuestion[] = yield call([fetchQuizQuestions, fetchQuizQuestions.fetchQuestions]);
    const questionsTransformed = questionTransformer.transform({
      questions,
      questionIds: fetchQuizQuestions.getQuestionIds(),
      attempt,
    });
    const populateAnswers = new PopulateAnswers(questionsTransformed, attempt);
    const { currentQuestionInfo } = populateAnswers.populate();
    const { questionId, questionIndex } = currentQuestionInfo;
    transformedAttempt[0].totalDisplayQuestions = fullAttemptsTransformer.getTotalDisplayQuestions(
      questionsTransformed,
      transformedAttempt[0],
      questionId,
    ).total;
    yield put(actions.setQuestions({ questions: questionsTransformed }));
    yield put(
      actions.setCurrentQuestion({
        questionId,
        currentQuestionIndex: questionIndex,
        currentDisplayeQuestionIndex: questionIndex,
      }),
    );
    transformedAttempt[0].questionsTransformed = questionsTransformed;
    yield put(actions.startQuizSuccess({ attempt: transformedAttempt[0] }));
    yield put(globalAction({ loading: false, errors: {} }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* fetchQuestion(action: { payload: { questionId: string } }) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    const { payload } = action;
    const { data: questionUrl }: IFetchQuestionResponse = yield call(axiosRequest, {
      url: endpoints.fetchQuestion(payload.questionId),
    });
    const { data: zipFile } = yield call(axiosRequest, {
      url: questionUrl,
      responseType: 'blob',
      useAuthorization: false,
    });
    const questionReader = new ZipQuestionReader(zipFile);
    const question: IQuestion = yield call([questionReader, questionReader.getQuestion]);
    yield put(actions.fetchQuestionSuccess({ question, questionId: payload.questionId }));
    yield put(globalAction({ loading: false, errors: {} }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* submitAnswer(action: {
  payload: {
    attemptId: string;
    questionId: string;
    response: string | string[];
  };
}) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  const questionResponseTransformer = new QuestionResponseTransformer();
  try {
    const { payload } = action;
    const { questionId, response } = payload;
    const transformedResponse = questionResponseTransformer.transformOut({ questionId, response });
    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(payload.attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });
    yield put(actions.submitAnswerSuccess({ data: {}, questionId: payload.questionId }));
    yield put(globalAction({ loading: false, errors: {} }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* submitSubAnswer(action: {
  payload: {
    attemptId: string;
    questionId: string;
    response: ILooseObject[];
    caseStudySubquestionId: string;
  };
}) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  const questionResponseTransformer = new QuestionResponseTransformer();
  try {
    const { payload } = action;
    const { questionId, response } = payload;
    const transformedResponse = questionResponseTransformer.transformOut({ questionId, response });
    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(payload.attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });
    yield put(
      actions.submitSubAnswerSuccess({
        data: {},
        questionId: payload.questionId,
        caseStudySubquestionId: payload.caseStudySubquestionId,
      }),
    );
    yield put(globalAction({ loading: false, errors: {} }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* fetchAttempts(action: PayloadAction<{ earliest?: string; showAll?: boolean; page?: number }>) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    const {
      payload: { earliest, showAll, page },
    } = action;
    let originalData: IAttempt[];
    if (showAll) {
      originalData = yield call(requestShowAll, {
        url: endpoints.fetchAttempts,
        pageSize: 90,
        earliest,
      });
    } else {
      const { data } = yield call(axiosRequest, {
        url: `${endpoints.fetchAttempts}&page=${page}&pageSize=10`,
      });
      originalData = data;
    }
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const data = allAttemptsTransformer.transform(originalData);
    yield put(actions.fetchAttemptsSuccess({ attempts: data, showAll }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* fetchProficiency() {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    const originalData: IAttempt[] = yield call(requestShowAll, {
      url: endpoints.fetchAttempts,
      pageSize: 90,
      earliest: getMonthAgo(),
    });
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const data = allAttemptsTransformer.transform(originalData);
    const proficiency = calculateProficiency(data);
    yield put(actions.setProficiency({ proficiency }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

// Handles side effects of setting current attempt if not handled
function* setCurrentAttempt(action: PayloadAction<{ attempt: IAttempt }>) {
  try {
    const { payload } = action;
    const { attempt } = payload;
    if (attempt.questionsTransformed) return;
    const attemptsTransformer = new FullAttemptsTransformer();
    yield put(actions.setLoadingAttempts({ isLoading: true }));
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const {
      selectedTopicIds,
      questionsTransformed,
      questionAnswers,
      questionId,
      questionIndex,
      displayedQuestionIndex,
    } = transformedAttempt;
    yield put(topicActions.setSelectedTopicIds({ selectedTopicIds }));
    yield put(actions.setQuestions({ questions: questionsTransformed }));
    yield put(actions.setQuestionAnswers({ questionAnswers }));
    yield put(
      actions.setCurrentQuestion({
        questionId,
        currentQuestionIndex: questionIndex,
        currentDisplayeQuestionIndex: displayedQuestionIndex,
      }),
    );
    yield put(actions.setCurrentAttemptNoSideEffect({ attempt: transformedAttempt }));
    yield put(actions.setLoadingAttempts({ isLoading: false }));
  } catch (error) {
    //
  }
}

function* fetchAttempt(action: { payload: { attemptId: string } }) {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    const {
      payload: { attemptId },
    } = action;
    const { data: attempt }: IFetchAttemptResponse = yield call(axiosRequest, {
      url: endpoints.fetchAttempt(attemptId),
    });
    if (!attempt) throw new Error('Attempt not found');
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const data = allAttemptsTransformer.transform([attempt]);

    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], data[0]);
    const {
      selectedTopicIds,
      questionsTransformed,
      questionAnswers,
      questionId,
      questionIndex,
      isDone,
      displayedQuestionIndex,
    } = transformedAttempt;
    yield put(topicActions.setSelectedTopicIds({ selectedTopicIds }));
    yield put(actions.setQuestions({ questions: questionsTransformed }));
    yield put(actions.setQuestionAnswers({ questionAnswers }));
    yield put(
      actions.setCurrentQuestion({
        questionId,
        currentQuestionIndex: questionIndex,
        currentDisplayeQuestionIndex: displayedQuestionIndex,
      }),
    );
    yield put(actions.setCurrentAttempt({ attempt: transformedAttempt }));
    if (isDone) yield put(actions.viewResults());
    yield put(actions.fetchAttemptSuccess({ attempt: transformedAttempt }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* attemptQuiz(action: { payload: { quizId: string } }) {
  try {
    const { quizId } = action.payload;
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');
    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt.data.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );

    if (!attempt) throw new Error('Attempt not found');
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const fullAttemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt = allAttemptsTransformer.transform([attempt]);
    const questionTransformer = new QuestionTransformer();
    const fetchQuizQuestions = new FetchQuizQuestions(attempt);
    const questions: IQuestion[] = yield call([fetchQuizQuestions, fetchQuizQuestions.fetchQuestions]);
    const questionsTransformed = questionTransformer.transform({
      questions,
      questionIds: fetchQuizQuestions.getQuestionIds(),
      attempt,
    });

    const populateAnswers = new PopulateAnswers(questionsTransformed, attempt);
    const { currentQuestionInfo } = populateAnswers.populate();
    const { questionId, questionIndex } = currentQuestionInfo;
    const { total, currentDisplayedIndex } = fullAttemptsTransformer.getTotalDisplayQuestions(
      questionsTransformed,
      transformedAttempt[0],
      questionId,
    );
    transformedAttempt[0].totalDisplayQuestions = total;

    yield put(actions.setQuestions({ questions: questionsTransformed }));
    yield put(
      actions.setCurrentQuestion({
        questionId,
        currentQuestionIndex: questionIndex,
        currentDisplayeQuestionIndex: currentDisplayedIndex,
      }),
    );
    //yield put(actions.startQuizSuccess({ attempt }));
    yield put(actions.attemptQuizSuccess({ attempt: transformedAttempt[0] }));
  } catch (e) {
    const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
    yield put(globalAction({ loading: false, errors: e }));
  }
}

function* fetchOnBoardingAttempts() {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of onBoardingQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}` });
        attempts = data.concat(attempts);
      }
    });
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    yield put(actions.fetchOnBoardingAttemptsSuccess({ attempts: transformedAttempts }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* fetchPlacementAttempts() {
  const globalAction = globalActions[`${SLICE_NAME}SetGlobalState`];
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of placementQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}` });
        attempts = data.concat(attempts);
      }
    });
    const allAttemptsTransformer = new AllAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    yield put(actions.fetchPlacementAttemptsSuccess({ attempts: transformedAttempts }));
  } catch (error) {
    yield put(globalAction({ loading: false, errors: error }));
  }
}

function* watchCreateQuiz() {
  yield takeLatest(actions.createQuiz, createQuiz);
}
function* watchFetchQuestion() {
  yield takeLatest(actions.fetchQuestion, fetchQuestion);
}
function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}
function* watchSubmitSubAnswer() {
  yield takeLatest(actions.submitSubAnswer, submitSubAnswer);
}

function* watchFetchAttempts() {
  yield takeLatest(actions.fetchAttempts, fetchAttempts);
}

function* watchFetchAttempt() {
  yield takeLatest(actions.fetchAttempt, fetchAttempt);
}

function* watchStartAttempt() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSetCurrentAttemt() {
  yield takeLatest(actions.setCurrentAttempt, setCurrentAttempt);
}

function* watchFetchOnBoardingAttempts() {
  yield takeLatest(actions.fetchOnBoardingAttempts, fetchOnBoardingAttempts);
}

function* watchFetchPlacementAttempts() {
  yield takeLatest(actions.fetchPlacementAttempts, fetchPlacementAttempts);
}

function* watchFetchProficiency() {
  yield takeLatest(actions.fetchProficiency, fetchProficiency);
}

const sagas = [
  watchCreateQuiz,
  watchFetchQuestion,
  watchSubmitAnswer,
  watchFetchAttempts,
  watchStartAttempt,
  watchFetchAttempt,
  watchSetCurrentAttemt,
  watchSubmitSubAnswer,
  watchFetchOnBoardingAttempts,
  watchFetchPlacementAttempts,
  watchFetchProficiency,
];

export default sagas;
