import { useEffect, useRef } from 'react';

const useVariableKeyDownPress = (keyCodes: string, handler: () => void, continueProp?: boolean) => {
  const handleButtonDown = (event: KeyboardEvent) => {
    if (keyCodes === event.code) {
      handler();
      if (!continueProp) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  const previousListener = useRef(handleButtonDown);
  useEffect(() => {
    window.removeEventListener('keydown', previousListener.current);
    previousListener.current = handleButtonDown;
    window.addEventListener('keydown', handleButtonDown);
    return () => {
      window.removeEventListener('keydown', handleButtonDown);
    };
  }, [handler]);
};

export default useVariableKeyDownPress;
