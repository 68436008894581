import { IStepComponent } from '../types';
import { ChoicesContainerCommute } from './styled';
import useStep from './useStep';

const Commute = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices } = useStep({ question });

  return (
    <>
      {title}
      <ChoicesContainerCommute>{renderMultipleChoices('radio')}</ChoicesContainerCommute>
    </>
  );
};

export default Commute;
