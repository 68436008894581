import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { OpacityHoverAnimation } from '../../sharedStyled';

const AttachmentButtonContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const ButtonContainer = styled('div')`
  input[type='file'] {
    display: none;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;

  label {
    width: 100%;
    display: block;
    height: 100%;
  }
`;

const DeleteIconContainer = styled('div')`
  svg {
    color: ${colors.red};
  }
  ${OpacityHoverAnimation()};
`;

export { AttachmentButtonContent, ButtonContainer, DeleteIconContainer };
