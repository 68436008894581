import { EVideoEventsDetails } from './enums';
import { TFieldValues } from '../components/Form/Fields/types';

enum Eevents {
  LOGIN = 'ORBITS User Logged In',
  ONBOARDING_QUESTION = 'SmartN User Answered Onboarding question',
}

interface IVideoEvent {
  detailType: EVideoEventsDetails;
  detail: {
    startTime?: number;
    videoId?: string;
    groupId?: string;
  };
}

interface ILoginEvent {
  detailType: Eevents.LOGIN;
}

type Event = IVideoEvent | ILoginEvent;
export { Eevents };
export type { Event, IVideoEvent, ILoginEvent };
