import { useState, useEffect } from 'react';
import {
  InfoContainer,
  CaseStudyContainer,
  QuestionContainer,
  InfoTitle,
  InfoSubtitle,
  InfoTabsContainer,
  InfoTab,
  InfoTabContent,
  InfoTabContentWrapper,
  QuestionBody,
  QuestionNote,
  ReviewContainer,
  RationaleContainer,
  RationaleTitle,
  Rationale,
  CorrectAnswerContainer,
  CorrectAnswerTitle,
  MarksHeader,
} from './styled';
import { en } from '../../../../i18n';
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import MatrixChoice from './MatrixChoice';
import MultipleChoiceHighlight from './MultipleChoiceHighlight';
import DropDownCloze from './DropDownCloze';
import DragDropCloze from './DragDropCloze';
import BowTie from './BowTie';
import ContentRenderer from './ContentRenderer';
import { ReactComponent as TipIcon } from '../../../../images/tip.svg';
import { ReactComponent as PlusIcon } from '../../../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../images/minus.svg';
import { ICaseStudy, ECaseStudyQuestionTypes, ILooseObject, IQuestionAnswers, EQuestionTypes } from '../../../types';
import CustomTooltip from '../../../components/Tooltip';
import { PlacementCaseStudy } from '../../../PlacementTest/styled';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import parse from 'html-react-parser';
import QuestionTip from '../../../components/QuestionTip';

const { ngnCaseStudy, smartnTip, rationale, correctAnswers, noModify, mark, marks, of } = en.caseStudy;
const { markingExplanations } = en.reviewResults;

const CaseStudy = ({
  caseStudy,
  currentQuestionIndex,
  isStarted,
  answerSubquestion,
  answers,
  isReviewingCaseStudy,
  isViewingResults,
  tags,
  isPlacement,
}: {
  caseStudy: ICaseStudy;
  currentQuestionIndex: number;
  isStarted: boolean;
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  answers: IQuestionAnswers;
  isReviewingCaseStudy?: boolean;
  isViewingResults?: boolean;
  tags?: JSX.Element;
  isPlacement?: boolean;
}) => {
  const introduction = caseStudy.introduction;
  const tabs = caseStudy.questions[currentQuestionIndex].detail.tabs;
  const currentQuestion = caseStudy.questions[currentQuestionIndex].detail.question.detail;
  const currentQuestionId = caseStudy.questions[currentQuestionIndex].id;
  const questionType = caseStudy.questions[currentQuestionIndex].detail.question.detailType;
  const totalQuestions = caseStudy.questions.length;
  const [currentTab, setCurrentTab] = useState(0);
  const [tipOpen, setTipOpen] = useState(false);
  const currentQuestionMark = answers?.subAnswers?.[currentQuestionId]?.parsedMark?.mark;
  const currentQuestionMaxMark = answers?.subAnswers?.[currentQuestionId]?.parsedMark?.maximumMark;

  useEffect(() => {
    setCurrentTab(0);
  }, [currentQuestionIndex]);

  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  const renderQuestion = (isReviewing: boolean, isDisabled: boolean) => {
    switch (questionType) {
      case ECaseStudyQuestionTypes.SINGLE_CHOICE:
        return (
          <SingleChoice
            choices={currentQuestion.options || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            correctAnswer={currentQuestion.answerId}
            isReviewing={isReviewing}
          />
        );
      case ECaseStudyQuestionTypes.MULTIPLE_CHOICE_V2:
        return (
          <MultipleChoice
            choices={currentQuestion.options || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            correctAnswer={currentQuestion.answerIds}
            isReviewing={isReviewing}
          />
        );
      case ECaseStudyQuestionTypes.MATRIX_SINGLE_CHOICE:
      case ECaseStudyQuestionTypes.MATRIX_MULTIPLE_CHOICE:
        return (
          <MatrixChoice
            header={currentQuestion.header}
            rows={currentQuestion.questions || []}
            isMultiple={questionType === ECaseStudyQuestionTypes.MATRIX_MULTIPLE_CHOICE}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            isReviewing={isReviewing}
            isDisabled={isDisabled}
          />
        );
      case ECaseStudyQuestionTypes.MULTIPLE_HIGHLIGHT:
        return (
          <MultipleChoiceHighlight
            header={currentQuestion.header}
            choices={currentQuestion.options || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            correctAnswer={currentQuestion.answerIds}
            isReviewing={isReviewing}
          />
        );
      case ECaseStudyQuestionTypes.DROP_DOWN_CLOZE:
        return (
          <DropDownCloze
            questions={currentQuestion.questions || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            isReviewing={isReviewing}
          />
        );
      case ECaseStudyQuestionTypes.DRAG_DROP_CLOZE:
        return (
          <DragDropCloze
            questions={currentQuestion.questions || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            correctAnswer={currentQuestion.questions?.[0].detail.answerIds || []}
            isReviewing={isReviewing}
          />
        );
      case ECaseStudyQuestionTypes.BOW_TIE:
        return (
          <BowTie
            questions={currentQuestion.questions || []}
            answerSubquestion={answerSubquestion}
            questionId={currentQuestionId}
            key={currentQuestionId}
            answer={answers?.subAnswers?.[currentQuestionId]?.value}
            isDone={answers?.subAnswers?.[currentQuestionId]?.isDone}
            isReviewing={isReviewing}
          />
        );
      default:
        return <></>;
    }
  };
  const Container = isPlacement ? PlacementCaseStudy : CaseStudyContainer;
  return (
    <>
      <Container isReviewing={isReviewingCaseStudy}>
        <InfoContainer>
          <InfoTitle>{ngnCaseStudy(currentQuestionIndex + 1, totalQuestions)}</InfoTitle>
          {tags}
          <InfoSubtitle>
            <ContentRenderer content={introduction} />
          </InfoSubtitle>
          <InfoTabsContainer>
            {tabs.map((tab, index) => (
              <InfoTab key={index} selected={currentTab === index} onClick={() => setCurrentTab(index)}>
                <ContentRenderer content={tab.detail.label} />
              </InfoTab>
            ))}
          </InfoTabsContainer>
          <InfoTabContentWrapper>
            <InfoTabContent>
              <ContentRenderer content={(tabs[currentTab] || tabs[0]).detail.content} />
            </InfoTabContent>
          </InfoTabContentWrapper>
        </InfoContainer>
        <QuestionContainer>
          {isViewingResults && currentQuestionMark !== undefined && (
            <MarksHeader>
              <span>
                {currentQuestionMark} {of} {currentQuestionMaxMark} {currentQuestionMaxMark === 1 ? mark : marks}
              </span>
              <CustomTooltip
                enterTouchDelay={0}
                disableFocusListener
                width="100%"
                arrow={false}
                title={parse(markingExplanations[questionType])}
                placement="top"
              >
                <InfoIcon />
              </CustomTooltip>
            </MarksHeader>
          )}
          {caseStudy.questions[currentQuestionIndex].detail.question.detail.tip && currentQuestion.tip && (
            <QuestionTip tip={currentQuestion.tip} />
          )}
          <QuestionBody>
            <ContentRenderer content={currentQuestion.question} />
          </QuestionBody>
          {currentQuestion.note && (
            <QuestionNote>
              <ContentRenderer content={currentQuestion.note} />
            </QuestionNote>
          )}
          {answers?.subAnswers?.[currentQuestionId]?.isDone && !isViewingResults ? (
            <CustomTooltip centerText width="100%" arrow={false} title={noModify} placement="top">
              <span>{renderQuestion(false, true)}</span>
            </CustomTooltip>
          ) : (
            renderQuestion(false, false)
          )}
        </QuestionContainer>
      </Container>
      {isReviewingCaseStudy && (
        <ReviewContainer>
          <RationaleContainer>
            <RationaleTitle>{rationale}</RationaleTitle>
            <Rationale>
              <ContentRenderer content={currentQuestion.explanation} />
            </Rationale>
          </RationaleContainer>
          <CorrectAnswerContainer>
            <CorrectAnswerTitle>{correctAnswers}</CorrectAnswerTitle>
            {[ECaseStudyQuestionTypes.MULTIPLE_CHOICE_V2, ECaseStudyQuestionTypes.SINGLE_CHOICE].includes(
              questionType,
            ) && (
              <QuestionBody>
                <ContentRenderer content={currentQuestion.question} />
              </QuestionBody>
            )}
            {renderQuestion(true, true)}
          </CorrectAnswerContainer>
        </ReviewContainer>
      )}
    </>
  );
};

export default CaseStudy;
