import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IProps extends TooltipProps {
  leftArrow?: string;
  leftPopper?: string;
  rightPopper?: string;
  marginTopPopper?: string;
  arrow?: boolean;
  width?: string;
  centerText?: boolean;
  fontSize?: string;
}

const CustomTooltip = styled(
  ({
    className,
    leftArrow,
    leftPopper,
    rightPopper,
    marginTopPopper,
    arrow = true,
    centerText,
    fontSize,
    ...props
  }: IProps) => <Tooltip {...props} arrow={arrow} classes={{ popper: className }} />,
)(
  ({
    theme,
    leftArrow,
    leftPopper,
    centerText,
    rightPopper,
    width = '75%',
    marginTopPopper = '7px',
    fontSize = '0.875rem',
  }) => {
    return {
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
        ...(leftArrow && { left: `${leftArrow} !important` }),
        overflow: 'hidden',
        position: 'absolute',
        boxSizing: 'border-box',

        borderStyle: 'solid',
        borderWidth: '0 10px 15px 10px',
        borderColor: 'transparent transparent #353535 transparent',
      },

      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(2),
        fontSize,
        transform: 'none',
        width,
        background: '#353535',
      },

      '&.MuiTooltip-popper': {
        ...(leftPopper && { left: `${leftPopper} !important` }),
        ...(rightPopper && { right: `${rightPopper} !important` }),
        ...(centerText && { textAlign: 'center' }),

        marginTop: '-1.38em',
        '& div': {
          marginTop: `${marginTopPopper} !important`,
        },
      },
    };
  },
);

export type { IProps as ITooltipProps };
export default CustomTooltip;
