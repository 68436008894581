import { useState, ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { SingleChoiceContainer, Choice } from './styled';
import ContentRenderer from './ContentRenderer';
import { ICaseStudyOption, ILooseObject } from '../../../types';

const MultipleChoice = ({
  choices,
  answerSubquestion,
  questionId,
  answer,
  isDone,
  correctAnswer,
  isReviewing,
}: {
  choices: ICaseStudyOption[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  answer?: ILooseObject;
  isDone?: boolean;
  correctAnswer?: string[];
  isReviewing: boolean;
}) => {
  const onClickChoice = (id: string) => () => {
    const isChecked = isReviewing ? !!correctAnswer?.includes(id) : !!answer?.answerIds?.includes(id);
    const newValue = [...(answer?.answerIds || [])];
    if (!isChecked) newValue.push(id);
    else newValue.splice(newValue.indexOf(id), 1);
    answerSubquestion({ id: questionId, answerIds: newValue }, { id: questionId, answerIds: correctAnswer });
  };

  return (
    <SingleChoiceContainer>
      {choices.map(choice => (
        <Choice onClick={onClickChoice(choice.id)} key={choice.id}>
          <Checkbox
            checked={isReviewing ? !!correctAnswer?.includes(choice.id) : !!answer?.answerIds?.includes(choice.id)}
            value={choice.id}
            //onChange={onChange}
            disabled={isDone}
          />
          <ContentRenderer content={choice.detail.content} />
        </Choice>
      ))}
    </SingleChoiceContainer>
  );
};

export default MultipleChoice;
