import { TabContainer } from '../Components/styled';
import { ReactComponent as Practice } from '../../../images/icons/dashboard/parctice.svg';
import TabTitle from '../Components/TabTitle';
import { en } from '../../../i18n';
import ContinueQuiz from './ContinueQuiz';
import StartQuiz from './StartQuiz';
import { IHomePageSection } from '../Components/types';

const { dashboard } = en;
const { title } = dashboard.practiceQuiz;

const PracticeQuiz = ({ attempts, id }: IHomePageSection) => {
  return (
    <TabContainer id={`${id}`}>
      <TabTitle Icon={Practice} text={title} />
      <ContinueQuiz />
      <StartQuiz attempts={attempts} />
    </TabContainer>
  );
};

export default PracticeQuiz;
