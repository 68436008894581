import { actions } from './slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { endpoints } from '../../constants';
import axiosRequest, { ERequestMethods } from '../../axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { getOrbitsConfig } from '../../../utils';
import { Event } from '../../types';

function* sendEvent(action: PayloadAction<{ event: Event }>) {
  try {
    const {
      payload: { event },
    } = action;

    const config = getOrbitsConfig();
    yield call(axiosRequest, {
      url: `${config?.endpointV2}${endpoints.sendEvent}`,
      method: ERequestMethods.POST,
      useCorsHeaders: false,
      requestData: {
        body: {
          ...event,
        },
      },
    });
    yield put(actions.sendEventSuccess());
  } catch (errors) {
    yield put(actions.sendEventFailure({ errors }));
  }
}

function* watchSendEvent() {
  yield takeLatest(actions.sendEventRequest, sendEvent);
}

const sagas = [watchSendEvent];

export default sagas;
