import { ChoicesContainer, Choice, SelectedTag, ChoicesLimit } from './styled';
import { en } from '../../i18n';
import CustomTooltip from '../components/Tooltip';
import { ILooseObject } from '../types';

const { selected, select, topics, of, topicsSelected, clearAllSelections } = en.onBoarding;

const Choices = ({
  choices,
  onChange,
  onMultipleChange,
  value,
  isMultiple,
  isTopicsChoices,
  limit,
  clearMultipleSelection,
}: {
  choices: ILooseObject[];
  onChange: (id: string) => void;
  onMultipleChange: (id: string) => void;
  value?: string | string[];
  isMultiple?: boolean;
  isTopicsChoices?: boolean;
  limit?: number;
  clearMultipleSelection: () => void;
}) => {
  const ChoiceContentWrapper = ({ children, tooltipTitle }: { children: React.ReactNode; tooltipTitle: string }) =>
    isTopicsChoices ? (
      <CustomTooltip centerText width="100%" arrow={false} title={tooltipTitle} placement="top">
        <span>{children}</span>
      </CustomTooltip>
    ) : (
      <>{children}</>
    );
  const limitText = !value?.length
    ? `${select} ${limit} ${topics}`
    : `${value?.length} ${of} ${limit} ${topicsSelected}`;
  return (
    <ChoicesContainer isTopicsChoices={isTopicsChoices}>
      {limit && (
        <ChoicesLimit>
          <div>{limitText}</div>
          {!!value?.length && <div onClick={clearMultipleSelection}>{clearAllSelections}</div>}
        </ChoicesLimit>
      )}
      {choices.map(choice => {
        const Icon = choice.icon;
        const isSelected = isMultiple ? value?.includes(choice.id) : value === choice.id;
        const disabled = isTopicsChoices && !isSelected && value?.length === limit;
        return (
          <Choice
            onClick={() => (isMultiple ? onMultipleChange(choice.id) : onChange(choice.id))}
            selected={isSelected}
            hasIcon={!!Icon}
            isTopicsChoices={isTopicsChoices}
            disabled={disabled}
            count={choices.length}
          >
            <ChoiceContentWrapper tooltipTitle={choice.content}>
              {Icon && <Icon />}
              {choice.content}
              {isSelected && <SelectedTag hasIcon={!!Icon}>{selected}</SelectedTag>}
            </ChoiceContentWrapper>
          </Choice>
        );
      })}
    </ChoicesContainer>
  );
};

export default Choices;
