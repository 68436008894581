/* eslint-disable no-fallthrough */
import { useRef } from 'react';
import useSmartQuiz from '../useSmartQuiz';
import { IButtonProps } from '../../components/Button';
import { cloneDeep } from 'lodash';
import { en } from '../../../i18n';
import { useVariableKeyDownPress } from '../../hooks';
import { IFlowOverrides } from '../types';
import { EQuestionTypes } from '../../types';
interface IUseControlButtons {
  data: ReturnType<typeof useSmartQuiz> & { state: { isPlacement?: boolean } };
  flowOverrides?: Partial<IFlowOverrides>;
}

interface IButtonData {
  props: IButtonProps;
  text: string;
  hasToolTip: boolean;
}

const {
  previousQuestion,
  returnToPart,
  startCaseStudyButton,
  continueToPart,
  checkNgnAnswer,
  nextQuestion,
  viewQuizResults,
  checkAnswer,
  skipQuestion,
  skipAndViewResults,
} = en.smartQuiz;

const useControlButtons = ({ data, flowOverrides }: IUseControlButtons) => {
  const { skipExplanation = false } = flowOverrides || {};
  const { state, control } = data;
  const {
    loading,
    isCaseStudy,
    hasPreviousQuestion,
    caseStudyIndex,
    isCaseStudyOutroShown,
    isCaseStudyStarted,
    caseStudyTotalQuestions,
    isNextCaseStudyQuestionAllowed,
    isReviewingCaseStudy,
    hasNextQuestion,
    isGoingBack,
    isCheckAnswerAllowed,
    isCheckingAnswer,
    isPlacement,
    currentQuestion,
  } = state;
  const {
    goPreviousQuestion,
    goPreviousSubquestion,
    startCaseStudy,
    submitSubquestion,
    endCaseStudy,
    goNextQuestion,
    onViewResults,
    onCheckAnswer,
    showCaseStudyOutro,
  } = control;

  const stateRef = useRef(state);
  const controlRef = useRef(control);

  // To use inside on handler
  stateRef.current = state;
  controlRef.current = control;

  const buttonProps: IButtonProps = {
    variant: 'secondary',
    children: null,
  };

  const buttonData: IButtonData = {
    props: buttonProps,
    text: '',
    hasToolTip: false,
  };
  const buttonsData: IButtonData[] = [cloneDeep(buttonData), cloneDeep(buttonData), cloneDeep(buttonData)];

  const setButtonProps = (props: Partial<IButtonProps>, buttonText: string, { index = 0, hasToolTip = false } = {}) => {
    buttonsData[index].props = { ...buttonsData[index].props, ...props };
    buttonsData[index].text = buttonText;
    buttonsData[index].hasToolTip = hasToolTip;
  };

  const isFirstCaseStudyQuestion = caseStudyIndex === 0;
  const caseStudyInProgress = caseStudyIndex < caseStudyTotalQuestions - 1;
  const lastCaseStudyQuestion = caseStudyIndex === caseStudyTotalQuestions - 1;

  // TODO: Refactor the conditions urgently
  // Possible model as a state machine
  const setButtonsDataCaseStudy = () => {
    if (!isCaseStudyOutroShown) {
      if (hasPreviousQuestion && isFirstCaseStudyQuestion && !skipExplanation) {
        setButtonProps({ onClick: goPreviousQuestion }, previousQuestion, { index: 0 });
      }
      if (isCaseStudyStarted && caseStudyIndex > 0)
        setButtonProps({ onClick: goPreviousSubquestion }, `${returnToPart} ${caseStudyIndex}`, { index: 0 });
    }
    if (!isCaseStudyStarted)
      setButtonProps({ loading, variant: 'primary', onClick: () => startCaseStudy(false) }, startCaseStudyButton, {
        index: 1,
      });
    if (isCaseStudyStarted) {
      setButtonProps(
        { loading, variant: 'primary', onClick: submitSubquestion, disabled: !isNextCaseStudyQuestionAllowed },
        '',
        { index: 1 },
      );
      if (caseStudyInProgress) {
        setButtonProps({}, `${continueToPart} ${caseStudyIndex + 2}`, { index: 1 });
      }
      if (lastCaseStudyQuestion && !isReviewingCaseStudy) {
        const text = skipExplanation ? nextQuestion : checkNgnAnswer;
        setButtonProps({}, text, { index: 1 });
        if (!hasNextQuestion && skipExplanation) {
          setButtonProps(
            {
              loading: loading,
              variant: 'primary',
              onClick: () => {
                submitSubquestion();
                endCaseStudy();
                onViewResults();
              },
            },
            viewQuizResults,
            { index: 1 },
          );
        }
      }
    }

    if (lastCaseStudyQuestion && isReviewingCaseStudy) {
      if (isCaseStudyOutroShown) {
        if (hasPreviousQuestion)
          setButtonProps(
            {
              onClick: () => {
                goPreviousQuestion();
                startCaseStudy(true);
              },
              loading: false,
            },
            previousQuestion,
            { index: 1 },
          );

        if (hasNextQuestion) {
          setButtonProps(
            {
              loading: !isGoingBack && loading,
              variant: 'primary',
              onClick: () => {
                endCaseStudy();
                goNextQuestion();
              },
            },
            nextQuestion,
            { index: 2 },
          );
          return;
        }
        setButtonProps(
          {
            loading: loading,
            variant: 'primary',
            onClick: () => {
              endCaseStudy();
              onViewResults();
            },
          },
          viewQuizResults,
          { index: 2 },
        );
      } else {
        setButtonProps({ loading, onClick: showCaseStudyOutro, variant: 'primary' }, nextQuestion, { index: 1 });
      }
    }
  };

  const setButtonsCheckingAnswer = () => {
    if (hasPreviousQuestion && !skipExplanation)
      setButtonProps({ loading: isGoingBack && loading, onClick: goPreviousQuestion }, previousQuestion);

    if (hasNextQuestion) {
      setButtonProps({ loading: !isGoingBack && loading, onClick: goNextQuestion, variant: 'primary' }, nextQuestion, {
        index: 1,
      });
      return;
    }

    setButtonProps({ loading, onClick: onViewResults, variant: 'primary' }, viewQuizResults, { index: 2 });
  };

  const setButtonDefaultButtonData = () => {
    if (hasPreviousQuestion && !skipExplanation)
      setButtonProps({ loading: isGoingBack && loading, onClick: goPreviousQuestion }, previousQuestion);

    const buttonText = skipExplanation ? nextQuestion : checkAnswer;
    setButtonProps(
      { loading, onClick: onCheckAnswer, disabled: !isCheckAnswerAllowed, variant: 'primary' },
      buttonText,
      { index: 1, hasToolTip: true },
    );

    if (isPlacement) {
      if (hasNextQuestion) setButtonProps({ onClick: goNextQuestion }, skipQuestion, { index: 2, hasToolTip: false });
      else setButtonProps({ onClick: onViewResults }, skipAndViewResults, { index: 2, hasToolTip: false });
    }
  };
  // Also model as a state machine

  const handleCaseStudyOnEnter = () => {
    switch (true) {
      case !isCaseStudyStarted:
        startCaseStudy(false);
        break;
      case lastCaseStudyQuestion && isReviewingCaseStudy:
        if (isCaseStudyOutroShown) {
          if (hasNextQuestion) {
            endCaseStudy();
            goNextQuestion();
          } else {
            endCaseStudy();
            onViewResults();
          }
        } else {
          showCaseStudyOutro();
        }
        break;
      case isCaseStudyStarted && isNextCaseStudyQuestionAllowed:
        submitSubquestion();
        break;

      default:
        break;
    }
  };

  const handleCaseStudyBackSpace = () => {
    switch (true) {
      case !isCaseStudyOutroShown:
        if (hasPreviousQuestion && isFirstCaseStudyQuestion) {
          goPreviousQuestion();
          break;
        }

        if (isCaseStudyStarted && caseStudyIndex > 0) {
          goPreviousSubquestion();
        }
        break;

      case lastCaseStudyQuestion && isReviewingCaseStudy && isCaseStudyOutroShown && hasPreviousQuestion:
        goPreviousQuestion();
        startCaseStudy(true);
    }
  };
  const onEnter = () => {
    if (isCaseStudy) return handleCaseStudyOnEnter();

    if (isCheckingAnswer) {
      if (hasNextQuestion) return goNextQuestion();
      return onViewResults();
    }

    if (isCheckAnswerAllowed) onCheckAnswer();
  };

  const onBackSpace = () => {
    if (currentQuestion.questionType === EQuestionTypes.ARITHMETIC) {
      if (!isCheckingAnswer) return;
    }
    if (skipExplanation) return;
    if (isCaseStudy) {
      return handleCaseStudyBackSpace();
    }
    if (hasPreviousQuestion) goPreviousQuestion();
  };

  const setData = () => {
    switch (true) {
      case isCaseStudy:
        setButtonsDataCaseStudy();
        break;
      case isCheckingAnswer:
        setButtonsCheckingAnswer();
        break;
      default:
        setButtonDefaultButtonData();
    }

    return buttonsData;
  };
  setData();
  useVariableKeyDownPress('Enter', onEnter);
  useVariableKeyDownPress(
    'Backspace',
    onBackSpace,
    currentQuestion.questionType === EQuestionTypes.ARITHMETIC && !isCheckingAnswer,
  );

  return buttonsData;
};

export type { IUseControlButtons };
export default useControlButtons;
