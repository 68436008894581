import { ReactNode } from 'react';
import BaseContent, { IConfirmButton } from './BaseContent';
import { en } from '../../../../i18n';
import { EPaymentSteps } from '../../../types';

const { addonConfirmationButton, addonsTitle, paymentForm, addonsTopTitle, addonsMembershipInfoOneTime } = en.billing;
const { changePaymentMethod } = paymentForm;

class BuyAddon extends BaseContent {
  public landingMembershipInfoTitle(): string {
    const plan = super.getStripePlan();
    return `${plan.title} Access`;
  }
  public confirmButton(): IConfirmButton {
    const state = super.getCurrentState();
    const additionalInfo = super.getAdditionalInfo();
    switch (state) {
      case EPaymentSteps.EDIT_CARD:
        return {
          loading: !!additionalInfo.loadingChangePaymentMethod,
          form: 'payment-method-form',
          text: changePaymentMethod,
          variant: 'primary',
        };
      default:
        return {
          loading: !!additionalInfo.loadingPurchaseAddon,
          form: '',
          text: addonConfirmationButton,
          onClick: 'purchaseAddon',
          variant: 'primary',
        };
    }
  }
  public formTitle(): string {
    const plan = super.getStripePlan();
    return `${addonsTitle} ${plan.title}`;
  }
  public membershipInfoTitle(): ReactNode {
    const plan = super.getStripePlan();
    return `${plan.title} Access`;
  }
  public membershipInfoSubTitle(): string {
    return addonsMembershipInfoOneTime;
  }

  public membershipInfoTopTitle(): string {
    return addonsTopTitle;
  }
}

export default BuyAddon;
