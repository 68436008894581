// TODO: Refactor this to avoid repeating the same code

import { styled } from '@mui/material';

const shouldForwardProps = (prop: PropertyKey, allowedProps: string[]) => !allowedProps.includes(prop as string);

interface IButtonProps {
  customStyle: string;
}

const UnderLinedButtonSpan = styled('p', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['customStyle']),
})<IButtonProps>(({ customStyle }) => customStyle);

const UnderLinedButton = styled('button', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['customStyle']),
})<IButtonProps>(({ customStyle }) => customStyle);

const PrimaryButton = styled('button', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['customStyle']),
})<IButtonProps>(({ customStyle }) => customStyle);

const PrimaryButtonSpan = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['customStyle']),
})<IButtonProps>(({ customStyle }) => customStyle);

const SecondaryButton = styled('button', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['customStyle']),
})<IButtonProps>(({ customStyle }) => customStyle);

export { UnderLinedButton, PrimaryButton, UnderLinedButtonSpan, SecondaryButton, PrimaryButtonSpan };
