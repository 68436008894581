import { ECaseStudyQuestionTypes, EQuestionTypes, IAttempt, ICaseStudy, ILooseObject } from '../../../types';
import { QuestionTag, QuestionTypesContainer } from '../../styled';
import { useRef } from 'react';
import { questionTypesDisplayMapping } from './data';
import { useSelector } from 'react-redux';
import { quizSelectors } from '../../../StudySpace/redux/slice';

interface IQuestionTypes {
  attempt: IAttempt;
  topicId: string;
}

const QuestionTypes = ({ attempt, topicId }: IQuestionTypes) => {
  const quizState = useSelector(quizSelectors.allState);
  const questions = attempt?.questionsTransformed || quizState.questions || {};
  const initTopicResults = () => {
    const topicsResults: ILooseObject = {};

    const setQuestionTypeTopicResults = (questionType: ECaseStudyQuestionTypes | EQuestionTypes) => {
      const entry = topicsResults[questionType];
      if (!entry) {
        topicsResults[questionType] = {
          totalQuestions: 1,
        };
        return;
      }

      entry.totalQuestions += 1;
    };

    Object.keys(questions).forEach(key => {
      const question = questions[key];
      const { topicsIds, questionType } = question;
      if (topicsIds?.[0] !== topicId) return;
      if (questionType === EQuestionTypes.NGN_CASE_STUDY) {
        const caseStudy = (question as unknown) as ICaseStudy;
        const { questions } = caseStudy;

        questions.forEach(subQuestion => {
          let questionType = subQuestion.detail.question.detailType;
          if (questionType === ECaseStudyQuestionTypes.SINGLE_CHOICE) {
            questionType = ECaseStudyQuestionTypes.MULTIPLE_CHOICE_V2;
          }
          setQuestionTypeTopicResults(questionType);
        });
      }

      if (questionType !== EQuestionTypes.NGN_CASE_STUDY) {
        setQuestionTypeTopicResults(questionType);
        return;
      }
    });
    return topicsResults;
  };

  const topicsResultsRef = useRef(initTopicResults());

  const renderTags = () => {
    return Object.keys(topicsResultsRef.current).map(questionType => {
      const value = topicsResultsRef.current[questionType];
      const mapping = questionTypesDisplayMapping[(questionType as unknown) as EQuestionTypes];
      const countText = value.totalQuestions > 1 ? `${value.totalQuestions} ` : '';
      if (!mapping) return null;
      return (
        <QuestionTag variant="filled">
          <span>
            {countText}
            {mapping?.text}
          </span>
        </QuestionTag>
      );
    });
  };

  return <QuestionTypesContainer>{renderTags()}</QuestionTypesContainer>;
};

export default QuestionTypes;
