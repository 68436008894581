import { green, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    xsm: true;
    sm: true;
    md: true;
    xmd: true;
    lg: true;
    xl: true;
    $xxs: true;
    $xs: true;
    $sm: true;
    $md: true;
    $lg: true;
    $xl: true;
    $xxl: true;
    $xxxl: true;
    $xxxxl: true;
  }
}

type themeName = 'default' | 'redGreen';

type themeType = 'light' | 'dark';

const fieldBackgroundColor = '#FFEDE2';
const fieldColor = '#C22C2C';
const errorFieldBackgroundColor = '#FFE2E2';
const errorFieldColor = '#C22C2C';

function getPalette(name: themeName) {
  switch (name) {
    case 'redGreen':
      return {
        primary: {
          main: red[500],
        },
        secondary: {
          main: green[400],
        },
      };
    case 'default':
    default:
      return {
        primary: {
          main: '#604BDF',
          white: 'dfd',
          purple30: 'fasf',
        },
        secondary: {
          main: '#d15318',
        },
      };
  }
}

function getTheme(name: themeName = 'default') {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        xsm: 376,
        sm: 600,
        md: 900,
        xmd: 1025,
        lg: 1200,
        xl: 1536,
        $xxs: 360,
        $xs: 480,
        $sm: 576,
        $md: 768,
        $lg: 992,
        $xl: 1200,
        $xxl: 1600,
        $xxxl: 1920,
        $xxxxl: 2560,
      },
    },
    palette: {
      ...getPalette(name),

      // type,

      text: {
        disabled: getPalette(name).primary.main,
        // hint: getPalette(name).primary.main,
        primary: getPalette(name).primary.main,
        secondary: getPalette(name).primary.main,
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });
}

const colors = {
  white: '#FFF',
  black: '#181818',
  grey: '#CCC',
  lightGrey: '#E2E2E7',
  lightestGrey: '#F9F9FB',
  grey50: '#F0F0F5',
  grey500: '#737478',
  grey600: '#D6D6D6',
  grey650: '#353535',
  darkGrey: '#999',
  originalPrimary: '#044256',
  mediumLightGray: '#EFEFF4',
  primary: '#604BDF',
  secondary: '#d15318',
  purple40: '#3A2B95',
  purple30: '#604BDF',
  purple20: '#7B61FF',
  purple10: '#D1CBF6',
  purple15: '#F7F7FD',
  purple500: '#604BDF',
  purple600: '#5D5499',
  purple5: '#ECE9FB',
  purple1: '#F6F6FD',
  purple300: '#DFDAFC',
  orange40: '#CA6D38',
  orange30: '#F89C68',
  orange20: '#F7D8BA',
  orange10: '#FDF3E8',
  orange5: '#FBF5E9',
  green20: '#1A6E68',
  green10: '#4BDFD6',
  green15: '#EEF9EE',
  green5: '#D2F9F6',
  highlightRed: '#FF6161',
  lightestGreen: '#e5fbe5',
  mediumGray: '#B3B3B3',
  pink: '#f9eaea',
  black10: '#181818',
  charcoal: '#353535',
  mediumLowGrey: '#D6D6D6',
  mediumDarkGrey: '#E8E8E8',
  red: '#ff0000',
  highlightGreen: '#6DD898',
  highlightBlue: '#1145ff',
  errorRed: '#F22424',
  errorRed5: '#F9EAEA',
  grey400: '#737478',
  lightPurple: '#ECC9E4',
  darkPurple: '#81336F',
  lighterPurple: '#F7F7FD',
  blue10: '#E6E6F3',
  blue400: '#405EFA',
  yellow: '#FFFFA8',
  highlightBlue2: '#576EE6',
  blue50: '#D2EBF9',
  smartNApostrophe: '#FF6061',
  oAuthGrey: '#6D6D6D',
  red1: '#62370D',
  red2: '#FBF3F3',
  red3: '#FF7C7C',
  blue1: '#1F526F',
  blue2: '#E6F4FC',
  yellow1: '#554621',
  yellow2: '#FEFAD4',
  green1: '#384C3D',
  green2: '#E6F8EA',
  red300: '#D02828',
  purpleWhite: '#FAF9FF',
  red100: '#FF6061',
  darkBlue: '#2F2B38',
  darkPurple10: '#39304E',
  darkPurple20: '#393346',
  darkPurple30: '#433D53',
  darkPurple40: '#42375A',
  darkPurple50: '#544E79',
  darkPurple60: '#534E6E',
  primaryHover: '#5D4CC4 ',
  lightblack: '#4A4A4E',
};

const padding = {
  tiny: '2px',
  small: '8px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
};

export { getTheme, fieldBackgroundColor, fieldColor, errorFieldBackgroundColor, errorFieldColor, colors, padding };
export type { themeName, themeType };
