// Can Handle localization when needed

// (int) The current year
const THIS_YEAR = +new Date().getFullYear();
// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
const THIS_MONTH = +new Date().getMonth() + 1;

const THIS_DAY = +new Date().getDate();

const THIS_DATE = new Date();
// Week days names and shortnames
const WEEK_DAYS = {
  Sunday: 'SUN',
  Monday: 'MON',
  Tuesday: 'TUE',
  Wednesday: 'WED',
  Thursday: 'THU',
  Friday: 'FRI',
  Saturday: 'SAT',
};
// Calendar months names and short names
const CALENDAR_MONTHS = {
  '1': 'January',
  '2': 'Febraury',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};
// Weeks displayed on calendar
const CALENDAR_WEEKS = 6;
// Pads a string value with leading zeroes(0) until length is reached
// For example: zeroPad(5, 2) => "05"
const zeroPad = (value: number, length: number) => {
  return `${value}`.padStart(length, '0');
};
// (int) Number days in a month for a given year from 28 - 31
const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;
  return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};
// (int) First day of the month for a given year from 1 - 7
// 1 => Sunday, 7 => Saturday
const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() + 1;
};

const getDayRows = (firstMonthDay: number, previousMonthDays: number, monthDays: number) => {
  const days = new Array(40).fill(0);
  days[firstMonthDay - 1] = 1;
  let previousMonthDaysCounter = 0;
  for (let i = firstMonthDay - 2; i >= 0; i--) {
    days[i] = previousMonthDays - previousMonthDaysCounter;
    previousMonthDaysCounter += 1;
  }
  let dayCounter = 2;
  for (let j = firstMonthDay; j < monthDays + firstMonthDay - 1; j += 1) {
    days[j] = dayCounter;
    dayCounter += 1;
  }

  let lastIndex = days.findIndex(element => element === 0);
  let nextMonthPaddingCounter = 1;
  while (lastIndex % 7 !== 0) {
    days[lastIndex] = nextMonthPaddingCounter;
    nextMonthPaddingCounter += 1;
    lastIndex += 1;
  }
  return days.slice(0, lastIndex);
};

export {
  THIS_YEAR,
  THIS_MONTH,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  CALENDAR_WEEKS,
  zeroPad,
  getMonthDays,
  getMonthFirstDay,
  getDayRows,
  THIS_DAY,
  THIS_DATE,
};
