import useMembershipPlan from '../hooks/useMembershipPlan';
import {
  Container,
  PopupContentContainer,
  PopupHeaderContainer,
  TitleContainer,
  LandingContainerSubscribed,
  ContentHeader,
  EStatusTag,
  StatusTag,
  MembershipSubTitle,
  PlanPrice,
  AddonsSection,
  AddonsSubTitle,
  AddonCard,
  TitleAddonCard,
  PricingAddonCard,
  AddonTag,
  AddonActivatedTag,
  CancelActionsContainer,
  RenewalInfo,
} from './styled';
import { en } from '../../../i18n';
import Button from '../../components/Button';
import { AddonBenefit, PlanBenefits } from '../Landing/styled';
import parse from 'html-react-parser';
import { ReactComponent as StarIcon } from '../../../images/icons/membership/star.svg';
import PaymentIssueMessage from './PaymentIssueMessage';
import { defaultPlan as plan, addons as addonsMap, IAddon, defaultPlan, defaultSponsoredPlan } from '../plans';

const { billing } = en;
const { landingSubscribed, membershipEndsOn, deprecatedSubscriptionStatusTag } = billing;
const addons = Object.values(addonsMap);

const {
  title,
  subTitle,
  status,
  addonsTitle,
  addonsSubTitle,
  addon,
  oneTimeFee,
  button,
  popupTitle,
  popupButton,
  buttonActivated,
  resubscribe,
  deprecatedAddonButton,
} = landingSubscribed;
import { ReactComponent as CheckMarkIcon } from '../../../images/check.svg';
import Message from '../../components/Message';
import { format } from 'date-fns';
import DeprecatedSubscriptionMessage from '../../components/DeprecatedSubscriptionMessage';
import ContentManager from '../PaymentForm/classes/ContentManager';
import { EPaymentSteps, EPlanTypes } from '../../types';

const LandingSubscribed = () => {
  const { state, control } = useMembershipPlan();
  const {
    isActive,
    isCancelled,
    currentSubscription,
    isPaymentIssue,
    loadingFetchAddons,
    addons: subscribedAddons,
    isDeprecatedSubscription,
    isTrialling,
    stripePlan,
    deprecatedSubscription,
    isSponsored,
  } = state;
  const { showPaymentFormPopup, handleCancelSubscription } = control;

  const getContentManagerPlan = () => {
    //if (stripePlan.type === EPlanTypes.ADDON) return stripePlan;

    if (isSponsored) return defaultSponsoredPlan;
    if (stripePlan.type === EPlanTypes.ADDON) return defaultPlan;
    return stripePlan;
  };
  //const contentManagerPlan = stripePlan.type === EPlanTypes.ADDON ? defaultPlan : stripePlan;

  const contentManagerPlan = getContentManagerPlan();
  const contentManager = new ContentManager(contentManagerPlan, EPaymentSteps.VIEW_SUBSCRIPTION, {
    currentSubscription,
    isActive,
    isTrialling,
    deprecatedSubscription,
    isCancelled,
    isPaymentIssue,
    isSponsored,
  });

  const getStatus = () => {
    switch (true) {
      case isPaymentIssue:
        return { status: EStatusTag.PAYMENT_ISSUE, text: status.paymentIssue };
      case isDeprecatedSubscription && !isCancelled:
        return { status: EStatusTag.DEPRECATED, text: parse(deprecatedSubscriptionStatusTag) };

      case isCancelled:
        return { status: EStatusTag.CANCELLED, text: status.cancelled };

      case isActive:
        return { status: EStatusTag.ACTIVE, text: status.active };
      default:
        return { status: EStatusTag.ACTIVE, text: status.active };
    }
  };

  const renderAddonBenefits = (data: string[]) => {
    return data.map(benefit => (
      <AddonBenefit key={benefit}>
        <div>
          <CheckMarkIcon />
        </div>
        <div>{parse(benefit)}</div>
      </AddonBenefit>
    ));
  };

  const renderAddons = () => {
    return addons.map((addonCard: IAddon, index) => {
      const isActivated = subscribedAddons[addonCard.key];

      const renderAddonButton = () => {
        if (isCancelled) {
          if (isActivated) return <StatusTag status={getStatus().status}>{getStatus().text}</StatusTag>;
          return null;
        }

        if (isActivated) return <AddonActivatedTag>{buttonActivated}</AddonActivatedTag>;
        if (isDeprecatedSubscription) return <AddonActivatedTag>{deprecatedAddonButton}</AddonActivatedTag>;
        return (
          <Button
            onClick={() => showPaymentFormPopup(addonCard)}
            disabled={isCancelled || isDeprecatedSubscription}
            size="small"
            variant="secondary"
            loading={loadingFetchAddons}
          >
            {button}&nbsp;${addonCard.price} USD
          </Button>
        );
      };

      return (
        <AddonCard key={index}>
          <AddonTag>
            <StarIcon />
            <span>{addon}</span>
          </AddonTag>
          <TitleAddonCard>
            <h1>{addonCard.title}</h1>
            <h2>{addonCard.subTitle}</h2>
          </TitleAddonCard>
          <PricingAddonCard>
            <span>
              ${addonCard.price} USD {addonCard.isOneTime ? oneTimeFee : addonCard.billingCycle}
            </span>
          </PricingAddonCard>
          <PlanBenefits>{renderAddonBenefits(addonCard.benefits)}</PlanBenefits>
          {renderAddonButton()}
        </AddonCard>
      );
    });
  };

  const renderMembershipActions = () => {
    if (!currentSubscription || !isCancelled) {
      return (
        <Button onClick={() => showPaymentFormPopup(plan)} variant="secondary">
          {popupButton}
        </Button>
      );
    }
    const { formattedEndDate = '', currentPeriodEnd } = currentSubscription;
    let endDate = formattedEndDate;
    if (!formattedEndDate && currentPeriodEnd) {
      endDate = format(new Date(currentPeriodEnd), 'MMMM dd, yyyy');
    }
    if (isCancelled)
      return (
        <CancelActionsContainer>
          <div>
            {membershipEndsOn} <span>{endDate}</span>
          </div>
          {!isDeprecatedSubscription && (
            <Button size="small" fullWidth variant="primary" onClick={handleCancelSubscription}>
              {resubscribe}
            </Button>
          )}
        </CancelActionsContainer>
      );
    return null;
  };

  const renderMessages = () => {
    const messages: JSX.Element[] = [];
    if (isPaymentIssue) {
      messages.push(
        <Message key="payment-issue" variant="errorRed300">
          <PaymentIssueMessage />
        </Message>,
      );
    }
    if (isDeprecatedSubscription) {
      messages.push(
        <Message key="deprecated-subscription" variant="warning">
          <DeprecatedSubscriptionMessage />
        </Message>,
      );
    }

    return messages;
  };

  return (
    <Container>
      {renderMessages()}
      <TitleContainer>
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
      </TitleContainer>
      <LandingContainerSubscribed>
        <PopupHeaderContainer>
          <h1>{popupTitle}</h1>
          {renderMembershipActions()}
        </PopupHeaderContainer>
        <PopupContentContainer>
          <ContentHeader>
            {contentManager.content.membershipInfoTitle()}
            {/* <span>
              <SmartNText>{planRightTitle}</SmartNText>
            </span> */}
            <StatusTag status={getStatus().status}>{getStatus().text}</StatusTag>
          </ContentHeader>
          <MembershipSubTitle>{contentManager.content.membershipMeta()}</MembershipSubTitle>
          <PlanPrice>{contentManager.content.membershipInfoPrice()}</PlanPrice>
          {/* <PlanBenefits>{renderBenefits(plan.benefits)}</PlanBenefits> */}
          <AddonsSection>
            <ContentHeader>
              <h1>{addonsTitle}</h1>
            </ContentHeader>
            <AddonsSubTitle>{addonsSubTitle}</AddonsSubTitle>

            {renderAddons()}
          </AddonsSection>
        </PopupContentContainer>
      </LandingContainerSubscribed>
    </Container>
  );
};

export default LandingSubscribed;
