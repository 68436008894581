import PlainText from './PlainText';
import ImageText from './ImageText';
import { EContentTypes, IContent, IImageText } from '../../../types';
import { QuestionContainer } from '../styled';
import HtmlTemplate from './HtmlTemplate';

const templateToTypeMapping = {
  [EContentTypes.PLAIN_TEXT]: PlainText,
  [EContentTypes.IMAGE_TEXT]: ImageText,
  [EContentTypes.HTML]: HtmlTemplate,
  '': null,
};

interface IProps {
  content: IContent;
  width?: string;
}

const TemplateRenderer = ({ content, width }: IProps) => {
  const { type } = content;
  const Component = templateToTypeMapping[type];
  if (!Component) return null;
  return (
    <QuestionContainer>
      <Component width={width} content={content.content as IImageText} />{' '}
    </QuestionContainer>
  );
};

export default TemplateRenderer;
