import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entities } from '../constants';
import { IGlobalState, IErrorAction, ILooseObject } from '../types';
import { RootState } from './store';

const buildReducers: { [key: string]: (state: IGlobalState, action: any) => void } = Object.values(entities).reduce(
  (acc, entity) => {
    const { name } = entity;
    const actions = {
      [`${name}IndexRequest`]: (state: IGlobalState) => {
        state[name] = { loading: true, errors: {} };
      },
      [`${name}IndexSuccess`]: (state: IGlobalState) => {
        state[name] = { loading: false, errors: {} };
      },
      [`${name}IndexFailure`]: (state: IGlobalState, action: IErrorAction) => {
        state[name] = { loading: false, errors: action.payload };
      },
      [`${name}SetGlobalState`]: (
        state: IGlobalState,
        action: PayloadAction<{ loading: boolean; errors: ILooseObject }>,
      ) => {
        state[name] = action.payload;
      },
    };
    const reducers = { ...acc, ...actions };
    return reducers;
  },
  {
    toggleSideBar: (state: IGlobalState) => {
      state.mobileSideBar.isOpen = !state.mobileSideBar.isOpen;
    },
  },
);

const initialState: IGlobalState = Object.values(entities).reduce(
  (acc: IGlobalState, entity) => {
    acc[entity.name] = { loading: false, errors: {} };

    return acc;
  },
  {
    mobileSideBar: {
      isOpen: false,
      loading: false,
      errors: {},
    },
  },
);

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: buildReducers,
});

const globalSelector = (name: string) => (state: RootState) => state.global[name];
const mobileHeaderSelector = (state: RootState) => state.global.mobileSideBar.isOpen;

const actions = { ...slice.actions };

export { actions, globalSelector, mobileHeaderSelector };
export default slice.reducer;
