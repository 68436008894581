import {
  Footer,
  Title,
  ChooseAdventureContainer,
  AdventuresContainer,
  Adventure,
  SelectedTag,
  Note,
  ChooseAdventureTitleContainer,
} from './styled';
import { useState } from 'react';
import Button from '../components/Button';
import CustomTooltip from '../components/Tooltip';
import { en } from '../../i18n';
import { ReactComponent as PersonalizedQuizIcon } from '../../images/personalizedQuiz.svg';
import { ReactComponent as NgnQuizIcon } from '../../images/ngn.svg';
import { ReactComponent as BaselineIcon } from '../../images/baseline.svg';
import { ReactComponent as ReadinessIcon } from '../../images/icons/placement/readiness.svg';
import parse from 'html-react-parser';
import { useHistory } from 'react-router';
import { placementTestPath, nurseGPTPath } from '../Router/paths';
import { EQuizTypes } from '../types';
import { useStartQuiz } from '../hooks';
import { ngnOnboardingTopic } from './constants';

const {
  whereToStart,
  selected,
  personQuizSubtitle,
  personQuizTitle,
  ngnSubtitle,
  ngnTitle,
  baselineSubtitle,
  baselineTitle,
  selectAdventure,
  startTrial,
  upgrade,
  fullAccess,
  readinessTitle,
  readinessSubTitle,
  nurseGPTTitle,
  nurseGPTSubtitle,
} = en.onBoarding;

const ChooseAdventure = ({ topics }: { topics: string[] }) => {
  const history = useHistory();
  const { startQuiz } = useStartQuiz();
  const [adventure, setAdventure] = useState<number>();

  const adventures = [
    {
      icon: <PersonalizedQuizIcon />,
      title: personQuizTitle,
      subtitle: personQuizSubtitle,
      onClick: () => {
        startQuiz(topics, [EQuizTypes.MULTIPLE_CHOICE]);
      },
    },
    {
      icon: <NgnQuizIcon />,
      title: ngnTitle,
      subtitle: ngnSubtitle,
      onClick: () => {
        startQuiz([ngnOnboardingTopic], [EQuizTypes.NGN_CASE_STUDY]);
      },
    },
    {
      icon: <BaselineIcon />,
      title: baselineTitle,
      subtitle: baselineSubtitle,
      onClick: () => {
        history.push(placementTestPath);
      },
    },

    {
      icon: <ReadinessIcon />,
      title: nurseGPTTitle,
      subtitle: nurseGPTSubtitle,
      onClick: () => {
        history.push(nurseGPTPath);
      },
    },
  ];

  const renderButton = () => {
    const button = (
      <Button variant="primary" onClick={adventures[(adventure || 1) - 1].onClick} disabled={!adventure}>
        {startTrial}
      </Button>
    );
    return adventure ? (
      button
    ) : (
      <CustomTooltip leftPopper="-7px" centerText width="100%" arrow={false} title={selectAdventure} placement="top">
        <span>{button}</span>
      </CustomTooltip>
    );
  };

  return (
    <>
      <ChooseAdventureContainer>
        <ChooseAdventureTitleContainer>
          <Title>{whereToStart}</Title>
        </ChooseAdventureTitleContainer>
        <AdventuresContainer>
          {adventures.map((adv, index) => (
            <Adventure key={index} selected={index + 1 === adventure} onClick={() => setAdventure(index + 1)}>
              {/* {adv.icon} */}
              <span>{adv.title}</span>
              <span>{adv.subtitle}</span>
              {index + 1 === adventure && <SelectedTag>{selected}</SelectedTag>}
            </Adventure>
          ))}
        </AdventuresContainer>
        <Note>
          <div>{fullAccess}</div>
          {/* <div>{upgrade}</div> */}
        </Note>
      </ChooseAdventureContainer>
      <Footer>{renderButton()}</Footer>
    </>
  );
};

export default ChooseAdventure;
