import { useState, useEffect, MutableRefObject } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { quizSelectors, actions, SLICE_NAME } from '../StudySpace/redux/slice';
import { topicsSelectors } from '../StudySpace/redux/topics/slice';
import { actions as globalActions } from '../redux/globalSlice';
import { useDispatch, useNavigations, useContinueQuiz } from '../hooks';

import { getCurrentProgress } from '../utils';
import { EGoogleTags, EQuestionTypes, EQuizTypes, ICaseStudy } from '../types';
import { GoogleTags } from '../classes';
import useCaseStudy from './useCaseStudy';
import { IControlOverrides, IFlowOverrides, IStateOverrides } from './types';

interface IUseSmartQuiz {
  ref: MutableRefObject<HTMLDivElement>;
  flowOverrides?: Partial<IFlowOverrides>;
  stateOverrides?: Partial<IStateOverrides>;
  controlOverrides?: Partial<IControlOverrides>;
}

const useSmartQuiz = ({ ref, flowOverrides, stateOverrides, controlOverrides }: IUseSmartQuiz) => {
  const {
    isStarted,
    currentQuestionId,
    questions,
    currentAttempt,
    currentQuestionIndex,
    questionAnswers,
    loadingAttempts,
    isViewingResults,
    loadingStartQuiz,
    currentDisplayedQuestionIndex,
  } = useSelector(quizSelectors.allState);
  const { skipExplanation = false } = flowOverrides || {};
  const { loading } = useSelector(quizSelectors.globalSelector);
  const { selectedTopics, entities: allTopics } = useSelector(topicsSelectors.allState);
  const { loading: loadingTopics } = useSelector(topicsSelectors.globalSelector);
  const { dispatchWithLoading, dispatch } = useDispatch(SLICE_NAME);
  const { navigateToResults } = useNavigations();
  const noSelectedTopics = !selectedTopics || Object.keys(selectedTopics).length === 0;
  const history = useHistory();
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const totalNumberOfQuestions = currentAttempt?.questions.length || 1;
  const hasNextQuestion = totalNumberOfQuestions > currentQuestionIndex + 1;
  const hasPreviousQuestion = currentQuestionIndex > 0;
  const quizProgress = getCurrentProgress(questions, questionAnswers, currentAttempt).percentage;
  const currentQuestion = questions[currentQuestionId || ''];
  const { id: questionID } = currentQuestion || {};

  const canCheckAnswer = () => {
    const isAnswerPresent = !!questionAnswers[questionID];
    if (!isAnswerPresent) return false;
    if (currentQuestion?.questionType === EQuestionTypes.ARITHMETIC) {
      if (isAnswerPresent) return !!questionAnswers[questionID]?.value;
    }
    return true;
  };

  const isCheckAnswerAllowed = canCheckAnswer();
  const isCheckingAnswer = !skipExplanation && !!questionAnswers[questionID]?.isDone;
  const quizStarted = isStarted && (!stateOverrides || stateOverrides?.isStarted);

  const goNextQuestion = () => {
    if (!hasNextQuestion) return;
    setIsGoingBack(false);
    return navigateQuestion('next');
  };

  const { state: caseStudyState, control: caseStudyControl } = useCaseStudy({
    currentQuestion,
    currentQuestionIndex,
    flowOverrides,
    goNextQuestion,
    quizStarted,
  });

  useContinueQuiz();

  useEffect(() => {
    return () => {
      dispatch(actions.setViewResults({ isViewingResults: false }));
    };
  }, []);

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(isOpen => !isOpen);
  };

  const toggleExitModal = () => {
    setIsExitModalOpen(isOpen => !isOpen);
  };

  const answerQuestion = (answer: string[] | string) => {
    const { id } = currentQuestion;

    const answerPayload = {
      index: currentQuestionIndex,
      value: answer,
      ctime: new Date().getTime(),
    };
    dispatch(actions.setQuestionAnswer({ questionId: id, response: answerPayload }));
  };

  const scrollToTop = () => {
    if (ref.current?.offsetTop || ref.current?.offsetTop === 0) {
      window.scrollTo({ top: ref.current.offsetTop });
    }
  };

  const navigateQuestion = (direction: 'next' | 'previous') => {
    const getTargetIndices = () => {
      if (direction === 'next')
        return {
          targetIndex: currentQuestionIndex + 1,
          displayedIndex: currentDisplayedQuestionIndex + 1,
        };

      return {
        targetIndex: currentQuestionIndex - 1,
        displayedIndex: currentDisplayedQuestionIndex - 1,
      };
    };

    const { targetIndex, displayedIndex } = getTargetIndices();

    const { questions: attemptQuestions = [] } = currentAttempt || {};
    const targetQuestionId = attemptQuestions[targetIndex].questionId;
    const targetQuestion = questions[targetQuestionId];
    scrollToTop();
    if (targetQuestion) {
      dispatch(
        actions.setCurrentQuestion({
          questionId: targetQuestionId,
          currentQuestionIndex: targetIndex,
          currentDisplayeQuestionIndex: displayedIndex,
        }),
      );
      if (direction === 'previous') {
        if (targetQuestion.questionType === EQuestionTypes.NGN_CASE_STUDY) {
          const totalQuestions = ((targetQuestion as unknown) as ICaseStudy).questions?.length || 0;
          const nextIndex = displayedIndex + 1 - totalQuestions;
          dispatch(actions.setCurrentDisplayedIndex({ index: nextIndex }));
        }
      }

      return;
    }
    return dispatchWithLoading(actions.fetchQuestion({ questionId: targetQuestionId }));
  };

  const goPreviousQuestion = () => {
    if (!(currentQuestionIndex > 0)) return;
    setIsGoingBack(true);
    return navigateQuestion('previous');
  };

  const startQuiz = (groupIds: string[], questionTypes: EQuizTypes[]) => {
    dispatch(globalActions.quizSetGlobalState({ loading: true, errors: {} }));
    dispatch(actions.createQuiz({ groupIds, questionTypes }));
  };

  const onCheckAnswer = () => {
    dispatchWithLoading(
      actions.submitAnswer({
        attemptId: currentAttempt?.attemptId || '',
        questionId: currentQuestion.id,
        response: questionAnswers[currentQuestion.id]?.value,
      }),
    );
  };

  useEffect(() => {
    if (!skipExplanation) return;
    if (questionAnswers?.[questionID]?.isDone) {
      if (hasNextQuestion) {
        goNextQuestion();
        return;
      }

      if (quizStarted && currentQuestionIndex === totalNumberOfQuestions - 1) onViewResults();
    }
  }, [hasNextQuestion, !!questionAnswers?.[questionID]?.isDone]);

  const resetState = () => {
    dispatch(actions.quizAgain());
  };

  const onViewResults = () => {
    const sendEvent = () => {
      const getEvent = () => {
        switch (true) {
          case history.location?.pathname?.includes('placement'):
            return EGoogleTags.KA;
          case history.location?.pathname?.includes('readiness'):
            return EGoogleTags.READINESS_ASSESMENT_COMPLETE;
          default:
            return EGoogleTags.QUIZ_COMPLETE;
        }
      };
      const event = getEvent();
      const googleTags = new GoogleTags(event);
      googleTags.fireEvent();
    };

    sendEvent();
    scrollToTop();
    if (controlOverrides?.onViewResults) return controlOverrides.onViewResults();
    dispatch(actions.viewResults());
    dispatch(actions.fetchAttempt({ attemptId: currentAttempt?.attemptId || '' }));
  };

  const redirectToViewResults = () => {
    if (currentAttempt) return navigateToResults(currentAttempt);
    return null;
  };

  return {
    state: {
      isStarted,
      currentQuestionIndex,
      currentQuestion,
      isCheckingAnswer,
      hasNextQuestion,
      quizProgress,
      hasPreviousQuestion,
      loadingAttempts,
      loadingTopics,
      questionAnswers,
      isCheckAnswerAllowed,
      isViewingResults,
      isGoingBack,
      loading,
      totalNumberOfQuestions,
      noSelectedTopics,
      allTopics,
      isFiltersModalOpen,
      isExitModalOpen,
      loadingStartQuiz,
      ctime: currentAttempt?.ctime,
      currentAttempt,
      currentDisplayedQuestionIndex,
      ...caseStudyState,
    },
    control: {
      goNextQuestion,
      goPreviousQuestion,
      startQuiz,
      onCheckAnswer,
      answerQuestion,
      onViewResults,
      resetState,
      redirectToViewResults,
      toggleFiltersModal,
      toggleExitModal,
      ...caseStudyControl,
    },
  };
};

export default useSmartQuiz;
