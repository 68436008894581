import { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner';
import { en } from '../../../i18n';
import { useSelector } from 'react-redux';
import { subscriptionsSelectors, actions } from '../../Membership/redux/slice';
import { useDispatch, useNavigations } from '../../hooks';
import ConfettiCelebration from '../../components/ConfettiCelebration';
import DashboardModal from '../../components/DashboardModal';
import Button from '../../components/Button';

const { dashboard, studySpace, billing } = en;
const { title, content } = dashboard.paymentIssueModal;
const { cancel } = studySpace;
const { update } = billing;

const PaymentIssue = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isPaymentIssueModal } = useSelector(subscriptionsSelectors.allState);
  const { navigateToMembership } = useNavigations();
  const { dispatch } = useDispatch();

  useEffect(() => {
    if (isPaymentIssueModal && !isModalOpen) {
      setIsModalOpen(true);
      dispatch(actions.setPaymentIssueModal(false));
    }
  }, [isPaymentIssueModal]);

  const closeButton = (closeModal: () => void) => {
    return (
      <Button onClick={closeModal} variant="secondary">
        {cancel}
      </Button>
    );
  };

  const updatePaymentMehod = (
    <Button onClick={navigateToMembership} variant="primary">
      {update}
    </Button>
  );

  return (
    <DashboardModal
      closeButton={closeButton}
      confirmButton={updatePaymentMehod}
      title={title}
      content={content}
      isOpen={isModalOpen}
    >
      <Spinner loading={false} />
    </DashboardModal>
  );
};

export default PaymentIssue;
