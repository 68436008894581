import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

interface IOAuthContainerProps {
  isSignIn?: boolean;
}

const OAuthContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open', 'isSignIn']),
})<IOAuthContainerProps>(
  ({ isSignIn, theme }) => `
  width: 100%;

  div {
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  }
  ${theme.breakpoints.down('sm')} {
   
    div {
      margin-bottom: 12px;
      margin-top: 12px;
    }
    ${
      !isSignIn
        ? `
      button {
        box-shadow: none;
      }
    `
        : ''
    }
  }
`,
);

const GoogleButton = styled('button')`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.oAuthGrey};
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 15px 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  box-shadow: 0px 2px 4px #f7f8fe;
  cursor: pointer;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 8px 0;
  }
`;

export { OAuthContainer, GoogleButton };
