import BaseMembership from './BaseMembership';
import FreeUserBanner from '../../Home/FreeUserBanner';

class DashboardBanner extends BaseMembership {
  public free() {
    return <FreeUserBanner />;
  }
  public essential() {
    return null;
  }
  public allAccess() {
    return this.essential();
  }
}

export default DashboardBanner;
