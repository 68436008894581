import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { RootState } from '../../redux/store';
import {
  ISubscriptionsState,
  ISubscription,
  IStateCustomerAddress,
  SubscriptionModalVariantType,
  IPaymentFormState,
  EPaymentSteps,
  IMembershipErrors,
  TAddons,
} from '../../types';
import { en } from '../../../i18n';
import { defaultPlan, IAddon, IPlan } from '../plans';

const SLICE_NAME = 'subscriptions';

const emptyErrors = {
  global: '',
  address: '',
  card: '',
  promoCode: '',
};

const initialState: ISubscriptionsState = {
  loading: false,
  loadingDebounce: false,
  loadingCreateSubscription: false,
  loadingChangePaymentMethod: false,
  loadingFetchSubscriptions: false,
  loadingFetchAddons: false,
  errors: {
    global: '',
    address: '',
    card: '',
    promoCode: '',
  },
  modalVariant: 'upgraded',
  showPaymentForm: false,
  showModal: false,
  paymentStep: EPaymentSteps.INPUT_FIELDS,
  subscriptionsFound: undefined,
  isAddressCreated: false,
  isTrialEndedModalShown: true,
  isPriceIdChanged: false,
  stripePlan: defaultPlan,
  isTrialEnded: false,
  loadingCreateTrial: false,
  isPaymentIssueModal: false,
  isDeprecatedSubscriptionModal: false,
  isPaymentMethodChanged: false,
  loadingPurchaseAddon: false,
  isSponsored: false,
  addons: {
    //smartCLEX: true,
  },
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaymentFormState: state => {
      return {
        ...state,
        ...initialState,
        ...{ addons: state.addons, subscriptionsFound: state.subscriptionsFound, isSponsored: state.isSponsored },
      };
    },
    createSubscriptionRequest: state => {
      //state.loading = true;
      //state.errors = emptyErrors;
      state.errors.global = '';
      state.errors.address = '';
      state.loadingCreateSubscription = true;
    },
    createSubscriptionSuccess: (state, action: PayloadAction<{ subscription: ISubscription }>) => {
      state.currentSubscription = action.payload.subscription;
      state.paymentStep = EPaymentSteps.TAX_CALCULATED;
      state.loading = false;
      state.loadingCreateSubscription = false;
      state.modalVariant = 'upgraded';
      state.errors.global = '';
    },
    createSubscriptionFailure: (state, action: PayloadAction<{ errors: Partial<typeof emptyErrors> }>) => {
      state.loading = false;
      state.loadingCreateSubscription = false;
      //state.errors.global = en.somethingWentWrong;

      state.errors = { ...state.errors, ...action.payload.errors };
    },
    createAddressRequest: (state, action: PayloadAction<{ address: IStateCustomerAddress }>) => {
      state.errors.address = '';
      state.loadingCreateSubscription = true;
      state.isAddressCreated = false;
      state.isPriceIdChanged = false;
    },

    createAddressSuccess: state => {
      state.isAddressCreated = true;
      state.errors.global = '';
    },
    createAddressFailure: state => {
      state.errors.address = en.billing.invalidAddressError;
      state.errors.global = '';
      state.loadingCreateSubscription = false;
    },

    fetchPromoCodeRequest: (state, action: PayloadAction<{ code: string; productId: string }>) => {
      state.promotionCodeID = '';
      state.loadingCreateSubscription = true;
      state.isPriceIdChanged = false;
    },

    fetchPromoCodeSuccess: (state, action: PayloadAction<{ id: string }>) => {
      state.promotionCodeID = action.payload.id;
    },
    fetchPromoCodeFailure: state => {
      state.errors.promoCode = en.billing.invalidPromoCodeError;
      // state.loadingCreateSubscription = false;
    },

    fetchSubscriptionRequest: state => {
      state.loading = true;
      state.errors = emptyErrors;
      state.loadingFetchSubscriptions = true;
    },
    fetchSubscriptionRequestPolling: state => {
      state.loading = true;
      state.errors = emptyErrors;
      state.loadingFetchSubscriptions = false;
    },
    fetchSubscriptionSuccess: (
      state,
      action: PayloadAction<{
        currentSubscription: ISubscription;
        triallingSubscription?: ISubscription;
        subscriptionsFound: boolean;
        isSponsoredSubscription: boolean;
      }>,
    ) => {
      const {
        currentSubscription,
        triallingSubscription,
        subscriptionsFound,
        isSponsoredSubscription,
      } = action.payload;
      state.currentSubscription = currentSubscription;
      state.loading = false;
      state.errors = emptyErrors;
      state.triallingSubscription = triallingSubscription;
      state.subscriptionsFound = subscriptionsFound;
      state.loadingFetchSubscriptions = false;
      state.isSponsored = isSponsoredSubscription;
    },
    fetchSubscriptionFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loading = false;
      state.errors = errors;
    },
    cancelSubscriptionRequest: (state, action: PayloadAction<{ subId: string; cancelAtPeriodEnd?: boolean }>) => {
      state.loading = true;
      state.errors = emptyErrors;
    },
    cancelSubscriptionSuccess: (state, action: PayloadAction<{ currentSubscription: ISubscription }>) => {
      const { currentSubscription } = action.payload;
      state.currentSubscription = currentSubscription;
      state.loading = false;
      state.errors = emptyErrors;
      state.showModal = true;
      state.modalVariant = 'cancelled';
    },
    cancelSubscriptionFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loading = false;
      state.errors = errors;
    },
    resubscribeSuccess: (state, action: PayloadAction<{ currentSubscription: ISubscription }>) => {
      const { currentSubscription } = action.payload;
      state.currentSubscription = currentSubscription;
      state.loading = false;
      state.errors = emptyErrors;
      state.showModal = true;
      state.modalVariant = 'resubscribed';
    },

    confirmPaymentRequest: (
      state,
      action: PayloadAction<{
        stripe: Stripe | null;
        elements: StripeElements | null;
        clientSecret: string;
        formState: IPaymentFormState;
      }>,
    ) => {
      state.loading = true;
      state.errors = emptyErrors;
    },
    confirmPaymentFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loading = false;
      state.errors = errors;
    },
    confirmPaymentSuccess: state => {
      state.showPaymentForm = false;
      state.showModal = true;
      state.modalVariant = 'upgraded';
      state.loading = false;
      state.stripePlan = defaultPlan;
    },

    setShowPaymentForm: (state, action: PayloadAction<boolean>) => {
      state.showPaymentForm = action.payload;
      state.errors = emptyErrors;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setPaymentFormLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setModalVariant: (state, action: PayloadAction<SubscriptionModalVariantType>) => {
      state.modalVariant = action.payload;
    },

    resetPaymentFormErrors: state => {
      state.errors = emptyErrors;
    },

    setPaymentStep: (state, action: PayloadAction<EPaymentSteps>) => {
      state.paymentStep = action.payload;
    },
    hideChangePaymentMethod: state => {
      state.isPaymentMethodChanged = false;
    },

    resetState: () => initialState,
    resetPromoCodeError: state => {
      state.errors.promoCode = '';
    },
    setLoadingDebounce: (state, action: PayloadAction<boolean>) => {
      state.loadingDebounce = action.payload;
    },
    resetAddressError: state => {
      state.errors.address = '';
    },

    refreshSubscriptions: state => {
      state.loading = true;
      state.errors = emptyErrors;
      state.currentSubscription = {
        address: { city: '', country: '', line1: '', postalCode: '' },
        isDeprecated: false,
      };
    },

    setPaymentIssueModal: (state, action: PayloadAction<boolean>) => {
      state.isPaymentIssueModal = action.payload;
    },

    setDeprecatedSubscriptionModal: (state, action: PayloadAction<boolean>) => {
      state.isDeprecatedSubscriptionModal = action.payload;
    },

    setStripePlan: (state, action: PayloadAction<{ stripePlan: IPlan | IAddon }>) => {
      state.stripePlan = action.payload.stripePlan;
      state.isPriceIdChanged = true;
    },

    setIsAdressCreated: (state, action: PayloadAction<{ isAddressCreated: boolean }>) => {
      state.isAddressCreated = action.payload.isAddressCreated;
    },

    setLoadingCreateSubscription: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.loadingCreateSubscription = action.payload.isLoading;
    },

    resetPromoCode: state => {
      state.promotionCodeID = '';
      state.errors.promoCode = '';
    },

    setisTrialEndedModalShown: (state, action: PayloadAction<boolean>) => {
      state.isTrialEndedModalShown = action.payload;
    },
    setPriceIdIsChanged: (state, action: PayloadAction<boolean>) => {
      state.isPriceIdChanged = false;
    },

    changePaymentMethodRequest: (
      state,
      action: PayloadAction<{
        stripe: Stripe | null;
        elements: StripeElements | null;
        formState: IPaymentFormState;
        subId: string;
      }>,
    ) => {
      state.loadingChangePaymentMethod = true;
      state.isPaymentMethodChanged = false;
    },

    changePaymentMethodSuccess: state => {
      state.loadingChangePaymentMethod = false;
      state.isPaymentMethodChanged = true;
      state.paymentStep = EPaymentSteps.VIEW_SUBSCRIPTION;
      state.isAddressCreated = true;
    },

    changePaymentMethodFailure: (state, action: PayloadAction<{ errors: Partial<IMembershipErrors> }>) => {
      state.loadingChangePaymentMethod = false;
      state.isPaymentMethodChanged = false;
      state.errors = { ...state.errors, ...action.payload.errors };
    },

    setErrors: (state, action: PayloadAction<{ errors: Partial<IMembershipErrors> }>) => {
      state.errors = { ...state.errors, ...action.payload.errors };
    },

    purchaseAddonRequest: (
      state,
      action: PayloadAction<{
        priceId: string;
        stripe: Stripe | null;
        elements: StripeElements | null;
        formState: IPaymentFormState;
      }>,
    ) => {
      state.loadingPurchaseAddon = true;
    },

    purchaseAddonStoredCardRequest: (
      state,
      action: PayloadAction<{
        priceId: string;
        paymentMethodId: string;
        stripe: Stripe | null;
      }>,
    ) => {
      state.loadingPurchaseAddon = true;
    },

    purchaseAddonSuccess: state => {
      state.loadingPurchaseAddon = false;
      state.showModal = true;
      state.modalVariant = 'addonPurchased';
    },

    purchaseAddonFailue: (state, action: PayloadAction<{ errors: Partial<IMembershipErrors> }>) => {
      state.errors = { ...state.errors, ...action.payload.errors };
      state.loadingPurchaseAddon = false;
    },

    fetchAddonsRequest: state => {
      state.loadingFetchAddons = true;
    },
    fetchAddonsSuccess: (state, action: PayloadAction<{ addons: TAddons }>) => {
      state.loadingFetchAddons = false;
      state.addons = action.payload.addons;
      state.showPaymentForm = false;
    },
    fetchAddonsfailure: (state, action: PayloadAction<{ errors: Partial<IMembershipErrors> }>) => {
      state.loadingFetchAddons = false;
      state.errors = { ...state.errors, ...action.payload.errors };
    },
  },
});

const subscriptionsSelectors = {
  allState: (state: RootState): typeof initialState => state.subscriptions,
};

const actions = { ...slice.actions };

export { subscriptionsSelectors, SLICE_NAME, actions };
export default slice.reducer;
