import { actions } from './slice';
import { actions as globalActions } from '../../../redux/globalSlice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { endpoints } from '../../../constants';
import axiosRequest from '../../../axios';
import { TopicsTransformer } from '../../../classes';

const topicsTransformer = new TopicsTransformer({ applyOldTopicsFilter: false, applyNewTopicsFilter: false });

function* fetchTopics(action: any) {
  try {
    const { subjectID } = action.payload;
    const { data } = yield call(axiosRequest, { url: endpoints.fetchTopics(subjectID) });
    const topics = topicsTransformer.transform(data);
    yield put(actions.indexSuccess({ entities: topics }));
    yield put(globalActions.topicsIndexSuccess({}));
  } catch (error) {
    yield put(globalActions.topicsIndexFailure({ error }));
  }
}

function* watchFetchTopics() {
  yield takeLatest(globalActions.topicsIndexRequest, fetchTopics);
}

const sagas = [watchFetchTopics];

export default sagas;
