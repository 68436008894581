import Form from '../../components/Form';
import { loginPath } from '../../Router/paths';
import { FormContainer, SignUpFormContainer } from './styled';
import { FormFooter } from '../styled';
import { en } from '../../../i18n';
import { fieldConfigs } from '../constants';
import { useDispatch } from '../../hooks';
import { actions } from '../redux/slice';
import { ILooseObject } from '../../types';
import useAuthForm from '../useAuthForm';
import { useHistory } from 'react-router';
import parse from 'html-react-parser';
import UserAgreements from '../../components/UserAgreements';
import OAuthButtons from '../../components/OAuthButtons';
import { Link } from 'react-router-dom';

const { fullName, email, userName, password, passwordConfirmation } = fieldConfigs;
const fields = { fullName, email, userName, password, passwordConfirmation };
const { signUp } = en;
const { form } = signUp;

const { signInHere, alreadyHaveAnAccount } = form;

const SignUpForm = () => {
  const { dispatch } = useDispatch();
  const { loading, errorFeedback } = useAuthForm({ errorFeedbackPosition: 'aboveFieldsWithNoMargin' });
  const history = useHistory();

  const onSubmit = (formState: ILooseObject) => {
    const { userName, password, email, fullName } = formState;
    dispatch(actions.signUpRequest({ userName, password, email, fullName, history }));
  };

  return (
    <SignUpFormContainer>
      <FormContainer>
        <Form
          loading={loading}
          onSubmit={onSubmit}
          fields={fields}
          title={parse(form.title)}
          submitText={form.submitText}
          formError={errorFeedback}
          useGlobalError={true}
          extraChild={<OAuthButtons />}
        ></Form>

        <UserAgreements />
      </FormContainer>
      <FormFooter applyFormFooterOnMobile>
        <span>
          {alreadyHaveAnAccount} <Link to={loginPath}>{signInHere}</Link>
        </span>
      </FormFooter>
    </SignUpFormContainer>
  );
};

export default SignUpForm;
