import StudySpace from '../StudySpace';
import ReviewResults from '../ReviewResults';
import ForgetPassword from '../Auth/ForgetPassword';
import NewPassword from '../Auth/NewPassword';
import Signup from '../Auth/Signup';
import Login from '../Auth/Login';
import ForceChangePasword from '../Auth/ForceChangeNewPassword';
import OnBoarding from '../OnBoarding';
import { PrivacyPolicyPage, TermsAndAgreements } from '../components/UserAgreements/Pages';
import CodeInput from '../Auth/ForgetPasswordInput';
import RegisterConfirmation from '../Auth/RegisterConfirmation';
import PlacementTest from '../PlacementTest';
import Membership from '../Membership';

import ComingSoon from '../ComingSoon';
import ReadinessAssesment from '../ReadinessAssesment';
import Answers from '../ReviewResults/Answers';
import Home from '../Home';
import NurseGPT from '../NurseGPT';
import Apply from '../Apply';

const authBasePath = '/auth';
const nestedReviewQuizResultsPath = 'study-space/review-results';
const reviewResultsAnswersPath = 'answers';
const dashboardPath = '/home';
const loginPath = `${authBasePath}/login`;
const registerPath = `${authBasePath}/register`;
const forgetPasswordPath = `${authBasePath}/forget-password`;
const changePasswordPath = `${authBasePath}/change-password`;
const membershipPath = '/membership';
const studySpacePath = '/:subjectID/study-space';
const forceChangePasswordPath = `${authBasePath}/force-change-password`;
const passwordResetPath = `${authBasePath}/reset-password`;
const registerConfirmationPath = `${authBasePath}/register-confirmation`;
const placementTestPath = '/placement-test';
const onBoardingPath = '/on-boarding';
const comingSoonPath = '/coming-soon';
const readinessAssesmentPath = '/readiness-assesment';
const nurseGPTPath = '/nursegpt';
const applyPath = '/apply';

const paths = {
  public: {
    forceChangePasswordPassword: {
      path: forceChangePasswordPath,
      component: ForceChangePasword,
      exact: true,
    },
    passwordReset: {
      path: passwordResetPath,
      component: CodeInput,
      exact: true,
    },
    register: {
      path: registerPath,
      component: Signup,
      exact: true,
    },
    forgetPassword: {
      path: forgetPasswordPath,
      component: ForgetPassword,
      exact: true,
    },
    changePassword: {
      path: changePasswordPath,
      component: NewPassword,
      exact: true,
    },
    registerConfirmation: {
      path: registerConfirmationPath,
      component: RegisterConfirmation,
      exact: true,
    },

    login: {
      path: loginPath,
      component: Login,
      exact: true,
    },
    termsAndAgreements: {
      path: '/terms-and-agreements',
      exact: true,
      component: TermsAndAgreements,
      applyRedirect: false,
    },
    privacyPolicy: {
      path: '/privacy-policy',
      component: PrivacyPolicyPage,
      exact: true,
      applyRedirect: false,
    },
  },

  private: {
    onboarding: {
      path: onBoardingPath,
      applyRedirect: false,
      component: OnBoarding,
      exact: true,
    },
    placementTest: {
      path: placementTestPath,
      component: PlacementTest,
      exact: true,
    },
    membership: {
      path: membershipPath,
      component: Membership,
      exact: true,
    },

    studySpace: {
      path: studySpacePath,
      component: StudySpace,
      exact: true,
    },

    reviewResults: {
      path: `/:subjectID/${nestedReviewQuizResultsPath}`,
      component: ReviewResults,
      exact: true,
    },
    reviewResultsAnswers: {
      path: `/:subjectID/${nestedReviewQuizResultsPath}/${reviewResultsAnswersPath}`,
      component: Answers,
      exact: true,
    },

    dashboard: {
      path: dashboardPath,
      exact: true,
      component: Home,
    },
    comingSoon: {
      path: comingSoonPath,
      exact: true,
      component: ComingSoon,
    },
    readinessExam: {
      path: readinessAssesmentPath,
      exact: true,
      component: ReadinessAssesment,
    },
    nurseGPT: {
      path: nurseGPTPath,
      exact: true,
      component: NurseGPT,
    },

    apply: {
      path: applyPath,
      exact: true,
      component: Apply,
    },

    default: {
      path: '/',
      exact: true,
      component: NurseGPT,
    },
  },
};

const defaultPaths = {
  public: paths.public.login.path,
  private: paths.private.nurseGPT.path,
};

const headerHiddenPages = {
  [`${nestedReviewQuizResultsPath}/${reviewResultsAnswersPath}`]: true,
};

const searchBarHiddenPages = {
  [paths.private.onboarding.path]: true,
  [paths.private.placementTest.path]: true,
};

export {
  headerHiddenPages,
  searchBarHiddenPages,
  defaultPaths,
  nestedReviewQuizResultsPath,
  dashboardPath,
  loginPath,
  registerPath,
  forgetPasswordPath,
  changePasswordPath,
  membershipPath,
  studySpacePath,
  forceChangePasswordPath,
  passwordResetPath,
  registerConfirmationPath,
  placementTestPath,
  onBoardingPath,
  comingSoonPath,
  readinessAssesmentPath,
  reviewResultsAnswersPath,
  nurseGPTPath,
  applyPath,
};
export default paths;
