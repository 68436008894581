import { PieConfig } from '@ant-design/charts';
import { TPieChartData } from '../../types';
import PieChart from './PieChart';

class ReadinessPieChart extends PieChart {
  public build(data?: TPieChartData | undefined): PieConfig {
    const categoryBreakDown = data || [
      {
        type: 'Safety & Infection Control',
        value: 12,
      },
      {
        type: 'Psychosocial Integrity',
        value: 8,
      },

      {
        type: 'Health Promotions and Maintenance',
        value: 9,
      },
      {
        type: 'Reduction of Risk Potential',
        value: 14,
      },
      {
        type: 'Management of Care',
        value: 14,
      },
      {
        type: 'Physiological Adaptation',
        value: 14,
      },
      {
        type: 'Basic Care and Comfort',
        value: 11,
      },
    ];
    return {
      ...super.build(categoryBreakDown),
      legend: {
        position: 'bottom',
      },
    };
  }
}

export default ReadinessPieChart;
