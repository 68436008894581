import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';

const ResultsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 238px;
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 50px;
    margin-bottom: 138px;
  }
`;

const ResultsSubtitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${colors.black};
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ResultsTextContainer = styled('div')`
  width: 41%;
  > div:nth-child(2) {
    margin-bottom: 32px;
  }
  > div:nth-child(3) {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 90%;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    width: 80%;
  }
`;

const ResultsTitle = styled('div')`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${colors.black10};
  margin-bottom: 40px;
`;

const ConceptsList = styled('ul')`
  list-style-type: circle;
  list-style-position: inside;
  padding-left: 8px;
  margin: 0;
`;

const Concept = styled('li')`
  font-size: 1.125rem;
  color: ${colors.purple30};
  margin: 8px 0;
  span {
    color: ${colors.black10};
  }
`;

const PurpleText = styled('span')`
  color: ${colors.primary};
`;

export { ResultsContainer, ResultsSubtitle, ResultsTitle, ResultsTextContainer, ConceptsList, Concept, PurpleText };
