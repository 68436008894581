import Button from '../../components/Button';
import { IAttempt } from '../../types';
import { SectionContainer, TitleContainer, TopicsAnalysisTitle } from '../styled';
import { useNavigations } from '../../hooks';

import { en } from '../../../i18n';

const { topicAnalysis } = en.reviewResults;

interface ITopicAnalysis {
  attempt?: IAttempt;
}
const TopicAnalysis = ({ attempt }: ITopicAnalysis) => {
  const { navigateToResultsAnswers } = useNavigations();

  return (
    <SectionContainer>
      <TitleContainer>
        <TopicsAnalysisTitle>{topicAnalysis.title}</TopicsAnalysisTitle>
        <Button onClick={() => attempt && navigateToResultsAnswers(attempt)} variant="secondary">
          {' '}
          {topicAnalysis.subTitleButton}
        </Button>
      </TitleContainer>
    </SectionContainer>
  );
};

export default TopicAnalysis;
