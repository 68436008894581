import { PageContainer } from '../Auth/styled';
import { AuthContainerOverride } from '../OnBoarding/styled';
import {
  BannerOverride,
  BannerTitle,
  FormPopupOveride,
  ProgressText,
  SmartQuizContainerOverride,
  QuestionContainer,
  RadialBackgroundOverride,
  LocationQuestionContainer,
} from './styled';
import { en } from '../../i18n';
import Unstarted from './Unstarted';
import { useSeoTags, useVariableKeyDownPress } from '../hooks';
import useApply from './useApply';
import Spinner from '../components/Spinner';
import { colors } from '../../themes';
import { questionData } from './useSteps';
import { MutableRefObject, useRef } from 'react';
import ProgressBar from '../components/ProgressBar';
import { Banner } from '../SmartQuiz/styled';
import parse from 'html-react-parser';
import Button from '../components/Button';
import CustomTooltip from '../components/Tooltip';

const { apply } = en;

const Apply = () => {
  const seo = useSeoTags({ pageName: 'apply' });
  const { state, control } = useApply({ fetchAttempts: true });
  const { loading, currentApplyAttempt, stepIndex, quizProgress, isNextDisabled, currentStep, loadingAnswer } = state;
  const { goNext, goPrevious, renderStep } = control;

  const bannerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useVariableKeyDownPress('Enter', goNext);
  //useVariableKeyDownPress('Backspace', goPrevious);

  const renderNextButton = () => {
    const button = (
      <Button loading={loadingAnswer} disabled={isNextDisabled} onClick={goNext} variant="primary">
        {currentStep.buttonText || apply.next}
      </Button>
    );
    if (!isNextDisabled) return button;

    return (
      <CustomTooltip leftPopper="-30px" centerText width="100%" arrow={false} title={apply.answerRequired}>
        <span>{button}</span>
      </CustomTooltip>
    );
  };

  const renderExit = () => {
    return (
      <SmartQuizContainerOverride>
        <QuestionContainer>{renderStep()}</QuestionContainer>
      </SmartQuizContainerOverride>
    );
  };

  const renderQuestion = () => {
    const isLocation = currentStep.id === questionData.location.id;
    const Container = isLocation ? LocationQuestionContainer : QuestionContainer;
    return (
      <SmartQuizContainerOverride progress={quizProgress} ref={bannerRef} isCaseStudy={false}>
        <ProgressBar
          backgroundColor={colors.mediumGray}
          barHeight="12px"
          color={colors.purple20}
          useBarOnly
          useBarStripes
          percentage={quizProgress}
        />
        <BannerOverride>
          <BannerTitle>{parse(apply.bannerTitle)}</BannerTitle>
          <ProgressText>
            {quizProgress}% {apply.complete}
          </ProgressText>
        </BannerOverride>

        <Container key={stepIndex}>{renderStep()}</Container>
        <Banner bottom centerButtons>
          {stepIndex > 0 && (
            <Button onClick={goPrevious} variant="secondary">
              {apply.prev}
            </Button>
          )}

          {renderNextButton()}
        </Banner>
      </SmartQuizContainerOverride>
    );
  };

  const renderContent = () => {
    if (loading) return <Spinner appplyBackground loading size="48px" spinnerColor={colors.primary} />;

    if (!currentApplyAttempt)
      return (
        <FormPopupOveride>
          <Unstarted />
        </FormPopupOveride>
      );
    const { isExit } = currentStep;
    if (isExit) return renderExit();

    return renderQuestion();
  };

  return (
    <PageContainer>
      {seo}
      <RadialBackgroundOverride>
        <AuthContainerOverride>{renderContent()}</AuthContainerOverride>
      </RadialBackgroundOverride>
    </PageContainer>
  );
};

export default Apply;
