import { styled } from '@mui/material';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

interface ICircularProgressBarContainer {
  offset: number;
  scale: number;
  // id: string;
}

const CircularProgressBarContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['offset', 'scale']),
})<ICircularProgressBarContainer>(
  ({ offset, scale }) => `
  svg {
    width: 64px;
    height: 64px;

    //transform: scale(${scale}, ${scale});

    text {
      fill: ${colors.black};
      //font-size: ${scale > 1 ? 1 - scale : 1 + scale}rem;
      font-size:1rem;
      font-weight: 700;

    }
  }
  .bg {
    fill: none;
    stroke-width: 5.6px;
    stroke: ${colors.grey600};
  }
  .meter {
    fill: none;
    stroke-width: 5.6px;
    stroke-linecap: round;
    transform: scaleX(-1) rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: 360;
    stroke-dashoffset: ${offset};
    stroke: ${colors.highlightGreen};
    z-index:100;
   
  
  }

//   @keyframes progress {
//     from {
//       stroke-dashoffset: -327;
//     }
//     to {
//       stroke-dashoffset: ${offset};
//     }
//   }
`,
);

export { CircularProgressBarContainer };
