import BaseTransformer from './BaseTransformer';
import { IAttempt } from '../../types';

class QuizTopicsTransformer extends BaseTransformer {
  public transform(attempt: IAttempt): string[] {
    const topics = attempt.questions.flatMap(question => question.groupIds);
    return [...new Set(topics)];
  }
}

export default QuizTopicsTransformer;
