import { styled } from '@mui/material/styles';
//import { colors } from '../../themes';
import { OpacityHoverAnimation } from '../../sharedStyled';

const BackToSubjectView = styled('div')`
  display: flex;
  gap: 12px;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  ${props => OpacityHoverAnimation(props.theme.palette.primary.main)}
  align-items:center;
`;

export { BackToSubjectView };
