import { Breakpoint } from '@mui/material';
import { ILooseObject } from '../../../types';
enum EFieldTypes {
  TEXT,
  PASSWORD,
  CODE_INPUT,
  EMAIL,
  PASSWORD_CONFIRMATION,
  DROP_DOWN,
  DATE,
}

interface IStyledTextFieldProps {
  isFocused?: boolean;
  isEmpty?: boolean;
  position?: 'relative' | 'absolute';
  fullWidth?: boolean;
  disabled?: boolean;
}

type TValidationRules =
  | 'required'
  | 'emailValidation'
  | 'userNameValidation'
  | 'passwordValidation'
  | 'minLengthValidation'
  | 'passwordConfirmationValidation'
  | 'emailOrUserNameValidation';

interface IError {
  type: TValidationRules;
  message: string;
}
interface IFieldExternal {
  label?: string;
  type?: EFieldTypes;
  required?: boolean;
  helperText?: string;
  errorText?: string;
  minLength?: number;
  applyValidationRules?: TValidationRules[];
  skipValidations?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface IPasswordFieldExternal extends IFieldExternal {
  showForgetPassword?: boolean;
}

interface ICodeInputFieldExternal extends IFieldExternal {
  inputLength?: number;
  submitAutomatically?: boolean;
  outputFormat?: 'text' | 'number';
}

interface IDropDownOptions {
  label: string;
  value: string | number;
}
interface IDropDownFieldExternal extends IFieldExternal {
  options: IDropDownOptions[];
  autoWidth?: boolean;
  size?: 'small' | 'medium';
}

interface IDatePickerFieldExternal extends IFieldExternal {
  format?: string;
  modalBreakpoint?: Breakpoint;
  useModal?: boolean;
  onlyFuture?: boolean;
  onFocus?: () => void;
  variant: 'numeric' | 'mui' | 'default';
}

interface IBaseField extends IFieldExternal, IStyledTextFieldProps {
  error?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onTouchStart?: () => void;
  fieldKey: string;
  onChange: (value: TFieldValues) => void;
  value: TFieldValues;
  errors?: IError[];
  renderErrors?: () => React.ReactNode;
}

interface IPasswordField extends IBaseField, IPasswordFieldExternal {}

interface IDropDownField extends IBaseField, IDropDownFieldExternal {
  isOpen?: boolean;
  classesOverride?: ILooseObject;
  renderOptionsOverride?: () => JSX.Element[];
  notched?: boolean;
}

interface IDatePickerField extends IBaseField, IDatePickerFieldExternal {
  isModalOpen?: boolean;
}

interface ICodeInputField extends IBaseField, ICodeInputFieldExternal {
  loading?: boolean;
  onSubmit?: (code: ILooseObject) => void;
  formState?: ILooseObject;
}

interface ITextField extends IBaseField {
  inputType?: 'email' | 'text';
}

type TFieldExternal =
  | IFieldExternal
  | IPasswordFieldExternal
  | ICodeInputFieldExternal
  | IDropDownFieldExternal
  | IDatePickerFieldExternal;

type TField = IBaseField | IPasswordField | ICodeInputField | IDropDownField | IDatePickerField;

type TFieldValues = string | number | ILooseObject | string[] | number[] | Date;

type TFeedbackPositions =
  | 'belowTitle'
  | 'aboveTitle'
  | 'aboveFields'
  | 'aboveFieldsWithNoMargin'
  | 'hidden'
  | 'topOfForm';

export { EFieldTypes };
export type {
  IStyledTextFieldProps,
  IBaseField,
  IFieldExternal,
  TFieldValues,
  IPasswordField,
  ICodeInputFieldExternal,
  ICodeInputField,
  TFieldExternal,
  TField,
  IError,
  ITextField,
  TValidationRules,
  TFeedbackPositions,
  IDropDownField,
  IDropDownFieldExternal,
  IDatePickerField,
};
