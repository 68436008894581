import { Pie as AntdPie } from '@ant-design/charts';
import { PieChartWrapper } from './styled';
import PieChartBuilder from '../../classes/Builders/PieChart';
import { TPieChartData } from '../../types';
import { useMediaQuery, useTheme } from '@mui/material';
import ReadinessPieChart from '../../classes/Builders/ReadinessPieChart';

// TODO add more config to this

type TVariants = 'readiness';
interface IPie {
  title: string;
  data?: TPieChartData;
  variant?: TVariants;
  subTitle?: string;
}

const Pie = ({ title, data, variant, subTitle }: IPie) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pieConfig = () => {
    switch (variant) {
      case 'readiness':
        return new ReadinessPieChart().build(data);

      default:
        return new PieChartBuilder().build(data);
    }
  };

  return (
    <PieChartWrapper>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <AntdPie {...pieConfig()} {...(isMobile && { legend: undefined })} />
    </PieChartWrapper>
  );
};

export default Pie;
