import { useEffect, useMemo } from 'react';
import { Hub, Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { authSelectors, actions } from '../Auth/redux/slice';
import { CurrentProfile, AuthUserTransfomer, AuthErrorTransformer, GoogleTags } from '../classes';
import useDispatch from './useDispatch';
import { actions as studySpaceActions, quizSelectors } from '../StudySpace/redux/slice';
import { actions as membershipActions } from '../Membership/redux/slice';
import { actions as eventActions } from '../events/redux/slice';
import { actions as applyActions } from '../Apply/redux/slice';
import useSubscriptions from './useSubscriptions';
import useAttempts from './useAttempts';
import { EGoogleTags, EUserRole, Eevents } from '../types';
import LocalStorageWithTTL from '../classes/LocalStorageWithTTL';
import { IS_SIGN_UP_LOCAL_STORAGE_ID } from '../constants';
import { roleQuestionId, roleQuestionIdV2 } from '../OnBoarding/constants';
import UserRolesTransformer from '../classes/DataTransformers/UserRolesTransformer';

// To avoid a user changing the config from the console to skip the quizzes
const { control } = window.config || {};
const skipIntroductoryQuizzes = control?.skipIntroductoryQuizzes === 'true';
interface IUseAuthorization {
  useFetchSubscriptions?: boolean;
}
const useAuthorization = (props?: IUseAuthorization) => {
  const { useFetchSubscriptions = false } = props || {};
  const { user, authState, liveOnboardingRole } = useSelector(authSelectors.allState);
  const { attempts, onBoardingAttempts, placementAttempts } = useSelector(quizSelectors.allState);
  const { dispatch } = useDispatch();
  const isLoggedIn = Boolean(user?.username) && Boolean(user?.isConfirmed) && Boolean(!user?.isTemp);
  const isConfirmed = Boolean(user?.isConfirmed);
  const subscriptions = useSubscriptions({ useFetchSubscriptions: useFetchSubscriptions && isLoggedIn });

  const {
    isOnboarded: isDataOnboarded,
    isPlacementTestDone: isDataPlacementTestDone,
    currentOnBoardingTest: storedOnboarding,
    isFullyOnboarded,
  } = useAttempts();

  const isPlacementTestDone = () => {
    if (skipIntroductoryQuizzes || !placementAttempts || isDataPlacementTestDone) return true;
    return isDataPlacementTestDone;
  };
  const isOnboarded = () => {
    if (skipIntroductoryQuizzes || isDataOnboarded || !onBoardingAttempts) return true;
    if (isDataOnboarded === undefined && attempts && attempts.length > 0) true;
    return isDataOnboarded;
  };

  const logout = () => {
    dispatch(actions.logOut());
    dispatch(studySpaceActions.resetState());
    dispatch(membershipActions.resetState());
    dispatch(applyActions.resetState());
    CurrentProfile.logout();
  };

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI':
          handleFederatedLoginSuccess();
          break;
        case 'cognitoHostedUI_failure':
          handleFederatedLoginFailure(data);
          break;
      }
    });
  }, []);

  const handleFederatedLoginSuccess = async () => {
    const fireGoogleTagEvent = () => {
      try {
        const localstorage = new LocalStorageWithTTL(IS_SIGN_UP_LOCAL_STORAGE_ID);
        const data = localstorage.get(true);
        if (!data) return;
        const googleTag = new GoogleTags(EGoogleTags.SIGN_UP);
        const googleTagVerified = new GoogleTags(EGoogleTags.VERIFIED_SIGN_UP);
        googleTagVerified.fireEvent();
        googleTag.fireEvent();
      } catch (e) {
        console.log(e);
      }
    };
    const user = await Auth.currentAuthenticatedUser();
    const transformedUser = new AuthUserTransfomer().federatedTransformOut(user);
    CurrentProfile.setProfile(transformedUser);
    fireGoogleTagEvent();
    dispatch(actions.federatedLoginSuccess({ user: transformedUser }));
    dispatch(eventActions.sendEventRequest({ event: { detailType: Eevents.LOGIN } }));
  };

  const handleFederatedLoginFailure = (data: any) => {
    const authError = new AuthErrorTransformer().transformOut(data);
    dispatch(actions.federatedLoginFailure(authError));
  };

  const storedRole = useMemo(() => {
    const getRawResponse = () => {
      if (liveOnboardingRole) return liveOnboardingRole;

      const onboardingAttempt = storedOnboarding;
      if (!onboardingAttempt) return EUserRole.USER;
      const goalQuestion = onboardingAttempt.questions.find(
        question => question.questionId === roleQuestionId || question.questionId === roleQuestionIdV2,
      );
      if (!goalQuestion?.response) return EUserRole.USER;
      return goalQuestion.response;
    };

    const goalQuestionResponse = getRawResponse();

    const rawResponse = `${goalQuestionResponse}`.replace(/"/g, '');
    const transformer = new UserRolesTransformer();
    return transformer.transform(rawResponse);
  }, [storedOnboarding, liveOnboardingRole]);

  return {
    state: {
      isLoggedIn,
      user,
      isConfirmed,
      authState,
      isOnboarded: isOnboarded(),
      isPlacementTestDone: isPlacementTestDone(),
      isFullyOnboarded,
      storedRole,
    },
    subscriptions,
    logout,
  };
};

export default useAuthorization;
