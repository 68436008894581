import { LandingContainer, LandingFixedContainer } from './styled';
import DisplayUpgrade from './DisplayUpgrade';
import Plan from './Plan';

const Landing = () => {
  return (
    <LandingFixedContainer>
      <LandingContainer>
        <DisplayUpgrade />
        <Plan />
      </LandingContainer>
    </LandingFixedContainer>
  );
};

export default Landing;
