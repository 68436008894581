import { CircularProgressBarContainer } from './styled';

interface ICircularProgressBar {
  progress: number;
  size?: number;
}
const FULL_WIDTH_CIRCLE = 360;
const SCALE = 90;
const SCALE_REFERENCE = 50;
const SVG_WIDTH = 114;

const CircularProgressBar = ({ progress, size = 64 }: ICircularProgressBar) => {
  const strokeOffset = FULL_WIDTH_CIRCLE - (progress * SCALE) / SCALE_REFERENCE;
  const svgSize = size / SVG_WIDTH;

  return (
    <CircularProgressBarContainer scale={svgSize} offset={strokeOffset}>
      <svg>
        <g>
          <circle className="bg" cx="32" cy="32" r="29" />
          <circle className="meter" cx="32" cy="32" r="29"></circle>
          <text x="50%" y="50%" dy="2px" textAnchor="middle" alignmentBaseline="middle">
            {progress}%
          </text>
        </g>
      </svg>
    </CircularProgressBarContainer>
  );
};

export default CircularProgressBar;
