import { FeedbackContainer } from '../components/Form/styled';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { ILooseObject } from '../types';
import { en } from '../../i18n';
import { TFeedbackPositions } from '../components/Form';

interface IuseError {
  selector: (state: RootState) => Partial<{ errors: ILooseObject }>;
  useDefaultErrorMessage?: boolean;
  errorPosition?: TFeedbackPositions;
}
const { somethingWentWrong: defaultMessage } = en;
const useError = ({
  selector,
  useDefaultErrorMessage = true,
  errorPosition = 'aboveFieldsWithNoMargin',
}: IuseError) => {
  const { errors } = useSelector(selector);
  const isError = errors && Object.keys(errors).length > 0;

  const renderError = () => {
    if (!isError) return null;
    const message = useDefaultErrorMessage ? defaultMessage : errors.message;
    return (
      <FeedbackContainer isError position={errorPosition}>
        <h2>{message || defaultMessage}</h2>
      </FeedbackContainer>
    );
  };

  return { renderError, isError };
};

export default useError;
