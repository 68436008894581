import { ReactElement } from 'react';
import { DropDownClozeContainer, DropDownQuestion } from './styled';
import ContentRenderer from './ContentRenderer';
import { ILooseObject, ICaseStudySubquestion } from '../../../types';
import { TFieldValues } from '../../../components/Form/Fields/types';
import DropDown from '../../../components/Form/Fields/DropDown';
import { en } from '../../../../i18n';
import parse from 'html-react-parser';

const { select } = en.caseStudy;

const DropdownCloze = ({
  questions,
  answerSubquestion,
  questionId,
  answer,
  isDone,
  isReviewing,
}: {
  questions: ICaseStudySubquestion[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  answer?: ILooseObject;
  isDone?: boolean;
  isReviewing?: boolean;
}) => {
  const onChange = (id: TFieldValues, index: number) => {
    const newValue = questions.map((question, index) => ({
      id: question.id,
      answerId: answer?.questions?.[index]?.answerId || '',
    }));
    newValue[index].answerId = id;
    answerSubquestion(
      {
        id: questionId,
        questions: newValue,
      },
      {
        id: questionId,
        questions: questions.map(question => ({
          id: question.id,
          answerId: question.detail.answerId,
        })),
      },
    );
  };

  const addDropDowntoHtml = (question: ICaseStudySubquestion, index: number) => {
    const parsedHtml = parse(question.detail.question.detail.copy) as ReactElement;
    parsedHtml.props.children[1] = (
      <span>
        <DropDown
          options={
            question.detail.options?.map(option => ({ value: option.id, label: option.detail.content.detail.copy })) ||
            []
          }
          placeholder={select}
          onChange={id => onChange(id, index)}
          fieldKey={`dropdown-cloze-${index}`}
          value={(isReviewing ? question.detail.answerId : answer?.questions?.[index]?.answerId) || ''}
          size="small"
          disabled={isDone}
          fullWidth
        />
      </span>
    );
    return parsedHtml;
  };

  return (
    <DropDownClozeContainer>
      {questions.map((question: ICaseStudySubquestion, index: number) => (
        <>
          <DropDownQuestion>{addDropDowntoHtml(question, index)}</DropDownQuestion>
        </>
      ))}
    </DropDownClozeContainer>
  );
};

export default DropdownCloze;
