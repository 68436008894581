import { en } from '../../../i18n';
import { ReactNode, useState } from 'react';
import { humanize } from '../../utils';
import { FormContainer, FormTitle, FormSubTitle, FeedbackContainer } from './styled';
import Button from '../Button';
import Field from './Fields';
import { EFieldTypes, TFieldValues, TFieldExternal, TFeedbackPositions } from './Fields/types';
import { ILooseObject } from '../../types';
import useValidation from './useValidation';
import { ReactComponent as CloseIcon } from '../../../images/icons/signup/close.svg';

const { form } = en;

interface IFormFeedback {
  title?: string;
  subTitle?: string;
  position: TFeedbackPositions;
  isActive: boolean;
}

interface IForm {
  fields: {
    [key: string]: TFieldExternal;
  };
  title?: string | JSX.Element | JSX.Element[];
  submitText?: string;
  subTitle?: string;
  CustomButtonComponent?: React.ElementType<any>;
  formSuccess?: IFormFeedback;
  onSubmit?: (formState: ILooseObject) => void;
  loading?: boolean;
  formError?: IFormFeedback;
  useGlobalError?: boolean;
  successSubmitText?: string;
  sucessSubmitCallback?: (formState: ILooseObject) => void;
  extraChild?: ReactNode;
}

const Form = ({
  fields,
  title,
  submitText = form.submitDefault,
  subTitle,
  CustomButtonComponent,
  formSuccess,
  onSubmit: onSubmitCallback,
  loading,
  formError,
  useGlobalError,
  sucessSubmitCallback,
  successSubmitText,
  extraChild,
}: IForm) => {
  const [focusedField, setFocusedField] = useState('');
  const [formState, setFormState] = useState<ILooseObject>({});
  const [isFirstSubmitDone, setIsFirstSubmitDone] = useState(false);
  const { validate, errors, isError } = useValidation({ formState, fields });

  const isSuccess = !formError?.isActive && formSuccess?.isActive;
  const feedbackSource = isSuccess ? formSuccess : formError;
  const feedbackPosition = feedbackSource?.position;
  const isExternalError = formError?.isActive;
  const buttonText = isSuccess && successSubmitText ? successSubmitText : submitText;
  const submitCallback = isSuccess && sucessSubmitCallback ? sucessSubmitCallback : onSubmitCallback;

  const onChange = (fieldKey: string) => (value: TFieldValues) => {
    const nextState = { ...formState, [fieldKey]: value };
    setFormState(nextState);
    if (isFirstSubmitDone) validate(nextState);
  };

  const renderFields = () => {
    if (feedbackPosition === 'belowTitle') return renderFormFeedback();
    return Object.keys(fields).map(fieldKey => {
      const { label = humanize(fieldKey), type, required = false, ...props } = fields[fieldKey] || {};
      const fieldValue = formState[fieldKey] === undefined ? '' : formState[fieldKey];
      return (
        <Field
          fieldKey={fieldKey}
          key={fieldKey}
          label={label}
          type={type}
          required={required}
          onFocus={() => setFocusedField(fieldKey)}
          onTouchStart={() => setFocusedField(fieldKey)}
          onBlur={() => setFocusedField('')}
          isFocused={fieldKey === focusedField}
          onChange={onChange(fieldKey)}
          value={fieldValue}
          errors={errors[fieldKey]}
          error={Boolean(errors[fieldKey] || (useGlobalError ? isExternalError : false))}
          onSubmit={submitCallback}
          loading={loading}
          formState={formState}
          {...props}
        />
      );
    });
  };

  const renderFormFeedback = () => {
    if (!feedbackSource?.isActive) return null;
    const isError = formError?.isActive;
    const { title, subTitle } = feedbackSource;
    return (
      <FeedbackContainer position={feedbackPosition} isError={isError}>
        <h2>{title}</h2>
        <h3>
          {isError && feedbackPosition === 'topOfForm' && <CloseIcon />}
          {subTitle}
        </h3>
      </FeedbackContainer>
    );
  };

  const renderButton = () => {
    if (CustomButtonComponent) return <CustomButtonComponent formState={formState} />;

    return (
      <Button loading={loading} disabled={isError} type="submit" className="form-button" fullWidth variant="primary">
        {buttonText}
      </Button>
    );
  };

  const onSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (loading) return;
    if (!isFirstSubmitDone) setIsFirstSubmitDone(true);
    const valid = validate();
    if (!valid) return;
    submitCallback?.(formState);
  };

  return (
    <FormContainer className="form-container">
      {['aboveTitle', 'topOfForm'].includes(feedbackPosition || '') && renderFormFeedback()}
      {title && <FormTitle className="form-title">{title}</FormTitle>}
      {subTitle && <FormSubTitle className="form-subtitle">{subTitle}</FormSubTitle>}

      <form autoComplete="new-password" onSubmit={onSubmit}>
        {feedbackPosition?.includes('aboveFields') && renderFormFeedback()}
        {extraChild}
        {renderFields()}
        {renderButton()}
      </form>
    </FormContainer>
  );
};

export type { TFieldExternal, IFormFeedback, TFeedbackPositions };
export { EFieldTypes };
export default Form;
