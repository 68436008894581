import { IStepComponent } from '../types';
import { en } from '../../../i18n';
import { OutlinedInput } from '@mui/material';

import { InputContainer, QuestionHeader } from './styled';
import React from 'react';
import useStep from './useStep';

const { apply } = en;
const { experience } = apply;

const Experience = ({ question }: IStepComponent) => {
  const { title, onChangeAnswer, currentResponse } = useStep({ question });

  const onChangeCurrentExperience = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) onChangeAnswer(value);
  };
  return (
    <>
      <QuestionHeader>{experience.title}</QuestionHeader>
      {title}
      <InputContainer>
        <OutlinedInput
          type="text"
          size="medium"
          placeholder={experience.placeHolder}
          onChange={onChangeCurrentExperience}
          value={currentResponse}
          autoFocus
          notched={false}
          key={'experience'}
        />
      </InputContainer>
    </>
  );
};

export default Experience;
