import { ReactComponent as BaseLineIcon } from '../../../images/icons/dashboard/baseline.svg';
import { ReactComponent as ReadinessIcon } from '../../../images/icons/dashboard/readiness.svg';
import { en } from '../../../i18n';
import {
  ButtonContinerFlat,
  IconTextContainer,
  UnstartedQuizContainer,
  UnstartedQuizContainerFlat,
  UnstartedQuizzesContainer,
} from './styled';
import Button from '../../components/Button';
import { useNavigations } from '../../hooks';
import useAttempts from '../../hooks/useAttempts';
import { useMediaQuery, useTheme } from '@mui/material';

const { baselineQuiz, readiness } = en.dashboard.assess;

const InitialQuizzes = () => {
  const { navigateToReadiness, navigateToBaseLineTest, navigateToResults, navigateToContinueQuiz } = useNavigations();
  const { currentReadinessAttempt, readinessAttempts, currentPlacementTest, isPlacementTestDone } = useAttempts();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const startBaseLineQuiz = () => {
    navigateToBaseLineTest(true);
  };

  const startReadinessQuiz = () => {
    navigateToReadiness();
  };

  const renderBaseLineButton = () => {
    switch (true) {
      case isPlacementTestDone:
        return {
          text: baselineQuiz.buttonDone,
          func: () => currentPlacementTest && navigateToResults(currentPlacementTest),
        };
      case Boolean(currentPlacementTest):
        return {
          text: baselineQuiz.buttonContinue,
          func: () => currentPlacementTest && navigateToContinueQuiz(currentPlacementTest),
        };
      default:
        return {
          text: baselineQuiz.button,
          func: startBaseLineQuiz,
        };
    }
  };

  const baseLineButtonPayload = renderBaseLineButton();

  const flatBaseLine = (
    <UnstartedQuizContainerFlat>
      <IconTextContainer>
        <BaseLineIcon />
        <div>
          <h1>{baselineQuiz.title}</h1>
          <span>{baselineQuiz.description}</span>
        </div>
      </IconTextContainer>
      <ButtonContinerFlat>
        <Button onClick={baseLineButtonPayload.func} variant="secondary">
          {baseLineButtonPayload.text}
        </Button>
      </ButtonContinerFlat>
    </UnstartedQuizContainerFlat>
  );

  const mobileReadiness = (
    <UnstartedQuizContainerFlat>
      <IconTextContainer>
        <ReadinessIcon />
        <div>
          <h1>{readiness.title}</h1>
          <span>{readiness.description}</span>
        </div>
      </IconTextContainer>
      <ButtonContinerFlat>
        <Button onClick={startReadinessQuiz} variant="secondary">
          {readiness.button}
        </Button>
      </ButtonContinerFlat>
    </UnstartedQuizContainerFlat>
  );

  if (currentReadinessAttempt || readinessAttempts?.length !== 0) {
    return <UnstartedQuizzesContainer>{flatBaseLine}</UnstartedQuizzesContainer>;
  }

  if (isMobile) {
    return (
      <UnstartedQuizzesContainer>
        {flatBaseLine}
        {mobileReadiness}
      </UnstartedQuizzesContainer>
    );
  }

  return (
    <UnstartedQuizzesContainer>
      <UnstartedQuizContainer>
        <BaseLineIcon />
        <h1>{baselineQuiz.title}</h1>
        <span>{baselineQuiz.description}</span>
        <Button onClick={baseLineButtonPayload.func} variant="secondary">
          {baseLineButtonPayload.text}
        </Button>
      </UnstartedQuizContainer>

      <UnstartedQuizContainer>
        <ReadinessIcon />
        <h1>{readiness.title}</h1>
        <span>{readiness.description}</span>
        <Button onClick={startReadinessQuiz} variant="secondary">
          {readiness.button}
        </Button>
      </UnstartedQuizContainer>
    </UnstartedQuizzesContainer>
  );
};

export default InitialQuizzes;
