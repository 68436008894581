import { Radio, TableContainer } from '@mui/material';
import { Table as StyledTable } from '../../SmartQuiz/question/CaseStudy/MatrixChoice/styled';
import { IMatrixComponentUI } from './types';

const Table = ({ header, rows, response, onChange }: IMatrixComponentUI) => {
  const isResponse = !!response;

  return (
    <TableContainer>
      <StyledTable>
        <tr>
          <th />
          {header.map(option => (
            <th>{option.value}</th>
          ))}
        </tr>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.value}</td>
            {header.map(option => {
              return (
                <td>
                  <Radio
                    key={index}
                    checked={response?.[row.id] === option.id}
                    value={option.id}
                    onChange={() => onChange(row.id, option.id)}
                    //disabled={isDone}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
