import {
  UnstartedCaseStudyWrapper,
  UnstartedCaseStudyContainer,
  UnstartedCaseStudyTitle,
  UnstartedCaseStudyText,
} from './styled';
import { Tag } from '../../../sharedStyled';
import { en } from '../../../../i18n';

const { endedTitle, endedBody, endedTitlePlacement, endedFirstBodyPlacement, endedSecondBodyPlacement } = en.caseStudy;

interface IEndCaseStudy {
  topic?: string;
  isPlacement?: boolean;
}

const EndedCaseStudy = ({ topic, isPlacement }: IEndCaseStudy) => {
  const renderTitle = () => {
    if (isPlacement) return endedTitlePlacement;
    return endedTitle;
  };

  const renderBody = () => {
    if (isPlacement) return endedFirstBodyPlacement;
    return endedBody;
  };
  return (
    <UnstartedCaseStudyWrapper>
      <UnstartedCaseStudyContainer>
        <UnstartedCaseStudyTitle>{renderTitle()}</UnstartedCaseStudyTitle>
        <Tag variant="outlined">{topic}</Tag>
        <UnstartedCaseStudyText>{renderBody()}</UnstartedCaseStudyText>
        {isPlacement && <UnstartedCaseStudyText>{endedSecondBodyPlacement}</UnstartedCaseStudyText>}
      </UnstartedCaseStudyContainer>
    </UnstartedCaseStudyWrapper>
  );
};

export default EndedCaseStudy;
