import { useRef } from 'react';
import { en } from '../../../i18n';
import { ESelectedSection } from './types';
import paths, { applyPath, dashboardPath, nurseGPTPath } from '../../Router/paths';
import { useNavigations } from '../../hooks';
import { SectionHeader, SectionHeaderText } from './styled';
import { ReactComponent as Practice } from '../../../images/icons/dashboard/parctice.svg';
import { ReactComponent as Review } from '../../../images/icons/dashboard/review.svg';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';

const { dashboard } = en;
const { navHeader } = dashboard;

interface IUseMainNavHeader {
  pathname: string;
  nurseGPTRef: React.RefObject<HTMLDivElement>;
  studyRef: React.RefObject<HTMLDivElement>;
  applyRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const useMainNavHeader = ({ pathname, nurseGPTRef, studyRef, applyRef, isMobile }: IUseMainNavHeader) => {
  const { navigateToNurseGPT, navigateToHome, navigateToApply } = useNavigations();

  const offsetTop = useRef(() => {
    const section = document.getElementById(`${ESelectedSection.NURSE_GPT}`);

    return section?.offsetTop || 0;
  });

  const data = [
    {
      ref: nurseGPTRef,
      onClick: navigateToNurseGPT,
      isSelected: pathname === nurseGPTPath || pathname === paths.private.default.path,
      key: 'nurseGPT',
      Icon: NurseGPTIcon,
      label: navHeader.nurseGPt.label,
      disabled: false,
    },
    {
      ref: studyRef,
      onClick: () => navigateToHome(ESelectedSection.PRACTICE),
      isSelected: pathname === dashboardPath,
      key: 'study',
      Icon: Practice,
      label: navHeader.study.label,
      disabled: false,
    },
    {
      ref: applyRef,
      isSelected: pathname === paths.private.apply.path,
      onClick: navigateToApply,
      key: 'apply',
      Icon: Review,
      label: navHeader.apply.label,
      disabled: false,
    },
  ];

  const renderSections = () => {
    return data.map(dataItem => {
      const { Icon, ref, onClick, isSelected, key, label, disabled } = dataItem;
      return (
        <SectionHeader ref={ref} onClick={onClick} isSelected={isSelected} key={key} disabled={disabled}>
          <Icon />
          <SectionHeaderText>{label}</SectionHeaderText>
        </SectionHeader>
      );
    });
  };

  const getRef = () => {
    if (isMobile) return null;
    if (pathname === nurseGPTPath || pathname === paths.private.default.path) return nurseGPTRef;
    if (pathname === dashboardPath) return studyRef;
    if (pathname === applyPath) return applyRef;
    return null;
  };

  return { offsetTop, getRef, renderSections };
};

export default useMainNavHeader;
