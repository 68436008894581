import TabContent from '../Components/TabContent';
import { en } from '../../../i18n';
import { InnerContentTitle, InnerContentTitleNoMargin, TopicBoxesRow } from '../Components/styled';
import { EQuestionTypes, EQuizTypes, IAttempt, ILooseObject } from '../../types';
import { useMemo, useState } from 'react';
import TopicBox from './TopicBox';
import {
  ClearAllButtonContainer,
  SelectAllButtonContainer,
  StartButtonContainer,
  StartQuizContentContainer,
  TitleContainerLargeMargin,
  TitleContainerSmallMargin,
  TopicsNumberInfo,
  TopicsTitle,
} from './styled';
import useSubject from '../Subject/useSubject';
import { subject, topicsWithCaseStudies } from '../../StudySpace/staticConstants';
import { useSelector } from 'react-redux';
import {
  topicsSelectors,
  actions as topicsActions,
  SLICE_NAME as topicsSlice,
} from '../../StudySpace/redux/topics/slice';

const { startQuiz } = en.dashboard.practiceQuiz;
import { ReactComponent as InfoIcon } from '../../../images/icons/dashboard/info.svg';
import { TitleContainer } from '../../ReviewResults/styled';
import Button from '../../components/Button';
import CustomTooltip from '../../components/Tooltip';
import { useDispatch, useMembershipProxy, useStartQuiz } from '../../hooks';
import ButtonToolTip from '../../components/ButtonToolTip';
import { StartQuizButtonContainer } from '../../SmartQuiz/styled';
import { quizSelectors } from '../../StudySpace/redux/slice';
import { NoQuizzes, NoQuizzesTextDisclaimer } from '../ReviewInsigts/styled';

const {
  title,
  subTitle,
  questionTitle,
  multipleChoiceOption,
  ngnOption,
  allOptions,
  quizPrompt,
  weakTopics: weakTopicsText,
  strongTopics: strongTopicsText,
  limitedTopics,
  selectAllButton,
  button,
  infoIconLimited,
  infoIconStrong,
  infoIconWeak,
  mustSelect,
  deselectAll,
  clearButton,
  selectAllHeaderButton,
  numberSelected,
  nclexQuestions,
} = startQuiz;

const { noTopicsTitle, noTopicsSubTitle } = en.dashboard.analyze;

const types = [EQuizTypes.SMARTN_STYLE_QUESTIONS, EQuizTypes.NGN_CASE_STUDY, EQuizTypes.ALL];
const typeData = {
  [EQuizTypes.MULTIPLE_CHOICE]: {
    label: multipleChoiceOption,
    payload: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.ARITHMETIC],
  },
  [EQuizTypes.NGN_CASE_STUDY]: {
    label: ngnOption,
    payload: [EQuizTypes.NGN_CASE_STUDY],
  },
  [EQuizTypes.BOTH]: {
    label: allOptions,
    payload: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.NGN_CASE_STUDY, EQuizTypes.ARITHMETIC],
  },
  [EQuizTypes.ARITHMETIC]: {
    label: multipleChoiceOption,
    payload: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.NGN_CASE_STUDY],
  },

  [EQuizTypes.SMARTN_STYLE_QUESTIONS]: {
    label: nclexQuestions,
    payload: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.ARITHMETIC],
  },

  [EQuizTypes.ALL]: {
    label: allOptions,
    payload: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.ARITHMETIC, EQuizTypes.NGN_CASE_STUDY],
  },
};

interface IStartQuiz {
  attempts?: IAttempt[];
}

enum ETopicTypes {
  WEAK,
  STRONG,
  LIMITED_PRACTICE_DATA,
}
const StartQuiz = ({ attempts }: IStartQuiz) => {
  const [selectedType, setType] = useState<EQuizTypes>(EQuizTypes.MULTIPLE_CHOICE);
  const { getStrongTopics, getWeakTopics } = useSubject({ attempts });
  const weakTopics = getWeakTopics();
  const strongTopics = getStrongTopics();
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const [selectedSuggested, setSelectedSuggested] = useState<ILooseObject>({});
  const [selectedLimitedPractice, setSelectedLimitedPractice] = useState<ILooseObject>({});
  const [selectedStrong, setSelectedStrong] = useState<ILooseObject>({});
  const { dispatch: topicsDispatch } = useDispatch(topicsSlice);
  const { loading } = useSelector(quizSelectors.globalSelector);
  const { startQuiz } = useStartQuiz();
  const { quizYourself } = useMembershipProxy({ useStrict: true });
  const buttonStatus = quizYourself.run();

  const { noTopicsSelected, allTopicsSelected, allTopicsObject } = useMemo(() => {
    const allTopicsObject = { ...selectedSuggested, ...selectedLimitedPractice, ...selectedStrong };
    const allTopicsSelected: string[] = Object.entries(allTopicsObject)
      .map(([key, value]) => {
        if (value) return key;
        return '';
      })
      .filter(topic => topic);

    return {
      allTopicsSelected,
      topicsCount: allTopicsSelected.length,
      noTopicsSelected: allTopicsSelected.length === 0,
      allTopicsObject,
    };
  }, [selectedSuggested, selectedLimitedPractice, selectedStrong]);

  const onStart = () => {
    topicsDispatch(topicsActions.setSelectedTopics({ selectedTopics: allTopicsObject }));
    startQuiz(allTopicsSelected, typeData[selectedType || EQuestionTypes.MULTIPLE_CHOICE].payload);
  };
  const onChangeCheck = (id: EQuizTypes, isChecked: boolean) => {
    if (isChecked) setType(id);
  };

  const getLimitedPracticeTopics = () => {
    const uniqueTopicIds = Array.from(new Set(Object.keys(allTopics)));
    const limitedPracticeTopics = uniqueTopicIds.filter(
      topicId => !weakTopics.includes(topicId) && !strongTopics.includes(topicId),
    );

    return limitedPracticeTopics;
  };
  const limitedPracticeTopics = getLimitedPracticeTopics();

  const renderQuizOptions = () => {
    return types.map((type, index) => {
      return (
        <TopicBox
          key={type}
          isChecked={selectedType === type}
          type="radio"
          onChangeCheck={onChangeCheck}
          id={type}
          text={typeData[type].label}
        />
      );
    });
  };

  const onChangeCheckedWeak = (id: string, isChecked: boolean) => {
    setSelectedSuggested(current => ({ ...current, [id]: isChecked }));
  };

  const onChangeCheckedStrong = (id: string, isChecked: boolean) => {
    setSelectedStrong(current => ({ ...current, [id]: isChecked }));
  };

  const onChangeCheckedLimited = (id: string, isChecked: boolean) => {
    setSelectedLimitedPractice(current => ({ ...current, [id]: isChecked }));
  };

  const getTopicTypesPayload = (topicsType: ETopicTypes) => {
    const filterTopicsSource = (topicIds: string[]) => {
      if (selectedType !== EQuizTypes.NGN_CASE_STUDY) return topicIds;
      return topicIds.filter(topicId => topicsWithCaseStudies.includes(topicId));
    };
    switch (topicsType) {
      case ETopicTypes.WEAK:
        return {
          func: onChangeCheckedWeak,
          dataSource: selectedSuggested,
          allDataSource: filterTopicsSource(weakTopics),
          stateFunc: setSelectedSuggested,
        };
      case ETopicTypes.STRONG:
        return {
          func: onChangeCheckedStrong,
          dataSource: selectedStrong,
          allDataSource: filterTopicsSource(strongTopics),
          stateFunc: setSelectedStrong,
        };
      case ETopicTypes.LIMITED_PRACTICE_DATA:
        return {
          func: onChangeCheckedLimited,
          dataSource: selectedLimitedPractice,
          allDataSource: filterTopicsSource(limitedPracticeTopics),
          stateFunc: setSelectedLimitedPractice,
        };
    }
  };

  const renderTopic = (topicId: string, topicType: ETopicTypes) => {
    if (topicId === subject.id || !allTopics[topicId]) return null;

    const payload = getTopicTypesPayload(topicType);
    return (
      <TopicBox
        isLarge
        key={topicId}
        isChecked={!!payload.dataSource[topicId]}
        type="checkbox"
        onChangeCheck={payload.func}
        id={topicId}
        text={allTopics[topicId]?.name || ''}
      />
    );
  };

  const renderTopics = (topicsType: ETopicTypes) => {
    const dataSource = getTopicTypesPayload(topicsType).allDataSource;
    if (!dataSource || dataSource.length === 0) {
      return (
        // <Message>
        //   <span>{noTopicsFound}</span>
        // </Message>
        <NoQuizzes>
          <NoQuizzesTextDisclaimer>
            <h1>{noTopicsTitle(topicsType === ETopicTypes.STRONG)}</h1>
            <h2>{noTopicsSubTitle}</h2>
          </NoQuizzesTextDisclaimer>
        </NoQuizzes>
      );
    }
    return <TopicBoxesRow>{dataSource.map(topic => renderTopic(topic, topicsType))}</TopicBoxesRow>;
  };

  const onSelectAllTopicType = (topicsType: ETopicTypes, skipInvert?: boolean) => () => {
    const payload = getTopicTypesPayload(topicsType);
    const isAllSelected = isAllSelectedCheck(topicsType);
    if (isAllSelected && !skipInvert) {
      payload.stateFunc({});
      return;
    }

    const selectPayload = payload.allDataSource.reduce(
      (accum: ILooseObject, topic) => ({ ...accum, [topic]: true }),
      {},
    );

    payload.stateFunc(selectPayload);
  };

  const isAllSelectedCheck = (topicsType: ETopicTypes) => {
    const payload = getTopicTypesPayload(topicsType);
    const isAllSelected = payload.allDataSource.every(topic => payload.dataSource[topic]);
    return isAllSelected;
  };

  const renderButtonText = (topicsType: ETopicTypes | ETopicTypes[]) => {
    if (!Array.isArray(topicsType)) {
      const isEmpty = getTopicTypesPayload(topicsType).allDataSource.length === 0;
      if (isEmpty) return null;
      const isAllSelected = isAllSelectedCheck(topicsType);
      return isAllSelected ? deselectAll : selectAllButton;
    }

    const isAllSelected = topicsType.every(isAllSelectedCheck);

    return isAllSelected ? deselectAll : selectAllHeaderButton;
  };

  const onSelectAllTopics = () => {
    const invert = [ETopicTypes.LIMITED_PRACTICE_DATA, ETopicTypes.STRONG, ETopicTypes.WEAK]
      .filter(topicType => getTopicTypesPayload(topicType).allDataSource.length)
      .every(isAllSelectedCheck);
    onSelectAllTopicType(ETopicTypes.LIMITED_PRACTICE_DATA, !invert)();
    onSelectAllTopicType(ETopicTypes.WEAK, !invert)();
    onSelectAllTopicType(ETopicTypes.STRONG, !invert)();
  };

  const onClearAllSelections = () => {
    const types = [ETopicTypes.WEAK, ETopicTypes.STRONG, ETopicTypes.LIMITED_PRACTICE_DATA];
    types.forEach(type => {
      const payload = getTopicTypesPayload(type);
      payload.stateFunc({});
    });
  };

  const getStartButton = () => {
    const { buttonDisabled, buttonDisableText = mustSelect } = buttonStatus || {};
    const disableNoTopicsSelected = noTopicsSelected || !selectedType;
    const isButtonDisabled = buttonDisabled || disableNoTopicsSelected;

    const startButton = (
      <ButtonToolTip
        buttonProps={{
          disabled: isButtonDisabled,
          loading: loading,
          onClick: onStart,
          size: 'large',
          variant: 'primary',
          children: button,
        }}
        // buttonOverride={StartQuizButton}
        toolTipProps={{
          title: buttonDisableText,
          leftPopper: '20px',
        }}
        showToolTip={isButtonDisabled}
        ToolTipContainer={StartQuizButtonContainer}
      ></ButtonToolTip>
    );
    return startButton;
  };

  const renderTopicsNumberInfo = () => {
    if (!allTopicsSelected?.length) return null;

    return (
      <>
        <TopicsNumberInfo>{numberSelected(allTopicsSelected.length)}</TopicsNumberInfo>
        <ClearAllButtonContainer>
          <Button onClick={onClearAllSelections} variant="underlinedSpan">
            {clearButton}
          </Button>
        </ClearAllButtonContainer>
      </>
    );
  };

  const renderSuggested = () => {
    const dataSource = getTopicTypesPayload(ETopicTypes.WEAK).allDataSource;
    if (!dataSource || dataSource.length === 0) return null;
    return (
      <>
        <TitleContainerSmallMargin>
          <TopicsTitle>
            {weakTopicsText}{' '}
            <CustomTooltip width="100%" arrow={false} title={infoIconWeak}>
              <span>
                <InfoIcon />
              </span>
            </CustomTooltip>
          </TopicsTitle>
          <SelectAllButtonContainer>
            <Button onClick={onSelectAllTopicType(ETopicTypes.WEAK)} variant="underlinedSpan">
              {renderButtonText(ETopicTypes.WEAK)}
            </Button>
          </SelectAllButtonContainer>
        </TitleContainerSmallMargin>
        {renderTopics(ETopicTypes.WEAK)}
      </>
    );
  };

  const renderLimitedPractice = () => {
    const dataSource = getTopicTypesPayload(ETopicTypes.LIMITED_PRACTICE_DATA).allDataSource;
    if (!dataSource || dataSource.length === 0) return null;
    return (
      <>
        <TitleContainerSmallMargin>
          <TopicsTitle>
            {limitedTopics}{' '}
            <CustomTooltip width="100%" arrow={false} title={infoIconLimited}>
              <span>
                <InfoIcon />
              </span>
            </CustomTooltip>
          </TopicsTitle>
          <SelectAllButtonContainer>
            <Button onClick={onSelectAllTopicType(ETopicTypes.LIMITED_PRACTICE_DATA)} variant="underlinedSpan">
              {renderButtonText(ETopicTypes.LIMITED_PRACTICE_DATA)}
            </Button>
          </SelectAllButtonContainer>
        </TitleContainerSmallMargin>
        {renderTopics(ETopicTypes.LIMITED_PRACTICE_DATA)}
      </>
    );
  };

  const renderStrong = () => {
    const dataSource = getTopicTypesPayload(ETopicTypes.STRONG).allDataSource;
    if (!dataSource || dataSource.length === 0) return null;
    return (
      <>
        <TitleContainerSmallMargin>
          <TopicsTitle>
            {strongTopicsText}{' '}
            <CustomTooltip width="100%" arrow={false} title={infoIconStrong}>
              <span>
                <InfoIcon />
              </span>
            </CustomTooltip>
          </TopicsTitle>
          <SelectAllButtonContainer>
            <Button onClick={onSelectAllTopicType(ETopicTypes.STRONG)} variant="underlinedSpan">
              {renderButtonText(ETopicTypes.STRONG)}
            </Button>
          </SelectAllButtonContainer>
        </TitleContainerSmallMargin>
        {renderTopics(ETopicTypes.STRONG)}
      </>
    );
  };
  return (
    <TabContent title={title} subTitle={subTitle} smallTitleMargin>
      <StartQuizContentContainer>
        <TitleContainer>
          <InnerContentTitle>{questionTitle}</InnerContentTitle>
        </TitleContainer>
        <TopicBoxesRow>{renderQuizOptions()}</TopicBoxesRow>
        <TitleContainerLargeMargin>
          <TitleContainer>
            <InnerContentTitleNoMargin>{quizPrompt}</InnerContentTitleNoMargin>
          </TitleContainer>
          <SelectAllButtonContainer>
            <Button onClick={onSelectAllTopics} variant="underlinedSpan">
              {renderButtonText([ETopicTypes.LIMITED_PRACTICE_DATA, ETopicTypes.STRONG, ETopicTypes.WEAK])}
            </Button>
          </SelectAllButtonContainer>
        </TitleContainerLargeMargin>
        {renderSuggested()}
        {renderLimitedPractice()}
        {renderStrong()}
        <StartButtonContainer>
          {getStartButton()}
          {renderTopicsNumberInfo()}
        </StartButtonContainer>
      </StartQuizContentContainer>
    </TabContent>
  );
};

export default StartQuiz;
