import { IAttempt, ILooseObject } from '../../types';
import {
  AnalysisBanner,
  AnalysisTagsContainer,
  DateContainer,
  DatesContainer,
  QuizTitle,
  TitleContainer,
} from '../styled';
import { format } from 'date-fns';
import { ReactComponent as Checkmark } from '../../../images/icons/reviewResults/checkMark.svg';
import { ReactComponent as CompletedCircle } from '../../../images/icons/reviewResults/completedCircle.svg';
import AnalysisTag from './AnalysisTag';
import { colors } from '../../../themes';
import { calculatePassingChancePercentage, humanReadableDuration, mapPercentageToPassingChanceTag } from '../../utils';
import QuestionProgress from './QuestionProgress';
import { en } from '../../../i18n';
import InfoIconGeneral from '../../components/InfoIconGeneral';

const { reviewResults } = en;
const { topBanner } = reviewResults;

interface ITopBanner {
  attempt?: IAttempt;
  topicsResults: ILooseObject;
}

const dateFormat = 'MMMM d, yyyy';

const TopBanner = ({ attempt, topicsResults }: ITopBanner) => {
  if (!attempt) return null;

  const createdDate = new Date(attempt.ctime);
  const endDate = new Date(attempt.endtime || Date.now());
  const { progress, totalDisplayQuestions } = attempt;
  const passingChancePercentage = calculatePassingChancePercentage(progress.grade, topicsResults);

  const { text } = mapPercentageToPassingChanceTag(
    passingChancePercentage,
    progress.answeredQuestions,
    totalDisplayQuestions / 2,
  );

  const questionProgress = () => {
    const { progress } = attempt;
    const { answeredQuestions, totalQuestions, totalMark, totalMaximumMark, grade } = progress;
    return <QuestionProgress percentage={grade} totalMaximumMark={totalMaximumMark} totalMark={totalMark} />;
  };

  return (
    <AnalysisBanner>
      <TitleContainer>
        <QuizTitle>{attempt.title}</QuizTitle>
        <DatesContainer>
          <DateContainer>
            <CompletedCircle />
            <span>
              {reviewResults.quizStartDateLabel} {format(createdDate, dateFormat)}{' '}
            </span>
          </DateContainer>
          <DateContainer>
            <Checkmark />
            <span>
              {reviewResults.quizEndDateLabel} {format(endDate, dateFormat)}{' '}
            </span>
          </DateContainer>
        </DatesContainer>
      </TitleContainer>

      <AnalysisTagsContainer>
        <AnalysisTag
          title={topBanner.marksScored}
          component={questionProgress()}
          textColor={colors.red1}
          backgroundColor={colors.red2}
        />
        {attempt.isReadiness && (
          <AnalysisTag
            title={
              <>
                {topBanner.passingChanceTag}
                <InfoIconGeneral text={en.readinessAssesment.passingChanceDisclaimerReviewResults} />
              </>
            }
            bodyText={text}
            textColor={colors.blue1}
            backgroundColor={colors.blue2}
          />
        )}

        <AnalysisTag
          title={topBanner.totalTimeTaken}
          bodyText={humanReadableDuration(attempt.totalTime)}
          textColor={colors.yellow1}
          backgroundColor={colors.yellow2}
        />

        <AnalysisTag
          title={topBanner.avgTime}
          bodyText={humanReadableDuration(attempt.avgQuestionTime, {
            showHours: false,
            useTextFormat: true,
            applyPadding: false,
          })}
          textColor={colors.green1}
          backgroundColor={colors.green2}
        />
      </AnalysisTagsContainer>
    </AnalysisBanner>
  );
};

export default TopBanner;
