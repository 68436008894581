import { FormFooter, FormFooterContainer } from '../styled';
import Form from '../../components/Form';
import { en } from '../../../i18n';
import { registerPath } from '../../Router/paths';
import Auth from '../Auth';
import { fieldConfigs } from '../constants';
import { useDispatch, useSeoTags } from '../../hooks';
import { actions } from '../redux/slice';
import { ILooseObject } from '../../types';
import useAuthForm from '../useAuthForm';
import { useHistory } from 'react-router';
import { TValidationRules } from '../../components/Form/Fields/types';
import OAuthButtons from '../../components/OAuthButtons';
import { Link } from 'react-router-dom';

const { userName, password } = fieldConfigs;
const { login } = en;

const { title, submitText, footerText, linkText, userNameOrEmail, subTitle } = login;

const fields = {
  userName: {
    ...userName,
    label: userNameOrEmail,
    applyValidationRules: ['emailOrUserNameValidation' as TValidationRules],
  },
  password: { ...password, showForgetPassword: true, skipValidations: true },
};

const Login = () => {
  const { dispatch } = useDispatch();
  const { loading, errorFeedback, successFeedback } = useAuthForm({ disableResetState: true });
  const history = useHistory();
  const seo = useSeoTags({ pageName: 'login' });

  const onSubmit = (formState: ILooseObject) => {
    // TODO: Type cast this so we can dispatch with the form state directly
    const { userName, password } = formState;
    dispatch(actions.loginRequest({ userName, password, history }));
  };

  const oAuthButtons = <OAuthButtons isSignIn />;

  return (
    <Auth tallForm>
      {seo}
      <Form
        formError={errorFeedback}
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
        title={title}
        submitText={submitText}
        formSuccess={successFeedback}
        useGlobalError={true}
        extraChild={oAuthButtons}
      ></Form>

      <FormFooter applyBorderRadius>
        <span>
          {footerText} <Link to={registerPath}>{linkText}</Link>
        </span>
      </FormFooter>
    </Auth>
  );
};

export default Login;
