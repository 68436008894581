import { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { InputFieldIcon, StyledTextField } from '../styled';
import {
  ControlButtons,
  CurrentMonthHeader,
  DateBody,
  DatePickerContainer,
  DatePickerPopup,
  DayHeaders,
  PopupHeader,
  DateRow,
  DayCell,
  NumericDateContainer,
} from './styled';
import { format as fnsFormat, parse } from 'date-fns';
import DropDown from '../DropDown';
import { ReactComponent as NextArrow } from '../../../../../images/icons/form/nextarrow.svg';
import { ReactComponent as PrevArrow } from '../../../../../images/icons/form/prevarrow.svg';
import { ReactComponent as DateIcon } from '../../../../../images/icons/form/date.svg';
import Button from '../../../Button';
import { CALENDAR_MONTHS, THIS_YEAR, WEEK_DAYS, zeroPad, THIS_MONTH, THIS_DAY, THIS_DATE } from './utils';
import useDatePicker from './useDatePicker';
import { en } from '../../../../../i18n';
import { IDatePickerField } from '../types';
import { chunk } from 'lodash';
import Modal from '../../../Modal';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-US';

const { datePicker } = en;

type TMonthIndices = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

//TODO: Support receiving value

const DatePicker = ({
  onChange,
  value,
  fullWidth,
  disabled,
  format = 'MMMM d, yyyy',
  modalBreakpoint = 'sm',
  useModal,
  onlyFuture,
  isModalOpen,
  onFocus: onFocusExternal,
  variant = 'default',
}: IDatePickerField) => {
  const theme = useTheme();
  const isMediaQuery = useMediaQuery(theme.breakpoints.down(modalBreakpoint));
  const isModal = isMediaQuery || useModal;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isClickedBefore, setIsClickedBefore] = useState(false);
  const { state, control } = useDatePicker();
  const { currentDays, month, year, day: currentDay } = state;
  const { onNextMonth, onPreviousMonth, onChangeMonth, onChangeYear, onChangeDay, resetState } = control;

  useEffect(() => {
    if (isModalOpen) {
      setIsDropDownOpen(isModalOpen);
      if (!isClickedBefore) setIsClickedBefore(true);
    }
  }, [isModalOpen]);

  const renderWeekDays = () => {
    return Object.values(WEEK_DAYS).map(day => {
      return <span key={day}>{day}</span>;
    });
  };

  const confirmChangeDate = () => {
    onChange(new Date(`${month}/${currentDay}/${year}`));
    setIsDropDownOpen(false);
    resetState();
  };

  const areAllValuesPresent = currentDay && month && year;

  const renderDayRows = () => {
    const dayRows = chunk(currentDays, 7);

    const renderDays = (weekIndex: number) => {
      const week = dayRows[weekIndex];

      return week.map(day => {
        const isDisabled =
          (weekIndex === 0 && day > 7) ||
          (weekIndex > 2 && day < 10) ||
          (onlyFuture && new Date(year, month - 1, day) < THIS_DATE);

        const onChange = () => {
          if (isDisabled) return;
          onChangeDay(day);
        };
        return (
          <DayCell selected={currentDay === day} onClick={onChange} disabled={isDisabled} key={day}>
            {zeroPad(day, 2)}
          </DayCell>
        );
      });
    };
    const renderedRows = [];
    for (let i = 0; i < dayRows.length; i++) {
      const row = <DateRow key={i}>{renderDays(i)}</DateRow>;
      renderedRows.push(row);
    }

    return renderedRows;
  };

  const getMonthName = () => {
    const indexKey = `${month}` as TMonthIndices;
    return CALENDAR_MONTHS[indexKey];
  };

  const allMonths = () => {
    return Object.entries(CALENDAR_MONTHS).map(entry => ({ value: entry[0], label: entry[1] }));
  };

  // TODO: Change this to be dynamic based on scroll position\
  // For now only need future years
  const allYears = () => {
    const pivotPoint = THIS_YEAR;
    const currentDistance = year ? Math.abs(year - THIS_YEAR) * 3 : 0;
    const requiredLength = Math.max(currentDistance, 10);
    const requiredLengthToOddNumber = requiredLength % 2 === 0 ? requiredLength + 1 : requiredLength;
    const midPointIndex = onlyFuture ? 0 : Math.floor(requiredLengthToOddNumber / 2);
    const years = new Array(requiredLengthToOddNumber).fill(pivotPoint);
    for (let i = midPointIndex + 1; i < years.length; i++) {
      const currentStep = i - midPointIndex;
      years[i] = years[i] + currentStep;
      const backIndex = i - midPointIndex - 1;
      if (!onlyFuture) years[backIndex] = years[backIndex] - (midPointIndex + 1 - currentStep);
    }
    return years.map(year => ({ label: `${year}`, value: `${year}` }));
  };

  const displayValue = value && fnsFormat(new Date(`${value}`), format);

  const onFocus = (e: React.SyntheticEvent) => {
    if (disabled) return;
    if (!isClickedBefore) setIsClickedBefore(true);
    setIsDropDownOpen(!isDropDownOpen);
    onFocusExternal?.();
  };

  const cancelChanges = () => {
    setIsDropDownOpen(false);
    resetState();
  };

  const startAdornment = (
    <InputAdornment position="start" onClick={onFocus}>
      <InputFieldIcon>
        <DateIcon />
      </InputFieldIcon>
    </InputAdornment>
  );

  const dateComponent = (
    <DatePickerPopup isClickedBefore={isClickedBefore} isVisible={isDropDownOpen && !disabled}>
      <PopupHeader>
        <DropDown
          size="small"
          fullWidth
          label={datePicker.month}
          fieldKey="month"
          value={month}
          options={allMonths()}
          onChange={onChangeMonth}
        />
        <DropDown
          size="small"
          fullWidth
          label={datePicker.year}
          fieldKey="year"
          value={year}
          options={allYears()}
          onChange={onChangeYear}
        />
      </PopupHeader>
      <CurrentMonthHeader>
        {(!onlyFuture || month !== THIS_MONTH || year !== THIS_YEAR) && <PrevArrow onClick={onPreviousMonth} />}

        <span>
          {getMonthName()} &nbsp; {year}
        </span>
        <NextArrow onClick={onNextMonth} />
      </CurrentMonthHeader>

      <DateBody>
        <DayHeaders>{renderWeekDays()}</DayHeaders>
        {renderDayRows()}
      </DateBody>
      <ControlButtons>
        <Button onClick={cancelChanges} variant="secondary">
          {datePicker.buttonCancel}
        </Button>
        <Button disabled={!areAllValuesPresent} onClick={confirmChangeDate} variant="primary">
          {datePicker.buttonConfirm}
        </Button>
      </ControlButtons>
    </DatePickerPopup>
  );

  const renderDate = () => {
    if (isModal)
      return (
        <Modal
          onClose={cancelChanges}
          id="date-modal"
          isModalOpen={isDropDownOpen && !disabled}
          onPrimaryButtonClick={confirmChangeDate}
        >
          {dateComponent}
        </Modal>
      );
    return dateComponent;
  };

  const renderNumericDate = () => {
    const placeholders = ['MM', 'DD', 'YYYY'];
    const date = new Date(`${value}`);
    const values = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    return (
      <NumericDateContainer>
        {startAdornment}
        {[...Array(3).keys()].map((_, index) => (
          <StyledTextField
            value={values[index] || ''}
            placeholder={placeholders[index]}
            onFocus={onFocus}
            InputProps={{ autoComplete: 'off', readOnly: disabled }}
            focused={disabled ? false : undefined}
            disabled={disabled}
            size="small"
          />
        ))}
      </NumericDateContainer>
    );
  };

  const onMuiDateChange = (date?: string | null, context?: any) => {
    if (!date || context?.validationError) return;
    onChange(new Date(date));
  };

  const renderDateComponentVariant = () => {
    switch (variant) {
      case 'numeric':
        return (
          <>
            {renderNumericDate()}
            {renderDate()}
          </>
        );
      //TODO: Update mui package version to avoid overriding typescript
      case 'mui':
        return (
          <LocalizationProvider adapterLocale={enLocale} dateAdapter={AdapterDateFns}>
            {/*@ts-ignore*/}
            <MUIDatePicker
              onChange={onMuiDateChange}
              label=""
              //@ts-ignore
              {...(value && { value: new Date(value) })}
            />
          </LocalizationProvider>
        );

      default:
        return (
          <>
            <StyledTextField
              value={displayValue}
              fullWidth={fullWidth}
              placeholder={datePicker.placeHolder}
              onFocus={onFocus}
              InputProps={{ autoComplete: 'off', startAdornment, readOnly: disabled }}
              focused={disabled ? false : undefined}
              disabled={disabled}
              size="small"
            ></StyledTextField>
            {renderDate()}
          </>
        );
    }
  };

  return <DatePickerContainer>{renderDateComponentVariant()}</DatePickerContainer>;
};

export default DatePicker;
