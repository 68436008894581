import { ILooseObject } from '../../types';
import { TFieldExternal, IError, TFieldValues, EFieldTypes, TValidationRules } from './Fields/types';
import { humanize, isEmptyValue } from '../../utils';
import { en } from '../../../i18n';

const { form } = en;
class Validator {
  private fieldValue: string;
  private label: string;

  constructor(fieldKey: string, fieldValue: string, label?: string) {
    this.fieldValue = fieldValue;
    this.label = label || humanize(fieldKey);
  }

  validateRequired(): IError | boolean {
    if (!isEmptyValue(this.fieldValue)) return false;
    return {
      type: 'required',
      message: `${this.label} ${form.requiredErrorMessage} `,
    };
  }

  validateEmail(): IError | boolean {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegExp.test(this.fieldValue.toLowerCase())) return false;
    return {
      type: 'emailValidation',
      message: form.invalidEmail,
    };
  }

  validateUserName(): IError | boolean {
    if (this.fieldValue.split(/\s/).length > 1)
      return {
        type: 'userNameValidation',
        message: form.invalidUserName,
      };

    if (this.fieldValue.includes('@'))
      return {
        type: 'userNameValidation',
        message: form.userNameIsEmail,
      };
    return false;
  }

  validateEmailOrUserName(): IError | boolean {
    if (this.fieldValue.includes('@')) return this.validateEmail();
    else if (this.fieldValue.split(/\s/).length > 1)
      return {
        type: 'userNameValidation',
        message: form.invalidUserName,
      };
    return false;
  }

  validateLength(minLength: number = 8): IError | boolean {
    if (this.fieldValue.length < minLength)
      return {
        type: 'minLengthValidation',
        message: `${this.label} ${form.minLength(minLength)}`,
      };

    return false;
  }

  validatePassword(): IError | boolean {
    const error: IError = {
      type: 'passwordValidation',
      message: '',
    };
    switch (true) {
      case !/[\d]{1}/g.test(this.fieldValue):
        error.message = form.passwordOneNumber;
        break;
      case !/[a-z]{1}/g.test(this.fieldValue):
        error.message = form.passwordOneLowerCase;
        break;
      case !/[A-Z]{1}/g.test(this.fieldValue):
        error.message = form.passwordOneUpperCase;
        break;
      case !/[^a-zA-Z0-9]{1}/g.test(this.fieldValue):
        error.message = form.passwordOneSpecialCharacter;
        break;
    }
    if (error.message) return error;
    return false;
  }

  validateConfirmPassword(password: string): IError | boolean {
    if (this.fieldValue !== password)
      return {
        type: 'passwordConfirmationValidation',
        message: form.passwordsNotMatching,
      };
    return false;
  }
}

export default Validator;
