import { TVariant, Tsizes } from './types';
import { colors, getTheme } from '../../../themes';
import { OpacityBackroundHoverAnimation, OpacityHoverAnimation } from '../../sharedStyled';
import { Theme } from '@mui/material';

interface IButtonProps {
  size: Tsizes;
  disabled?: boolean;
  loading: boolean;
  variant: TVariant;
  fullWidth?: boolean;
}

class StyleBuilder {
  private variant: TVariant;
  private loading: boolean;
  private size: Tsizes;
  private theme: Theme;
  private disabled: boolean;
  private fullWidth: boolean;

  private sizeStyles = {
    small: `padding:6.5px 16px; font-size:0.875rem;`,
    medium: `padding:10.5px 24px; font-size:0.875rem;`,
    large: `padding:13px 24px; font-size:1rem;`,
    extraLarge: ` padding: 15.5px 32px;  font-size: 1.125rem;`,
  };

  private mobileSizeStyles = {
    small: 'padding: 3.25px 8px;',
    medium: 'padding: 8px 18px;',
    large: 'padding: 10px 18px;',
    extraLarge: 'padding: 7.75px 16px;',
  };

  constructor({ variant, loading, size, disabled = false, fullWidth }: IButtonProps) {
    this.variant = variant;
    this.loading = loading;
    this.size = size;
    this.disabled = disabled;
    this.fullWidth = Boolean(fullWidth);
    this.theme = getTheme();
  }

  private isFilledVariant() {
    return ['primary', 'secondary'].includes(this.variant);
  }

  private getSizeStyle() {
    return `${this.sizeStyles[this.size]}${this.theme.breakpoints.down('sm')} {
        ${this.mobileSizeStyles[this.size]}

    }`;
  }

  private getLoaderSize() {
    switch (this.size) {
      case 'small':
        return 1.5;
      case 'medium':
        return 2;
      default:
        return 2.2;
    }
  }
  private buildLoaderStyle() {
    const size = this.getLoaderSize();
    const progress = `.MuiCircularProgress-root {
        color:${['underlined', 'underlinedSpan'].includes(this.variant) ? colors.primary : colors.white};
        position:absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width:${size}rem !important;
        height:${size}rem !important;
        ${this.theme.breakpoints.down('md')} {
          width:${size - 0.5}rem !important;
          height:${size - 0.5}rem !important;
        }
      }`;
    const hiddenContent = `span:first-child {
        visibility: hidden;
      }`;
    if (!this.loading) return progress;
    return `${progress}${hiddenContent}`;
  }

  private buildDisabledGreyStyle() {
    return ` color:${colors.mediumGray};
  
    border: 2px solid ${colors.mediumGray};
    cursor: no-drop;
    box-shadow:none; 
    &:hover { 
        opacity: 1;
        }
    `;
  }

  private buildDisabledStyle() {
    if (this.disabled || this.loading) {
      if (this.variant === 'secondayGreyDisable') return this.buildDisabledGreyStyle();
      return `
    color:${this.variant === 'primary' ? colors.white : colors.primary};
    background-color:${this.isFilledVariant() ? colors.mediumGray : 'none'};
    border: ${this.isFilledVariant() ? `2px solid ${colors.mediumGray}` : 'none'}; 
    cursor: no-drop; 
    &:hover { 
        opacity: 1;
        }
    `;
    }
    return '';
  }

  private underlinedStyle() {
    return `cursor: pointer;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    position:relative;
    padding:0;
    ${OpacityHoverAnimation()}
    `;
  }

  private underlinedSpanStyle() {
    return this.underlinedStyle();
  }

  private filledButtonStyle() {
    return `
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid rgba(96, 75, 223, 1);
    font-weight: 700;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position:relative;
    ${this.getSizeStyle()}
    `;
  }

  private primaryStyle() {
    return `
    ${this.filledButtonStyle()}
    color:${colors.white};
    background-color: ${colors.primary};
   ${this.disabled ? '' : OpacityBackroundHoverAnimation(colors.primaryHover)};
    `;
  }

  private primarySpanStyle() {
    return this.primaryStyle();
  }

  private secondaryStyle() {
    return `
    ${this.filledButtonStyle()}
    color: ${colors.primary};
    background: transparent;
    transition: background 0.3s linear;
    &:hover {
      background: ${this.disabled ? 'transparent' : colors.purple5};
     }
    `;
  }

  private buildFullWidthStyle() {
    if (!this.fullWidth) return '';
    return 'width:100%;';
  }

  private errorStyle() {
    return `${this.secondaryStyle()}
            color:${colors.errorRed};
            border: 2px solid ${colors.errorRed};
            `;
  }

  private errorFilledStyle() {
    return `
    ${this.filledButtonStyle()}
    color: ${colors.white};
    background: ${colors.errorRed};
    border:2px solid  ${colors.red300};
    `;
  }

  public errorRed300Style() {
    return `
    ${this.filledButtonStyle()}
    color: ${colors.red300};
    border:2px solid  ${colors.red300};`;
  }

  private secondayGreyDisableStyle() {
    return this.secondaryStyle();
  }

  public build() {
    return `

    ${this[`${this.variant}Style`]()}
    ${this.buildLoaderStyle()}
    ${this.buildDisabledStyle()}
    ${this.buildFullWidthStyle()}
 
    `;
  }
}

export default StyleBuilder;
