import { useState } from 'react';
import Radio from '@mui/material/Radio';
import { SingleChoiceContainer, Choice } from './styled';
import ContentRenderer from './ContentRenderer';
import { ICaseStudyOption, ILooseObject } from '../../../types';

const SingleChoice = ({
  choices,
  answerSubquestion,
  questionId,
  answer,
  isDone,
  correctAnswer,
  isReviewing,
}: {
  choices: ICaseStudyOption[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  answer?: ILooseObject;
  isDone?: boolean;
  correctAnswer?: string;
  isReviewing: boolean;
}) => {
  const onChange = (id: string) => {
    answerSubquestion({ id: questionId, answerId: id }, { id: questionId, answerId: correctAnswer });
  };

  return (
    <SingleChoiceContainer>
      {choices.map((choice, index) => (
        <Choice onClick={() => onChange(choice.id)} key={index}>
          <Radio
            checked={isReviewing ? correctAnswer === choice.id : answer?.answerId === choice.id}
            value={choice.id}
            //onChange={() => onChange(choice.id)}
            disabled={isDone}
          />
          <ContentRenderer content={choice.detail.content} />
        </Choice>
      ))}
    </SingleChoiceContainer>
  );
};

export default SingleChoice;
