import { RadialBackground } from '../Auth/styled';
import { PageWrapper } from '../StudySpace/styled';
import { ReactComponent as SandClock } from '../../images/icons/dashboard/sandClock.svg';
import { en } from '../../i18n';
import Button from '../components/Button';
import { useHistory } from 'react-router';
const { comingSoon } = en;

import { ComingSoonContainer, ComingSoonContent, ComingSoonTitle } from './styled';
import { dashboardPath } from '../Router/paths';

const ComingSoon = () => {
  const history = useHistory();
  const onClick = () => {
    history.push(dashboardPath);
  };
  return (
    <PageWrapper>
      <RadialBackground>
        <ComingSoonContainer>
          <ComingSoonContent>
            <ComingSoonTitle>{comingSoon.title}</ComingSoonTitle>
            <SandClock />
            <p>{comingSoon.subTitle}</p>

            <Button onClick={onClick} fullWidth variant="primary">
              {comingSoon.button}
            </Button>
          </ComingSoonContent>
        </ComingSoonContainer>
      </RadialBackground>
    </PageWrapper>
  );
};

export default ComingSoon;
