import TabContent from '../Components/TabContent';
import { en } from '../../../i18n';
import { useMembershipProxy, useNavigations, usePastQuizzes } from '../../hooks';
import { IAttempt } from '../../types';
import { useSelector } from 'react-redux';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import { format } from 'date-fns';
import {
  QuizCard,
  ContinueQuizContent,
  ContinueQuizLeftContent,
  ButtonContainer,
  PastQuizzesContainer,
  ContinueTitleContainer,
  ContinueContentTitle,
} from './styled';

import CircularProgressBar from '../../components/CircularProgressBar';

import Button from '../../components/Button';

import { NoQuizzes, NoQuizzesTextDisclaimer } from '../ReviewInsigts/styled';

const { dashboard } = en;
const { continueTitle, button, continueButtonTitle } = dashboard.practiceQuiz;
const { pastQuizzes } = en.dashboard;
const { noTopicsSubTitle } = dashboard.analyze;

const ContinueQuiz = () => {
  const { attempts } = usePastQuizzes();

  const { continueQuizButtons } = useMembershipProxy();
  const { buttonDisabled, buttonDisabledText } = continueQuizButtons.run();
  const { navigateToContinueQuiz, navigateToMembershipUnconditional, navigateToStudySpace } = useNavigations();

  const { entities: topics } = useSelector(topicsSelectors.allState);

  const incompleteAttempts = attempts?.filter(attempt => !attempt.isDone);

  if (!incompleteAttempts || incompleteAttempts.length === 0) {
    return (
      <TabContent title={continueTitle}>
        <NoQuizzes>
          <NoQuizzesTextDisclaimer>
            <h1>{pastQuizzes.notFoundMessage}</h1>
            <h2>{noTopicsSubTitle}</h2>
          </NoQuizzesTextDisclaimer>
        </NoQuizzes>
      </TabContent>
    );
  }

  const getTopics = (attempt: IAttempt) => {
    const titles = attempt.topicIDs.map(topicId => topics[topicId]?.name);
    const topicsStringified = titles.slice(0, 3).join(', ');
    if (titles.length > 3) {
      const remainingTopics = titles.length - 3;
      return `${topicsStringified} ${pastQuizzes.andMore(remainingTopics)}`;
    }
    return topicsStringified;
  };

  const renderCompleteButton = (attempt: IAttempt) => {
    if (attempt.isDone) return null;
    if (buttonDisabled)
      return (
        <Button size="small" variant="secondary" onClick={navigateToMembershipUnconditional}>
          {buttonDisabledText}
        </Button>
      );
    return (
      <Button size="small" variant="secondary" onClick={() => navigateToContinueQuiz(attempt)}>
        {button}
      </Button>
    );
  };

  const renderPastQuizzes = () => {
    return incompleteAttempts.slice(0, 1).map((attempt, index) => {
      const date = attempt.ctime ? new Date(attempt.ctime) : new Date();
      const formattedDate = format(date, "MMM d, yyyy 'at' H:mm a");
      return (
        <QuizCard applyFlex hideCursor key={index}>
          <ContinueQuizContent>
            <ContinueQuizLeftContent>
              <CircularProgressBar progress={attempt.isDone ? 100 : attempt.progress.percentage} />
              <div>
                <h3>{formattedDate}</h3>
                <h2>{attempt.title}</h2>
                <span>{getTopics(attempt)}</span>
              </div>
            </ContinueQuizLeftContent>
            <ButtonContainer>{renderCompleteButton(attempt)}</ButtonContainer>
          </ContinueQuizContent>
        </QuizCard>
      );
    });
  };

  return (
    <TabContent>
      <ContinueTitleContainer>
        <ContinueContentTitle>{continueTitle}</ContinueContentTitle>
        <Button onClick={() => navigateToStudySpace(true, false, true)} variant="secondary">
          {continueButtonTitle}
        </Button>
      </ContinueTitleContainer>
      <PastQuizzesContainer>{renderPastQuizzes()}</PastQuizzesContainer>
    </TabContent>
  );
};

export default ContinueQuiz;
