import { EGoogleTags } from '../types';
import TagManager from 'react-gtm-module';
import CurrentProfile from './CurrentProfile';
import ProductionDomain from './Domains';

interface IUserCustomPayload {
  userEmail: string;
}
class GoogleTags {
  private tag: EGoogleTags;
  private customPayload?: IUserCustomPayload;

  constructor(tag: EGoogleTags, customPayload?: IUserCustomPayload) {
    this.tag = tag;
    this.customPayload = customPayload;
  }

  private buildEvent() {
    const currentProfile = CurrentProfile.getProfile();
    return {
      dataLayer: {
        event: this.tag,
        userEmail: currentProfile?.attributes?.email || this.customPayload?.userEmail,
        userID: currentProfile?.attributes?.sub,
      },
    };
  }

  public fireEvent() {
    try {
      const event = this.buildEvent();
      console.log(event.dataLayer.event);
      if (!ProductionDomain.isProductionDomain()) return;
      TagManager.dataLayer(event);
    } catch (error: any) {
      console.log('Could not send google tag event');
    }
  }
}

export default GoogleTags;
