import React from 'react';
import { Boundary, Title, SubTitleA, SubTitleB } from './styled';
import { en } from '../../i18n';
import Bugsnag from '@bugsnag/js';

const { errorBoundary } = en;

const { title, subTitleA, subTitleB, buttonText } = errorBoundary;
import { ReactComponent as ErrorMessage } from '../../images/icons/errorMessage.svg';
import { ReactComponent as ErrorIcon } from '../../images/icons/error.svg';
import Header from '../../Header';
import Button from '../components/Button';
import paths from '../Router/paths';
import { BugSnag, CurrentProfile } from '../classes';
import ProductionDomain from '../classes/Domains';

class ErrorBoundary extends React.Component {
  state = { isError: false };
  static getDerivedStateFromError(error: Error) {
    return { isError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    new BugSnag().notify(error);
  }

  render() {
    if (this.state.isError)
      return (
        <>
          <Header isLoggedIn={false} isAuthorized={false} />
          <Boundary>
            <SubTitleA> {subTitleA}</SubTitleA>
            <SubTitleB>
              {subTitleB}&nbsp;<a href="mailto:connect@smartn.ca">connect@smartn.ca</a>
            </SubTitleB>
            <a href={CurrentProfile.getToken() ? paths.private.dashboard.path : paths.public.login.path}>
              <Button onClick={() => {}} size="medium" variant="primary">
                {buttonText}
              </Button>
            </a>
          </Boundary>
        </>
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
