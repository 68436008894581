//checked={value === id} value={id}
import { RadioChoiceItem, RadioChoiceContainer } from './styled';
import Radio from '@mui/material/Radio';
import { IQuestion } from '../types';
import TemplateRenderer from '../SmartQuiz/question/templates';

interface IRadioChoice {
  question?: IQuestion;
  value?: string;
  onSelectionChange: (id: string) => void;
}

const RadioChoices = ({ question, value, onSelectionChange }: IRadioChoice) => {
  if (!question) return null;
  const { choices } = question.content;
  if (!choices) return null;

  const renderChoices = () =>
    choices.map((choice, index) => {
      const { content, id } = choice;
      return (
        <RadioChoiceItem key={index} onClick={() => onSelectionChange(id)}>
          <Radio checked={id === value} />

          {content.choice && <TemplateRenderer content={content.choice} />}
        </RadioChoiceItem>
      );
    });

  return <RadioChoiceContainer>{renderChoices()}</RadioChoiceContainer>;
};

export default RadioChoices;
