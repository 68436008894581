import React, { forwardRef, useState, useEffect } from 'react';
import { StyledCell, IStyledCell } from './styled';

interface ICell extends IStyledCell {
  onChange: (value: string) => void;
  onFocus: () => void;
  loading?: boolean;
  goBackPreviousIndex: () => void;
  pasteCode: (code: string | number) => void;
  externalValue?: string;
}
const Cell = (
  { onChange: onChangeExternal, onFocus, error, loading, goBackPreviousIndex, pasteCode, externalValue }: ICell,
  ref: any,
) => {
  const [value, setValue] = useState('');
  const [isChangedAfterError, setChangedAfterError] = useState(false);

  useEffect(() => {
    if (externalValue && !(value === externalValue)) setValue(externalValue);
  }, [externalValue]);

  useEffect(() => {
    if (error && !isChangedAfterError) {
      setChangedAfterError(false);
    }
  }, [error]);

  // This hack is done instead of setting maxLength=1 to the input
  // to allow users to change the input without deleting
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value ? e.target.value.replace(value, '') : '';
    setValue(nextValue);
    onChangeExternal(nextValue);
    if (error) {
      setChangedAfterError(true);
    }
  };

  const checkBackSpace = (key: any) => {
    if (key.code === 'Backspace' && !value) goBackPreviousIndex();
  };

  const onPaste = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const pastedData = e.clipboardData.getData('Text');
    pasteCode(pastedData);
  };
  return (
    <StyledCell
      onKeyDown={checkBackSpace}
      ref={ref}
      type="text"
      inputMode="numeric"
      pattern="\d{1}"
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      error={error && !isChangedAfterError}
      autoComplete="new-password"
      readOnly={loading}
      onPaste={onPaste}
    />
  );
};

export default forwardRef(Cell);
