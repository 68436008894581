import Button from '../../components/Button';
import CustomTooltip from '../../components/Tooltip';
import useControlButtons, { IUseControlButtons } from './useControlButtons';
import { en } from '../../../i18n';

const { proceedTooltip } = en.smartQuiz;

const ControlButtons = ({ data, flowOverrides }: IUseControlButtons) => {
  const buttonsData = useControlButtons({ data, flowOverrides });
  const { isCheckAnswerAllowed } = data.state;

  const renderButtons = () =>
    buttonsData.map((buttonData, index) => {
      if (!buttonData.text) return;
      const button = (
        <Button key={index} {...buttonData.props}>
          {buttonData.text}
        </Button>
      );
      if (!buttonData.hasToolTip) return button;
      return (
        <CustomTooltip
          key={`${index}-customToolTip`}
          centerText
          width="100%"
          arrow={false}
          title={isCheckAnswerAllowed ? '' : proceedTooltip}
          placement="top"
        >
          {button}
        </CustomTooltip>
      );
    });

  return <>{renderButtons()}</>;
};

export default ControlButtons;
