import { IAttempt, IAttemptQuestion, ILooseObject, IQuestionAnswersState } from '../../types';
import { getCaseStudyLength } from '../../utils';
import PopulateAnswers from '../PopulateAnswers';
import AllAttemptsTransformer from './AllAttemptsTransformer';

class LiveAttemptTransformer extends AllAttemptsTransformer {
  public constructor(private questionAnswers: IQuestionAnswersState) {
    super();
  }

  public markQuestionAndTrackProgress(question: IAttemptQuestion, attempt: IAttempt) {
    const { response: storedResponse } = question;

    if (storedResponse) return super.markQuestionAndTrackProgress(question, attempt);

    const response = this.questionAnswers[question.questionId];

    if (!response) {
      attempt.progress.totalQuestions += 1;
      return;
    }

    const { parsedMark, subAnswers } = response;

    if (subAnswers) {
      Object.values(subAnswers).forEach(subAnswer => {
        const { isDone, parsedMark } = subAnswer;
        if (isDone) attempt.progress.answeredQuestions += 1;
        attempt.progress.totalMark += parsedMark?.mark || 0;
        attempt.progress.totalMaximumMark += parsedMark?.maximumMark || 0;
      });
      question.subAnswers = subAnswers;
      attempt.progress.totalQuestions += getCaseStudyLength(question.questionId);
      return;
    }
    question.parsedMark = parsedMark;
    attempt.progress.totalQuestions += 1;
    attempt.progress.answeredQuestions += 1;
    attempt.progress.totalMark += parsedMark?.mark || 0;
    attempt.progress.totalMaximumMark += parsedMark?.maximumMark || 0;
  }

  public mapQuestionAnswersToAttempt(attempt: IAttempt) {
    attempt.questions.forEach(question => {
      if (question.response) return;
      const questionAnswer = this.questionAnswers[question.questionId];
      question.ctime = questionAnswer?.ctime;
      //question.response = questionAnswer.value;
      //question.isCorrect = questionAnswer.isCorrect;
    });
  }

  public setAttemptCurrentQuestionInfo(attempt: IAttempt) {
    const populateAnswers = new PopulateAnswers({}, attempt, true);
    attempt.currentQuestionInfo = populateAnswers.getCurrentQuestion();
    const { questionIndex, isDone } = attempt.currentQuestionInfo;
    attempt.isDone = isDone && questionIndex === attempt.questions.length - 1;
  }
}

export default LiveAttemptTransformer;
