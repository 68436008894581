import Modal from './Modal';
import { useState } from 'react';
import { en } from '../../../i18n';
import { PrivacyPolicyText, MinimalText, MinimalTextContainer } from './styled';

const { signUp } = en;
const { and, form } = signUp;
const { agreement, termsOfUse, privacyPolicy, privacyPolicyMinimal, termsOfUseMinimal } = form;
interface IUserAgreement {
  variant?: 'full' | 'minimal';
}
const UserAgreements = ({ variant = 'full' }: IUserAgreement) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsAndAgreementsModalOpen, setIsTermsAndAgreementsModalOpen] = useState(false);

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };
  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };
  const openTermsAndAgreementsModal = () => {
    setIsTermsAndAgreementsModalOpen(true);
  };
  const closeTermsAndAgreementsModal = () => {
    setIsTermsAndAgreementsModalOpen(false);
  };

  const renderText = () => {
    if (variant == 'full')
      return (
        <>
          {agreement} <a onClick={openTermsAndAgreementsModal}>{termsOfUse}</a> {and}{' '}
          <a onClick={openPrivacyModal}>{privacyPolicy}</a>
        </>
      );

    return (
      <MinimalText>
        {' '}
        <span onClick={openTermsAndAgreementsModal}>{termsOfUseMinimal}</span>{' '}
        <span onClick={openPrivacyModal}>{privacyPolicyMinimal}</span>
      </MinimalText>
    );
  };
  const Container = variant === 'full' ? PrivacyPolicyText : MinimalTextContainer;
  return (
    <Container className="user-agreements">
      <Modal onClose={closePrivacyModal} type="privacyPolicy" id="privacy-policy" isModalOpen={isPrivacyModalOpen} />
      <Modal
        onClose={closeTermsAndAgreementsModal}
        type="termsAndAgreements"
        id="terms-agreements"
        isModalOpen={isTermsAndAgreementsModalOpen}
      />
      {renderText()}
    </Container>
  );
};

export default UserAgreements;
