import { EFieldTypes, TFieldExternal } from '../components/Form';
import { en } from '../../i18n';

const { form, signUp, resetPassword } = en;

const { passwordHelper } = signUp.form;

const fieldConfigs: { [key: string]: TFieldExternal } = {
  email: {
    label: form.emailLabel,
    type: EFieldTypes.EMAIL,
    required: true,
  },

  password: {
    type: EFieldTypes.PASSWORD,
    label: form.passwordLabel,
    required: true,
  },
  fullName: {
    required: true,
  },
  passwordConfirmation: {
    type: EFieldTypes.PASSWORD_CONFIRMATION,
    helperText: passwordHelper,
    required: true,
  },

  inputCode: {
    type: EFieldTypes.CODE_INPUT,
    inputLength: 6,
    submitAutomatically: true,
    outputFormat: 'text',
    errorText: resetPassword.error,
  },
  userName: {
    label: form.usernameLabel,
    required: true,
    applyValidationRules: ['userNameValidation'],
  },
};

enum EAWSErrorCodes {
  UserNotConfirmedException = 'UserNotConfirmedException',
  InvalidForgetPasswordCode = 'CodeMismatchException',
  newPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  resourceNotFoundException = 'ResourceNotFoundException',
}

export { fieldConfigs, EAWSErrorCodes };
