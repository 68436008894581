import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalSelector } from '../../redux/globalSlice';
import {
  IQuizState,
  IAttempt,
  IQuestion,
  IQuestionAnswers,
  IQuestionAnswersState,
  EQuizTypes,
  ILooseObject,
} from '../../types';
import { RootState } from '../../redux/store';
import { topicsSelectionQuestion, firstNameQuestionId, roleQuestionId } from '../../OnBoarding/constants';
import { cloneDeep } from 'lodash';

const SLICE_NAME = 'quiz';

const initialState: IQuizState = {
  isStarted: false,
  questions: {},
  currentQuestionIndex: -1,
  currentDisplayedQuestionIndex: 0,
  questionAnswers: {},
  loadingAttempts: false,
  loadingQuestion: false,
  isViewingResults: false,
  transformingAttempt: false,
  loadingStartQuiz: false,
  loadingOnBoardingAttempts: false,
  loadingPlacementAttempts: false,
  loadingAttemptsPage: false,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    createQuiz: (state, action: PayloadAction<{ groupIds: string[]; questionTypes: EQuizTypes[] }>) => {
      state.isViewingResults = false;
      state.loadingStartQuiz = true;
      state.currentAttempt = undefined;
      state.questionAnswers = {};
    },
    createQuizSuccess: (state, action: PayloadAction<{ quizId: string }>) => {
      const { quizId } = action.payload;
      state.quizId = quizId;
    },
    startQuizSuccess: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      const { attempt } = action.payload;
      state.currentAttempt = attempt;
      state.isStarted = true;
      state.loadingStartQuiz = false;
      state.attempts?.unshift(attempt);
    },
    resetState: state => initialState,
    quizAgain: state => ({
      ...initialState,
      attempts: state.attempts,
      currentAttempt: state.currentAttempt,
      proficiency: state.proficiency,
      placementAttempts: state.placementAttempts,
      onBoardingAttempts: state.onBoardingAttempts,
    }),
    fetchQuestion: (state, action: PayloadAction<{ questionId: string }>) => {
      state.loadingQuestion = true;
    },

    fetchQuestionSuccess: (state, action: PayloadAction<{ question: IQuestion; questionId: string }>) => {
      const { question, questionId } = action.payload;
      state.currentQuestionId = questionId;
      state.isStarted = true;
      state.currentQuestionIndex = state.currentAttempt?.questions.findIndex(q => q.questionId === questionId) || 0;
      state.questions[questionId] = { ...question, id: questionId };
      state.loadingQuestion = false;
    },

    submitAnswer: (
      state,
      action: PayloadAction<{ attemptId: string; questionId: string; response: string[] | string }>,
    ) => {
      state.loadingAnswer = true;
    },

    submitAnswerSuccess: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
      state.loadingAnswer = false;
    },

    setQuestionAnswer: (state, action: PayloadAction<{ questionId: string; response: IQuestionAnswers }>) => {
      const { questionId, response } = action.payload;
      state.questionAnswers = { ...state.questionAnswers, [questionId]: response };
    },

    submitSubAnswer: (
      state,
      action: PayloadAction<{
        attemptId: string;
        questionId: string;
        response: ILooseObject[];
        caseStudySubquestionId: string;
      }>,
    ) => {
      state.loadingAnswer = true;
    },

    submitSubAnswerSuccess: (state, action: PayloadAction<any>) => {
      const { questionId, caseStudySubquestionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: {
          ...state.questionAnswers[questionId],
          subAnswers: {
            ...state.questionAnswers[questionId].subAnswers,
            [caseStudySubquestionId]: {
              ...state.questionAnswers[questionId].subAnswers?.[caseStudySubquestionId],
              isDone: true,
            },
          },
        },
      };
      state.caseStudySubquestionNewId = caseStudySubquestionId;
      state.loadingAnswer = false;
    },

    getAttempt: () => {},
    fetchAttempts: (state, action: PayloadAction<{ earliest?: string; showAll?: boolean; page?: number }>) => {
      const { showAll, page } = action.payload;
      if (showAll) state.loadingAttempts = true;
      else {
        state.loadingAttemptsPage = true;
        if (page === 1) state.attempts = [];
      }
    },
    fetchAttemptsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[]; showAll?: boolean }>) => {
      const { attempts, showAll } = action.payload;
      if (showAll) {
        state.attempts = attempts;
        state.currentAttempt = attempts[0];
        state.loadingAttempts = false;
      } else {
        state.attempts = state.attempts?.concat(attempts);
        state.loadingAttemptsPage = false;
      }
    },
    fetchAttempt: (state, action: PayloadAction<{ attemptId: string }>) => {
      state.loadingAttempts = true;
    },
    fetchAttemptSuccess: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      const { attempt } = action.payload;
      state.attempts = [attempt];
      state.currentAttempt = attempt;
      state.loadingAttempts = false;
    },

    setQuestions: (state, action: PayloadAction<{ questions: { [key: string]: IQuestion } }>) => {
      state.questions = action.payload.questions;
    },

    setQuestionAnswers: (state, action: PayloadAction<{ questionAnswers: IQuestionAnswersState }>) => {
      state.questionAnswers = action.payload.questionAnswers;
    },

    setCurrentQuestion: (
      state,
      action: PayloadAction<{ questionId: string; currentQuestionIndex: number; currentDisplayeQuestionIndex: number }>,
    ) => {
      const { questionId, currentQuestionIndex, currentDisplayeQuestionIndex } = action.payload;
      state.currentQuestionId = questionId;
      state.currentQuestionIndex = currentQuestionIndex;
      state.currentDisplayedQuestionIndex = currentDisplayeQuestionIndex;
      state.isStarted = true;
    },
    setCurrentAttempt: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      const { attempt } = action.payload;
      state.currentAttempt = attempt;
    },
    setCurrentAttemptNoSideEffect: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      const { attempt } = action.payload;
      state.currentAttempt = attempt;
    },
    viewResults: state => {
      state.isViewingResults = true;
    },

    setViewResults: (state, action: PayloadAction<{ isViewingResults: boolean }>) => {
      state.isViewingResults = action.payload.isViewingResults;
    },

    markAsDoneOnboarding: (
      state,
      action: PayloadAction<{
        attempt: IAttempt;
        answers: (ILooseObject | string | undefined)[];
        questionIds: string[];
      }>,
    ) => {
      const { attempt, answers, questionIds } = action.payload;
      const targetAttemptIndex = state.attempts?.findIndex(attemptItem => attemptItem.attemptId === attempt.attemptId);
      if (state.attempts && targetAttemptIndex !== undefined && targetAttemptIndex >= 0) {
        state.attempts[targetAttemptIndex].isDone = true;
      }
      let targetOnBoardingAttemptIndex = state.onBoardingAttempts?.findIndex(
        attemptItem => attemptItem.attemptId === attempt.attemptId,
      );
      if (state.onBoardingAttempts && targetOnBoardingAttemptIndex !== undefined && targetOnBoardingAttemptIndex >= 0) {
        state.onBoardingAttempts[targetOnBoardingAttemptIndex].isDone = true;
      } else {
        const attemptClone = cloneDeep(attempt);
        state.onBoardingAttempts = [attemptClone].concat(state.onBoardingAttempts || []);
        state.onBoardingAttempts[0].isDone = true;
        targetOnBoardingAttemptIndex = 0;
      }
      const topicsSelectionQuestionIndex = attempt.questions.findIndex(
        question => question.questionId === topicsSelectionQuestion,
      );
      const firstNameQuestionIndex = attempt.questions.findIndex(
        question => question.questionId === firstNameQuestionId,
      );
      const roleQuestionIndex = attempt.questions.findIndex(question => question.questionId === roleQuestionId);
      const topicsSelectionAnswer =
        answers[questionIds.findIndex(questionId => questionId === topicsSelectionQuestion)];
      const firstNameAnswer = answers[questionIds.findIndex(questionId => questionId === firstNameQuestionId)];
      const roleAnswer = answers[questionIds.findIndex(questionId => questionId === roleQuestionId)];
      state.onBoardingAttempts[targetOnBoardingAttemptIndex].questions[
        topicsSelectionQuestionIndex
      ].response = JSON.stringify(topicsSelectionAnswer);
      state.onBoardingAttempts[targetOnBoardingAttemptIndex].questions[
        firstNameQuestionIndex
      ].response = JSON.stringify(firstNameAnswer);
      state.onBoardingAttempts[targetOnBoardingAttemptIndex].questions[roleQuestionIndex].response = JSON.stringify(
        roleAnswer,
      );
    },

    markAsDoneOnboardingPartOne: (
      state,
      action: PayloadAction<{
        attempt: IAttempt;
        answers: (ILooseObject | string | undefined)[];
        questionIds: string[];
      }>,
    ) => {
      const { attempt, answers, questionIds } = action.payload;
      let targetOnboardingAttempt: IAttempt;
      const targetOnBoardingAttemptIndex = state.onBoardingAttempts?.findIndex(
        attemptItem => attemptItem.attemptId === attempt.attemptId,
      );
      if (state.onBoardingAttempts && targetOnBoardingAttemptIndex !== undefined && targetOnBoardingAttemptIndex >= 0) {
        targetOnboardingAttempt = state.onBoardingAttempts[targetOnBoardingAttemptIndex];
      } else {
        const attemptClone = cloneDeep(attempt);
        state.onBoardingAttempts = [attemptClone].concat(state.onBoardingAttempts || []);
        targetOnboardingAttempt = state.onBoardingAttempts[0];
      }
      questionIds.forEach((questionId, index) => {
        const answer = answers[index] || '_';
        const question = targetOnboardingAttempt.questions.find(question => question.questionId === questionId);
        if (question) question.response = JSON.stringify(answer);
      });
    },

    attemptQuiz: (state, action: PayloadAction<{ quizId: string }>) => {
      state.questionAnswers = {};
      state.questions = {};
      state.currentAttempt = undefined;
      state.isViewingResults = false;
      state.loadingAttempts = true;
      state.quizId = action.payload.quizId;
    },

    attemptQuizSuccess: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      state.loadingAttempts = false;
      state.currentAttempt = action.payload.attempt;
      state.attempts = [action.payload.attempt, ...(state.attempts || [])];
    },

    setLoadingAttempts(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.loadingAttempts = action.payload.isLoading;
    },

    markAsDonePlacement: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      const { attempt } = action.payload;
      const targetAttemptIndex = state.attempts?.findIndex(attemptItem => attemptItem.attemptId === attempt.attemptId);
      if (state.attempts && targetAttemptIndex !== undefined && targetAttemptIndex >= 0) {
        state.attempts[targetAttemptIndex].isDone = true;
      }
      const targetPlacementAttemptIndex = state.placementAttempts?.findIndex(
        attemptItem => attemptItem.attemptId === attempt.attemptId,
      );
      if (state.placementAttempts && targetPlacementAttemptIndex !== undefined && targetPlacementAttemptIndex >= 0) {
        state.placementAttempts[targetPlacementAttemptIndex].isDone = true;
      } else {
        state.placementAttempts = [attempt].concat(state.placementAttempts || []);
        state.placementAttempts[0] = { ...state.placementAttempts[0], isDone: true };
      }
    },

    incrementDisplayedIndex: state => {
      state.currentDisplayedQuestionIndex += 1;
    },

    decrementDisplayedIndex: state => {
      state.currentDisplayedQuestionIndex -= 1;
    },

    setCurrentDisplayedIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.currentDisplayedQuestionIndex = action.payload.index;
    },

    fetchOnBoardingAttempts: state => {
      state.loadingOnBoardingAttempts = true;
    },
    fetchOnBoardingAttemptsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      const { attempts } = action.payload;
      state.onBoardingAttempts = attempts;
      state.loadingOnBoardingAttempts = false;
    },

    fetchPlacementAttempts: state => {
      state.loadingPlacementAttempts = true;
    },

    fetchPlacementAttemptsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      const { attempts } = action.payload;
      state.placementAttempts = attempts;
      state.loadingPlacementAttempts = false;
    },

    setProficiency: (state, action: PayloadAction<{ proficiency: ILooseObject }>) => {
      const { proficiency } = action.payload;
      state.proficiency = proficiency;
    },
    fetchProficiency: () => {},
  },
});

const quizSelectors = {
  allState: (state: RootState): typeof initialState => state.quiz,
  globalSelector: globalSelector(SLICE_NAME),
};

const actions = { ...slice.actions };

export { quizSelectors, SLICE_NAME, actions };
export default slice.reducer;
