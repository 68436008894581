import MembershipInfo from './MembershipInfo';
import {
  FormContainer,
  FormTitleBottomMargin,
  Title,
  PaymentMethodInfoContainer,
  PaymentMethodInfoTitle,
  PaymentMethodInfoTitleContainer,
  CardTitle,
  CardExpiry,
  ErrorMessage,
  ErrorIconWrapper,
  NoPaymentMethod,
} from './styled';
import { en } from '../../../i18n';
import usePaymentForm from '../hooks/usePaymentForm';
import { humanizeNumber } from '../../utils';
import Button from '../../components/Button';
import { ReactComponent as Visa } from '../../../images/icons/membership/visa.svg';
import { ReactComponent as Amex } from '../../../images/icons/membership/amex.svg';
import { ReactComponent as Diners } from '../../../images/icons/membership/diners.svg';
import { ReactComponent as Generic } from '../../../images/icons/membership/generic.svg';
import { ReactComponent as JCB } from '../../../images/icons/membership/jcb.svg';
import { ReactComponent as MasterCard } from '../../../images/icons/membership/mastercard.svg';
import { ECardBrands, EPaymentSteps } from '../../types';
import CardSection from './CardSection';
import Message from '../../components/Message';
import { ReactComponent as ErrorIcon } from '../../../images/icons/errorMessage.svg';
import ContentManager from './classes/ContentManager';

const {
  paymentForm: {
    title,
    titlePayment,
    paymentMethodButton,
    paymentMethodChanged,
    paymentMethodChangedWithPaymentIssue,
    noPaymentMethodAvailable,
  },
} = en.billing;

const MembershipForm = ({ control, state }: ReturnType<typeof usePaymentForm>) => {
  const { currentSubscription, formState, errors, isPaymentMethodChanged, isPaymentIssue, stripePlan } = state;

  if (!currentSubscription) return null;

  const { paymentMethod } = currentSubscription;
  const {
    onSumbitChangePaymentMethod,
    onToggleEdit,
    handleChange,
    handleCountryChange,
    handleAddressFieldChange,
    getCurrentState,
    onModalClick,
    onChangeCVC,
    onChangeCard,
    onChangeExpiry,
  } = control;

  const contentManager = new ContentManager(stripePlan, getCurrentState());

  const getCardLogo = () => {
    if (!paymentMethod) return <Generic />;
    const { brand } = paymentMethod;
    switch (brand) {
      case ECardBrands.VISA:
        return <Visa />;
      case ECardBrands.AMEX:
        return <Amex />;
      case ECardBrands.DINERS:
        return <Diners />;
      case ECardBrands.MASTER_CARD:
        return <MasterCard />;
      case ECardBrands.JCB:
        return <JCB />;
      default:
        return <Generic />;
    }
  };

  const renderContent = () => {
    if (getCurrentState() === EPaymentSteps.EDIT_CARD)
      return (
        <CardSection
          handleChange={handleChange}
          formState={formState}
          errors={errors}
          handleAddressFieldChange={handleAddressFieldChange}
          handleCountryChange={handleCountryChange}
          onChangeCVC={onChangeCVC}
          onChangeCard={onChangeCard}
          onChangeExpiry={onChangeExpiry}
        />
      );
    if (!paymentMethod) return <NoPaymentMethod>{noPaymentMethodAvailable}</NoPaymentMethod>;
    return (
      <>
        <FormTitleBottomMargin>{titlePayment}</FormTitleBottomMargin>
        <PaymentMethodInfoContainer isError={isPaymentIssue}>
          <PaymentMethodInfoTitle>
            <PaymentMethodInfoTitleContainer>
              {getCardLogo()}
              <PaymentMethodInfoTitle>
                <CardTitle>
                  <span>**** **** ****</span> {paymentMethod.last4}
                </CardTitle>
                <CardExpiry>
                  Expires {humanizeNumber(paymentMethod.expMonth)}/{paymentMethod.expYear}
                </CardExpiry>
              </PaymentMethodInfoTitle>
            </PaymentMethodInfoTitleContainer>
          </PaymentMethodInfoTitle>

          <Button onClick={onToggleEdit} variant="underlined">
            {paymentMethodButton}
          </Button>

          {isPaymentIssue && (
            <ErrorIconWrapper>
              <ErrorIcon />
            </ErrorIconWrapper>
          )}
        </PaymentMethodInfoContainer>
      </>
    );
  };

  return (
    <FormContainer onClick={onModalClick} onSubmit={onSumbitChangePaymentMethod} id="payment-method-form">
      <Title>{contentManager.content.formTitle()}</Title>
      {isPaymentMethodChanged && (
        <Message variant="success">
          {isPaymentIssue ? paymentMethodChangedWithPaymentIssue : paymentMethodChanged}
        </Message>
      )}
      <MembershipInfo stripePlan={stripePlan} currentState={getCurrentState()} />
      {renderContent()}
      {errors?.global && <ErrorMessage>{errors.global}</ErrorMessage>}
    </FormContainer>
  );
};

export default MembershipForm;
