import { styled } from '@mui/material/styles';
import { Feature, FeaturesContainer, UpgradeMoreContainer } from '../../SmartQuiz/results/unSubscribedStyled';
import { colors } from '../../../themes';

const UpgradeMoreContainerDashboard = styled(UpgradeMoreContainer)`
  background: white;
  height: auto;
  //   width: 100%;
  margin: 0;
  width: min(576px, 40vw);
  box-sizing: border-box;
  padding: ${props => props.theme.spacing(5)};

  ${props => props.theme.breakpoints.down('lg')} {
    margin-top: 30px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    margin-top: 30px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: 90%;
    h1 {
      font-size: 1.15rem;
    }
  }
`;

const FeaturesContainerDashboard = styled(FeaturesContainer)`
  h3 {
    margin: 0;
  }
  gap: ${props => props.theme.spacing(2)};
`;

const FeatureDashboard = styled(Feature)`
  font-weight: 700;
  span {
    font-weight: 700;
  }
`;

const ButtonsContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(3)};
`;

const NurseGPTModalOverlay = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 24, 24, 0.4);
`;

const NurseGPTModalContainer = styled('div')`
  width: 45%;
  max-width: 650px;
  background-color: ${colors.white};
  padding: 40px;
  border-radius: 16px;
  textarea {
    outline: 1px solid ${colors.mediumGray};
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 1rem;
    color: ${colors.black};
    margin-bottom: 20px;
    ::placeholder {
      color: ${colors.grey400};
      opacity: 1;
    }
    :hover {
      outline: 1px solid ${colors.purple30};
    }
    :focus {
      outline: 2px solid ${colors.purple30};
    }
  }
  > button {
    display: block;
    margin: auto;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 70%;
  }
`;

const NurseGPTModalTitle = styled('h3')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0;
  color: ${colors.black};
`;

const NurseGPTModalSummary = styled('div')`
  font-size: 1rem;
  color: ${colors.black};
  line-height: 24px;
  margin-bottom: 30px;
  font-family: OpenSans-Google !important;
  font-weight: 600;
`;

const NurseGPTModalQuestion = styled('div')`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 20px;
`;

const SpinnerContainer = styled('div')`
  div {
    position: static;
  }
`;

export {
  UpgradeMoreContainerDashboard,
  FeaturesContainerDashboard,
  FeatureDashboard,
  ButtonsContainer,
  NurseGPTModalOverlay,
  NurseGPTModalContainer,
  NurseGPTModalTitle,
  NurseGPTModalSummary,
  SpinnerContainer,
  NurseGPTModalQuestion,
};
