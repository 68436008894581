import React from 'react';

interface ILogicFunctions {
  navigateToMembership: () => void;
  navigateToReadiness: (e: any) => void;
}
interface IBaseContent {
  functions: ILogicFunctions;
}

abstract class BaseContent {
  public functions: ILogicFunctions;
  constructor(options: IBaseContent) {
    this.functions = options.functions;
  }

  public abstract body(): string | React.ReactNode;
  public abstract buttonText(): string;
  public abstract logicFunction(): (e?: any) => void;
}

export default BaseContent;
