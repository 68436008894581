import { FreeUserBannerContainer, FreeUserBannerWrapper, LargeWhiteButton } from '../Home/styled';
import { en } from '../../i18n';
import { membershipPath } from '../Router/paths';

const { buttonText, text } = en.dashboard.freeBanner;

const FreeUserBanner = () => {
  return (
    <FreeUserBannerWrapper>
      <FreeUserBannerContainer>
        <span>
          <LargeWhiteButton link={membershipPath} variant="underlined">
            {buttonText}
          </LargeWhiteButton>
          &nbsp;{text}
        </span>
      </FreeUserBannerContainer>
    </FreeUserBannerWrapper>
  );
};

export default FreeUserBanner;
