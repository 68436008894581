import Button from '../../components/Button';
import {
  UpgradeMoreContainer as UpgradeMoreContainerDefault,
  UpgradeTitleText,
  FeaturesContainer as FeaturesContainerDefault,
  Feature as FeatureDefault,
  ButtonContainer,
  SubTitleA,
  SubTitleB,
} from './unSubscribedStyled';
import { en } from '../../../i18n';
import { ReactComponent as RaiseHand } from '../../../images/icons/placement/raiseHand.svg';
import { ReactComponent as Search } from '../../../images/icons/placement/search.svg';
import { ReactComponent as Arrow } from '../../../images/icons/placement/arrow.svg';
import usePropsOverride from '../../hooks/usePropsOverride';
import { useNavigations } from '../../hooks';
import SmartNText from '../../components/SmartnText';

const icons = [RaiseHand, RaiseHand, Search, Arrow];

const { placementTest } = en;

const { upgradeInfo, info: infoDefault, buttonText } = placementTest;

interface IUpgradeInfoOverrides {
  header?: string;
  subTitleA?: string;
  subTitleB?: string;
  info?: typeof infoDefault[0][];
  isTrialing?: boolean;
  UpgradeMoreContainer?: React.ElementType<any>;
  FeaturesContainer?: React.ElementType<any>;
  Feature?: React.ElementType<any>;
  showFeatures?: boolean;
  centerButton?: boolean;
}
const UpgradeInfo = (props: IUpgradeInfoOverrides) => {
  const {
    header = upgradeInfo.trialling.header,
    subTitleA = upgradeInfo.trialling.subTitleA,
    subTitleB = upgradeInfo.trialling.subTitleB,
    info = infoDefault,
    isTrialing = true,
    showFeatures = false,
    UpgradeMoreContainer = UpgradeMoreContainerDefault,
    FeaturesContainer = FeaturesContainerDefault,
    Feature = FeatureDefault,
    centerButton = false,
  } = usePropsOverride<IUpgradeInfoOverrides>({}, props);

  const { navigateToMembership } = useNavigations();

  const renderFeatures = () =>
    info.map((feature, index) => {
      const Icon = icons[index];
      return (
        <Feature key={index}>
          <div>
            <Icon />
          </div>

          <h3>
            {feature.bold} <span>{feature.text}</span>
          </h3>
        </Feature>
      );
    });

  const logoText = (
    <>
      &nbsp;
      <SmartNText />
    </>
  );
  return (
    <UpgradeMoreContainer>
      <UpgradeTitleText>{header}</UpgradeTitleText>
      {/* <PlanPrices useLinks applyTopMargin /> */}
      {/* <FeaturesContainer>{renderFeatures()}</FeaturesContainer> */}
      <SubTitleA>
        {subTitleA}
        {isTrialing && logoText}.
      </SubTitleA>
      <SubTitleB>{subTitleB}</SubTitleB>
      {showFeatures && <FeaturesContainer>{renderFeatures()}</FeaturesContainer>}
      <ButtonContainer isCentered={centerButton}>
        <Button onClick={() => navigateToMembership()} variant="primary">
          {buttonText}
        </Button>
      </ButtonContainer>
    </UpgradeMoreContainer>
  );
};

export default UpgradeInfo;
