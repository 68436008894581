import BaseMembership from './BaseMembership';
import { FreeUserSubjectTitleContainer } from '../../StudySpace/styled';

class StudySpaceTitle extends BaseMembership {
  public free() {
    return { Container: FreeUserSubjectTitleContainer };
  }
  public essential() {
    return {};
  }
  public allAccess() {
    return this.essential();
  }

  public useStrictSubscriptions() {
    return false;
  }

  public trialling() {
    return this.free();
  }

  public useStrictFreeMembers() {
    return true;
  }
}

export default StudySpaceTitle;
