import StateCity from '../../assets/StateCity.json';
import { ILooseObject } from '../types';

enum ELocationCodes {
  USA = 'US',
  CANADA = 'CA',
  ONTARIO = 'ON',
  MICHiGAN = 'MI',
}

const supportedCountries = [ELocationCodes.CANADA, ELocationCodes.USA];

const supportedStates: ILooseObject = {
  [ELocationCodes.CANADA]: [ELocationCodes.ONTARIO],
  [ELocationCodes.USA]: [ELocationCodes.MICHiGAN],
};

class LocationInterface {
  public getStatesOfCountry(countryISO: ELocationCodes) {
    if (!supportedCountries.includes(countryISO)) return [];
    //@ts-ignore
    const states = StateCity[countryISO];
    if (!states) return [];
    const options = Object.keys(states).map(stateKey => {
      const data = states[stateKey];
      return {
        label: data.name,
        value: stateKey,
        id: stateKey,
      };
    });

    return options;
  }

  public getCitiesOfState(countryISO: string, stateISO: string) {
    //@ts-ignore
    const states = StateCity[countryISO];
    if (!states) return [];
    const cities = states[stateISO]?.cities || [];
    return cities.map((city: string) => ({
      label: city,
      value: city,
      id: city,
    }));
  }
}

export { ELocationCodes, supportedCountries, supportedStates };
export default LocationInterface;
