import { IAttempt, IFetchQuestionResponse, IQuestion } from '../types';
import axiosRequest from '../axios';
import { endpoints } from '../constants';
import ZipQuestionReader from './ZipQuestionReader';

class FetchQuizQuestions {
  private attempt: IAttempt;
  private questionIds: string[];
  constructor(attempt: IAttempt) {
    this.attempt = attempt;
    this.questionIds = this.getQuestionIds();
  }

  public async fetchQuestionURL(questionId: string) {
    const { data: questionUrl }: IFetchQuestionResponse = await axiosRequest({
      url: endpoints.fetchQuestion(questionId),
    });

    return questionUrl;
  }

  public async fetchQuestionZip(questionUrl: string) {
    const { data: zipFile } = await axiosRequest({
      url: questionUrl,
      responseType: 'blob',
      useAuthorization: false,
    });

    return zipFile;
  }

  public async extractQuestionsFromZip(zipFile: Blob) {
    const questionReader = new ZipQuestionReader(zipFile);
    const question = await questionReader.getQuestion();
    return question;
  }

  public async fetchQuestion(questionId: string) {
    const questionUrl = await this.fetchQuestionURL(questionId);
    const zipFile = await this.fetchQuestionZip(questionUrl);
    const question = await this.extractQuestionsFromZip(zipFile);
    return question;
  }

  public getQuestionIds(): string[] {
    return this.questionIds || this.attempt.questions.map(question => question.questionId);
  }

  public fetchQuestions() {
    return Promise.all(this.getQuestionIds().map(questionId => this.fetchQuestion(questionId)));
  }
}

export default FetchQuizQuestions;
