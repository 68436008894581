import BaseMembership from './BaseMembership';
import HeaderFreeBanner from '../../Header/HeaderFreeBanner';
import HeaderTrialBanner from '../../Header/HeaderTrialBanner';
import HeaderSponsoredBanner from '../../Header/HeaderSponsoredBanner';

class HeaderBanner extends BaseMembership {
  public free() {
    return <HeaderFreeBanner />;
  }

  public sponsored() {
    return (
      <HeaderFreeBanner>
        <HeaderSponsoredBanner />
      </HeaderFreeBanner>
    );
  }
  public essential() {
    return null;
  }
  public allAccess() {
    return this.essential();
  }
  public trialling() {
    return (
      <HeaderFreeBanner>
        <HeaderTrialBanner />
      </HeaderFreeBanner>
    );
  }
  public useStrictFreeMembers() {
    return true;
  }
}

export default HeaderBanner;
