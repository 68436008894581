import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';

const LeftContentContainer = styled('div')`
  width: 50vw;
  min-height: calc(100vh - 64px);
  background-color: ${colors.purple5};
  ${props => props.theme.breakpoints.down('md')} {
    width: 100vw;
  }
`;

const RightContentContainer = styled('div')`
  width: 50vw;
  position: relative;
`;

const LandingText = styled('h1')`
  font-family: Poppins !important;
  background: linear-gradient(103.44deg, #ea20a5 -1.55%, #4a2dff 93.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 0px;
  font-weight: 800;
  line-height: 32px;
  font-size: 2rem;
  margin-bottom: calc(8.75% - 4px);
  padding-bottom: 4px;
  color: ${colors.purple40};
  white-space: pre-line;
  span {
    display: block;
    height: 2.5rem;
  }
  ${props => props.theme.breakpoints.up(1920)} {
    font-size: 3.5rem;
    line-height: 64px;
  }
  .smartn {
    text-fill-color: ${colors.primary};
  }
  .smartn-apostrophe {
    text-fill-color: ${colors.smartNApostrophe};
  }
`;

const SignupContainer = styled('div')`
  display: flex;
`;

const InfoSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(5)};
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const InfoItem = styled('div')`
  flex: 0 calc(50% - 20px);
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 24.5px;
  gap: ${props => props.theme.spacing(2.375)};
  color: ${colors.purple30};
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
    }
  }
  .bold {
    font-weight: 700;
  }
  .underline {
    text-decoration: underline;
  }
`;

const ContentContainer = styled('div')`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  align-items: center;
  height: 100%;
  ${props => props.theme.breakpoints.down('md')} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 10%;
  }
  ${props => props.theme.breakpoints.down('$xxs')} {
    padding-left: 4%;
    padding-right: 4%;
  }
`;

const FormContainer = styled('div')`
  .form-title {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
  width: 56%;
  margin: auto;
  padding-top: max(5%, 50px);
  padding-bottom: max(5%, 50px);
  ${props => props.theme.breakpoints.down('lg')} {
    width: 80%;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
  .smartn {
    color: ${colors.primary};
  }
  .smartn-apostrophe {
    color: ${colors.smartNApostrophe};
  }
`;

const AlreadyHaveAnAccountText = styled('div')`
  height: 64px;
  color: ${colors.black};
  width: 100%;
  font-weight: 700;
  bottom: 0;
  position: absolute;
  background-color: ${colors.lightestGrey};
  a {
    color: #1145ff;
  }

  ${props => props.theme.breakpoints.down('md')} {
    background-color: transparent;
    position: relative;
  }
`;

const SignUpFormContainer = styled('div')`
  box-sizing: border-box;
  height: calc(100% - 64px);
  flex-direction: column;
  display: flex;
  justify-content: center;
  ${props => props.theme.breakpoints.down('md')} {
    height: 100%;
    background: rgba(255, 255, 255, 0.01);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0px 15px 0px 15px;
    margin-bottom: ${props => props.theme.spacing(5)};
  }
`;

export {
  LeftContentContainer,
  SignupContainer,
  RightContentContainer,
  LandingText,
  InfoSection,
  InfoItem,
  ContentContainer,
  FormContainer,
  AlreadyHaveAnAccountText,
  SignUpFormContainer,
};
