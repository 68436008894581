import { CurrentProfile } from '../classes';
import { ILooseObject } from '../types';
import Axios from 'axios';

// For PRE-MVP Purposes, we are using a custom test instance to mock the API
//Axios.defaults.baseURL = window.config.endpointV2;
//Axios.defaults.baseURL = 'http://localhost:3000';

enum ERequestMethods {
  GET = 'get',
  DELETE = 'delete',
  POST = 'post',
  PUT = 'put',
}

interface IRequestData {
  body?: ILooseObject | FormData;
  params?: ILooseObject;
}

type TResponseTypes = 'json' | 'arraybuffer' | 'blob';

interface IAxiosRequest {
  url: string;
  requestData?: IRequestData;
  method?: ERequestMethods;
  isJson?: boolean;
  responseType?: TResponseTypes;
  useCorsHeaders?: boolean;
  useAuthorization?: boolean;
}

const axiosRequest = async ({
  url,
  requestData = {},
  method = ERequestMethods.GET,
  isJson = true,
  responseType = 'json',
  useAuthorization = true,
}: IAxiosRequest) => {
  const token = CurrentProfile.getToken();

  const { status, data, headers } = await Axios({
    method,
    responseType,
    // window.config?.smartnEndpoint
    url: url.includes('http') ? url : `${window.config?.smartnEndpoint}${url}`,
    data: requestData.body,
    params: requestData.params,
    headers: {
      'Content-Type': isJson ? 'application/json' : 'multipart/form-data',
      ...(token && useAuthorization && { Authorization: token }),
    },
  });
  return { status, data, headers };
};

export type { IAxiosRequest };
export { ERequestMethods, Axios };
export default axiosRequest;
