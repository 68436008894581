import { styled } from '@mui/material/styles';
import { colors } from '../../../../../themes';
import { shouldForwardProps } from '../../../../utils';

const Table = styled('table')`
  border-collapse: separate;
  border-spacing: 0 1px;
  margin-top: 16px;
  width: 100%;
  th {
    font-weight: 600;
    text-align: center;
    padding: 16px;
    :nth-child(1) {
      text-align: left;
    }
  }
  td {
    padding: 8px 16px;
    span {
      padding: 0;
      display: block;
      margin: auto;
      width: fit-content;
    }
  }
  tr:nth-child(odd) {
    background-color: ${colors.purple1};
  }
  tr:nth-child(even) {
    background-color: ${colors.white};
  }
  tr:nth-child(1) {
    background-color: ${colors.purple10};
  }

  ${props => props.theme.breakpoints.down('lg')} {
    font-size: 0.9rem;
    th {
      padding: 16px 8px 16px 8px;
    }
  }
`;

const ExpandablesContainer = styled('div')`
  margin-top: ${props => props.theme.spacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(3)};
`;
const Expandable = styled('div')`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(96, 75, 223, 0.4);

  border-radius: 16px;

  padding: 24px;
`;

const ExpandableTag = styled('div')`
  display: block;
  color: ${colors.purple40};
  font-size: 0.875rem;
  margin-bottom: 4px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ExpandableQuestion = styled('div')`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.lightGrey};
`;
interface IOptionsContainer {
  height?: string;
  horizontal?: boolean;
}
const OptionsContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['largeForm', 'horizontal']),
})<IOptionsContainer>(
  ({ height, horizontal }) => `
  margin-top: 8px;
  display: flex;
  flex-direction: ${horizontal ? 'row' : 'column'};
  transition:all 0.3s linear;
  ${height ? `max-height:${height};` : ''}
  overflow:hidden;
  ${horizontal ? 'flex-wrap:wrap;' : ''}
  ${horizontal ? 'justify-content:center;' : ''}
`,
);

const Option = styled('div')`
  display: flex;
  font-size: 0.9rem;
  align-items: center;
`;

export { Table, Expandable, ExpandableTag, ExpandableQuestion, ExpandablesContainer, OptionsContainer, Option };
