import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { shouldForwardProps } from '../utils';

interface IProps {
  isSelected?: boolean;
}
interface ITopicContainer {
  loading?: boolean;
}
interface IProficiencyTagProps {
  textColor: string;
  backgroundColor: string;
}

const commonStyles = `
width:50vw;
display: flex;
justify-content: space-between;
`;

const fontWeight = 700;

const PageWrapper = styled('div')`
  background-color: ${colors.purple5};
`;

const PageContainer = styled('div')(
  ({ theme }) => `
  padding: 5vh 15px;
  padding-top: 0px;
  min-height: 100vh;
  background: radial-gradient(circle at top left, rgba(250, 171, 98, 0.4) 0%, rgba(217, 217, 217, 0) 30%),
    radial-gradient(ellipse at center right, rgba(198, 172, 253, 0.8) 0%, rgba(217, 217, 217, 0) 50%),
    radial-gradient(circle at bottom right, rgba(250, 171, 98, 0.4) 0%, rgba(217, 217, 217, 0) 50%),
    radial-gradient(ellipse at bottom left, rgba(198, 172, 253, 0.2) 0%, rgba(217, 217, 217, 0) 80%);
  ${theme.breakpoints.up('$xxs')} {
    padding: 5vh 30px;
    padding-top: 0px;
  }
  ${theme.breakpoints.up('sm')} {
    padding: 5vh 40px;
    padding-top: 0px;
  }

`,
);

const SubjectTitleContainer = styled('div')`
  background-color: ${colors.purple1};
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 43%;
    ${props => props.theme.breakpoints.down('md')} {
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
  h1 {
    font-size: 1.75rem;
    margin: 0;
  }
  h3 {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
  }
  margin-left: -15px;
  margin-right: -15px;
  ${props => props.theme.breakpoints.up('$xxs')} {
    margin-left: -30px;
    margin-right: -30px;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: -40px;
    margin-right: -40px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px 20px;
  }
`;

const FreeUserSubjectTitleContainer = styled(SubjectTitleContainer)`
  ${props => props.theme.breakpoints.down('lg')} {
    margin-top: 47px;
  }
  ${props => props.theme.breakpoints.down('$md')} {
    margin-top: 47px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 59px;
  }
`;

const ProgressBarsContainer = styled('div')`
  width: 45%;
  p {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 4px;
  }
`;

const SectionTitle = styled('h1')`
  font-size: 1.5rem;
  font-weight: ${fontWeight};
  margin-top: ${({ theme }) => theme.spacing(10)};

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const TabsContainer = styled('div')`
  margin: ${({ theme }) => theme.spacing(3)} 0;
  display: flex;
  gap: 4px;

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

const Tab = styled('span', { shouldForwardProp: prop => shouldForwardProps(prop, ['isSelected']) })<IProps>(
  ({ theme, isSelected }) => `

  padding: 12px 24px;
  color: ${isSelected ? theme.palette.text.primary : colors.black10};
  font-weight: ${isSelected ? fontWeight : 600};
  font-size: 1.125rem;
  cursor: pointer;
  transition: opacity 0.3s linear;
  &:hover {
    opacity: 0.7;
  }

  ${theme.breakpoints.down('sm')}{
    padding: 12px 12px;
  }
  
  ${isSelected && `border-bottom: 4px solid ${theme.palette.text.primary};`}
  
  `,
);

const ProficiencyTag = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['textColor', 'backgroundColor']),
})<IProficiencyTagProps>(
  ({ textColor, backgroundColor }) => `
  color: ${textColor};
  background-color: ${backgroundColor};
  border-radius: 4px;
  padding: 9px 16px;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 24px; 
`,
);

const TopTitle = styled('h1')`
  text-transform: uppercase;
`;

export {
  PageContainer,
  SubjectTitleContainer,
  ProgressBarsContainer,
  SectionTitle,
  TabsContainer,
  Tab,
  ProficiencyTag,
  PageWrapper,
  FreeUserSubjectTitleContainer,
  TopTitle,
};
