import { IStepComponent } from '../types';
import { AttachmentButtonContainerBottomMargin, Title } from './styled';
import AttachmentButton from '../../components/AttachmentButton';
import { en } from '../../../i18n';
import useStep from './useStep';

const { apply } = en;
const ConfirmMissingCV = ({ question }: IStepComponent) => {
  const { renderSanitizedText, onUpload, currentResponse } = useStep({ question });

  return (
    <>
      <Title centerMobile>{apply.confirmMissingCV.title}</Title>
      <Title centerMobile>{apply.confirmMissingCV.subTitle}</Title>
      <AttachmentButtonContainerBottomMargin>
        <AttachmentButton
          text={apply.uploadResume.buttonText}
          onUpload={onUpload}
          externalFile={currentResponse as File}
        />
      </AttachmentButtonContainerBottomMargin>

      <Title centerMobile>{renderSanitizedText(en.apply.confirmMissingCV.disclaimer, true)}</Title>
    </>
  );
};

export default ConfirmMissingCV;
