import { useState, useEffect } from 'react';
import DashboardModal from '../../components/DashboardModal';
import UpgradeInfo from '../../SmartQuiz/results/UpgradeInfo';
import { UpgradeMoreContainerDashboard, FeaturesContainerDashboard, FeatureDashboard } from './styled';
import { en } from '../../../i18n';
import { useSelector } from 'react-redux';
import { subscriptionsSelectors, actions } from '../../Membership/redux/slice';
import { useDispatch } from '../../hooks';

const { upgradeInfo } = en.placementTest;
const { trialEnded } = en.dashboard;

const TrialEnded = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isTrialEndedModalShown } = useSelector(subscriptionsSelectors.allState);
  const { dispatch } = useDispatch();

  useEffect(() => {
    if (!isTrialEndedModalShown && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [isTrialEndedModalShown]);

  useEffect(() => {
    if (isModalOpen) dispatch(actions.setisTrialEndedModalShown(true));
  }, [isModalOpen]);

  return (
    <DashboardModal isOpen={isModalOpen} useLayoutOnly>
      <UpgradeInfo
        FeaturesContainer={FeaturesContainerDashboard}
        UpgradeMoreContainer={UpgradeMoreContainerDashboard}
        Feature={FeatureDashboard}
        info={trialEnded.info}
        header={upgradeInfo.freeMember.header}
        subTitleA={upgradeInfo.freeMember.subTitleA}
        subTitleB={upgradeInfo.freeMember.subTitleB}
        showFeatures
        centerButton
        isTrialing={false}
      />
    </DashboardModal>
  );
};

export default TrialEnded;
