import { EFieldTypes, IDropDownField, TField } from './types';
import PasswordField from './PasswordField';
import TextField from './TextField';
import CodeInputField from './CodeInputField';
import { isEmptyValue } from '../../../utils';
import { ErrorContainer, Error } from './styled';
import { ReactComponent as ErrorIcon } from '../../../../images/icons/error.svg';
import DropDown from './DropDown';
import DatePicker from './DatePicker';

const componentMappings = {
  [EFieldTypes.PASSWORD]: PasswordField,
  [EFieldTypes.TEXT]: TextField,
  [EFieldTypes.CODE_INPUT]: CodeInputField,
  [EFieldTypes.EMAIL]: TextField,
  [EFieldTypes.PASSWORD_CONFIRMATION]: PasswordField,
  [EFieldTypes.DROP_DOWN]: DropDown,
  [EFieldTypes.DATE]: DatePicker,
};

const Field = ({ type = EFieldTypes.TEXT, renderErrors, ...props }: TField) => {
  const { value, errors } = props;

  const defaultRenderErrors = () => {
    if (!errors) return null;
    return (
      <ErrorContainer>
        {errors.map((error, index) => (
          <Error key={index}>
            <ErrorIcon />
            <span>{error.message}</span>
          </Error>
        ))}
      </ErrorContainer>
    );
  };

  const Component = componentMappings[type];

  // TODO: IMPORTANT: Find a way to typecast required props
  //@ts-ignore
  return <Component renderErrors={defaultRenderErrors} isEmpty={isEmptyValue(value)} {...props} />;
};

export default Field;
