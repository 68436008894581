import BaseMembership from './BaseMembership';
import { FreeMemberTopic } from '../../Home/SideBar/styled';
import { StyledComponent } from '@mui/styles/styled';
import { FreeQuizCard } from '../../sharedStyled';

class ManualStartQuizComponents extends BaseMembership {
  public free(): { Topic: StyledComponent<any>; QuizCard: StyledComponent<any> } {
    return { Topic: FreeMemberTopic, QuizCard: FreeQuizCard };
  }
  public essential() {
    return {};
  }
  public allAccess() {
    return this.essential();
  }
}

export default ManualStartQuizComponents;
