import { TabContainer } from '../Components/styled';
import TabTitle from '../Components/TabTitle';
import { en } from '../../../i18n';
import { ReactComponent as Review } from '../../../images/icons/dashboard/review.svg';
import { IAttempt } from '../../types';
import { NoQuizzes, NoQuizzesTextDisclaimer, TitleContainer } from './styled';
import Button from '../../components/Button';
import TabContent from '../Components/TabContent';
import Message from '../../components/Message';
import { format } from 'date-fns';
import {
  QuizCard,
  ContinueQuizContent,
  ContinueQuizLeftContent,
  ButtonContainer,
  PastQuizzesContainer,
} from '../PracticeQuiz/styled';
import CircularProgressBar from '../../components/CircularProgressBar';
import { topicsSelectors } from '../../StudySpace/redux/topics/slice';
import { useSelector } from 'react-redux';
import { useNavigations } from '../../hooks';
import { IHomePageSection } from '../Components/types';
import CustomTooltip from '../../components/Tooltip';

const { pastQuizzes } = en.dashboard;
const {
  title,
  reviewAll,
  innerTitle,
  notFoundMessage,
  reviewAttempt,
  toolTip,
  noQuizzesTitle,
  noQuizzesSubTitle,
  startQuizbutton,
} = en.dashboard.reviewPastQuizzes;

const ReviewInsights = ({ attempts, id }: IHomePageSection) => {
  const { entities: topics } = useSelector(topicsSelectors.allState);
  const { navigateToStudySpace, navigateToResults } = useNavigations();

  const completeAttempts = attempts?.filter(attempt => attempt.isDone);

  const getTopics = (attempt: IAttempt) => {
    const titles = attempt.topicIDs.map(topicId => topics[topicId]?.name);
    const topicsStringified = titles.slice(0, 3).join(', ');
    if (titles.length > 3) {
      const remainingTopics = titles.length - 3;
      return `${topicsStringified} ${pastQuizzes.andMore(remainingTopics)}`;
    }
    return topicsStringified;
  };

  const renderContent = () => {
    if (!completeAttempts || completeAttempts.length === 0) {
      return (
        <NoQuizzes>
          <NoQuizzesTextDisclaimer>
            <h1>{noQuizzesTitle}</h1>
            <h2>{noQuizzesSubTitle}</h2>
          </NoQuizzesTextDisclaimer>
          <Button
            variant="secondary"
            onClick={() => {
              window.scrollTo({ behavior: 'smooth', top: 0 });
            }}
          >
            {startQuizbutton}
          </Button>
        </NoQuizzes>
      );
    }

    const renderAttempt = (attempt: IAttempt) => {
      const date = attempt.ctime ? new Date(attempt.ctime) : new Date();
      const formattedDate = format(date, "MMM d, yyyy 'at' H:mm a");

      return (
        <QuizCard key={attempt.attemptId} applyFlex hideCursor>
          <ContinueQuizContent>
            <ContinueQuizLeftContent>
              {!attempt.isDone && <CircularProgressBar progress={attempt.isDone ? 100 : attempt.progress.percentage} />}
              <div>
                <h3>{formattedDate}</h3>
                <h2>{attempt.title}</h2>
                <span>{getTopics(attempt)}</span>
              </div>
            </ContinueQuizLeftContent>
            <ButtonContainer>
              <Button onClick={() => navigateToResults(attempt)} size="small" variant="secondary">
                {reviewAttempt}
              </Button>
            </ButtonContainer>
          </ContinueQuizContent>
        </QuizCard>
      );
    };

    return completeAttempts.slice(0, 3).map(renderAttempt);
  };

  const button = (
    <Button onClick={() => navigateToStudySpace(true)} variant="secondayGreyDisable">
      {reviewAll}
    </Button>
  );

  const buttonWithToolTip = (
    <CustomTooltip width="100%" arrow={false} title={toolTip}>
      <span>{button}</span>
    </CustomTooltip>
  );
  return (
    <TabContainer id={`${id}`}>
      <TitleContainer>
        <TabTitle Icon={Review} text={title} />
        {attempts?.length === 0 ? buttonWithToolTip : button}
      </TitleContainer>

      <TabContent {...(completeAttempts?.length !== 0 && { title: innerTitle })}>
        <PastQuizzesContainer>{renderContent()}</PastQuizzesContainer>
      </TabContent>
    </TabContainer>
  );
};

export default ReviewInsights;
