import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { ITopic, ISelectedTopic } from '../../../types';
import { RootState } from '../../../redux/store';
import { globalSelector } from '../../../redux/globalSlice';
import { topicIdsToCategories } from '../../staticConstants';

const SLICE_NAME = 'topics';

const topicsAdapter = createEntityAdapter<ITopic>();

const initialState = topicsAdapter.getInitialState({
  selectedTopics: {} as ISelectedTopic,
  parentCategories: topicIdsToCategories,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    indexSuccess: (state, action: PayloadAction<{ entities: ITopic[] }>) => {
      const { entities } = action.payload;
      topicsAdapter.addMany(state, entities);
    },

    setSelectedTopicIds: (state, action: PayloadAction<{ selectedTopicIds: string[] }>) => {
      const { selectedTopicIds } = action.payload;
      state.selectedTopics = {};
      selectedTopicIds.forEach(id => (state.selectedTopics[id] = true));
    },

    setSelectedTopics: (state, action: PayloadAction<{ selectedTopics: ISelectedTopic }>) => {
      const { selectedTopics } = action.payload;
      state.selectedTopics = selectedTopics;
    },
  },
});

const topicsSelectors = {
  allState: (state: RootState): typeof initialState => state.topics,
  globalSelector: globalSelector(SLICE_NAME),
};

const actions = { ...slice.actions };

export { topicsSelectors, SLICE_NAME, actions };
export default slice.reducer;
