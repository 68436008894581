import { actions, subscriptionsSelectors } from './slice';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { endpoints } from '../../constants';
import axiosRequest, { ERequestMethods } from '../../axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { GoogleTags, SubscriptionTransformer } from '../../classes';
import { EGoogleTags, ILooseObject, IPaymentFormState, IStateCustomerAddress, ISubscription } from '../../types';
import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { en } from '../../../i18n';
import { pollRequest } from '../../utils';
import AllSubscriptionTransformer from '../../classes/DataTransformers/AllSubscriptionsTransformer';
import AddonsTransformer from '../../classes/DataTransformers/AddonsTransformer';
import PaymentError from '../../classes/Errors/PaymentError';

const reportPayment = () => {
  const googleTags = new GoogleTags(EGoogleTags.PURCHASE_COMPLETE);
  googleTags.fireEvent();
  window.scrollTo({ top: 0 });
};

function* createAddress(action: PayloadAction<{ address: IStateCustomerAddress; skipAddressSuccess?: boolean }>) {
  try {
    const { address, skipAddressSuccess } = action.payload;
    const transformedAddress = new SubscriptionTransformer().transformOut(address);
    yield call(axiosRequest, {
      url: endpoints.addresses,
      method: ERequestMethods.POST,
      requestData: {
        body: {
          ...transformedAddress,
        },
      },
    });
    if (!skipAddressSuccess) yield put(actions.createAddressSuccess());
    return true;
  } catch (errors) {
    yield put(actions.createAddressFailure());
    return false;
  }
}

function* fetchPromoCode(action: PayloadAction<{ code: string; productId: string }>) {
  try {
    const { code, productId } = action.payload;
    const { data } = yield call(axiosRequest, {
      url: endpoints.promocodes(code, productId),
    });

    yield put(actions.fetchPromoCodeSuccess({ id: data }));
  } catch (errors) {
    yield put(actions.fetchPromoCodeFailure());
  }
}

function* createSubscription() {
  try {
    const { stripePlan, promotionCodeID, isAddressCreated } = yield select(subscriptionsSelectors.allState);
    const { priceId } = stripePlan;
    if (!isAddressCreated || !priceId) {
      yield put(actions.setLoadingCreateSubscription({ isLoading: false }));
      if (!priceId) {
        yield put(actions.createSubscriptionFailure({ errors: { global: en.billing.paymentForm.selectPrice } }));
      }
      return;
    }
    const { data } = yield call(axiosRequest, {
      url: endpoints.subscriptions,
      method: ERequestMethods.POST,
      requestData: {
        body: {
          priceId,
          ...(promotionCodeID && { promotionCodeID }),
        },
      },
    });
    const subscription = new SubscriptionTransformer().transform(data);
    yield put(actions.createSubscriptionSuccess({ subscription }));
  } catch (errors: any) {
    const transformedErrors = new SubscriptionTransformer().transformStripeErrors(errors?.response?.data);
    yield put(actions.createSubscriptionFailure({ errors: transformedErrors }));
  }
}

function* fetchSubscription() {
  try {
    const { data } = yield call(axiosRequest, {
      url: endpoints.subscriptions,
    });
    const subscriptions = new AllSubscriptionTransformer().transform(data);
    yield put(actions.fetchSubscriptionSuccess(subscriptions));
  } catch (errors) {
    yield put(actions.fetchSubscriptionFailure({ errors }));
  }
}

function* fetchSubscriptionPolling() {
  try {
    const stoppingCriteria = (apiSubscriptions: any) => apiSubscriptions.data?.length > 0;

    const { data } = yield call(
      pollRequest,
      {
        url: endpoints.subscriptions,
      },
      {
        stopCallBack: stoppingCriteria,
        pollingAttempts: 6,
      },
    );
    const subscriptions = new AllSubscriptionTransformer().transform(data);
    yield put(actions.fetchSubscriptionSuccess(subscriptions));
  } catch (errors) {
    yield put(actions.fetchSubscriptionFailure({ errors }));
  }
}

function* cancelSubscription(action: PayloadAction<{ subId: string; cancelAtPeriodEnd?: boolean }>) {
  try {
    const {
      payload: { subId, cancelAtPeriodEnd = true },
    } = action;

    const { data } = yield call(axiosRequest, {
      url: endpoints.subscriptionId(subId),
      method: ERequestMethods.PUT,
      requestData: {
        body: {
          cancelAtPeriodEnd,
        },
      },
    });
    const transformedSub = new SubscriptionTransformer().transform(data);
    if (!cancelAtPeriodEnd) yield put(actions.resubscribeSuccess({ currentSubscription: transformedSub }));
    else yield put(actions.cancelSubscriptionSuccess({ currentSubscription: transformedSub }));
  } catch (errors) {
    yield put(actions.cancelSubscriptionFailure({ errors: { global: '', address: '' } }));
  }
}

function* confirmPayment(
  action: PayloadAction<{
    stripe: Stripe | null;
    elements: StripeElements | null;
    clientSecret: string;
    formState: IPaymentFormState;
  }>,
) {
  const { stripe, elements, clientSecret, formState } = action.payload;
  if (!stripe || !elements) return;
  try {
    const stripePromise = stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement) || { token: '' },
        billing_details: {
          name: formState.name,
          email: formState.email,
          address: {
            postal_code: formState.address.postalCode,
            country: formState.address.country.value,
          },
        },
      },
    });
    const data: ILooseObject = yield Promise.resolve(stripePromise);
    if (data.error) {
      throw new PaymentError({
        code: data.error.code,
        message: data.error.message,
        type: data.error.type,
        declineCode: data.error.decline_code,
      });
    }
    yield put(actions.fetchSubscriptionRequest());
    yield put(actions.confirmPaymentSuccess());
    reportPayment();
  } catch (error: any) {
    const payload = {
      global: '',
      address: '',
      card: '',
      promoCode: '',
    };

    if (error.code) {
      payload.card = error.message;
    } else {
      payload.global = en.somethingWentWrong;
    }

    yield put(
      actions.confirmPaymentFailure({
        errors: payload,
      }),
    );
  }
}

function* purchaseAddonNoCard(
  action: PayloadAction<{
    stripe: Stripe | null;
    elements: StripeElements | null;
    formState: IPaymentFormState;
    priceId: string;
  }>,
) {
  try {
    const { priceId, stripe } = action.payload;
    const paymentMethodId: string = yield createPaymentMethod(action);
    if (!paymentMethodId) throw new Error();
    yield purchaseAddon({ payload: { priceId, stripe, paymentMethodId }, type: 'PURCHASE_ADDON' });
  } catch (error: any) {
    yield put(actions.purchaseAddonFailue({ errors: {} }));
  }
}

function* createPaymentMethod(
  action: PayloadAction<{
    stripe: Stripe | null;
    elements: StripeElements | null;
    formState: IPaymentFormState;
    priceId: string;
  }>,
) {
  try {
    const { stripe, formState, elements, priceId } = action.payload;
    if (!elements) return false;
    const payload: PayloadAction<{ address: IStateCustomerAddress; skipAddressSuccess: boolean }> = {
      payload: { address: formState.address, skipAddressSuccess: true },
      type: '',
    };
    const isAddressCreated: boolean = yield createAddress(payload);
    if (!isAddressCreated) {
      yield put(actions.changePaymentMethodFailure({ errors: {} }));
      return false;
    }

    const { data: intentID } = yield call(axiosRequest, {
      url: endpoints.paymentMethods,
      method: ERequestMethods.POST,
    });

    const stripePromise = stripe?.confirmCardSetup(intentID, {
      payment_method: {
        card: elements?.getElement(CardNumberElement) || { token: '' },
      },
    });
    const setupIntentResult: SetupIntentResult = yield Promise.resolve(stripePromise);
    const { error, setupIntent } = setupIntentResult;
    if (error) {
      throw new PaymentError({
        code: error.code || '',
        message: error.message || '',
        type: error.type,
        declineCode: error.decline_code,
      });
    }
    const { payment_method: paymentMethod } = setupIntent;
    return paymentMethod;
  } catch (error) {
    const transformedErrors = new SubscriptionTransformer().transformStripeErrors(error);
    yield put(actions.changePaymentMethodFailure({ errors: transformedErrors }));
    return false;
  }
}

function* changePaymentMethod(
  action: PayloadAction<{
    stripe: Stripe | null;
    elements: StripeElements | null;
    formState: IPaymentFormState;
    subId: string;
  }>,
) {
  try {
    const { stripe, formState, elements, subId } = action.payload;
    if (!elements) return;

    const payload: PayloadAction<{ address: IStateCustomerAddress; skipAddressSuccess: boolean }> = {
      payload: { address: formState.address, skipAddressSuccess: true },
      type: '',
    };
    const isAddressCreated: boolean = yield createAddress(payload);
    if (!isAddressCreated) {
      yield put(actions.changePaymentMethodFailure({ errors: {} }));
      return;
    }

    const { data: intentID } = yield call(axiosRequest, {
      url: endpoints.paymentMethods,
      method: ERequestMethods.POST,
    });

    const stripePromise = stripe?.confirmCardSetup(intentID, {
      payment_method: {
        card: elements?.getElement(CardNumberElement) || { token: '' },
      },
    });
    const setupIntentResult: SetupIntentResult = yield Promise.resolve(stripePromise);
    const { error, setupIntent } = setupIntentResult;
    if (error) {
      throw new PaymentError({
        code: error.code || '',
        message: error.message || '',
        type: error.type,
        declineCode: error.decline_code,
      });
    }
    const { payment_method: paymentMethod } = setupIntent;

    const { data }: { data: ISubscription } = yield call(axiosRequest, {
      url: endpoints.subscriptionId(subId),
      method: ERequestMethods.PUT,
      requestData: {
        body: {
          cancelAtPeriodEnd: false,
          paymentMethod,
        },
      },
    });

    yield fetchSubscription();
    yield put(actions.changePaymentMethodSuccess());
  } catch (error: any) {
    const transformedErrors = new SubscriptionTransformer().transformStripeErrors(error);
    yield put(actions.changePaymentMethodFailure({ errors: transformedErrors }));
  }
}

function* purchaseAddon(action: PayloadAction<{ priceId: string; paymentMethodId: string; stripe: Stripe | null }>) {
  try {
    const { priceId, paymentMethodId, stripe } = action.payload;
    const { data: paymentIntentClientSecret } = yield call(axiosRequest, {
      url: endpoints.paymentIntents,
      method: ERequestMethods.POST,
      requestData: {
        body: {
          priceId,
        },
      },
    });
    if (!stripe) return;
    const stripePromise = stripe.confirmCardPayment(paymentIntentClientSecret, {
      payment_method: paymentMethodId,
    });
    const data: ILooseObject = yield Promise.resolve(stripePromise);
    if (data.error) {
      throw new PaymentError({
        code: data.error.code,
        message: data.error.message,
        type: data.error.type,
        declineCode: data.error.decline_code,
      });
    }
    yield fetchAddons();
    yield put(actions.purchaseAddonSuccess());
    reportPayment();
  } catch (error: any) {
    const transformedErrors = new SubscriptionTransformer().transformStripeErrors(error);
    yield put(actions.purchaseAddonFailue({ errors: transformedErrors }));
  }
}

function* fetchAddons() {
  try {
    const { data: paymentIntents } = yield call(axiosRequest, {
      url: endpoints.paymentIntents,
      method: ERequestMethods.GET,
    });
    const addons = new AddonsTransformer().transform(paymentIntents);
    yield put(actions.fetchAddonsSuccess({ addons }));
  } catch (error: any) {
    yield put(actions.fetchAddonsfailure({ errors: { global: en.somethingWentWrong } }));
  }
}

function* watchCreateSubscription() {
  yield takeLatest(actions.createSubscriptionRequest, createSubscription);
}

function* watchFetchSubscription() {
  yield takeLatest(actions.fetchSubscriptionRequest, fetchSubscription);
}

function* watchCancelSubscription() {
  yield takeLatest(actions.cancelSubscriptionRequest, cancelSubscription);
}

function* watchConfirmPayment() {
  yield takeLatest(actions.confirmPaymentRequest, confirmPayment);
}
function* watchRefreshSubscriptions() {
  yield takeLatest(actions.refreshSubscriptions, fetchSubscription);
}

function* watchFetchSubscriptionPollingSagas() {
  yield takeLatest(actions.fetchSubscriptionRequestPolling, fetchSubscriptionPolling);
}

// function* watchCreateTrial() {
//   yield takeLatest(actions.createTrialRequest, createTrial);
// }

function* watchCreateAddress() {
  yield takeLatest(actions.createAddressRequest, createAddress);
}

function* watchFetchPromoCode() {
  yield takeLatest(actions.fetchPromoCodeRequest, fetchPromoCode);
}

function* watchAddressCreated() {
  yield takeLatest(actions.createAddressSuccess, createSubscription);
}

function* watchPromoCodeValidated() {
  yield takeLatest(actions.fetchPromoCodeSuccess, createSubscription);
}

function* watchPromoCodeFailure() {
  yield takeLatest(actions.fetchPromoCodeFailure, createSubscription);
}

function* watchChangePaymentMethod() {
  yield takeLatest(actions.changePaymentMethodRequest, changePaymentMethod);
}

function* watchPurchaseAddon() {
  yield takeLatest(actions.purchaseAddonRequest, purchaseAddonNoCard);
}

function* watchFetchAddonRequest() {
  yield takeLatest(actions.fetchAddonsRequest, fetchAddons);
}

function* watchPurchaseAddonStoredCard() {
  yield takeLatest(actions.purchaseAddonStoredCardRequest, purchaseAddon);
}

const sagas = [
  watchCreateSubscription,
  watchFetchSubscription,
  watchCancelSubscription,
  watchConfirmPayment,
  watchRefreshSubscriptions,
  watchFetchSubscriptionPollingSagas,
  //watchCreateTrial,
  watchFetchPromoCode,
  watchAddressCreated,
  watchCreateAddress,
  watchPromoCodeValidated,
  watchPromoCodeFailure,
  watchChangePaymentMethod,
  watchPurchaseAddon,
  watchFetchAddonRequest,
  watchPurchaseAddonStoredCard,
];

export default sagas;
