import { useMediaQuery, useTheme } from '@mui/material';
import { IStepComponent } from '../types';

import { AttachmentButtonContainer, Title } from './styled';
import AttachmentButton from '../../components/AttachmentButton';

import { en } from '../../../i18n';
import useStep from './useStep';

const { apply } = en;

const UploadResume = ({ question }: IStepComponent) => {
  const theme = useTheme();
  const { renderSanitizedText, onUpload, currentResponse } = useStep({ question });

  return (
    <>
      <Title centerMobile>{renderSanitizedText(question.content.question.content.text)}</Title>
      <Title centerMobile>{apply.uploadResume.subTitle}</Title>
      <AttachmentButtonContainer>
        <AttachmentButton
          text={apply.uploadResume.buttonText}
          onUpload={onUpload}
          externalFile={currentResponse as File}
        />
      </AttachmentButtonContainer>
    </>
  );
};

export default UploadResume;
