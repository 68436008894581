import BaseMembership from './BaseMembership';
import { QuizFormPopupOverride } from '../../PlacementTest/styled';
import { PageContainerOverride, AuthContainerOverride, RadialBackgroundOverride } from '../../Auth/styled';

class BaseLineTestLayoutComponents extends BaseMembership {
  public free() {
    return {
      QuizFormPopup: QuizFormPopupOverride,
      PageContainer: PageContainerOverride,
      AuthContainer: AuthContainerOverride,
      RadialBackground: RadialBackgroundOverride,
    };
  }
  public essential() {
    //return this.free();
    return {
      QuizFormPopup: QuizFormPopupOverride,
      PageContainer: PageContainerOverride,
      AuthContainer: AuthContainerOverride,
      RadialBackground: RadialBackgroundOverride,
    };
  }
  public allAccess() {
    return this.essential();
  }
}
export default BaseLineTestLayoutComponents;
