import Matrix from '../../components/Matrix';
import { ILooseObject } from '../../types';
import { IStepComponent } from '../types';
import { InputContainer, MatrixInputContainer, Title } from './styled';
import useStep from './useStep';

const Relocate = ({ question }: IStepComponent) => {
  const { renderSanitizedText, currentResponse, onChangeAnswer } = useStep({ question });

  const header = [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ];

  const rows =
    question.content.choices?.map(choice => ({ id: choice.id, value: choice.content.choice?.content.text || '' })) ||
    [];

  return (
    <>
      <Title>{renderSanitizedText(question.content.question.content.text)}</Title>
      <MatrixInputContainer>
        <Matrix header={header} rows={rows} response={currentResponse as ILooseObject} onChange={onChangeAnswer} />
      </MatrixInputContainer>
    </>
  );
};

export default Relocate;
