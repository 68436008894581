import { InfoLabel, PersonalInfoContainer, PersonalName, InfoContainer, Info } from './styled';
import { IAttempt } from '../../types';
import { useEffect } from 'react';
import { useDispatch } from '../../hooks';
import { SLICE_NAME as studySpaceSlice, actions as studySpaceActions } from '../../StudySpace/redux/slice';
import { en } from '../../../i18n';
import usePersonalInfo from '../../hooks/usePersonalInfo';
import EditRole from '../../components/EditRole';

const { dashboard } = en;
const { proficiency, membership } = dashboard;

interface IPersonalInfo {
  attempts?: IAttempt[];
}

const PersonalInfo = ({ attempts }: IPersonalInfo) => {
  const { name, proficiencyObject, getMembership, getRole, isStudentRole } = usePersonalInfo();
  const { dispatch } = useDispatch(studySpaceSlice);

  useEffect(() => {
    dispatch(studySpaceActions.setProficiency({ proficiency: proficiencyObject }));
  }, [proficiencyObject]);

  return (
    <PersonalInfoContainer>
      <PersonalName>{name}</PersonalName>
      <InfoContainer>
        <InfoLabel>{membership}</InfoLabel>
        <Info>{getMembership()}</Info>
      </InfoContainer>
      <EditRole />
      {isStudentRole && (
        <InfoContainer>
          <InfoLabel>{proficiency}</InfoLabel>
          <Info>{proficiencyObject.text}</Info>
        </InfoContainer>
      )}
    </PersonalInfoContainer>
  );
};

export default PersonalInfo;
