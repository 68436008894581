import { useState } from 'react';
import { ICaseStudyOption, ICaseStudySubquestion } from '../../../../types';
import { ExpandableTag, Option, OptionsContainer } from './styled';
import ContentRenderer from '../ContentRenderer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { en } from '../../../../../i18n';
const { caseStudy } = en;
const { mobile } = caseStudy;

interface IExpandableOption {
  isDisabled?: boolean;
  row: ICaseStudySubquestion;
  renderRowAnswer: (row: ICaseStudySubquestion, option: ICaseStudyOption, index: number) => JSX.Element;
  index: number;
}
const ExpandableOption = ({ isDisabled, row, renderRowAnswer, index }: IExpandableOption) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const renderOption = (row: ICaseStudySubquestion, index: number) => {
    const optionContent = row.detail.options?.[index].detail.content;
    if (!optionContent) return null;
    return <ContentRenderer content={optionContent} />;
  };

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const renderExpandIcon = () => {
    if (isDisabled) return null;
    if (isExpanded) return <ExpandLessIcon onClick={toggleIsExpanded} />;
    return <ExpandMoreIcon onClick={toggleIsExpanded} />;
  };

  const renderOptions = () => {
    const height = isExpanded ? '10em' : '0';
    return (
      <OptionsContainer height={height}>
        {row.detail.options?.map((option, indexOption) => (
          <Option key={index}>
            {renderRowAnswer(row, option, index)}
            {renderOption(row, indexOption)}
          </Option>
        ))}
      </OptionsContainer>
    );
  };

  return (
    <>
      <ExpandableTag>
        <span>{mobile.expandablesOptionsLabel} </span>
        {renderExpandIcon()}
      </ExpandableTag>
      {renderOptions()}
    </>
  );
};

export default ExpandableOption;
