import { SLICE_NAME as topicsSlice, actions as topicsActions } from '../../StudySpace/redux/topics/slice';
import { SLICE_NAME as studySpaceSlice, actions as quizActions } from '../../StudySpace/redux/slice';
import { useNavigations, useDispatch, useTopicsRecommendations } from '../../hooks';
import { IAttempt } from '../../types';

interface IUseSubject {
  attempts?: IAttempt[];
}
const useSubject = ({ attempts }: IUseSubject) => {
  const { dispatch } = useDispatch(topicsSlice);
  const { dispatch: studySpaceDispatch } = useDispatch(studySpaceSlice);
  const { navigateToStudySpace } = useNavigations();
  const { getStrongTopics, getWeakTopics } = useTopicsRecommendations(attempts, { isRecent: true });
  const handleQuizClick = (ids: string[]) => {
    dispatch(topicsActions.setSelectedTopicIds({ selectedTopicIds: ids }));
    studySpaceDispatch(quizActions.quizAgain());
    navigateToStudySpace();
  };

  return { navigateToStudySpace, handleQuizClick, getStrongTopics, getWeakTopics };
};

export default useSubject;
