import {
  ExpandableTag,
  Expandable,
  ExpandableQuestion,
  ExpandablesContainer,
} from '../../SmartQuiz/question/CaseStudy/MatrixChoice/styled';

import ExpandableOption from './ExpandableOption';
import { IMatrixComponentUI } from './types';

const Expandables = ({ header, rows, response, onChange, horizontalOptionsMobile }: IMatrixComponentUI) => {
  const renderExpendables = () => {
    return rows.map((row, index) => {
      const onChangeRowOption = (optionId: string | number) => {
        onChange(row.id, optionId);
      };
      return (
        <Expandable key={index}>
          <ExpandableQuestion>{row.value}</ExpandableQuestion>

          <ExpandableOption
            horizontalMobile={horizontalOptionsMobile}
            onChange={onChangeRowOption}
            options={header}
            response={response?.[row.id]}
          />
          {/* <ExpandableOption isDisabled={isDisabled} index={index} renderRowAnswer={renderRowAnswer} row={row} /> */}
        </Expandable>
      );
    });
  };

  return <ExpandablesContainer>{renderExpendables()}</ExpandablesContainer>;
};

export default Expandables;
