import { en } from '../../../i18n';
import { ReactComponent as Practice } from '../../../images/icons/dashboard/parctice.svg';
import { ReactComponent as Assess } from '../../../images/icons/dashboard/assess.svg';
import { ReactComponent as Review } from '../../../images/icons/dashboard/review.svg';
import { ReactComponent as Analayze } from '../../../images/icons/dashboard/analyze.svg';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';

const icons = [Practice, Assess, Review, Analayze, Review];

enum ESelectedSection {
  PRACTICE,
  ASSESS,
  REVIEW,
  ANALYZE,
  NURSE_GPT,
  STUDY,
  APPLY,
}

export { icons, ESelectedSection };
