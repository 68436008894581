import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { actions } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  IAnswerQuestionResponse,
  ISubmitPromptResponse,
  IFetchSessionsResponse,
  IFetchSessionResponse,
  ILooseObject,
} from '../../types';
import { endpoints, NURSE_GPT_QUIZ_ID } from '../../constants';
import { QuestionResponseTransformer, FullAttemptsTransformer, GoogleTags } from '../../classes';
import { pollRequest } from '../../utils';
import { SendJsonMessage } from 'react-use-websocket/src/lib/types';

function* attemptQuiz() {
  try {
    const { data: attempts } = yield call(axiosRequest, {
      url: `${endpoints.fetchAttempts}&quizId=${NURSE_GPT_QUIZ_ID}`,
    });
    let attempt: IAttempt = attempts[0];
    if (!attempt) {
      const { data: result }: IStartQuizResponse = yield call(pollRequest, {
        url: endpoints.startQuiz,
        requestData: { body: { quizId: NURSE_GPT_QUIZ_ID } },
        method: ERequestMethods.POST,
      });
      const attemptId = result;
      if (!attemptId) throw new Error('Could not start quiz');
      const stoppingCriteria = (apiAttempt: any) => {
        return apiAttempt.data.attemptId === attemptId;
      };
      const { data: newAttempt }: IFetchAttemptResponse = yield call(
        pollRequest,
        {
          url: endpoints.fetchAttempt(attemptId),
        },
        { stopCallBack: stoppingCriteria },
      );
      attempt = newAttempt;
    }
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const { questionsTransformed } = transformedAttempt;
    const submitted = !!attempt.questions[0].response;
    yield put(actions.attemptQuizSuccess({ attempt, questions: questionsTransformed, submitted }));
  } catch (e) {
    yield put(actions.attemptQuizFailure({ errors: { message: e } }));
  }
}

function* submitAnswer(action: { payload: { questionId: string; attemptId: string; response: string } }) {
  try {
    const { payload } = action;
    const { questionId, response, attemptId } = payload;
    const questionResponseTransformer = new QuestionResponseTransformer();
    const transformedResponse = questionResponseTransformer.transformOut(
      { questionId, response: response || 'Joined without response' },
      true,
    );
    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(attemptId),
      requestData: {
        body: transformedResponse,
      },
      method: ERequestMethods.POST,
    });
    yield put(actions.submitAnswerSuccess());
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: { message: error } }));
  }
}

function* submitPrompt(action: {
  payload: { prompt: string; currentSessionId: string; sendJsonMessage: SendJsonMessage };
}) {
  try {
    const {
      payload: { prompt, currentSessionId, sendJsonMessage },
    } = action;
    yield call(sendJsonMessage, {
      action: 'stream',
      prompt,
      httpMethod: currentSessionId ? ERequestMethods.PUT.toUpperCase() : ERequestMethods.POST.toUpperCase(),
      ...(currentSessionId && { session_id: currentSessionId }),
    });
  } catch (error) {
    yield put(actions.submitPromptFailure({ errors: { webSocketError: { message: error } } }));
  }
}

function* fetchSessions(action: { payload: { lastSession?: ILooseObject } }) {
  try {
    const { lastSession } = action.payload;
    const { data }: { data: IFetchSessionsResponse[] } = yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?limit=20${
        lastSession ? `&after_id=${lastSession.sessionId}&after_created_at=${lastSession.createdAt / 1000}` : ''
      }`,
    });
    yield put(actions.fetchSessionsSuccess({ sessions: data }));
  } catch (error) {
    yield put(actions.fetchSessionsFailure({ errors: { message: error } }));
  }
}

function* fetchSession(action: { payload: { session: ILooseObject } }) {
  const { session } = action.payload;
  try {
    const { data }: { data: IFetchSessionResponse[] } = yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?session_id=${session.sessionId}&limit=10${
        session.messages?.length > 0 ? `&after=${session.messages[0].messageId}` : ''
      }`,
    });
    yield put(actions.fetchSessionSuccess({ session: data[0] }));
  } catch (error) {
    yield put(actions.fetchSessionFailure({ errors: { message: error }, session }));
  }
}

function* deleteSession(action: { payload: { sessionId: string } }) {
  try {
    const {
      payload: { sessionId },
    } = action;
    yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?session_id=${sessionId}`,
      method: ERequestMethods.DELETE,
    });
    yield put(actions.deleteSessionVisualSuccess({ sessionId }));
    yield delay(400);
    yield put(actions.deleteSessionSuccess({ sessionId }));
  } catch (error) {
    yield put(actions.deleteSessionFailure({ errors: { message: error } }));
  }
}

function* renameSession(action: { payload: { sessionId: string; title: string; originalTitle: string } }) {
  try {
    const {
      payload: { sessionId, title },
    } = action;
    yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}`,
      requestData: {
        body: {
          session_id: sessionId,
          session_title: title,
        },
      },
      method: ERequestMethods.PUT,
    });
    yield put(actions.renameSessionSuccess());
  } catch (error) {
    const {
      payload: { sessionId, originalTitle },
    } = action;
    yield put(actions.renameSessionFailure({ errors: { message: error }, sessionId, originalTitle }));
  }
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}

function* watchSubmitPrompt() {
  yield takeLatest(actions.submitPrompt, submitPrompt);
}

function* watchFetchSessions() {
  yield takeLatest(actions.fetchSessions, fetchSessions);
}

function* watchFetchSession() {
  yield takeLatest(actions.fetchSession, fetchSession);
}

function* watchDeleteSession() {
  yield takeLatest(actions.deleteSession, deleteSession);
}

function* watchRenameSession() {
  yield takeLatest(actions.renameSession, renameSession);
}

const sagas = [
  watchAttemptQuiz,
  watchSubmitAnswer,
  watchSubmitPrompt,
  watchFetchSessions,
  watchFetchSession,
  watchDeleteSession,
  watchRenameSession,
];

export default sagas;
