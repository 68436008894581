import BaseMembership from './BaseMembership';
import { en } from '../../../i18n';
import { SideBarQuizButton, SideBarQuizPrimaryButton } from '../../Home/styled';
import { membershipPath } from '../../Router/paths';
import CustomTooltip from '../../components/Tooltip';

const { smartQuiz } = en;
class QuizYourself extends BaseMembership {
  public free() {
    const context = super.getContext();
    const Component = context?.useWhiteButton ? SideBarQuizPrimaryButton : SideBarQuizButton;
    return {
      buttonDisabled: true,
      buttonDisableText: (
        <span>
          <Component link={membershipPath} variant="underlinedSpan">
            {smartQuiz.mustUpgradeButton}
          </Component>
          &nbsp;
          {smartQuiz.mustUpgrade}
        </span>
      ),
    };
  }
  public essential() {
    return {
      buttonDisabled: false,
      buttonDisableText: undefined,
    };
  }
  public allAccess() {
    return this.essential();
  }

  public useStrictFreeMembers() {
    return true;
  }

  public addonRequiredWithActiveMembership() {
    const context = super.getContext();
    const Component = context?.useWhiteButton ? SideBarQuizPrimaryButton : SideBarQuizButton;
    const buttonDisableText = (
      <CustomTooltip leftPopper="30px" marginTopPopper="15px" title={smartQuiz.addonFreeMemberText}>
        <span>
          <Component link={membershipPath} variant="underlinedSpan">
            {smartQuiz.addonRequiredButton}
          </Component>
        </span>
      </CustomTooltip>
    );

    return {
      buttonDisabled: true,
      buttonDisableText,
    };
  }

  public addonRequired() {
    const context = super.getContext();
    const Component = context?.useWhiteButton ? SideBarQuizPrimaryButton : SideBarQuizButton;
    const buttonDisableText = (
      <CustomTooltip leftPopper="30px" marginTopPopper="15px" title={smartQuiz.addonText}>
        <span>
          <Component link={membershipPath} variant="underlinedSpan">
            {smartQuiz.addonRequiredButton}
          </Component>
        </span>
      </CustomTooltip>
    );

    return {
      buttonDisabled: true,
      buttonDisableText,
    };
  }
}

export default QuizYourself;
