import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import { opacityInAnimation, slideAnimation } from '../../sharedStyled';

interface ITextContainerProps {
  width?: string;
}

interface IChoiceExplanationContainerProps {
  isSelected?: boolean;
  isCorrect?: boolean;
}

interface IQuestionRenderedContainerProps {
  marginBottom?: string;
  isGoingBack?: boolean;
  useOpacityAnimation?: boolean;
  padding?: string;
  textAreaMargin?: number;
  marginTop?: string;
}

interface IArithmeticAnswerProps {}

const TextContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['width']),
})<ITextContainerProps>(
  ({ width }) => `
  color: ${colors.black10};
  width: ${width || '100%'};
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 28px;
`,
);

const QuestionContainer = styled('div')`
  // img {
  //   width: 40%;
  //   border-radius: 5px;
  //   ${props => props.theme.breakpoints.down('sm')} {
  //     width: 100%;
  //   }
  //   ${props => props.theme.breakpoints.between('sm', 'md')} {
  //     width: 65%;
  //   }

  //   ${props => props.theme.breakpoints.between('md', 'lg')} {
  //     width: 70%;
  //   }
  // }

  img {
    width: 100%;
    border-radius: 5px;
  }
`;

const ChoicesContainer = styled('div')`
  margin-bottom: ${props => props.theme.spacing(5)};
  margin-top: ${props => props.theme.spacing(5)};
`;

const ChoiceContainer = styled('div')`
  position: relative;
  ${opacityInAnimation()}
`;

const Choice = styled('div')`
  display: flex;
  margin-bottom: ${props => props.theme.spacing(2.5)};
  gap: ${props => props.theme.spacing(1.25)};
  cursor: pointer;
  align-items: center;
  p {
    font-size: 1rem;
    margin: 0;
  }
  span {
    padding: 0px;
  }
  width: fit-content;
`;

const ChoiceExplanation = styled('div')`
  span {
    display: block;
    color: ${colors.grey400};
    font-size: 0.875rem;
    margin-bottom: 4px;
    font-weight: 700;
  }
  p {
    color: ${colors.grey400};
    font-size: 1rem;
    line-height: 24px;
    font-weight: 500;
  }
`;

const ChoiceExplanationContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSelected, isCorrect']),
})<IChoiceExplanationContainerProps>(
  ({ isCorrect, isSelected, theme }) => `
  background-color: ${colors.white};
  border-radius: 8px;
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};
  ${isSelected ? `border: 2px solid ${colors.purple40};padding-top: ${theme.spacing(4)};margin-top: 30px;` : ''}
  ${isCorrect ? `border: 2px solid ${colors.highlightGreen};` : ''}
  ${isSelected && !isCorrect ? `background-color: ${colors.lighterPurple};` : ''}
  overflow-x:auto;
  ${theme.breakpoints.down('$xxs')}{
    padding: ${theme.spacing(2)} ${theme.spacing(2)};
  } 
`,
);

const QuestionRenderContainer = styled('div', {
  shouldForwardProp: prop =>
    shouldForwardProps(prop, [
      'marginBottom',
      'isGoingBack',
      'useOpacityAnimation',
      'padding',
      'textAreaMargin',
      'marginTop',
    ]),
})<IQuestionRenderedContainerProps>(
  ({ theme, marginBottom, isGoingBack, useOpacityAnimation, padding, textAreaMargin, marginTop }) => `
  padding: ${padding || `56px 23.5% 2% 23.5%`};
  p {
    margin:0;
  }
  margin-bottom: ${marginBottom || '88px'};
  ${marginTop ? `margin-top:${marginTop};` : ''}
  ${theme.breakpoints.down('sm')}{
      ${marginTop && `margin-top:${parseInt(marginTop) / 2}px`}
  }
  textarea {
    resize: vertical;
    box-sizing: border-box;
    width: 100%;
    border-color: ${colors.lightGrey};
    border-width: 2px;
    border-radius: 8px;
    transition: border-color 0.3s linear;
    margin: ${theme.spacing(textAreaMargin || 5)} 0;
    padding: ${theme.spacing(1.25)};
    &:focus-visible,
    &:focus {
      outline: none;
      border-color: ${colors.primary} !important;
    }
    font-size: max(16px,1rem);
  }




  ${theme.breakpoints.down('sm')}{
    padding: 2% 5%;
    margin-bottom:${marginBottom === '0' ? '0' : '10%'};
  }

  ${theme.breakpoints.between('sm', 'md')} {
    padding: 2% 10%;
  }

  ${useOpacityAnimation ? opacityInAnimation() : slideAnimation(isGoingBack ? 'left' : 'right')}

  ${theme.breakpoints.down('sm')}{
    ${useOpacityAnimation ? opacityInAnimation() : slideAnimation(isGoingBack ? 'left' : 'right', 25)}
  }
`,
);

const ChoiceHeader = styled('div')`
  span:nth-child(1) {
    color: ${colors.charcoal};
    font-size: 0.875rem;
    margin-bottom: 4px;
    display: block;
    width: 80%;
    font-weight: 700;
  }
  span:nth-child(2) {
    color: ${colors.black10};
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 16px;
    display: block;
    width: 80%;
  }
`;

const QuestionExplanationContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCorrect']),
})<IChoiceExplanationContainerProps>(
  ({ theme, isCorrect }) => `
  background-color: ${isCorrect ? colors.lightestGreen : colors.pink};
  padding: ${theme.spacing(4)};
  border-radius: 8px;
  ${theme.breakpoints.down('$xxs')}{
    padding: ${theme.spacing(4)} ${theme.spacing(2)};
  } 
  ${opacityInAnimation()}
 
`,
);

const CorrectAnswer = styled('div')`
  margin-top: ${props => props.theme.spacing(0.75)};
  font-weight: 600;
`;

const QuestionExplanationText = styled('div')`
  margin-top: ${props => `${props.theme.spacing(3)}`};
`;

const ImageContainer = styled('div')`
  text-align: center;
  padding: 16px 0;
`;

const IconContainer = styled('div')`
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
`;

const ChoiceSelectionContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCorrect']),
})<IChoiceExplanationContainerProps>(
  ({ theme, isCorrect }) => `
  position: absolute;
  background-color: ${isCorrect ? colors.highlightGreen : colors.purple40};
  font-size: 0.875rem;
  border-radius: 4px;
  color: ${colors.white};
  padding: 3px 15px;
  left: 22px;
  transform: translateY(-50%);
`,
);

const ArithmeticAnswerContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCorrect', 'showBorder']),
})<IArithmeticAnswerProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 8px;
  
  border-radius:8px;


  font-weight: 700;
  margin-bottom: ${theme.spacing(5)};
  margin-top: ${theme.spacing(5)};
  .MuiInput-root {
    color: black;
  }
  input {
    width: auto;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  div {
    width: auto;
  }

  svg {
    margin-left:${theme.spacing(2)};
  }

  p {
    font-size:1rem;
    line-height:normal;
    text-align:center;
  }
`,
);

export {
  TextContainer,
  QuestionContainer,
  ChoicesContainer,
  Choice,
  QuestionRenderContainer,
  ChoiceContainer,
  ChoiceExplanation,
  ChoiceExplanationContainer,
  ChoiceHeader,
  QuestionExplanationContainer,
  CorrectAnswer,
  QuestionExplanationText,
  ImageContainer,
  IconContainer,
  ChoiceSelectionContainer,
  ArithmeticAnswerContainer,
};
