import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOnboardingState, IQuestion, ILooseObject, IAttempt } from '../../types';
import { RootState } from '../../redux/store';
const SLICE_NAME = 'onboarding';

const initialState: IOnboardingState = {
  loading: false,
  loadingQuestion: false,
  errors: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    attemptQuiz: (state, action: PayloadAction<{ quizId: string; userId: string }>) => {
      state.loadingQuestion = true;
      state.quizId = action.payload.quizId;
    },

    attemptQuizSuccess: (state, action: PayloadAction<{ attempt?: IAttempt }>) => {
      state.loadingQuestion = false;
      state.attempt = action.payload.attempt;
    },

    transformAttemptRequest: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      state.loadingQuestion = true;
    },
    transformAttemptSuccess: (state, action: PayloadAction<{ questions: { [key: string]: IQuestion } }>) => {
      state.questionsTransformed = action.payload.questions;
      state.loadingQuestion = false;
    },

    transformAttemptFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loadingQuestion = false;
    },
    submitAnswers: (
      state,
      action: PayloadAction<{
        questionIds: string[];
        attempt: IAttempt;
        answers: (ILooseObject | string | undefined)[];
        history: any;
        isSkipping?: boolean;
      }>,
    ) => {
      state.loading = true;
    },

    submitAnswerSuccess: state => {
      state.loading = false;
    },
    submitAnswerFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loading = false;
    },
  },
});

const onboardingSelectors = {
  allState: (state: RootState): typeof initialState => state.onboarding,
};

const actions = { ...slice.actions };

export { onboardingSelectors, SLICE_NAME, actions };
export default slice.reducer;
