import { EPaymentSteps, IPaymentFormState } from '../../types';
import {
  PriceFieldsWrapper,
  PriceFieldWrapper,
  Price,
  InnerPriceFieldsWrapper,
  TaxPrice,
  PriceField,
  DiscountPrice,
} from './styled';
import { en } from '../../../i18n';
import { useSelector } from 'react-redux';
import { subscriptionsSelectors } from '../redux/slice';
import { centsToDollars } from '../../utils';
import PromotionCode from './PromotionCode';
import Spinner from '../../components/Spinner';

const { billing } = en;

interface IPriceFields {
  formState: IPaymentFormState;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenPromoCode: () => void;
  onApplyPromoCode: () => void;
  resetPromoCode: () => void;
}

const PriceFields = ({ formState, handleChange, onOpenPromoCode, onApplyPromoCode, resetPromoCode }: IPriceFields) => {
  const {
    loadingCreateSubscription,
    currentSubscription,
    paymentStep,
    errors,
    loadingDebounce,
    isPriceIdChanged,
  } = useSelector(subscriptionsSelectors.allState);

  const loadingInput = loadingCreateSubscription || loadingDebounce;

  const getFieldValue = (fieldName: 'subtotal' | 'total' | 'tax') => {
    if (fieldName === 'subtotal' && currentSubscription && !isPriceIdChanged) {
      return centsToDollars(900);
      // const cents = currentSubscription.latestInvoice?.[fieldName];
      // console.log(cents, 'cents');
      // if (cents) return centsToDollars(cents);
    }

    if (loadingInput) return <Spinner inline loading />;
    if (!currentSubscription || paymentStep === EPaymentSteps.INPUT_FIELDS) return '-';
    const cents = currentSubscription.latestInvoice?.[fieldName] || 0;
    return centsToDollars(cents);
  };

  const getTotalDiscountAmount = () => {
    if (!currentSubscription) return '-';
    if (!currentSubscription.latestInvoice?.totalDiscountAmounts) {
      if (loadingInput) return <Spinner inline loading />;
      return '-';
    }
    const discountAmount =
      currentSubscription.latestInvoice.totalDiscountAmounts?.reduce((acc, discount) => acc + discount.amount, 0) || 0;
    return centsToDollars(discountAmount);
  };

  const isDiscounted = currentSubscription?.latestInvoice?.totalDiscountAmounts?.length || false;

  return (
    <PriceFieldsWrapper>
      <InnerPriceFieldsWrapper>
        <PriceFieldWrapper>
          <PriceField>
            <span>{billing.subTotal}:</span>
            <Price>{getFieldValue('subtotal')}</Price>
          </PriceField>
        </PriceFieldWrapper>

        <PriceFieldWrapper applyBorder>
          <PromotionCode
            errors={errors.promoCode}
            handleChange={handleChange}
            formState={formState}
            onOpenPromoCode={onOpenPromoCode}
            onApplyPromoCode={onApplyPromoCode}
            resetPromoCode={resetPromoCode}
          />

          {isDiscounted && (
            <PriceField>
              <span>{billing.discount}:</span>
              <DiscountPrice>{getTotalDiscountAmount()}</DiscountPrice>
            </PriceField>
          )}

          <PriceField>
            <span>{billing.taxes}:</span>
            <TaxPrice>{getFieldValue('tax')}</TaxPrice>
          </PriceField>
        </PriceFieldWrapper>

        <PriceFieldWrapper applyBorder>
          <PriceField>
            <span>{billing.total}:</span>
            <Price>{getFieldValue('total')}</Price>
          </PriceField>
        </PriceFieldWrapper>
      </InnerPriceFieldsWrapper>
    </PriceFieldsWrapper>
  );
};

export default PriceFields;
