import { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import useAttempts from './useAttempts';
import {
  EQuestionTypes,
  IAttempt,
  IAttemptQuestion,
  ICaseStudy,
  ILooseObject,
  IQuestion,
  IQuestionAnswersState,
} from '../types';
import { topicsSelectionQuestion } from '../OnBoarding/constants';

// TODO: Review results component should use this hook
// TODO: Refactor useMemo hook and break down

interface ITopicsRecommendationsOptions {
  thresholdTopics?: number;
  useOnbaordingWeakTopics?: boolean;
  questionAnswers?: IQuestionAnswersState;
  transformedQuestions?: {
    [key: string]: IQuestion;
  };
  isRecent?: boolean;
}

const useTopicsRecommendations = (attempts?: IAttempt[], options: ITopicsRecommendationsOptions = {}) => {
  const {
    thresholdTopics = 5,
    useOnbaordingWeakTopics = true,
    questionAnswers,
    transformedQuestions,
    isRecent,
  } = options;
  const { onBoardingAttempts, isOnboarded } = useAttempts();

  const { topicsRecommendation, total, topicsResults } = useMemo(() => {
    const topicsRecommendation: ILooseObject = {};
    const topicsResults: ILooseObject = {};

    const setStored = (question: IAttemptQuestion) => {
      const { groupIds, parsedMark, response, subAnswers } = question;
      if (!response) return null;
      const isNgn = !!subAnswers;
      const ngnCount = Object.values(subAnswers || {}).filter((sub: ILooseObject) => sub.isDone).length;
      const ngnMarks = Object.values(subAnswers || {})
        .filter((sub: ILooseObject) => sub.isDone)
        .map((sub: ILooseObject) => sub.parsedMark);

      return { groupIds, parsedMark, isNgn, ngnCount, ngnMarks };
    };

    const setLive = (question: IAttemptQuestion) => {
      if (!questionAnswers || !transformedQuestions) return setStored(question);
      const { groupIds } = question;
      const isNgn = transformedQuestions[question.questionId]?.questionType === EQuestionTypes.NGN_CASE_STUDY;
      const ngnCount = ((transformedQuestions[question.questionId] as unknown) as ICaseStudy)?.questions?.length;
      const ngnMarks = Object.values(questionAnswers[question.questionId]?.subAnswers || {}).map(
        (sub: ILooseObject) => sub.parsedMark,
      );
      const { parsedMark } = questionAnswers[question.questionId] || {};
      return { groupIds, parsedMark, isNgn, ngnCount, ngnMarks };
    };

    let total = 0;
    attempts?.forEach(attempt => {
      if (isRecent && differenceInDays(new Date(), new Date(attempt.ctime)) > 30) return;
      total += attempt.totalDisplayQuestions;
      attempt.questions.forEach(question => {
        const result = questionAnswers ? setLive(question) : setStored(question);
        if (!result) return;
        const { groupIds, parsedMark, isNgn, ngnCount, ngnMarks } = result;

        [...new Set(groupIds)].forEach(id => {
          if (!topicsResults[id]) {
            topicsResults[id] = {
              totalMark: 0,
              totalMaximumMark: 0,
              percentage: 0,
              totalQuestions: 0,
            };
          }

          if (isNgn) {
            topicsResults[id].totalQuestions += ngnCount;
            ngnMarks.forEach(parsedMark => {
              topicsResults[id].totalMark += parsedMark?.mark || 0;
              topicsResults[id].totalMaximumMark += parsedMark?.maximumMark || 0;
            });
          } else {
            topicsResults[id].totalMark += parsedMark?.mark || 0;
            topicsResults[id].totalMaximumMark += parsedMark?.maximumMark || 0;
            topicsResults[id].totalQuestions += 1;
          }
          topicsResults[id].percentage = topicsResults[id].totalMark / topicsResults[id].totalMaximumMark;
        });
      });
    });
    Object.keys(topicsResults).forEach(topicId => {
      const { percentage } = topicsResults[topicId];
      if (percentage >= 0.75 && topicsResults[topicId].totalQuestions >= thresholdTopics)
        topicsRecommendation[topicId] = {
          grade: 'strong',
          percentage,
        };
      else {
        topicsRecommendation[topicId] = {
          percentage,
          grade: 'weak',
        };
      }
    });
    return { topicsRecommendation, total, topicsResults };
  }, [attempts]);

  const totalMark = Object.values(topicsResults).reduce((accum, current) => {
    return accum + current.totalMark;
  }, 0);

  const totalMaximumMark = Object.values(topicsResults).reduce((accum, current) => {
    return accum + current.totalMaximumMark;
  }, 0);

  const getStrongTopics = () =>
    Object.keys(topicsRecommendation)
      .sort((a, b) => topicsRecommendation[b].percentage - topicsRecommendation[a].percentage)
      .filter(topicId => topicsRecommendation[topicId].grade === 'strong');

  const getWeakTopics = () => {
    let onboardingWeakTopics = [];
    if (isOnboarded && useOnbaordingWeakTopics) {
      const onboardingAttempt = onBoardingAttempts?.find(attempt => attempt.isDone);
      const onboardingTopicsQuestion = onboardingAttempt?.questions.find(
        question => question.questionId === topicsSelectionQuestion,
      );

      let responseString = onboardingTopicsQuestion?.response?.toString();
      if (responseString === '"_"') {
        responseString = '[]';
      }

      onboardingWeakTopics = JSON.parse(responseString || '[]');
      onboardingWeakTopics = onboardingWeakTopics.filter((topic: string) => !getStrongTopics().includes(topic));
    }
    const weakTopics = Object.keys(topicsRecommendation)
      .sort((a, b) => topicsRecommendation[a].percentage - topicsRecommendation[b].percentage)
      .filter(topicId => topicsRecommendation[topicId].grade === 'weak')
      .concat(onboardingWeakTopics);
    return [...new Set(weakTopics)];
  };

  return { getStrongTopics, getWeakTopics, total, topicsResults, totalMark, totalMaximumMark };
};

export default useTopicsRecommendations;
