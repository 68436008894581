import { ECardBrands } from './enums';
import { ILooseObject } from './general';

interface ISubscription {
  cancelAtPeriodEnd?: boolean;
  isDeprecated: boolean;
  created?: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
  formattedEndDate?: string;
  id?: string;
  status?: string;
  latestInvoice?: {
    paymentIntent: {
      clientSecret: string;
    };
    totalDiscountAmounts: { amount: number }[];
    subtotal: number;
    tax: number;
    total: number;
    subTotalExcludingTax: number;
  };
  items?: {
    data: [
      {
        id: string;
        price: {
          id: string;
        };
      },
    ];
  };

  address: ICustomerAddress;
  paymentMethod?: {
    brand: ECardBrands;
    expMonth: number;
    expYear: number;
    last4: string;
    id: string;
  };
}

interface ICustomerAddress {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
}

interface IPaymentFormState {
  name: string;
  email: string;
  address: IStateCustomerAddress;
  promoCode?: string;
  isPromoCodeApplied?: boolean;
}

interface IStateCustomerAddress {
  city: string;
  country: {
    value: string;
    label: string;
  };
  line1: string;
  postalCode: string;
}

enum EStripeErrorCodes {
  INVALID_ADDRESS = 'customer_tax_location_invalid',
  PAYMENT_INTENT_INCOMPATIBLE = 'payment_intent_incompatible_payment_method',
}

enum EStripeDeclineCodes {
  TEST_MODE = 'live_mode_test_card',
}

type SubscriptionModalVariantType = 'upgraded' | 'cancelled' | 'cancel' | 'addonPurchased' | 'resubscribed';

interface IAddress {
  formState: IPaymentFormState;
  handleCountryChange: (country: ILooseObject) => void;
  errors: {
    global: string;
    address: string;
    card: string;
    promoCode: string;
  };

  handleAddressFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export { EStripeErrorCodes, EStripeDeclineCodes };
export type {
  ISubscription,
  SubscriptionModalVariantType,
  ICustomerAddress,
  IPaymentFormState,
  IStateCustomerAddress,
  IAddress,
};
