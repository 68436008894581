import { useEffect, useState } from 'react';

const useRemoveChatWidget = () => {
  const [widgetLoaded, setWidgetLoaded] = useState(false);

  const intervalId = setInterval(() => {
    const chatWidgetObject = (window as any).fcWidget;
    const chatWidgetElement = document.getElementById('fc_frame');
    if (chatWidgetElement || chatWidgetObject) {
      setWidgetLoaded(true);
      clearInterval(intervalId);
    }
  }, 100);

  useEffect(() => {
    const chatWidgetObject = (window as any).fcWidget;
    const chatWidgetElement = document.getElementById('fc_frame');
    if (chatWidgetElement) chatWidgetElement.style.display = 'none';
    if (chatWidgetObject) chatWidgetObject.hide();
    return () => {
      clearInterval(intervalId);
      if (chatWidgetElement) chatWidgetElement.style.display = 'block';
      if (chatWidgetObject) chatWidgetObject.show();
    };
  }, [widgetLoaded]);
};

export default useRemoveChatWidget;
