import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { opacityInAnimation } from '../../sharedStyled';
import { TFeedbackPositions } from './Fields/types';
import { shouldForwardProps } from '../../utils';

interface IFeedbackContainer {
  isError?: boolean;
  position?: TFeedbackPositions;
}

const FormContainer = styled('div')`
  transition: width 0.3s ease-in;
  .form-button {
    margin-top: ${props => props.theme.spacing(3)};
  }
  .form-field {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    input {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
      color: transparent !important;
    }
    [data-autocompleted] {
      color: white !important;
    }
  }

  .form-field-label {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.darkGrey};
    span {
      color: red;
    }
  }

  .MuiFormHelperText-root {
    color: black;
    font-weight: 600;
    margin-left: 0;
    font-size: 0.875rem;
    line-height: 20px;
  }

  .MuiInputBase-root {
    color: ${colors.black};
    font-weight: 600;
    font-size: 16px;
  }
`;

const FormTitle = styled('div')`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: ${props => props.theme.spacing(6.125)};
  display: block;
  position: relative;
`;

const FormSubTitle = styled('div')`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black10};
`;

const FeedbackContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isError', 'position']),
})<IFeedbackContainer>(
  ({ theme, isError, position }) => `

  background: ${isError ? colors.errorRed5 : colors.lightestGreen};

  padding: ${theme.spacing(3)};
  border-radius: 8px;
  h2 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: ${theme.spacing(0.5)};
    margin-top: 0;
    color:${isError ? colors.errorRed : colors.black};
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    color:${isError ? colors.errorRed : colors.black};
  }
  ${opacityInAnimation()};
  ${position === 'aboveFields' ? `margin-top:-31px; margin-bottom:${theme.spacing(2.25)};` : ''}
  ${position === 'aboveFieldsWithNoMargin' ? `margin-bottom:${theme.spacing(2.25)};` : ''}
  ${
    position === 'topOfForm' &&
    `
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 16px 16px 0 0;
      padding: 17px;
      text-align: center;
      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 17px;
      }
  `
  }
`,
);

export { FormContainer, FormSubTitle, FormTitle, FeedbackContainer };
