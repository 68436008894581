import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDispatch from './useDispatch';
import { actions as studySpaceActions, quizSelectors } from '../StudySpace/redux/slice';
import { getMonthAgo } from '../utils';

const useInitializeAttempts = (isLoggedIn: boolean) => {
  const { onBoardingAttempts, loadingOnBoardingAttempts, placementAttempts, loadingPlacementAttempts } = useSelector(
    quizSelectors.allState,
  );
  const { dispatch } = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !onBoardingAttempts && !loadingOnBoardingAttempts) {
      dispatch(studySpaceActions.fetchOnBoardingAttempts());
    }
    if (isLoggedIn && !placementAttempts && !loadingPlacementAttempts) {
      dispatch(studySpaceActions.fetchPlacementAttempts());
    }
  }, [isLoggedIn]);
};

export default useInitializeAttempts;
