import { useCallback } from 'react';
import { useAppDispatch } from '../redux/store';
import { actions as globalActions } from '../redux/globalSlice';

const useDispatch = (sliceName?: string) => {
  const dispatch = useAppDispatch();

  const dispatchWithLoading = useCallback(
    (action: any) => {
      dispatch(action);
      dispatch(globalActions[`${sliceName}SetGlobalState`]({ loading: true, errors: {} }));
    },
    [dispatch],
  );

  return { dispatch, dispatchWithLoading };
};

export default useDispatch;
