import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .tab-title {
    margin-bottom: 0px !important;
  }
  margin-bottom: 24px;
  button {
    padding: 8px 12px;
    min-width: 160px;
    font-size: 0.875rem;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    button,
    span {
      width: 100%;
    }
  }
`;

const NoQuizzes = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  padding-top: 12vh;
  padding-bottom: 12vh;
`;

const NoQuizzesTextDisclaimer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${colors.black};
  h1 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
  }
`;

export { TitleContainer, NoQuizzes, NoQuizzesTextDisclaimer };
