import { styled } from '@mui/material/styles';
import MUIDrawer from '@mui/material/Drawer';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import { opacityInAnimation, thinScrollbar } from '../../sharedStyled';

interface IDrawerStyled {
  applyMargin: boolean;
}

interface IPersonalizedQuizContainer {
  smallMargin?: boolean;
}

const Drawer = styled(MUIDrawer, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['applyMargin']),
})<IDrawerStyled>(
  ({ theme, applyMargin }) => `
  z-index: 4 !important;
  .MuiDrawer-paperAnchorLeft {
    padding-top: ${applyMargin ? '50px' : '50px'};
  
  }
  .MuiPaper-root {
    ${thinScrollbar};
    transition: all 0.2s linear;
    z-index: 4;
    .MuiDrawer-paperAnchorLeft {
      margin-top: 150px;
    }
  }

  .MuiDrawer-paper {
    width: min(23vw, 460px);
    box-sizing: border-box;
  
  }
  ${theme.breakpoints.down('lg')} {
    .MuiDrawer-paper {
      width: 50vw
    }
    .MuiDrawer-paperAnchorLeft {
      margin-top: ${applyMargin ? '0px' : '150px'};
      padding-top: 64px;
    }
  }
  ${theme.breakpoints.down('sm')} {
    .MuiDrawer-paper {
      width: 100vw;
    }
  }
`,
);

const FreeUserDrawer = styled(Drawer, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['applyMargin']),
})<IDrawerStyled>(
  ({ theme, applyMargin }) => `
  .MuiDrawer-paperAnchorLeft {
    padding-top: ${applyMargin ? '100px' : '50px'};
  
  }
   .MuiDrawer-paperAnchorLeft {
    ${theme.breakpoints.down('lg')} {
      margin-top: ${applyMargin ? '100px' : '0px'};
      padding-top:0px;
    }
    ${theme.breakpoints.down('sm')} {
      margin-top:  ${applyMargin ? '120px' : '0px'};
      padding-top:0px;
    }
  }
`,
);

const DrawerContainer = styled('div')`
  width: 100%;
  box-sizing: border-box;
  //margin-top: 64px;
  padding: 29px 16px;
  color: ${colors.black};
  ${props => props.theme.breakpoints.down('lg')} {
    margin-top: 0;
  }
`;

const PersonalizedQuizContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['smallMargin']),
})<IPersonalizedQuizContainer>(
  ({ theme, smallMargin }) => `
  background: ${colors.purple15};
  margin-bottom: ${smallMargin ? theme.spacing(3) : theme.spacing(5)};
  padding: 24px 12px;
  border-radius: 8px;
  button > span {
    font-size: 0.875rem !important;
  }
  text-align:center;
  svg {
    margin-bottom:16px;
  }
`,
);

const SmartCLEXContainer = styled(PersonalizedQuizContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  color: ${colors.black};
  margin-top: 8px;
  ${opacityInAnimation()};
  padding-bottom: 0px;
`;

const SmartCLEXHeaderContainer = styled('div')`
  width: 100%;
  text-align: center;
`;

const SmartCLEXTitle = styled('h3')`
  font-size: 1rem;
  margin: 0;
  color: ${colors.primary};
  margin-bottom: 8px;
`;

const SmartCLEXSubTitle = styled('p')`
  font-size: 1rem;
  margin: 0;
`;

const SmartCLEXInfoContainer = styled('div')`
  font-size: 0.875rem;
  border-bottom: 1px solid ${colors.purple10};
  margin-top: ${props => props.theme.spacing(2)};
`;

const SmartCLEXInfoTitle = styled('div')`
  margin: 0;
  font-weight: 700;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const SmartCLEXInfoList = styled('div')`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
  font-weight: 600;
`;

const SmartCLEXButtonContainer = styled('div')`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 16px 0px;
`;

const PersonalizeQuizTitle = styled('h3')`
  font-size: 1rem;

  margin: 0;
  font-weight: 700;
`;

const SideBarCardTitle = styled('h3')`
  font-size: 1rem;
  margin: 0;
  margin-bottom: 12px;
  font-weight: 700;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const SideBarCardBody = styled('div')`
  font-size: 0.875rem;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
  width: 90%;
`;

const PersonalizeQuizSubTitle = styled('span')`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacing(2)};
  display: block;
`;

const CaseStudyTopicsContainer = styled('div')``;

const CaseStudyTopicsDescription = styled('div')`
  h3 {
    font-weight: 700;
    margin: 0;
    color: ${colors.purple600};
    font-size: 0.875rem;
  }

  span {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
    margin-top: 6.5px;
  }

  svg {
    display: inline;
  }
  margin-bottom: ${props => props.theme.spacing(2)};
  .MuiCircularProgress-root {
    margin-top: ${props => props.theme.spacing(25)};
  }
`;

const TitleIconWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const TopicsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2.5)};
  // ${props => props.theme.breakpoints.up('sm')} {
  //   max-height: calc(100vh - 360px);
  //   ${thinScrollbar};
  //   overflow-y: auto;
  // }
`;

const Topic = styled('div')`
  color: ${colors.purple600};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${colors.highlightBlue};
  }
  &::first-letter {
    text-transform: capitalize;
  }
`;

const FreeMemberTopic = styled('div')`
  color: ${colors.grey500};
  font-size: 1rem;
  cursor: not-allowed;
  &::first-letter {
    text-transform: capitalize;
  }
`;
const IconContainer = styled('div')``;

const BetaTag = styled('span')`
  font-weight: 700;
  font-size: 0.75rem;
  background-color: ${colors.blue50};
  color: ${colors.highlightBlue};
  border-radius: 24px;
  padding: 1px 12px;
`;

const NurseGPTText = styled('div')`
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
`;

const PersonalInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const PersonalName = styled('h1')`
  color: ${colors.black};
  font-weight: 700;
  font-size: 1.25rem;
`;

const InfoLabel = styled('div')`
  color: ${colors.grey500};
  font-weight: 600;
  font-size: 0.875rem;
`;

const Info = styled('div')`
  font-weight: 700;
  font-size: 0.875rem;
  color: ${colors.black};
`;

const InfoEditable = styled(Info)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

const DropdownContainer = styled('div')`
  width: 100%;

  .MuiInputBase-root {
    width: 90%;
    position: relative;
  }
`;

const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-bottom: 1.75rem;
`;

const SmartCLEXInactiveBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SmartCLEXInactiveBodyTitle = styled('span')`
  display: block;
  font-weight: 700;
`;

const SmartCLEXInactiveBodyText = styled('span')``;

export {
  Drawer,
  DrawerContainer,
  PersonalizedQuizContainer,
  PersonalizeQuizTitle,
  PersonalizeQuizSubTitle,
  CaseStudyTopicsContainer,
  CaseStudyTopicsDescription,
  TopicsContainer,
  Topic,
  TitleIconWrapper,
  FreeUserDrawer,
  FreeMemberTopic,
  IconContainer,
  SideBarCardTitle,
  BetaTag,
  NurseGPTText,
  PersonalInfoContainer,
  PersonalName,
  InfoLabel,
  Info,
  InfoContainer,
  SmartCLEXContainer,
  SmartCLEXTitle,
  SmartCLEXSubTitle,
  SmartCLEXHeaderContainer,
  SmartCLEXInfoContainer,
  SmartCLEXInfoTitle,
  SmartCLEXInfoList,
  SmartCLEXButtonContainer,
  SideBarCardBody,
  InfoEditable,
  DropdownContainer,
  SmartCLEXInactiveBody,
  SmartCLEXInactiveBodyText,
  SmartCLEXInactiveBodyTitle,
};
