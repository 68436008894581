import { styled } from '@mui/material';
import { colors } from '../../../themes';

const PieChartWrapper = styled('div')`
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${colors.white};
  & > *:nth-child(3) {
    width: 100%;
  }
  h1 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10;
  }
  h2 {
    font-size: 0.875rem;
    front-weight: 600;
    margin-bottom: 10;
    color: ${colors.darkGrey};
    text-align: center;
    width: 66%;
    margin: auto;
    ${props => props.theme.breakpoints.down('md')} {
      width: 80%;
    }
    ${props => props.theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
`;

export { PieChartWrapper };
