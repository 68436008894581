import BaseMembership from './BaseMembership';
import { FreeMemberTopic, FreeUserDrawer } from '../../Home/SideBar/styled';
import { StyledComponent } from '@mui/styles/styled';
import { FreeQuizCard } from '../../sharedStyled';

class SideBarStyled extends BaseMembership {
  public free(): { Container: StyledComponent<any>; Topic: StyledComponent<any>; QuizCard: StyledComponent<any> } {
    return { Container: FreeUserDrawer, Topic: FreeMemberTopic, QuizCard: FreeQuizCard };
  }
  public essential() {
    return {};
  }
  public allAccess() {
    return this.essential();
  }

  public trialling(): { Container: StyledComponent<any> } {
    return {
      Container: FreeUserDrawer,
    };
  }

  public sponsored() {
    return this.trialling();
  }

  public useStrictSubscriptions() {
    return false;
  }

  public useStrictFreeMembers() {
    return true;
  }
}

export default SideBarStyled;
