import { useState } from 'react';
import { StyledIconButton, Menu, MenuItem, MenuContainer, UserName, InfoRow, BottomMenuContainer } from './styled';
import { ReactComponent as Arrow } from '../../images/polygon.svg';
import { ReactComponent as Star } from '../../images/icons/header/star.svg';
import { ReactComponent as Edit } from '../../images/icons/header/edit.svg';
import { ReactComponent as SignOut } from '../../images/icons/header/signOut.svg';
import { en } from '../../i18n';
import { membershipPath, onBoardingPath } from '../Router/paths';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { useNavigations, usePersonalInfo, useSubscriptions } from '../hooks';
import { Info, InfoContainer, InfoLabel } from '../Home/SideBar/styled';
import Button from '../components/Button';
import EditRole from '../components/EditRole';

const { profileMenu } = en.header;
const { dashboard, smartQuiz } = en;
const { proficiency, membership } = dashboard;
const { mustUpgradeButton } = smartQuiz;

const { takenQuizzes, answeredQuestions, topics } = profileMenu;

interface IProfileMenu {
  userName?: string;
  logout: () => void;
  onlyLogout?: boolean;
  isFullyOnboarded?: boolean;
}
const ProfileMenu = ({ userName = 'A', logout, onlyLogout = false, isFullyOnboarded = true }: IProfileMenu) => {
  const firstLetter = userName.charAt(0).toUpperCase();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    name,
    getMembership,
    proficiencyObject,
    numberOfQuestions,
    numberOfQuizzes,
    numberOfTopics,
    isStudentRole,
  } = usePersonalInfo();
  const { isActive } = useSubscriptions({ useFetchSubscriptions: false });
  const { navigateToMembership } = useNavigations();

  const toggleMenu = (e: React.SyntheticEvent) => {
    setIsOpen(isOpen => !isOpen);
  };

  const closeMenu = (e: MouseEvent) => {
    const target = e.target as any;
    if (target?.className) {
      if (typeof target?.className === 'string') {
        if (target.className.includes('MuiMenuItem')) return;
      }
    }

    setIsOpen(false);
  };

  const studentInfo = (
    <>
      <InfoContainer>
        <InfoLabel>{proficiency}</InfoLabel>
        <Info>{proficiencyObject.text}</Info>
      </InfoContainer>

      <InfoRow>
        <InfoContainer>
          <InfoLabel>{takenQuizzes}</InfoLabel>
          <Info>{numberOfQuizzes}</Info>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>{answeredQuestions}</InfoLabel>
          <Info>{numberOfQuestions}</Info>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>{topics}</InfoLabel>
          <Info>{numberOfTopics}</Info>
        </InfoContainer>
      </InfoRow>
    </>
  );

  const renderStudentInfo = () => {
    if (isStudentRole) return studentInfo;
    return null;
  };

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <StyledIconButton
        allowHover={!isOpen}
        //allowHover={false}
        size="large"
        aria-label="show menu"
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        {firstLetter}
        <Menu onClick={e => e.stopPropagation()} open={isOpen}>
          <MenuContainer>
            <UserName>{name}</UserName>
            <InfoContainer>
              <InfoLabel>{membership}:</InfoLabel>
            </InfoContainer>
            <InfoRow className="info-container-editRole">
              {getMembership()}
              {!isActive && (
                <Button onClick={navigateToMembership} size="small" variant="secondary">
                  {mustUpgradeButton}
                </Button>
              )}
            </InfoRow>

            <EditRole />
            {renderStudentInfo()}
          </MenuContainer>
          <Arrow />
          <BottomMenuContainer>
            {!onlyLogout && (
              <Link to={membershipPath}>
                <MenuItem>
                  <Star />
                  {profileMenu.membership}
                </MenuItem>
              </Link>
            )}
            {/* {!isFullyOnboarded && (
              <Link to={onBoardingPath}>
                <MenuItem>
                  <Edit />
                  {profileMenu.completeProfile}
                </MenuItem>
              </Link>
            )} */}
            <MenuItem onClick={logout}>
              <SignOut />
              {profileMenu.signOut}
            </MenuItem>
          </BottomMenuContainer>
        </Menu>
      </StyledIconButton>
    </OutsideClickHandler>
  );
};

export default ProfileMenu;
