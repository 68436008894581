import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  ILooseObject,
  TApplyResponse,
  IAnswerQuestionResponse,
} from '../../types';
import { endpoints } from '../../constants';
import {
  QuestionResponseTransformer,
  FullAttemptsTransformer,
  GoogleTags,
  AllAttemptsTransformer,
} from '../../classes';
import { pollRequest } from '../../utils';
import { applyQuizIds } from '../../StudySpace/staticConstants';
import ApplyAnswersTransformer from '../../classes/DataTransformers/ApplyAnswersTransformer';
import { v1 as uuidv1 } from 'uuid';

function* attemptQuiz(action: { payload: { quizId: string } }) {
  try {
    const { quizId } = action.payload;
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');
    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt.data.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const allAttempsTransformer = new AllAttemptsTransformer();
    const fullTransformedAttempts: IAttempt[] = yield call(
      [allAttempsTransformer, allAttempsTransformer.transform],
      [transformedAttempt],
    );

    //yield put(studySpaceActions.fetchApplyAttemptsSuccess({ attempts: fullTransformedAttempts }));
    yield put(actions.fetchApplyAttemptsSuccess({ attempts: fullTransformedAttempts }));
    yield put(actions.attemptQuizSuccess({}));
  } catch (e) {
    yield put(actions.attemptQuizFailure({ errors: { message: e } }));
  }
}

function* fetchApplyAttempts() {
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of applyQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}` });
        attempts = data.concat(attempts);
      }
    });
    attempts = attempts.sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime());

    const allAttemptsTransformer = new AllAttemptsTransformer();
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    const currentApplyAttempt =
      transformedAttempts?.find(attempt => attempt.isDone) ||
      //transformedAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      transformedAttempts?.[0];
    const transformedAttempt: IAttempt = yield call(
      [attemptsTransformer, attemptsTransformer.transform],
      currentApplyAttempt,
    );

    const answers = new ApplyAnswersTransformer().transform(transformedAttempt);

    yield put(actions.setQuestionAnswers({ questionAnswers: answers }));
    yield put(actions.fetchApplyAttemptsSuccess({ attempts: [transformedAttempt] }));
  } catch (error) {
    yield put(actions.fetchApplyAttemptsFailure());
  }
}

function* transformAttempt(action: { payload: { attempt: IAttempt } }) {
  try {
    const { attempt } = action.payload;

    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const { questionsTransformed } = transformedAttempt;
    yield put(actions.transformAttemptSuccess({ questions: questionsTransformed }));
    yield put(actions.fetchApplyAttemptsSuccess({ attempts: [transformedAttempt] }));
  } catch (e) {
    yield put(actions.transformAttemptFailure({ errors: { message: e } }));
  }
}

function* submitAnswer(action: {
  payload: {
    attemptId: string;
    questionId: string;
    response: TApplyResponse | TApplyResponse[];
  };
}) {
  const questionResponseTransformer = new QuestionResponseTransformer();
  try {
    const { payload } = action;
    const { questionId, response } = payload;

    const transformedResponse = questionResponseTransformer.stringify({
      questionId,
      response: response as ILooseObject,
    });

    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(payload.attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });
    yield put(actions.submitAnswerSuccess({ data: {}, questionId: payload.questionId }));
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: {} }));
  }
}

function* upload(action: { payload: { questionId: string; file: File; attemptId: string } }) {
  try {
    const { file, questionId, attemptId } = action.payload;
    const uuid = uuidv1();
    const { data }: ILooseObject = yield call(axiosRequest, {
      url: endpoints.upload,
      requestData: { body: { title: uuid } },
      method: ERequestMethods.POST,
    });

    const { url = '', fields = {} } = data || {};
    const formData = new FormData();
    Object.entries(fields).forEach(([field, value]) => formData.append(field, `${value}`));
    formData.append('Content-Type', file?.type);
    formData.append('file', file);

    const { data: uploadResult } = yield call(axiosRequest, {
      url,
      isJson: false,
      requestData: { body: formData },
      method: ERequestMethods.POST,
      useAuthorization: false,
    });

    const answerPayload = {
      uuid: fields.key,
      name: file.name,
    };

    yield put(
      actions.submitAnswer({
        attemptId,
        questionId,
        response: answerPayload,
      }),
    );

    yield put(
      actions.uploadSuccess({
        questionId,
        ...answerPayload,
      }),
    );
  } catch (error) {
    yield put(actions.uploadFailure());
  }
}

function* watchTransformAttempt() {
  yield takeLatest(actions.transformAttemptRequest, transformAttempt);
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}
function* watchFetchApplyAttempts() {
  yield takeLatest(actions.fetchApplyAttempts, fetchApplyAttempts);
}

function* watchUploadRequest() {
  yield takeLatest(actions.uploadRequest, upload);
}

const sagas = [watchTransformAttempt, watchAttemptQuiz, watchSubmitAnswer, watchFetchApplyAttempts, watchUploadRequest];

export default sagas;
