import { MenuItem, TextField, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardProps } from '../../utils';
import { IStyledTextFieldProps } from '../Form/Fields/types';

const CountryMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DropDownTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1rem;
    text-align: left;
  }
`;

const Title = styled('h1')`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  * {
    font-size: 1.5rem;
    font-weight: 700;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: left;
  }
`;

const MobileAutoComplete = styled(TextField, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isFocused', 'isEmpty', 'disabled']),
})<IStyledTextFieldProps>(
  ({ isFocused, isEmpty, fullWidth, position, disabled }) => `


    .MuiAutocomplete-endAdornment{
     display:flex;
     justify-content:flex-end;
    }
    ${fullWidth ? 'width: 100%;' : ''}
    ${position ? `position: ${position};` : ''}
    ${disabled ? ' > * {pointer-events:none;} cursor:not-allowed;' : ''}
    ${
      isEmpty &&
      !isFocused &&
      `
    :not(:has(input:autofill)){
      label {
        transform: translate(14px, 11.5px) scale(1);
      }
    }
    /* firefox */
    @supports (not (selector(:has(*)))){
      label {
        transform: translate(14px, 11.5px) scale(1);
      }
    }
    `
    }
    `,
);
export { CountryMenuItem, DropDownTitleContainer, Title, MobileAutoComplete };
