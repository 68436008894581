import { IPlainText } from '../../../types';
import { TextContainer } from '../styled';
import parse from 'html-react-parser';

interface IProps {
  content: IPlainText;
}

//const removedHTMLText = ['<div><b>Explanation: </b></div>'];

const HtmlTemplate = ({ content }: IProps) => {
  const { text } = content;
  if (!text) return null;

  // const sanitize = () =>
  //   removedHTMLText.reduce((acc: string, textToBeRemoved: string) => acc.replace(textToBeRemoved, ''), text);

  return (
    <>
      <TextContainer>{parse(text)}</TextContainer>
    </>
  );
};

export default HtmlTemplate;
