import React from 'react';
import { TopicBoxContainer, TopicText } from '../Components/styled';
import { Radio, Checkbox } from '@mui/material';

interface ITopicBox {
  isChecked: boolean;
  type: 'radio' | 'checkbox';
  id: string;
  onChangeCheck: (id: any, isChecked: boolean) => void;
  text: string;
  isLarge?: boolean;
}

const TopicBox = ({ isChecked, type, id, onChangeCheck, text, isLarge }: ITopicBox) => {
  const getControlComponent = () => {
    switch (type) {
      case 'radio':
        return Radio;
      default:
        return Checkbox;
    }
  };

  const ControlComponent = getControlComponent();

  return (
    <TopicBoxContainer isLarge={isLarge} onClick={() => onChangeCheck(id, !isChecked)} isSelected={isChecked}>
      <ControlComponent checked={isChecked} />
      <TopicText>{text}</TopicText>
    </TopicBoxContainer>
  );
};

export default TopicBox;
