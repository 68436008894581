import { IStepComponent } from '../types';
import { ChoicesContainer } from './styled';
import useStep from './useStep';

const ShiftWork = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices, onChangeAnswer } = useStep({ question });
  return (
    <>
      {title}
      <ChoicesContainer>{renderMultipleChoices('checkbox')}</ChoicesContainer>
    </>
  );
};

export default ShiftWork;
