import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
const InfoIconWrapper = styled('div')`
  svg {
    color: ${colors.purple600};
    margin-left: 5px;
  }
  display: flex;
  align-items: center;
`;

export { InfoIconWrapper };
