// eslint-disable-next-line
import { AsTyped } from 'as-typed';
import { ICognitoUser } from './auth';
interface ILooseObject {
  [key: string]: any;
}

interface IAppContext {
  isLoggedIn: boolean;
  isOnboarded: boolean;
  user?: ICognitoUser;
  isSubscribed: boolean;
}

const OrbitsConfigSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  required: [
    'awsCognito',
    'endpointV2',
    'enableNotifications',
    'notificationDetails',
    'smartnEndpoint',
    'quizzingEndpoint',
    'stripePlans',
    'billingEndpoint',
    'stripePublishableKey',
  ],
  properties: {
    awsCognito: {
      type: 'object',
      required: ['aws_project_region', 'aws_user_pools_id', 'aws_user_pools_web_client_id', 'authenticationFlowType'],
      properties: {
        aws_project_region: {
          type: 'string',
          minLength: 1,
        },
        aws_user_pools_id: {
          type: 'string',
          minLength: 1,
        },
        aws_user_pools_web_client_id: {
          type: 'string',
          minLength: 1,
        },
        authenticationFlowType: {
          type: 'string',
          minLength: 1,
        },
      },
    },
    endpointV2: {
      type: 'string',
      minLength: 1,
    },
    nurseGPTEndpoint: {
      type: 'string',
      minLength: 1,
    },
    nurseGPTWebSocketEndpoint: {
      type: 'string',
      minLength: 1,
    },
    quizzingEndpoint: {
      type: 'string',
      minLength: 1,
    },
    billingEndpoint: {
      type: 'string',
      minLength: 1,
    },
    smartnEndpoint: {
      type: 'string',
      minLength: 1,
    },
    enableNotifications: {
      type: 'boolean',
    },
    allowedTrialQuizzes: {
      type: 'string',
      minLength: 1,
    },
    stripePlans: {
      type: 'object',
      required: [
        'essentials',
        'allAccess',
        'smartn30Days',
        'smartn90Days',
        'basicAccess',
        'smartCLEXAddon',
        'sponsoredBasicAccess',
      ],
      properties: {
        essentials: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },
        allAccess: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },
        smartn30Days: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },
        smartn90Days: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },
        basicAccess: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },

        smartCLEXAddon: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },

        sponsoredBasicAccess: {
          type: 'object',
          required: ['priceId', 'productId'],
          properties: {
            priceId: {
              type: 'string',
            },
            productId: {
              type: 'string',
            },
          },
        },
      },
    },
    stripePublishableKey: {
      type: 'string',
    },
    notificationDetails: {
      type: 'object',
      required: ['demoVideoId'],
      properties: {
        demoVideoId: {
          type: 'string',
        },
      },
    },
    control: {
      type: 'object',
      properties: {
        skipIntroductoryQuizzes: {
          type: 'string',
        },
        disbaleBugsnag: {
          type: 'string',
        },
      },
    },
  },
} as const;

type OrbitsConfig = AsTyped<typeof OrbitsConfigSchema>;

interface IRoute {
  path: string;
  exact?: boolean;
  component: React.ComponentType<any>;
  applyRedirect?: boolean;
}

export { OrbitsConfigSchema };
export type { ILooseObject, IAppContext, OrbitsConfig, IRoute };
