import { IStepComponent } from '../types';

import { InputContainer } from './styled';
import DropDown from '../../components/Form/Fields/DropDown';
import { TFieldValues } from '../../components/Form/Fields/types';
import useStep from './useStep';

const EducationLevel = ({ question }: IStepComponent) => {
  const { title, getDropdownOptions, onChangeAnswer, currentResponse } = useStep({ question });

  const onChange = (id: TFieldValues) => {
    onChangeAnswer(id);
  };

  return (
    <>
      {title}
      <InputContainer>
        <DropDown
          classesOverride={{ marginLeft: '10px' }}
          fieldKey="dropDown"
          onChange={onChange}
          value={currentResponse as string}
          options={getDropdownOptions()}
        />
      </InputContainer>
    </>
  );
};

export default EducationLevel;
