import DynamicField from '../../components/DynamicField';
import { IStepComponent } from '../types';
import { InputContainer } from './styled';
import { en } from '../../../i18n';
import useStep from './useStep';
import { ILooseObject } from '../../types';

const { apply } = en;

const Certifications = ({ question }: IStepComponent) => {
  const { title, onChangeAnswer, currentResponse } = useStep({ question });

  const onChangeDynamicField = (values: ILooseObject) => {
    const arr = Object.values(values).map(resp => resp.value);
    onChangeAnswer(arr);
  };

  const getInitialFieldState = (): ILooseObject | undefined => {
    if (!Array.isArray(currentResponse)) return undefined;
    const field = (currentResponse as string[]).reduce((accum: ILooseObject, value: string, index: number) => {
      accum[index] = { value };
      return accum;
    }, {} as ILooseObject);

    return field;
  };

  return (
    <>
      {title}
      <InputContainer>
        <DynamicField
          onChangeExternal={onChangeDynamicField}
          maxFields={5}
          placeHolder={apply.certificationPlaceHolder}
          initialFieldState={getInitialFieldState()}
        />
      </InputContainer>
    </>
  );
};

export default Certifications;
