import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useDispatch from './useDispatch';
import { quizSelectors, actions, SLICE_NAME } from '../StudySpace/redux/slice';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useMembershipProxy, useNavigations } from '.';

const useContinueQuiz = () => {
  const { attempts } = useSelector(quizSelectors.allState);
  const { dispatch } = useDispatch(SLICE_NAME);

  const { continueQuizButtons } = useMembershipProxy();
  const { navigateToDashboard } = useNavigations();
  const { buttonDisabled } = continueQuizButtons.run();

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const attemptId = query.get('attemptId');
  const isAttemptRedirected = useRef<boolean>(false);

  useEffect(() => {
    isAttemptRedirected.current = false;
  }, [attemptId]);

  useEffect(() => {
    if (isAttemptRedirected.current || !attemptId || !attempts) return;
    const attempt = attempts.find(attempt => attempt.attemptId === attemptId);
    if (!attempt) return;
    if (buttonDisabled) {
      navigateToDashboard();
      return;
    }
    dispatch(actions.setCurrentAttempt({ attempt }));
    dispatch(actions.setViewResults({ isViewingResults: false }));
    isAttemptRedirected.current = true;
  }, [attempts, attemptId]);

  return { isStarted: isAttemptRedirected.current };
};

export default useContinueQuiz;
