import { InfoIconWrapper } from '../InfoIconUpgrade/styled';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../Tooltip';
import parse from 'html-react-parser';
import { TextContainer } from './styled';

interface IInfoIconGeneral {
  text: string;
}

const InfoIconGeneral = ({ text }: IInfoIconGeneral) => {
  const title = <TextContainer>{parse(text)}</TextContainer>;
  return (
    <CustomTooltip fontSize="0.875rem" enterTouchDelay={0} centerText arrow={false} title={title}>
      <InfoIconWrapper>
        <InfoIcon />
      </InfoIconWrapper>
    </CustomTooltip>
  );
};

export default InfoIconGeneral;
