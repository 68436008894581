import Auth from '../Auth';
import Form from '../../components/Form';
import BackButton from '../../components/BackButton';
import { fieldConfigs } from '../constants';
import { SubTitleFormWrapper, AuthLinkText, BackButtonContainer } from '../styled';
import { en } from '../../../i18n';
import { useDispatch, useSeoTags } from '../../hooks';
import { ILooseObject } from '../../types';
import { useHistory } from 'react-router';
import { actions } from '../redux/slice';
import useAuthForm from '../useAuthForm';

const { email } = fieldConfigs;

const fields = { email };

const { forgotPassword } = en;

const { title, subTitle, submitText, back, footer, footerLink } = forgotPassword;

const ForgetPassword = () => {
  const { dispatch } = useDispatch();
  const { loading, errorFeedback } = useAuthForm({});
  const history = useHistory();
  const seo = useSeoTags({ pageName: 'forgetPassword' });

  const onSubmit = (formState: ILooseObject) => {
    const { email } = formState;
    //Dispatch here
    dispatch(actions.forgotPasswordRequest({ email, history }));
  };

  return (
    <Auth>
      {seo}
      <SubTitleFormWrapper>
        <BackButtonContainer>
          <BackButton text={back} />
        </BackButtonContainer>
        <Form
          loading={loading}
          onSubmit={onSubmit}
          formError={errorFeedback}
          fields={fields}
          subTitle={subTitle}
          title={title}
          submitText={submitText}
          useGlobalError={true}
        ></Form>
        <AuthLinkText>
          {footer} &nbsp;
          <a href="mailto:connect@smartn.ca">{footerLink}</a>
        </AuthLinkText>
      </SubTitleFormWrapper>
    </Auth>
  );
};

export default ForgetPassword;
