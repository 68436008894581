import { IStepComponent } from '../types';

import { Title, ChoiceContainer, ChoicesContainerFullWidth, TextNumberInput, ChoiceInputContiner } from './styled';

import { en } from '../../../i18n';
import { Checkbox } from '@mui/material';
import TemplateRenderer from '../../SmartQuiz/question/templates';
import React, { useState } from 'react';
import useStep from './useStep';
import { ILooseObject } from '../../types';

const { apply } = en;

const Notifications = ({ question }: IStepComponent) => {
  const { onChangeAnswer, currentResponse } = useStep({ question });

  const onCheck = (choiceId: string) => (event: React.SyntheticEvent, isChecked: boolean) => {
    const nextResponse = currentResponse ? { ...(currentResponse as ILooseObject) } : {};
    nextResponse[choiceId] = { checked: isChecked };
    onChangeAnswer(nextResponse);
  };

  const onChangeNumber = (choiceId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const nextResponse = currentResponse ? { ...(currentResponse as ILooseObject) } : {};
    nextResponse[choiceId] = { ...nextResponse[choiceId], number: value };
    onChangeAnswer(nextResponse);
  };

  const renderChoices = () => {
    if (!question.content.choices) return null;
    return question.content.choices.map(choice => {
      const checked = (currentResponse as ILooseObject)?.[choice.id]?.checked || false;
      if (choice.content.choice)
        return (
          <>
            <ChoiceContainer>
              <Checkbox onChange={onCheck(choice.id)} checked={checked} />
              <ChoiceInputContiner>
                <TemplateRenderer content={choice.content.choice} />
                {choice.id === 'text' && (
                  <TextNumberInput
                    onChange={onChangeNumber(choice.id)}
                    isVisible={checked}
                    placeholder={apply.notifications.textNumber}
                    size="small"
                    value={(currentResponse as ILooseObject)?.[choice.id]?.number}
                  />
                )}
              </ChoiceInputContiner>
            </ChoiceContainer>
          </>
        );
      return null;
    });
  };

  return (
    <>
      <Title centerMobile>{apply.notifications.title}</Title>
      <Title centerMobile>
        <TemplateRenderer content={question.content.question} />
      </Title>

      <ChoicesContainerFullWidth>{renderChoices()}</ChoicesContainerFullWidth>
    </>
  );
};

export default Notifications;
