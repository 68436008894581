import useWebSocket from 'react-use-websocket';
import { CurrentProfile } from '../classes';

interface IUseWebSocket {
  url: string;
  onOpen?: () => void;
  onClose?: () => void;
  shouldReconnect?: () => boolean;
  retryOnError?: boolean;
  share?: boolean;
  useAuthorization?: boolean;
}

const useSocket = ({
  url,
  onOpen = () => console.log('websocket connnection opened: ', url),
  onClose = () => console.log('websocket connnection closed: ', url),
  shouldReconnect = () => true,
  retryOnError = true,
  share = true,
  useAuthorization = true,
}: IUseWebSocket) => {
  const token = CurrentProfile.getToken();
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen,
    onClose,
    shouldReconnect,
    retryOnError,
    share,
    ...(useAuthorization && { queryParams: { token } }),
  });

  return { sendJsonMessage, lastMessage, readyState };
};

export default useSocket;
