import { AnalysisBodyTagText, AnalysisTagContainer, AnaylsisTitleTagText } from '../styled';

interface IAnalysisTag {
  textColor: string;
  title: string | React.ReactNode;
  bodyText?: string;
  backgroundColor: string;
  component?: React.ReactNode;
}

const AnalysisTag = ({ textColor, title, bodyText, backgroundColor, component }: IAnalysisTag) => {
  return (
    <AnalysisTagContainer textColor={textColor} backgroundColor={backgroundColor}>
      <AnaylsisTitleTagText>{title}</AnaylsisTitleTagText>
      {component || <AnalysisBodyTagText>{bodyText}</AnalysisBodyTagText>}
    </AnalysisTagContainer>
  );
};

export default AnalysisTag;
