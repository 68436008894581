import { useState } from 'react';

import {
  PromotionCodeButton,
  PromotionCodeWrapper,
  PromotionCodeInput,
  ApplyButton,
  PriceField,
  TaxPrice,
  PricingFieldLabel,
  FieldErrorMessage,
} from './styled';
import { en } from '../../../i18n';
import OutsideClickHandler from 'react-outside-click-handler';
import { IPaymentFormState } from '../../types';
import { useDispatch } from '../../hooks';
import { actions } from '../redux/slice';

const { promoCode, apply, promoCodeLabel, changePromoCode } = en.billing.paymentForm;

interface IPromotionCode {
  formState: IPaymentFormState;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string;
  onOpenPromoCode: () => void;
  onApplyPromoCode: () => void;
  resetPromoCode: () => void;
}
const PromotionCode = ({
  formState,
  handleChange,
  errors,
  onOpenPromoCode,
  onApplyPromoCode,
  resetPromoCode,
}: IPromotionCode) => {
  const [isClicked, setIsClicked] = useState(false);
  const { dispatch } = useDispatch();

  const open = (e: any) => {
    e.preventDefault();
    onOpenPromoCode();
    setIsClicked(true);
  };

  const close = (e: any) => {
    if (!isClicked) return;
    e.preventDefault();
    if (formState.promoCode) return;
    resetPromoCode();
    setIsClicked(false);
  };

  const applyPromoCode = (e: any) => {
    e.preventDefault();
    onApplyPromoCode();
    dispatch(actions.resetPromoCodeError());
    setIsClicked(false);
  };

  const renderContent = () => {
    if (!isClicked)
      return (
        <PromotionCodeButton onClick={open}>{formState.promoCode ? changePromoCode : promoCode}</PromotionCodeButton>
      );
    return (
      <PromotionCodeInput
        name="promoCode"
        onChange={handleChange}
        autoFocus
        placeholder={promoCode}
        value={formState.promoCode}
      ></PromotionCodeInput>
    );
  };

  const renderPromoCodeField = () => {
    if (!formState.promoCode || isClicked || !formState.isPromoCodeApplied) return null;
    return (
      <PriceField>
        <PricingFieldLabel>{promoCodeLabel}</PricingFieldLabel>
        <TaxPrice>{formState.promoCode}</TaxPrice>
      </PriceField>
    );
  };

  const renderErrors = () => {
    if (!errors) return null;
    return <FieldErrorMessage isVisible>{errors}</FieldErrorMessage>;
  };

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <PromotionCodeWrapper>
        {renderContent()}
        {isClicked && <ApplyButton onClick={applyPromoCode}>{apply}</ApplyButton>}
        {renderPromoCodeField()}
        {renderErrors()}
      </PromotionCodeWrapper>
    </OutsideClickHandler>
  );
};

export default PromotionCode;
