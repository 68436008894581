import { OAuthContainer, GoogleButton } from './styled';
import { en } from '../../../i18n';
import { ReactComponent as GoogleIcon } from '../../../images/google.svg';
import { useDispatch } from '../../hooks';
import { actions } from '../../Auth/redux/slice';
import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';
import { IS_SIGN_UP_LOCAL_STORAGE_ID } from '../../constants';

const { signInWithGoogle, signUpWithGoogle } = en.oAuth;

const OAuthButtons = ({ isSignIn }: { isSignIn?: boolean }) => {
  const { dispatch } = useDispatch();

  const onGoogleSignIn = () => {
    if (!isSignIn) {
      const localstorage = new LocalStorageWithTTL(IS_SIGN_UP_LOCAL_STORAGE_ID, 1.08e7);
      localstorage.set(true);
    }
    dispatch(actions.federatedLoginRequest());
  };

  return (
    <OAuthContainer isSignIn={isSignIn}>
      <GoogleButton type="button" onClick={onGoogleSignIn}>
        <GoogleIcon />
        {isSignIn ? signInWithGoogle : signUpWithGoogle}
      </GoogleButton>
      <div>or</div>
    </OAuthContainer>
  );
};

export default OAuthButtons;
