import { ModalOverlay } from '../styled';
import { PaymentFormContainer, FormContent, Footer, PayButton, FooterText } from './styled';
import Form from './Form';
import useMembershipPlan from '../hooks/useMembershipPlan';
import usePaymentForm from '../hooks/usePaymentForm';
import { useKeyDownPress } from '../../hooks';
import Button from '../../components/Button';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { en } from '../../../i18n';
import UserAgreements from '../../components/UserAgreements';
import { ReactComponent as StripeIcon } from '../../../images/stripe.svg';
import { ReactComponent as Seperator } from '../../../images/icons/membership/seperator.svg';
import MembershipForm from './MembershipForm';
import { EPaymentSteps } from '../../types';
import { useEffect } from 'react';
import ContentManager from './classes/ContentManager';

const {
  paymentForm: { requiredFields, poweredBy, requiredFieldsPaymentMethod },
} = en.billing;

const PaymentForm = () => {
  const { state } = useMembershipPlan();
  const { control, state: paymentFormState } = usePaymentForm();
  const { closeModal, getCurrentState, openCancelModal, purchaseAddon, purchaseAddonNoCard } = control;
  const {
    showPaymentForm,
    loading,
    isActive,
    loadingChangePaymentMethod,
    stripePlan,
    isPaymentIssue,
    isSponsored,
    currentSubscription,
  } = state;

  const { buttonDisabled, errors, paymentStep, loadingPurchaseAddon } = paymentFormState;

  useKeyDownPress('Escape', closeModal);
  const currentState = getCurrentState();

  const contentManager = new ContentManager(stripePlan, currentState, {
    loadingChangePaymentMethod,
    loading,
    loadingPurchaseAddon,
    isSponsored,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentState]);

  const renderCancelButton = () => {
    const buttonData = contentManager.content.cancelButton();
    return (
      <Button variant={buttonData.variant} onClick={closeModal}>
        {buttonData.text}
      </Button>
    );
  };

  const renderButton = () => {
    const { loading: loadingButton, form, text, variant, onClick } = contentManager.content.confirmButton();

    if (onClick === 'openCancelModal' && isSponsored) return null;
    const getFunction = () => {
      if (!onClick) return () => {};

      if (onClick === 'purchaseAddon' && currentState === EPaymentSteps.BUY_ADDON_NO_CARD) return purchaseAddonNoCard;

      switch (onClick) {
        case 'openCancelModal':
          return openCancelModal;
        case 'purchaseAddon':
          return purchaseAddon;
      }
    };
    const currentState = getCurrentState();

    const button = (
      <PayButton
        disabled={buttonDisabled || loadingButton}
        error={errors.global}
        variant={variant}
        onClick={getFunction()}
        form={form || undefined}
        loading={loadingButton}
      >
        {text}
      </PayButton>
    );

    if (buttonDisabled) {
      const title = currentState === EPaymentSteps.EDIT_CARD ? requiredFieldsPaymentMethod : requiredFields;
      return (
        <Tooltip placement="top" enterTouchDelay={0} disableFocusListener title={title}>
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  const renderForm = () => {
    if (currentState === EPaymentSteps.BUY_ADDON_NO_CARD) return <Form control={control} state={paymentFormState} />;
    if (isActive || isPaymentIssue) return <MembershipForm control={control} state={paymentFormState} />;
    return <Form control={control} state={paymentFormState} />;
  };

  if (!showPaymentForm) return null;

  return (
    <ModalOverlay>
      <ClickAwayListener onClickAway={closeModal}>
        <PaymentFormContainer>
          <FormContent>
            {/* <Pricing /> */}
            {renderForm()}
            <Footer>
              {renderCancelButton()}
              {renderButton()}
            </Footer>
          </FormContent>

          {((!isActive && !isPaymentIssue) ||
            [EPaymentSteps.EDIT_CARD, EPaymentSteps.BUY_ADDON_NO_CARD].includes(currentState)) && (
            <FooterText>
              <span>
                {poweredBy} &nbsp; <StripeIcon />
              </span>{' '}
              <Seperator className="seperator" /> <UserAgreements variant="minimal" />
            </FooterText>
          )}
        </PaymentFormContainer>
      </ClickAwayListener>
    </ModalOverlay>
  );
};

export default PaymentForm;
