import { ChangeEvent } from 'react';
import { StyledTextField, FieldHelperText } from './styled';
import { ITextField } from './types';

const TextField = ({
  label,
  required,
  helperText,
  onFocus,
  onBlur,
  isFocused,
  onChange,
  fieldKey,
  isEmpty,
  value,
  error,
  renderErrors,
  onTouchStart,
  inputType,
  fullWidth,
}: ITextField) => {
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const fieldHelperText = <FieldHelperText>{helperText}</FieldHelperText>;

  return (
    <StyledTextField
      className={`form-field form-field-${fieldKey}`}
      label={label}
      InputLabelProps={{ className: 'form-field-label' }}
      required={required}
      helperText={renderErrors?.() || fieldHelperText}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocused={isFocused}
      inputProps={{ autoComplete: 'off' }}
      autoComplete="off"
      isEmpty={isEmpty}
      onChange={onChangeField}
      value={value}
      error={error}
      type={inputType}
      fullWidth={fullWidth}
      {...(onTouchStart && { onTouchStart })}
    />
  );
};

export default TextField;
