import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import IconButton from '@mui/material/IconButton';
import { Menu as DashboardMenu, MenuItem as DashboardMenuItem } from '../Home/styled';
import { shouldForwardProps } from '../utils';
import { Toolbar } from '@mui/material';
import { Info } from '../Home/SideBar/styled';

interface IStyledIconButton {
  allowHover: boolean;
}

const HeaderFreeBannerContainer = styled('div')`
  border-radius: 4px;
  color: ${colors.black};
  background-color: ${colors.purple5};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  width: 100vw;
  text-align: center;
  margin: auto;
  box-sizing: border-box;
  position: sticky;
  z-index: 300;

  // ${props => props.theme.breakpoints.down('lg')} {
  //   position: absolute;
  //   top: 64px;
  //   min-height: 26px;
  //   left: 0;
  //   text-align: center;
  //   overflow: hidden;
  //   padding: 10px 0px;
  //   width: 100%;
  // }
`;

const BannerWrapper = styled('div')`
  width: 100%;
  ${props => props.theme.breakpoints.down('lg')} {
    width: auto;
  }
`;

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open', 'allowHover']),
})<IStyledIconButton>(
  ({ allowHover }) => `
  border: 0.3px solid ${colors.white};
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  width: 32px;
  height: 32px;
  background-color: ${colors.purple500};
  transition opacity,background-color 0.3s linear;
  &:hover {
    background-color: ${colors.purple500};
    opacity: ${allowHover ? 0.7 : 1};
  }
  color:${colors.white};
  font-size:14px;
  margin-left: 32px;
`,
);

const Menu = styled(DashboardMenu)`
  padding: 6px 12px;
  margin-top: 7px;
  min-width: 330px;
  a {
    all: unset !important;
  }
  z-index: 15;
  .info-container-editRole {
    .MuiCircularProgress-root {
      svg {
        all: unset !important;
      }
    }
  }
`;

const MenuItem = styled(DashboardMenuItem)`
  display: flex;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  svg {
    all: unset !important;
  }
  transition: background-color 0.3s linear;
  border-radius: 5px;
  padding-right: 30px;
  font-size: 1rem;
  :hover {
    background-color: ${colors.purple5};
  }
  align-items: center;
  ${props => props.theme.breakpoints.down('$sm')} {
    svg {
      width: 12px;
      height: 10px;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0px;
`;

const LogoHamburgerIconContainer = styled('div')`
  flex-grow: 1;
  display: flex;
`;

const HeaderTrial = styled('span')`
  .bold {
    font-weight: bold;
  }
  // ${props => props.theme.breakpoints.down('lg')} {
  //   display: block;
  //   width: calc(100% - 12px);
  // }
`;

const HeaderBannerContainer = styled('span')`
  svg {
    position: absolute;
    left: 0;
    bottom: -2px;
  }
`;

const SvgContainer = styled('span')`
  height: 100%;
  margin-right: ${props => props.theme.spacing(0.5)};
  width: 18px;
  display: inline-block;
  position: relative;

  ${props => props.theme.breakpoints.down('$sm')} {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const HeaderBannerText = styled('span')`
  // ${props => props.theme.breakpoints.down('lg')} {
  //   width: calc(100% - 12px);
  //   display: block;
  // }
`;

const MenuContainer = styled('div')`
  display: flex;
  gap: 12px;
  flex-direction: column;
  border-bottom: 1px solid ${colors.grey600};
  padding-top: 8px;
  div {
    text-align: left;
    margin-bottom: 0;
  }

  padding-bottom: 12px;
`;

const UserName = styled('div')`
  color: ${colors.black};
  font-size: 1.125rem;
  font-weight: 700;
  width: fit-content;
`;

const InfoRow = styled(Info)`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  button {
    width: 80px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const BottomMenuContainer = styled('div')`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 16px;
`;

export {
  HeaderFreeBannerContainer,
  StyledToolbar,
  LogoHamburgerIconContainer,
  ItemContainer,
  BannerWrapper,
  StyledIconButton,
  Menu,
  MenuItem,
  HeaderTrial,
  HeaderBannerContainer,
  SvgContainer,
  HeaderBannerText,
  MenuContainer,
  UserName,
  InfoRow,
  BottomMenuContainer,
};
