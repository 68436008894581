import { useContext } from 'react';
import { IRoute } from '../types';
import { AppContext } from '../../AppContext';
import { Redirect, Route } from 'react-router';
import { defaultPaths } from './paths';

const PublicRoute = ({ path, component, exact, applyRedirect = true }: IRoute) => {
  const { isLoggedIn } = useContext(AppContext);

  if (isLoggedIn && applyRedirect)
    return <Redirect from={path} exact={exact} path={path} to={defaultPaths.private}></Redirect>;

  return <Route path={path} component={component} exact={exact} />;
};

export default PublicRoute;
