import { useState } from 'react';
import { ESubscriptionsStatus } from '../../types/enums';
import { useSubscriptions } from '../../hooks';
import { en } from '../../../i18n';
import { useTimer } from 'react-timer-hook';

const GRACE_PERIOD = 10 * 60000;
// const oldDate = new Date();
// const GRACE_PERIOD = 100 * 60;
const { Now, Hours, Minutes, Days, Seconds } = en.header;

interface IUseTrialTimer {
  useRefreshSubscriptions?: boolean;
}

const useTrialTimer = ({ useRefreshSubscriptions = true }: IUseTrialTimer) => {
  const {
    currentSubscription: reduxCurrent,
    triallingSubscription,
    refreshSubscriptions,
    setTrialEnded,
  } = useSubscriptions({
    useFetchSubscriptions: false,
  });
  const [isTrialEnded, setIsTrialEnded] = useState(false);
  const currentSubscription =
    reduxCurrent?.status === ESubscriptionsStatus.TRIALING ? reduxCurrent : triallingSubscription;
  const oldDate = currentSubscription?.currentPeriodEnd ? new Date(currentSubscription.currentPeriodEnd) : new Date();
  const finalDate = new Date(oldDate.getTime() + GRACE_PERIOD);
  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: finalDate,
    onExpire: () => onTrialEnd(),
  });
  if (!currentSubscription || !currentSubscription.currentPeriodEnd)
    return { isTrialEnded, hasPeriodEnd: !!currentSubscription?.currentPeriodEnd, isVisible: false };

  const onTrialEnd = () => {
    if (!useRefreshSubscriptions) return;
    setIsTrialEnded(true);
    refreshSubscriptions();
    setTrialEnded();
  };

  const renderer = () => {
    //const { hours, minutes, seconds, days, completed } = props;

    if (!isRunning) {
      return <span>{Now}</span>;
    }

    const renderText = (quantity: number, text: string, useZeroPadding?: boolean) => {
      const displayedQuantity = useZeroPadding && quantity < 10 ? `0${quantity}` : quantity;
      if (quantity === 1) {
        if (useZeroPadding)
          return (
            <>
              {displayedQuantity} {text.slice(0, -1)}&nbsp;&nbsp;
            </>
          );
        return `${displayedQuantity} ${text.slice(0, -1)}`;
      }

      return `${displayedQuantity} ${text}`;
    };
    const daysText = renderText(days, Days);
    const hoursText = renderText(hours, Hours, true);
    const minutesText = renderText(minutes, Minutes, true);
    const secondsText = renderText(seconds, Seconds, true);
    if (days) {
      if (hours) {
        return (
          <span>
            {daysText} : {hoursText}
          </span>
        );
      }
      return <span>{daysText}</span>;
    }
    if (hours)
      return (
        <span>
          {hoursText} : {minutesText}
        </span>
      );
    if (minutes)
      return (
        <span>
          {minutesText} : {secondsText}
        </span>
      );
    return <span>{secondsText}</span>;
  };

  return { finalDate, isTrialEnded, hasPeriodEnd: true, renderer, isVisible: true };
};

export default useTrialTimer;
