import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  PageContainer,
  SubjectTitleContainer,
  ProficiencyTag,
  PageWrapper,
  ProgressBarsContainer,
  TopTitle,
} from './styled';
import ProgressBar from '../components/ProgressBar';
import { SLICE_NAME as topicsSlice } from './redux/topics/slice';
import { SLICE_NAME as categoriesSlice, actions as CategoriesActions } from './redux/categories/slice';
import { SLICE_NAME as studySpaceSlice, actions, quizSelectors } from './redux/slice';
import { en } from '../../i18n';
import { categories } from './staticConstants';
import SmartQuiz from '../SmartQuiz';
import useDidMount from '../hooks/useDidMount';
import { actions as globalActions } from '../redux/globalSlice';
import { useDispatch, useMembershipProxy, useSeoTags, useURLParam } from '../hooks';
import { useSelector } from 'react-redux';
import { getCurrentProgress } from '../utils';
import { ILooseObject } from '../types';

const { smartQuiz, subtitle, topTitle } = en.studySpace;

const Subject = () => {
  const didMount = useDidMount();
  const { subjectID } = useParams<{ subjectID: string }>();
  const history = useHistory();
  const {
    location: { state: locationState },
  } = history;
  const attemptIdQuery = useURLParam('attemptId');
  const { isViewingPastQuizzes } = (locationState as ILooseObject) || {};
  const { dispatch } = useDispatch(topicsSlice);
  const { dispatch: categoriesDispatch } = useDispatch(categoriesSlice);
  const { dispatch: studySpaceDispatch } = useDispatch(studySpaceSlice);
  const { currentAttempt, questionAnswers, isStarted, questions, proficiency } = useSelector(quizSelectors.allState);
  const { quizYourself, studySpaceTitle } = useMembershipProxy({ useStrict: true });
  const seo = useSeoTags({ pageName: 'studySpace' });

  const buttonStatus = quizYourself.run();
  const { Container = SubjectTitleContainer } = studySpaceTitle.run();

  const { percentage: progressPercentage, upperBound, lowerBound } = getCurrentProgress(
    questions,
    questionAnswers,
    currentAttempt,
  );

  useEffect(() => {
    categoriesDispatch(CategoriesActions.indexSuccess({ entities: categories }));
    // Dispatch an action to fetch the subject here
    dispatch(globalActions.topicsIndexRequest({ subjectID }));
    if (attemptIdQuery && (!currentAttempt || currentAttempt.attemptId !== attemptIdQuery))
      studySpaceDispatch(actions.fetchAttempt({ attemptId: attemptIdQuery }));
    if (!proficiency) studySpaceDispatch(actions.fetchProficiency());
  }, [subjectID]);

  return (
    <PageWrapper>
      {seo}
      <PageContainer>
        <Container>
          <div>
            <div>
              <TopTitle>{topTitle}</TopTitle>
              <ProficiencyTag textColor={proficiency?.textColor} backgroundColor={proficiency?.backgroundColor}>
                {proficiency?.text}
              </ProficiencyTag>
            </div>
            <h3>{subtitle}</h3>
          </div>
          {!isViewingPastQuizzes && isStarted && (
            <ProgressBarsContainer key="progress-bar">
              <ProgressBar
                upperBound={upperBound}
                lowerBound={lowerBound}
                useMountAnimation={!didMount}
                title={smartQuiz}
                percentage={progressPercentage}
                color="#F89C68"
              ></ProgressBar>
            </ProgressBarsContainer>
          )}
        </Container>
        <SmartQuiz unstartedQuizOverrides={buttonStatus} />
      </PageContainer>
    </PageWrapper>
  );
};

export default Subject;
