import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import { TabContent } from '../Components/styled';
import { opacityInAnimation } from '../../sharedStyled';
import Button from '../../components/Button';

interface ITopicsFilter {
  isSelected: boolean;
}

interface IMenuProps {
  open?: boolean;
}

interface IMenuItemProps {
  disabled?: boolean;
}

const MenuButton = styled(Button)`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  :hover {
    background-color: ${colors.purple5};
  }
  :focus {
    background-color: ${colors.purple5};
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Menu = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<IMenuProps>(
  ({ open }) => `
  padding: 8px 0px;
  border-radius: 4px;
  position: absolute;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  background-color: ${colors.white};
  display: ${open ? 'block' : 'none'};
  right: -8px;
  top: 50px;
  z-index: 1;
  svg {
    right: 12px;
    position: absolute;
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 20%));
    transform: translateY(-100%);
    top: 0;
    filter: drop-shadow(0px -1px 1px rgb(0 0 0 / 7%));
  }
`,
);

const MenuItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<IMenuItemProps>(
  ({ disabled }) => `
  font-size: 0.875rem;
  color: ${disabled ? colors.grey500 : colors.black};
  font-weight: 600;
  white-space: nowrap;
  :hover {
    background-color: ${disabled ? 'none' : colors.lightestGrey};
  }
  padding: 12px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  text-align: left;
`,
);

const TabContentAnalyze = styled(TabContent)``;

const TopicsFilter = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSelected']),
})<ITopicsFilter>(({ isSelected, theme }) => {
  return `
  padding: 8px 24px 8px 24px;
  border-radius: 200px;
  display:flex;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  font-weight: 700;
  cursor: pointer;
  font-size: 0.875rem;
  border:1px solid ${colors.primary};
  ${theme.breakpoints.down('sm')} {
   justify-content:center;
  }
  ${isSelected ? `background-color:${colors.primary};` : ''}
  color:${isSelected ? `${colors.white}` : `${colors.primary}`};
  `;
});

const TopicsFilterContainer = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const TopicsTitle = styled('h1')`
  margin: 0;
  font-weight: 700;
  color: ${colors.grey400};
  text-transform: uppercase;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 24px;
  font-size: 0.875rem;
  cursor: pointer;
`;

const TopicBox = styled('div')`
  background-color: ${colors.lightestGrey};
  border-radius: 8px;
  padding: 24px 24px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${opacityInAnimation()};
  div {
    &::first-letter {
      text-transform: capitalize;
    }
    font-weight: 600;
  }
`;

const TopicBoxesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export {
  TabContentAnalyze,
  TopicsFilter,
  TopicsFilterContainer,
  TopicsTitle,
  TopicBox,
  TopicBoxesContainer,
  Menu,
  MenuButton,
  MenuItem,
};
