import { TableContainer } from '@mui/material';
import { Table } from './styled';
import ContentRenderer from '../ContentRenderer';
import { IMatrixComponent } from './types';

const Matrix = ({ header, rows, renderRowAnswer }: IMatrixComponent) => {
  return (
    <TableContainer>
      <Table>
        <tr>
          <th>{header && <ContentRenderer content={header} />}</th>
          {rows[0].detail.options?.map(option => (
            <th>
              <ContentRenderer content={option.detail.content} />
            </th>
          ))}
        </tr>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>
              <ContentRenderer content={row.detail.question} />
            </td>
            {row.detail.options?.map((option, indexOption) => (
              <td key={`${indexOption}-option`}>{renderRowAnswer(row, option, index)}</td>
            ))}
          </tr>
        ))}
      </Table>
    </TableContainer>
  );
};

export default Matrix;
