import { useState } from 'react';
import { ILooseObject } from '../../../types';

const useDragAndDrop = (initialState: ILooseObject, dragCallback: (choices: ILooseObject) => void) => {
  const [choices, setChoices] = useState<ILooseObject>(initialState);
  const reorder = (list: ILooseObject[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ source, destination }: ILooseObject) => {
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const newChoices = reorder(choices[source.droppableId], source.index, destination.index);
      setChoices({ ...choices, [source.droppableId]: newChoices });
      return;
    }
    const sourceClone = Array.from(choices[source.droppableId]);
    const destClone = Array.from(choices[destination.droppableId]);
    const [removed] = sourceClone.splice(source.index, 1);
    destClone.splice(destination.index, 0, removed);
    const newChoices = { ...choices, [source.droppableId]: sourceClone, [destination.droppableId]: destClone };
    setChoices(newChoices);
    dragCallback(newChoices);
  };

  return { onDragEnd, choices };
};

export default useDragAndDrop;
