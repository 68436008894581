import { useMemo } from 'react';
import { useSelector } from 'react-redux';
//import { quizSelectors } from '../../StudySpace/redux/slice';
import { ChartsContainer } from '../styled';
import Pie from '../../components/Charts/Pie';
import { en } from '../../../i18n';
const { topicAnalysis } = en.reviewResults;

const CategoryBreakDown = () => {
  return (
    <ChartsContainer>
      <Pie
        title={topicAnalysis.readinessPieChart.title}
        subTitle={topicAnalysis.readinessPieChart.subTitle}
        variant="readiness"
      />
    </ChartsContainer>
  );
};

export default CategoryBreakDown;
