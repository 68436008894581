interface IBaseTemplate {
  text?: string;
}

interface IPlainText extends IBaseTemplate {}

interface IImageText extends IBaseTemplate {
  image: string;
}

enum EContentTypes {
  PLAIN_TEXT = 'plainText',
  IMAGE_TEXT = 'imageText',
  HTML = 'html',
}

interface IContent {
  type: EContentTypes | '';
  content: IPlainText | IImageText;
}

interface IEnumAnswerContent {
  label: string;
  value: string;
}

interface IChoiceAnswer {
  type: 'enum' | 'date' | 'text';
  content: IEnumAnswerContent[] | string;
}

//interface IChoiceContent extends

export { EContentTypes };
export type { IContent, IPlainText, IImageText, IChoiceAnswer };
