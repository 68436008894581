import { Apostrophe, TextSpan } from './styled';

interface ISmartNText {
  fontSize?: string;
  children?: React.ReactNode;
}

const SmartNText = ({ fontSize, children }: ISmartNText) => {
  const text = (
    <>
      Smart<Apostrophe>’</Apostrophe>n
    </>
  );

  const textWithChildren = (
    <>
      Smart<Apostrophe>’</Apostrophe>n {children}
    </>
  );

  return <TextSpan fontSize={fontSize}>{children ? textWithChildren : text}</TextSpan>;
};

export default SmartNText;
