import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { en } from '../../../i18n';
import { IAddress } from '../../types';

const {
  paymentForm: { address, countries, zipCode, line1, city },
} = en.billing;
import {
  AddressFieldsContainer,
  FieldLabel,
  SelectField,
  CountriesMenu,
  CountryOption,
  FieldErrorMessage,
  AddressField,
} from './styled';

const Address = ({ formState, handleCountryChange, errors, handleAddressFieldChange }: IAddress) => {
  const [showCountriesMenu, setShowCountriesMenu] = useState(false);

  const toggleCountriesMenu = () => {
    setShowCountriesMenu(!showCountriesMenu);
  };

  const closeCountriesMenu = () => {
    setShowCountriesMenu(false);
  };

  const renderCountriesMenu = () => {
    if (!showCountriesMenu) return null;
    return (
      <CountriesMenu>
        {countries.map((country, index) => (
          <CountryOption key={index} onClick={() => handleCountryChange(country)}>
            {country.label}
          </CountryOption>
        ))}
      </CountriesMenu>
    );
  };

  return (
    <AddressFieldsContainer>
      <FieldLabel isError={!!errors.address}>{address}</FieldLabel>
      <OutsideClickHandler onOutsideClick={closeCountriesMenu}>
        <AddressField
          value={formState.address.line1}
          applyBorderRadius
          name="line1"
          required
          placeholder={`${line1} *`}
          onChange={handleAddressFieldChange}
          applyTopBorder
        />
        <AddressField
          value={formState.address.city}
          name="city"
          placeholder={`${city}`}
          onChange={handleAddressFieldChange}
        />
        <AddressField
          value={formState.address.postalCode}
          name="postalCode"
          required
          placeholder={`${zipCode} *`}
          onChange={handleAddressFieldChange}
        />
        <SelectField isMenuShown={showCountriesMenu} onClick={toggleCountriesMenu}>
          <span>{formState.address.country?.label || countries[1].label}</span>
          {renderCountriesMenu()}
        </SelectField>
      </OutsideClickHandler>

      {errors.address && (
        <FieldErrorMessage isVisible={!!errors.address}>{errors.address || en.somethingWentWrong}</FieldErrorMessage>
      )}
    </AddressFieldsContainer>
  );
};

export default Address;
