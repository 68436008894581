import { DragDropContext } from 'react-beautiful-dnd';
import { ILooseObject, ICaseStudySubquestion } from '../../../types';
import {
  BowTieClozeContainer,
  ActionCloze,
  ActionItem,
  ConditionCloze,
  ConditionItem,
  BowTieColumnClozeContainer,
  ParameterCloze,
  ParameterItem,
  BowTieListsContainer,
  BowTieListTitle,
  BowTieChoiceContainer,
  BowTieTitlesContainer,
  Line,
  BowTieInfo,
} from './styled';
import { en } from '../../../../i18n';
import DroppableArea from './DroppableArea';
import useDragAndDrop from './useDragAndDrop';
import ContentRenderer from './ContentRenderer';
const { caseStudy } = en;

const BowTie = ({
  questions,
  answerSubquestion,
  questionId,
  isDone,
  isReviewing,
  answer,
}: {
  questions: ICaseStudySubquestion[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  isDone?: boolean;
  isReviewing?: boolean;
  answer: ILooseObject;
}) => {
  let initialState;
  if (isReviewing) {
    initialState = {
      firstAction: [
        {
          id: questions[0].detail.answerIds?.[0],
          content: questions[0].detail.options?.find(option => option.id === questions[0].detail.answerIds?.[0])?.detail
            .content.detail.copy,
        },
      ],
      secondAction: [
        {
          id: questions[0].detail.answerIds?.[1],
          content: questions[0].detail.options?.find(option => option.id === questions[0].detail.answerIds?.[1])?.detail
            .content.detail.copy,
        },
      ],
      condition: [
        {
          id: questions[1].detail.answerIds?.[0],
          content: questions[1].detail.options?.find(option => option.id === questions[1].detail.answerIds?.[0])?.detail
            .content.detail.copy,
        },
      ],
      firstParameter: [
        {
          id: questions[2].detail.answerIds?.[0],
          content: questions[2].detail.options?.find(option => option.id === questions[2].detail.answerIds?.[0])?.detail
            .content.detail.copy,
        },
      ],
      secondParameter: [
        {
          id: questions[2].detail.answerIds?.[1],
          content: questions[2].detail.options?.find(option => option.id === questions[2].detail.answerIds?.[1])?.detail
            .content.detail.copy,
        },
      ],
      actions: [],
      conditions: [],
      parameters: [],
    };
  } else if (isDone) {
    initialState = {
      firstAction: [
        {
          id: answer.questions[0].answerIds[0],
          content: questions[0].detail.options?.find(option => option.id === answer.questions[0].answerIds[0])?.detail
            .content.detail.copy,
        },
      ],
      secondAction: [
        {
          id: answer.questions[0].answerIds[1],
          content: questions[0].detail.options?.find(option => option.id === answer.questions[0].answerIds[1])?.detail
            .content.detail.copy,
        },
      ],
      condition: [
        {
          id: answer.questions[1].answerIds[0],
          content: questions[1].detail.options?.find(option => option.id === answer.questions[1].answerIds[0])?.detail
            .content.detail.copy,
        },
      ],
      firstParameter: [
        {
          id: answer.questions[2].answerIds[0],
          content: questions[2].detail.options?.find(option => option.id === answer.questions[2].answerIds[0])?.detail
            .content.detail.copy,
        },
      ],
      secondParameter: [
        {
          id: answer.questions[2].answerIds[1],
          content: questions[2].detail.options?.find(option => option.id === answer.questions[2].answerIds[1])?.detail
            .content.detail.copy,
        },
      ],
      actions: questions[0].detail.options
        ?.filter(option => !answer.questions[0].answerIds.includes(option.id))
        .map(option => ({ id: option.id, content: option.detail.content.detail.copy })),
      conditions: questions[1].detail.options
        ?.filter(option => !answer.questions[1].answerIds.includes(option.id))
        .map(option => ({ id: option.id, content: option.detail.content.detail.copy })),
      parameters: questions[2].detail.options
        ?.filter(option => !answer.questions[2].answerIds.includes(option.id))
        .map(option => ({ id: option.id, content: option.detail.content.detail.copy })),
    };
  } else {
    initialState = {
      firstAction: [],
      secondAction: [],
      condition: [],
      firstParameter: [],
      secondParameter: [],
      actions: questions[0]?.detail?.options?.map(option => ({
        id: option.id,
        content: option.detail.content.detail.copy,
      })),
      conditions: questions[1]?.detail?.options?.map(option => ({
        id: option.id,
        content: option.detail.content.detail.copy,
      })),
      parameters: questions[2]?.detail?.options?.map(option => ({
        id: option.id,
        content: option.detail.content.detail.copy,
      })),
    };
  }

  const dragCallback = (choices: ILooseObject) => {
    answerSubquestion(
      {
        id: questionId,
        questions: [
          { id: questions[0].id, answerIds: [choices.firstAction[0]?.id || '', choices.secondAction[0]?.id || ''] },
          { id: questions[1].id, answerIds: [choices.condition[0]?.id || ''] },
          {
            id: questions[2].id,
            answerIds: [choices.firstParameter[0]?.id || '', choices.secondParameter[0]?.id || ''],
          },
        ],
      },
      {
        id: questionId,
        questions: [
          { id: questions[0].id, answerIds: questions[0].detail.answerIds },
          { id: questions[1].id, answerIds: questions[1].detail.answerIds },
          { id: questions[2].id, answerIds: questions[2].detail.answerIds },
        ],
      },
    );
  };

  const { onDragEnd, choices } = useDragAndDrop(initialState, dragCallback);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <BowTieInfo>{caseStudy.bowTieInfo}</BowTieInfo>
      <BowTieClozeContainer>
        <BowTieColumnClozeContainer>
          <DroppableArea
            className="A"
            droppableId="firstAction"
            isDropDisabled={!!choices.firstAction[0]}
            droppableComponent={ActionCloze}
            draggableComponent={ActionItem}
            draggableItems={choices.firstAction}
            isItemDragged={true}
            placeholder={questions[0].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          >
            <Line angle={65} top={43} right={-29} rightMobile="-17%" topMobile="90px" angleMobile={48} />
          </DroppableArea>
          <DroppableArea
            droppableId="secondAction"
            isDropDisabled={!!choices.secondAction[0]}
            droppableComponent={ActionCloze}
            draggableComponent={ActionItem}
            draggableItems={choices.secondAction}
            isItemDragged={true}
            placeholder={questions[0].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          >
            <Line angle={-65} top={3} right={-28} rightMobile="64%" topMobile="90px" angleMobile={-48} />
          </DroppableArea>
        </BowTieColumnClozeContainer>
        <DroppableArea
          droppableId="condition"
          isDropDisabled={!!choices.condition[0]}
          droppableComponent={ConditionCloze}
          draggableComponent={ConditionItem}
          draggableItems={choices.condition}
          isItemDragged={true}
          placeholder={questions[1].detail.label?.detail.copy}
          isDragDisabled={!!isDone}
        />
        <BowTieColumnClozeContainer>
          <DroppableArea
            droppableId="firstParameter"
            isDropDisabled={!!choices.firstParameter[0]}
            droppableComponent={ParameterCloze}
            draggableComponent={ParameterItem}
            draggableItems={choices.firstParameter}
            isItemDragged={true}
            placeholder={questions[2].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          >
            <Line angle={-65} top={43} left={-29} topMobile="-31px" leftMobile="60%" angleMobile={-48} />
          </DroppableArea>
          <DroppableArea
            droppableId="secondParameter"
            isDropDisabled={!!choices.secondParameter[0]}
            droppableComponent={ParameterCloze}
            draggableComponent={ParameterItem}
            draggableItems={choices.secondParameter}
            isItemDragged={true}
            placeholder={questions[2].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          >
            <Line angle={65} top={3} left={-29} topMobile="-31px" leftMobile="-20%" angleMobile={48} />
          </DroppableArea>
        </BowTieColumnClozeContainer>
      </BowTieClozeContainer>
      {!isReviewing && (
        <>
          <BowTieTitlesContainer>
            <BowTieListTitle>
              <ContentRenderer content={questions[0].detail.question} />
            </BowTieListTitle>
            <BowTieListTitle>
              <ContentRenderer content={questions[1].detail.question} />
            </BowTieListTitle>
            <BowTieListTitle>
              <ContentRenderer content={questions[2].detail.question} />
            </BowTieListTitle>
          </BowTieTitlesContainer>
          <BowTieListsContainer>
            <DroppableArea
              droppableId="actions"
              isDropDisabled={false}
              droppableComponent={BowTieChoiceContainer}
              draggableComponent={ActionItem}
              draggableItems={choices.actions}
              isItemDragged={false}
              droppableComponentProps={{ count: questions[0].detail.options?.length }}
              isDragDisabled={!!isDone}
            />
            <DroppableArea
              droppableId="conditions"
              isDropDisabled={false}
              droppableComponent={BowTieChoiceContainer}
              draggableComponent={ConditionItem}
              draggableItems={choices.conditions}
              isItemDragged={false}
              droppableComponentProps={{ count: questions[1].detail.options?.length }}
              isDragDisabled={!!isDone}
            />
            <DroppableArea
              droppableId="parameters"
              isDropDisabled={false}
              droppableComponent={BowTieChoiceContainer}
              draggableComponent={ParameterItem}
              draggableItems={choices.parameters}
              isItemDragged={false}
              droppableComponentProps={{ count: questions[2].detail.options?.length }}
              isDragDisabled={!!isDone}
            />
          </BowTieListsContainer>
        </>
      )}
    </DragDropContext>
  );
};

export default BowTie;
