import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Input } from '@mui/material';
import { en } from '../../../i18n';
import { useDispatch } from '../../hooks';
import {
  NurseGPTModalOverlay,
  NurseGPTModalContainer,
  NurseGPTModalTitle,
  NurseGPTModalSummary,
  SpinnerContainer,
  NurseGPTModalQuestion,
} from './styled';
import { actions as nurseGPTActions, SLICE_NAME as nurseGPTSlice, nurseGPTSelectors } from '../../NurseGPT/redux/slice';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';

interface INurseGPTInterface {
  isNurseGPTModalOpen: boolean;
  toggleNurseGPTModal: () => void;
}

const { waitlist, summary, optional, join, thanks, back } = en.dashboard.nurseGPT;

const NurseGPT = ({ isNurseGPTModalOpen, toggleNurseGPTModal }: INurseGPTInterface) => {
  const { dispatch } = useDispatch();
  const { loading, loadingAnswer, questionsTransformed, submitted, attempt } = useSelector(nurseGPTSelectors.allState);
  const [response, setResponse] = useState('');

  useEffect(() => {
    if (isNurseGPTModalOpen && !attempt) dispatch(nurseGPTActions.attemptQuiz());
  }, [isNurseGPTModalOpen]);

  const handleResponseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setResponse(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      nurseGPTActions.submitAnswer({
        attemptId: attempt?.attemptId || '',
        questionId: Object.keys(questionsTransformed || {})[0],
        response,
      }),
    );
  };

  const renderModalContent = () => {
    if (loading)
      return (
        <SpinnerContainer>
          <Spinner loading={loading} />
        </SpinnerContainer>
      );
    if (submitted)
      return (
        <>
          <NurseGPTModalSummary>{thanks}</NurseGPTModalSummary>
          <Button variant="primary" onClick={toggleNurseGPTModal}>
            {back}
          </Button>
        </>
      );
    const questionText = Object.values(questionsTransformed || {})[0]?.content.question.content.text;
    return (
      <>
        <NurseGPTModalSummary>{summary}</NurseGPTModalSummary>
        <NurseGPTModalQuestion>{questionText}</NurseGPTModalQuestion>
        <Input
          placeholder={optional}
          minRows={3}
          onChange={handleResponseChange}
          value={response}
          disableUnderline
          fullWidth
          multiline
        />
        <Button variant="primary" loading={loadingAnswer} onClick={handleSubmit}>
          {join}
        </Button>
      </>
    );
  };

  if (!isNurseGPTModalOpen) return null;

  return (
    <NurseGPTModalOverlay>
      <ClickAwayListener onClickAway={toggleNurseGPTModal}>
        <NurseGPTModalContainer>
          <NurseGPTModalTitle>{waitlist}</NurseGPTModalTitle>
          {renderModalContent()}
        </NurseGPTModalContainer>
      </ClickAwayListener>
    </NurseGPTModalOverlay>
  );
};

export default NurseGPT;
