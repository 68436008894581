import { styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, getTheme } from '../../../../../themes';
import { ILooseObject } from '../../../../types';

const theme = getTheme();

const DropDownContainer = styled('div')`
  svg {
    color: ${colors.primary};
  }
  width: 100%;

  label,
  div {
    font-weight: 700;
    color: ${colors.black};
  }
`;

const dropDownStyles = (overrides?: ILooseObject) =>
  makeStyles({
    dropDownMenu: {
      padding: theme.spacing(1),
      border: `2px solid ${colors.primary}`,
      borderRadius: '8px',
      marginTop: '4px',
      maxHeight: '250px',
      color: colors.black,
      '&::-webkit-scrollbar ': {
        backgroundColor: colors.lightGrey,
        width: '4px',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',

        '@media only screen and (max-width: 600px)': {
          width: '4px',
        },
        backgroundColor: colors.primary,
      },
      'scrollbar-color': `${colors.primary} ${colors.lightGrey}`,
      'scrollbar-width': 'thin',
      '& li': {
        fontWeight: 600,
        borderRadius: '4px',
      },
      '& li:hover': {
        backgroundColor: colors.purple5,
        transition: 'background-color 0.2s ease-in-out',
      },
      ...overrides,
    },
  });

export { DropDownContainer, dropDownStyles };
