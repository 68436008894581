import React, { useEffect, useMemo, useState } from 'react';
import { TFieldValues } from '../Form/Fields/types';
import countryList from '../../../assets/countryList.json';
import { CountryMenuItem } from './styled';
import LocationInterface, { ELocationCodes } from '../../classes/LocationInterface';
import { ILooseObject } from '../../types';

interface ILocationState {
  country: string;
  state: string;
  city: string;
}

interface IOption {
  label: any;
  value: string;
  id: string;
}
interface IUseLocation {
  onChangeExternal?: (state: ILocationState) => void;
  externalState?: ILocationState;
  index?: number;
}

const useLocation = ({ onChangeExternal, externalState }: IUseLocation) => {
  const [currentCountry, setCurrentCountry] = useState(externalState?.country || '');
  const [currentState, setCurrentState] = useState(externalState?.state || '');
  const [currentCity, setCurrentCity] = useState(externalState?.city || '');
  const locationInterface = new LocationInterface();

  useEffect(() => {
    onChangeExternal?.({ country: currentCountry, state: currentState, city: currentCity });
  }, [currentCountry, currentState, currentCity]);

  const onChangeCountries = (value: TFieldValues) => {
    setCurrentCountry(value as string);
    setCurrentState('');
    setCurrentCity('');
  };

  const onChangeCountryMobile = (e: React.SyntheticEvent, option: IOption | null) => {
    if (!option) {
      setCurrentCountry('');
      setCurrentState('');
      setCurrentCity('');
      return;
    }
    setCurrentCountry(option.id);
    setCurrentState('');
    setCurrentCity('');
  };

  const onChangeState = (value: TFieldValues) => {
    setCurrentState(value as string);
    setCurrentCity('');
  };

  const onChangeStateMobile = (e: React.SyntheticEvent, option: IOption | null) => {
    if (!option) {
      setCurrentState('');
      setCurrentCity('');
      return;
    }
    setCurrentState(option.id);
    setCurrentCity('');
  };

  const onChangeCity = (value: TFieldValues) => {
    setCurrentCity(value as string);
  };

  const onChangeCityMobile = (e: React.SyntheticEvent, option: IOption | null) => {
    if (!option) {
      setCurrentCity('');
      return;
    }
    setCurrentCity(option.id);
  };

  const states = useMemo(() => {
    if (!currentCountry) return [];
    // const values = State.getStatesOfCountry(currentCountry);
    // const options = values.map(value => ({
    //   label: value.name,
    //   id: value.isoCode,
    //   value: value.isoCode,
    // }));
    const options = locationInterface.getStatesOfCountry(currentCountry as ELocationCodes);
    if (options.length === 0) {
      setCurrentState('N/A');
      return [
        {
          label: notAvailable,
          id: notAvailable,
          value: notAvailable,
        },
      ];
    }

    return options;
  }, [currentCountry]);

  const cities = useMemo(() => {
    if (!currentState || !currentCountry) return [];
    const options = locationInterface.getCitiesOfState(currentCountry, currentState);
    if (options.length === 0) {
      setCurrentCity('N/A');
      return [
        {
          label: notAvailable,
          id: notAvailable,
          value: notAvailable,
        },
      ];
    }
    return options;
  }, [currentState, currentCountry]);

  const renderCountryOptions = () => {
    const countries = Object.values(countryList);
    return countries.map(({ name, code, image, emoji }) => {
      return (
        <CountryMenuItem key={code} value={code}>
          {image ? <img src={image} alt={emoji} width="16px" height="16px" /> : <div style={{ width: '16px' }} />}{' '}
          {name}
        </CountryMenuItem>
      );
    });
  };

  const getCountryOptions = () => {
    const countries = Object.values(countryList);
    return countries.map(({ name, code, image, emoji }) => ({
      id: code,
      value: code,
      label: name,
      img: image,
    }));
  };

  const renderCountryOption = (props: any, country: ILooseObject) => {
    const { value: code, id, label: name, img: image } = country;
    return (
      <CountryMenuItem {...props} key={code} value={code}>
        {image ? <img src={image} alt={name} width="16px" height="16px" /> : <div style={{ width: '16px' }} />} {name}
      </CountryMenuItem>
    );
  };
  return {
    state: {
      country: currentCountry,
      state: currentState,
      city: currentCity,
    },
    control: {
      onChangeCountries,
      onChangeState,
      onChangeCity,
      renderCountryOptions,
      onChangeStateMobile,
      onChangeCityMobile,
      onChangeCountryMobile,
      getCountryOptions,
      renderCountryOption,
    },
    data: {
      states,
      cities,
    },
  };
};

const notAvailable = 'N/A';
export type { IUseLocation, ILocationState };
export { notAvailable };
export default useLocation;
