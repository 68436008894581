import { useState, ChangeEvent, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import {
  EmptySmartQuizContainer,
  Banner,
  StartQuizButton,
  CustomizeQuizTitle,
  CustomizeQuizSubtitle,
  CustomizeQuizSelectionContainer,
  TopicsHeader,
  TopicsSubtitle,
  TopicChoice,
  TopicChoicesContainer,
  SelectionButtons,
  TopicsControl,
  SearchIconStyled,
  SearchBar,
  SearchInput,
  StartQuizButtonContainer,
  NoTopicsMessage,
  CategoriesContainer,
  TopicTitle,
  CategoriesTags,
  NoCategoriesMessage,
  CategoriesList,
  ContentContainer,
  LogoTextContainer,
  Text,
  ButtonContainer,
  CustomizeQuizHeading,
  CustomizeQuizTypesContainer,
  PaddedButtonContainer,
} from './styled';
import Button from '../components/Button';
import { en } from '../../i18n';
import Spinner from '../components/Spinner';
import usePropsOverride from '../hooks/usePropsOverride';
import { IUnstartedQuizProps } from './types';
import { topicsSelectors, actions as topicsActions, SLICE_NAME as topicsSlice } from '../StudySpace/redux/topics/slice';
import { categoriesSelectors } from '../StudySpace/redux/categories/slice';
import { ISelectedTopic, EQuizTypes, ECaseStudyClinicalJudgement, ILooseObject } from '../types';
import { Tag, TitleText } from '../sharedStyled';
import { humanize } from '../utils';
import { useDispatch, useKeyDownPress, useNavigations, useURLParam } from '../hooks';
import { topicsWithCaseStudies, caseStudyTopicIdsToClinicalJudgement } from '../StudySpace/staticConstants';
import parse from 'html-react-parser';
import ButtonToolTip from '../components/ButtonToolTip';
import SmartNLogo from '../../images/icons/signup/smartN.png';
import { useHistory } from 'react-router';

const {
  title: i18nTitle,
  text: i18nText,
  buttonText: i18nButtonText,
  customizeQuiz,
  selectTopics,
  selectOneTopic,
  topicsTitle,
  topicsSelected,
  topicsSubtitle,
  selectAll,
  clearAll,
  searchTopics,
  cancel,
  mustSelect,
  categoriesTitle,
  categoriesSubtitle,
  noTopics,
  noCategories,
  customizeQuizHeading,
  selectType,
  selectOneOption,
  multipleChoiceOption,
  ngnOption,
  bothOptions,
  skip,
  noClinicalJudgement,
  clinicalJudgementSubTitle,
  clinicalJudgementTitle,
} = en.smartQuiz;

const types = [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.NGN_CASE_STUDY, EQuizTypes.BOTH];

const typesToLabels = {
  [EQuizTypes.MULTIPLE_CHOICE]: multipleChoiceOption,
  [EQuizTypes.NGN_CASE_STUDY]: ngnOption,
  [EQuizTypes.BOTH]: bothOptions,
  [EQuizTypes.ARITHMETIC]: null,
  [EQuizTypes.SMARTN_STYLE_QUESTIONS]: null,
  [EQuizTypes.ALL]: null,
};

const typesToPayload = {
  [EQuizTypes.MULTIPLE_CHOICE]: [EQuizTypes.MULTIPLE_CHOICE],
  [EQuizTypes.NGN_CASE_STUDY]: [EQuizTypes.NGN_CASE_STUDY],
  [EQuizTypes.BOTH]: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.NGN_CASE_STUDY],
  [EQuizTypes.ARITHMETIC]: [EQuizTypes.ARITHMETIC],
  [EQuizTypes.SMARTN_STYLE_QUESTIONS]: [EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.ARITHMETIC],
  [EQuizTypes.ALL]: [EQuizTypes.NGN_CASE_STUDY, EQuizTypes.MULTIPLE_CHOICE, EQuizTypes.ARITHMETIC],
};

const UnstartedQuiz = ({ overrides, ...props }: IUnstartedQuizProps) => {
  const {
    startQuiz,
    skipPlacement,
    loading,
    loadingContainer = false,
    title = i18nTitle,
    buttonText = i18nButtonText,
    text = i18nText,
    isPlacement,
    buttonDisabled = false,
    buttonDisableText = mustSelect,
    component,
    bannerOverride,
  } = usePropsOverride<IUnstartedQuizProps>(props, overrides);

  const history = useHistory();
  const {
    location: { state: locationState },
  } = history;
  const { dispatch: topicsDispatch } = useDispatch(topicsSlice);
  const { entities: topics, ids: topicsIds, parentCategories, selectedTopics } = useSelector(topicsSelectors.allState);
  const { entities: categories } = useSelector(categoriesSelectors.allState);
  const attemptIdQuery = useURLParam('attemptId');
  const [type, setType] = useState<EQuizTypes>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [currentSelectedTopics, setCurrentSelectedTopics] = useState<ISelectedTopic>({});
  const topicsCount = Object.values(currentSelectedTopics).filter(Boolean).length;
  const noTopicsSelected = topicsCount === 0;
  const { isViewingPastQuizzes } = (locationState as ILooseObject) || {};
  const { navigateToHome } = useNavigations();

  useEffect(() => {
    setCurrentSelectedTopics(selectedTopics);
  }, [selectedTopics]);

  const onTypeChange = (type: EQuizTypes) => {
    if (type === EQuizTypes.NGN_CASE_STUDY) {
      const newSelectedTopics = Object.keys(currentSelectedTopics).reduce((acc, id) => {
        return { ...acc, [id]: currentSelectedTopics[id] && topicsWithCaseStudies.includes(id) };
      }, {});
      setCurrentSelectedTopics(newSelectedTopics);
    }
    setType(type);
  };

  const toggleSearch = () => {
    setQuery('');
    setIsSearchOpen(!isSearchOpen);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onTopicChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newSelectedTopics = { ...currentSelectedTopics, [target.value]: target.checked };
    setCurrentSelectedTopics(newSelectedTopics);
  };

  const onSelectAll = () => {
    const newCurrentSelectedTopics = topicsIds.reduce((acc, id) => {
      if (!parentCategories[id]) return acc;
      let result;
      if (type !== EQuizTypes.NGN_CASE_STUDY) result = true;
      else result = topicsWithCaseStudies.includes(id as string);
      return { ...acc, [id]: result };
    }, {});
    setCurrentSelectedTopics(newCurrentSelectedTopics);
  };

  const onClear = () => {
    setCurrentSelectedTopics({});
  };

  const onStart = () => {
    topicsDispatch(topicsActions.setSelectedTopics({ selectedTopics: currentSelectedTopics }));
    startQuiz(
      Object.keys(currentSelectedTopics).filter(topicId => currentSelectedTopics[topicId]),
      typesToPayload[type || EQuizTypes.MULTIPLE_CHOICE],
    );
  };

  const renderTopicChoices = () => {
    return topicsIds.map((id: EntityId) => {
      if (!id) return null;
      const topic = topics[id];
      if (!topic) return null;
      //if (!parentCategories[id]) return null;
      if (query && !topic.name.toLowerCase().includes(query)) return null;
      return (
        <TopicChoice key={id}>
          <Checkbox
            checked={!!currentSelectedTopics[topic.id]}
            value={topic.id}
            onChange={onTopicChange}
            disabled={type === EQuizTypes.NGN_CASE_STUDY && !topicsWithCaseStudies.includes(id as string)}
          />
          <p>{topic.name}</p>
        </TopicChoice>
      );
    });
  };

  const renderCategories = () => {
    return Object.keys(currentSelectedTopics)
      .filter((topicId: EntityId) => currentSelectedTopics[topicId])
      .map((topicId: EntityId) => {
        const topicName = topics[topicId]?.name;

        return (
          <CategoriesContainer key={topicId}>
            <TopicTitle>{topicName}</TopicTitle>
            {parentCategories[topicId].length === 0 ? (
              <NoCategoriesMessage>{noCategories}</NoCategoriesMessage>
            ) : (
              <CategoriesTags>
                {parentCategories[topicId].map((categoryId: EntityId) => (
                  <Tag variant="filled">{humanize(categories[categoryId]?.name || '')}</Tag>
                ))}
              </CategoriesTags>
            )}
          </CategoriesContainer>
        );
      });
  };

  const renderClinicalJudgementModels = () => {
    return Object.keys(currentSelectedTopics)
      .filter((topicId: EntityId) => currentSelectedTopics[topicId])
      .map((topicId: EntityId) => {
        const topicName = topics[topicId]?.name;
        const judgementModels = caseStudyTopicIdsToClinicalJudgement[topicId];
        const judgementModelsSet = judgementModels && new Set(judgementModels);
        return (
          <CategoriesContainer key={topicId}>
            <TopicTitle>{topicName}</TopicTitle>
            {!judgementModels || judgementModels.length === 0 ? (
              <NoCategoriesMessage>{noClinicalJudgement}</NoCategoriesMessage>
            ) : (
              <CategoriesTags>
                {Array.from(judgementModelsSet).map((judgement: ECaseStudyClinicalJudgement) => (
                  <Tag variant="filled">{judgement}</Tag>
                ))}
              </CategoriesTags>
            )}
          </CategoriesContainer>
        );
      });
  };

  useKeyDownPress('Enter', () => {
    if (isPlacement) {
      onStart();
    }
  });

  const getStartButon = () => {
    const disableNoTopicsSelected = (noTopicsSelected || !type) && !isPlacement;
    const isButtonDisabled = buttonDisabled || disableNoTopicsSelected;

    const startButton = (
      <ButtonToolTip
        buttonProps={{
          disabled: isButtonDisabled,
          loading: loading,
          onClick: onStart,
          size: 'large',
          variant: 'primary',
          children: buttonText,
        }}
        extraButtonProps={{ isPlacement }}
        buttonOverride={StartQuizButton}
        toolTipProps={{
          title: buttonDisableText,
          leftPopper: '20px',
        }}
        showToolTip={isButtonDisabled}
        ToolTipContainer={StartQuizButtonContainer}
      ></ButtonToolTip>
    );
    return startButton;
  };

  const getBanner = () => {
    if (bannerOverride) return bannerOverride;
    return <Banner bottom>{getStartButon()}</Banner>;
  };

  const renderUnstartedPlacementTest = () => (
    <ContentContainer>
      <LogoTextContainer>
        <img src={SmartNLogo}></img>
        <TitleText>{title}</TitleText>
      </LogoTextContainer>
      <Text>{parse(text)}</Text>
      <PaddedButtonContainer>
        {getStartButon()}
        <Button variant="secondary" onClick={skipPlacement}>
          {skip}
        </Button>
      </PaddedButtonContainer>
    </ContentContainer>
  );

  const renderTags = () => {
    switch (type) {
      case EQuizTypes.MULTIPLE_CHOICE:
        return renderCategories();
      case EQuizTypes.NGN_CASE_STUDY:
        return renderClinicalJudgementModels();
    }
    return renderCategories();
  };

  const renderUnstartedQuiz = () => {
    if (loadingContainer) return null;
    if (!attemptIdQuery && !isViewingPastQuizzes) navigateToHome();

    return (
      <>
        <CustomizeQuizTitle>{customizeQuiz}</CustomizeQuizTitle>
        <CustomizeQuizHeading>{customizeQuizHeading}</CustomizeQuizHeading>
        <CustomizeQuizSubtitle>
          {selectType} <span>{selectOneOption}</span>
        </CustomizeQuizSubtitle>
        <CustomizeQuizTypesContainer>
          {types.map((option, index) => (
            <div key={index}>
              <Radio checked={option === type} value={option} onChange={() => onTypeChange(option)} />
              <div>{typesToLabels[option]}</div>
            </div>
          ))}
        </CustomizeQuizTypesContainer>
        <CustomizeQuizSubtitle>
          {selectTopics} <span>{selectOneTopic}</span>
        </CustomizeQuizSubtitle>
        <CustomizeQuizSelectionContainer>
          <div>
            <TopicsHeader>
              <span>{topicsTitle}</span>
              <span>
                {topicsCount} {topicsSelected}
              </span>
            </TopicsHeader>
            <TopicsSubtitle>{topicsSubtitle}</TopicsSubtitle>
            <TopicsControl isSearchOpen={isSearchOpen}>
              <SearchIconStyled onClick={toggleSearch} isSearchOpen={isSearchOpen} />
              {isSearchOpen ? (
                <SearchBar>
                  <SearchInput placeholder={searchTopics} onChange={onInputChange} value={query} />
                  <Button onClick={toggleSearch} variant="underlined">
                    {cancel}
                  </Button>
                </SearchBar>
              ) : (
                <SelectionButtons>
                  <Button onClick={onSelectAll} variant="underlined">
                    {selectAll}
                  </Button>
                  <Button onClick={onClear} variant="underlined">
                    {clearAll}
                  </Button>
                </SelectionButtons>
              )}
            </TopicsControl>
            <TopicChoicesContainer>{renderTopicChoices()}</TopicChoicesContainer>
          </div>
          <div>
            <TopicsHeader>
              <span>{type === EQuizTypes.NGN_CASE_STUDY ? clinicalJudgementTitle : categoriesTitle}</span>
            </TopicsHeader>
            <TopicsSubtitle>
              {type === EQuizTypes.NGN_CASE_STUDY ? clinicalJudgementSubTitle : categoriesSubtitle}
            </TopicsSubtitle>
            {noTopicsSelected ? (
              <NoTopicsMessage>{noTopics}</NoTopicsMessage>
            ) : (
              <CategoriesList>{renderTags()}</CategoriesList>
            )}
          </div>
        </CustomizeQuizSelectionContainer>
        {getBanner()}
      </>
    );
  };

  const renderCustomComponent = () => {
    if (!component || loading || loadingContainer) return null;
    return (
      <>
        <ContentContainer>{component}</ContentContainer>
        {getBanner()}
      </>
    );
  };

  const renderContent = () => {
    if (isPlacement) return renderUnstartedPlacementTest();
    if (component) return renderCustomComponent();
    return renderUnstartedQuiz();
  };

  return (
    <EmptySmartQuizContainer isCustom={!!component} isPlacement={isPlacement}>
      <Spinner size={'60px'} appplyBackground={isPlacement} loading={loadingContainer} />
      {renderContent()}
    </EmptySmartQuizContainer>
  );
};

export default UnstartedQuiz;
