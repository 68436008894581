import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import { widthInAnimation } from '../../sharedStyled';

interface IProps {
  color?: string;
  useBarOnly?: boolean;
  barHeight?: string;
  useBarStripes?: boolean;
  backgroundColor?: string;
  width?: number;
  useMountAnimation?: boolean;
}

const BarContainer = styled('div')`
  gap: ${({ theme }) => theme.spacing(0.75)};
  button,
  span {
    flex-basis: 100%;
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: ;
  ${props => props.theme.breakpoints.down('sm')} {
  
    margin: ${props => props.theme.spacing(1)} 0;
  }
  p {
    flex-shrink: 0;
    margin:0;
    ${props => props.theme.breakpoints.up('sm')} {
      display: none;
    }
  }
  sp
`;

const Bar = styled('div', {
  shouldForwardProp: prop =>
    shouldForwardProps(prop, [
      'color',
      'title',
      'useBarStripes',
      'width',
      'backgroundColor',
      'barHeight',
      'useBarStripes',
      'useBarOnly',
      'useMountAnimation',
    ]),
})<IProps>(
  ({ color, useBarOnly, theme, barHeight, backgroundColor, width, useBarStripes, useMountAnimation }) => `
  position: relative;
  margin-top: ${useBarOnly ? 0 : theme.spacing(0.75)};
  height: ${barHeight || '20px'};
  width: 100%;
  margin-right: ${theme.spacing(1)};
  border-radius: 50px;
  background-color: ${backgroundColor || '#dadada'};
  span {
    display: block;
    position: relative;
    height: 100%;

    ${useMountAnimation ? widthInAnimation(width || 0, 2) : `transition: width 0.6s linear`};;
    
    width: ${width ? `${width}%` : '0'};
    border-radius: 50px;
    background-color: ${color || colors.orange30};
    overflow: hidden;
  }
  ${
    useBarStripes &&
    `
  span:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 7px 7px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }`
  }
`,
);

const BarButtonContair = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  p {
    flex-shrink: 0;
    margin-top: 5px;
    ${props => props.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
  button {
    flex-shrink: 0;
    margin-top: 3px;
  }
`;

export type { IProps };
export { Bar, BarContainer, BarButtonContair, TitleContainer };
