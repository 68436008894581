import { PassingChanceContainer, PassingChanceTag, PassingChanceIconContainer, InfoSpan } from './styled';
import { en } from '../../../i18n';
import { IAttempt } from '../../types';
const { readinessAssesement } = en.dashboard;
import { Skeleton } from '@mui/material';
import { calculatePassingChancePercentage, mapPercentageToPassingChanceTag } from '../../utils';
import { READINESS_PASSING_CHANCE_QUESTION_THRESHOLD } from '../../constants';
import { useMemo } from 'react';
import { useTopicsRecommendations } from '../../hooks';
import InfoIconGeneral from '../../components/InfoIconGeneral';

interface IPassingChance {
  readinessAttempts?: IAttempt[];
}

const PassingChance = ({ readinessAttempts }: IPassingChance) => {
  const gradePercentage = useMemo(() => {
    const grades = {
      totalQuestionsAnswered: 0,
      percentage: 0,
    };
    if (!readinessAttempts) return grades;
    const stats = readinessAttempts.reduce(
      (accum, attempt) => ({
        totalQuestionsAnswered: accum.totalQuestionsAnswered + attempt.progress.answeredQuestions,
        totalMark: accum.totalMark + attempt.progress.totalMark,
        totalMaximumMark: accum.totalMaximumMark + attempt.progress.totalMaximumMark,
      }),
      { totalQuestionsAnswered: 0, totalMark: 0, totalMaximumMark: 0 },
    );
    const { totalQuestionsAnswered, totalMark, totalMaximumMark } = stats;
    if (totalQuestionsAnswered === 0 || totalMark === 0 || totalMaximumMark === 0)
      return { totalQuestionsAnswered, percentage: 0 };
    const percentage = stats.totalMark / stats.totalMaximumMark;
    return { totalQuestionsAnswered, percentage };
  }, [readinessAttempts, readinessAttempts?.length]);

  const { topicsResults } = useTopicsRecommendations(readinessAttempts, {
    thresholdTopics: 0,
    useOnbaordingWeakTopics: false,
  });

  const finalPercentage = calculatePassingChancePercentage(gradePercentage.percentage, topicsResults);

  const renderPassingChance = () => {
    const payload = mapPercentageToPassingChanceTag(
      finalPercentage,
      gradePercentage.totalQuestionsAnswered,
      READINESS_PASSING_CHANCE_QUESTION_THRESHOLD,
    );
    const tag = (
      <PassingChanceTag textColor={payload.textColor} backgroundColor={payload.backgroundColor}>
        {payload.text}
      </PassingChanceTag>
    );
    if (!readinessAttempts)
      return (
        <Skeleton className="skeleton" variant="rounded">
          {tag}
        </Skeleton>
      );
    return tag;
  };

  return (
    <PassingChanceContainer>
      {renderPassingChance()}
      <PassingChanceIconContainer>
        <InfoSpan>{readinessAssesement.tagLabel}</InfoSpan>
        <InfoIconGeneral text={en.readinessAssesment.passingChanceDisclaimerDashboard} />
      </PassingChanceIconContainer>
    </PassingChanceContainer>
  );
};

export default PassingChance;
