import { IFlowOverrides } from '../SmartQuiz/types';

type TMode = 'exam' | 'practice';

interface ISmartQuizTypeBuilder {
  mode: TMode;
}
class SmartQuizTypeBuilder {
  private mode: TMode;

  constructor({ mode = 'practice' }: ISmartQuizTypeBuilder) {
    this.mode = mode;
  }

  getFlowOverrides(): IFlowOverrides | undefined {
    if (this.mode === 'practice') return undefined;
    return {
      skipExplanation: true,
      skipCaseStudyUnstartedPage: true,
    };
  }
}

export default SmartQuizTypeBuilder;
