import { styled } from '@mui/material/styles';
import { ModalContainer, ModalContent, ModalOverlay } from '../../Membership/styled';
import { shouldForwardProps } from '../../utils';

interface IModalOverlay {
  stickToTop?: boolean;
}

const DashboardModalOverlay = styled(ModalOverlay, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open', 'stickToTop']),
})<IModalOverlay>(
  ({ stickToTop, theme }) => `
    ${theme.breakpoints.up('lg')} {
      width: calc(100vw - min(23vw, 460px));
      margin-left: min(23vw, 460px);
    }
    position: fixed;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: flex-start;
    top:${stickToTop ? 0 : 64}px;
    ${theme.breakpoints.up('lg')} {
      align-items: center;
    }
  
    transition: all 0.2s linear;
  `,
);

const DashboardModalContent = styled(ModalContent)`
  span {
    color: unset;
  }
  .bold {
    font-weight: 700;
  }
`;

const DashboardModalContainer = styled(ModalContainer)`
  ${props => props.theme.breakpoints.down('lg')} {
    width: 75%;
    margin-top: 30px;
  }
`;

const LayoutWrapper = styled('div')`
  display: contents;
`;

export { DashboardModalOverlay, DashboardModalContent, DashboardModalContainer, LayoutWrapper };
