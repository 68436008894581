import { useEffect } from 'react';
import { useDispatch } from '../hooks';
import { useSelector } from 'react-redux';
import { IFormFeedback, TFeedbackPositions } from '../components/Form';
import { authSelectors, actions } from './redux/slice';
import { en } from '../../i18n';

interface IUseAuthForm {
  errorFeedbackPosition?: TFeedbackPositions;
  successFeedbackPosition?: TFeedbackPositions;
  disableResetState?: boolean;
  useDefaultValues?: boolean;
}

const useAuthForm = ({
  errorFeedbackPosition = 'topOfForm',
  successFeedbackPosition = 'aboveFields',
  disableResetState = false,
  useDefaultValues = true,
}: IUseAuthForm) => {
  const { dispatch } = useDispatch();
  const { loading, isError, errors, successFeedback } = useSelector(authSelectors.allState);

  useEffect(() => {
    if (disableResetState) return;

    dispatch(actions.resetState());
  }, []);

  const getFormErrorFeedback = (): IFormFeedback | undefined => {
    if (!isError) return undefined;
    const { message } = errors;
    return {
      subTitle: useDefaultValues ? message || en.somethingWentWrong : message,
      position: errorFeedbackPosition,
      isActive: true,
    };
  };

  const getSuccesFeedback = (): IFormFeedback | undefined => {
    if (!successFeedback) return undefined;
    const { title, subTitle } = successFeedback;
    return {
      title,
      subTitle: useDefaultValues ? subTitle || en.success : subTitle,
      position: successFeedbackPosition,
      isActive: true,
    };
  };

  return {
    loading,
    errorFeedback: getFormErrorFeedback(),
    successFeedback: getSuccesFeedback(),
  };
};

export default useAuthForm;
