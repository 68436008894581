const document = `<p>
  PLEASE READ THIS PRIVACY DISCLOSURE AND INFORMATION (&ldquo;PRIVACY POLICY&rdquo;) CAREFULLY BEFORE USING the Smartn
  PLATFORM.
</p>
<p><br /></p>
<p>LAST PUBLISHED: February 15th, 2023</p>
<p><br /></p>
<p>Welcome to smart&rsquo;n - the next generation learning support platform for nurses.</p>
<p><br /></p>
<p>
  You are reading this Privacy Policy because you are using the smartn website, digital experience, social media
  platform, mobile app, or one of the other products or services, all of which are part of the Smart&apos;n Platform
  (&ldquo;Platform&rdquo;).&nbsp;
</p>
<p><br /></p>
<p>
  You may access the Platform through a computer, mobile phone, tablet, console, or other technology, which we refer to
  here as a &ldquo;Device&rdquo;. Your carrier&rsquo;s normal rates and fees apply to your Device. &nbsp;These TERMS OF
  USE (&ldquo;Terms of Use&rdquo;) constitute a binding contract between you and Soach, Inc. d.b.a. Smartn.ca or
  smart&rsquo;n regarding your use of the Platform.&nbsp;
</p>
<p><br /></p>
<p>This Privacy Policy explains how we protect visitors&rsquo; information gathered via the Smart&rsquo;n Platform.</p>
<p><br /></p>
<p>By using the Platform you are agreeing to the use of your information as described in this Privacy Policy.</p>
<p><br /></p>
<p>
  This Privacy Policy may be supplemented or amended from time to time by Privacy Policies that are specific to certain
  areas of the Platform (e.g. smartn.ca).
</p>
<p><br /></p>
<p>1. INFORMATION COLLECTION</p>
<p><br /></p>
<p>
  1.1. You do not have to submit any personal information in order to use Smart&rsquo;n website. &nbsp;This website only
  collects personal information that is specifically and voluntarily provided by visitors. Such information may consist
  of, but is not limited to, your first and last name, postal address, and email address.
</p>
<p><br /></p>
<p>
  1.2. We may also store and maintain any user content that you provide on the Platform, including but not limited to
  postings on any blogs, forums, wikis and other social media applications and services that we may provide.
</p>
<p><br /></p>
<p>
  1.3. The Platform is intended for and directed at users that reside in North America. We do not intentionally collect
  or store personal information of users that reside outside of North America.
</p>
<p><br /></p>
<p>2. LOG INFORMATION, COOKIES, AND WEB BEACONS</p>
<p><br /></p>
<p>
  2.1. The Platform collects standard internet log information including your IP address, browser type and language,
  access times, and referring website addresses. To ensure that the Platform is well managed and to facilitate improved
  navigation, we or our service providers may also use cookies (small text files stored in a user&rsquo;s browser) or
  web beacons (electronic images that allow the Platform to count visitors who have accessed a particular page and to
  access certain cookies) to collect aggregate data.
</p>
<p><br /></p>
<p>3. INFORMATION USE</p>
<p><br /></p>
<p>
  3.1. At times, you may provide personal information via this site, for example, to gain access to specific content,
  attend a hosted event, respond to a survey, or request communications about specific areas of interest. In such cases,
  the information you submit will be used to manage your request and to customize and improve the Platform and related
  services offered to you.&nbsp;
</p>
<p><br /></p>
<p>
  3.2. We may also use your personal information for marketing purposes, or to send you promotional materials or
  communications regarding products or services provided by Smart&rsquo;n that we feel may be of interest to you.&nbsp;
</p>
<p><br /></p>
<p>
  3.3. We may also contact you to seek feedback on products or services provided by Smart&rsquo;n or for market or other
  research purposes.
</p>
<p><br /></p>
<p>
  3.4. Your personal information may also be used to protect our rights or property and that of our users and, where
  appropriate, to comply with legal process.
</p>
<p><br /></p>
<p>
  3.5. You may at any time request that we discontinue sending you emails or other communications generated in response
  to your provision of personal information via the Platform.
</p>
<p><br /></p>
<p>4. DISCLOSURE OF INFORMATION TO THIRD PARTIES</p>
<p><br /></p>
<p>
  4.1. We will not provide your personal information to entities that are not part of smartn.ca, except in the case of
  disclosure to third parties in order to respond to your requests or inquiries, or as part of a corporate transaction
  such as a sale, divestiture, reorganization, merger or acquisition, or where those parties handle information on our
  behalf.
</p>
<p><br /></p>
<p>
  4.2. All of these disclosures may involve the transfer of personal information to countries or regions without data
  protection rules similar to those in effect in Canada and the United States.
</p>
<p><br /></p>
<p>
  4.3. Personal information may be disclosed if the creation of your Smart&rsquo;n access was initiated by a third party
  or your access was initiated by you with the use of a code provided to you by a third party. User data refers to
  personal information provided by the user that could be used to identify the user. &nbsp;Examples include user name,
  email address, or phone number. &nbsp;User actions refer to data collected while using the Platform. &nbsp;Examples
  include number of logins, time on site, lessons viewed, study plan progress, and number of questions taken. &nbsp;User
  results refer to results of evaluations on Smar&rsquo;n. &nbsp;Examples include quiz results and questions
  statistics.&nbsp;
</p>
<p><br /></p>
<p>
  4.4. Personal information may also be disclosed to law enforcement, regulatory, or other government agencies, or to
  other third parties, in each case to comply with legal or regulatory obligations or requests.
</p>
<p><br /></p>
<p>
  4.5. By providing personal information through the Platform, you are consenting to the disclosures described
  above.&nbsp;
</p>
<p><br /></p>
<p>5. BLOGS, FORUMS, WIKIS, AND OTHER SOCIAL MEDIA</p>
<p><br /></p>
<p>
  5.1. The Platform may comprise various blogs, forums, wikis, and other social media applications or services that
  allow you to share content with other users (collectively &ldquo;Social Media Applications&rdquo;). Any personal
  information or other information that you contribute to any Social Media Application can be read, collected, and used
  by other users of that Social Media Application over whom we have no control.&nbsp;
</p>
<p><br /></p>
<p>
  5.2. Therefore, we are not responsible for any other user&rsquo;s use, misuse, or misappropriation of any personal
  information or other information that you contribute to any Social Media Application.
</p>
<p><br /></p>
<p>6. ACCESS TO INFORMATION</p>
<p><br /></p>
<p>
  6.1. Platform Users who would like to request access to their information, to update their details or unsubscribe from
  communications should contact us at connect@smartn.ca. We will treat requests to access information or change
  information in accordance with applicable legal requirements.
</p>
<p><br /></p>
<p>7. RETAINING AND DELETING PERSONAL DATA</p>
<p><br /></p>
<p>
  7.1. This Section 7 sets out our data retention policies and procedure, which are designed to help ensure that we
  comply with our legal obligations in relation to the retention and deletion of personal data.
</p>
<p><br /></p>
<p>
  7.2. Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that
  purpose or those purposes.
</p>
<p><br /></p>
<p>7.3. We will retain your personal data as follows:</p>
<p><br /></p>
<p>
  7.3.1. Personal data will be retained for as long as you retain a user account on the Platform or request erasure of
  your personal data, whichever is later.
</p>
<p><br /></p>
<p>
  7.3.2. Notwithstanding the other provisions of this Section 7, we may retain your personal data where such retention
  is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital
  interests or the vital interests of another natural person.
</p>
<p><br /></p>
<p>8. YOUR RIGHTS</p>
<p><br /></p>
<p>
  8.1. In this Section 8, we have summarized the rights that you may have under data protection law. Some of the rights
  are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant
  laws and guidance from the regulatory authorities for a full explanation of these rights.
</p>
<p><br /></p>
<p>8.2. Your principal rights under data protection law may include:</p>
<p>(a) the right to access;</p>
<p>(b) the right to rectification;</p>
<p>(c) the right to erasure;</p>
<p>(d) the right to restrict processing;</p>
<p>(e) the right to object to processing;</p>
<p>(f) the right to data portability;</p>
<p>(g) the right to complain to a supervisory authority; and</p>
<p>(h) the right to withdraw consent.</p>
<p><br /></p>
<p>
  8.3. You have the right to confirmation as to whether or not we process your personal data and, where we do, access to
  the personal data, together with certain additional information. That additional information includes details of the
  purposes of the processing, the categories of personal data concerned and the recipients of the personal data.
  Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The
  first copy will be provided free of charge, but additional copies may be subject to a reasonable fee.
</p>
<p><br /></p>
<p>
  8.4. You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes
  of the processing, to have any incomplete personal data about you completed.
</p>
<p><br /></p>
<p>
  8.5. In some circumstances you have the right to the erasure of your personal data without undue delay. Those
  circumstances include: the personal data are no longer necessary in relation to the purposes for which they were
  collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under
  certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal
  data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions
  include where processing is necessary: for exercising the right of freedom of expression and information; for
  compliance with a legal obligation; or for the establishment, exercise or defense of legal claims.
</p>
<p><br /></p>
<p>
  8.6. In some circumstances you have the right to restrict the processing of your personal data. However, we will only
  otherwise process it: with your consent; for the establishment, exercise or defense of legal claims; for the
  protection of the rights of another natural or legal person; or for reasons of important public interest.
</p>
<p><br /></p>
<p>
  8.7. You have the right to object to our processing of your personal data on grounds relating to your particular
  situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the
  performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or
  the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will
  cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing
  which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of
  legal claims.
</p>
<p><br /></p>
<p>
  8.8. You have the right to object to our processing of your personal data for direct marketing purposes (including
  profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data
  for this purpose.
</p>
<p><br /></p>
<p>
  8.9. You have the right to object to our processing of your personal data for scientific or historical research
  purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary
  for the performance of a task carried out for reasons of public interest.
</p>
<p><br /></p>
<p>
  8.10. To the extent that the legal basis for our processing of your personal information is consent, you have the
  right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the
  withdrawal.
</p>
<p><br /></p>
<p>
  8.11. Use of the Platform and providing an email address shall constitute implicit consent for the purpose of
  receiving regular emails from Smart&rsquo;n for purposes of obtaining news and information about our services and
  products and general marketing emails. However, at any point in time you may withdraw your consent by unsubscribing
  following the instructions in the individual email communications you receive from us.
</p>
<p><br /></p>
<p>
  8.12. You may exercise any of your rights in relation to your personal data by contacting us at connnect<a
    href="mailto:info@smartn.ca"
    >@smartn.ca</a
  >.
</p>
<p><br /></p>
<p>9. INFORMATION SECURITY</p>
<p><br /></p>
<p>
  9.1. We have in place reasonable commercial standards of technology and operational security to protect all
  information provided by users via this Website from unauthorized access, disclosure, alteration, or destruction.
</p>
<p><br /></p>
<p>10. CHANGES TO OUR Privacy Policy.</p>
<p><br /></p>
<p>
  10.1. We may modify or amend this Privacy Policy from time to time at our discretion. When we make changes to this
  policy, we will amend the revision date at the top of this page, and such modified or amended Privacy Policy shall be
  effective as to you and your information as of that revision date. We encourage you to periodically review this
  Privacy Policy to be informed about how we are protecting your information.
</p>
<p><br /></p>
<p>11. CHILDREN&rsquo;S PRIVACY PROTECTION</p>
<p><br /></p>
<p>
  11.1. We understand the importance of protecting children&rsquo;s privacy in the interactive online world. The
  Platform is not designed for, directed to, or intentionally targeted at children younger than thirteen (13) years of
  age. It is not our policy to intentionally collect or maintain information about anyone under the age of 13. &nbsp;IF
  YOU ARE YOUNGER THAN THIRTEEN (13) YEARS OF AGE YOU MAY NOT USE THE PLATFORM AND YOU SHOULD NOT SUBMIT ANY PERSONAL
  INFORMATION ON THE PLATFORM USING ANY DEVICE. IF YOU ARE THIRTEEN (13) YEARS OF AGE OR OLDER BUT UNDER EIGHTEEN YEARS
  OF AGE, YOU MAY ONLY USE THE PLATFORM WITH THE APPROVAL OF YOUR PARENT OR GUARDIAN.
</p>
<p><br /></p>
<p>12. COOKIE NOTICE</p>
<p><br /></p>
<p>
  12.1. In order to provide you with a more personalized and responsive service we need to remember and store
  information about how you use the Platform. This is done using small text files called cookies. Cookies contain small
  amounts of information and are downloaded to your computer or other device by a server for the Platform. Your web
  browser then sends these cookies back to smartn.ca on each subsequent visit so that it can recognize you and remember
  things like your user preferences. You can find more detailed information about cookies and how they work at&nbsp;<a
    href="http://www.aboutcookies.org/"
    >http://www.aboutcookies.org/</a
  >.
</p>
<p><br /></p>
<p>
  12.2. Whenever you use the Platform, information may be collected through the use of cookies and other technologies.
  By using the Platform you agree to our use of cookies as described in this section 12 Cookie Notice.
</p>
<p><br /></p>
<p>
  12.3. Some of the cookies we use are necessary to enable you to move around the Platform and use its features such as
  accessing secure areas that may contain content for registered users.
</p>
<p><br /></p>
<p>
  12.4. We also use functional cookies to record information about the choices you have made and to allow us to tailor
  the Platform to our users; for example, to remember your language or region or that you have already completed a
  survey. This information is usually anonymized and is not used for any purpose unrelated to the Platform.
</p>
<p><br /></p>
<p>
  12.5. smartn.ca or our service providers also use analytic services to help us understand how effective our content
  is, what interests our users have, and to improve how the Platform works. In addition, we use web beacons, tracking
  pixels and similar services to understand more about the visitors to the Platform, and to count visitor numbers and
  performance cookies to track how many individual users access the Platform and how often. This information is used for
  statistical purposes and to improve how the Platform works and it is not our intention to use such information to
  personally identify any user.&nbsp;
</p>
<p><br /></p>
<p>
  12.6. However, if at any time you register and sign into the Platform we may combine such data with information from
  our web analytic services and cookies to analyze how you use the Platform in more detail.
</p>
<p><br /></p>
<p>
  12.7. The Platform does use targeting cookies to deliver targeted advertising on the Platform. We may use web beacons
  (also known as pixel tags) to determine which users have come to the Platform after viewing an advertisement we have
  served on third party websites. We also work with third parties to collect data so that we can determine the
  effectiveness of our ads and serve ads targeted to your interests. The use of web beacons does not provide us with any
  personal information and we do not use this technology to access your personal information. It is used only to compile
  aggregated data about users who visit the Platform and to gauge the effectiveness of our ads. Web beacons do not place
  information on your device, but may work in conjunction with cookies to monitor activity. You cannot remove or block
  web beacons, but you can remove or block cookies with which they work to block the collection of information about
  your user activity.
</p>
<p><br /></p>
<p>13. MANAGING COOKIES</p>
<p><br /></p>
<p>
  13.1. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from
  browser to browser, and from version to version.&nbsp;
</p>
<p><br /></p>
<p>13.2. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
<p><br /></p>
<p>13.2.1. https://support.google.com/chrome/answer/95647?hl=en (Chrome);</p>
<p>13.2.2. https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</p>
<p>13.2.3. http://www.opera.com/help/tutorials/security/cookies/ (Opera);</p>
<p>
  13.2.4. https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet
  Explorer);
</p>
<p>13.2.5. https://support.apple.com/kb/PH21411 (Safari); and</p>
<p>13.2.6. https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</p>
<p>
  13.3. You may opt-out of Google&rsquo;s use of cookies by visiting Google&rsquo;s Ad Settings at
  https://adssettings.google.com/authenticated&nbsp;
</p>
<p>
  13.4. Blocking all cookies will have a negative impact upon the usability of many websites including the
  Platform.&nbsp;
</p>
<p>13.5. If you block cookies, you will not be able to use all the features on the Platform.</p>
<p><br /></p>
<p>14. EMAIL COMMUNICATIONS</p>
<p><br /></p>
<p>
  14.1. We may also use tracking technologies to determine whether you have read, clicked on, or forwarded certain email
  communications we send to you so that we can make our communications more helpful and interesting. If you do not wish
  us to confirm whether you have opened, clicked on or forwarded our communications, you will need to unsubscribe, as it
  is not possible for us to send these emails without tracking enabled. Registered subscribers can update their
  communication preferences at any time by contacting us, or you can unsubscribe following the instructions in the
  individual email communications you receive from us.
</p>
<p><br /></p>
<p>15. SMS COMMUNICATIONS</p>
<p><br /></p>
<p>
  15.1 You may have opted in to receive automated promotional SMS text messages from us. Message and data rates may
  apply to our automated promotional SMS text message service. We may use tracking technologies to determine whether you
  have read or clicked links in the automated SMS messages that we send to you so that we can make our communications
  more helpful or interesting. If you do not wish us to confirm whether you have viewed or clicked links on our SMS
  messages, you will need to opt out of the messages by replying STOP to end our SMS messages. We store and maintain
  your name, email and mobile number that you voluntarily provide to us upon opting in to our automated promotional text
  messages. This allows us to record who has opted in to the automated promotional SMS text message service. We may also
  store and maintain responses to SMS messages that we receive from you after you have opted in to the automated
  promotional SMS text message service. After opting in to the service, you understand and agree that you must notify us
  if you change your mobile number at any time so we can cease sending automated promotional SMS text messages to your
  prior mobile number.
</p>
<p><br /></p>
<p><br /></p>`;

export default document;
