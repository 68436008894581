import { EContentTypes } from './questionTemplates';
import { EQuestionTypes } from './enums';

interface ICaseStudyBaseTemplate {
  copy: string;
}

interface ICaseStudyPlainText extends ICaseStudyBaseTemplate {}

interface ICaseStudyImageText extends ICaseStudyBaseTemplate {
  image: string;
}

interface ICaseStudyContent {
  detailType: EContentTypes;
  detail: ICaseStudyPlainText | ICaseStudyImageText;
}

enum ECaseStudyQuestionTypes {
  MULTIPLE_CHOICE_V2 = 'multipleChoiceV2',
  MATRIX_SINGLE_CHOICE = 'matrixSingleChoice',
  SINGLE_CHOICE = 'singleChoice',
  MATRIX_MULTIPLE_CHOICE = 'matrixMultipleChoice',
  MULTIPLE_HIGHLIGHT = 'multipleHighlight',
  DROP_DOWN_CLOZE = 'dropDownCloze',
  DRAG_DROP_CLOZE = 'dragAndDropCloze',
  BOW_TIE = 'bowTie',
}

interface ICaseStudyTab {
  id: string;
  detailType: 'tab';
  detail: {
    label: ICaseStudyContent;
    content: ICaseStudyContent;
  };
}

interface ICaseStudyOption {
  id: string;
  detailType: 'option';
  detail: {
    content: ICaseStudyContent;
  };
}

interface ICaseStudySubquestion {
  id?: string;
  detailType: ECaseStudyQuestionTypes;
  detail: {
    question: ICaseStudyContent;
    explanation?: ICaseStudyContent;
    note?: ICaseStudyContent;
    tip?: ICaseStudyContent;
    header?: ICaseStudyContent;
    options?: ICaseStudyOption[];
    answerIds?: string[];
    answerId?: string;
    questions?: ICaseStudySubquestion[];
    label?: ICaseStudyContent;
  };
}

interface ICaseStudyQuestions {
  id: string;
  detailType: 'subQuestion';
  detail: {
    tabs: ICaseStudyTab[];
    question: ICaseStudySubquestion;
  };
}

interface ICaseStudy {
  id?: string;
  questionType: EQuestionTypes.NGN_CASE_STUDY;
  introduction?: ICaseStudyContent;
  questions: ICaseStudyQuestions[];
  topicsIds?: string[];
}

export type {
  ICaseStudy,
  ICaseStudySubquestion,
  ICaseStudyOption,
  ICaseStudyTab,
  ICaseStudyContent,
  ICaseStudyQuestions,
};

export { ECaseStudyQuestionTypes };
