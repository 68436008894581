import { useContext } from 'react';
import { IRoute } from '../types';
import { AppContext } from '../../AppContext';
import { Redirect, Route } from 'react-router';
import paths, { defaultPaths } from './paths';

const PrivateRoute = ({ path, component, exact, applyRedirect = true }: IRoute) => {
  const { isLoggedIn, isOnboarded } = useContext(AppContext);

  if (!isLoggedIn) return <Redirect exact={exact} path={path} to={defaultPaths.public} />;

  if (!isOnboarded && applyRedirect) return <Redirect exact={exact} path={path} to={paths.private.onboarding.path} />;

  return <Route path={path} component={component} exact={exact} />;
};

export default PrivateRoute;
