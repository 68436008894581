import { styled } from '@mui/material/styles';
import { colors } from '../../../../themes';
import { shouldForwardProps } from '../../../utils';
import { scrollbar, opacityInAnimation } from '../../../sharedStyled';
import { Theme } from '@mui/system';

interface ITabProps {
  selected: boolean;
}

interface IChoiceListProps {
  highlighted: boolean;
  isReviewing: boolean;
}

interface IDragItemProps {
  isDragged?: boolean;
}

interface IDragChoiceListProps {
  count: number;
}

interface ILineProps {
  angle: number;
  top?: number;
  left?: number;
  right?: number;
  topMobile?: number | string;
  leftMobile?: number | string;
  rightMobile?: number | string;
  angleMobile?: number;
}

interface ITipProps {
  open: boolean;
}

interface ICaseStudyContainerProps {
  isReviewing?: boolean;
}

const getItemHeight = (theme: Theme, useMin?: boolean) => {
  return `
    ${useMin ? 'min-height' : 'height'}: 48px;
    text-overflow: ellipsis;
    ${theme.breakpoints.down('xmd')} {
      ${useMin ? 'min-height' : 'height'}: 60px;
    }
    ${theme.breakpoints.down('$xs')} {
      min-height: 60px;
      height: auto;
    
    }
  `;
};

const CaseStudyContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isReviewing']),
})<ICaseStudyContainerProps>(
  ({ isReviewing, theme }) => `
  display: flex;
  height: 564px;
  // margin-bottom: ${isReviewing ? '0' : '88px'};
  overflow-x: hidden;
  ${theme.breakpoints.down('xmd')} {
    flex-direction: column;
    height: unset;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`,
);

const InfoContainer = styled('div')`
  width: 50%;
  border-right: 1px solid ${colors.lightGrey};
  padding: 26px 24px 26px 40px;
  ${props => props.theme.breakpoints.down('xmd')} {
    border-right: none;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px;
  }
`;

const QuestionContainer = styled('div')`
  width: calc(50% - 8px);
  box-sizing: border-box;
  margin-right: 8px;
  color: ${colors.black10};
  padding: 51px 32px 26px 40px;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar}
  ${props => props.theme.breakpoints.down('xmd')} {
    width: 100%;
    padding: 40px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px;
  }
  span.Mui-disabled {
    color: ${colors.purple30};
  }
  > span {
    opacity: 0.5;
  }
`;

const InfoTitle = styled('div')`
  font-weight: 700;
  font-size: 1rem;
  color: ${colors.black10};
`;

const MarksHeader = styled('div')`
  font-weight: 700;
  font-size: 1.15rem;
  color: ${colors.black10};
  width: fit-content;
  margin-left: auto;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 7px;
  ${props => props.theme.breakpoints.down('xmd')} {
    margin-left: unset;
    margin-bottom: 20px;
  }
`;

const InfoSubtitle = styled('div')`
  font-size: 1rem;
  margin-top: 8px;
  margin-bottom: 32px;
  color: ${colors.black10};
`;

const InfoTabsContainer = styled('div')`
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  flex-wrap: wrap;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    border-radius: 10px 10px 0 0;
  }
`;

const InfoTab = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['selected']),
})<ITabProps>(
  ({ selected, theme }) => `
    font-size: 0.875rem;
    font-weight: 700;
    padding: 10px 5px;
    color: ${selected ? colors.white : colors.purple30};
    background-color: ${selected ? colors.purple30 : colors.purple5};
    border: 1px solid ${colors.purple30};
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    min-width: 154px;
    text-align: center;
    cursor: pointer;

    ${theme.breakpoints.down('sm')} {
    border-radius:0;
    border:none;
  }
`,
);

const InfoTabContentWrapper = styled('div')`
  width: 100%;
  border-top: 4px solid ${colors.purple30};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  height: 348px;
`;

const InfoTabContent = styled('div')`
  color: ${colors.black10};
  margin: 32px 8px 16px 16px;
  height: 85%;
  overflow-y: auto;
  ${scrollbar}
  overflow-x:hidden;
  .note {
    display: flex;
    gap: 10px;
    margin-bottom: 40px;
    margin-top: 10px;
    div:nth-child(2) {
      margin-right: 28px;
    }
  }
  .title {
    margin: 24px 0;
    :nth-child(1) {
      margin-top: 0;
    }
  }
  ul {
    margin-top: 8px;
    padding-left: 24px;
    li {
      margin-bottom: 8px;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1px;
    margin-right: 16px;
    width: calc(100% - 16px);
    th {
      font-weight: 500;
      text-align: left;
      padding: 16px;
      background-color: ${colors.purple10};
    }
    td {
      padding: 8px 16px;
    }
    td:nth-child(1) {
      display: block;
    }
    tr:nth-child(even) {
      background-color: ${colors.blue10};
    }
    tr:nth-child(odd) {
      background-color: ${colors.purple1};
    }
  }
`;

const QuestionBody = styled('div')`
  margin-bottom: 40px;
`;

const QuestionNote = styled('div')`
  font-weight: 700;
`;

const SingleChoiceContainer = styled('div')`
  margin-top: 40px;
`;

const Choice = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1rem;
  margin-bottom: 16px;
  cursor: pointer;
  width: fit-content;
  div {
    padding: 0;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
  span {
    padding: 0;
  }
`;

const MultipleHighlightContainer = styled('div')`
  margin-top: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${colors.white};
  .header {
    font-size: 1.125rem;
    margin-bottom: 24px;
  }
`;

const ChoicesList = styled('ul')`
  margin-top: 8px;
  padding-left: 25px;
`;

const ChoiceListItem = styled('li', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['highlighted', 'isReviewing']),
})<IChoiceListProps>(
  ({ highlighted, isReviewing }) => `
  margin-bottom: 2px;
  width: fit-content;
  padding: 4px 2px;
  cursor: pointer;
  ${highlighted ? `background-color: ${colors.yellow};` : ''}
  ${isReviewing && highlighted ? `background-color: ${colors.highlightGreen};` : ''}
`,
);

const DropDownClozeContainer = styled('div')`
  margin-top: 24px;
`;

const DropDownQuestion = styled('div')`
  margin-bottom: 32px;
  line-height: 2.4;
  > div {
    > div {
      display: inline;
      margin-right: 16px;
    }
    > span {
      display: inline-flex;
      > div {
        min-width: 180px;
        width: fit-content;
      }
    }
  }
`;

const DragDropClozeContainer = styled('div')`
  margin-top: 24px;
  color: ${colors.black10};
`;

const DragDropQuestion = styled('div')`
  font-size: 1rem;
  margin-bottom: 16px;
`;

const ClozeContainer = styled('div')`
  display: flex;
  gap: 13px;
  align-items: center;
  margin-bottom: 30px;
  ${props => props.theme.breakpoints.down('$xs')} {
    flex-direction: column;
  }
`;

const ClozeItem = styled('div')`
  width: 50%;
  ${props => getItemHeight(props.theme)}
  background-color: ${colors.white};
  border: 1px dashed ${colors.purple30};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.breakpoints.down('$xs')} {
    width: 100%;
  }
`;

const ChoiceListContainer = styled('div')`
  width: 100%;
  padding: 16px;
  border: 1px solid ${colors.mediumLowGrey};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${colors.white};
`;

const ChoiceListTitle = styled('div')`
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

const DragDropChoiceList = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['count']),
})<IDragChoiceListProps>(
  ({ count, theme }) => `
    width: 45%;
    ${theme.breakpoints.down('$xs')} {
      width: 100%;
  
    }
    margin: auto;
    height: ${(48 + 8) * count}px;
`,
);

const DragDropChoiceItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isDragged']),
})<IDragItemProps>(
  ({ isDragged, theme }) => `
  ${getItemHeight(theme)}
  border: 1px solid ${colors.purple30};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: ${isDragged ? '0' : '8px'};
  background-color: ${colors.white};
  width: 100%;
  box-sizing: border-box;

`,
);

const BowTieClozeContainer = styled('div')`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 18px;

  align-items: center;

  ${props => props.theme.breakpoints.down('$xs')} {
    flex-direction: column;
    gap: 60px;
  }
`;

const BowTieColumnClozeContainer = styled('div')`
  width: 33%;
  gap: 25px;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.down('$xs')} {
    width: 100%;
    flex-direction: row;
  }
`;

const ActionCloze = styled('div')`
  width: 100%;
  ${props => getItemHeight(props.theme)}
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${colors.green20};
  background-color: ${colors.green5};
  border-radius: 4px;
  position: relative;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
`;

const ActionItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isDragged']),
})<IDragItemProps>(
  ({ isDragged, theme }) => `
  width: 100%;
  ${getItemHeight(theme, true)}
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.green20};
  background-color: ${colors.green5};
  padding: 0 8px;
  margin-bottom: ${isDragged ? '0' : '8px'};
  border-radius: 4px;
  box-sizing: border-box;
`,
);

const ConditionCloze = styled('div')`
  width: 33%;
  ${props => getItemHeight(props.theme, true)}
  font-size: 0.75rem;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${colors.highlightBlue2};
  background-color: ${colors.blue50};
  text-align: center;
  border-radius: 4px;
  span {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
`;

const ConditionItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isDragged']),
})<IDragItemProps>(
  ({ isDragged, theme }) => `
  width: 100%;
  ${getItemHeight(theme, true)}
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.highlightBlue2};
  background-color: ${colors.blue50};
  padding: 0 8px;
  margin-bottom: ${isDragged ? '0' : '8px'};
  border-radius: 4px;
  box-sizing: border-box;
`,
);

const ParameterCloze = styled('div')`
  width: 100%;
  ${props => getItemHeight(props.theme)}
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${colors.purple30};
  background-color: ${colors.white};
  border-radius: 4px;
  position: relative;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
`;

const ParameterItem = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isDragged']),
})<IDragItemProps>(
  ({ isDragged, theme }) => `
  width: 100%;
 ${getItemHeight(theme)}
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.green20};
  background-color: ${colors.green5};
  padding: 0 8px;
  margin-bottom: ${isDragged ? '0' : '8px'};
  border-radius: 4px;
  box-sizing: border-box;
 
`,
);

const BowTieListsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const BowTieListTitle = styled('div')`
  font-weight: 700;
  margin-bottom: 8px;
  width: 33%;
`;

const BowTieChoiceContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['count']),
})<IDragChoiceListProps>(
  ({ count }) => `
  width: 33%;
  height: ${(48 + 8) * count}px;
`,
);

const BowTieTitlesContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 40px;
`;

const Line = styled('div', {
  shouldForwardProp: prop =>
    shouldForwardProps(prop, [
      'angle',
      'top',
      'left',
      'right',
      'rightMobile',
      'leftMobile',
      'angleMobile',
      'topMobile',
    ]),
})<ILineProps>(
  ({ angle, top, left, right, topMobile, rightMobile, leftMobile, angleMobile, theme }) => `
  width: 37px;
  border: 1px solid ${colors.purple30};
  position: absolute;
  ${right ? `right: ${right}px;` : ''}
  ${top ? `top: ${top}px;` : ''}
  ${left ? `left: ${left}px;` : ''}
 transform: rotate(${angle}deg);
 ${theme.breakpoints.down('$xs')} {
  width:78px;
  ${rightMobile ? `right: ${rightMobile};` : ''}
  ${topMobile ? `top: ${topMobile};` : ''}
  ${leftMobile ? `left: ${leftMobile};` : ''}
  transform: rotate(${angleMobile}deg);
}
`,
);

const UnstartedCaseStudyWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 88px;
  padding-top: 30px;
  width: 100%;
  @media only screen and (min-width: 375px) and (max-width: 950px) and (orientation: landscape) {
    position: static;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    position: static;
  }
`;

const UnstartedCaseStudyContainer = styled('div')`
  width: 40%;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    padding: 20px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 20px;
  }
  > div:nth-child(2) {
    width: fit-content;
  }
`;

const UnstartedCaseStudyTitle = styled('div')`
  font-size: 1.75rem;
  font-weight: 700;
  color: ${colors.black10};
  margin-bottom: 18px;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1.5rem;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  img {
    width: 28px;
    height: 28px;
    border-radius: 4px;
  }
`;

const UnstartedCaseStudyText = styled('div')`
  font-size: 1.25rem;
  color: ${colors.black10};
  margin-top: 40px;

  ${props => props.theme.breakpoints.down('$xxl')} {
    //font-size: 1rem;
    margin-top: 20px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 1rem;
    margin-top: 20px;
  }
`;

const ReviewContainer = styled('div')`
  background-color: ${colors.white};
  width: 100%;
  padding: 64px 40px 64px 40px;
  box-sizing: border-box;
  // margin-bottom: 88px;
  display: flex;
  ${props => props.theme.breakpoints.down('xmd')} {
    flex-direction: column-reverse;
    padding: 40px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px;
  }
`;

const RationaleContainer = styled('div')`
  width: 50%;
  ${props => props.theme.breakpoints.down('xmd')} {
    width: 100%;
    margin-top: 40px;
  }
`;

const RationaleTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Rationale = styled('div')`
  background-color: ${colors.lightestGrey};
  padding: 16px;
  padding-right: 0;
  border-radius: 5px;
  overflow-x: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  > div {
    overflow-x: hidden;
    height: 420px;
    overflow-y: auto;
    margin-right: 8px;
    padding-right: 28px;
    ${scrollbar}
  }
  .title {
    margin-top: 24px;
    margin-bottom: 4px;
    font-weight: 700;
  }
  img {
    margin: 10px 0;
    width: 50%;
  }
`;

const CorrectAnswerContainer = styled('div')`
  width: 50%;
  box-sizing: border-box;
  padding-left: 70px;
  span.Mui-disabled {
    color: ${colors.purple30};
  }
  span.Mui-checked {
    color: ${colors.highlightGreen};
  }
  ${props => props.theme.breakpoints.down('xmd')} {
    width: 100%;
    padding-left: 0;
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CorrectAnswerTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 16px;
`;

const BowTieInfo = styled('span')`
  margin-top: 16px;
  display: block;
`;

export {
  CaseStudyContainer,
  InfoContainer,
  QuestionContainer,
  InfoTitle,
  InfoSubtitle,
  InfoTabsContainer,
  InfoTab,
  InfoTabContent,
  InfoTabContentWrapper,
  QuestionBody,
  QuestionNote,
  SingleChoiceContainer,
  Choice,
  MultipleHighlightContainer,
  ChoicesList,
  ChoiceListItem,
  DropDownClozeContainer,
  DropDownQuestion,
  DragDropClozeContainer,
  DragDropQuestion,
  ClozeContainer,
  ClozeItem,
  ChoiceListContainer,
  ChoiceListTitle,
  DragDropChoiceList,
  DragDropChoiceItem,
  BowTieClozeContainer,
  ActionCloze,
  ActionItem,
  ConditionCloze,
  ConditionItem,
  BowTieColumnClozeContainer,
  ParameterCloze,
  ParameterItem,
  BowTieListsContainer,
  BowTieListTitle,
  BowTieChoiceContainer,
  BowTieTitlesContainer,
  Line,
  UnstartedCaseStudyWrapper,
  UnstartedCaseStudyContainer,
  UnstartedCaseStudyTitle,
  UnstartedCaseStudyText,
  ReviewContainer,
  RationaleContainer,
  RationaleTitle,
  Rationale,
  CorrectAnswerContainer,
  CorrectAnswerTitle,
  BowTieInfo,
  MarksHeader,
};
