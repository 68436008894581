import { useHistory } from 'react-router';

const useURLParam = (param: string) => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const query = new URLSearchParams(search);
  const result = query.get(param);
  return result;
};

export default useURLParam;
