import { useMediaQuery, useTheme } from '@mui/material';
import Expandables from './Expandables';
import Table from './Table';
import { IMatrixComponent } from './types';

const Matrix = ({
  header,
  rows,
  response,
  onChange,
  rowUniquenessConstraint,
  horizontalOptionsMobile,
}: IMatrixComponent) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onChangeOption = (rowId: string | number, optionId: string | number) => {
    const nextResponse = response ? { ...response } : {};
    nextResponse[rowId] = optionId;
    if (optionId !== 0 && rowUniquenessConstraint) {
      Object.keys(nextResponse).forEach(key => {
        if (key === rowId) return;
        if (nextResponse[key] === optionId) {
          nextResponse[key] = null;
        }
      });
    }
    onChange?.(nextResponse);
  };

  if (isMobile)
    return (
      <Expandables
        horizontalOptionsMobile={horizontalOptionsMobile}
        header={header}
        rows={rows}
        onChange={onChangeOption}
        response={response}
      />
    );

  return <Table header={header} rows={rows} onChange={onChangeOption} response={response} />;
};

export default Matrix;
