import { styled } from '@mui/material/styles';
import { zoomInAnimation, disappearZoomAnimation } from '../../../../sharedStyled';
import { colors } from '../../../../../themes';
import { shouldForwardProps } from '../../../../utils';

interface IDatePickerPopup {
  isClickedBefore?: boolean;
  isVisible?: boolean;
}

interface IDayCell {
  disabled?: boolean;
  selected?: boolean;
}

const DatePickerContainer = styled('div')`
  width: 100%;
  position: relative;
  input {
    color: black;
    font-weight: 600;
  }
  .MuiFormControl-root{
    margin-top: ${props => props.theme.spacing(1)};
    width: 100%;
  }
}
`;

const DatePickerPopup = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isClickedBefore', 'isVisible']),
})<IDatePickerPopup>(
  ({ theme, isClickedBefore, isVisible }) => `
  ${!isClickedBefore ? 'visibility:hidden;' : ''}
  ${isVisible ? zoomInAnimation(0.6) : disappearZoomAnimation(0.6)}
  position:absolute;
  width: fit-content;
  margin-top: ${theme.spacing(1)};
  background: ${colors.white};
  border: 2px solid ${colors.purple30};
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));
  padding: ${theme.spacing(3)};
  z-index:5;
`,
);

const PopupHeader = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(1.5)};
  align-items: center;
  justify-content: center;
`;

const CurrentMonthHeader = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.spacing(2)};
  font-weight: 700;
  font-size: 1rem;
  gap: ${props => props.theme.spacing(3)};
  cursor: pointer;
`;

const ControlButtons = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing(1.375)};
`;

const DateBody = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: ${props => props.theme.spacing(1)};
  font-size: 0.875rem;
  margin: ${props => props.theme.spacing(3)} 0;
`;

const DayHeaders = styled('div')`
  display: flex;
  width: 100%;
  gap: ${props => props.theme.spacing(3.5)};
  justify-content: center;
  align-items: center;
  span {
    color: ${colors.darkGrey};
    font-weight: 700;
    width: 36px;
    text-align: center;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    gap: 1px;
  }
  ${props => props.theme.breakpoints.down('$xxs')} {
    span {
      width: 30px;
    }
  }
`;
const DateRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${props => props.theme.spacing(3.5)};
  align-items: center;
  ${props => props.theme.breakpoints.down('sm')} {
    gap: 1px;
  }
`;

const DayCell = styled('div', { shouldForwardProp: prop => shouldForwardProps(prop, ['disabled']) })<IDayCell>(
  ({ disabled, selected, theme }) => `
  color: ${disabled ? colors.mediumGray : selected ? colors.white : colors.black10};
  font-weight: 700;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  width: 36px;
  height: 36px;
  background-color: ${selected && !disabled ? colors.purple20 : ''};
  border-radius:${selected && !disabled ? '50%;' : ''};
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('$xxs')} {
    width: 30px;
    height: 30px;
  }
  &:hover {
    transition: background-color 0.3s ease-in;
    background-color: ${disabled ? '' : colors.purple10};
    border-radius: 50%;
  }
 
  
`,
);

const NumericDateContainer = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 32px;
  > div {
    max-width: 120px;
    input {
      padding: 13px 14px;
      text-align: center;
    }
  }
`;

export {
  ControlButtons,
  DayHeaders,
  DateBody,
  DatePickerContainer,
  DatePickerPopup,
  PopupHeader,
  CurrentMonthHeader,
  DateRow,
  DayCell,
  NumericDateContainer,
};
