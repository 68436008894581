import BaseContent from './BaseContent';
import { en } from '../../../../../i18n';
import parse from 'html-react-parser';
import { SmartCLEXInactiveBody, SmartCLEXInactiveBodyText, SmartCLEXInactiveBodyTitle } from '../../styled';
const { readinessAssesement } = en.dashboard;

const { inactive } = readinessAssesement;

class InActive extends BaseContent {
  public body(): React.ReactNode {
    return (
      <SmartCLEXInactiveBody>
        <SmartCLEXInactiveBodyTitle>{inactive.body}</SmartCLEXInactiveBodyTitle>
        <SmartCLEXInactiveBodyText>{inactive.subText}</SmartCLEXInactiveBodyText>
      </SmartCLEXInactiveBody>
    );
  }
  public buttonText(): string {
    return inactive.startButton;
  }
  public logicFunction() {
    return this.functions.navigateToMembership;
  }
}

export default InActive;
