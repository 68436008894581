import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

// TODO: Add size prop
interface ISpinnerContainer {
  color?: string;
  applyBackground?: boolean;
  size?: string;
}

const LoaderContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['color', 'applyBackground', 'size']),
})<ISpinnerContainer>(
  ({ color = colors.primary, applyBackground, size = '40px' }) => `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  transition: background 0.3s ease;
  ${
    applyBackground &&
    `background: rgba(0, 0, 0, 0.2);
     transition: background 0.3s ease;
  `
  }
  .MuiCircularProgress-root {
    color: ${color};
    width:${size} !important;
    height:${size} !important;
    }
  }

`,
);

const InlineLoaderContainer = styled('span', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['color', 'applyBackground', 'size']),
})<ISpinnerContainer>(
  ({ size = '20px', color }) => `

  .MuiCircularProgress-root {
    color: ${color};
    width:${size} !important;
    height:${size} !important;
    }
  }
  > span {
    display:flex;
  }
`,
);
export { LoaderContainer, InlineLoaderContainer };
