import { useState } from 'react';
import { ILooseObject } from '../../types';
import { TFieldExternal, IError, EFieldTypes, TValidationRules } from './Fields/types';
import { en } from '../../../i18n';
import Validator from './Validator';

interface IUseValidation {
  formState: ILooseObject;
  fields: {
    [key: string]: TFieldExternal;
  };
}

interface IErrors {
  [key: string]: IError[];
}

const useValidation = ({ formState, fields }: IUseValidation) => {
  const [errors, setErrors] = useState<IErrors>({});
  const isError = Object.keys(errors).length > 0;

  const getValidationRules = (field: TFieldExternal) => {
    const rules: TValidationRules[] = [];
    const { applyValidationRules, required, type, skipValidations } = field;
    if (required) rules.push('required');
    if (skipValidations) return rules;
    if (applyValidationRules) rules.push(...applyValidationRules);

    switch (type) {
      case EFieldTypes.EMAIL:
        rules.push('emailValidation');
        break;
      case EFieldTypes.PASSWORD:
        rules.push('minLengthValidation');
        rules.push('passwordValidation');
        break;
      case EFieldTypes.PASSWORD_CONFIRMATION:
        rules.push('passwordConfirmationValidation');
        break;
    }
    return [...new Set(rules)];
  };

  const applyValidationRules = (
    rules: TValidationRules[],
    fieldKey: string,
    fieldValue: string,
    dataSource: ILooseObject,
  ) => {
    const validator = new Validator(fieldKey, fieldValue, fields[fieldKey].label);
    const results = rules.reduce((accum: (IError | boolean)[], rule) => {
      const currentResults: (IError | boolean)[] = [];
      if (rule === 'required') currentResults.push(validator.validateRequired());
      if (!fieldValue) return [...accum, ...currentResults];
      switch (rule) {
        case 'required':
          break;
        case 'emailValidation':
          currentResults.push(validator.validateEmail());
          break;
        case 'userNameValidation':
          currentResults.push(validator.validateUserName());
          break;
        case 'minLengthValidation':
          currentResults.push(validator.validateLength());
          break;
        case 'passwordValidation':
          currentResults.push(validator.validatePassword());
          break;
        case 'passwordConfirmationValidation':
          currentResults.push(validator.validateConfirmPassword(dataSource.password));
          break;
        case 'emailOrUserNameValidation':
          currentResults.push(validator.validateEmailOrUserName());
      }
      return [...accum, ...currentResults];
    }, []);
    return results;
  };

  const validate = (inputState?: ILooseObject) => {
    const errors = Object.keys(fields).reduce((accum: IErrors, key) => {
      const dataSource = inputState || formState;
      const fieldValue = dataSource[key];
      const validationRules = getValidationRules(fields[key]);
      const appliedRules = applyValidationRules(validationRules, key, fieldValue, dataSource);
      const fieldErrors = appliedRules.filter(Boolean);
      if (fieldErrors.length > 0) {
        accum[key] = fieldErrors as IError[];
      }
      return accum;
    }, {});
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return { validate, errors, isError };
};

export default useValidation;
