import BaseTransformer from './BaseTransformer';
import { ILooseObject } from '../../types';

interface IQuestionResponse {
  questionId: string;
  response: string | string[] | ILooseObject;
}
class QuestionResponseTransformer extends BaseTransformer {
  public transform(response: string | string[]) {
    if (!response) return response;
    if (response.includes('[')) return this.safeJSONParse(response);
    return response;
  }

  public transformOut({ questionId, response }: IQuestionResponse, stringify = false) {
    return {
      questionId,
      response: stringify || Array.isArray(response) ? JSON.stringify(response) : response,
    };
  }

  public stringify({ questionId, response }: IQuestionResponse) {
    return {
      questionId,
      response: JSON.stringify(response),
    };
  }

  public transformParse(response: string | string[]) {
    if (!response) return response;
    return this.safeJSONParse(response);
  }

  public safeJSONParse(data: string | string[]) {
    try {
      if (Array.isArray(data)) return data;
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }
}

export default QuestionResponseTransformer;
