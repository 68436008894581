import { PersonalizedQuizContainer, SideBarCardTitle, SideBarCardBody } from '../styled';
import Button from '../../../components/Button';
import { useNavigations } from '../../../hooks';
import { ReactComponent as SmartCLEXIcon } from '../../../../images/smartclex.svg';
import Active from './Content/Active';
import InActive from './Content/InActive';
import { en } from '../../../../i18n';

const { title } = en.dashboard.readinessAssesement;

interface ISmartCLEX {
  isActive: boolean;
}

const SmartCLEX = ({ isActive }: ISmartCLEX) => {
  const { navigateToMembership, navigateToReadiness } = useNavigations();

  const getContentClass = () => {
    const initPayload = { functions: { navigateToMembership, navigateToReadiness } };
    return isActive ? new Active(initPayload) : new InActive(initPayload);
  };

  const contentClass = getContentClass();

  return (
    <PersonalizedQuizContainer smallMargin>
      <SmartCLEXIcon />
      <SideBarCardTitle>{title}</SideBarCardTitle>
      <SideBarCardBody>{contentClass.body()}</SideBarCardBody>
      <Button variant="secondary" onClick={contentClass.logicFunction()} fullWidth size="small">
        {contentClass.buttonText()}
      </Button>
    </PersonalizedQuizContainer>
  );
};

export default SmartCLEX;
