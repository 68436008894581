import { DisplayUpgradeContainer, DisplayUpgradeTitle, DisplayUpgradeSubTitle } from './styled';
import { ReactComponent as Rocket } from '../../../images/icons/membership/rocket.svg';
import { en } from '../../../i18n';
const { landingTitle, landingSubTitle } = en.billing;

const DisplayUpgrade = () => {
  return (
    <DisplayUpgradeContainer>
      {/* <Rocket /> */}
      <DisplayUpgradeTitle>{landingTitle}</DisplayUpgradeTitle>
      {/* <DisplayUpgradeSubTitle>{landingSubTitle}</DisplayUpgradeSubTitle> */}
    </DisplayUpgradeContainer>
  );
};

export default DisplayUpgrade;
