import { ContentCell, TitleCell, TopicsTableContentRow, TopicsTableRow } from '../../styled';
import { ITableRender, titles, rowKeys } from './data';

const DesktopTable = ({ data }: ITableRender) => {
  const getCellWidthFromIndex = (index: number) => {
    switch (index) {
      case 0:
        return 25;
      case 1:
        return 40;
      default:
        return 15;
    }
  };

  const renderTitles = () => {
    return titles.map((title, index) => (
      <TitleCell isCentered={index > 0} width={getCellWidthFromIndex(index)} key={title}>
        {title}
      </TitleCell>
    ));
  };

  const renderRows = () => {
    return data.map((row, index) => (
      <TopicsTableContentRow key={index}>
        {rowKeys.map((key, indexInner) => (
          <ContentCell
            isCentered={indexInner > 1}
            key={`${key}-${indexInner}`}
            width={getCellWidthFromIndex(indexInner)}
          >
            {row[key]}
          </ContentCell>
        ))}

        <ContentCell width={2} isCentered>
          {row.menuButton}
        </ContentCell>
      </TopicsTableContentRow>
    ));
  };

  return (
    <>
      <TopicsTableRow>
        {renderTitles()}
        <TitleCell width={2} isCentered key={'placeholder'} />
      </TopicsTableRow>
      {renderRows()}
    </>
  );
};

export default DesktopTable;
