import BaseTransformer from './BaseTransformer';
import { IAddon, addons } from '../../Membership/plans';
import { EAddons, TAddons } from '../../types';

class AddonsTransformer extends BaseTransformer {
  public transform(data: { priceID: string }[]) {
    const addonsResult: TAddons = {
      [EAddons.smartCLEX]: false,
    };
    data.forEach(item => {
      if (!item.priceID) return;
      //@ts-ignore
      Object.keys(addons).forEach((addonKey: EAddons) => {
        const addon = addons[addonKey];
        if (addon.priceId === item.priceID) {
          addonsResult[addonKey] = true;
        }
      });
    });
    return addonsResult;
  }
}

export default AddonsTransformer;
