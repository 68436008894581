import BaseTransformer from './BaseTransformer';
import { ILooseObject, IQuestion, IAttempt, ICaseStudy } from '../../types';

class QuestionTransformer extends BaseTransformer {
  public transform({
    questions,
    questionIds,
    attempt,
  }: {
    questions: (IQuestion | ICaseStudy)[];
    questionIds: string[];
    attempt: IAttempt;
  }): ILooseObject {
    return questionIds.reduce((acc: ILooseObject, questionId, index) => {
      acc[questionId] = {
        ...questions[index],
        id: questionId,
        topicsIds: attempt.questions.find(question => question.questionId === questionId)?.groupIds || [],
      };
      return acc;
    }, {});
  }
}

export default QuestionTransformer;
