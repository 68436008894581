import { EQuestionTypes, ILooseObject, IQuestion } from '../types';
import JSZip from 'jszip';
import ImageRegistry from './ImageRegistry';

class ZipQuestionReader {
  private file: ILooseObject = {};
  private zip: Blob | ArrayBuffer;
  private extractedZip: JSZip = new JSZip();
  private extractedJSON: ILooseObject = {};

  constructor(zip: Blob | ArrayBuffer) {
    this.zip = zip;
  }
  private async readFile() {
    const extractedZip = await JSZip.loadAsync(this.zip);
    this.extractedZip = extractedZip;
  }

  private async readJsonFile() {
    const data = await this.extractedZip.file('spec.json')?.async('string');
    this.extractedJSON = JSON.parse(data || '');
  }

  private async writeImages() {
    const imageRegistry = new ImageRegistry();
    const orderedKeys: string[] = [];
    const promises = Object.keys(this.extractedZip.files)
      .map(key => {
        if (key.includes('.') && key.includes('image') && this.extractedZip) {
          orderedKeys.push(key);
          return this.extractedZip.file(key)?.async('blob');
        }
        return null;
      })
      .filter(Boolean);

    const blobs = await Promise.all(promises);
    const base64Promises = blobs.map(blob => blob && this.blobToBase64(blob));
    const images = await Promise.all(base64Promises);
    images.forEach((image, index: number) => {
      imageRegistry.setImage(orderedKeys[index], image as string);
    });
  }
  private blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
  public async getQuestion(): Promise<IQuestion> {
    await this.readFile();
    await this.readJsonFile();
    await this.writeImages();
    if (this.extractedJSON.detail.detailType === EQuestionTypes.NGN_CASE_STUDY)
      return { ...this.extractedJSON.detail.detail, questionType: EQuestionTypes.NGN_CASE_STUDY };
    return this.extractedJSON.detail;
  }
}

export default ZipQuestionReader;
