import { MouseEvent } from 'react';
import { ModalOverlay, ModalContainer, ModalTitle, ModalContent, ModalButtonsContainer } from './styled';
import { ReactComponent as AccountUpgradedIcon } from '../../images/accountUpgraded.svg';
import { ILooseObject, SubscriptionModalVariantType } from '../types';
import { en } from '../../i18n';
import { format } from 'date-fns';
import Button from '../components/Button';
import Spinner from '../components/Spinner';
import { useDispatch, useNavigations } from '../hooks';
import { actions } from './redux/slice';
import ConfettiCelebration from '../components/ConfettiCelebration';
import parse from 'html-react-parser';
import SmartNText from '../components/SmartnText';
import { IAddon, IPlan } from './plans';

const {
  accountUpgraded,
  accountUpgradedTo,
  close,
  wantToCancel,
  nevermind,
  cancelled,
  subscriptionHasBeenCancelled,
  cancelModalButton,
  cancelModalTitle,
  addonPurchased,
  addonPurchasedContent,
  accountResubscribed,
  accountResubscribedContent,
  upgradedConfirmModal,
} = en.billing;

interface ISubscriptionModal {
  handleCancelSubscription: () => void;
  plan?: ILooseObject;
  loading: boolean;
  variant: SubscriptionModalVariantType;
  stripePlan?: IPlan | IAddon;
}

const SubscriptionModal = ({ handleCancelSubscription, plan, loading, variant, stripePlan }: ISubscriptionModal) => {
  const { dispatch } = useDispatch();
  const { navigateToDashboard } = useNavigations();
  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const closeModal = () => {
    dispatch(actions.setShowModal(false));
  };

  const formatSubscriptionEndDate = () => {
    if (!plan) return '';

    const { currentPeriodEnd = '' } = plan;
    const endDate = format(new Date(currentPeriodEnd), 'MMMM dd, yyyy');
    return endDate;
    // const { months, days } = intervalToDuration({
    //   start: new Date(),
    //   end: new Date(plan?.currentPeriodEnd || ''),
    // });
    // const monthsFormatted = months ? `${months} months ` : '';
    // const daysFormatted = days ? `${days} days` : '';
    // return monthsFormatted + daysFormatted;
  };

  const upgradedContent = () => {
    return accountUpgradedTo();
  };
  const modalStaticContent = {
    upgraded: {
      title: accountUpgraded,
      icon: <AccountUpgradedIcon />,
      content: upgradedContent(),
    },
    cancel: {
      title: cancelModalTitle,
      icon: null,
      content: plan?.id && wantToCancel(formatSubscriptionEndDate()),
    },
    cancelled: {
      title: (
        <>
          <SmartNText />
          &nbsp;{cancelled}
        </>
      ),
      icon: null,
      content: plan?.id && subscriptionHasBeenCancelled(formatSubscriptionEndDate()),
    },
    addonPurchased: {
      title: `${addonPurchased} ${stripePlan?.title}`,
      icon: <AccountUpgradedIcon />,
      content: addonPurchasedContent,
    },
    resubscribed: {
      title: accountResubscribed,
      icon: <AccountUpgradedIcon />,
      content: plan?.id && accountResubscribedContent(formatSubscriptionEndDate()),
    },
  };

  const confirmUpgradedAccount = () => {
    closeModal();
    navigateToDashboard();
  };

  const renderButtons = () => {
    if (variant === 'upgraded') {
      return (
        <Button variant="primary" onClick={confirmUpgradedAccount}>
          {upgradedConfirmModal}
        </Button>
      );
    }
    return ['cancelled', 'addonPurchased', 'resubscribed'].includes(variant) ? (
      <Button variant="primary" onClick={closeModal}>
        {close}
      </Button>
    ) : (
      <ModalButtonsContainer>
        <Button variant="secondary" onClick={closeModal}>
          {nevermind}
        </Button>
        <Button variant="errorFilled" onClick={handleCancelSubscription}>
          {cancelModalButton}
        </Button>
      </ModalButtonsContainer>
    );
  };

  return (
    <ModalOverlay onClick={closeModal}>
      <ConfettiCelebration isShown={['upgraded', 'addonPurchased', 'resubscribed'].includes(variant)} />
      <Spinner loading={loading} />
      {!loading && (
        <ModalContainer onClick={onModalClick}>
          <>
            <ModalTitle>{modalStaticContent[variant].title}</ModalTitle>
            {modalStaticContent[variant].icon}
            <ModalContent>
              {modalStaticContent[variant].content && parse(modalStaticContent[variant].content)}
            </ModalContent>
            {renderButtons()}
          </>
        </ModalContainer>
      )}
    </ModalOverlay>
  );
};

export default SubscriptionModal;
