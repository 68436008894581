import { Datum, PieConfig } from '@ant-design/charts';
import { ILooseObject, TPieChartData } from '../../types';
import BaseBuilder from './BaseBuilder';

class PieChart extends BaseBuilder<PieConfig> {
  public build(data?: TPieChartData): PieConfig {
    return {
      appendPadding: 10,
      angleField: 'value',
      colorField: 'type',
      radius: 0.9,
      data: data || [],
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      tooltip: {
        formatter: (datum: Datum) => {
          return { name: datum.type, value: `${datum.value}%` };
        },
        showTitle: true,
      },
      interactions: [
        {
          type: 'element-active',
        },
        {
          type: 'legend-filter',
          enable: false,
        },
      ],
    };
  }
}

export default PieChart;
