import { EQuizTypes } from '../types';
import useDispatch from './useDispatch';
import { actions, quizSelectors } from '../StudySpace/redux/slice';
import useNavigations from './useNavigations';
import { actions as globalActions } from '../redux/globalSlice';
import useMembershipProxy from './useMembershipProxy';
import { useSelector } from 'react-redux';

const useStartQuiz = () => {
  const { navigateToStudySpace } = useNavigations();
  const { loadingAttempts } = useSelector(quizSelectors.allState);
  const { dispatch } = useDispatch();
  const { quizYourself } = useMembershipProxy({ useStrict: true });
  const { buttonDisabled } = quizYourself.run();

  const startQuiz = (groupIds: string[], questionTypes: EQuizTypes[]) => {
    if (buttonDisabled || loadingAttempts) return;
    dispatch(actions.createQuiz({ groupIds, questionTypes }));
    dispatch(globalActions.quizSetGlobalState({ loading: true, errors: {} }));
    navigateToStudySpace(false, true);
  };

  return { startQuiz };
};

export default useStartQuiz;
