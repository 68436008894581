import { useHistory } from 'react-router';
import Auth from '../Auth';
import Form from '../../components/Form';
import { SubTitleFormWrapper } from '../styled';
import { fieldConfigs } from '../constants';
import { en } from '../../../i18n';
import { ILooseObject } from '../../types';
import { loginPath } from '../../Router/paths';
import useAuthForm from '../useAuthForm';
import { actions, authSelectors } from '../redux/slice';
import { useDispatch } from '../../hooks';
import { useSelector } from 'react-redux';

const { fullName, password, passwordConfirmation } = fieldConfigs;

const requiredFullName = { ...fullName, required: true };

const fields = { fullName: requiredFullName, password, passwordConfirmation };

const { setNewPassword } = en;

const { title, subTitle, submitText, successText } = setNewPassword;

const ForceChangePasword = () => {
  const history = useHistory();
  const { dispatch } = useDispatch();
  const { loading, errorFeedback, successFeedback } = useAuthForm({
    successFeedbackPosition: 'belowTitle',
    disableResetState: true,
  });
  const { user } = useSelector(authSelectors.allState);

  if (!user?.userClass) history.push(loginPath);

  const onSubmitSuccess = () => {
    history.push(loginPath);
  };

  const onSubmit = (formState: ILooseObject) => {
    const { fullName, password } = formState;
    if (user?.userClass)
      dispatch(actions.forceChangeNewPasswordRequest({ user: user.userClass, password, name: fullName }));
  };

  return (
    <Auth>
      <SubTitleFormWrapper>
        <Form
          fields={fields}
          formSuccess={successFeedback}
          formError={errorFeedback}
          loading={loading}
          subTitle={subTitle}
          title={title}
          submitText={submitText}
          onSubmit={onSubmit}
          successSubmitText={successText}
          sucessSubmitCallback={onSubmitSuccess}
          useGlobalError={true}
        ></Form>
      </SubTitleFormWrapper>
    </Auth>
  );
};

export default ForceChangePasword;
