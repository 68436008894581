import { useEffect } from 'react';
//TODO: Use Enum key codes
const useKeyDownPress = (keyCodes: string, handler: () => void) => {
  const handleButtonDown = (event: KeyboardEvent) => {
    if (keyCodes === event.code) handler();
  };
  useEffect(() => {
    window.addEventListener('keydown', handleButtonDown);
    return () => {
      window.removeEventListener('keydown', handleButtonDown);
    };
  }, []);
};

export default useKeyDownPress;
