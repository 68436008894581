import { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { DropDownContainer, dropDownStyles } from './styled';
import FormControl from '@mui/material/FormControl';
import { IDropDownField } from '../types';

const DropDown = ({
  fullWidth,
  label,
  required,
  options,
  autoWidth = false,
  value,
  onChange,
  size = 'medium',
  placeholder,
  disabled,
  isOpen,
  classesOverride,
  notched,
  renderOptionsOverride,
}: IDropDownField) => {
  const classes = dropDownStyles(classesOverride)();
  const renderOptions = () => {
    if (renderOptionsOverride) return renderOptionsOverride();
    return options.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <DropDownContainer>
      <FormControl required={required} size={size} fullWidth={fullWidth}>
        <InputLabel shrink={false} id="drop-down-label">
          {!value && placeholder ? placeholder : label}
        </InputLabel>
        <Select
          value={`${value}`}
          onChange={onSelectChange}
          MenuProps={{ classes: { paper: classes.dropDownMenu }, disableScrollLock: true }}
          label={!value && placeholder ? placeholder : label}
          autoWidth={autoWidth}
          id="drop-down"
          labelId="drop-down-label"
          placeholder={placeholder}
          disabled={disabled}
          defaultOpen={isOpen}
          notched={notched}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </DropDownContainer>
  );
};

export default DropDown;
