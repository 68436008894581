import { EPaymentSteps, ILooseObject, ISubscription } from '../../../types';
import { IAddon, IDeprecatedSubscription, IPlan, defaultPlan, defaultSponsoredPlan } from '../../plans';
import { en } from '../../../../i18n';
import React from 'react';
import { TVariant } from '../../../components/Button/types';

const {
  paymentForm: { cancel, close },
} = en.billing;

interface IConfirmButton {
  loading: boolean;
  form: string;
  text: string;
  variant: TVariant;
  onClick?: 'openCancelModal' | 'purchaseAddon';
}

interface ICancelButton {
  variant: TVariant;
  text: string;
}

interface IAdditionalInfo {
  loadingChangePaymentMethod: boolean;
  loading: boolean;
  currentSubscription: ISubscription;
  isTrialling: boolean;
  isActive: boolean;
  loadingPurchaseAddon: boolean;
  deprecatedSubscription?: IDeprecatedSubscription;
  isCancelled?: boolean;
  isPaymentIssue?: boolean;
  isSponsored: boolean;
}

abstract class BaseContent {
  private stripePlan: IPlan | IAddon;
  private currentState: EPaymentSteps;
  private additionalInfo: Partial<IAdditionalInfo>;

  public constructor(
    stripePlan: IPlan | IAddon,
    currentPaymentState: EPaymentSteps,
    additionalInfo: Partial<IAdditionalInfo> = {},
  ) {
    this.stripePlan = stripePlan;
    this.currentState = currentPaymentState;
    this.additionalInfo = additionalInfo;
  }

  public getStripePlan() {
    return this.stripePlan;
  }

  public getCurrentState() {
    return this.currentState;
  }

  public getAdditionalInfo() {
    return this.additionalInfo;
  }

  public membershipMeta(): React.ReactNode {
    return null;
  }

  public cancelButton(): ICancelButton {
    if (this.currentState === EPaymentSteps.VIEW_SUBSCRIPTION)
      return {
        variant: 'primary',
        text: close,
      };
    return {
      variant: 'secondary',
      text: cancel,
    };
  }

  public abstract confirmButton(): IConfirmButton;

  public abstract formTitle(): string;

  public abstract membershipInfoTitle(): React.ReactNode;

  public membershipInfoPrice() {
    return `$${this.stripePlan.price} ${this.stripePlan.priceDescription}`;
  }

  public abstract membershipInfoSubTitle(): string;

  public abstract membershipInfoTopTitle(): string;

  public abstract landingMembershipInfoTitle(): string;
}

export type { IConfirmButton, IAdditionalInfo };
export default BaseContent;
