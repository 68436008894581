import { IImageText } from '../../../types';
import { TextContainer, ImageContainer } from '../styled';
import { ImageRegistry } from '../../../classes';

interface IProps {
  content: IImageText;
  width?: string;
}

const ImageText = ({ content, width }: IProps) => {
  const { text, image } = content;
  const imageBase64 = new ImageRegistry().getImage(image);
  return (
    <>
      <TextContainer width={width}>
        <p>{text}</p>
      </TextContainer>

      {image && (
        <ImageContainer>
          <img src={imageBase64} />
        </ImageContainer>
      )}
    </>
  );
};

export default ImageText;
