import { styled } from '@mui/material/styles';
import { colors } from '../themes';

const shouldForwardProps = (prop: PropertyKey, allowedProps: string[]) => !allowedProps.includes(prop as string);

interface ITagProps {
  variant: 'filled' | 'outlined';
}

interface IQuizCard {
  hideCursor?: boolean;
  applyFlex?: boolean;
}

const OpacityHoverAnimation = (color: string = '#604bdf', duration: number = 0.3) => `
color:${color} ;
transition: opacity,background-color ${duration}s linear;
&:hover {
  opacity: 0.7;
}
`;

const OpacityBackroundHoverAnimation = (color: string = '#604bdf', duration: number = 0.3) => `

transition: opacity,background-color ${duration}s linear;
&:hover {
  background-color:${color};
}
`;

const TitleText = styled('h1')`
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0;
`;

const SecondaryTitleText = styled('h2')`
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;
`;

const LargeTitleText = styled('h1')`
  font-weight: 700;
  font-size: 1.75rem;
  margin: 0;
`;

const Text = styled('span')`
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.black10};
`;

const SmallText = styled('span')`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${colors.black10};
`;

const scrollbar = `

&::-webkit-scrollbar {
  @media only screen and (max-width: 600px){
    width: 6px;
  }
  width: 8px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 20px;
  @media only screen and (max-width: 600px){
    width: 10px;
  }
  background-color: ${colors.primary};
}
// fire-fox support
scrollbar-color:  ${colors.primary} ${colors.lightGrey};
scrollbar-width: thin;
`;

const thinScrollbar = `

&::-webkit-scrollbar {
  @media only screen and (max-width: 600px){
    width: 4px;
  }
  width: 6px;
  background-color: ${colors.lightGrey};
  border-radius: 8px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 8px;
  @media only screen and (max-width: 600px){
    width: 4px;
  }
  background-color: ${colors.primary};
}
// fire-fox support
scrollbar-color:  ${colors.primary} ${colors.lightGrey};
scrollbar-width: thin;
`;

const BoldText = styled('span')`
  font-weight: 800;
`;

// TODO: Refactor this to a proper interface
const opacityInAnimation = (time: number = 0.6, transitionType = 'ease-in-out') => `
animation: opacityIn ${time}s ${transitionType};
@keyframes opacityIn {
  0% {
    visibility:visible;
    opacity:0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity:1;
  }
}
`;

const widthInAnimation = (width: number, time: number = 0.6, transitionType = 'linear') => `
animation: widthIn ${time}s ${transitionType};
@keyframes widthIn {
  0% {
    width:0;
  }
  50% {
    width: ${width / 2}%;
  }
  100% {
    width: ${width ? `${width}%` : '0'};
  }
}
`;

const slideAnimation = (
  direction: 'right' | 'left' = 'right',
  transform: number = 200,
  time: number = 0.4,
  transitionType = 'linear',
) => `
animation: ${direction === 'right' ? 'slideRight' : 'slideLeft'} ${time}s ${transitionType};

@keyframes slideRight {
  0% {
    transform: translateX(-${transform}px);
    opacity: 0;
  
  }
  100% {
    transform: translateX(0px);
    opacity: 1 !important;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(${transform}px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }

  
}

`;

const zoomInAnimation = (time: number = 1.5, transitionType = 'ease-in-out') => `

animation: zoomIn ${time}s ${transitionType} forwards;

@keyframes zoomIn {
  0%{  transform: scale(0);opacity:0; visibility:visible; }
  100% { transform: scale(1);opacity:1;}
}
`;

const zoomOutAnimation = (time: number = 0.5, transitionType = 'ease-in-out') => `

animation: zoomOut ${time}s ${transitionType};

@keyframes zoomOut {
  0%{  transform: scale(1.1);opacity:0; }
  100% { transform: scale(1);opacity:1;}
}
`;

const disappearZoomAnimation = (time: number = 0.8, transitionType = 'ease-in-out') => `
animation: disappearZoomAnimation ${time}s ${transitionType} forwards;

@keyframes disappearZoomAnimation {
  0% { transform: scale(1);opacity:1;}
  100%{  transform: scale(0); opacity:0; display:none;}
  
}
`;
const Tag = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['variant', 'marginRight', 'applyWrap']),
})<ITagProps>(
  ({ variant, theme }) => `
  display: flex;
  align-items: center;
  background-color: ${variant === 'filled' ? colors.purple10 : 'transparent'};
  border-radius: 200px;
  color: ${colors.purple30};
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 4px 16px;
  margin-right: 8px;
  white-space: nowrap;
  ${variant === 'outlined' && `border: 1px solid ${colors.purple30};`}
  ${theme.breakpoints.down('$xxs')}{
  white-space: normal;
  overflow-wrap: anywhere;
}
`,
);

const QuizCard = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['hideCursor', 'applyFlex']),
})<IQuizCard>(
  ({ hideCursor, applyFlex }) => `
  background-color: ${colors.white};
  border: 1px solid rgba(226, 226, 231, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: ${hideCursor ? 'default' : 'pointer'};
  min-height: 130px;
  box-sizing: border-box;
  ${applyFlex ? 'display:flex;' : ''}
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    z-index:19;
  }

  padding: 24px;
  h3 {
    font-size: 0.875rem;
    color: ${colors.charcoal};
    margin-bottom: 4px;
    font-weight: 600;
    margin-top: 0;
  }

  h2 {
    font-size: 1rem;
    color: ${colors.black10};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 4px;
  }

  span {
    color: ${colors.grey500};
    font-size: 0.875rem;
    font-weight: 600;
  }


`,
);

const FreeQuizCard = styled(QuizCard)`
  &:hover {
    transform: none;
  }
  cursor: not-allowed;
  background-color: rgba(211, 211, 211, 0.1);
`;

export {
  OpacityHoverAnimation,
  SmallText,
  TitleText,
  SecondaryTitleText,
  LargeTitleText,
  scrollbar,
  Text,
  BoldText,
  Tag,
  opacityInAnimation,
  widthInAnimation,
  slideAnimation,
  zoomInAnimation,
  zoomOutAnimation,
  thinScrollbar,
  disappearZoomAnimation,
  OpacityBackroundHoverAnimation,
  QuizCard,
  FreeQuizCard,
};
