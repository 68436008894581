import { ValidateWithJsonSchema } from '@orbits/lib-commands';

import { OrbitsConfig, OrbitsConfigSchema } from './v2/types';

declare const OrbitsWindow: Window &
  typeof globalThis & {
    config: OrbitsConfig;
  };
export type OrbitsWindowType = typeof OrbitsWindow;
export { OrbitsWindow };

declare global {
  interface Window {
    config: OrbitsConfig;
  }
}

const OrbitsConfigValidator = (() => {
  try {
    console.warn = () => {};

    return new ValidateWithJsonSchema(OrbitsConfigSchema);
  } catch (e) {
    console.error(e, 'Schema Error');
    return undefined;
  }
})();

export function getOrbitsConfig(): OrbitsConfig {
  try {
    console.warn = () => {};
    OrbitsConfigValidator?.execute(window['config'] as unknown);
    return window['config'] as OrbitsConfig;
  } catch (e) {
    console.error(e, 'Schema error');
    return {} as OrbitsConfig;
  }
}
