class LocalStorageWithTTL {
  constructor(private readonly identifier: string, private readonly ttlMs: number = 300000) {
    if (!identifier) {
      throw new Error('identifier is required');
    }
  }
  set(value: any) {
    try {
      const data = {
        value: value,
        expires_at: new Date().getTime() + this.ttlMs / 1,
      };
      localStorage.setItem(this.identifier, JSON.stringify(data));
    } catch (e) {
      console.error(e);
    }
  }
  get(willRemove?: boolean, skipTTL?: boolean) {
    try {
      const data = JSON.parse(String(localStorage.getItem(this.identifier)));
      if (data) {
        if (data.expires_at && data.expires_at < new Date().getTime() && !skipTTL) {
          localStorage.removeItem(this.identifier);
          return undefined;
        } else {
          if (willRemove) localStorage.removeItem(this.identifier);
          return data.value;
        }
      }
      return undefined;
    } catch (e) {
      // console.error(e);
      return undefined;
    }
  }
  clear() {
    localStorage.removeItem(this.identifier);
  }
}

export default LocalStorageWithTTL;
