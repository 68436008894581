import { useSelector } from 'react-redux';
import { useDispatch } from '../hooks';
import { actions, applySelector } from './redux/slice';
import { HARD_CODED_QUIZ_ID } from './constants';
import useAttempts from '../hooks/useAttempts';
import { useEffect, useRef } from 'react';
import useSteps, { allRequiredSteps, IStep, questionData } from './useSteps';

interface IUseApply {
  fetchAttempts?: boolean;
}

const useApply = ({ fetchAttempts = false }: IUseApply) => {
  const { dispatch } = useDispatch();
  const { loading, loadingStartQuiz, questionAnswers, loadingAnswer, currentQuestionIndex: stepIndex } = useSelector(
    applySelector.allState,
  );

  const { currentApplyAttempt } = useAttempts();
  const { renderStep, currentStep, getCurrentQuestion, getSteps } = useSteps({
    index: stepIndex,
    attempt: currentApplyAttempt,
  });

  const isProgressChecked = useRef(false);

  useEffect(() => {
    if (!fetchAttempts) return;
    if (isProgressChecked.current) return;
    if (!currentApplyAttempt) return;
    isProgressChecked.current = true;
    let lastDoneQuestionId = '';
    let isDone = true;

    allRequiredSteps.forEach((step, index) => {
      const isCurrentQuestionDisabled = isQuestionDisabled(step);
      isDone = isDone && !isCurrentQuestionDisabled;
      if (
        !isCurrentQuestionDisabled &&
        ![questionData.certification.id, questionData.uploadResume.id].includes(step.id)
      ) {
        lastDoneQuestionId = step.id;
      }
    });

    if (isDone) {
      dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
      return;
    }

    const findAllIndicesById = (arr: IStep[], id: string): number[] =>
      arr.reduce((indices: number[], item: IStep, index: number) => {
        if (item.id === id) indices.push(index);

        return indices;
      }, []);

    if (lastDoneQuestionId === questionData.license.id) lastDoneQuestionId = questionData.uploadResume.id;

    if (lastDoneQuestionId) {
      const steps = getSteps();
      const indices = findAllIndicesById(steps, lastDoneQuestionId).sort((a, b) => b - a);
      if (indices.length > 0) {
        const targetIndex = indices[0] + 1;

        if (targetIndex < steps.length) {
          dispatch(actions.setCurrentQuestionIndex({ index: targetIndex }));
        }
      }
    }
  }, [currentApplyAttempt]);

  const startQuiz = () => {
    dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID }));
  };

  const isQuestionDisabled = (step: IStep) => {
    const { id } = step;
    const currentResponse = questionAnswers?.[id]?.response;
    if (step.checkDisabled) return step.checkDisabled(currentResponse, getCurrentQuestion(id));
    if (Array.isArray(currentResponse)) return currentResponse.length === 0;
    return currentResponse === undefined || currentResponse === '';
  };

  const isNextDisabled = () => isQuestionDisabled(currentStep);

  const goNext = () => {
    if (isNextDisabled()) return;
    const currentResponse = questionAnswers?.[currentStep?.id]?.response;
    const handleFileUploads = () => {
      const isFile = currentResponse instanceof File;
      if (isFile) {
        dispatch(
          actions.uploadRequest({
            questionId: questionData.uploadResume.id,
            file: currentResponse,
            attemptId: currentApplyAttempt?.attemptId || '',
          }),
        );
        return;
      }

      if (currentResponse === null) {
        dispatch(
          actions.submitAnswer({
            attemptId: currentApplyAttempt?.attemptId || '',
            questionId: currentStep?.id || '',
            response: currentResponse,
          }),
        );
        return;
      }
      dispatch(actions.incrementQuestionIndex());
    };

    if (currentStep?.id === questionData.uploadResume.id) {
      handleFileUploads();
      return;
    }

    if (!currentResponse) {
      dispatch(actions.incrementQuestionIndex());
      return;
    }
    dispatch(
      actions.submitAnswer({
        attemptId: currentApplyAttempt?.attemptId || '',
        questionId: currentStep?.id || '',
        response: currentResponse,
      }),
    );
  };

  const goPrevious = () => {
    dispatch(actions.decrementQuestionIndex());
  };

  const totalQuestions = currentApplyAttempt?.progress?.totalQuestions || 1;

  const quizProgress = Math.round((stepIndex / totalQuestions) * 100);
  useEffect(() => {
    if (!currentApplyAttempt && fetchAttempts) dispatch(actions.fetchApplyAttempts());
    // if (currentApplyAttempt && fetchAttempts && !currentApplyAttempt.questionsTransformed)
    //   dispatch(actions.transformAttemptRequest({ attempt: currentApplyAttempt }));
  }, [currentApplyAttempt]);

  return {
    control: {
      startQuiz,
      goNext,
      goPrevious,
      renderStep,
    },
    state: {
      loading,
      loadingStartQuiz,
      currentApplyAttempt,
      stepIndex,
      quizProgress,
      totalQuestions,
      currentStep,
      isNextDisabled: isNextDisabled(),
      loadingAnswer,
    },
  };
};

export default useApply;
