import BaseTransformer from './BaseTransformer';
import { IQuestion, IAttempt, ICaseStudy, ILooseObject, EQuestionTypes } from '../../types';
import FetchQuizQuestions from '../FetchQuizQuestions';
import PopulateAnswers from '../PopulateAnswers';
import QuizTopicsTransformer from './QuizTopicsTransformer';
import QuestionTransformer from './QuestionsTransformer';

class FullAttemptsTransformer extends BaseTransformer {
  public async transform(attempt: IAttempt) {
    const fetchQuizQuestions = new FetchQuizQuestions(attempt);
    const questionTransformer = new QuestionTransformer();
    const questions: (IQuestion | ICaseStudy)[] = await fetchQuizQuestions.fetchQuestions();

    const questionsTransformed = questionTransformer.transform({
      questions,
      questionIds: fetchQuizQuestions.getQuestionIds(),
      attempt,
    });

    const populateAnswers = new PopulateAnswers(questionsTransformed, attempt);
    const { questionAnswers, currentQuestionInfo } = populateAnswers.populate();
    const { isDone, questionId, questionIndex } = currentQuestionInfo;
    const { total, currentDisplayedIndex } = this.getTotalDisplayQuestions(questionsTransformed, attempt, questionId);
    const selectedTopicIds = new QuizTopicsTransformer().transform(attempt);

    return {
      ...attempt,
      questionsTransformed,
      questionAnswers,
      selectedTopicIds,
      totalDisplayQuestions: total,
      //isDone,
      questionId,
      questionIndex,
      currentQuestionInfo,
      displayedQuestionIndex: currentDisplayedIndex == -1 ? questionIndex : currentDisplayedIndex,
    };
  }

  getTotalDisplayQuestions(questions: ILooseObject, attempt: IAttempt, currentquestionId: string) {
    const flatQuestions = attempt.questions.flatMap((attemptQuestion: ILooseObject) => {
      const question = questions[attemptQuestion.questionId];
      if (question.questionType === EQuestionTypes.NGN_CASE_STUDY) {
        question.questions.forEach((subQuestion: ILooseObject) => {
          subQuestion.displayId = `${subQuestion.id}-${question.id}`;
        });
        return question.questions;
      }
      return question;
    });

    const currentDisplayedIndex = flatQuestions.findIndex(
      flatQuestion => currentquestionId === flatQuestion.id || flatQuestion.displayId?.includes(currentquestionId),
    );

    return { total: flatQuestions.length, currentDisplayedIndex };
  }
}

export default FullAttemptsTransformer;
