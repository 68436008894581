import DeprecatedSubscription from './DeprecatedSubscription';
import PaymentIssue from './PaymentIssue';
import TrialEnded from './TrialEnded';
import NurseGPT from './NurseGPT';

interface IModalsInterface {
  isNurseGPTModalOpen: boolean;
  toggleNurseGPTModal: () => void;
}

const Modals = ({ isNurseGPTModalOpen, toggleNurseGPTModal }: IModalsInterface) => {
  return (
    <>
      <TrialEnded />
      <PaymentIssue />
      <NurseGPT isNurseGPTModalOpen={isNurseGPTModalOpen} toggleNurseGPTModal={toggleNurseGPTModal} />
      {/* <DeprecatedSubscription /> */}
    </>
  );
};

export default Modals;
