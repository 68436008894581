import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  HeaderFreeBannerContainer,
  BannerWrapper,
  HeaderBannerContainer,
  SvgContainer,
  HeaderBannerText,
} from './styled';
import Button from '../components/Button';
import { en } from '../../i18n';
import { membershipPath, nurseGPTPath } from '../Router/paths';
import { ReactComponent as StarIcon } from '../../images/icons/header/goldenStar.svg';
import SmartNText from '../components/SmartnText';

const {
  freeBannerAfterButtonText,
  freeBannerButtonText,
  freeBannerBeforeButtonText,
  freeBannerAfterLogoText,
} = en.header;

interface IHeaderFreeBanner {
  children?: React.ReactNode;
}

const HeaderFreeBanner = ({ children }: IHeaderFreeBanner) => {
  const history = useHistory();
  if (history.location.pathname === nurseGPTPath || history.location.pathname === '/') return null;

  const renderContent = () => {
    if (children) return children;
    return (
      <HeaderBannerContainer>
        <SvgContainer>
          <StarIcon />
        </SvgContainer>
        <HeaderBannerText>
          {freeBannerBeforeButtonText}&nbsp;
          <SmartNText>Aspire</SmartNText>&nbsp;
          {freeBannerAfterLogoText}&nbsp;
          <Button link={membershipPath} variant="underlined">
            {freeBannerButtonText}
          </Button>
          &nbsp;
          {freeBannerAfterButtonText}
        </HeaderBannerText>
      </HeaderBannerContainer>
    );
  };

  return (
    <BannerWrapper>
      <HeaderFreeBannerContainer>{renderContent()}</HeaderFreeBannerContainer>
    </BannerWrapper>
  );
};

export default HeaderFreeBanner;
