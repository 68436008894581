import { useState } from 'react';
import ContentRenderer from '../ContentRenderer';
import { ExpandableTag, Expandable, ExpandableQuestion, ExpandablesContainer } from './styled';
import { IMatrixComponent } from './types';

import { ICaseStudySubquestion } from '../../../../types';
import ExpandableOption from './ExpandableOption';

const Expandables = ({ header, rows, renderRowAnswer, isDisabled }: IMatrixComponent) => {
  const renderHeader = () => {
    if (!header) return null;
    return (
      <ExpandableTag>
        <ContentRenderer content={header} />
      </ExpandableTag>
    );
  };

  const renderExpendables = () => {
    return rows.map((row, index) => {
      return (
        <Expandable key={index}>
          {renderHeader()}
          <ExpandableQuestion>
            <ContentRenderer content={row.detail.question} />
          </ExpandableQuestion>
          <ExpandableOption isDisabled={isDisabled} index={index} renderRowAnswer={renderRowAnswer} row={row} />
        </Expandable>
      );
    });
  };

  return <ExpandablesContainer>{renderExpendables()}</ExpandablesContainer>;
};

export default Expandables;
