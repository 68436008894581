import parse from 'html-react-parser';
import { ICaseStudyContent, EContentTypes, IImageText } from '../../../types';

const ContentRenderer = ({ content }: { content?: ICaseStudyContent }) => {
  if (!content) return null;
  const { detailType, detail } = content;

  switch (detailType) {
    case EContentTypes.PLAIN_TEXT:
      return <>{detail.copy}</>;
    case EContentTypes.HTML:
      return <>{parse(detail.copy)}</>;
    case EContentTypes.IMAGE_TEXT:
      return (
        <>
          <div>{detail.copy}</div>
          <img src={(detail as IImageText).image} />
        </>
      );
    default:
      return <>{detail.copy}</>;
  }
};

export default ContentRenderer;
