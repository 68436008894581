import { useHistory } from 'react-router-dom';

import { ReactComponent as BackArrowIcon } from '../../../images/icons/backarrow.svg';
import { BackToSubjectView } from './styled';

interface IProps {
  text: string;
  onClick?: () => void;
}
const BackButton = ({ text, onClick }: IProps) => {
  const history = useHistory();

  const defaultOnClick = () => {
    history.goBack();
  };
  return (
    <BackToSubjectView onClick={onClick || defaultOnClick}>
      <BackArrowIcon />
      <span>{text}</span>
    </BackToSubjectView>
  );
};

export default BackButton;
