import { styled } from '@mui/material/styles';
import { colors } from '../../themes';

const Boundary = styled('div')`
  width: 100vw;
  min-height: calc(100vh - 64px);
  box-sizing: border-box;
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 7rem;
    height: 7rem;
  }

  button {
    margin-top: ${props => props.theme.spacing(4)};
  }
`;

const Title = styled('h1')`
  font-weight: 700;
  margin: 0;
  margin-bottom: ${props => props.theme.spacing(4)};
  font-size: 3rem;
  text-align: center;
`;

const SubTitleA = styled('h2')`
  font-weight: 400;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
`;

const SubTitleB = styled('h2')`
  font-weight: 400;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
`;

export { Boundary, SubTitleA, SubTitleB, Title };
