import { styled } from '@mui/material/styles';
import { Banner } from '../SmartQuiz/styled';
import { colors } from '../../themes';

const BottomBannerContainer = styled('div')`
  margin-top: auto;
`;

const BannerOverride = styled(Banner)`
  border: 1px solid ${colors.lightGrey};
  ${props => props.theme.breakpoints.down('sm')} {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const ButtonsContainer = styled('div')`
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 12px;
  }
`;

const FooterNote = styled('div')`
  line-height: 24px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: ${props => props.theme.spacing(3)};
  ${props => props.theme.breakpoints.down('md')} {
    position: static;
  }
`;

const FeaturesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
  margin-top: ${props => props.theme.spacing(4)};
`;

const Feature = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
  font-size: 1rem;
  font-weight: 500;
  align-items: center;
`;

const IconContainer = styled('div')`
  flex-shrink: 0;
`;

const UnstartedQuizContainer = styled('div')`
  margin-top: calc(22% - 16px);
  margin-bottom: calc(22% - 16px);
`;

export {
  BannerOverride,
  ButtonsContainer,
  FooterNote,
  BottomBannerContainer,
  UnstartedQuizContainer,
  FeaturesContainer,
  Feature,
  IconContainer,
};
