import { LeftContentContainer, RightContentContainer, SignupContainer, LandingText, ContentContainer } from './styled';
import { RadialBackground } from '../styled';
import SignUpInfo from './SignUpInfo';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import parse from 'html-react-parser';
import { en } from '../../../i18n';
import SignUpForm from './SignUpForm';
import { useSeoTags, useRemoveChatWidget } from '../../hooks';

const {
  signUp: { banner },
} = en;

const Signup = () => {
  useRemoveChatWidget();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const seo = useSeoTags({ pageName: 'signup' });

  const renderRightContent = () => {
    if (isMedium) return null;
    return (
      <RightContentContainer>
        <SignUpForm />
      </RightContentContainer>
    );
  };

  const renderLeftContent = () => {
    return (
      <LeftContentContainer>
        <RadialBackground>
          <ContentContainer>
            <LandingText>{parse(banner)}</LandingText>
            {isMedium && <SignUpForm />}
            {/* <SignUpInfo /> */}
          </ContentContainer>
        </RadialBackground>
      </LeftContentContainer>
    );
  };

  return (
    <SignupContainer>
      {seo}
      {renderLeftContent()}
      {renderRightContent()}
    </SignupContainer>
  );
};

export default Signup;
