import { useRef, MutableRefObject, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SmartQuizContainer, Banner, ExitButtonContainer } from './styled';
import ProgressBar from '../components/ProgressBar';
import Question from './question';
import useSmartQuiz from './useSmartQuiz';
import { colors } from '../../themes';
import UnstartedQuiz from './UnstartedQuiz';
import Results from './results';
import { ISmartQuizOverrides } from './types';
import PastQuizzes from './PastQuizzes';
import Spinner from '../components/Spinner';
import { ICaseStudy, ILooseObject } from '../types';
import SmartQuizModal from './SmartQuizModal';
import { actions as topicsActions, SLICE_NAME as topicsSlice } from '../StudySpace/redux/topics/slice';
import { useDispatch, useNavigations } from '../hooks';

import CaseStudy from './question/CaseStudy';
import UnstartedCaseStudy from './question/CaseStudy/UnstartedCaseStudy';
import EndedCaseStudy from './question/CaseStudy/EndedCaseStudy';
import ControlButtons from './ControlButtons';
import QuizBanner from './QuizBanner';
import Button from '../components/Button';
import { en } from '../../i18n';

const {
  filtersModalTitle,
  filtersModalBody,
  changeMySelections,
  exitModalTitle,
  exitModalBody,
  exitModalConfirm,
} = en.smartQuiz;

//import { FiltersModalTitle,fil } from './styled';

const SmartQuiz = ({
  unstartedQuizOverrides,
  controlOverrides,
  stateOverrides,
  resultsOverrides,
  bannerOverrides,
  textOverrides,
  flowOverrides,
}: ISmartQuizOverrides) => {
  const bannerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const {
    location: { state: locationState },
  } = useHistory();
  const { isViewingPastQuizzes, isManualCreate } = (locationState as ILooseObject) || {};
  const { state, control } = useSmartQuiz({ ref: bannerRef, flowOverrides, stateOverrides, controlOverrides });
  const { navigateToDashboard } = useNavigations();

  const { dispatch: topicsDispatch } = useDispatch(topicsSlice);

  const finalState = { ...state, ...stateOverrides };
  const {
    isStarted,
    currentQuestion,
    quizProgress,
    isCheckingAnswer,
    questionAnswers,
    isViewingResults,
    isGoingBack,
    loading,
    loadingAttempts,
    loadingTopics,
    allTopics,
    isFiltersModalOpen,
    isPlacement,
    isCaseStudy,
    isCaseStudyStarted,
    caseStudyIndex,
    isReviewingCaseStudy,
    isCaseStudyOutroShown,
    caseStudyTotalQuestions,
    loadingStartQuiz,
    isExitModalOpen,
    ctime,
    currentAttempt,
    currentDisplayedQuestionIndex,
  } = finalState;

  const finalControl = { ...control, ...controlOverrides };

  const {
    startQuiz,
    answerQuestion,
    resetState,
    redirectToViewResults,
    toggleFiltersModal,
    answerSubquestion,
    skipPlacement,
    toggleExitModal,
  } = finalControl;

  useEffect(() => {
    if (isReviewingCaseStudy) bannerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [caseStudyIndex]);

  const onChangeFiltersApply = () => {
    topicsDispatch(topicsActions.setSelectedTopics({ selectedTopics: {} }));
    resetState();
  };

  const handleExitModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    toggleExitModal();
  };

  const renderQuestion = () => {
    return isCaseStudy ? (
      <CaseStudy
        caseStudy={(currentQuestion as unknown) as ICaseStudy}
        currentQuestionIndex={caseStudyIndex}
        isStarted={isCaseStudyStarted}
        answerSubquestion={answerSubquestion}
        answers={questionAnswers[currentQuestion.id]}
        isReviewingCaseStudy={isReviewingCaseStudy}
        isPlacement={isPlacement}
      />
    ) : (
      <Question
        answer={questionAnswers[currentQuestion.id]}
        onAnswer={answerQuestion}
        showExplanations={isCheckingAnswer}
        question={currentQuestion}
        isGoingBack={isGoingBack}
        useOpacityAnimation
      />
    );
  };
  const isInitialLoading = () => {
    if (loadingAttempts) return true;
    if (isManualCreate) {
      if (loadingTopics || loadingStartQuiz) return true;
    }

    return false;
  };

  const renderCaseStudy = () => {
    if (!isCaseStudyStarted)
      return (
        <SmartQuizContainer ref={bannerRef}>
          {currentQuestion?.topicsIds && (
            <UnstartedCaseStudy
              isPlacement={isPlacement}
              topic={allTopics[currentQuestion.topicsIds[0]]?.name}
              totalQuestions={caseStudyTotalQuestions}
            />
          )}
          <Banner bottom centerButtons>
            <ControlButtons data={{ state: finalState, control: finalControl }} flowOverrides={flowOverrides} />
          </Banner>
        </SmartQuizContainer>
      );

    if (isCaseStudyOutroShown && caseStudyIndex === caseStudyTotalQuestions - 1) {
      return (
        <SmartQuizContainer progress={quizProgress} ref={bannerRef}>
          {currentQuestion?.topicsIds && (
            <EndedCaseStudy isPlacement={isPlacement} topic={allTopics[currentQuestion.topicsIds[0]]?.name} />
          )}
          <Banner bottom centerButtons>
            <ControlButtons data={{ state: finalState, control: finalControl }} />
          </Banner>
        </SmartQuizContainer>
      );
    }
    return null;
  };

  if (isInitialLoading())
    return (
      <SmartQuizContainer>
        <Spinner loading />
      </SmartQuizContainer>
    );

  if (isViewingPastQuizzes) return <PastQuizzes />;

  if (!isStarted)
    return (
      <UnstartedQuiz
        loadingContainer={loadingAttempts || loadingTopics}
        loading={loading}
        startQuiz={startQuiz}
        overrides={unstartedQuizOverrides}
        skipPlacement={skipPlacement}
      />
    );

  if (isViewingResults)
    return (
      <Results overrides={resultsOverrides} redirectToViewResults={redirectToViewResults} resetState={resetState} />
    );

  if (isCaseStudy) {
    const caseStudy = renderCaseStudy();
    if (caseStudy) return caseStudy;
  }

  return (
    <>
      <SmartQuizContainer progress={quizProgress} ref={bannerRef} isCaseStudy={isCaseStudy}>
        <ProgressBar
          backgroundColor={colors.mediumGray}
          barHeight="12px"
          color={colors.purple20}
          useBarOnly
          useBarStripes={false}
          percentage={quizProgress}
        />
        <QuizBanner
          bannerRef={bannerRef}
          currentQuestionIndex={currentDisplayedQuestionIndex}
          currentQuestion={currentQuestion}
          totalNumberOfQuestions={currentAttempt?.totalDisplayQuestions || -1}
          isCaseStudy={isCaseStudy}
          caseStudyIndex={caseStudyIndex}
          caseStudyTotalQuestions={caseStudyTotalQuestions}
          isPlacement={isPlacement}
          skipPlacement={skipPlacement}
          toggleFiltersModal={toggleFiltersModal}
          allTopics={allTopics}
          ctime={ctime}
          currentAttemptTitle={currentAttempt?.title || ''}
          overrides={bannerOverrides}
        />
        {renderQuestion()}
        <Banner leftPadding="23.5%" bottom centerButtons={isCaseStudy}>
          <ControlButtons flowOverrides={flowOverrides} data={{ state: finalState, control: finalControl }} />
          <ExitButtonContainer>
            <Button onClick={handleExitModal} variant="underlined">
              {textOverrides?.exitButtonText || 'Exit'}
            </Button>
          </ExitButtonContainer>
        </Banner>
      </SmartQuizContainer>
      {isFiltersModalOpen && (
        <SmartQuizModal
          confirmButtonText={changeMySelections}
          title={filtersModalTitle}
          body={filtersModalBody}
          toggleModal={toggleFiltersModal}
          onApply={onChangeFiltersApply}
        />
      )}
      {isExitModalOpen && (
        <SmartQuizModal
          confirmButtonText={textOverrides?.exitModalConfirmButtonText || exitModalConfirm}
          title={textOverrides?.exitModalTitle || exitModalTitle}
          body={exitModalBody}
          toggleModal={toggleExitModal}
          onApply={navigateToDashboard}
          buttonError
        />
      )}
    </>
  );
};

export default SmartQuiz;
