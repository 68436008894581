import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Auth from '../Auth';
import Form from '../../components/Form';
import { SubTitleFormWrapper } from '../styled';
import { fieldConfigs } from '../constants';
import { en } from '../../../i18n';
import { ILooseObject } from '../../types';

import { forgetPasswordPath, loginPath } from '../../Router/paths';
import useAuthForm from '../useAuthForm';
import { actions } from '../redux/slice';
import { useDispatch, useSeoTags } from '../../hooks';

const { password, passwordConfirmation } = fieldConfigs;

const fields = { password, passwordConfirmation };

const { setNewPassword } = en;

const { title, subTitle, submitText, successText } = setNewPassword;

const ForgetPassword = () => {
  const history = useHistory();
  const { dispatch } = useDispatch();
  const { state } = history.location;
  const { email, code } = (state as ILooseObject) || {};
  const { loading, errorFeedback, successFeedback } = useAuthForm({ successFeedbackPosition: 'belowTitle' });
  const seo = useSeoTags({ pageName: 'changePassword' });
  useEffect(() => {
    if (!email || !code) history.push(forgetPasswordPath);
  }, []);

  const onSubmitSuccess = () => {
    history.push(loginPath);
  };

  const onSubmit = (formState: ILooseObject) => {
    dispatch(actions.changePasswordRequest({ email, code, password: formState.password, history }));
  };

  return (
    <Auth>
      {seo}
      <SubTitleFormWrapper>
        <Form
          fields={fields}
          formSuccess={successFeedback}
          formError={errorFeedback}
          loading={loading}
          subTitle={subTitle}
          title={title}
          submitText={submitText}
          onSubmit={onSubmit}
          successSubmitText={successText}
          sucessSubmitCallback={onSubmitSuccess}
          useGlobalError={true}
        ></Form>
      </SubTitleFormWrapper>
    </Auth>
  );
};

export default ForgetPassword;
