import { IAttempt } from '../../types';
import { en } from '../../../i18n';
import TabContent from '../Components/TabContent';
import {
  CurrentReadinessAttemptContainer,
  ExamContainer,
  ExamHistoryContainer,
  ExamIconContainer,
  ExamInfoContainer,
  ExamSubTitle,
  ExamTitle,
  InfoHeader,
  InfoSpan,
  InfoTagsContainer,
  InsightsSection,
  NewReadinessAttemptContainer,
  PassingChanceContainer,
  StartAnotherTitle,
} from './styled';
import { ReactComponent as ReadinessIcon } from '../../../images/icons/dashboard/readiness.svg';
import Button from '../../components/Button';
import PassingChance from './PassingChance';
import { humanReadableDuration } from '../../utils';
import ReadinessQuizCard from './ReadinessQuizCard';
import { useNavigations } from '../../hooks';

const { title, examTitle, examSubTitle, examInsightsButton, marks, time, innerTitle } = en.dashboard.practiceQuiz.nclex;

interface IReadiness {
  currentReadinessAttempt: IAttempt;
  readinessAttempts: IAttempt[];
}

const Readiness = ({ currentReadinessAttempt, readinessAttempts }: IReadiness) => {
  const { navigateToResults } = useNavigations();
  const renderMarks = () => {
    const { progress } = currentReadinessAttempt;
    if (!progress || !progress.totalMark || !progress.totalMaximumMark) return null;
    return (
      <PassingChanceContainer>
        <InfoHeader>
          {progress.totalMark} / {progress.totalMaximumMark}
        </InfoHeader>
        <InfoSpan>{marks}</InfoSpan>
      </PassingChanceContainer>
    );
  };

  const renderTime = () => {
    const { totalTime } = currentReadinessAttempt;
    if (!totalTime) return null;
    return (
      <PassingChanceContainer>
        <InfoHeader>{humanReadableDuration(totalTime, { useTextFormat: true })}</InfoHeader>
        <InfoSpan>{time}</InfoSpan>
      </PassingChanceContainer>
    );
  };
  return (
    <TabContent title={title}>
      <ExamContainer>
        <InsightsSection>
          <ExamIconContainer>
            <ReadinessIcon />
            <ExamInfoContainer>
              <ExamTitle>{examTitle}</ExamTitle>
              <ExamSubTitle>{examSubTitle}</ExamSubTitle>
            </ExamInfoContainer>
          </ExamIconContainer>
          <Button onClick={() => navigateToResults(currentReadinessAttempt)} variant="secondary">
            {examInsightsButton}
          </Button>
        </InsightsSection>
        <ExamHistoryContainer>
          <InfoTagsContainer>
            <PassingChance readinessAttempts={readinessAttempts} />
            {renderMarks()}
            {renderTime()}
          </InfoTagsContainer>
          <CurrentReadinessAttemptContainer>
            <ReadinessQuizCard attempt={currentReadinessAttempt} />
          </CurrentReadinessAttemptContainer>
          <StartAnotherTitle>{innerTitle}</StartAnotherTitle>
          <NewReadinessAttemptContainer>
            <ReadinessQuizCard />
          </NewReadinessAttemptContainer>
        </ExamHistoryContainer>
      </ExamContainer>
    </TabContent>
  );
};

export default Readiness;
