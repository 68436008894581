import SmartNText from '../components/SmartnText';
import { HeaderTrial } from './styled';
import { en } from '../../i18n';

const { header } = en;

const HeaderSponsoredBanner = () => {
  return (
    <HeaderTrial>
      {header.sponsoredTrialTextA}
      &nbsp;
      <SmartNText>Aspire</SmartNText>
      &nbsp;
      {header.sponsoredTrialTextB}
    </HeaderTrial>
  );
};

export default HeaderSponsoredBanner;
