import { AuthContainer, RadialBackground } from '../../Auth/styled';
import Modal from './Modal';

const PrivacyPolicyPage = () => {
  return (
    <AuthContainer>
      <RadialBackground>
        <Modal id="terms" onClose={() => {}} isModalOpen type="privacyPolicy"></Modal>
      </RadialBackground>
    </AuthContainer>
  );
};

const TermsAndAgreements = () => {
  return (
    <AuthContainer>
      <RadialBackground>
        <Modal id="terms" onClose={() => {}} isModalOpen type="termsAndAgreements"></Modal>
      </RadialBackground>
    </AuthContainer>
  );
};

export { PrivacyPolicyPage, TermsAndAgreements };
