import useLocationUI, { IUseLocationUI } from './useLocationUI';

interface ILocation extends IUseLocationUI {}

const Location = ({ externalState, onChangeExternal, index }: ILocation) => {
  const { titleDropDowns } = useLocationUI({ externalState, onChangeExternal, hideOnDisabled: true, index });
  const { countryDropdownTitle, stateDropDownTitle, cityDropDownTitle } = titleDropDowns;

  return (
    <>
      {countryDropdownTitle}
      {stateDropDownTitle}
      {cityDropDownTitle}
    </>
  );
};

export default Location;
