import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  ILooseObject,
  IAnswerQuestionResponse,
  EGoogleTags,
} from '../../types';
import { endpoints } from '../../constants';
import { QuestionResponseTransformer, FullAttemptsTransformer, GoogleTags } from '../../classes';
import { actions as studySpaceActions } from '../../StudySpace/redux/slice';
import { pollRequest } from '../../utils';
import { dashboardPath, nurseGPTPath } from '../../Router/paths';
import { steps, roleQuestionId } from '../constants';

function* attemptQuiz(action: { payload: { quizId: string; userId: string } }) {
  try {
    const { quizId } = action.payload;
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');
    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt.data.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );

    yield put(actions.attemptQuizSuccess({ attempt }));
  } catch (e) {
    yield put(actions.transformAttemptFailure({ errors: { message: e } }));
  }
}

function* transformAttempt(action: { payload: { attempt: IAttempt } }) {
  try {
    const { attempt } = action.payload;
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const { questionsTransformed } = transformedAttempt;
    yield put(actions.transformAttemptSuccess({ questions: questionsTransformed }));
  } catch (e) {
    yield put(actions.transformAttemptFailure({ errors: { message: e } }));
  }
}

function* submitAnswers(action: {
  payload: {
    questionIds: string[];
    attempt: IAttempt;
    answers: (ILooseObject | string | undefined)[];
    history: any;
    isSkipping?: boolean;
  };
}) {
  try {
    const questionResponseTransformer = new QuestionResponseTransformer();
    const { payload } = action;
    const { questionIds, answers, attempt, isSkipping } = payload;
    for (const index in questionIds) {
      const questionId = questionIds[index];
      const answer = answers[index];
      const transformedResponse = questionResponseTransformer.transformOut(
        { questionId, response: answer || '_' },
        true,
      );

      const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
        url: endpoints.submitAnswer(payload.attempt.attemptId),
        requestData: { body: transformedResponse },
        method: ERequestMethods.POST,
      });
    }
    yield put(actions.submitAnswerSuccess());
    if (!isSkipping) {
      const googleTag = new GoogleTags(EGoogleTags.ONBOARDING_COMPLETE);
      googleTag.fireEvent();
      yield put(studySpaceActions.markAsDoneOnboarding({ attempt, questionIds, answers }));
    } else {
      yield put(studySpaceActions.markAsDoneOnboardingPartOne({ attempt, questionIds, answers }));
    }
    yield payload.history.push(nurseGPTPath);
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: { message: error } }));
  }
}

function* watchTransformAttempt() {
  yield takeLatest(actions.transformAttemptRequest, transformAttempt);
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswers, submitAnswers);
}

const sagas = [watchTransformAttempt, watchAttemptQuiz, watchSubmitAnswer];

export default sagas;
