import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { shouldForwardProps } from '../../../utils';
import { IStyledTextFieldProps } from './types';
import { colors } from '../../../../themes';
import { opacityInAnimation } from '../../../sharedStyled';

const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isFocused', 'isEmpty', 'disabled']),
})<IStyledTextFieldProps>(
  ({ isFocused, isEmpty, fullWidth, position, disabled }) => `

    ${fullWidth ? 'width: 100%;' : ''}
    ${position ? `position: ${position};` : ''}
    ${disabled ? ' > * {pointer-events:none;} cursor:not-allowed;' : ''}
    ${
      isEmpty &&
      !isFocused &&
      `
    :not(:has(input:autofill)){
      label {
        transform: translate(14px, 11.5px) scale(1);
      }
    }
    /* firefox */
    @supports (not (selector(:has(*)))){
      label {
        transform: translate(14px, 11.5px) scale(1);
      }
    }
    `
    }
    `,
);

const ForgetPassword = styled('div')`
  display: flex;
  justify-content: flex-end;
  a {
    color: ${colors.highlightBlue};
  }
  font-size: 0.875rem;
  font-weight: 700;
`;

const InputFieldIcon = styled('span')`
  cursor: pointer;
  display: flex;
`;

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(0.5)};
  ${opacityInAnimation()};
`;

const Error = styled('span')`
  display: flex;
  gap: 5px;
  color: ${colors.errorRed};
  font-size: 0.875rem;
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const FieldHelperText = styled('span')`
  color: ${colors.black10};
`;
export { StyledTextField, InputFieldIcon, ForgetPassword, ErrorContainer, Error, FieldHelperText };
