import { useState, useEffect, useRef } from 'react';
import { EQuestionTypes, ICaseStudy, ICaseStudyQuestions, ILooseObject, IQuestion, IQuestionAnswers } from '../types';
import { quizSelectors, SLICE_NAME, actions } from '../StudySpace/redux/slice';
import { useSelector } from 'react-redux';
import { useDispatch } from '../hooks';
import { IFlowOverrides } from './types';
import { getCaseStudyLength } from '../utils';

interface IUseCaseStudy {
  currentQuestion: IQuestion;
  currentQuestionIndex: number;
  flowOverrides?: Partial<IFlowOverrides>;
  goNextQuestion: () => void;
  quizStarted?: boolean;
}
const useCaseStudy = ({
  currentQuestion,
  currentQuestionIndex,
  flowOverrides,
  goNextQuestion,
  quizStarted,
}: IUseCaseStudy) => {
  const {
    questions,
    questionAnswers,
    caseStudySubquestionNewId,
    currentAttempt,
    currentDisplayedQuestionIndex,
  } = useSelector(quizSelectors.allState);
  const { dispatchWithLoading, dispatch } = useDispatch(SLICE_NAME);
  const caseStudiesInitialState = Object.keys(questions).reduce((acc, questionId) => {
    const question = questions[questionId];
    if (question.questionType !== EQuestionTypes.NGN_CASE_STUDY) return acc;
    acc = {
      ...acc,
      [questionId]: {
        isStarted: flowOverrides?.skipCaseStudyUnstartedPage,
        index: 0,
        isReviewing: false,
        isOutroShown: false,
      },
    };
    return acc;
  }, {});
  const [caseStudiesState, setCaseStudiesState] = useState<ILooseObject>(caseStudiesInitialState);

  const { skipExplanation = false, skipCaseStudyUnstartedPage = false } = flowOverrides || {};

  const isCaseStudy = (currentQuestion || {}).questionType === EQuestionTypes.NGN_CASE_STUDY;
  const isCaseStudyStarted = caseStudiesState[currentQuestion?.id]?.isStarted;
  const caseStudyIndex = caseStudiesState[currentQuestion?.id]?.index;
  const isReviewingCaseStudy = caseStudiesState[currentQuestion?.id]?.isReviewing;
  const isCaseStudyOutroShown = caseStudiesState[currentQuestion?.id]?.isOutroShown;
  const caseStudySubquestionId = ((currentQuestion as unknown) as ICaseStudy)?.questions?.[caseStudyIndex]?.id;
  const caseStudyTotalQuestions = ((currentQuestion as unknown) as ICaseStudy)?.questions?.length;
  const isNextCaseStudyQuestionAllowed = Boolean(
    isCaseStudy && questionAnswers[currentQuestion?.id]?.subAnswers?.[caseStudySubquestionId]?.value,
  );

  const initialDisplayIndex = useRef(currentDisplayedQuestionIndex);
  const startCaseStudy = (isReviewing: boolean) => {
    if (isReviewing) {
      dispatch(actions.setCurrentDisplayedIndex({ index: initialDisplayIndex.current }));
    }
    const newCaseStudiesState = { ...caseStudiesState };
    newCaseStudiesState[currentQuestion?.id] = {
      ...newCaseStudiesState[currentQuestion?.id],
      isStarted: true,
      index: 0,
      currentDisplayedQuestionIndex,
      isReviewing,
      isOutroShown: false,
    };
    setCaseStudiesState(newCaseStudiesState);
    if (!isReviewing) initialDisplayIndex.current = currentDisplayedQuestionIndex;
  };

  const goNextSubquestion = () => {
    const newCaseStudiesState = { ...caseStudiesState };
    newCaseStudiesState[currentQuestion?.id] = {
      ...newCaseStudiesState[currentQuestion?.id],
      index: newCaseStudiesState[currentQuestion?.id]?.index + 1,
    };
    setCaseStudiesState(newCaseStudiesState);
    dispatch(actions.incrementDisplayedIndex());
  };

  const goPreviousSubquestion = () => {
    const newCaseStudiesState = { ...caseStudiesState };
    newCaseStudiesState[currentQuestion?.id] = {
      ...newCaseStudiesState[currentQuestion?.id],
      index: newCaseStudiesState[currentQuestion?.id]?.index - 1,
    };
    setCaseStudiesState(newCaseStudiesState);

    dispatch(actions.decrementDisplayedIndex());
  };

  useEffect(() => {
    setCaseStudiesState(caseStudiesInitialState);
  }, [questions]);

  useEffect(() => {
    if (caseStudySubquestionNewId === undefined) return;
    if (caseStudyIndex === caseStudyTotalQuestions - 1) startCaseStudy(true);
    else goNextSubquestion();
  }, [caseStudySubquestionNewId]);

  const answerSubquestion = (answer: ILooseObject, correctAnswer: ILooseObject) => {
    const { id } = currentQuestion;
    const answerPayload: IQuestionAnswers = {
      index: currentQuestionIndex,
      value: '',
      subAnswers: questionAnswers[id]?.subAnswers,
      ctime: new Date().getTime(),
    };
    if (!answerPayload.subAnswers)
      answerPayload.subAnswers = ((currentQuestion as unknown) as ICaseStudy).questions.reduce(
        (acc: ILooseObject, question: ICaseStudyQuestions) => {
          acc[question.id] = {
            value: '',
            isDone: false,
          };
          return acc;
        },
        {},
      );
    const updatedAnswerPayload = {
      ...answerPayload,
      subAnswers: {
        ...answerPayload.subAnswers,
        [caseStudySubquestionId]: {
          value: answer,
          isDone: false,
        },
      },
    };
    dispatch(actions.setQuestionAnswer({ questionId: id, response: updatedAnswerPayload }));
  };
  const endCaseStudy = () => {
    const newCaseStudiesState = { ...caseStudiesState };
    newCaseStudiesState[currentQuestion?.id] = {
      ...newCaseStudiesState[currentQuestion?.id],
      index: 0,
      isOutroShown: false,
    };
    setCaseStudiesState(newCaseStudiesState);
  };

  const showCaseStudyOutro = () => {
    const newCaseStudiesState = { ...caseStudiesState };
    newCaseStudiesState[currentQuestion?.id] = {
      ...newCaseStudiesState[currentQuestion?.id],
      isOutroShown: true,
    };
    setCaseStudiesState(newCaseStudiesState);
  };

  const submitSubquestion = () => {
    if (!questionAnswers[currentQuestion.id].subAnswers?.[caseStudySubquestionId]?.isDone) {
      dispatchWithLoading(
        actions.submitSubAnswer({
          attemptId: currentAttempt?.attemptId || '',
          questionId: currentQuestion.id,
          response: Object.values(questionAnswers[currentQuestion.id].subAnswers || {})
            .map((sub: ILooseObject) => sub.value)
            .filter(value => !!value),
          caseStudySubquestionId,
        }),
      );
    } else {
      if (caseStudyIndex === caseStudyTotalQuestions - 1) startCaseStudy(true);
      else goNextSubquestion();
    }
  };

  useEffect(() => {
    if (!skipExplanation) return;
    const subAnswers = questionAnswers[currentQuestion?.id]?.subAnswers;
    if (!subAnswers) return;
    const isAllDone =
      Object.values(subAnswers).every(subAnswer => subAnswer.isDone) &&
      Object.keys(subAnswers).length === getCaseStudyLength(currentQuestion.id);
    if (isAllDone) {
      endCaseStudy();
      goNextQuestion();
    }
  });

  // useEffect(() => {
  //   if (!quizStarted || !skipCaseStudyUnstartedPage || !isCaseStudy || isCaseStudyStarted) return;
  //   startCaseStudy(false);
  // }, [isCaseStudy, currentQuestionIndex, isCaseStudyStarted, quizStarted]);

  useEffect(() => {
    if (!skipExplanation) return;

    setCaseStudiesState(caseStudiesInitialState);
  }, [questions]);

  return {
    state: {
      isCaseStudy,
      isCaseStudyStarted,
      caseStudyIndex,
      isNextCaseStudyQuestionAllowed,
      isReviewingCaseStudy,
      isCaseStudyOutroShown,
      caseStudyTotalQuestions,
    },
    control: {
      startCaseStudy,
      goNextSubquestion,
      goPreviousSubquestion,
      answerSubquestion,
      submitSubquestion,
      endCaseStudy,
      showCaseStudyOutro,
    },
  };
};

export default useCaseStudy;
