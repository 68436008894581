import { ILooseObject, EMembershipType, TAddons, EAddons } from '../../types';

// Additional customization to run in certain locations only
// Not enforced in abstraction and to be handled case by case
// Might abstract later if it becomes a common case

interface IBaseMembership {
  isSubscribed: boolean;
  location?: string;
  context?: ILooseObject;
  isSubscribedStrict: boolean;
  useStrict: boolean;
  isTrialling: boolean;
  isFreeUserStrict: boolean;
  addons: TAddons;
  requiredAddons?: EAddons[];
  isDeprecatedSubscription: boolean;
  isPaymentIssue: boolean;
  isSponsored: boolean;
}

abstract class BaseMembership {
  private membershipType: EMembershipType = EMembershipType.FREE;
  private location?: string;
  private context?: ILooseObject = {};
  private isSubscribedStrict: boolean;
  private useStrict: boolean;
  private isSubscribed: boolean;
  private isTrialling: boolean;
  private isFreeUserStrict: boolean;
  private addons: TAddons;
  private requiredAddons?: EAddons[];
  private isDeprecatedSubscription: boolean;
  private isPaymentIssue: boolean;
  private isSponsored: boolean;

  constructor({
    isSubscribed,
    location,
    context,
    isSubscribedStrict,
    useStrict,
    isTrialling,
    isFreeUserStrict,
    addons,
    requiredAddons,
    isDeprecatedSubscription,
    isPaymentIssue,
    isSponsored,
  }: IBaseMembership) {
    this.location = this.mapLocation(location);
    this.context = context;
    this.isSubscribedStrict = isSubscribedStrict;
    this.useStrict = useStrict;
    this.isSubscribed = isSubscribed;
    this.isTrialling = isTrialling;
    this.isFreeUserStrict = isFreeUserStrict;
    this.isDeprecatedSubscription = isDeprecatedSubscription;
    this.isPaymentIssue = isPaymentIssue;
    this.addons = addons;
    this.requiredAddons = requiredAddons;
    this.membershipType = this.mapIsSubscribed();
    this.isSponsored = isSponsored;
  }

  public useStrictSubscriptions() {
    return this.useStrict;
  }

  public useStrictFreeMembers() {
    return false;
  }

  // Dummy method to just map the boolean
  // isSubscribed to a membership type
  private mapIsSubscribed() {
    if (this.isTrialling) return EMembershipType.TRIALLING;
    if (this.isPaymentIssue) return EMembershipType.FREE;
    if (this.isDeprecatedSubscription) return EMembershipType.DEPRECATED_SUBSCRIPTION;
    const subscribedCheck = this.useStrictSubscriptions() ? this.isSubscribedStrict : this.isSubscribed;
    if (subscribedCheck) return EMembershipType.ESSENTIALS;

    if (this.useStrictFreeMembers()) {
      if (this.isFreeUserStrict) return EMembershipType.FREE;
      return EMembershipType.ESSENTIALS;
    }

    return EMembershipType.FREE;
  }

  private mapLocation(location?: string) {
    const getLastUrlElement = (url: string) => {
      const array = url.split('/');
      return array[array.length - 1];
    };
    if (!location) return;

    const lastUrlElement = getLastUrlElement(location);
    return lastUrlElement;
  }

  public getLocation() {
    return this.location;
  }

  public getContext() {
    return this.context;
  }

  public abstract free(): any;
  public abstract essential(): any;
  public abstract allAccess(): any;
  public trialling() {
    return this.essential();
  }
  public sponsored() {
    return this.essential();
  }

  public runMembershipCheck() {
    if (this.isSponsored) return this.sponsored();

    switch (this.membershipType) {
      case EMembershipType.FREE:
        return this.free();
      case EMembershipType.ESSENTIALS:
        return this.essential();
      case EMembershipType.ALL_ACCESS:
        return this.allAccess();
      case EMembershipType.TRIALLING:
        return this.trialling();
      case EMembershipType.DEPRECATED_SUBSCRIPTION:
        return this.essential();
    }
  }

  public addonRequired(): any {
    return null;
  }

  public addonRequiredWithActiveMembership(): any {
    return null;
  }
  public getMembershipType() {
    return this.membershipType;
  }

  public addonCheck() {
    if (!this.addons || !this.requiredAddons) return null;
    let addonsFullfilled = false;
    this.requiredAddons.forEach(addon => {
      if (this.addons[addon]) {
        addonsFullfilled = true;
      }
    });

    if (addonsFullfilled && this.membershipType === EMembershipType.ESSENTIALS) return null;
    return this.addonRequired();
  }

  public run() {
    const membershipCheck = this.runMembershipCheck();
    if (this.membershipType === EMembershipType.DEPRECATED_SUBSCRIPTION) return membershipCheck;
    const addonCheck = this.addonCheck();
    const addonWithFreeFreeMembership = this.addonRequiredWithActiveMembership();
    if (addonCheck === null) return membershipCheck;
    if (this.membershipType === EMembershipType.ESSENTIALS && addonCheck && addonWithFreeFreeMembership)
      return addonWithFreeFreeMembership;

    return addonCheck || membershipCheck;
  }
}

export default BaseMembership;
