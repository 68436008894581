import Button from '../../../components/Button';
import { InfoContainer, MobileTopicCard, MobileTopicCardRow, MobileTopicCardsContainer } from '../../styled';
import { ITableRender, TMobileGroup, mobileGroups } from './data';
import { en } from '../../../../i18n';

const { resultsTopicFilterButton } = en.reviewResults;

const MobileCards = ({ data }: ITableRender) => {
  const renderCard = (cardData: typeof data[0]) => {
    const renderInfoRow = (pair?: TMobileGroup) => {
      if (!pair) return null;
      const { title, value: value, isComponent, fullWidth } = pair;
      const renderedValue = isComponent ? cardData[value] : <h4>{cardData[value]}</h4>;
      return (
        <InfoContainer fullWidth={fullWidth}>
          <h5>{title}</h5>
          {renderedValue}
        </InfoContainer>
      );
    };

    return mobileGroups.map((pair, index) => {
      const [pairA, pairB] = pair;
      return (
        <MobileTopicCardRow key={index}>
          {renderInfoRow(pairA)}
          {renderInfoRow(pairB)}
        </MobileTopicCardRow>
      );
    });
  };

  const renderCards = () => {
    return data.map((row, index) => {
      return (
        <MobileTopicCard key={index}>
          {renderCard(row)}

          <Button
            onClick={() => {
              const viewResults = row.viewResults as () => void;
              viewResults();
            }}
            size="medium"
            variant="primary"
          >
            {resultsTopicFilterButton}
          </Button>
        </MobileTopicCard>
      );
    });
  };

  return <MobileTopicCardsContainer>{renderCards()}</MobileTopicCardsContainer>;
};

export default MobileCards;
