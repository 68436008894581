import { styled } from '@mui/material';
import { shouldForwardProps } from '../../../../utils';
import { colors } from '../../../../../themes';
import { opacityInAnimation } from '../../../../sharedStyled';

interface IStyledCell {
  error?: boolean;
  readOnly?: boolean;
}

const StyledCell = styled('input', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['error']),
})<IStyledCell>(
  ({ error, readOnly, theme }) => `
  width: 64px;
  height: 64px;
  border: 1px solid ${error ? colors.errorRed : colors.mediumGray};
  outline-color:${colors.mediumGray};
  transition:all 0.5s ease;
  border-radius:4px;
  font-weight:700;
  font-size:2.5rem;
  text-align:center;
  &:focus {
   outline-color:${colors.purple20};
  }
  ${readOnly ? `background-color:${colors.darkGrey};` : ''}


  ${theme.breakpoints.between('$xxs', 'sm')} {
    width:35px;
    height:35px;

    font-size:2rem;
  }

  ${theme.breakpoints.between(0, '$xxs')} {
    width:30px;
    height:30px;
    font-size:16px;
  }


`,
);

const ErrorContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  background-color: ${colors.errorRed5};
  color: ${colors.errorRed};
  font-weight: 700;
  font-size: 1rem;
  ${opacityInAnimation()};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  padding: 17px;
  text-align: center;
`;

const Cells = styled('div')`
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
  justify-content: center;
  ${props => props.theme.breakpoints.down('$xs')} {
    flex-wrap: wrap;
  }
`;

const Container = styled('div')``;
export type { IStyledCell };
export { StyledCell, Cells, ErrorContainer, Container };
