import { Auth } from 'aws-amplify';
import { ICognitoUser } from '../types';
import LocalStorageWithTTL from './LocalStorageWithTTL';

const dayMilliseconds = 8.64e7 * 25;

class CurrentProfile {
  static profile?: ICognitoUser;
  static localStorage = new LocalStorageWithTTL('auth', dayMilliseconds);

  static setProfile(profile?: ICognitoUser) {
    const { userClass, ...rest } = profile || {};
    CurrentProfile.localStorage.set(rest);
    CurrentProfile.profile = profile;
    //Auth.signOut();
  }

  static getToken() {
    return CurrentProfile.profile?.idToken || CurrentProfile.localStorage.get()?.idToken;
  }

  static loadProfile() {
    CurrentProfile.profile = CurrentProfile.localStorage.get();
    return CurrentProfile.profile;
  }

  static getProfile() {
    return CurrentProfile.profile;
  }

  static async logout() {
    Auth.signOut();
    CurrentProfile.localStorage.clear();
    localStorage.clear();
  }

  static updateToken(idToken: string) {
    const current = CurrentProfile.localStorage.get();
    if (current) {
      current.idToken = idToken;
      this.localStorage.set(current);
      CurrentProfile.profile = current;
    }
  }
}

export default CurrentProfile;
