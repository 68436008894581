import { ILooseObject, ITopic } from '../../types';
import BaseTransformer from './BaseTransformer';
interface IApiSubgroups {
  subgroup: {
    displayName: string;
    id: string;
  };
}
interface ITopicsTransformer {
  applyOldTopicsFilter?: boolean;
  applyNewTopicsFilter?: boolean;
}

const oldTopicsFilter: ILooseObject = {
  '7be43704-6060-11ed-91bc-3933fa62cf29': 'oncology',
  '7bb531f2-6060-11ed-91bc-3933fa62cf29': 'tests',
};

const newTopicsFilter: ILooseObject = {
  'e0c71bb8-12e0-11ee-be56-0242ac120002': 'immunology_infectious',
  'e0c70e34-12e0-11ee-be56-0242ac120002': 'complexCareConcepts',
  'e0c70f9c-12e0-11ee-be56-0242ac120002': 'pediatricHealth',
  'e0c71514-12e0-11ee-be56-0242ac120002': 'medicationCalculation',
  'e0c713b6-12e0-11ee-be56-0242ac120002': 'renal',
};

class TopicTransformer extends BaseTransformer {
  private applyOldTopicsFilter: boolean;
  private applyNewTopicsFilter: boolean;
  constructor({ applyNewTopicsFilter = false, applyOldTopicsFilter = false }: ITopicsTransformer = {}) {
    super();
    this.applyOldTopicsFilter = applyOldTopicsFilter;
    this.applyNewTopicsFilter = applyNewTopicsFilter;
  }
  public transform(data: IApiSubgroups[]): ITopic[] {
    let topics = data.map(item => ({ name: item.subgroup.displayName, id: item.subgroup.id }));
    if (this.applyOldTopicsFilter) topics = topics.filter(topic => !oldTopicsFilter[topic.id]);
    if (this.applyNewTopicsFilter) topics = topics.filter(topic => !newTopicsFilter[topic.id]);

    return topics;
  }
}

export default TopicTransformer;
