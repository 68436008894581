import CircularProgress from '@mui/material/CircularProgress';
import { InlineLoaderContainer, LoaderContainer } from './styled';

interface ISpinner {
  loading: boolean;
  spinnerColor?: string;
  appplyBackground?: boolean;
  size?: string;
  inline?: boolean;
}

const Spinner = ({ loading, spinnerColor, appplyBackground, size, inline }: ISpinner) => {
  if (!loading) return null;

  const spinElement = <CircularProgress />;

  if (inline) return <InlineLoaderContainer>{spinElement}</InlineLoaderContainer>;

  return (
    <LoaderContainer size={size} color={spinnerColor} applyBackground={appplyBackground}>
      {spinElement}
    </LoaderContainer>
  );
};

export default Spinner;
