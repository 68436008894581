import { PaymentIssueMessageContent, PaymentIssueText } from '../../Membership/LandingSubscribed/styled';
import { en } from '../../../i18n';
import parse from 'html-react-parser';

const { billing } = en;
const { deprecatedSubscriptionSubTitle, deprecatedSubscriptionTitle } = billing;

const DeprecatedSubscriptionMessage = () => {
  return (
    <PaymentIssueMessageContent>
      <PaymentIssueText>
        <div>{deprecatedSubscriptionTitle}</div>
        <div>{parse(deprecatedSubscriptionSubTitle)}</div>
      </PaymentIssueText>
    </PaymentIssueMessageContent>
  );
};

export default DeprecatedSubscriptionMessage;
