import Bugsnag, { BrowserConfig } from '@bugsnag/js';
import { APP_VERSION, appIds } from '../../constants';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Domains from './Domains';
import { EEnviornment } from '../types';
import CurrentProfile from './CurrentProfile';
import BugsnagPerformance from '@bugsnag/browser-performance';

class BugSnag {
  public static isInitialized = false;
  public init() {
    try {
      const releaseStageName = this.getReleaseStageName();
      const { control } = window.config || {};
      const disbaleBugsnag = control?.disbaleBugsnag === 'true';
      if (disbaleBugsnag) {
        console.log('Skipping Bugsnag initialization - control flag set to false');
        return;
      }
      if (!releaseStageName) {
        console.log(`Skipping Bugsnag Initialization for ${Domains.getEnviornment()} ${Domains.getTestInstanceName()}`);
        return;
      }
      const config = this.buildConfig(releaseStageName);
      Bugsnag.start(config);
      console.log(`Bugsnag initialized ${releaseStageName} version: ${config.appVersion}`);
      BugSnag.isInitialized = true;
      BugsnagPerformance.start({ apiKey: appIds.bugsnag });
    } catch (error) {
      console.log(`Bug snag initialization failed ${error}`);
    }
  }

  public getReleaseStageName() {
    const environment = Domains.getEnviornment();
    switch (environment) {
      case EEnviornment.PRODUCTION:
        return environment;
      case EEnviornment.TEST_INSTANCE:
        return Domains.getTestInstanceName();
      default:
        return '';
    }
  }

  public notify(error: Error) {
    if (!BugSnag.isInitialized) return;
    console.log('Bugsnag notified');
    Bugsnag.notify(error);
  }

  private buildConfig(releaseStageName: string): BrowserConfig {
    const onError = (event: any) => {
      const userProfile = CurrentProfile.loadProfile();
      event.setUser(
        userProfile?.attributes?.sub || 'N/A',
        userProfile?.attributes?.email || 'N/A',
        userProfile?.username || 'N/A',
      );
    };
    const config: BrowserConfig = {
      apiKey: appIds.bugsnag,
      plugins: [new BugsnagPluginReact()],
      appVersion: APP_VERSION,
      releaseStage: releaseStageName,
      onError,
    };
    return config;
  }
}

export default BugSnag;
