import { useState, useMemo } from 'react';
import { TFieldValues } from '../types';
import { THIS_YEAR, THIS_MONTH, getMonthDays, getMonthFirstDay, getDayRows } from './utils';

interface IDateState {
  day: number | null;
  month: number;
  year: number;
}

const useDatePicker = () => {
  const [dateComponents, setDateComponents] = useState<IDateState>({ day: null, month: THIS_MONTH, year: THIS_YEAR });
  const { day, month, year } = dateComponents;

  const resetState = () => {
    setDateComponents({ day: null, month: THIS_MONTH, year: THIS_YEAR });
  };

  const onNextMonth = () => {
    let nextMonth = month + 1;
    let currentYear = year;
    if (month === 12) {
      nextMonth = 1;
      currentYear = year + 1;
    }
    setDateComponents({ day: null, month: nextMonth, year: currentYear });
  };

  const onPreviousMonth = () => {
    let nextMonth = month - 1;
    let currentYear = year;
    if (month === 1) {
      nextMonth = 12;
      currentYear = year - 1;
    }
    setDateComponents({ day: null, month: nextMonth, year: currentYear });
  };

  const onChangeMonth = (nextMonth: TFieldValues) => {
    setDateComponents({ day, month: +nextMonth, year });
  };

  const onChangeYear = (nextYear: TFieldValues) => {
    setDateComponents({ day, month, year: +nextYear });
  };

  const onChangeDay = (nextDay: number) => {
    setDateComponents({ day: nextDay, month, year });
  };

  const previousMonth = month === 1 ? 12 : month - 1;
  const previousMonthYear = previousMonth === 12 ? year - 1 : year;
  const previousMonthDays = getMonthDays(previousMonth, previousMonthYear);
  const monthDays = getMonthDays(month, year);
  const firstMonthDay = getMonthFirstDay(month, year);
  const currentDays = useMemo(() => getDayRows(firstMonthDay, previousMonthDays, monthDays), [
    firstMonthDay,
    previousMonthDays,
    monthDays,
  ]);

  return {
    state: { dateComponents, monthDays, firstMonthDay, previousMonthDays, currentDays, day, month, year },
    control: { onNextMonth, onPreviousMonth, onChangeMonth, onChangeYear, onChangeDay, resetState },
  };
};

export default useDatePicker;
