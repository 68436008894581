import { FormTitle, MembershipBillingCycle, MembershipInfoContainer, MembershipInfoTitle } from './styled';
import { en } from '../../../i18n';
import SmartNText from '../../components/SmartnText';
import { useSubscriptions } from '../../hooks';
import { IAddon, IPlan, defaultPlan as plan } from '../plans';
import ContentManager from './classes/ContentManager';
import { EPaymentSteps, EPlanTypes } from '../../types';

// const {
//   paymentForm: { titleTop, planRightTitle, planSubTitleTrial, notSubscribed, planSubTitleMember },
// } = en.billing;

interface IMembershipInfo {
  stripePlan: IPlan | IAddon;
  currentState: EPaymentSteps;
}
const MembershipInfo = ({ stripePlan, currentState }: IMembershipInfo) => {
  const { currentSubscription, isTrialling, isActive, deprecatedSubscription, isSponsored } = useSubscriptions({
    useFetchSubscriptions: false,
  });

  const contentManager = new ContentManager(stripePlan, currentState, {
    currentSubscription,
    isActive,
    isTrialling,
    deprecatedSubscription,
    isSponsored,
  });
  const isAddon = stripePlan.type === EPlanTypes.ADDON;

  return (
    <div>
      <FormTitle>{contentManager.content.membershipInfoTopTitle()}</FormTitle>
      <MembershipInfoContainer>
        <MembershipInfoTitle>
          <span className={isAddon ? 'addon-title' : ''}>{contentManager.content.membershipInfoTitle()}</span>
          {/* <span>
            <SmartNText />
            &nbsp;{planRightTitle}
          </span> */}
          <span>
            {/* ${plan.price}&nbsp;
            {plan.priceDescription} */}
            {contentManager.content.membershipInfoPrice()}
          </span>
        </MembershipInfoTitle>
        <MembershipBillingCycle>{contentManager.content.membershipInfoSubTitle()}</MembershipBillingCycle>
      </MembershipInfoContainer>
    </div>
  );
};

export default MembershipInfo;
