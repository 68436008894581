import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

interface IButtonContainer {
  isCentered?: boolean;
}

const UnsubscribedResults = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  h1,
  h3 {
    color: ${colors.black};
    margin: 0;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const UpgradeMoreContainer = styled('div')`
  //   border: 2px solid;
  //   border-image-source: linear-gradient(103.44deg, #ea20a5 -1.55%, #4a2dff 93.9%);
  //   border-image-slice: 1;
  width: 42%;
  height: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    background: linear-gradient(103.44deg, #ea20a5 -1.55%, #4a2dff 93.9%) border-box;
    border: 2px solid transparent;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
  }

  padding: ${props => props.theme.spacing(5)} ${props => props.theme.spacing(5)} ${props => props.theme.spacing(4.25)}
    ${props => props.theme.spacing(5)};
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 16px;
  margin-right: 80px;
  ${props => props.theme.breakpoints.down('lg')} {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 80px;
    width: 80%;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding: ${props => props.theme.spacing(2.5)} ${props => props.theme.spacing(2.5)}
      ${props => props.theme.spacing(2.125)} ${props => props.theme.spacing(2.5)};
  }

  ${props => props.theme.breakpoints.down('$xxs')} {
    padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1.9)}
      ${props => props.theme.spacing(2)};
  }

  //box-shadow: 0px 0px 40px 0px rgba(209, 203, 246, 1);

  ${props => props.theme.spacing(5)};
  ${props => props.theme.spacing(3)};
  ${props => props.theme.spacing(5)};
`;

const UpgradeTitleText = styled('h1')`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
`;

const HighlightText = styled('span')`
  color: ${colors.purple30};
`;

// Overrides for feedback
const FeedbackContainer = styled('div')`
  margin-top: 163px;
  margin-bottom: 238px;
  margin-left: 80px;
  ${props => props.theme.breakpoints.down('lg')} {
    margin-left: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }
`;

const FeedbackTextContainer = styled('div')`
  width: 80%;
  > div:nth-child(2) {
    margin-bottom: 32px;
  }
  > div:nth-child(3) {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 90%;
    margin: auto;
  }
`;

const FeaturesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
  margin-top: ${props => props.theme.spacing(3)};
`;

const Feature = styled('div')`
  display: flex;
  font-size: 0.9rem;
  font-weight: 700;
  align-items: center;
  gap: ${props => props.theme.spacing(1)};
  svg {
    font-size: 1.125rem;
  }
  span {
    font-weight: 600;
  }
`;

const ButtonContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCentered']),
})<IButtonContainer>(
  ({ isCentered, theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isCentered ? 'center' : 'flex-start'};
  margin-top: ${theme.spacing(4.25)};
`,
);

const CenteredButtonContainer = styled(ButtonContainer)`
  justify-content: center;
`;

const ComingSoonText = styled('span')`
  color: ${colors.grey500};
  font-weight: 500;
`;

const SubTitleA = styled('div')`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 16px;
`;

const SubTitleB = styled('div')`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 24px;
`;

export {
  HighlightText,
  UpgradeTitleText,
  UnsubscribedResults,
  UpgradeMoreContainer,
  FeedbackContainer,
  FeedbackTextContainer,
  FeaturesContainer,
  Feature,
  ButtonContainer,
  ComingSoonText,
  SubTitleA,
  SubTitleB,
  CenteredButtonContainer,
};
