import { getOrbitsConfig } from '../../../utils';
import { ESubscriptionsStatus, ILooseObject } from '../../types';
import BaseTransformer from './BaseTransformer';
import SubscriptionTransformer from './SubscriptionTransformer';

const config = getOrbitsConfig();
class AllSubscriptionsTransformer extends BaseTransformer {
  private subscriptionTransformer = new SubscriptionTransformer();

  public transform(data: ILooseObject[]) {
    if (!data || data.length === 0) {
      return {
        currentSubscription: { address: { country: 'CA', postalCode: '', city: '', line1: '' }, isDeprecated: false },
        subscriptionsFound: false,
        isSponsoredSubscription: false,
      };
    }
    const activeSubscription = data.find((sub: any) => sub.status === ESubscriptionsStatus.ACTIVE);
    const triallingSubscription = data.find((sub: any) => sub.status === ESubscriptionsStatus.TRIALING);
    const isSponsoredSubscription = data.some((sub: any) => {
      if (sub.status !== ESubscriptionsStatus.ACTIVE) return false;
      if (!sub?.items?.data) return false;
      return !!sub.items.data.some((item: any) => item.price.id === config.stripePlans.sponsoredBasicAccess.priceId);
    });
    const sub = activeSubscription || triallingSubscription || data[0];
    const transformedTrialling = triallingSubscription
      ? this.subscriptionTransformer.transform(triallingSubscription)
      : undefined;
    const currentSubscription = this.subscriptionTransformer.transform(sub);
    return {
      currentSubscription,
      triallingSubscription: transformedTrialling,
      subscriptionsFound: true,
      isSponsoredSubscription: isSponsoredSubscription,
    };
  }
}

export default AllSubscriptionsTransformer;
