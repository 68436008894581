import { IAddon, IPlan } from '../Membership/plans';
import { ICognitoUser } from './auth';
import { EAuthStates, EPlanTypes, EPaymentSteps, EAddons } from './enums';
import { ILooseObject } from './general';
import { IQuestion } from './question';
import { ISubscription, SubscriptionModalVariantType } from './subscriptions';
import { IMessage } from './nurseGPT';

interface IErrorAction {
  payload: ILooseObject;
}

interface ITopic {
  id: string;
  name: string;
}
interface ISelectedTopic {
  [key: string | number]: boolean;
}

interface IGlobalState {
  [key: string]: {
    loading: boolean;
    errors: ILooseObject;
    isOpen?: boolean;
  };
  // TODO: Fix state here
  mobileSideBar: {
    isOpen?: boolean;
    loading: boolean;
    errors: ILooseObject;
  };
}

interface IQuizState {
  quizId?: string;
  isStarted: boolean;
  currentAttempt?: IAttempt;
  currentQuestionId?: string;
  currentQuestionIndex: number;
  currentDisplayedQuestionIndex: number;
  questionAnswers: IQuestionAnswersState;
  attempts?: IAttempt[];
  loadingAttempts: boolean;
  loadingQuestion: boolean;
  loadingStartQuiz: boolean;
  isViewingResults: boolean;
  loadingAnswer?: boolean;
  transformingAttempt?: boolean;
  questions: {
    [key: string]: IQuestion;
  };
  caseStudySubquestionNewId?: string;
  onBoardingAttempts?: IAttempt[];
  loadingOnBoardingAttempts: boolean;
  placementAttempts?: IAttempt[];
  loadingPlacementAttempts: boolean;
  proficiency?: ILooseObject;
  loadingAttemptsPage: boolean;
}

interface ICreateQuizResponse {
  data: string;
}

interface IStartQuizResponse {
  data: string;
}

interface IAttemptQuestion {
  questionId: string;
  groupIds: string[];
  response?: string[] | string;
  mark?: string;
  parsedMark?: ILooseObject;
  subAnswers?: ILooseObject;
  displayedIndex: number;
  ctime: string | number;
}

interface IQuestionAnswers {
  index: number;
  value: string[] | string;
  subAnswers?: ILooseObject;
  parsedMark?: ILooseObject;
  isDone?: boolean;
  ctime: number | string;
}

interface IQuestionAnswersState {
  [key: string]: IQuestionAnswers;
}

enum EAttemptTypes {
  SMART_QUIZ = 'smartQuiz',
  NURSE_GPT = 'nurseGPT',
  ONBOARDING = 'onboarding',
  PLACEMENT = 'placement',
  READINESS = 'readiness',
  ROLE_PLACEHOLDER = 'rolePlaceholder',
  APPLY = 'apply',
}
interface IAttempt {
  attemptId: string;
  title: string;
  quizType: EAttemptTypes;
  questions: IAttemptQuestion[];
  questionsTransformed: {
    [key: string]: IQuestion;
  };
  questionAnswers: IQuestionAnswersState;
  selectedTopicIds: string[];
  isDone: boolean;
  questionId: string;
  questionIndex: number;
  displayedQuestionIndex: number;
  isPlacement: boolean;
  isOnBoarding: boolean;
  isRolePlaceHolder: boolean;
  onBoardingVersion?: 'v0' | 'v1' | 'v2' | 'v3';
  isReadiness: boolean;
  isNurseGPT: boolean;
  isApply: boolean;
  topicIDs: string[];
  ctime: string;
  endtime: string | number;
  avgQuestionTime: number | null;
  totalTime: number | null;
  progress: {
    totalQuestions: number;
    answeredQuestions: number;
    percentage: number;
    totalMark: number;
    totalMaximumMark: number;
    grade: number;
  };
  currentQuestionInfo: {
    questionId: string;
    isDone: boolean;
    questionIndex: number;
  };

  totalDisplayQuestions: number;
}

interface IAttemptResponse {
  data: IAttempt;
}

interface IFetchQuestionResponse {
  data: string;
}

interface IAnswerQuestionResponse {
  data: {
    mark: string;
  };
}

interface IFetchAttemptsResponse {
  data: IAttempt[];
}

interface IFetchAttemptResponse {
  data: IAttempt;
}

interface IAuthError {
  responseCodeName?: string;
  responseCode?: string;
  message?: string;
}
interface ISuccessFeedbackPayload {
  title?: string;
  subTitle?: string;
}

interface IAuthState {
  loading: boolean;
  errors: IAuthError;
  successFeedback?: ISuccessFeedbackPayload;
  isError?: boolean;
  user?: ICognitoUser;
  authState?: EAuthStates | null;
  liveOnboardingRole?: string;
  loadingEditRole: boolean;
}

interface IOnboardingState {
  loading: boolean;
  loadingQuestion: boolean;
  errors: ILooseObject;
  questionsTransformed?: {
    [key: string]: IQuestion;
  };
  quizId?: string;
  attempt?: IAttempt;
}

type TApplyResponse = string | number | boolean | ILooseObject | null;
interface IApplyAnswers {
  [key: string]: {
    response: TApplyResponse | TApplyResponse[] | null;
    isDone?: boolean;
  };
}

interface IApplyState {
  questionAnswers: IApplyAnswers;
  loading: boolean;
  loadingStartQuiz: boolean;
  errors: ILooseObject;
  attempts: IAttempt[];
  loadingAnswer: boolean;
  questionsTransformed?: ILooseObject;
  currentQuestionIndex: number;
}
interface IMembershipErrors {
  global: string;
  address: string;
  card: string;
  promoCode: string;
}

type TAddons = {
  [key in EAddons]?: boolean;
};
interface ISubscriptionsState {
  loading: boolean;
  loadingCreateSubscription: boolean;
  loadingDebounce: boolean;
  loadingChangePaymentMethod: boolean;
  loadingFetchSubscriptions: boolean;
  loadingFetchAddons: boolean;
  isPaymentMethodChanged: boolean;
  loadingPurchaseAddon: boolean;
  errors: IMembershipErrors;
  currentSubscription?: ISubscription;
  modalVariant: SubscriptionModalVariantType;
  showPaymentForm: boolean;
  showModal: boolean;
  paymentStep: EPaymentSteps;
  triallingSubscription?: ISubscription;
  subscriptionsFound?: boolean;
  promotionCodeID?: string;
  isAddressCreated: boolean;
  isTrialEndedModalShown: boolean;
  isPriceIdChanged: boolean;
  stripePlan: IPlan | IAddon;
  isTrialEnded: boolean;
  isPaymentIssueModal: boolean;
  isDeprecatedSubscriptionModal: boolean;
  loadingCreateTrial: boolean;
  addons: TAddons;
  isSponsored: boolean;
}

interface INurseGPTState {
  loading: boolean;
  loadingAnswer: boolean;
  errors: ILooseObject;
  attempt?: IAttempt;
  questionsTransformed?: ILooseObject;
  submitted: boolean;
  loadingResponse: boolean;
  currentSessionId: string;
  sessions: ILooseObject[];
  loadingSessions: boolean;
  allSessionsFetched: boolean;
  lastFetchedSession: string;
}

interface ISubmitPromptResponse {
  data: {
    session_id: string;
    session_created_at: string;
    messages: ILooseObject[];
  };
}

interface IFetchSessionResponse {
  session_id: string;
  session_created_at: number;
  messages: ILooseObject[];
  session_title?: string;
}

interface IFetchSessionsResponse {
  session_id: string;
  session_created_at: number;
  session_title?: string;
}

export type {
  IErrorAction,
  ITopic,
  ISelectedTopic,
  IGlobalState,
  IQuizState,
  ICreateQuizResponse,
  IStartQuizResponse,
  IAttempt,
  IAttemptQuestion,
  IAttemptResponse,
  IFetchQuestionResponse,
  IAnswerQuestionResponse,
  IQuestionAnswers,
  IQuestionAnswersState,
  IFetchAttemptsResponse,
  IFetchAttemptResponse,
  IAuthState,
  IAuthError,
  ISuccessFeedbackPayload,
  IOnboardingState,
  ISubscriptionsState,
  IMembershipErrors,
  TAddons,
  INurseGPTState,
  ISubmitPromptResponse,
  IFetchSessionsResponse,
  IApplyState,
  TApplyResponse,
  IFetchSessionResponse,
};

export { EAttemptTypes };
