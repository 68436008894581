import React from 'react';
import MUIModal from '@mui/material/Modal';
import { ModalContainer } from './styled';

interface IModal {
  children: React.ReactNode;
  heading?: string;
  id: string;
  isModalOpen: boolean;
  onClose?: () => void;
  onPrimaryButtonClick?: () => void;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
}

const Modal = ({ children, id, isModalOpen, onClose, onPrimaryButtonClick }: IModal) => {
  const handleClose = () => {
    onClose?.();
  };

  const handlePrimaryButtonClick = () => {
    onPrimaryButtonClick?.();
  };

  return (
    <MUIModal className="UserAgreementsModal" disableRestoreFocus id={id} open={isModalOpen} onClose={handleClose}>
      <ModalContainer>{children}</ModalContainer>
    </MUIModal>
  );
};

export default Modal;
