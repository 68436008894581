import BaseTransformer from './BaseTransformer';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { EAWSErrorCodes } from '../../Auth/constants';

class CognitoUserWithChallenge extends CognitoUser {
  challengeName?: string = '';
  attributes?: {
    sub: string;
  };
}
class AuthUserTransfomer extends BaseTransformer {
  public transformOut(user: CognitoUserWithChallenge, isConfirmed?: boolean) {
    // Using the enum causes a runtime error for some reason
    const isTemp = user.challengeName === 'NEW_PASSWORD_REQUIRED';
    const payload = {
      username: user.getUsername(),
      idToken: user.getSignInUserSession()?.getIdToken().getJwtToken() || '',
      refreshToken: user.getSignInUserSession()?.getRefreshToken().getToken() || '',
      isConfirmed: isConfirmed === undefined ? true : isConfirmed,
      isTemp,
      userClass: user,
      attributes: user.attributes,
    };
    return payload;
  }

  public federatedTransformOut(user: CognitoUser) {
    const payload = {
      username: user.getUsername(),
      idToken: user.getSignInUserSession()?.getIdToken().getJwtToken() || '',
      refreshToken: user.getSignInUserSession()?.getRefreshToken().getToken() || '',
      isConfirmed: true,
      isTemp: false,
      userClass: user,
      attributes: user.getSignInUserSession()?.getIdToken().payload,
    };
    return payload;
  }

  public constructUser(userName: string) {
    const payload = {
      username: userName,
      idToken: '',
      refreshToken: '',
      isConfirmed: false,
    };
    return payload;
  }
}

export default AuthUserTransfomer;
