import { useState, ChangeEvent, useRef, MutableRefObject, useEffect } from 'react';
import {
  Banner,
  ChoicesContainer,
  Choice,
  QuestionsContainer,
  MessageContainer,
  CaseStudyContainer,
  SubquestionContainer,
  CaseStudySelection,
  ResultsContainer,
  TagsContainer,
  QuestionHeader,
  MarksHeader,
} from './styled';
import { en } from '../../i18n';
import BackButton from '../components/BackButton';
import { TitleText, Text, SmallText, BoldText, SecondaryTitleText, Tag } from '../sharedStyled';
import Radio from '@mui/material/Radio';
import { useHistory } from 'react-router-dom';
import Question from '../SmartQuiz/question';
import { IQuestion, EQuestionTypes, ICaseStudy, ICaseStudyQuestions, ILooseObject } from '../types';
import { getTheme } from '../../themes';
import { useSelector } from 'react-redux';
import { quizSelectors, SLICE_NAME } from '../StudySpace/redux/slice';
import { topicsSelectors } from '../StudySpace/redux/topics/slice';
import { actions as globalActions } from '../redux/globalSlice';
import { useDispatch, useKeyDownPress } from '../hooks';
import Spinner from '../components/Spinner';
import CaseStudy from '../SmartQuiz/question/CaseStudy';
import DropDown from '../components/Form/Fields/DropDown';
import { subject } from '../StudySpace/staticConstants';
import useInitResults from './useInitResults';
import CustomTooltip from '../components/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { SINGLE_CHOICE_SELECT_ALL_QUESTIONS, SINGLE_QUESTION_CASE_STUDIES } from '../constants';
import parse from 'html-react-parser';

const {
  backToQuizResults,
  reviewAnswers,
  reviewAnswersFor,
  filterQuestions,
  allQuestions,
  wrongAnswers,
  correctAnswers,
  noCorrectAnswers,
  noWrongAnswers,
  noQuestions,
  ngnCaseStudies,
  multipleChoiceQuestions,
  questionTypes,
  ngnCaseStudy,
  selectCaseStudy,
  marks,
  mark,
  markingExplanations,
  bowTie,
  nclexStyleQuestions,
} = en.reviewResults;

const { question: questionText, of } = en.smartQuiz;

enum EFilter {
  ALL_QUESTIONS = 'allQuestions',
  WRONG_ANSWERS = 'wrongAnswers',
  CORRECT_ANSWERS = 'correctAnswers',
}

const choices = [
  {
    value: EFilter.ALL_QUESTIONS,
    label: allQuestions,
  },
  { value: EFilter.WRONG_ANSWERS, label: wrongAnswers },
  { value: EFilter.CORRECT_ANSWERS, label: correctAnswers },
];

const questionTypesChoices = [
  {
    value: EQuestionTypes.NCLEX_STYLE_QUESTIONS,
    label: nclexStyleQuestions,
  },
  {
    value: EQuestionTypes.NGN_CASE_STUDY,
    label: ngnCaseStudies,
  },
];

// Handle if a user goes on this URL without the quiz being finished & loading
// Handle if a user goes on this URL directly to view the past result of a quiz
const Answers = () => {
  const quizState = useSelector(quizSelectors.allState);
  const { entities: allTopics } = useSelector(topicsSelectors.allState);
  const { currentAttempt, loading, query } = useInitResults();
  const questions = currentAttempt?.questionsTransformed || quizState.questions || {};
  const questionAnswers = currentAttempt?.questionAnswers || quizState.questionAnswers || {};

  const getFilterTopicIds = () => {
    const inputTopicIds = query?.get('topicIds');
    if (!inputTopicIds) return null;
    const topicIds = inputTopicIds.split(',');
    return topicIds;
  };

  const filteredTopicIds = getFilterTopicIds();

  const getInitialFilterChoice = () => {
    const questionKeys = Object.keys(questionAnswers);
    if (questionKeys.length === 0) return EQuestionTypes.NCLEX_STYLE_QUESTIONS;
    const isAllCaseStudy = questionKeys.every(questionKey => questionAnswers[questionKey].subAnswers);
    return isAllCaseStudy ? EQuestionTypes.NGN_CASE_STUDY : EQuestionTypes.NCLEX_STYLE_QUESTIONS;
  };
  const { transformingAttempt } = quizState;
  const { dispatch } = useDispatch(SLICE_NAME);
  const [filter, setFilter] = useState<EFilter>(EFilter.ALL_QUESTIONS);

  const [questionType, setQuestionType] = useState<EQuestionTypes>(getInitialFilterChoice());
  const resultsRef = useRef() as MutableRefObject<HTMLDivElement>;
  const history = useHistory();
  const caseStudies = Object.keys(questions).filter(questionId => {
    const initialCondition = questions[questionId].questionType === EQuestionTypes.NGN_CASE_STUDY;
    if (!filteredTopicIds) return initialCondition;
    return initialCondition && questions[questionId].topicsIds?.some(topicId => filteredTopicIds.includes(topicId));
  });
  const normalCaseStudies = caseStudies.filter(caseStudy => !SINGLE_QUESTION_CASE_STUDIES.includes(caseStudy));
  const bowTies = caseStudies.filter(caseStudy => SINGLE_QUESTION_CASE_STUDIES.includes(caseStudy));
  const [currentCaseStudy, setCurrentCaseStudy] = useState(caseStudies[0]);

  const isQuestionTypeChanged = useRef(false);

  useEffect(() => {
    if (isQuestionTypeChanged.current) return;
    const nextQuestionType = getInitialFilterChoice();
    if (nextQuestionType !== questionType) {
      setQuestionType(nextQuestionType);
      isQuestionTypeChanged.current = true;
    }
  }, [questionAnswers]);
  useEffect(() => {
    if (!currentCaseStudy) setCurrentCaseStudy(caseStudies[0]);
  }, [caseStudies]);

  useEffect(() => {
    if (Object.keys(allTopics).length === 0) dispatch(globalActions.topicsIndexRequest({ subjectID: subject.id }));
  }, []);

  const onChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value as EFilter);
    resultsRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onChangeQuestionType = (event: ChangeEvent<HTMLInputElement>) => {
    isQuestionTypeChanged.current = true;
    setQuestionType(event.target.value as EQuestionTypes);
  };

  const renderChoices = () =>
    choices.map(choice => (
      <Choice key={choice.value}>
        <Radio onChange={onChangeFilter} value={choice.value} checked={choice.value === filter} />{' '}
        <Text>{choice.label}</Text>
      </Choice>
    ));

  const renderQuestionTypes = () =>
    questionTypesChoices.map(type => (
      <Choice key={type.value}>
        <Radio onChange={onChangeQuestionType} value={type.value} checked={type.value === questionType} />{' '}
        <Text>{type.label}</Text>
      </Choice>
    ));

  const isCorrect = (parsedMark?: ILooseObject) => {
    return parsedMark && parsedMark.mark !== undefined && parsedMark.mark === parsedMark.maximumMark;
  };

  const filterQuiz = () => {
    const questionsFilteredUserSelection = Object.values(questions).filter((question: IQuestion) =>
      [EQuestionTypes.ARITHMETIC, EQuestionTypes.MULTIPLE_CHOICE].includes(question.questionType),
    );

    const filteredQuestions = filteredTopicIds
      ? questionsFilteredUserSelection.filter(question =>
          question.topicsIds?.some(questionTopic => filteredTopicIds.includes(questionTopic)),
        )
      : questionsFilteredUserSelection;

    if (filter === EFilter.ALL_QUESTIONS)
      return filteredQuestions.filter((question: IQuestion) => questionAnswers[question.id]) || [];

    const correctCondition = filter === EFilter.CORRECT_ANSWERS;

    return filteredQuestions.filter(
      question => isCorrect(questionAnswers[question.id]?.parsedMark) === correctCondition,
    );
  };

  const isSubquestionRendered = (question: ICaseStudyQuestions) => {
    if (filter === EFilter.ALL_QUESTIONS) return !!questionAnswers[currentCaseStudy]?.subAnswers?.[question.id]?.value;
    const correctCondition = filter === EFilter.CORRECT_ANSWERS;
    return isCorrect(questionAnswers[currentCaseStudy]?.subAnswers?.[question.id]?.parsedMark) === correctCondition;
  };

  const filterCaseStudy = () => {
    const caseStudy = (questions[currentCaseStudy] as unknown) as ICaseStudy;
    if (filter === EFilter.ALL_QUESTIONS)
      return (
        caseStudy?.questions?.filter(question => questionAnswers[currentCaseStudy]?.subAnswers?.[question.id]?.value) ||
        []
      );
    const correctCondition = filter === EFilter.CORRECT_ANSWERS;
    return (
      caseStudy?.questions?.filter(
        question =>
          isCorrect(questionAnswers[currentCaseStudy]?.subAnswers?.[question.id]?.parsedMark) === correctCondition,
      ) || []
    );
  };

  const getNoQuestionsMessage = () => {
    const quizQuestions = Object.values(questions) || [];
    if (!quizQuestions.length) return noQuestions;

    const notFoundFilterSet = () => {
      const isAllCaseStudies = quizQuestions.length === caseStudies.length;
      return (
        (questionType === EQuestionTypes.NGN_CASE_STUDY && caseStudies.length === 0) ||
        (questionType === EQuestionTypes.NCLEX_STYLE_QUESTIONS && isAllCaseStudies)
      );
    };
    if (notFoundFilterSet()) return noQuestions;
    switch (filter) {
      case EFilter.ALL_QUESTIONS:
        return noQuestions;
      case EFilter.WRONG_ANSWERS:
        return noWrongAnswers;
      case EFilter.CORRECT_ANSWERS:
        return noCorrectAnswers;
    }
  };

  const renderQuestions = () => {
    if (loading || transformingAttempt) return <Spinner loading size="60px"></Spinner>;
    const filteredQuiz = filterQuiz();
    if (!filteredQuiz.length || !Object.keys(questionAnswers).length)
      return (
        <MessageContainer>
          <SecondaryTitleText>{getNoQuestionsMessage()}</SecondaryTitleText>
        </MessageContainer>
      );
    return filteredQuiz.map((question: IQuestion) => {
      const questionMark = questionAnswers[question.id].parsedMark?.mark;
      const questionMaxMark = questionAnswers[question.id].parsedMark?.maximumMark;
      const isMultiple =
        (question.content.answers?.length || 0) > 1 || SINGLE_CHOICE_SELECT_ALL_QUESTIONS.includes(question.id);

      const getMarkingExplanation = () => {
        if (question?.questionType === EQuestionTypes.ARITHMETIC) return markingExplanations.arithmetic;
        return isMultiple ? markingExplanations.multipleChoiceSelectAll : markingExplanations.multipleChoice;
      };
      return (
        <div key={question.id}>
          <Question
            styleOverrides={{ marginBottom: getTheme().spacing(5) }}
            question={question}
            showExplanations
            answer={questionAnswers[question.id]}
            useOpacityAnimation
          >
            <QuestionHeader>
              <SmallText>
                {questionText} <BoldText>{questionAnswers[question.id].index + 1}</BoldText> {of}{' '}
                <BoldText>{currentAttempt?.questions.length}</BoldText>
              </SmallText>
              {questionMark !== undefined && (
                <MarksHeader>
                  <SmallText>
                    <BoldText>{questionMark}</BoldText> {of} <BoldText>{questionMaxMark}</BoldText>{' '}
                    {questionMaxMark === 1 ? mark : marks}
                  </SmallText>
                  <CustomTooltip
                    enterTouchDelay={0}
                    disableFocusListener
                    width="100%"
                    arrow={false}
                    title={parse(getMarkingExplanation())}
                    placement="top"
                  >
                    <InfoIcon />
                  </CustomTooltip>
                </MarksHeader>
              )}
            </QuestionHeader>
            {renderTags(question)}
          </Question>
        </div>
      );
    });
  };

  const renderQuestionsContainer = () => <QuestionsContainer ref={resultsRef}>{renderQuestions()}</QuestionsContainer>;

  const getCaseStudyLabel = (caseStudy: string) => {
    let index = bowTies.indexOf(caseStudy);
    if (index > -1) return `${bowTie} ${index + 1}`;
    index = normalCaseStudies.indexOf(caseStudy);
    return `${ngnCaseStudy} ${index + 1}`;
  };

  const renderCaseStudies = () => {
    const caseStudy = (questions[currentCaseStudy] as unknown) as ICaseStudy;

    if (!caseStudy)
      return (
        <CaseStudyContainer ref={resultsRef} isPastQuiz={false}>
          <MessageContainer>
            <SecondaryTitleText>{getNoQuestionsMessage()}</SecondaryTitleText>
          </MessageContainer>
        </CaseStudyContainer>
      );
    return (
      <CaseStudyContainer ref={resultsRef} isPastQuiz={false}>
        <CaseStudySelection>
          <div>{selectCaseStudy}</div>
          <DropDown
            fieldKey="select-case-study"
            value={currentCaseStudy}
            options={caseStudies.map(caseStudy => ({
              label: getCaseStudyLabel(caseStudy),
              value: caseStudy,
            }))}
            onChange={value => setCurrentCaseStudy(value as string)}
            size="small"
          />
        </CaseStudySelection>
        {filterCaseStudy().length === 0 && (
          <MessageContainer>
            <SecondaryTitleText>{getNoQuestionsMessage()}</SecondaryTitleText>
          </MessageContainer>
        )}
        {caseStudy.questions.map((question, index) => {
          if (isSubquestionRendered(question))
            return (
              <SubquestionContainer>
                <CaseStudy
                  caseStudy={caseStudy}
                  currentQuestionIndex={index}
                  answerSubquestion={() => {}}
                  answers={questionAnswers[currentCaseStudy]}
                  tags={renderTags(caseStudy)}
                  isReviewingCaseStudy
                  isStarted
                  isViewingResults
                />
              </SubquestionContainer>
            );
          else return null;
        })}
      </CaseStudyContainer>
    );
  };

  const ResultsContainerComponent = ResultsContainer;

  const renderTags = (question: IQuestion | ICaseStudy) => {
    const topicNames = question?.topicsIds ? [question.topicsIds[question.topicsIds.length - 1]] : [];
    const topicsNames = topicNames.map(id => allTopics?.[id]?.name).filter(name => name);
    return (
      <TagsContainer>
        {topicsNames?.slice(0, 3).map(name => (
          <Tag variant="outlined">{name}</Tag>
        ))}
      </TagsContainer>
    );
  };

  const renderTitle = () => {
    if (!currentAttempt?.title) return reviewAnswers;

    return `${reviewAnswersFor} ${currentAttempt.title}`;
  };
  useKeyDownPress('Backspace', () => history.goBack());
  return (
    <>
      <Banner>
        <BackButton onClick={() => history.goBack()} text={backToQuizResults} />
        <TitleText>{renderTitle()}</TitleText>
        <ChoicesContainer>
          <Text>{questionTypes}</Text>
          {renderQuestionTypes()}
        </ChoicesContainer>
        <ChoicesContainer>
          <Text>{filterQuestions}</Text>
          {renderChoices()}
        </ChoicesContainer>
      </Banner>
      <ResultsContainerComponent>
        {questionType === EQuestionTypes.NCLEX_STYLE_QUESTIONS && renderQuestionsContainer()}
        {questionType === EQuestionTypes.NGN_CASE_STUDY && renderCaseStudies()}
      </ResultsContainerComponent>
    </>
  );
};

export default Answers;
