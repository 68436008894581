import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import OrbotAvatar from './v2/components/OrbotAvatar';
import { dashboardPath, onBoardingPath, nurseGPTPath } from './v2/Router/paths';
import homeSVG from './images/icons/dashboard/home.svg';
import nurseGPTSVG from './images/nurseGPT.svg';
import { useAuthorization, useDispatch, useMembershipProxy, useNavigations } from './v2/hooks';
import ProfileMenu from './v2/Header/ProfileMenu';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledToolbar, ItemContainer, LogoHamburgerIconContainer } from './v2/Header/styled';
import { actions } from './v2/redux/globalSlice';
import { isInternalUser } from './v2/utils';
import NavHeader from './v2/Header/NavHeader';

import { useMediaQuery, useTheme } from '@mui/material';
import { NavHeaderContainer } from './v2/Home/styled';

interface IHeader {
  isAuthorized?: Boolean;
  isLoggedIn?: Boolean;
}
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#F9F9FB',
  color: theme.palette.text.primary,
  border: 'none',
  width: '100vw',
}));

interface MenuItemsData {
  icon: React.ReactElement;
  label: string;
  onClick: React.MouseEventHandler<HTMLLIElement> | undefined;
  showOnHeader: boolean;
  showInMenu: boolean;
}

function MenuItemCreator({ icon, label, onClick }: MenuItemsData): JSX.Element {
  return (
    <MenuItem key={label} onClick={onClick} sx={{ height: '42px', py: 1, fontWeight: 700, fontSize: '0.875rem' }}>
      <IconButton aria-label={label} color="inherit" sx={{ mr: 1, height: '0.875rem', width: '0.875rem' }}>
        {icon}
      </IconButton>
      <p>{label}</p>
    </MenuItem>
  );
}

const Header = ({ isAuthorized, isLoggedIn }: IHeader) => {
  const history = useHistory();
  const { logout, state } = useAuthorization();
  const { user, isFullyOnboarded } = state;
  const { headerBanner } = useMembershipProxy();
  const { dispatch } = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xmd'));

  const isDashboard = location.pathname.includes(dashboardPath) || (location.pathname == '/' && isAuthorized);
  const isNurseGpt = location.pathname.includes(nurseGPTPath);
  const isPlacement = location.pathname.includes('on-boarding') || location.pathname.includes('placement');

  const menuItemsData: MenuItemsData[] = [
    {
      icon: <img src={homeSVG} />,
      label: 'Home',
      onClick: () => {
        if (isPlacement) return;
        history.push(dashboardPath);
      },
      showOnHeader: !isPlacement,
      showInMenu: false,
    },
    // {
    //   icon: <img height={24} width={24} src={nurseGPTSVG} />,
    //   label: 'NurseGPT',
    //   onClick: () => {
    //     if (isPlacement) return;
    //     history.push(nurseGPTPath);
    //   },
    //   showOnHeader: !isPlacement && Boolean(isInternalUser(user?.attributes?.email)) && !isNurseGpt,
    //   showInMenu: false,
    // },
  ];

  const headerMenuItems = menuItemsData.filter(item => item.showOnHeader).map(MenuItemCreator);

  const stickyHeader = { ...((isDashboard || isNurseGpt) && { position: 'sticky', top: '0', zIndex: 6 }) };

  const toggleDrawer = () => {
    dispatch(actions.toggleSideBar({}));
  };

  if (!isAuthorized) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar position="relative" variant="outlined" elevation={0} sx={{ height: '64px', zIndex: 6 }}>
          <StyledToolbar>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ pr: 0, mr: 0, background: 'none !important' }}
              disableRipple
            >
              <OrbotAvatar />
            </IconButton>
            {isLoggedIn && !isPlacement && (
              <Box>
                <ProfileMenu onlyLogout logout={logout} userName={user?.attributes?.name} />
              </Box>
            )}
          </StyledToolbar>
        </StyledAppBar>
      </Box>
    );
  }

  return (
    <>
      {history.location.pathname !== onBoardingPath && headerBanner.run()}
      <Box sx={{ flexGrow: 1, ...stickyHeader }}>
        <StyledAppBar
          position="relative"
          variant="outlined"
          elevation={0}
          sx={{ height: '64px', zIndex: 6, borderBottom: '1px solid #D1CBF6' }}
        >
          <StyledToolbar>
            <LogoHamburgerIconContainer>
              {isDashboard && (
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    pr: 0,
                    mr: 1.5,
                    background: 'none !important',
                    display: { xs: 'inline-flex', lg: 'none' },
                    svg: { width: '1.4em', height: '1.4em' },
                  }}
                  disableRipple
                >
                  <MenuIcon />
                </IconButton>
              )}

              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="open dashboard"
                sx={{
                  pr: 0,
                  mr: 0,
                  background: 'none !important',
                  flexGrow: {
                    xs: isDashboard ? 1 : 0,
                    lg: 0,
                  },
                }}
                disableRipple
              >
                <OrbotAvatar />
              </IconButton>
            </LogoHamburgerIconContainer>

            <ItemContainer>
              {/* <Box sx={{ marginRight: '20px !important', display: { xs: 'none', lg: 'flex' } }}>{headerMenuItems}</Box> */}

              {!isMobile && !isPlacement && <NavHeader />}
              <Box>
                <ProfileMenu logout={logout} userName={user?.attributes?.name} isFullyOnboarded={isFullyOnboarded} />
              </Box>
            </ItemContainer>
          </StyledToolbar>
        </StyledAppBar>
        {history.location.pathname === dashboardPath && !isMobile && (
          <NavHeaderContainer>
            <NavHeader variant="secondary" />
          </NavHeaderContainer>
        )}
      </Box>
    </>
  );
};

export default Header;
