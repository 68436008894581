import { en } from '../../../i18n';
import { membershipPath } from '../../Router/paths';
import Button from '../Button';
import SmartNText from '../SmartnText';
import TrialTimer from './Timer';
import useTrialTimer from './useTrialTimer';
import parse from 'html-react-parser';

interface ITimerText {
  useRefreshSubscriptions: boolean;
}

const {
  defaultTrialText,
  defaultEnding,
  ending,
  freeBannerButtonText,
  endsIn,
  ended,
  freeBannerAfterButtonText,
} = en.header;

const TimerText = ({ useRefreshSubscriptions = true }: ITimerText) => {
  const { renderer, finalDate, hasPeriodEnd, isTrialEnded, isVisible } = useTrialTimer({ useRefreshSubscriptions });
  if (!isVisible) return null;

  if (hasPeriodEnd)
    return (
      <>
        {defaultTrialText}&nbsp;
        <SmartNText>Aspire</SmartNText>&nbsp;Trial&nbsp;
        {isTrialEnded ? ended : endsIn}{' '}
        <TrialTimer date={finalDate} renderer={renderer} useRefreshSubscriptions={false} />
        .&nbsp;
        <Button link={membershipPath} variant="underlined">
          {freeBannerButtonText}
        </Button>
        &nbsp;
        {freeBannerAfterButtonText}
      </>
    );

  return (
    <>
      {parse(defaultTrialText)}&nbsp;
      <SmartNText>Aspire</SmartNText>
      &nbsp;
      <Button link={membershipPath} variant="underlined">
        {freeBannerButtonText}
      </Button>
      &nbsp;
      {freeBannerAfterButtonText}
    </>
  );
};

export default TimerText;
