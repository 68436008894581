import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  useAuthorization,
  useDispatch,
  useError,
  useVariableKeyDownPress,
  useSubscriptions,
  useSeoTags,
} from '../hooks';
import {
  HARD_CODED_QUIZ_ID,
  roleQuestionId,
  stepsV2,
  TContainerMapping,
  TComponentsMapping,
  mainSteps,
} from './constants';
import { PageContainer, RadialBackground } from '../Auth/styled';
import {
  FormPopup,
  QuestionContainer,
  Footer,
  AuthContainerOverride,
  Title,
  Subtitle,
  Hint,
  FieldsContainer,
  ModalContainer,
  FooterWrapper,
  PreferredNameQuestionContainer,
  PreferredNameContentContainer,
  ModalContent,
  PersonalizeContainer,
} from './styled';
import { en } from '../../i18n';

import { useHistory } from 'react-router';
import { actions, onboardingSelectors } from './redux/slice';
import Spinner from '../components/Spinner';
import { quizSelectors } from '../StudySpace/redux/slice';
import useAttempts from '../hooks/useAttempts';
import { dashboardPath } from '../Router/paths';

import { actions as authActions } from '../Auth/redux/slice';
import { EQuestionTypes, ILooseObject } from '../types';
import { Input } from '@mui/material';
import { ReactComponent as Enter } from '../../images/enter.svg';
import DatePicker from '../components/Form/Fields/DatePicker';
import { TFieldValues } from '../components/Form/Fields/types';
import Choices from './Choices';
import { StyledTextField } from '../components/Form/Fields/styled';
import Modal from '../components/Modal';
import parse from 'html-react-parser';
import ChooseAdventure from './ChooseAdventure';
import CustomTooltip from '../components/Tooltip';
import Button from '../components/Button';
import { colors } from '../../themes';
import PreferredName from './PreferredName';
import Ready from './Ready';
import { ReadyQuestionContainer } from './Ready/styled';
import UserRolesTransformer from '../classes/DataTransformers/UserRolesTransformer';
import RadioChoices from './RadioChoices';

const { onBoarding } = en;
const {
  disabledNoAnswer,
  next,
  previous,
  skip,
  skipForNow,
  footerHint,
  hintFirstPart,
  hintSecondPart,
  standby,
  personalizing,
  location,
} = onBoarding;

const containerMapping: TContainerMapping = {
  PreferredNameQuestionContainer: PreferredNameQuestionContainer,
  ReadyQuestionContainer,
};

const componentMapping: TComponentsMapping = {
  PreferredName,
  Ready,
};

const OnBoarding = () => {
  const { loadingQuestion, loading, questionsTransformed: questions, attempt } = useSelector(
    onboardingSelectors.allState,
  );
  const {
    state: { user, isOnboarded, isFullyOnboarded },
  } = useAuthorization();
  const {
    attempts,
    loadingAttempts,
    onBoardingAttempts,
    loadingOnBoardingAttempts,
    loadingPlacementAttempts,
  } = useSelector(quizSelectors.allState);
  const { renderError, isError } = useError({ selector: onboardingSelectors.allState, errorPosition: 'topOfForm' });
  const { dispatch } = useDispatch();
  const history = useHistory();
  const [formState, setFormState] = useState<(string | string[] | ILooseObject)[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isMovingForward, setIsMovingForward] = useState(true);
  const seo = useSeoTags({ pageName: 'onboarding' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { fetchSubscriptionPolling, loading: loadingSubscriptions } = useSubscriptions({
    useFetchSubscriptions: false,
  });

  const getCurrentRole = (): string => {
    const roleStep = mainSteps.findIndex(step => step.onboardingQuestionId === roleQuestionId);
    if (roleStep > -1) {
      return formState[roleStep] as string;
    }
    return '';
  };

  const role = getCurrentRole();

  const roleEnum = new UserRolesTransformer().transform(role || '');
  const steps = stepsV2(roleEnum);

  const { currentOnBoardingTest } = useAttempts();

  const currentAttempt = currentOnBoardingTest || attempt;

  const currentDetails = steps[currentStep];

  useEffect(() => {
    fetchSubscriptionPolling();
  }, []);

  useEffect(() => {
    if (isOnboarded) history.push(dashboardPath);
    if (isOnboarded && !isFullyOnboarded) {
      const newFormState: (string | string[] | ILooseObject)[] = [];
      currentOnBoardingTest?.questions.forEach(question => {
        const stepIndex = steps.findIndex(step => step.onboardingQuestionId === question.questionId);
        if (question.response && JSON.parse(question.response?.toString()) !== '_') {
          newFormState[stepIndex] = JSON.parse(question.response?.toString() || '""');
        }
      });
      setCurrentStep(newFormState.length);
      setFormState(newFormState);
    }
  }, [currentAttempt]);

  useEffect(() => {
    if (loadingAttempts || loadingOnBoardingAttempts) return;
    if (!currentOnBoardingTest) {
      dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID, userId: user?.attributes?.sub || '' }));
    } else {
      if (attempt) dispatch(actions.attemptQuizSuccess({ attempt }));
    }
  }, [attempts, onBoardingAttempts, currentOnBoardingTest]);

  useEffect(() => {
    if (currentAttempt) dispatch(actions.transformAttemptRequest({ attempt: currentAttempt }));
  }, [currentAttempt]);

  useEffect(() => {
    if (!currentDetails.dependsOn) return;
    const skip = isMovingForward ? goNext : goPrevious;
    const dependeeChoice = formState[currentDetails.dependsOn.step];
    const requiredChoice = currentDetails.dependsOn.choice;
    const isDependeeArray = Array.isArray(dependeeChoice);
    const isRequiredArray = Array.isArray(requiredChoice);
    const dependeeContainsRequired =
      (isDependeeArray && !isRequiredArray && dependeeChoice.includes(requiredChoice)) ||
      (isDependeeArray && isRequiredArray && requiredChoice.some(choice => dependeeChoice.includes(choice)));
    const dependeeEqualsRequired =
      (!isDependeeArray && !isRequiredArray && dependeeChoice === requiredChoice) ||
      (!isDependeeArray && isRequiredArray && requiredChoice.includes(dependeeChoice));
    if (!dependeeContainsRequired && !dependeeEqualsRequired) skip();
  }, [currentStep]);

  // Dispatch answer to question here
  const onSubmit = (isSkipping?: boolean, data?: typeof formState) => {
    if (!currentAttempt) return;
    const questionIds = [];
    const answers = [];
    const upperBound = isSkipping ? currentStep : currentStep + 1;
    const dataSource = data || formState;
    for (let i = 0; i < upperBound; i++) {
      const questionId = steps[i].onboardingQuestionId;
      if (questionId) {
        questionIds.push(questionId);
        answers.push(dataSource[i]);
      }
    }

    const roleStep = steps.findIndex(step => step.onboardingQuestionId === roleQuestionId);
    if (roleStep > -1) {
      const roleAnswer = dataSource[roleStep];
      dispatch(authActions.setLiveOnboardingRole({ role: `${roleAnswer}` }));
    }

    dispatch(
      actions.submitAnswers({
        questionIds,
        history,
        attempt: currentAttempt,
        answers,
        isSkipping,
      }),
    );
  };

  const goNext = () => {
    setIsMovingForward(true);
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    else onSubmit();
  };

  const goPrevious = () => {
    setIsMovingForward(false);
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  const getDisabled = () => {
    const currentValue = formState[currentStep];
    const noValue = !currentValue;
    const isEmptyArray = Array.isArray(currentValue) && !(currentValue as string[])[0];
    const isMultipleFields =
      questions?.[currentDetails.onboardingQuestionId || '']?.content?.question.content.text?.[0] === '[';
    const fields =
      isMultipleFields &&
      JSON.parse(questions?.[currentDetails.onboardingQuestionId || '']?.content?.question.content.text || '[]');
    const isMissingFields =
      typeof currentValue === 'object' &&
      !Array.isArray(currentValue) &&
      !fields.every((field: ILooseObject) => !!(currentValue as ILooseObject)[field.name]);
    return (noValue || isEmptyArray || isMissingFields) && !currentDetails.skipValidation;
  };

  const disabled = getDisabled();

  const renderButtons = () => {
    const { showFooterHint, hidePrevious, nextButtonText, showSkip } = currentDetails;
    const nextButton = (
      <Button onClick={goNext} disabled={disabled} size="medium" variant="primary">
        {nextButtonText || next}
      </Button>
    );

    const prevButton = (
      <Button onClick={goPrevious} size="medium" variant="secondary">
        {previous}
      </Button>
    );
    //() => onSubmit(true)
    const skipButton = (
      <Button onClick={goNext} size="medium" variant="underlined">
        {isMobile ? skip : skipForNow}
      </Button>
    );

    return (
      <>
        {currentStep > 0 && !hidePrevious && prevButton}
        {disabled ? (
          <CustomTooltip
            leftPopper="-7px"
            centerText
            width="100%"
            arrow={false}
            title={disabledNoAnswer}
            placement="top"
          >
            <span>{nextButton}</span>
          </CustomTooltip>
        ) : (
          nextButton
        )}
        {showFooterHint && (
          <span>
            {footerHint} <Enter />
          </span>
        )}
        {showSkip && skipButton}
      </>
    );
  };

  useVariableKeyDownPress('Enter', () => {
    if (!disabled) {
      goNext();
    }
  });

  const isLoadingAll =
    loadingQuestion ||
    loadingAttempts ||
    !questions ||
    loadingSubscriptions ||
    loadingOnBoardingAttempts ||
    loadingPlacementAttempts;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFormState = [...formState];
    newFormState[currentStep] = e.target.value;
    setFormState(newFormState);
  };

  const onMultipleInputChange = (name: string, value: string) => {
    const newFormState = [...formState];
    newFormState[currentStep] = { ...(newFormState[currentStep] as ILooseObject), [name]: value };
    setFormState(newFormState);
  };

  const onDateChange = (date: TFieldValues) => {
    const newFormState = [...formState];
    newFormState[currentStep] = date.toString();
    setFormState(newFormState);
  };

  const onSelectionChange = (id: string) => {
    const newFormState = [...formState];
    newFormState[currentStep] = id;
    setFormState(newFormState);
  };

  const onMultipleSelectionChange = (id: string) => {
    const { limit } = currentDetails;
    const newFormState = [...formState];
    if (newFormState[currentStep]?.includes(id))
      (newFormState[currentStep] as string[]).splice(newFormState[currentStep].indexOf(id), 1);
    else if (!limit || (newFormState[currentStep] || []).length < limit)
      newFormState[currentStep] ? (newFormState[currentStep] as string[]).push(id) : (newFormState[currentStep] = [id]);
    setFormState(newFormState);
  };

  const clearMultipleSelection = () => {
    const newFormState = [...formState];
    newFormState[currentStep] = [];
    setFormState(newFormState);
  };

  const onReady = (value: any) => {
    const newFormState = [...formState];
    newFormState[currentStep] = value;
    setFormState(newFormState);
    if (currentStep === steps.length - 1) onSubmit(false, newFormState);
    else goNext();
  };

  const getChoices = () => {
    const { onboardingQuestionId, choicesIcons } = currentDetails;
    return questions?.[onboardingQuestionId || ''].content.choices?.map(choice => ({
      id: choice.id,
      content: choice.content.choice?.content.text || '',
      icon: choicesIcons?.[choice.content.explanation?.content.text || ''],
    }));
  };

  const renderQuestion = () => {
    const { onboardingQuestionId, isMultiple, isTopicsChoices, limit, questionType, hideAnswer } = currentDetails;
    const isDate = questions?.[onboardingQuestionId || '']?.content?.choices?.[0]?.content.answer?.type === 'date';
    const isMultipleFields = questions?.[onboardingQuestionId || '']?.content?.question.content.text?.[0] === '[';
    const type = questionType || questions?.[onboardingQuestionId || '']?.questionType;
    const hint = questions?.[onboardingQuestionId || '']?.content.hint || '';
    if (hideAnswer) return null;
    if (isDate)
      return (
        <DatePicker
          fullWidth
          value={formState[currentStep]}
          onChange={date => {
            onDateChange(date);
          }}
          fieldKey="date-picker"
          useModal
          variant="mui"
        />
      );
    if (isMultipleFields) {
      const fields = JSON.parse(questions?.[onboardingQuestionId || '']?.content?.question.content.text || '{}');
      return (
        <FieldsContainer>
          {fields?.map((field: ILooseObject) => (
            <StyledTextField
              label={field.label}
              onChange={e => onMultipleInputChange(field.name, e.target.value)}
              value={(formState[currentStep] as ILooseObject)?.[field.name]}
              required
              size="small"
              fullWidth={field.fullWidth}
              {...(field.name === 'email' && { type: 'email' })}
            />
          ))}
        </FieldsContainer>
      );
    }
    if (type === EQuestionTypes.SHORT_ANSWER)
      return (
        <Input placeholder={hint.toString()} disableUnderline onChange={onInputChange} value={formState[currentStep]} />
      );
    if (type === EQuestionTypes.MULTIPLE_CHOICE) {
      return (
        <>
          <Choices
            choices={getChoices() || []}
            onChange={onSelectionChange}
            onMultipleChange={onMultipleSelectionChange}
            value={formState[currentStep] as string | string[]}
            isMultiple={isMultiple}
            isTopicsChoices={isTopicsChoices}
            limit={limit}
            clearMultipleSelection={clearMultipleSelection}
          />
        </>
      );
    }

    if (type === 'radioChoices') {
      return (
        <RadioChoices
          onSelectionChange={onSelectionChange}
          value={formState[currentStep] as string}
          question={questions?.[onboardingQuestionId || '']}
        />
      );
    }

    return;
  };

  const renderTitle = () => {
    const { title, onboardingQuestionId, isLargeTitle } = currentDetails;
    const titleText = title || questions?.[onboardingQuestionId || ''].content.question.content.text;
    const replacedTitle = titleText?.replace('{firstName}', formState[0]?.toString());
    const parsedTitle = parse(replacedTitle || '');
    return <Title isLargeTitle={isLargeTitle}>{parsedTitle}</Title>;
  };

  const renderSubtitle = () => {
    const { isSubtitleHighlighted, onboardingQuestionId, subtitle, isSubtitlePushed } = currentDetails;
    const subtitleText = subtitle || questions?.[onboardingQuestionId || '']?.content?.explanation;
    if (!subtitleText) return;
    return (
      <Subtitle isSubtitleHighlighted={isSubtitleHighlighted} isSubtitlePushed={isSubtitlePushed}>
        {parse(subtitleText?.toString() || '')}
      </Subtitle>
    );
  };

  const renderHint = () => {
    const { showHint } = currentDetails;
    if (!showHint) return;
    return (
      <Hint>
        {hintFirstPart} <Enter /> {hintSecondPart}
      </Hint>
    );
  };

  const renderStep = () => {
    if (isLoadingAll) return <Spinner loading={!isError} appplyBackground size="48px" />;
    const getComponent = () => {
      const componentName = currentDetails.questionComponent;
      if (componentName) return componentMapping[componentName];
      return null;
    };
    const Component = getComponent();
    if (Component)
      return (
        <>
          <Component
            currentStep={currentDetails}
            questions={questions}
            onReady={onReady}
            isError={isError}
            role={roleEnum}
          />
          {renderQuestion()}
        </>
      );
    if (currentDetails.isLargeWidth) {
      return (
        <PreferredNameContentContainer>
          {renderTitle()}
          {renderSubtitle()}
          {renderQuestion()}
        </PreferredNameContentContainer>
      );
    }
    return (
      <>
        {renderTitle()}
        {renderSubtitle()}
        {renderQuestion()}
      </>
    );
  };

  const renderModalContent = () => {
    return (
      <ModalContainer>
        <h3>{standby}</h3>
        <ModalContent>
          {role === 'underGradStudent' && <div>{parse(location)}</div>}
          <PersonalizeContainer>
            <p>{personalizing}</p>
            <Spinner loading={true} size="24px" spinnerColor={colors.purple30} />
          </PersonalizeContainer>
        </ModalContent>
      </ModalContainer>
    );
  };

  const renderFormContent = () => {
    const ContainerComponent = currentDetails.containerComponent
      ? containerMapping[currentDetails.containerComponent]
      : QuestionContainer;
    return isOnboarded && isFullyOnboarded ? (
      <FormPopup isChoosingAdventure>
        <ChooseAdventure topics={formState[steps.length - 1] as string[]} />
      </FormPopup>
    ) : (
      <FormPopup>
        {renderError()}
        <ContainerComponent key={currentStep} error={isError}>
          {renderStep()}
        </ContainerComponent>
        {!isLoadingAll && !currentDetails.hideFooter && (
          <FooterWrapper>
            <Footer>
              {renderButtons()}
              {renderHint()}
            </Footer>
          </FooterWrapper>
        )}
      </FormPopup>
    );
  };

  return (
    <PageContainer>
      {seo}
      <Modal id="onboarding-modal" isModalOpen={loading}>
        {renderModalContent()}
      </Modal>
      <RadialBackground>
        <AuthContainerOverride>{renderFormContent()}</AuthContainerOverride>
      </RadialBackground>
    </PageContainer>
  );
};

export default OnBoarding;
