import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { usePageChangeScroll } from './v2/hooks';
import Header from './Header';
import { getTheme } from './themes';
import useInitializeAttempts from './v2/hooks/useInitializeAttempts';
import initHelpHero from 'helphero';
import { appIds } from './constants';
import Domains from './v2/classes/Domains';
import { AppContext } from './AppContext';
import Routes from './v2/Router/Routes';
import { headerHiddenPages } from './v2/Router/paths';
import { BugSnag } from './v2/classes';

const theme = getTheme();
new BugSnag().init();

function App() {
  const isAppInitialized = useRef(false);
  const { isLoggedIn, user, isOnboarded } = useContext(AppContext);

  useInitializeAttempts(isLoggedIn);
  usePageChangeScroll();

  const location = useLocation();
  const isHeaderHidden = Object.keys(headerHiddenPages).reduce((accum, key: string) => {
    if (location.pathname.includes(key)) return true;

    return accum;
  }, false);

  useEffect(() => {
    if (isLoggedIn) {
      if (Domains.isProductionDomain()) {
        if (!isAppInitialized.current) {
          console.log('AppHero Initialized');
          const helpHero = initHelpHero(appIds.helphero);
          helpHero.identify(user?.username || 0);
          isAppInitialized.current = true;
        }
      }

      // reportWebVitals(detail => sendPerfEvents(location.pathname, detail, eventApi));
    }
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      {!isHeaderHidden && <Header isLoggedIn={isLoggedIn} isAuthorized={isLoggedIn && isOnboarded} />}
      <Routes />
    </ThemeProvider>
  );
}

export default App;
