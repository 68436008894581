import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ILooseObject, ICaseStudySubquestion } from '../../../types';
import {
  DragDropClozeContainer,
  DragDropQuestion,
  ClozeContainer,
  ClozeItem,
  ChoiceListTitle,
  DragDropChoiceList,
  ChoiceListContainer,
  DragDropChoiceItem,
} from './styled';
import { en } from '../../../../i18n';
import DroppableArea from './DroppableArea';
import useDragAndDrop from './useDragAndDrop';
import ContentRenderer from './ContentRenderer';

const { and, wordChoices } = en.caseStudy;

const DragDropCloze = ({
  questions,
  answerSubquestion,
  questionId,
  isDone,
  isReviewing,
  correctAnswer,
  answer,
}: {
  questions: ICaseStudySubquestion[];
  answerSubquestion: (answer: ILooseObject, correctAnswer: ILooseObject) => void;
  questionId: string;
  isDone?: boolean;
  isReviewing?: boolean;
  correctAnswer: string[];
  answer: ILooseObject;
}) => {
  let initialState;
  if (isReviewing) {
    initialState = {
      firstCloze: [
        {
          id: correctAnswer[0],
          content: questions[0].detail.options?.find(option => option.id === correctAnswer[0])?.detail.content.detail
            .copy,
        },
      ],
      secondCloze: [
        {
          id: correctAnswer[1],
          content: questions[0].detail.options?.find(option => option.id === correctAnswer[1])?.detail.content.detail
            .copy,
        },
      ],
      list: [],
    };
  } else if (isDone) {
    initialState = {
      firstCloze: [
        {
          id: answer.questions[0].answerIds[0],
          content: questions[0].detail.options?.find(option => option.id === answer.questions[0].answerIds[0])?.detail
            .content.detail.copy,
        },
      ],
      secondCloze: [
        {
          id: answer.questions[0].answerIds[1],
          content: questions[0].detail.options?.find(option => option.id === answer.questions[0].answerIds[1])?.detail
            .content.detail.copy,
        },
      ],
      list: questions[0]?.detail?.options
        ?.filter(option => !answer.questions[0]?.answerIds.includes(option.id))
        .map(option => ({ id: option.id, content: option.detail.content.detail.copy })),
    };
  } else {
    initialState = {
      firstCloze: [],
      secondCloze: [],
      list: questions[0]?.detail?.options?.map(option => ({
        id: option.id,
        content: option.detail.content.detail.copy,
      })),
    };
  }

  const dragCallback = (choices: ILooseObject) => {
    answerSubquestion(
      {
        id: questionId,
        questions: [
          { id: questions[0].id, answerIds: [choices.firstCloze[0]?.id || '', choices.secondCloze[0]?.id || ''] },
        ],
      },
      { id: questionId, questions: [{ id: questions[0].id, answerIds: correctAnswer }] },
    );
  };

  const { onDragEnd, choices } = useDragAndDrop(initialState, dragCallback);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DragDropClozeContainer>
        <DragDropQuestion>
          <ContentRenderer content={questions[0].detail.question} />
        </DragDropQuestion>
        <ClozeContainer>
          <DroppableArea
            droppableId="firstCloze"
            isDropDisabled={!!choices.firstCloze[0]}
            droppableComponent={ClozeItem}
            draggableComponent={DragDropChoiceItem}
            draggableItems={choices.firstCloze}
            isItemDragged={true}
            placeholder={questions[0].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          />
          {and}
          <DroppableArea
            droppableId="secondCloze"
            isDropDisabled={!!choices.secondCloze[0]}
            droppableComponent={ClozeItem}
            draggableComponent={DragDropChoiceItem}
            draggableItems={choices.secondCloze}
            isItemDragged={true}
            placeholder={questions[0].detail.label?.detail.copy}
            isDragDisabled={!!isDone}
          />
        </ClozeContainer>
        {!isReviewing && (
          <ChoiceListContainer>
            <ChoiceListTitle>{wordChoices}</ChoiceListTitle>
            <DroppableArea
              droppableId="list"
              isDropDisabled={false}
              droppableComponent={DragDropChoiceList}
              draggableComponent={DragDropChoiceItem}
              draggableItems={choices.list}
              isItemDragged={false}
              droppableComponentProps={{ count: questions[0]?.detail?.options?.length }}
              isDragDisabled={!!isDone}
            />
          </ChoiceListContainer>
        )}
      </DragDropClozeContainer>
    </DragDropContext>
  );
};

export default DragDropCloze;
