import Confetti from 'react-confetti';
import useWindowSize from '../../hooks/useWindowSize';

interface IConfettiCelebration {
  isShown?: boolean;
}

const ConfettiCelebration = ({ isShown = false }: IConfettiCelebration) => {
  const { width, height } = useWindowSize();

  if (!isShown) return null;
  return <Confetti width={width} height={height} />;
};

export default ConfettiCelebration;
