import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { TitleText, opacityInAnimation } from '../sharedStyled';

const ComingSoonContainer = styled('div')`
  height: calc(100vh - 64px - 10vh);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
`;

const ComingSoonContent = styled('div')`
  ${opacityInAnimation()};
  border-radius: 16px;
  background: ${colors.white};
  padding: 40px;
  box-sizing: border-box;
  width: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  p {
    width: 80%;
    text-align: center;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px;
  }
`;

const ComingSoonTitle = styled(TitleText)`
  //margin-bottom: 24px;
  text-align: center;
`;

export { ComingSoonContainer, ComingSoonContent, ComingSoonTitle };
