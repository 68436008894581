import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { zoomOutAnimation, thinScrollbar, opacityInAnimation, widthInAnimation } from '../sharedStyled';
import { shouldForwardProps } from '../utils';
import { AuthContainer } from '../Auth/styled';

interface IQuestionContainer {
  error?: boolean;
}
interface ISubtitle {
  isSubtitleHighlighted?: boolean;
  isSubtitlePushed?: boolean;
}
interface ITitle {
  isLargeTitle?: boolean;
}
interface IChoice {
  selected?: boolean;
  hasIcon?: boolean;
  isTopicsChoices?: boolean;
  disabled?: boolean;
  count?: number;
}
interface IChoicesContainer {
  isTopicsChoices?: boolean;
}
interface IFormPopup {
  isChoosingAdventure?: boolean;
}
interface ISelectedTag {
  hasIcon?: boolean;
}
const FormPopup = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isChoosingAdventure']),
})<IFormPopup>(
  ({ theme, isChoosingAdventure }) => `
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgb(209, 203, 246));
  background: ${colors.white};
  width: min(1240px, 86vw);
  min-height: min(90vh, 860px);
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  ${theme.breakpoints.down('sm')} {
    min-height: 102vh;
    padding-top: 20px;
    width: 90%;
  }
  ${theme.breakpoints.down('xsm')} {
    min-height: 120vh;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape), {
    min-height: min(85vh, 860px);
  }
  @media only screen and (max-height: 600px) and (orientation: landscape) {
    min-height: min(85vh, 860px);
  }
  overflow-y: hidden;
  border-radius: 16px;
  position: relative;
  ${zoomOutAnimation()}
  ${
    isChoosingAdventure
      ? `
  ${theme.breakpoints.down('xl')} {
    height: auto;
    margin: 40px 0;
  }
  ${theme.breakpoints.down('sm')} {
    height: auto;
  }
  ${theme.breakpoints.down('xsm')} {
    height: auto;
  }
  `
      : ''
  }
`,
);

const QuestionContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['error']),
})<IQuestionContainer>(
  ({ error, theme }) => `
  ${opacityInAnimation()}
  margin: auto;
  ${error ? 'margin-top: 30px;' : ''}
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  flex-grow: 1;
  margin-top:24px;
  margin-bottom:24px;
  .required {
    color: ${colors.red};
    -webkit-text-fill-color: ${colors.red};
    text-fill-color: ${colors.red};
  }
  span {
    color: ${colors.red};
    -webkit-text-fill-color: ${colors.red};
    text-fill-color: ${colors.red};
  }
  > div > input {
    outline: 1px solid ${colors.mediumGray};
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 1rem;
    color: ${colors.black};
    margin-top: 8px;
    ::placeholder {
      color: ${colors.grey400};
      opacity: 1;
    }
    :hover {
      outline: 1px solid ${colors.purple30};
    }
    :focus {
      outline: 2px solid ${colors.purple30};
    }
  }
  input {
    color: ${colors.black10};
  }
  label {
    color: ${colors.grey400};
  }
  label.Mui-focused {
    color: ${colors.black10};
    font-weight: 700;
  }
  ${theme.breakpoints.down('md')} {
    width: 75%;
  }
  ${theme.breakpoints.down('sm')} {
    width: 90%;
  }
`,
);

const PreferredNameQuestionContainer = styled(QuestionContainer)`
  width: 100%;
  input {
    width: 45%;
    margin: auto;
    ${props => props.theme.breakpoints.down('md')} {
      width: 75%;
    }
    ${props => props.theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
`;

const PreferredNameContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 64px;
`;

const Title = styled('h1', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isLargeTitle']),
})<ITitle>(
  ({ isLargeTitle }) => `
  font-family: Poppins !important;
  line-height: 32px;
  font-size: ${isLargeTitle ? '1.5rem' : '1.25rem'};
  background: linear-gradient(103.44deg, #ea20a5 -1.55%, #4a2dff 93.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
  white-space: pre-line;
  font-weight:800;
  text-align:center;
`,
);

// const PreferredNameContainer = styled('div')`
//   font-weight: 800;
// `;

const Subtitle = styled('p', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSubtitleHighlighted']),
})<ISubtitle>(
  ({ isSubtitleHighlighted, isSubtitlePushed }) => `

  ${
    isSubtitleHighlighted
      ? `margin-top: 40px;
         margin-bottom: 8px;
         font-size: 1.125rem;
         font-weight: 700;
  `
      : `margin-top: ${isSubtitlePushed ? '30px' : '8px'};
         margin-bottom: 0;
         font-size: 1rem;
         font-weight: 500;
         line-height: 24px;
         -webkit-font-smoothing: antialiased;`
  }
`,
);

const Hint = styled('div')`
  font-size: 0.875rem;
  padding: 16px 24px;
  background-color: ${colors.mediumLightGray};
  outline-radius: 8px;
  width: 65%;
  line-height: 19px;
  position: absolute;
  top: calc(-100% - 24px);
  margin: auto;
  width: 310px;
  svg {
    width: 14px;
    height: 9.5px;
    margin: 0 8px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 268px;
  }
`;

const Footer = styled('div')`
  //position: absolute;
  height: 64px;
  //bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: ${colors.lightestGrey};
  border-radius: 0px 0px 16px 16px;
  > span:nth-child(2),
  > button:nth-child(2),
  > button:nth-child(3) {
    margin-left: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    ${props => props.theme.breakpoints.down('sm')} {
      margin-left: 8px;
      gap: 8px;
    }
  }
  button:nth-child(3) {
    color: ${colors.errorRed};
    margin: 0;
    position: absolute;
    right: 30px;
    ${props => props.theme.breakpoints.down('sm')} {
      right: 15px;
    }
  }
`;

const AuthContainerOverride = styled(AuthContainer)`
  overflow-x: unset;
  @media only screen and (max-height: 700px) and (orientation: landscape) {
    padding: 30px 0;
  }
`;

const ChoicesContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isTopicsChoices']),
})<IChoicesContainer>(
  ({ isTopicsChoices, theme }) => `
  display: flex;
  gap: ${isTopicsChoices ? '16px' : '24px'};
  margin-top: 32px;
  flex-wrap: wrap;
  ${
    isTopicsChoices
      ? `
    overflow-y: auto;
    ${thinScrollbar}
    overflow-x: hidden;
  `
      : ''
  }
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items:center;
  }
`,
);

const Choice = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['selected, hasIcon', 'isTopicsChoices', 'disabled', 'count']),
})<IChoice>(
  ({ selected, hasIcon, isTopicsChoices, disabled, theme }) => `
  position: relative;
  padding: 13px 15px;
  font-weight: 700;
  box-sizing:border-box;
  color: ${colors.purple500};
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  flex: none;
  width: 142px;
  ${
    hasIcon
      ? `
    padding: 32px 16px;
    font-weight: 500;
    color: ${colors.black10};
    font-size: 0.875rem;
    flex: 1 1 0;
    width: auto;
    :last-child {
      svg {
        margin-top: -16px;
        ${theme.breakpoints.down('sm')} {
          margin-top: 0;
        }
      }
    }
  `
      : ''
  }
  ${
    isTopicsChoices
      ? `
    font-weight: 500;
    font-size: 0.875rem;
    width: calc(33% - 12px);
  `
      : ''
  }
  ${selected ? `color: ${colors.purple500};` : ''}
  background-color: ${selected ? colors.purple1 : colors.white};
  outline: ${selected ? '2px' : '1px'} solid ${colors.purple500};
  ${
    disabled
      ? `
    color: ${colors.mediumGray};
    outline: 1px solid ${colors.mediumGray};
    background-color: ${colors.lightestGrey};
    cursor: pointer;
  `
      : ''
  }
  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${disabled ? colors.mediumGray : colors.purple500};
    -webkit-text-fill-color: ${disabled ? colors.mediumGray : colors.purple500};
    text-fill-color: ${disabled ? colors.mediumGray : colors.purple500};
  }
  svg {
    height: 56px;
  }
  ${theme.breakpoints.down('xmd')} {
    width: 120px;
    ${isTopicsChoices ? 'width: calc(33% - 12px);' : ''}
    ${hasIcon ? 'width: auto;' : ''}
  }
  ${theme.breakpoints.down('md')} {
    width: 138px;
    ${isTopicsChoices ? 'width: calc(33% - 12px);' : ''}
    ${hasIcon ? 'width: auto;' : ''}
  }
  ${theme.breakpoints.down('sm')} {
    width: calc(100% - 63px);
    ${
      hasIcon
        ? `padding: 20px 16px;
           width: calc(100% - 35px);
        `
        : ''
    }
    ${isTopicsChoices ? 'width: calc(100% - 40px)' : ''}
  }
  ${
    isTopicsChoices
      ? `
    outline: none;
    border: ${selected ? '2px' : '1px'} solid ${disabled ? colors.mediumGray : colors.purple500};
    padding: ${selected ? '10px 8px' : '12px 10px'};
  `
      : ''
  }
`,
);

const SelectedTag = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['hasIcon']),
})<ISelectedTag>(
  ({ hasIcon }) => `
  position: absolute;
  font-size: ${hasIcon ? '0.875rem' : '0.75rem'};
  padding: ${hasIcon ? '4px 16px' : '2px 16px'};
  top: 0;
  right: 50%;
  background-color: ${colors.purple40};
  color: ${colors.white};
  border-radius: 4px;
  transform: translate(50%, -50%);
  font-weight: 500;
  -webkit-text-fill-color: ${colors.white};
`,
);

const FieldsContainer = styled('div')`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 32px;
  > div:not(.MuiFormControl-fullWidth) {
    width: calc(50% - 12px);
  }
`;

const ChoicesLimit = styled('div')`
  width: calc(100% - 10px);
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    font-weight: 700;
  }
  div:nth-child(2) {
    font-weight: 500;
    color: ${colors.purple500};
    text-decoration: underline;
    cursor: pointer;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding-right: 5px;
    box-sizing: border-box;
  }
`;

const ModalContainer = styled('div')`
  position: relative;
  box-sizing: border-box;
  width: 400px;
  background-color: ${colors.white};
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  text-align: center;
  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 21.8px;
  }
  p {
    font-size: 1rem;
    color: ${colors.grey500};
  }
  > div {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    div {
      position: static;
    }
    span {
      height: 30px !important;
      width: 30px !important;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 85%;
  }
`;

const ChooseAdventureContainer = styled('div')`
  position: relative;

  height: calc(100% - 64px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 120px;
  gap: 32px;
  h1 {
    width: 70%;
  }

  ${props => props.theme.breakpoints.down('xl')} {
    height: auto;

    padding-bottom: 70px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
    padding-top: 90px;
    h1 {
      width: 90%;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 45px;
  }
`;

const AdventuresContainer = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const ChooseAdventureTitleContainer = styled('div')`
  width: 66%;
  margin: auto;
  display: flex;
  justify-content: center;

  ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
`;

const Adventure = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['selected']),
})<IChoice>(
  ({ theme, selected }) => `
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  outline: 1px solid ${colors.purple500};
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  svg {
    margin-bottom: 16px;
  }
  span:nth-child(1) {
    margin-bottom: 4px;
    font-weight: 700;
  }
  ${
    selected
      ? `
    color: ${colors.purple500};
    outline: 2px solid ${colors.purple500};
    background-color: ${colors.purple1};
  `
      : ''
  }
  ${theme.breakpoints.down('sm')} {
    width: 80%;
  }
`,
);

const Note = styled('div')`
  line-height: 21.8px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: auto;
  span {
    font-weight: 700;
  }
  ${props => props.theme.breakpoints.down('md')} {
    position: static;
  }
`;

const ToolTipContainer = styled('span')``;

const FooterWrapper = styled('div')``;

const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  .purple {
    font-weight: 800;
    color: ${colors.primary};
    text-fill-color: ${colors.primary};
  }
`;

const PersonalizeContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const RadioChoiceItem = styled('div')`
  display: flex;
  margin-bottom: ${props => props.theme.spacing(2.5)};
  gap: ${props => props.theme.spacing(1.25)};
  cursor: pointer;
  align-items: center;
  p {
    font-size: 1rem;
    margin: 0;
  }
  span {
    padding: 0px;
  }
  width: fit-content;
  span {
    color: ${colors.primary};
    text-fill-color: ${colors.primary};
  }
`;

const RadioChoiceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 48px;
  span {
    color: ${colors.black};
    -webkit-text-fill-color: ${colors.black};
    text-fill-color: ${colors.black};
  }
`;
export {
  FormPopup,
  QuestionContainer,
  ChoicesContainer,
  Footer,
  Choice,
  AuthContainerOverride,
  Title,
  Subtitle,
  Hint,
  SelectedTag,
  FieldsContainer,
  ChoicesLimit,
  ModalContainer,
  ChooseAdventureContainer,
  AdventuresContainer,
  Adventure,
  Note,
  ToolTipContainer,
  ChooseAdventureTitleContainer,
  FooterWrapper,
  PreferredNameQuestionContainer,
  PreferredNameContentContainer,
  ModalContent,
  PersonalizeContainer,
  RadioChoiceContainer,
  RadioChoiceItem,
};
