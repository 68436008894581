import { colors } from '../../../../themes';
import { ECaseStudyQuestionTypes, EQuestionTypes } from '../../../types';

type TTableDataKeys =
  | 'id'
  | 'topicName'
  | 'mastery'
  | 'menuButton'
  | 'viewResults'
  | 'questionProgress'
  | 'questionTypes';

const titles = ['topic', 'question analysis', 'mastery level', 'question types'];

type TMobileGroup = {
  title: string;
  value: TTableDataKeys;
  isComponent?: boolean;
  fullWidth?: boolean;
};
const mobileGroups: TMobileGroup[][] = [
  [
    { title: 'topic', value: 'topicName' },
    { title: 'mastery level', value: 'mastery', isComponent: true },
  ],
  [{ title: ' Question Types', value: 'questionTypes', fullWidth: true, isComponent: true }],
  [{ title: 'question analysis', value: 'questionProgress', isComponent: true, fullWidth: true }],
];

const rowKeys: TTableDataKeys[] = ['topicName', 'questionProgress', 'mastery', 'questionTypes'];

type TTableData = {
  [key in TTableDataKeys]: string | React.ReactNode | (() => void);
};

interface ITableRender {
  data: TTableData[];
}

interface IQuestionType {
  backgroundColor: string;
  textColor: string;
  text: string;
}
type TQuestionTypesDisplayMapping = {
  [key in ECaseStudyQuestionTypes | EQuestionTypes]: IQuestionType;
};

type TFallbackQuestionTypesDisplayMapping = {
  default: IQuestionType;
};

const questionTypesDisplayMapping: Partial<TQuestionTypesDisplayMapping> = {
  multipleChoiceV2: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - MCQ',
  },
  matrixSingleChoice: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - Matrix',
  },
  singleChoice: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - MCQ',
  },
  matrixMultipleChoice: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - Matrix',
  },
  multipleHighlight: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - Highlight',
  },
  dropDownCloze: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - Cloze',
  },
  bowTie: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Case Study - Bow Tie',
  },
  multipleChoice: {
    backgroundColor: colors.primary,
    textColor: colors.white,
    text: 'MCQ',
  },
  shortAnswer: {
    backgroundColor: colors.orange40,
    textColor: colors.white,
    text: 'Short Answer',
  },

  arithmetic: {
    backgroundColor: colors.primary,
    textColor: colors.white,
    text: 'Arithmetic',
  },
  // default: {
  //   backgroundColor: colors.black,
  //   textColor: colors.white,
  //   text: 'MCQ',
  // },
};

export { titles, rowKeys, mobileGroups, questionTypesDisplayMapping };
export type { TTableDataKeys, TTableData, ITableRender, TMobileGroup };
