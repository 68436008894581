import { ChangeEvent } from 'react';
import { EQuestionTypes, ESources, IQuestion, IQuestionAnswers } from '../../types';
import { isCorrectAnswer } from '../../utils';
import { QuestionRenderContainer } from './styled';
import TemplaterRenderer from './templates';
import Choices from './Choices';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Explanation from './Explanation';
import QuestionTip from '../../components/QuestionTip';
import ArithmeticAnswer from './ArithmeticAnswer';

interface IStyleOverrides {
  marginBottom?: string;
  padding?: string;
  textAreaMargin?: number;
  marginTop?: string;
}
interface IProps {
  question: IQuestion;
  showExplanations: boolean;
  onAnswer?: (answer: string[] | string) => void;
  answer?: IQuestionAnswers;
  children?: any;
  styleOverrides?: IStyleOverrides;
  isGoingBack?: boolean;
  useOpacityAnimation?: boolean;
}

const Question = ({
  question,
  onAnswer,
  showExplanations,
  answer,
  children,
  isGoingBack,
  styleOverrides = {},
  useOpacityAnimation,
}: IProps) => {
  const { content, questionType } = question;
  const { explanation, choices, answers, answer: arithmeticAnswer, hint } = content;

  // TODO: Move this in it own seperate component
  const onChangeTextArea = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    onAnswer?.(target.value);
  };

  const onChangeArithmetic = (value: string) => {
    onAnswer?.(value);
  };

  const renderAnswerType = () => {
    switch (questionType) {
      case EQuestionTypes.MULTIPLE_CHOICE:
        return (
          <Choices
            questionId={question.id}
            answer={answer}
            showExplanations={showExplanations}
            correctAnswers={answers}
            choices={choices}
            onChoose={onAnswer}
          />
        );

      case EQuestionTypes.ARITHMETIC:
        return (
          <ArithmeticAnswer
            showExplanations={showExplanations}
            correctAnswer={arithmeticAnswer}
            onChange={onChangeArithmetic}
            currentAnswer={answer}
          />
        );

      case EQuestionTypes.NGN_CASE_STUDY:
        return null;

      default:
        return (
          <TextareaAutosize
            key={question.id}
            disabled={showExplanations}
            value={answer?.value}
            onChange={onChangeTextArea}
            minRows={6}
          ></TextareaAutosize>
        );
    }
  };

  const renderExplanations = () => {
    const getAnswers = () => {
      if (questionType === EQuestionTypes.ARITHMETIC) return arithmeticAnswer?.content || '';

      if (!answers) return '';

      const textAnswers = answers
        .flatMap(answer => choices?.find(choice => choice.id === answer)?.content?.choice?.content?.text)
        .filter(answer => answer)
        .join(', ');

      return textAnswers;
    };

    const questionSourceHidExplanation = () => {
      if (!question.source) return true;
      return ![ESources.SAUNDERS, ESources.UWORLD, ESources.YOURIKA].includes(question.source);
    };
    if (!showExplanations || questionSourceHidExplanation()) return null;
    return (
      <Explanation
        isShortAnswer={question.questionType === EQuestionTypes.SHORT_ANSWER}
        isCorrect={isCorrectAnswer(answer?.value || '', questionType, answers)}
        content={explanation}
        answer={getAnswers()}
      />
    );
  };

  return (
    <QuestionRenderContainer
      useOpacityAnimation={useOpacityAnimation}
      key={question.id}
      isGoingBack={isGoingBack}
      {...styleOverrides}
    >
      {children && children}
      {hint && <QuestionTip tip={hint} />}
      <TemplaterRenderer content={content.question} />
      {renderAnswerType()}
      {renderExplanations()}
    </QuestionRenderContainer>
  );
};

export default Question;
