import {
  ApplyContainer,
  ButtonPreText,
  ButtonTextContainer,
  Column,
  ColumnDetail,
  ColumnDetails,
  ColumnsContainer,
  ColumnSubTitle,
  ColumnTitle,
  IconContainer,
  RelativeTitle,
  Title,
  TitlesContainer,
} from './styled';

import { en } from '../../i18n';
import parse from 'html-react-parser';
import { ReactComponent as CheckMarkIcon } from '../../images/check.svg';
import Button from '../components/Button';
import useApply from './useApply';

const { apply } = en;

const Unstarted = () => {
  const { control, state } = useApply({});

  const { startQuiz } = control;
  const { loadingStartQuiz } = state;

  const renderColumns = () => {
    const { columns } = apply;

    return columns.map((column, index) => (
      <Column key={index}>
        <ColumnTitle>
          <IconContainer>
            <CheckMarkIcon />
            <RelativeTitle>
              {column.title}
              <ColumnDetails>
                <ColumnSubTitle>{parse(column.subTitle)}</ColumnSubTitle>
                <ColumnDetail>{parse(column.details)}</ColumnDetail>
              </ColumnDetails>
            </RelativeTitle>
          </IconContainer>
        </ColumnTitle>
      </Column>
    ));
  };

  // <ColumnSubTitle>{column.subTitle}</ColumnSubTitle>
  //       <ColumnDetail>{parse(column.details)}</ColumnDetail>
  return (
    <ApplyContainer>
      <TitlesContainer>
        <Title>{parse(apply.titelA)}</Title>
      </TitlesContainer>

      <ColumnsContainer>{renderColumns()}</ColumnsContainer>
      <ButtonTextContainer>
        <ButtonPreText>{parse(apply.buttonPreText)}</ButtonPreText>
        <Button loading={loadingStartQuiz} onClick={startQuiz} size="large" variant="primary">
          {apply.buttonText}
        </Button>
      </ButtonTextContainer>
    </ApplyContainer>
  );
};

export default Unstarted;
