import { styled } from '@mui/material/styles';
import { shouldForwardProps } from '../../utils';
import { colors } from '../../../themes';

interface ITextSpan {
  fontSize?: string;
}

const TextSpan = styled('span', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['fontSize']),
})<ITextSpan>(
  ({ fontSize, theme }) => `
    font-weight:700;
    color:${colors.primary};
    font-size:${fontSize || 'inherit'};
`,
);

const Apostrophe = styled('span')`
  color: ${colors.smartNApostrophe};
`;

export { TextSpan, Apostrophe };
