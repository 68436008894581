import BaseMembership from './BaseMembership';
import { en } from '../../../i18n';
import { membershipPath } from '../../Router/paths';
import Button from '../../components/Button';
import { EMembershipType } from '../../types';
import CustomTooltip from '../../components/Tooltip';

const { smartQuiz } = en;

class ContinueQuizButtons extends BaseMembership {
  public free() {
    return {
      buttonDisabled: true,
      buttonDisabledText: (
        <Button link={membershipPath} variant="underlinedSpan">
          {smartQuiz.mustUpgradeButton}
          &nbsp;
          <span>{smartQuiz.toContinue}</span>
        </Button>
      ),

      rawText: `${smartQuiz.mustUpgradeButton} ${smartQuiz.toContinue} `,
    };
  }

  public essential() {
    return {
      buttonDisabled: false,
      buttonDisableText: undefined,
    };
  }
  public allAccess() {
    return this.essential();
  }
  public useStrictFreeMembers() {
    return true;
  }

  public addonRequired() {
    const membershipType = this.getMembershipType();
    if (membershipType !== EMembershipType.ESSENTIALS) {
      return this.free();
    }

    const buttonDisableText = (
      <CustomTooltip leftPopper="30px" marginTopPopper="15px" title={smartQuiz.addonText}>
        <span>
          <Button link={membershipPath} variant="underlinedSpan">
            {smartQuiz.addonRequiredButton}
          </Button>
        </span>
      </CustomTooltip>
    );

    return {
      buttonDisabled: true,
      buttonDisableText,
      rawText: smartQuiz.addToContinue,
    };
  }
}

export default ContinueQuizButtons;
