import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { LandingText } from '../../Auth/Signup/styled';
import { shouldForwardProps } from '../../utils';
import Button from '../../components/Button';
import { opacityInAnimation } from '../../sharedStyled';

interface IPlanButtonProps {
  isCurrent: boolean;
}
interface IOuterPrice {
  isRecommended?: boolean;
}

interface IOuterPlanPrices {
  applyTopMargin?: boolean;
}

const LandingContainer = styled('div')`
  background: white;
  box-sizing: border-box;
  width: min(80vw, 1240px);

  box-shadow: 0px 0px 40px #d1cbf6;
  border-radius: 16px;
  display: flex;
  margin: auto;
  transition: margin 10s linear;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    width: min(90vw, 1240px);
  }

  ${opacityInAnimation()};
`;

const PlanContainer = styled('div')`
  height: 100%;
  width: 67%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
  ${props => props.theme.breakpoints.down('md')} {
    width: 53%;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
const PlanContent = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  //align-items: center;
  // justify-content: center;
  // width: 65%;
  ${props => props.theme.breakpoints.down('sm')} {
    // width: 90%;
  }
`;

const DisplayUpgradeContainer = styled('div')`
  box-sizing: border-box;
  background: ${colors.purple1};
  border-radius: 16px 0px 0px 16px;

  padding-left: 25px;
  padding-right: 25px;
  width: 43%;
  ${props => props.theme.breakpoints.down('xmd')} {
    //padding: 132px ${props => props.theme.spacing(3)};
    width: 47%;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    padding: 50px ${props => props.theme.spacing(3)};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: ${props => props.theme.spacing(2.5)};
    padding-bottom: ${props => props.theme.spacing(3)};
    svg {
      width: 65px;
      height: 65px;
    }
  }
`;

const DisplayUpgradeTitle = styled(LandingText)`
  font-size: 2.5rem;
  height: 100%;
  display: flex;
  align-items: end;
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  line-height: 48px;
  padding-bottom: ${props => props.theme.spacing(10.5)};
  ${props => props.theme.breakpoints.up('lg')} {
    font-size: 2.5rem;
    line-height: 64px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    line-height: 48px;
    padding-bottom: 0;
    align-items: center;
  }
  // ${props => props.theme.breakpoints.down('sm')} {
  //   font-size: 2rem;
  //   line-height: 36px;
  // }
`;

const DisplayUpgradeSubTitle = styled('h2')`
  font-size: 1.125rem;
  color: ${colors.black};
  margin: 0;
  font-weight: 600;
`;

const PlanTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${colors.black};
  margin-bottom: 8px;
  text-align: left;
`;

const PlanPrice = styled('div')`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${colors.black};
  margin-bottom: 32px;
`;

const PlanHeadLine = styled('div')`
  font-size: 1rem;
  font-weight: 600;
  //text-align: center;

  margin-bottom: ${props => props.theme.spacing(4)};
  span {
    font-weight: bold;
  }
  p {
    margin-bottom: 5px;
  }
`;

const PlanHeadLineDisclaimer = styled('div')`
  font-size: 0.8rem;
  margin: 0;
  font-weight: normal;
`;

const PlanBenefits = styled('div')`
  diplay: flex;
  flex-direction: column;
`;

const PlanBenefit = styled('div')`
  font-size: 1rem;
  color: ${colors.black};
  margin-bottom: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
  span {
    font-weight: bold;
  }
  p {
    margin: 0;
  }
`;

const AddonBenefit = styled(PlanBenefit)`
  font-size: 0.875rem;
`;

const PlanButton = styled(Button, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCurrent']),
})<IPlanButtonProps>(
  ({ isCurrent, disabled }) => `

  box-shadow: none;
  min-height: 20px;
 

  ${
    isCurrent
      ? `
  background-color: ${colors.purple5};
  border: none;
  cursor: auto;
  `
      : disabled
      ? ''
      : `
  :hover {
    background-color: ${colors.purple30};
    color: ${colors.white};
  }
  `
  }
`,
);

const CancelButton = styled(Button)`
  position: absolute;
  font-size: 0.875rem !important;
  font-weight: 700;
  color: ${colors.red};
  left: 50%;
  transform: translate(-50%, calc(100% + 12px));
  bottom: 0;
  width: 100%;
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

const PlanPromotion = styled('div')`
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  span {
    color: ${colors.purple30};
  }
`;

const ButtonsContainer = styled('div')`
  position: relative;
  margin-top: 50px;
  width: 100%;
`;

const CancelledText = styled('div')`
  color: ${colors.darkGrey};
  font-style: italic;
  position: absolute;
  font-size: 0.875rem;
  font-weight: 700;
  left: 50%;
  transform: translate(-50%, calc(100% + 12px));
  bottom: 0;
  width: 100%;
  text-align: center;
`;

const PlanOuterPrices = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isCurrent', 'applyTopMargin']),
})<IOuterPlanPrices>(
  ({ applyTopMargin, theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
  flex-direction: column;
  margin-bottom: 15px;
  margin-top:${applyTopMargin ? '15px' : 0};
  a {
    width: 100%;
    text-align: center;
    z-index:10;
    font-size: 1.25rem;
    color:${colors.black};
    font-weight: 700;
    span {
      font-weight: 700;
    }
  }
`,
);

const PlanOuterPrice = styled('span')`
  text-align: center;

  color: ${colors.black};
  span {
    font-weight: 700;
  }
`;

const LandingFixedContainer = styled('div')`
  ${props => props.theme.breakpoints.up('$xxxl')} {
    display: flex;
    height: calc(100vh - 64px - 82px - 92px);
  }
`;

// const PlanPrice = styled('div')`
//   font-size: 1.25rem;
//   font-weight: 700;
// `;

export {
  LandingContainer,
  PlanContainer,
  DisplayUpgradeContainer,
  DisplayUpgradeTitle,
  DisplayUpgradeSubTitle,
  PlanTitle,
  PlanPrice,
  PlanHeadLine,
  PlanBenefits,
  PlanBenefit,
  PlanButton,
  CancelButton,
  PlanPromotion,
  ButtonsContainer,
  PlanContent,
  CancelledText,
  PlanOuterPrices,
  PlanOuterPrice,
  LandingFixedContainer,
  PlanHeadLineDisclaimer,
  AddonBenefit,
};
