import { ErrorMessage, Title, FormContainer } from './styled';
import { en } from '../../../i18n';
import usePaymentForm from '../hooks/usePaymentForm';
import MembershipInfo from './MembershipInfo';
import { subscriptionsSelectors } from '../redux/slice';
import { useSelector } from 'react-redux';
import PriceFields from './PriceFields';
import CardSection from './CardSection';
import { EPaymentSteps } from '../../types';
import ContentManager from './classes/ContentManager';

const {
  paymentForm: { title },
} = en.billing;

const Form = ({ control, state }: ReturnType<typeof usePaymentForm>) => {
  const { errors } = useSelector(subscriptionsSelectors.allState);

  const {
    handleChange,
    handleCountryChange,
    handleAddressFieldChange,
    onOpenPromoCode,
    onApplyPromoCode,
    resetPromoCode,
    onSubmit,
    onModalClick,
    onChangeCVC,
    onChangeCard,
    onChangeExpiry,
    getCurrentState,
  } = control;
  const { formState, stripePlan } = state;

  // //TODO: Refactor this
  // const onModalClick = (e: React.SyntheticEvent) => {
  //   //@ts-ignore
  //   const parentElement = e.target?.parentElement;
  //   if (parentElement?.className?.includes('UserAgreementsModal')) return;
  //   e.stopPropagation();
  // };

  const currentState = getCurrentState();

  const contentManager = new ContentManager(stripePlan, getCurrentState());

  return (
    <FormContainer id="payment-form" onSubmit={onSubmit} onClick={onModalClick}>
      <Title>{contentManager.content.formTitle()}</Title>
      <MembershipInfo stripePlan={stripePlan} currentState={currentState} />
      <CardSection
        handleChange={handleChange}
        formState={formState}
        errors={errors}
        handleAddressFieldChange={handleAddressFieldChange}
        handleCountryChange={handleCountryChange}
        onChangeCVC={onChangeCVC}
        onChangeCard={onChangeCard}
        onChangeExpiry={onChangeExpiry}
      />
      {currentState !== EPaymentSteps.BUY_ADDON_NO_CARD && (
        <PriceFields
          onOpenPromoCode={onOpenPromoCode}
          onApplyPromoCode={onApplyPromoCode}
          handleChange={handleChange}
          formState={formState}
          resetPromoCode={resetPromoCode}
        />
      )}
      {errors?.global && <ErrorMessage>{errors.global}</ErrorMessage>}
    </FormContainer>
  );
};

export default Form;
