import { RadialBackground, AuthFormPopup, AuthContainer, AuthFormInsideContainer, PageContainer } from './styled';

interface IAuth {
  children?: React.ReactNode;
  centerFormVertical?: boolean;
  largeForm?: boolean;
  mobileWidth?: number;
  tallForm?: boolean;
}

const Auth = ({ children, largeForm = false, centerFormVertical = true, mobileWidth, tallForm }: IAuth) => {
  return (
    <PageContainer>
      <RadialBackground>
        <AuthContainer>
          <AuthFormPopup tallForm={tallForm} largeForm={largeForm}>
            <AuthFormInsideContainer
              tallForm={tallForm}
              mobileWidth={mobileWidth}
              centerFormVertical={centerFormVertical}
            >
              {children}
            </AuthFormInsideContainer>
          </AuthFormPopup>
        </AuthContainer>
      </RadialBackground>
    </PageContainer>
  );
};

export default Auth;
