import { IStepComponent } from '../types';
import { LocationInputContainer } from './styled';

import LocationDropDowns from './../../components/Location';
import useStep from './useStep';
import { ILocationState } from '../../components/Location/useLocation';
import { OutlinedInput } from '@mui/material';

const Location = ({ question }: IStepComponent) => {
  const { title, onChangeAnswer, currentResponse } = useStep({ question });

  const onChange = (locationState: ILocationState) => {
    onChangeAnswer(locationState);
  };
  return (
    <>
      {title}
      <LocationInputContainer>
        <LocationDropDowns onChangeExternal={onChange} externalState={currentResponse as ILocationState} />
      </LocationInputContainer>
    </>
  );
};

export default Location;
