import { styled } from '@mui/material/styles';
import { opacityInAnimation } from '../../sharedStyled';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

type TVariant = 'warning' | 'error' | 'success' | 'errorRed300';

interface IMessage {
  variant: TVariant;
}
// TODO: Add style builder for all variants : error,success etc
// Only warning is needed for now
// Refactor form error messages to use this component
const MessageContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['variant']),
})<IMessage>(({ variant, theme }) => {
  const getColors = () => {
    switch (variant) {
      case 'success':
        return {
          background: colors.lightestGreen,
          border: colors.lightestGreen,
          text: colors.black,
        };
      case 'error':
        return {
          background: colors.errorRed5,
          border: colors.errorRed5,
          text: colors.errorRed,
        };
      case 'warning':
        return {
          background: 'rgba(96, 75, 223, 0.2)',
          border: colors.purple600,
          text: colors.purple600,
        };
      case 'errorRed300':
        return {
          background: colors.pink,
          text: colors.red300,
          border: colors.red300,
        };
      // case 'info':
      // default:
      //   return {
      //     background: manualTheme.opacityColors.info(),
      //     border: manualTheme.opacityColors.infoBorder(1),
      //     text: colors.blue1,
      //   };
    }
  };
  const messageColors = getColors();
  return `
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 2px solid ${messageColors.border};
  color: ${messageColors.text || colors.black};
  font-weight: 700;
  background-color: ${messageColors.background};
  border-radius: 8px;
  ${opacityInAnimation()};

`;
});

export type { TVariant };
export { MessageContainer };
