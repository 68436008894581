import { useEffect } from 'react';
import {
  AuthContainer as OriginalAuthContainer,
  PageContainer as OriginalPageContainer,
  RadialBackground as OriginalRadialBackground,
} from '../Auth/styled';
import { QuizFormPopup as OriginalQuizFormPopup } from './styled';
import SmartQuiz from '../SmartQuiz';
import { en } from '../../i18n';
import { actions, quizSelectors } from '../StudySpace/redux/slice';
import { useAuthorization, useDispatch, useMembershipProxy, usePropsOverride, useSeoTags } from '../hooks';
import { useSelector } from 'react-redux';
import { actions as globalActions } from '../redux/globalSlice';
import { dashboardPath } from '../Router/paths';
import { useHistory } from 'react-router';
import useAttempts from '../hooks/useAttempts';

const HARD_CODED_QUIZ_ID = '76a42ce4-ebb2-11ed-a05b-0242ac120003';

const { unstartedQuiz, smartQuiz, resultsButtonText } = en.placementTest;

interface IOverrides {
  QuizFormPopup?: React.ElementType<any>;
  PageContainer?: React.ElementType<any>;
  AuthContainer?: React.ElementType<any>;
  RadialBackground?: React.ElementType<any>;
  overrides?: Partial<IOverrides>;
}

const PlacementTest = () => {
  const history = useHistory();
  const {
    currentQuestionId,
    attempts,
    placementAttempts,
    loadingAttempts,
    loadingPlacementAttempts,
    currentAttempt,
    questionAnswers,
    loadingAnswer,
    isViewingResults,
  } = useSelector(quizSelectors.allState);
  const {
    state: { isPlacementTestDone },
  } = useAuthorization();

  const { baseLineTestLayoutComponents } = useMembershipProxy({ useStrict: true });
  const seo = useSeoTags({ pageName: 'placementTest' });

  const { enforcePlacement } = (history?.location?.state || {}) as { enforcePlacement: boolean };

  const {
    AuthContainer = OriginalAuthContainer,
    RadialBackground = OriginalRadialBackground,
    QuizFormPopup = OriginalQuizFormPopup,
    PageContainer = OriginalPageContainer,
  } = usePropsOverride<IOverrides>({}, baseLineTestLayoutComponents.run());
  const { currentPlacementTest: currentPlacementTestStored } = useAttempts();
  const currentPlacementTest = currentPlacementTestStored || currentAttempt;

  const isQuizStarted = () => {
    if (!attempts || !placementAttempts) return false;
    return !!currentPlacementTest && currentPlacementTest.isPlacement;
  };

  useEffect(() => {
    if (isPlacementTestDone && !enforcePlacement) history.push(dashboardPath);
  }, []);

  const { dispatch } = useDispatch();
  //const [loading, setLoading] = useState(false);
  const unstartedQuizOverrides = {
    title: unstartedQuiz.title,
    text: unstartedQuiz.text,
    buttonText: unstartedQuiz.buttonText,
    buttonDisabled: false,
    hideReviewQuizzes: true,
    isPlacement: true,
  };

  const stateOverrides = {
    loading: loadingAttempts || loadingAnswer || loadingPlacementAttempts,
    isStarted: isQuizStarted(),
    isPlacement: true,
  };

  useEffect(() => {
    if (!loadingAttempts && !loadingPlacementAttempts && attempts && placementAttempts && currentPlacementTest) {
      if (currentAttempt?.attemptId !== currentPlacementTest.attemptId) {
        dispatch(actions.setCurrentAttempt({ attempt: currentPlacementTest }));
      }
    }
  }, [attempts, placementAttempts, currentPlacementTest, loadingAttempts, loadingPlacementAttempts]);

  const textOverrides = {
    title: smartQuiz.title,
  };

  const resultsOverrides = {
    buttonText: resultsButtonText,
    resetState: () => {
      history.push(dashboardPath);
    },
  };

  const onCheckAnswer = () => {
    if (!currentQuestionId || !currentAttempt) return;

    dispatch(
      actions.submitAnswer({
        attemptId: currentAttempt.attemptId,
        questionId: currentQuestionId,
        response: questionAnswers[currentQuestionId]?.value,
      }),
    );
  };

  const startQuiz = () => {
    dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID }));
  };

  const skipPlacement = () => {
    history.push(dashboardPath);
  };

  const controlOverrides = {
    startQuiz,
    onCheckAnswer,
    skipPlacement,
  };

  useEffect(() => {
    dispatch(globalActions.topicsIndexRequest({ subjectID: '7b9a7934-6060-11ed-91bc-3933fa62cf29' }));
  }, []);

  return (
    <PageContainer>
      {seo}
      <RadialBackground>
        <AuthContainer>
          <QuizFormPopup isViewingResults={isViewingResults}>
            <SmartQuiz
              unstartedQuizOverrides={unstartedQuizOverrides}
              controlOverrides={controlOverrides}
              stateOverrides={stateOverrides}
              textOverrides={textOverrides}
              resultsOverrides={resultsOverrides}
            />
          </QuizFormPopup>
        </AuthContainer>
      </RadialBackground>
    </PageContainer>
  );
};

export default PlacementTest;
