import { ICustomerAddress, ILooseObject, ISubscription, IStateCustomerAddress, EStripeErrorCodes } from '../../types';
import BaseTransformer from './BaseTransformer';
import { en } from '../../../i18n';
const { billing } = en;
import { format } from 'date-fns';
import { deprecatedSubscriptions } from '../../Membership/plans';

class SubscriptionTransformer extends BaseTransformer {
  public transform(data: ILooseObject): ISubscription {
    const { address, currentPeriodEnd } = data;
    const { postal_code, ...restOfAddress } = address;
    restOfAddress.postalCode = postal_code || address.postalCode;
    data.address = restOfAddress;
    data.isDeprecated = false;
    const { items } = data;
    if (items?.data?.[0]) {
      const { price } = items.data[0];
      const { id } = price;
      data.isDeprecated = Object.values(deprecatedSubscriptions).some(deprecatedSub => deprecatedSub.priceId === id);
    }

    if (currentPeriodEnd) {
      data.formattedEndDate = format(new Date(currentPeriodEnd), 'MMMM dd, yyyy');
    }
    return data as ISubscription;
  }

  public transformOut(data: IStateCustomerAddress): ICustomerAddress {
    return { ...data, country: data.country?.value || billing.paymentForm.countries[1].value };
  }

  public transformStripeErrors(errors: any) {
    const payload: ILooseObject = { global: '', address: '', card: '', promoCode: '' };
    if (!errors || !(typeof errors === 'object')) {
      payload.global = en.somethingWentWrong;
      return payload;
    }
    const { code } = errors;
    switch (code) {
      case EStripeErrorCodes.INVALID_ADDRESS:
        payload.address = en.billing.invalidAddressError;
        break;
      case EStripeErrorCodes.PAYMENT_INTENT_INCOMPATIBLE:
        payload.global = en.somethingWentWrong;
        break;
      default:
        if (!errors.code) payload.global = en.somethingWentWrong;
        else payload.global = errors.message;
    }
    return payload;
  }
}

export default SubscriptionTransformer;
