import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { thinScrollbar } from '../../sharedStyled';

const ModalContainer = styled('div')`
  width: max(600px, 38%);
  max-width: 560px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 40px;

  padding-right: 0;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 80%;
    padding: 20px;
    padding-right: 0px;
  }
`;

const ModalBody = styled('div')`
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 40px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px;
  }
  ${thinScrollbar};
`;

const PrivacyPolicyText = styled('div')`
  margin-top: ${props => props.theme.spacing(2.5)};
  color: ${colors.black};
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  a {
    color: #1145ff;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const MinimalText = styled('span')`
  font-size: 0.75rem;
  color: ${colors.darkGrey};
  display: flex;
  gap: 19px;
  align-items: center;
  span {
    cursor: pointer;
  }
`;
const MinimalTextContainer = styled('div')``;

export { ModalContainer, ModalBody, PrivacyPolicyText, MinimalText, MinimalTextContainer };
