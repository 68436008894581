import useTrialTimer from './useTrialTimer';

interface ITrialTimer {
  useRefreshSubscriptions: boolean;
  renderer?: () => JSX.Element;
  date?: any;
}
const TrialTimer = ({ useRefreshSubscriptions = true, ...props }: ITrialTimer) => {
  const { renderer: rendererDefault, finalDate } = useTrialTimer({ useRefreshSubscriptions });
  const { renderer = rendererDefault, date = finalDate } = props;

  if (renderer) return renderer();

  return null;
};

export default TrialTimer;
