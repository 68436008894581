//import CustomTooltip from '../../../components/Tooltip';
import { Tooltip } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ILooseObject } from '../../../types';

const DroppableArea = ({
  droppableId,
  isDropDisabled,
  droppableComponent,
  draggableComponent,
  draggableItems,
  isItemDragged,
  placeholder,
  droppableComponentProps = {},
  children,
  isDragDisabled,
  className,
}: {
  droppableId: string;
  droppableComponent: any;
  draggableComponent: any;
  isDropDisabled: boolean;
  draggableItems: ILooseObject[];
  isItemDragged: boolean;
  placeholder?: string;
  droppableComponentProps?: ILooseObject;
  children?: JSX.Element;
  isDragDisabled?: boolean;
  className?: string;
}) => {
  const DroppableComponent = droppableComponent;
  const DraggableComponent = draggableComponent;
  const renderItem = (item: ILooseObject) => {
    if (!isItemDragged) return <span>{item.content}</span>;
    return (
      <Tooltip
        disableFocusListener
        PopperProps={{
          disablePortal: true,
        }}
        enterTouchDelay={0}
        title={item.content}
        placement="bottom"
      >
        <span>{item.content}</span>
      </Tooltip>
    );
  };
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <DroppableComponent ref={provided.innerRef} {...droppableComponentProps}>
          {draggableItems.map((item: ILooseObject, index: number) => (
            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
              {(provided, snapshot) => (
                <DraggableComponent
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  isDragged={isItemDragged}
                >
                  {renderItem(item)}
                </DraggableComponent>
              )}
            </Draggable>
          ))}
          {!snapshot.isDraggingOver && !draggableItems[0] && placeholder}
          {children}
        </DroppableComponent>
      )}
    </Droppable>
  );
};

export default DroppableArea;
