import { ReactNode } from 'react';
import Active from './Active';
import { en } from '../../../../i18n';
import SmartNText from '../../../components/SmartnText';

const {
  paymentForm: { landingSubscribedSponsored, planSubTitleSponsored },
  sponsored,
} = en.billing;

class Sponsored extends Active {
  public membershipMeta(): ReactNode {
    return <>{landingSubscribedSponsored}</>;
  }

  public membershipInfoTitle() {
    return (
      <span>
        <SmartNText>Aspire</SmartNText> - {sponsored}
      </span>
    );
  }

  public membershipInfoSubTitle(): string {
    const additionalInfo = super.getAdditionalInfo();
    const { currentSubscription } = additionalInfo;
    let endDate;
    if (currentSubscription) endDate = currentSubscription.formattedEndDate;
    return `${planSubTitleSponsored} ${endDate}`;
  }
}

export default Sponsored;
