import { ReactComponent as NCLEXRNIcon } from '../../images/nclexrn.svg';
import { ReactComponent as NCLEXPNIcon } from '../../images/nclexpn.svg';
import { ReactComponent as GeneralStudyIcon } from '../../images/generalStudy.svg';
import { ILooseObject, EQuestionTypes, EUserRole } from '../types';
import { en } from '../../i18n';

const { welcomeToSmartn, youCanSkip, selectAll, smartnBuddy } = en.onBoarding;

type TContainers = 'PreferredNameQuestionContainer' | 'ReadyQuestionContainer';

type TComponents = 'PreferredName' | 'Ready';

interface step {
  onboardingQuestionId?: string;
  isSubtitleHighlighted?: boolean;
  showFooterHint?: boolean;
  isLargeTitle?: boolean;
  title?: string;
  showHint?: boolean;
  nextButtonText?: string;
  hidePrevious?: boolean;
  skipValidation?: boolean;
  isMultiple?: boolean;
  choicesIcons?: ILooseObject;
  dependsOn?: ILooseObject;
  isTopicsChoices?: boolean;
  limit?: number;
  subtitle?: string;
  questionType?: EQuestionTypes | 'radioChoices';
  isSubtitlePushed?: boolean;
  showSkip?: boolean;
  subTitlePath?: string;
  subTitlePathB?: string;
  titlePath?: string;
  containerComponent?: TContainers;
  questionComponent?: TComponents;
  isLargeWidth?: boolean;
  hideAnswer?: boolean;
  hideFooter?: boolean;
}

type TContainerMapping = {
  [key in TContainers]: React.ElementType<any>;
};

type TComponentsMapping = {
  [key in TComponents]: React.ElementType<any>;
};

const mainSteps: step[] = [
  {
    onboardingQuestionId: 'cddcea40-44b8-11ef-9454-0242ac120002',
    titlePath: 'content.question.content.text',
    subTitlePathB: 'content.answers[1]',
    subTitlePath: 'content.explanation',
    isLargeTitle: true,
    isSubtitleHighlighted: true,
    showFooterHint: true,
    containerComponent: 'PreferredNameQuestionContainer',
    questionComponent: 'PreferredName',
  },
  {
    onboardingQuestionId: 'c982ad4a-44bd-11ef-9454-0242ac120002',
    showFooterHint: false,
    isLargeTitle: true,
    containerComponent: 'PreferredNameQuestionContainer',
    isLargeWidth: true,
  },
  {
    onboardingQuestionId: 'd1ac4878-44bd-11ef-9454-0242ac120002',
    showFooterHint: false,
    isLargeTitle: true,
    containerComponent: 'ReadyQuestionContainer',
    questionComponent: 'Ready',
    isLargeWidth: true,
    hideAnswer: true,
    hideFooter: true,
  },
];
const stepsV2 = (role?: EUserRole): step[] => {
  const weakTopics: step = {
    onboardingQuestionId: 'e8206f80-44bd-11ef-9454-0242ac120002',
    questionType: EQuestionTypes.MULTIPLE_CHOICE,
    isTopicsChoices: true,
    isMultiple: true,
    limit: 5,
    showSkip: false,
    //nextButtonText: 'Start',
  };

  const writeExam: step = {
    onboardingQuestionId: '494802d2-4f2c-11ef-9454-0242ac120002',
    questionType: 'radioChoices',
    isLargeTitle: true,
    isLargeWidth: true,
    containerComponent: 'PreferredNameQuestionContainer',
  };

  if (role === EUserRole.UNDERGRAD) return [mainSteps[0], mainSteps[1], writeExam, weakTopics, mainSteps[2]];

  return mainSteps;
};

const steps: step[] = [
  {
    onboardingQuestionId: 'c316056c-f840-11ed-b67e-0242ac120002',
    isSubtitleHighlighted: true,
    showFooterHint: true,
    isLargeTitle: true,
  },
  {
    onboardingQuestionId: '0da76772-0018-11ee-be56-0242ac120002',
  },
  {
    title: welcomeToSmartn,
    subtitle: youCanSkip,
    isSubtitlePushed: true,
    // showHint: true,
    // nextButtonText: letsDoThis,
    // hidePrevious: true,
    skipValidation: true,
    isLargeTitle: true,
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c31608aa-f840-11ed-b67e-0242ac120002',
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c31609f4-f840-11ed-b67e-0242ac120002',
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c3160b34-f840-11ed-b67e-0242ac120002',
    choicesIcons: {
      nclexExam: NCLEXRNIcon,
      nclexPNExam: NCLEXPNIcon,
      gerneralNursing: GeneralStudyIcon,
    },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'eca71d8e-0313-11ee-be56-0242ac120002',
    dependsOn: { step: 5, choice: 'other' },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c3160c4c-f840-11ed-b67e-0242ac120002',
    dependsOn: { step: 5, choice: ['passExam', 'passPNExam'] },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c31612c8-f840-11ed-b67e-0242ac120002',
    dependsOn: { step: 7, choice: 'yes' },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c3161156-f840-11ed-b67e-0242ac120002',
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c3161462-f840-11ed-b67e-0242ac120002',
    // this question depends on a non-exsiting step, causing it to be hidden (along with its follow up question)
    dependsOn: { step: -1, choice: 'NA' },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c31615b6-f840-11ed-b67e-0242ac120002',
    title: smartnBuddy,
    dependsOn: { step: 10, choice: 'yes' },
    showSkip: true,
  },
  {
    onboardingQuestionId: 'c31616d8-f840-11ed-b67e-0242ac120002',
    questionType: EQuestionTypes.MULTIPLE_CHOICE,
    isTopicsChoices: true,
    isMultiple: true,
    limit: 5,
    showSkip: true,
  },
];

// old ID
//const HARD_CODED_QUIZ_ID = '2bacade0-81c9-11ed-aa13-313f0737df45';

const HARD_CODED_QUIZ_ID_V2 = '37cee2b0-0314-11ee-be56-0242ac120002';
const HARD_CODED_QUIZ_ID = '4e5508fd-979d-47ad-a56b-e9a604d02f1f';

const ROLE_PLACE_HOLDER_ID = 'a32b0674-4a76-11ef-9454-0242ac120002';

const ngnOnboardingTopic = '7bed20da-6060-11ed-91bc-3933fa62cf29';

const topicsSelectionQuestion = 'e8206f80-44bd-11ef-9454-0242ac120002';

const firstNameQuestionId = 'cddcea40-44b8-11ef-9454-0242ac120002';

const roleQuestionIdV2 = '0da76772-0018-11ee-be56-0242ac120002';
const roleQuestionId = 'c982ad4a-44bd-11ef-9454-0242ac120002';
const readyQuestionID = 'd1ac4878-44bd-11ef-9454-0242ac120002';

const studyGoalOnboardingQuestionId = 'c3160b34-f840-11ed-b67e-0242ac120002';

export type { TContainers, TContainerMapping, step, TComponentsMapping };

export {
  steps,
  stepsV2,
  mainSteps,
  HARD_CODED_QUIZ_ID,
  ngnOnboardingTopic,
  topicsSelectionQuestion,
  firstNameQuestionId,
  roleQuestionId,
  studyGoalOnboardingQuestionId,
  roleQuestionIdV2,
  HARD_CODED_QUIZ_ID_V2,
  ROLE_PLACE_HOLDER_ID,
  readyQuestionID,
};
