import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SLICE_NAME as topicsSlice, topicsSelectors } from '../StudySpace/redux/topics/slice';
import { actions as globalActions } from '../redux/globalSlice';
import { SLICE_NAME as studySpaceSlice, actions as quizActions, quizSelectors } from '../StudySpace/redux/slice';
import { subscriptionsSelectors } from '../Membership/redux/slice';
import { useDispatch, useMembershipProxy, useSeoTags, useSubscriptions } from '../hooks';
import SideBar from './SideBar';

import { subject } from '../StudySpace/staticConstants';
import Spinner from '../components/Spinner';
import useAttempts from '../hooks/useAttempts';
import Modals from './Modals';
import { getMonthAgo } from '../utils';
import { DashboardContainer, DashboardContentContainer, NavHeaderContainer, SpinnerContainer } from './styled';
import { ESelectedSection } from '../Header/NavHeader/types';
import PracticeQuiz from './PracticeQuiz';
import ReviewInsights from './ReviewInsigts';
import Analyze from './Analyze';
import Assess from './Assess';

const Home = () => {
  const { filteredAttempts: attempts, currentReadinessAttempt, readinessAttempts } = useAttempts();
  const { dispatch } = useDispatch(topicsSlice);
  const { dispatch: studySpaceDispatch } = useDispatch(studySpaceSlice);
  const { loading } = useSelector(topicsSelectors.globalSelector);
  const { loadingAttempts, loadingOnBoardingAttempts, loadingPlacementAttempts } = useSelector(quizSelectors.allState);
  const { loading: loadingTopics } = useSelector(topicsSelectors.globalSelector);
  const { loading: loadingSubscriptions } = useSelector(subscriptionsSelectors.allState);
  const { dashboardBanner } = useMembershipProxy();
  const seo = useSeoTags({ pageName: 'home' });
  useSubscriptions({ useFetchSubscriptions: false });
  const { id } = subject;
  const [isNurseGPTModalOpen, setIsNurseGPTModalOpen] = useState(false);

  const toggleNurseGPTModal = () => {
    setIsNurseGPTModalOpen(!isNurseGPTModalOpen);
  };

  useEffect(() => {
    dispatch(globalActions.topicsIndexRequest({ subjectID: id }));
    studySpaceDispatch(quizActions.fetchAttempts({ earliest: getMonthAgo(), showAll: true }));
  }, []);

  const isLoading =
    loading ||
    loadingAttempts ||
    loadingSubscriptions ||
    loadingTopics ||
    loadingOnBoardingAttempts ||
    loadingPlacementAttempts;

  const content = (
    <>
      <DashboardContentContainer>
        <Modals isNurseGPTModalOpen={isNurseGPTModalOpen} toggleNurseGPTModal={toggleNurseGPTModal} />
        <PracticeQuiz id={ESelectedSection.PRACTICE} attempts={attempts} />
        <Assess
          id={ESelectedSection.ASSESS}
          attempts={attempts}
          readinessAttempts={readinessAttempts}
          currentReadinessAttempt={currentReadinessAttempt}
        />
        <ReviewInsights id={ESelectedSection.REVIEW} attempts={attempts} />
        <Analyze id={ESelectedSection.ANALYZE} attempts={attempts} />
      </DashboardContentContainer>
    </>
  );

  const renderContent = () => {
    if (isLoading)
      return (
        <SpinnerContainer>
          <Spinner loading={isLoading} />
        </SpinnerContainer>
      );
    return content;
  };

  return (
    <DashboardContainer>
      {seo}
      <SideBar attempts={attempts || []} toggleNurseGPTModal={toggleNurseGPTModal} />
      {renderContent()}
      {!isLoading && dashboardBanner.run()}
    </DashboardContainer>
  );
};

export default Home;
