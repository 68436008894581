import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { DashboardModalContent, DashboardModalOverlay, DashboardModalContainer, LayoutWrapper } from './styled';
import { ModalTitle } from '../../Membership/styled';
import useKeyDownPress from '../../hooks/useKeyDownPress';
import useScrollPosition from '../../hooks/useScrollPosition';
import Button from '../Button';
import { en } from '../../../i18n';
import { ButtonsContainer } from '../../Home/Modals/styled';

interface IDashboardModal {
  children?: React.ReactNode;
  isOpen: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  useLayoutOnly?: boolean;
  closeButton?: (closeModal: () => void) => React.ReactNode;
  confirmButton?: React.ReactNode;
}

const { close } = en.billing;

const DashboardModal = ({
  isOpen,
  children,
  title,
  content,
  icon,
  useLayoutOnly,
  closeButton,
  confirmButton,
}: IDashboardModal) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollPosition = useScrollPosition();
  const stickToTop = scrollPosition > 64;
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useKeyDownPress('Escape', closeModal);
  if (!isModalOpen) return null;

  const renderButton = () => {
    if (closeButton) return closeButton(closeModal);
    return (
      <Button variant="primary" onClick={closeModal}>
        {close}
      </Button>
    );
  };

  if (useLayoutOnly)
    return (
      <DashboardModalOverlay stickToTop={stickToTop}>
        <ClickAwayListener onClickAway={closeModal}>
          <LayoutWrapper>{children}</LayoutWrapper>
        </ClickAwayListener>
      </DashboardModalOverlay>
    );
  return (
    <DashboardModalOverlay stickToTop={stickToTop}>
      <ClickAwayListener onClickAway={closeModal}>
        <LayoutWrapper>
          {children}

          <DashboardModalContainer>
            <>
              <ModalTitle>{title}</ModalTitle>
              {icon}
              <DashboardModalContent>{content}</DashboardModalContent>
              <ButtonsContainer>
                {renderButton()}
                {confirmButton}
              </ButtonsContainer>
            </>
          </DashboardModalContainer>
        </LayoutWrapper>
      </ClickAwayListener>
    </DashboardModalOverlay>
  );
};

export default DashboardModal;
